import React, { useEffect, useReducer } from 'react';
import PropTypes from 'prop-types';
import { Table } from 'antd';
import '../../styles/transaction.css';
import { getPaymentTypesService } from '../../services/merchants';
import { getAPIErrorMessage } from '../../helpers/utils';

const initialState = {
  isFetching: false,
  paymentTypes: [],
  status: null,
  message: null,
};

function reducer(prevState, action) {
  switch (action.type) {
    case 'GET_PAYMENT_TYPES':
      return {
        ...prevState,
        isFetching: true,
        status: null,
        message: null,
      };
    case 'GET_PAYMENT_TYPES_SUCCESS':
      return {
        ...prevState,
        paymentTypes: action.paymentTypes,
        isFetching: false,
      };
    case 'GET_PAYMENT_TYPES_FAILED':
      return {
        ...prevState,
        isFetching: false,
        status: 'error',
        message: action.message,
      };
    default:
      return prevState;
  }
}

const MerchantPaymentTypesTable = (props) => {
  const { merchant } = props;
  const [state, dispatch] = useReducer(reducer, initialState);

  useEffect(() => {
    let ableToSet = true;

    (async () => {
      dispatch({ type: 'GET_PAYMENT_TYPES' });
      try {
        const response = await getPaymentTypesService(merchant.id);
        const { paymentTypes } = response.data;
        if (ableToSet) {
          dispatch({ type: 'GET_PAYMENT_TYPES_SUCCESS', paymentTypes });
        }
      } catch (error) {
        if (ableToSet) {
          const message = getAPIErrorMessage(error, 'We are not able to get the payment types. Please try again later');
          dispatch({ type: 'GET_PAYMENT_TYPES_FAILED', message });
        }
      }
    })();

    return () => {
      ableToSet = false;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const columns = [
    {
      title: 'Name',
      key: 'name',
      render: paymentType => (
        <div className="table-col-item">
          <strong>{paymentType.name}</strong>
        </div>
      ),
    }, {
      title: 'Code',
      key: 'type',
      render: paymentType => (
        <div className="table-col-item">
          <div className="text-primary">{paymentType.code}</div>
        </div>
      ),
    },
  ];

  return (
    <Table
      className="table-standard"
      dataSource={state.paymentTypes}
      columns={columns}
      rowKey="name"
      pagination={false}
    />
  );
};

MerchantPaymentTypesTable.propTypes = {
  merchant: PropTypes.shape({
    id: PropTypes.number.isRequired,
  }).isRequired,
};

export default MerchantPaymentTypesTable;
