import React, { useContext, useEffect, useReducer } from 'react';
import PropTypes from 'prop-types';
import {
  Alert,
  Button,
  Card,
  Col,
  Form,
  Icon,
  Input,
  Row,
  Select,
  Spin,
} from 'antd';
import {
  getUserService,
  updateUserService,
  sendInviteService,
  deactivateUserService,
} from '../../services/users';
import { getMerchantsService } from '../../services/merchants';
import SessionContext from '../../contexts/SessionContext';
import './edituserform.css';


const SYSTEM_ROLE_USER = 70;
const MERCHANT_ROLE_MERCHANT_ADMIN = 10;
const formItemLayout = { labelCol: { span: 24 }, wrapperCol: { span: 24 } };
const { Option } = Select;

const initialState = {
  isFetching: false,
  merchants: [],
  fetchedUser: {},
  editedUser: {},
  msg: {},
};

function reducer(prevState, action) {
  switch (action.type) {
    case 'SET_IS_FETCHING':
      return {
        ...prevState,
        isFetching: action.payload,
      };

    case 'FETCH_MERCHANTS_SUCCESS':
      return {
        ...prevState,
        isFetching: false,
        merchants: action.payload,
      };

    case 'SET_USER_SUCCESS':
      return {
        ...prevState,
        isFetching: false,
        fetchedUser: action.payload,
        editedUser: action.payload,
      };
    case 'SET_USER_FAILED':
      return {
        ...prevState,
        isFetching: false,
        fetchedUser: {},
        editedUser: {},
      };
    case 'EDIT_USER':
      return {
        ...prevState,
        editedUser: action.payload,
      };
    case 'SET_MESSAGE':
      return {
        ...prevState,
        msg: action.payload,
      };
    default: return prevState;
  }
}


const EditUserForm = (props) => {
  const { systemRoles, merchantRoles } = useContext(SessionContext);
  const [state, dispatch] = useReducer(reducer, initialState);
  const {
    email,
    form,
    form: { getFieldDecorator },
    setEmail,
    userId,
  } = props;

  /* Fetches the user to be edited */
  useEffect(() => {
    let ableToSet = true;

    (async () => {
      try {
        dispatch({ type: 'SET_IS_FETCHING', payload: true });
        const response = await getUserService(userId);
        const { data } = response;
        if (ableToSet) {
          setEmail(data.email);
          dispatchSetUser(data);
        }
      } catch (error) {
        dispatch({
          type: 'SET_MESSAGE',
          payload: {
            message: 'Unable to fetch user as of the moment',
            status: 'error',
          },
        });
        dispatch({ type: 'SET_USER_FAILED' });
      }
    })();

    return () => { ableToSet = false; };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  async function updateUser(values) {
    try {
      dispatch({ type: 'SET_IS_FETCHING', payload: true });
      const { data: { person, message } } = await updateUserService({ userId, ...values });
      dispatchSetUser(person);
      dispatch({
        type: 'SET_MESSAGE',
        payload: { message, status: 'success' },
      });
    } catch (error) {
      dispatch({
        type: 'SET_MESSAGE',
        payload: {
          message: 'Unable to update user as of the moment',
          status: 'error',
        },
      });
      dispatch({ type: 'SET_IS_FETCHING', payload: false });
    }
  }

  async function sendInvite() {
    dispatch({ type: 'SET_IS_FETCHING', payload: true });
    try {
      const response = await sendInviteService(email);
      const { data: { message } } = response;
      dispatch({
        type: 'SET_MESSAGE',
        payload: { message, status: 'success' },
      });
      dispatch({ type: 'SET_IS_FETCHING', payload: false });
    } catch (error) {
      dispatch({
        type: 'SET_MESSAGE',
        payload: {
          message: 'Unable to send invite as of the moment',
          status: 'error',
        },
      });
      dispatch({ type: 'SET_IS_FETCHING', payload: false });
    }
  }

  async function deactivateUser() {
    dispatch({ type: 'SET_IS_FETCHING', payload: true });
    try {
      const response = await deactivateUserService(email);
      const { data: { person, message } } = response;
      dispatchSetUser(person);
      dispatch({
        type: 'SET_MESSAGE',
        payload: { message, status: 'success' },
      });
    } catch (error) {
      dispatch({
        type: 'SET_MESSAGE',
        payload: {
          message: 'Unable to deactivate user as of the moment',
          status: 'error',
        },
      });
      dispatch({ type: 'SET_IS_FETCHING', payload: false });
    }
  }

  function handleSubmit(e) {
    const { editedUser } = state;
    e.preventDefault();
    form.validateFields((err, values) => {
      if (!err) {
        try {
          updateUser({
            ...values,
            email,
            systemrole: editedUser.systemRole[0],
            merchantrole: editedUser.systemRole[0] === SYSTEM_ROLE_USER && editedUser.merchantRole
              ? editedUser.merchantRole[0]
              : null,
            merchants: editedUser.systemRole[0] === SYSTEM_ROLE_USER && editedUser.merchantRole[0] >= MERCHANT_ROLE_MERCHANT_ADMIN
              ? [...editedUser.scopes]
              : [],
          });
        } catch (error) {
          dispatch({
            type: 'SET_MESSAGE',
            payload: {
              message: 'Unable to update user as of the moment',
              status: 'error',
            },
          });
          dispatch({ type: 'SET_IS_FETCHING', payload: false });
        }
      }
    });
  }

  function onNameChange(e) {
    dispatch({
      type: 'EDIT_USER',
      payload: {
        ...state.editedUser,
        [e.target.name]: e.target.value, // either firstName or lastName
      },
    });
  }

  function onSelectSystemRole(value) {
    const { fetchedUser, editedUser } = state;
    const foundSystemRole = systemRoles.find(r => r.code === value);
    let payload = {
      ...editedUser,
      systemRole: [
        foundSystemRole.key,
        foundSystemRole.code,
        foundSystemRole.label,
        foundSystemRole.description,
      ],
    };

    /* If value is not 'user', there is no need to have scopes and merchantRole. */
    if (value !== 'user') {
      payload = { ...payload, scopes: [], merchantRole: null };
    } else {
      payload = { ...payload, scopes: fetchedUser.scopes, merchantRole: fetchedUser.merchantRole };
    }

    dispatch({ type: 'EDIT_USER', payload });
  }

  function onSelectMerchantRole(value) {
    const foundMerchantRole = merchantRoles.find(r => r.key === value);
    dispatch({
      type: 'EDIT_USER',
      payload: {
        ...state.editedUser,
        merchantRole: [
          foundMerchantRole.key,
          foundMerchantRole.label,
          foundMerchantRole.description,
        ],
      },
    });
  }

  function onSelectMerchants(value) {
    dispatch({
      type: 'EDIT_USER',
      payload: {
        ...state.editedUser,
        scopes: [...value].sort(),
      },
    });
  }

  let timeout;
  let currentValue;

  async function onSearchMerchant(merchantCode) {
    if (timeout) {
      clearTimeout(timeout);
      timeout = null;
    }

    currentValue = merchantCode;
    async function searchMerchant() {
      try {
        let searchOptions = {
          size: 20,
          page: 1,
        };

        if (merchantCode) {
          searchOptions = {
            query: merchantCode,
            size: 20,
            page: 1,
          };
        }

        const { data } = await getMerchantsService(searchOptions);
        if (currentValue === merchantCode) {
          dispatch({ type: 'FETCH_MERCHANTS_SUCCESS', payload: data.merchants });
        }
      } catch (error) {
        dispatch({ type: 'FETCH_MERCHANTS_SUCCESS', merchants: [] });
      }
    }

    // Set timeout to minimize flooding the server with API calls when
    // searching for merchants
    timeout = setTimeout(searchMerchant, 400);
  }

  function dispatchSetUser(userData) {
    const merchantRole = userData.merchantRoles && userData.merchantRoles.length
      ? userData.merchantRoles[0].merchantRole
      : userData.merchantRole;
    const scopes = userData.scopes.length > 0 ? userData.scopes.sort() : [];
    dispatch({
      type: 'SET_USER_SUCCESS',
      payload: { ...userData, scopes, merchantRole },
    });
  }

  const {
    isFetching,
    fetchedUser,
    editedUser,
    merchants,
    msg,
  } = state;
  const {
    firstName,
    lastName,
    isEnabled,
    isAccountConfirmed,
  } = fetchedUser;
  const notChanged = JSON.stringify(editedUser) === JSON.stringify(fetchedUser);
  return (
    <>
      <Row gutter={32}>
        {msg && msg.status && msg.message && (
          <Col span={24} style={{ marginBottom: '16px' }}>
            <Alert
              message={<p style={{ marginBottom: 0 }}>{msg.message}</p>}
              type={msg.status}
              showIcon
              closable
              onClose={() => dispatch({ type: 'SHOW_ALERT', message: null })}
            />
          </Col>
        )}
        <Col span={12}>
          <Card className="card-standard" title="User Details">
            {!isFetching ? (
              <Form id="detailsForm" onSubmit={handleSubmit}>
                <div className="details-body">
                  <Form.Item {...formItemLayout} className="form-item" label="Email Address">
                    <Input className="form-input" value={email} disabled type="email" />
                  </Form.Item>
                  <Form.Item {...formItemLayout} className="form-item" label="First Name">
                    {getFieldDecorator('firstname', {
                      initialValue: firstName,
                      rules: [{ required: true, message: 'Please enter the first name.' }],
                    })(<Input name="firstName" onChange={onNameChange} className="form-input" />)}
                  </Form.Item>
                  <Form.Item {...formItemLayout} className="form-item" label="Last Name">
                    {getFieldDecorator('lastname', {
                      initialValue: lastName,
                      rules: [{ required: true, message: 'Please enter the last name.' }],
                    })(<Input name="lastName" onChange={onNameChange} className="form-input" />)}
                  </Form.Item>
                </div>
                <Form.Item className="deactivate-section">
                  <div className="deactivate-text title">
                    User Status
                  </div>
                  <div className="deactivate-text subtitle">
                    {isAccountConfirmed ? (
                      `This user is currently ${isEnabled ? 'active' : 'inactive'}.
                        If you wish to ${isEnabled
                        ? 'deactivate this user, you may do so by clicking the button below.'
                        : 'reactivate this user, please contact the administrator.'}`
                    ) : (
                      `Send an email to this user with a link
                      that will allow them to sign up to this dashboard.`
                    )}
                  </div>
                  {
                    // User is enabled, but not confirming yet
                    isEnabled && !isAccountConfirmed ? (
                      <Button
                        className="button button-standard"
                        loading={isFetching}
                        onClick={sendInvite}
                      >
                        Send Invite
                      </Button>
                    ) : (
                      // User is enabled and confirmed, OR disabled
                      <Button
                        className={isEnabled ? 'button button-standard' : 'button button-standard disabled'}
                        disabled={!isEnabled}
                        loading={isFetching}
                        onClick={deactivateUser}
                      >
                        Deactivate
                      </Button>
                    )
                  }

                </Form.Item>
              </Form>
            ) : (
              <div className="spinner">
                <Spin />
                <span className="spinner-text">
                  Loading...
                </span>
              </div>
            )}
          </Card>
        </Col>
        <Col span={12}>
          <Card className="card-standard" title="Permission">
            {!isFetching ? (
              <Form>
                <div className="details-body">
                  <div className="dropdown-label">
                    Set the role this user has.
                  </div>
                  <div className="dropdown-role">
                    {getFieldDecorator('systemRole', {
                      initialValue: editedUser.systemRole ? editedUser.systemRole[2] : 'sysadmin',
                    })(
                      <Select
                        className="role-select"
                        onChange={onSelectSystemRole}
                        suffixIcon={<Icon type="caret-down" />}
                      >
                        {systemRoles.map(r => (
                          <Option key={r.key} value={r.code} label={r.label}>
                            {r.label}
                          </Option>
                        ))}
                      </Select>,
                    )}
                    <div className="role-description">
                      {`${editedUser.systemRole ? editedUser.systemRole[3] : '-'}`}
                    </div>
                  </div>
                  {editedUser?.systemRole && editedUser.systemRole[0] === SYSTEM_ROLE_USER && (
                    <div className="dropdown-role">
                      <Select
                        className="role-select"
                        onChange={onSelectMerchantRole}
                        value={editedUser.merchantRole.length
                          && editedUser.merchantRole[1]}
                        placeholder="Please select a role"
                        suffixIcon={<Icon type="caret-down" />}
                      >
                        {merchantRoles.map(r => (
                          <Option key={r.key} value={r.key}>
                            {r.label}
                          </Option>
                        ))}
                      </Select>
                      {editedUser.merchantRole && editedUser.merchantRole.length ? (
                        <div className="role-description">
                          {`${editedUser.merchantRole[2]}`}
                        </div>
                      ) : (
                        <div className="role-description">
                          {`${merchantRoles[0].description}`}
                        </div>
                      )}
                    </div>
                  )}
                  {editedUser.systemRole && editedUser.systemRole[0] === SYSTEM_ROLE_USER && (
                    <>
                      <div className="dropdown-label merchant-label">
                        Select the merchant this user has access to.
                      </div>
                      <div className="dropdown-role">
                        <Select
                          style={{ width: '100%' }}
                          className="merchant-select"
                          mode="multiple"
                          showSearch
                          defaultValue={editedUser.scopes}
                          onChange={onSelectMerchants}
                          onSearch={onSearchMerchant}
                          placeholder="Please select a merchant"
                          suffixIcon={<Icon type="caret-down" />}
                          notFoundContent={null}
                        >
                          {merchants.map(m => (
                            <Option key={m.code} value={m.code} label={m.name}>
                              {m.code}
                            </Option>
                          ))}
                        </Select>
                      </div>
                    </>
                  )}
                </div>
              </Form>
            ) : (
              <div className="spinner">
                <Spin />
                <span className="spinner-text">
                  Loading...
                </span>
              </div>
            )}
          </Card>
        </Col>
      </Row>
      <div className="button-container">
        <Button
          className="button button-standard"
          form="detailsForm"
          key="submit"
          htmlType="submit"
          type="primary"
          loading={isFetching}
          disabled={notChanged}
        >
          Save Changes
        </Button>
      </div>
    </>
  );
};

EditUserForm.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  form: PropTypes.object.isRequired,
  email: PropTypes.string.isRequired,
  userId: PropTypes.string.isRequired,
  setEmail: PropTypes.func.isRequired,
};

export default Form.create()(EditUserForm);
