import moment from 'moment';
import React, { useReducer, useEffect } from 'react';
import PropTypes from 'prop-types';
import qs from 'query-string';
import { useLocation, useHistory } from 'react-router-dom';
import {
  Row, Col, Breadcrumb, Table, Input, Icon, Button, Dropdown, Menu, Alert,
} from 'antd';
import { PaymentIcon, RepeatIcon, CircleIcon } from '../../components/Icons';
import PaymentsFilterModal from '../../components/PaymentsFilterModal';
import { searchPayments, exportPaymentsService } from '../../services/payments';
import { authenticateExportTokenService } from '../../services/transactions';
import { formatNumber, getStatusColor, getDefaultCreatedDate } from '../../helpers/utils';
import { APP_STATES } from '../../constants';
import '../../styles/transaction.css';
import './index.css';

const dateFormat = 'YYYY-MM-DD';
const displayDateFormat = 'MMM. DD, YYYY';
const { defaultEndDate, defaultStartDate } = getDefaultCreatedDate(dateFormat);

const defaultFilter = {
  page: 1,
  status: ['PAID', 'SETTLED'],
  startDate: defaultStartDate,
  endDate: defaultEndDate,
  paymentMethod: 'all',
  transactionType: 'all',
  query: null,
  merchant: null,
  project: null,
  paymentType: null,
  reportingDate: null,
  dueDate: null,
};

/**
 * All following fields are dependent on the merchant. If merchant
 * does not exist, return an empty
 * @param {{ merchant: string }} queryFilters
 */
function getMerchantDependentFilters(queryFilters) {
  if (queryFilters.merchant) {
    return {
      paymentType: queryFilters.paymentType || null,
      project: queryFilters.project || null,
      reportingDate: moment(queryFilters.reporting || null, dateFormat),
      dueDate: moment(queryFilters.due || null, dateFormat),
    };
  }
  return {
    paymentType: null,
    project: null,
    reportingDate: moment(null, dateFormat),
    dueDate: moment(null, dateFormat),
  };
}

function getInitialState(queryFilters) {
  const defaultOpts = {
    page: parseInt(queryFilters.page, 10) || 1,
    size: parseInt(queryFilters.size, 10) || 20,
    totalCount: 0,
  };

  // if there is "query" on the filters on load, ignore all others
  const searchOption = queryFilters.query ? {
    ...defaultOpts,
    query: queryFilters.query,
    status: [],
    startDate: moment(null, dateFormat),
    endDate: moment(null, dateFormat),
    paymentMethod: 'all',
    transactionType: 'all',
    merchant: null,
    paymentType: null,
    project: null,
    reportingDate: null,
    dueDate: null,
  } : {
    ...defaultOpts,
    query: null,
    status: queryFilters.status ? queryFilters.status.split(',') : defaultFilter.status,
    startDate: moment((queryFilters.start || defaultStartDate), dateFormat),
    endDate: moment((queryFilters.end || defaultEndDate), dateFormat),
    paymentMethod: queryFilters.method ? queryFilters.method : defaultFilter.paymentMethod,
    transactionType: queryFilters.transactionType ? queryFilters.transactionType : defaultFilter.transactionType,
    merchant: queryFilters.merchant || null,
    ...getMerchantDependentFilters(queryFilters),
  };

  return {
    isExporting: false,
    isFetchingPayments: true,
    message: null,
    status: null,
    payments: [],
    searchOption,
  };
}

function reducer(prevState, action) {
  switch (action.type) {
    case 'GET_PAYMENTS':
      return {
        ...prevState,
        isFetchingPayments: true,
      };
    case 'GET_PAYMENTS_SUCCESSFUL':
      return {
        ...prevState,
        isFetchingPayments: false,
        payments: action.payments,
        searchOption: {
          ...prevState.searchOption,
          totalCount: action.totalCount,
        },
      };
    case 'GET_PAYMENTS_FAILED':
      return {
        ...prevState,
        isFetchingPayments: false,
        payments: [],
        searchOption: {
          ...prevState.searchOption,
          totalCount: 0,
        },
        message: action.message,
        status: 'error',
      };
    case 'CLEAR_MESSAGE':
      return {
        ...prevState,
        status: null,
        message: null,
      };
    case 'SET_MESSAGE':
      return {
        ...prevState,
        status: action.status,
        message: action.message,
      };
    case 'EXPORTING_PAYMENTS':
      return {
        ...prevState,
        isExporting: true,
      };
    case 'EXPORTING_PAYMENTS_SUCCESSFUL':
      return {
        ...prevState,
        isExporting: false,
      };
    case 'EXPORTING_PAYMENTS_FAILED':
      return {
        ...prevState,
        isExporting: false,
      };
    case 'UPDATE_SEARCH_OPTION':
      return {
        ...prevState,
        searchOption: action.searchOption,
      };
    default:
      return prevState;
  }
}

/**
 * @param {string} path
 * @param {{ page: number, size: number, status: string[], query: string }} searchOption
 * @returns {string}
 */
function getFilteredURLPath(path, searchOption) {
  const {
    page, size, status, startDate, endDate, query,
    reportingDate, dueDate, project, merchant, paymentMethod,
    paymentType, transactionType,
  } = searchOption;
  let url = `${path}?page=${page || 1}`;
  if (size) { url += `&size=${size}`; }

  if (query) {
    url += `&query=${query}`;
  } else {
    if (startDate && startDate.isValid()) { url += `&start=${startDate.format(dateFormat)}`; }
    if (endDate && endDate.isValid()) { url += `&end=${endDate.format(dateFormat)}`; }
    if (status) { url += `&status=${status}`; }
    if (paymentMethod) { url += `&method=${paymentMethod}`; }
    if (transactionType) { url += `&transactionType=${transactionType}`; }
    if (merchant) {
      url += `&merchant=${merchant}`;
      if (project) { url += `&project=${project}`; }
      if (paymentType) { url += `&paymentType=${paymentType}`; }
      if (reportingDate && reportingDate.isValid()) { url += `&reporting=${reportingDate.format(dateFormat)}`; }
      if (dueDate && dueDate.isValid()) { url += `&due=${dueDate.format(dateFormat)}`; }
    }
  }
  return url;
}

function QwikwirePayments(props) {
  const timezone = moment.tz.guess(true);
  const { appState } = props;
  const location = useLocation();
  const history = useHistory();

  const queryFilters = qs.parse(location.search, { ignoreQueryPrefix: true });
  const [state, dispatch] = useReducer(reducer, getInitialState(queryFilters));
  const { searchOption } = state;

  useEffect(() => {
    let ableToSet = true;
    (async () => {
      dispatch({ type: 'GET_PAYMENTS' });
      try {
        const status = state.searchOption.status
          .reduce((pv, cv) => `${pv},${cv}`, '')
          .slice(1);
        const paymentsResponse = await searchPayments({
          ...state.searchOption,
          status,
        });
        const { payments, totalCount } = paymentsResponse.data;
        if (ableToSet) {
          dispatch({
            type: 'GET_PAYMENTS_SUCCESSFUL',
            payments,
            totalCount,
          });
        }
      } catch (error) {
        const message = error && error.response ? error.response.data.message
          : 'We are not able to get the payments from the server as of the moment';
        if (ableToSet) {
          dispatch({ type: 'GET_PAYMENTS_FAILED', message });
        }
      }
    })();

    return () => { ableToSet = false; };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    searchOption.page,
    searchOption.size,
    searchOption.status,
    searchOption.paymentMethod,
    searchOption.transactionType,
    searchOption.startDate,
    searchOption.endDate,
    searchOption.merchant,
    searchOption.project,
    searchOption.paymentType,
    searchOption.reportingDate,
    searchOption.dueDate,
    searchOption.query,
  ]);

  function onChangeSearchOption(opts) {
    const url = getFilteredURLPath(location.pathname, opts);
    history.push(url, {});
    dispatch({
      type: 'UPDATE_SEARCH_OPTION',
      searchOption: opts,
    });
  }

  function onApplyFilters(opts) {
    // I just need to convert the IDs back to string because
    // the prop types for PaymentsFilterModal are expecting it as string.
    onChangeSearchOption({
      ...state.searchOption,
      page: 1,
      startDate: opts.startDate,
      endDate: opts.endDate,
      status: opts.status,
      paymentMethod: opts.paymentMethod,
      transactionType: opts.transactionType,
      merchant: opts.merchantId ? opts.merchantId.toString() : null,
      project: opts.projectId ? opts.projectId.toString() : null,
      paymentType: opts.paymentTypeId ? opts.paymentTypeId.toString() : null,
      reportingDate: opts.reportAt,
      dueDate: opts.dueAt,
    });
  }

  function resetFiltersToDefault() {
    onChangeSearchOption({
      ...state.searchOption,
      ...defaultFilter,
    });
  }

  function onSearchByQuery(query) {
    if (!query) {
      resetFiltersToDefault();
      return;
    }

    onChangeSearchOption({
      ...state.searchOption,
      query,
      page: 1,
      startDate: moment(null, dateFormat),
      endDate: moment(null, dateFormat),
      status: [],
      merchant: null,
      project: null,
      paymentType: null,
      paymentMethod: 'all',
      transactionType: 'all',
      reportingDate: moment(null, dateFormat),
      dueDate: moment(null, dateFormat),
    });
  }

  const columns = [
    {
      title: 'Status',
      key: 'status',
      render: row => (
        <span
          style={{
            fontWeight: 700,
            color: '#ffffff',
            backgroundColor: getStatusColor(row.paymentStatus),
            borderRadius: '4px',
            padding: '4px 8px',
          }}
        >
          {row.paymentStatus}
        </span>
      ),
    },
    {
      title: 'Merchant',
      key: 'merchant',
      render: row => (
        <strong className="text-primary">{row.merchantName}</strong>
      ),
    },
    {
      title: 'Project',
      key: 'project',
      render: row => (
        <div className="text-secondary">{row.projectName}</div>
      ),
    },
    {
      title: 'Customer',
      key: 'customer',
      render: row => (
        <div>
          <div className="text-secondary">{row.customerName}</div>
        </div>
      ),
    },
    {
      title: 'Amount',
      key: 'amount',
      render: row => (
        <div style={{ display: 'flex' }}>
          <span style={{ width: 14.6, marginTop: 2, marginRight: 8 }} alt={row.transactionType}>
            {row.transactionType === 'PAYMENT'
              ? <CircleIcon diameter="14px" fill="#8ED140" />
              : <RepeatIcon diameter="16px" fill="#FFD426" />}
          </span>
          <div className="text-primary">
            {row.billBase
              ? `${row.billBase[0]} ${formatNumber(row.billBase[1])}`
              : '-----'}
          </div>
        </div>
      ),
    },
    {
      title: 'Date',
      key: 'createdAt',
      render: (row) => {
        const dt = row.paidAt || row.invoiceCreatedAt;
        return (
          <div className="table-col-item">
            <div className="text-primary">
              {moment(dt).tz(timezone).format('lll')}
            </div>
          </div>
        );
      },
    },
  ];

  const pagination = {
    size: 'small',
    current: state.searchOption.page,
    defaultCurrent: 1,
    defaultPageSize: 10,
    pageSize: state.searchOption.size,
    total: state.searchOption.totalCount,
    showSizeChanger: true,
    pageSizeOptions: ['10', '20', '50', '100'],
    onShowSizeChange: (currentPage, size) => {
      onChangeSearchOption({
        ...state.searchOption,
        page: 1,
        size,
      });
    },
    onChange: (p) => {
      const totalPage = Math.ceil(state.searchOption.totalCount / state.searchOption.size);
      let page = p || 1;
      if (page < 1) { page = 1; }
      if (page > totalPage) { page = totalPage - 1; }

      onChangeSearchOption({
        ...state.searchOption,
        page,
      });
    },
    showTotal: (total, range) => `Showing ${range[0]} - ${range[1]} of ${total}`,
  };

  async function exportPayments() {
    try {
      if (state.searchOption.totalCount === 0) {
        dispatch({
          type: 'SET_MESSAGE',
          status: 'error',
          message: 'You cannot export zero payments into a CSV file',
        });
        return;
      }

      dispatch({ type: 'EXPORTING_PAYMENTS' });
      const { data: { exportToken } } = await authenticateExportTokenService();
      exportPaymentsService(state.searchOption, exportToken);
      dispatch({ type: 'EXPORTING_PAYMENTS_SUCCESSFUL' });
    } catch (err) {
      const message = err && err.response
        ? err.response.data.message
        : 'Unable to export payments data as of the moment';
      dispatch({ type: 'EXPORTING_PAYMENTS_FAILED', message });
    }
  }

  const menu = (
    <Menu>
      <Menu.Item key="download" onClick={() => exportPayments()}>
        <Icon type="download" />
        Export to CSV
      </Menu.Item>
    </Menu>
  );

  if (appState < APP_STATES.AUTHENTICATED) { return <div />; }

  return (
    <div className="qwikwire">
      <section style={{ display: 'flex', justifyContent: 'space-between' }}>
        <div className="transaction-breadcrumb">
          <div className="transaction-breadcrumb-icon">
            <PaymentIcon />
          </div>
          <Breadcrumb>
            <Breadcrumb.Item>
              All Payments
            </Breadcrumb.Item>
          </Breadcrumb>
        </div>
        <div className="action-container">
          <Dropdown overlay={menu} trigger={['click']}>
            <Button
              type="primary"
              className="button button-standard button-standard-outline button-small"
            >
              Actions
              <Icon type="down" />
            </Button>
          </Dropdown>
        </div>
      </section>
      <Row
        type="flex"
        justify="space-between"
        align="middle"
        className="filters"
        style={{ marginBottom: '12px' }}
      >
        <Col lg={12}>
          {(() => {
            if (state.searchOption.query) {
              return (
                <h4 className="text-secondary">
                  Showing all payments for
                  {` "${state.searchOption.query}"`}
                </h4>
              );
            }
            return (
              <h4 className="text-secondary">
                Showing payments on
                {` ${state.searchOption.startDate.format(displayDateFormat)} - ${state.searchOption.endDate.format(displayDateFormat)}`}
              </h4>
            );
          })()}
        </Col>
        <Col lg={12}>
          <Row type="flex" justify="end">
            <Col style={{ marginRight: '16px' }}>
              {!state.searchOption.query && (
                // Hide the payments filter modal if the search query is available.
                // It will rerender and reset to default upon clear.
                <PaymentsFilterModal
                  onApply={onApplyFilters}
                  defaultOption={defaultFilter}
                  searchOption={state.searchOption}
                />
              )}
            </Col>
            <Col lg={12}>
              <Input.Search
                allowClear
                placeholder="Search by name or ID"
                style={{ width: '100%' }}
                defaultValue={state.searchOption.query}
                onChange={(e) => {
                  if (!e.target.value) { resetFiltersToDefault(); }
                }}
                onSearch={onSearchByQuery}
              />
            </Col>
          </Row>
        </Col>
      </Row>
      {state && state.status && state.message && (
        <div style={{ marginBottom: '12px' }}>
          <Row>
            <Col span={24}>
              <Alert
                message={<p style={{ marginBottom: 0 }}>{state.message}</p>}
                type={state.status}
                showIcon
                closable
                onClose={() => dispatch({ type: 'CLEAR_MESSAGE' })}
              />
            </Col>
          </Row>
        </div>
      )}
      <Table
        className="table-standard"
        dataSource={state.payments}
        loading={state.isFetchingPayments}
        columns={columns}
        pagination={pagination}
        rowKey="invoiceId"
        rowClassName="row-clickable"
        onRow={(row) => {
          const onClick = () => history.push(`/payments/${row.invoiceId}`);
          return { onClick };
        }}
      />
    </div>
  );
}

QwikwirePayments.propTypes = {
  appState: PropTypes.number.isRequired,
};

export default QwikwirePayments;
