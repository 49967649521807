import moment from 'moment';
import React, { Fragment, useReducer, useEffect } from 'react';
import PropTypes from 'prop-types';
import qs from 'query-string';
import {
  Table, Row, Col, Input, Alert, Breadcrumb, Icon,
  Menu, Dropdown, Button,
} from 'antd';
import EnrollmentsFilterModal from '../EnrollmentsFilterModal';
import { EnrollmentIcon } from '../Icons';
import { getEnrollmentsService, exportMerchantEnrollmentsService } from '../../services/enrollments';
import { authenticateMerchantExportTokenService } from '../../services/transactions';
import { formatNumber, getStatusColor, getDefaultCreatedDate } from '../../helpers/utils';
import '../../styles/transaction.css';
import './querybasedenrollmentstable.scss';

const dateFormat = 'YYYY-MM-DD';
const displayDateFormat = 'MMM DD, YYYY';
const { defaultEndDate, defaultStartDate } = getDefaultCreatedDate(dateFormat);

const defaultFilter = {
  page: 1,
  status: ['ONGOING'],
  startDate: defaultStartDate,
  endDate: defaultEndDate,
  query: null,
  project: null,
  paymentType: null,
};

function getInitialState(queryFilters) {
  const defaultOpts = {
    page: parseInt(queryFilters.page, 10) || 1,
    size: parseInt(queryFilters.size, 10) || 20,
    totalCount: 0,
  };

  // if there is "query" on the filters on load, ignore all others
  const searchOption = queryFilters.query ? {
    ...defaultOpts,
    query: queryFilters.query,
    status: [],
    startDate: moment(null, dateFormat),
    endDate: moment(null, dateFormat),
    paymentType: null,
    project: null,
  } : {
    ...defaultOpts,
    query: null,
    status: queryFilters.status?.split(',') || defaultFilter.status,
    startDate: moment(queryFilters.start || defaultStartDate, dateFormat),
    endDate: moment(queryFilters.end || defaultEndDate, dateFormat),
    paymentType: queryFilters.paymentType,
    project: queryFilters.project,
  };

  return {
    isFetchingEnrollments: true,
    isExporting: false,
    enrollments: [],
    searchOption,
    status: null,
    message: null,
  };
}

function reducer(prevState, action) {
  switch (action.type) {
    case 'GET_ENROLLMENTS':
      return {
        ...prevState,
        isFetchingEnrollments: true,
      };
    case 'GET_ENROLLMENTS_SUCCESSFUL':
      return {
        ...prevState,
        isFetchingEnrollments: false,
        enrollments: action.enrollments,
        searchOption: { ...prevState.searchOption, totalCount: action.totalCount },
      };
    case 'GET_ENROLLMENTS_FAILED':
      return {
        ...prevState,
        isFetchingEnrollments: false,
        enrollments: [],
        searchOption: {
          ...prevState.searchOption,
          totalCount: 0,
        },
        message: action.message,
        status: 'error',
      };
    case 'CLEAR_MESSAGE':
      return {
        ...prevState,
        status: null,
        message: null,
      };
    case 'SET_MESSAGE':
      return {
        ...prevState,
        status: action.status,
        message: action.message,
      };
    case 'UPDATE_SEARCH_OPTION':
      return {
        ...prevState,
        searchOption: action.searchOption,
      };
    case 'EXPORTING_ENROLLMENTS':
      return {
        ...prevState,
        isExporting: true,
      };
    case 'EXPORTING_ENROLLMENTS_SUCCESSFUL':
      return {
        ...prevState,
        isExporting: false,
      };
    case 'EXPORTING_ENROLLMENTS_FAILED':
      return {
        ...prevState,
        isExporting: false,
      };
    default:
      return prevState;
  }
}

const QueryBasedEnrollmentsTable = (props) => {
  const { location, merchant, history, onChangeSearchOption } = props;

  const queryFilters = qs.parse(location.search, { ignoreQueryPrefix: true });
  const timeZone = moment.tz.guess(true);
  const [state, dispatch] = useReducer(reducer, getInitialState(queryFilters));
  const { searchOption } = state;

  useEffect(() => {
    let ableToSet = true;

    (async () => {
      try {
        dispatch({ type: 'GET_ENROLLMENTS' });
        const status = state.searchOption.status
          .reduce((pv, cv) => `${pv},${cv}`, '')
          .slice(1);
        const enrollmentsResponse = await getEnrollmentsService(merchant.id, {
          ...state.searchOption,
          status,
        });
        const { enrollments, totalCount } = enrollmentsResponse.data;
        if (ableToSet) {
          dispatch({ type: 'GET_ENROLLMENTS_SUCCESSFUL', enrollments, totalCount });
        }
      } catch (error) {
        const message = error && error.response ? error.response.data.message
          : 'We are able to get the enrollments from the server as of the moment';
        if (ableToSet) {
          dispatch({ type: 'GET_ENROLLMENTS_FAILED', message });
        }
      }
    })();

    return () => { ableToSet = false; };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    searchOption.page,
    searchOption.size,
    searchOption.status,
    searchOption.startDate,
    searchOption.endDate,
    searchOption.project,
    searchOption.paymentType,
    searchOption.query,
  ]);

  function onApplyFilters(opts) {
    // I just need to convert the IDs back to string because
    // the prop types for PaymentsFilterModal are expecting it as string.
    const updatedOpts = {
      ...state.searchOption,
      page: 1,
      startDate: opts.startDate,
      endDate: opts.endDate,
      status: opts.status,
      project: opts.projectId ? opts.projectId.toString() : null,
      paymentType: opts.paymentTypeId ? opts.paymentTypeId.toString() : null,
    };
    onChangeSearchOption(updatedOpts);
    dispatch({ type: 'UPDATE_SEARCH_OPTION', searchOption: updatedOpts });
  }

  function resetFiltersToDefault() {
    const updatedOpts = {
      ...state.searchOption,
      ...defaultFilter,
    };
    onChangeSearchOption(updatedOpts);
    dispatch({ type: 'UPDATE_SEARCH_OPTION', searchOption: updatedOpts });
  }

  function onSearchByQuery(query) {
    if (!query) {
      resetFiltersToDefault();
      return;
    }

    const updatedOpts = {
      ...state.searchOption,
      query,
      page: 1,
      startDate: moment(null, dateFormat),
      endDate: moment(null, dateFormat),
      status: [],
      project: null,
      paymentType: null,
    };
    onChangeSearchOption(updatedOpts);
    dispatch({ type: 'UPDATE_SEARCH_OPTION', searchOption: updatedOpts });
  }

  async function exportEnrollments() {
    try {
      if (state.searchOption.totalCount === 0) {
        dispatch({
          type: 'SET_MESSAGE',
          status: 'error',
          message: 'You cannot export zero enrollments into a CSV file',
        });
        return;
      }

      dispatch({ type: 'EXPORTING_ENROLLMENTS' });
      const { data: { exportToken } } = await authenticateMerchantExportTokenService(merchant.id);
      exportMerchantEnrollmentsService(merchant, searchOption, exportToken);
      dispatch({ type: 'EXPORTING_ENROLLMENTS_SUCCESSFUL' });
    } catch (err) {
      const message = err && err.response
        ? err.response.data.message
        : 'Unable to export enrollments data as of the moment';
      dispatch({ type: 'EXPORTING_ENROLLMENTS_FAILED', message });
    }
  }

  const columns = [
    {
      title: 'Status',
      key: 'paymentStatus',
      render: row => (
        <div>
          <span
            style={{
              fontWeight: 700,
              color: '#ffffff',
              backgroundColor: getStatusColor(row.transactionStatus),
              borderRadius: '4px',
              padding: '4px 8px',
            }}
          >
            {row.transactionStatus}
          </span>
        </div>
      ),
    }, {
      title: 'Customer',
      key: 'customer',
      render: row => (
        <div className="text-primary">{row.customerName || '-----'}</div>
      ),
    }, {
      title: 'Project',
      key: 'project',
      render: row => (
        <div className="text-secondary">
          {(({ projectName, projectCategory }) => {
            if (projectName && projectCategory) { return `${projectCategory} - ${projectName}`; }
            if (projectName) { return projectName; }
            return '----';
          })(row)}
        </div>
      ),
    }, {
      title: 'Amount',
      render: row => (
        <div className="text-primary">
          {row.baseAmount
            ? `${row.baseAmount[0]} ${formatNumber(row.baseAmount[1])}`
            : '-----'}
        </div>
      ),
    }, {
      title: 'Enrollment Date',
      key: 'createdAt',
      render: row => (
        <div className="text-primary">
          {moment(row.createdAt).tz(timeZone).format('lll')}
        </div>
      ),
    },
  ];

  const pagination = {
    size: 'small',
    current: state.searchOption.page,
    defaultCurrent: 1,
    defaultPageSize: 10,
    pageSize: state.searchOption.size,
    total: state.searchOption.totalCount,
    showSizeChanger: true,
    pageSizeOptions: ['10', '20', '50', '100'],
    onShowSizeChange: (currentPage, size) => {
      const updatedOpts = {
        ...state.searchOption,
        page: 1,
        size: Number(size),
      };
      onChangeSearchOption(updatedOpts);
      dispatch({ type: 'UPDATE_SEARCH_OPTION', searchOption: updatedOpts });
    },
    onChange: (p) => {
      const totalPage = Math.ceil(state.searchOption.totalCount / state.searchOption.size);
      let page = p || 1;
      if (page < 1) page = 1;
      if (page > totalPage) { page = totalPage - 1; }

      const updatedOpts = {
        ...state.searchOption,
        page,
      };
      onChangeSearchOption(updatedOpts);
      dispatch({ type: 'UPDATE_SEARCH_OPTION', searchOption: updatedOpts });
    },
    showTotal: (total, range) => `Showing ${range[0]} - ${range[1]} of ${total}`,
  };

  const actionMenu = (
    <Menu>
      <Menu.Item key="1" onClick={exportEnrollments} disabled={state.isExporting}>
        <Icon type="download" />
        Export as CSV
      </Menu.Item>
    </Menu>
  );

  return (
    <Fragment>
      <Row className="breadcrumb-row">
        <Col className="breadcrumb-header">
          <Breadcrumb className="breadcrumb-parent">
            <Breadcrumb.Item className="item-container">
              <div className="breadcrumb-item breadcrumb-icon">
                <EnrollmentIcon />
              </div>
              <div className="breadcrumb-item icon-label">
                Enrollments
              </div>
            </Breadcrumb.Item>
          </Breadcrumb>
          <div className="action-container">
            <Dropdown overlay={actionMenu} trigger={['click']}>
              <Button
                type="primary"
                className="button button-standard button-standard-outline button-small"
              >
                Actions
                <Icon type="down" />
              </Button>
            </Dropdown>
          </div>
        </Col>
      </Row>
      <Row
        type="flex"
        justify="space-between"
        align="middle"
        className="filters"
        style={{ marginBottom: '12px' }}
      >
        <Col lg={12}>
          {(() => {
            if (state.searchOption.query) {
              return (
                <h4 className="text-secondary">
                  Showing all enrollments for
                  {` "${state.searchOption.query}"`}
                </h4>
              );
            }
            return (
              <h4 className="text-secondary">
                Showing enrollments on
                {` ${state.searchOption.startDate.format(displayDateFormat)} - ${state.searchOption.endDate.format(displayDateFormat)}`}
              </h4>
            );
          })()}
        </Col>
        <Col lg={12}>
          <Row type="flex" justify="end">
            <Col style={{ marginRight: '16px' }}>
              {!state.searchOption.query && (
                <EnrollmentsFilterModal
                  onApply={onApplyFilters}
                  merchant={merchant}
                  defaultOption={defaultFilter}
                  searchOption={state.searchOption}
                />
              )}
            </Col>
            <Col lg={12}>
              <Input.Search
                allowClear
                placeholder="Search by name or ID"
                style={{ width: '100%' }}
                defaultValue={state.searchOption.query}
                onChange={(e) => {
                  if (!e.target.value) { resetFiltersToDefault(); }
                }}
                onSearch={onSearchByQuery}
              />
            </Col>
          </Row>
        </Col>
      </Row>
      {state && state.status && state.message && (
        <div style={{ marginBottom: '12px' }}>
          <Row>
            <Col span={24}>
              <Alert
                message={<p style={{ marginBottom: 0 }}>{state.message}</p>}
                type={state.status}
                showIcon
                closable
                onClose={() => dispatch({ type: 'CLEAR_MESSAGE' })}
              />
            </Col>
          </Row>
        </div>
      )}
      <Table
        className="table-standard enrollments-table"
        dataSource={state.enrollments}
        loading={state.isFetchingEnrollments}
        columns={columns}
        rowKey="transactionId"
        pagination={pagination}
        onRow={row => ({
          onClick: () => {
            history.push(`/merchants/${merchant.code}/enrollments/${row.externalTransactionId}`);
          },
        })}
      />
    </Fragment>
  );
};

QueryBasedEnrollmentsTable.propTypes = {
  merchant: PropTypes.shape({
    id: PropTypes.number.isRequired,
    code: PropTypes.string.isRequired,
  }).isRequired,
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }).isRequired,
  location: PropTypes.shape({
    pathname: PropTypes.string.isRequired,
    search: PropTypes.string.isRequired,
  }).isRequired,
  onChangeSearchOption: PropTypes.func,
};

QueryBasedEnrollmentsTable.defaultProps = {
  onChangeSearchOption: () => {},
};

export default QueryBasedEnrollmentsTable;
