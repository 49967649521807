const apiBaseUrl = process.env.REACT_APP_API_BASE_URL || 'http://localhost:8021/xqwapi';
module.exports = {
  apiBaseUrl,
  exportUrl: process.env.REACT_APP_EXPORT_URL || apiBaseUrl,
  zxcvbnMinScore: parseInt(process.env.REACT_APP_ZXCVBN_MIN_SCORE || 3, 10),
  googleAnalyticsTrackingId: process.env.REACT_APP_GOOGLE_ANALYTICS_TRACKING_ID || '',
  portalsPrefix: process.env.REACT_APP_PORTALS_URL_PREFIX || '',
  environment: process.env.NODE_ENV || 'development',
  beaconSecretKey: process.env.REACT_APP_BEACON_SECRET_KEY || '',
};
