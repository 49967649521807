import moment from 'moment';
import React, { useReducer, Fragment, useContext } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { Row, Col, Breadcrumb, DatePicker, Button, Table } from 'antd';

import { getPaymentMethodIssuersReport } from '../../services/reports';
import SingleForm from '../../components/SingleForm';
import { PieChartIcon, FolderIcon } from '../../components/Icons';
import { getAPIErrorMessage, formatNumber } from '../../helpers/utils';
import SessionContext from '../../contexts/SessionContext';

/**
 * @param {{ data: { label: String, items: any[] } }} props
 */
function StatsColumn(props) {
  const { data, name } = props;
  if (!data) {
    return (
      <Fragment>
        <h3>{name}</h3>
        <div>0 / 0</div>
      </Fragment>
    );
  }
  const { label, items } = data;
  return (
    <Fragment key={label}>
      <h3>
        {items
          .map(item => item[0])
          .reduce((pv, cv) => `${pv} / ${cv}`)}
      </h3>
      <div>
        {items
          .map(item => item[1])
          .reduce((pv, cv) => `${pv} / ${cv}`)}
      </div>
    </Fragment>
  );
}

StatsColumn.propTypes = {
  name: PropTypes.string.isRequired,
  data: PropTypes.shape({
    label: PropTypes.string.isRequired,
    items: PropTypes.arrayOf(PropTypes.any).isRequired,
  }).isRequired,
};

/**
 * @param {{
 *   merchants: {id: Number, code: String}[],
 *   match: { params: { merchantCode: String } }
 * }} props
 */
function ReportPaymentIssuers(props) {
  const { match } = props;
  const { merchantCode } = match.params;
  const sessionContext = useContext(SessionContext);
  const { merchants, loggedInUser } = sessionContext;
  const merchant = merchants.find(m => m.code === merchantCode);
  const dateFormat = 'YYYY/MM/DD';

  const [state, dispatch] = useReducer((prevState, action) => {
    switch (action.type) {
      case 'UPDATE_DATES':
        return {
          ...prevState,
          startDate: action.startDate,
          endDate: action.endDate,
        };
      case 'GENERATE_REPORT':
        return {
          ...prevState,
          isGenerating: true,
        };
      case 'GENERATE_REPORT_SUCCESSFUL':
        return {
          ...prevState,
          isGenerating: false,
          issuers: action.issuers,
          stats: action.stats,
          status: 'success',
          message: null,
        };
      case 'GENERATE_REPORT_FAILED':
        return {
          ...prevState,
          isGenerating: false,
          issuers: [],
          stats: {},
          status: 'error',
          message: action.message,
        };
      default:
        return prevState;
    }
  }, {
    isGenerating: false,
    issuers: [],
    stats: {},
    status: null,
    message: null,
    startDate: moment().subtract(7, 'days'),
    endDate: moment(),
  });

  if (!merchant || !merchant.canAccessReports) {
    const errorMessage = merchant && !merchant.canAccessReports ? 'Page not found' : 'Merchant does not exist';
    return (
      <SingleForm
        title={errorMessage}
        subtitle="The page you requested could not be found. Please go back to Dashboard or contact us at support@aqwire.io."
        footer={{ show: false }}
      />
    );
  }

  const { systemRole, merchantRoles } = loggedInUser;
  const { merchantRole } = merchantRoles.find(m => m.merchantCode === merchantCode);

  if (systemRole[0] >= 80
    || (systemRole[0] === 70 && merchantRole[0] > 10)
    || !merchant
  ) {
    return (
      <div>
        <div className="spinner">
          <FolderIcon />
          <h1>You are not allowed to access the merchant settings page.</h1>
          <p>
            You can contact us at
            <a href="mailto:support@aqwire.io">support@aqwire.io</a>
            if you have concerns or issues.
          </p>
        </div>
      </div>
    );
  }

  async function generateReport() {
    if (!merchant || !merchant.canAccessReports) {
      dispatch({ type: 'GENERATE_REPORT_FAILED',
        message: 'We are not able to generate the report. Please try again later.' });
    }
    try {
      dispatch({ type: 'GENERATE_REPORT' });
      const options = {
        startDate: state.startDate,
        endDate: state.endDate,
      };
      const response = await getPaymentMethodIssuersReport(merchant.id, options);
      const { issuers, stats } = response.data;
      dispatch({ type: 'GENERATE_REPORT_SUCCESSFUL', issuers, stats });
    } catch (error) {
      const message = getAPIErrorMessage(error,
        'We are not able to generate the report. Please try again later.');
      dispatch({ type: 'GENERATE_REPORT_FAILED', message });
    }
  }


  const statHeaders = Object.keys(state.stats).map(key => state.stats[key].items).flat();
  return (
    <div>
      <Row className="breadcrumb-row">
        <Col className="breadcrumb-header">
          <Breadcrumb className="breadcrumb-parent">
            <Breadcrumb.Item className="item-container">
              <div className="breadcrumb-item breadcrumb-icon">
                <PieChartIcon diameter="28px" />
              </div>
              <div className="breadcrumb-item icon-label">
                Reports
              </div>
            </Breadcrumb.Item>
          </Breadcrumb>
          <Breadcrumb separator=">">
            <Breadcrumb.Item className="breadcrumb-item">
              <Link to={`/merchants/${merchant.code}/reports`}>
                <span>Reports</span>
              </Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item>
              <span>Payment Method Issuers</span>
            </Breadcrumb.Item>
          </Breadcrumb>
        </Col>
      </Row>
      <section style={{ margin: '12px 0 20px' }}>
        <Row gutter={8} className="filters">
          <Col lg={6}>
            <DatePicker.RangePicker
              className="transaction-filter"
              defaultValue={[
                state.startDate.isValid() ? state.startDate : null,
                state.endDate.isValid() ? state.endDate : null,
              ]}
              format={dateFormat}
              style={{ width: '100%' }}
              onChange={([start, end]) => {
                const startDate = start ? start.format(dateFormat) : null;
                const endDate = end ? end.format(dateFormat) : null;
                dispatch({
                  type: 'UPDATE_DATES',
                  startDate: moment(startDate, dateFormat),
                  endDate: moment(endDate, dateFormat),
                });
              }}
            />
          </Col>
          <Col lg={12} />
          <Col lg={6} style={{ display: 'flex', justifyContent: 'flex-end' }}>
            <Button
              className="button button-standard button-standard-outline"
              loading={state.isGenerating}
              onClick={generateReport}
            >
              Generate Report
            </Button>
          </Col>
        </Row>
      </section>
      {statHeaders.length > 0 && (
        <div style={{ marginBottom: '12px' }}>
          <section className="panel panel-standard">
            <div className="panel-header">
              <h1 style={{ fontSize: '20px', marginBottom: '8px' }}>Statistics</h1>
              <Row>
                {statHeaders.map(([label, value]) => (
                  <Col lg={6} className="panel-item">
                    <h3>{label}</h3>
                    <div>{value}</div>
                  </Col>
                ))}
              </Row>
            </div>
          </section>
        </div>
      )}
      <Table
        className="table-standard"
        dataSource={state.issuers}
        loading={state.isGenerating}
        columns={[
          {
            title: 'Method',
            key: 'method',
            render: item => (
              <div>
                {((method) => {
                  switch (method) {
                    case 'cc': return 'Credit Card';
                    case 'ach': return 'Bank Transfer (ACH)';
                    case 'pp': return 'PayPal';
                    default: return 'Unknown';
                  }
                })(item.method)}
              </div>
            ),
          },
          {
            title: 'Origin',
            key: 'origin',
            render: item => (
              <div>{item.origin || 'Unknown'}</div>
            ),
          },
          {
            title: 'Provider',
            key: 'provider',
            render: item => (
              <div>{item.provider || 'Unknown'}</div>
            ),
          },
          {
            title: 'Amount',
            key: 'amount',
            render: item => (
              <div>{`${item.currency} ${formatNumber(item.amount)}`}</div>
            ),
          },
          {
            title: 'Total Count',
            key: 'totalCount',
            render: item => (
              <div>{item.count}</div>
            ),
          },
        ]}
        pagination={{
          size: 'small',
          defaultCurrent: 1,
          defaultPageSize: 10,
        }}
        rowKey="key"
      />
    </div>
  );
}

ReportPaymentIssuers.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      merchantCode: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
};

export default ReportPaymentIssuers;
