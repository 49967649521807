import React, { useContext, useReducer, useEffect } from 'react';
import PropTypes from 'prop-types';
import qs from 'query-string';
import { useLocation, Link } from 'react-router-dom';
import { Avatar, Table, Form, Row, Col, Input, Select } from 'antd';
import { getMerchantsService } from '../../services/merchants';
import SessionContext from '../../contexts/SessionContext';
import { getAPIErrorMessage } from '../../helpers/utils';
import './merchants-table.scss';

const getInitialState = queryFilters => ({
  isFetchingMerchants: true,
  merchants: [],
  merchant: {},
  searchOption: {
    totalCount: 0,
    size: parseInt(queryFilters.size, 10) || 10,
    page: parseInt(queryFilters.page, 10) || 1,
    query: queryFilters.query,
    type: queryFilters.type,
    category: queryFilters.category,
  },
  status: null,
  message: null,
});

function QueryBasedMerchantsTable(props) {
  const { onChangeSearchFunction } = props;
  const location = useLocation();
  const queryFilters = qs.parse(location.search, { ignoreQueryPrefix: true });
  const { merchantCategories } = useContext(SessionContext);

  const [state, dispatch] = useReducer((prevState, action) => {
    switch (action.type) {
      case 'GET_MERCHANTS_SUCCESSFUL':
        return {
          ...prevState,
          isFetchingMerchants: false,
          merchants: action.merchants,
          searchOption: {
            ...prevState.searchOption,
            totalCount: action.totalCount,
          },
        };
      case 'GET_MERCHANTS_FAILED':
        return {
          ...prevState,
          isFetchingMerchants: false,
          message: action.message,
        };

      default:
        return prevState;
    }
  }, getInitialState(queryFilters));

  const merchantTypes = [
    { label: 'Wiremo', value: 'wiremo' },
    { label: 'Aqwire', value: 'aqwire' },
  ];

  useEffect(() => {
    let ableToSet = true;
    (async () => {
      try {
        const { data: { merchants, totalCount } } = await getMerchantsService({
          query: state.searchOption.query,
          type: state.searchOption.type,
          category: state.searchOption.category,
          size: parseInt(queryFilters.size, 10) || 10,
          page: parseInt(queryFilters.page, 10) || 1,
        });
        if (ableToSet) {
          dispatch({ type: 'GET_MERCHANTS_SUCCESSFUL', merchants, totalCount });
        }
      } catch (error) {
        if (ableToSet) {
          const message = getAPIErrorMessage(error,
            'We are not able to load the merchants. Please try again later.');
          dispatch({
            type: 'GET_MERCHANTS_FAILED',
            message,
          });
        }
      }
    })();

    return () => { ableToSet = false; };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const columns = [
    {
      title: 'Name',
      key: 'name',
      render: row => (
        <div className="table-col-item">
          <div className="table-col-item-img">
            {row.logo ? <Avatar src={row.logo} alt="logo" /> : <Avatar>{row.name[0]}</Avatar>}
          </div>
          <Link to={`merchants/${row.code}`} rel="noopener noreferrer" target="_self">
            <div style={{ marginTop: '12px' }}>{row.name}</div>
          </Link>
        </div>
      ),
    }, {
      title: 'Type',
      render: row => (
        <div>{row.type}</div>
      ),
    }, {
      title: 'Category',
      render: row => (
        <div>{row.categoryDescription}</div>
      ),
    }, {
      title: 'Owner',
      render: row => (
        <div>{row.ownerName && row.ownerName.trim() ? row.ownerName : 'Unknown'}</div>
      ),
    }, {
      title: 'Status',
      render: row => (
        <div>{row.status[2]}</div>
      ),
    },
  ];

  const category = merchantCategories.find(c => c.id === Number(state.searchOption.category));

  const paginationSettings = {
    size: 'small',
    current: state.searchOption.page,
    defaultCurrent: 1,
    defaultPageSize: 10,
    pageSize: state.searchOption.size,
    total: state.searchOption.totalCount,
    showSizeChanger: true,
    pageSizeOptions: ['10', '20', '50', '100'],
    onShowSizeChange: (_, size) => {
      onChangeSearchFunction({
        ...state.searchOption,
        page: 1,
        size: Number(size),
      });
    },
    onChange: (p) => {
      const totalPage = Math.ceil(state.searchOption.totalCount / state.searchOption.size);
      let page = p || 1;
      if (page < 1) { page = 1; }
      if (page > totalPage) { page = totalPage - 1; }

      onChangeSearchFunction({
        ...state.searchOption,
        page,
      });
    },
    showTotal: (total, range) => `Showing ${range[0]} - ${range[1]} of ${total}`,
  };
  return (
    <div className="qwikwire">
      <Row gutter={10} className="filters" style={{ marginBottom: '14px' }}>
        <Col lg={8}>
          <div className="dropdown-container">
            <h4 className="text-secondary">Name</h4>
            <Input.Search
              allowClear
              className="transaction-filter"
              placeholder="Search by name"
              defaultValue={state.searchOption.query}
              onSearch={(query) => {
                onChangeSearchFunction({
                  ...state.searchOption,
                  query,
                  page: 1,
                });
              }}
            />
          </div>
        </Col>
        <Col lg={6}>
          <div className="dropdown-container">
            <h4 className="text-secondary">Type</h4>
            <Select
              className="transaction-filter"
              allowClear
              disabled={state.isFetching}
              defaultValue={state.searchOption.type}
              placeholder="Type"
              filterOption={(inputValue, option) => {
                const { props: { children } } = option;
                return children.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1;
              }}
              onSelect={(selectedType) => {
                const foundType = merchantTypes.find(t => t.value === selectedType);
                onChangeSearchFunction({
                  ...state.searchOption,
                  page: 1,
                  type: foundType ? foundType.value : undefined,
                });
              }}
              onChange={(selectedType) => {
                if (!selectedType) {
                  onChangeSearchFunction({
                    ...state.searchOption,
                    page: 1,
                    type: undefined,
                  });
                }
              }}
            >
              {merchantTypes.map(t => (
                <Select.Option key={t.value} value={t.value} label={t.label}>
                  {t.label}
                </Select.Option>
              ))}
            </Select>
          </div>
        </Col>
        <Col lg={6}>
          <div className="dropdown-container">
            <h4 className="text-secondary">Category</h4>
            <Select
              className="transaction-filter"
              allowClear
              showSearch
              listHeight={100}
              disabled={state.isFetching}
              defaultValue={category ? category.id : undefined}
              placeholder="Industry"
              filterOption={(inputValue, option) => {
                const { props: { children } } = option;
                return children.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1;
              }}
              onSelect={(selectedCategory) => {
                const foundCategory = merchantCategories.find(c => c.id === selectedCategory);
                onChangeSearchFunction({
                  ...state.searchOption,
                  page: 1,
                  category: foundCategory ? foundCategory.id : undefined,
                });
              }}
              onChange={(selectedCategory) => {
                if (!selectedCategory) {
                  onChangeSearchFunction({
                    ...state.searchOption,
                    page: 1,
                    category: undefined,
                  });
                }
              }}
            >
              {merchantCategories.map(c => (
                <Select.Option key={c.id} value={c.id} label={c.description}>
                  {c.description}
                </Select.Option>
              ))}
            </Select>
          </div>
        </Col>
      </Row>
      <Table
        className="table-standard"
        dataSource={state.merchants}
        columns={columns}
        style={{ marginBottom: 15 }}
        rowKey="code"
        pagination={paginationSettings}
        loading={state.isFetchingMerchants}
      />
    </div>
  );
}

QueryBasedMerchantsTable.propTypes = {
  onChangeSearchFunction: PropTypes.func.isRequired,
  form: PropTypes.shape({
    getFieldDecorator: PropTypes.func.isRequired,
    setFields: PropTypes.func.isRequired,
    validateFields: PropTypes.func.isRequired,
  }).isRequired,
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }).isRequired,
};

export default Form.create()(QueryBasedMerchantsTable);
