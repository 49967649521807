import React from 'react';
import PropTypes from 'prop-types';
import { Switch, Route } from 'react-router-dom';
import SignUp from '../screens/SignUp';
import ForgotPassword from '../screens/ForgotPassword';
import Home from '../screens/Home';
import LogIn from '../screens/LogIn';
import PasswordReset from '../screens/PasswordReset';
import NotFound from '../screens/NotFound';
import withTracker from './withTracker';


function AuthenticatedLayout(props) {
  const { user, merchants, appState } = props;
  return (
    <Switch>
      <Route exact path="/" component={withTracker(ownProps => <Home user={user} merchants={merchants} appState={appState} {...ownProps} />)} />
      <Route exact path="/login" component={withTracker(ownProps => <LogIn {...ownProps} />)} />
      <Route exact path="/signup/:encodedEmail" component={withTracker(ownProps => <SignUp {...ownProps} />)} />
      <Route exact path="/forgot-password" component={withTracker(ownProps => <ForgotPassword {...ownProps} />)} />
      <Route exact path="/password-reset/:resetId/:token" component={withTracker(ownProps => <PasswordReset {...ownProps} />)} />
      <Route path="*" component={withTracker(ownProps => <NotFound {...ownProps} />)} />
    </Switch>
  );
}

AuthenticatedLayout.propTypes = {
  appState: PropTypes.number.isRequired,
  user: PropTypes.shape({
    id: PropTypes.number,
  }),
  merchants: PropTypes.arrayOf(PropTypes.any),
};

AuthenticatedLayout.defaultProps = {
  user: null,
  merchants: [],
};

export default AuthenticatedLayout;
