import React, { useEffect, useReducer, useContext } from 'react';
import PropTypes from 'prop-types';
import qs from 'query-string';
import { Link } from 'react-router-dom';
import { Breadcrumb, Button, Icon, Input, Select, Switch, Table, Row, Col, Menu, Dropdown, Modal } from 'antd';
import { getUsersService, sendInviteService } from '../../services/users';
import { getMerchantsService, updateMerchantOwnerService, getMerchantById, getMerchantByCode } from '../../services/merchants';
import { UsersIconBig, NoEntryIcon, CheckCircleIcon, TimesCircleIcon } from '../../components/Icons';
import SessionContext from '../../contexts/SessionContext';
import { getAPIErrorMessage } from '../../helpers/utils';
import './users.css';

function getFilteredURLPath(path, opts) {
  const { page, scope, systemRole, searchQuery, showDeactivated } = opts;
  let url = `${path}?page=${page || 1}`;
  if (scope) { url += `&scope=${scope}`; }
  if (systemRole) { url += `&systemrole=${systemRole}`; }
  if (showDeactivated) { url += `&deactivated=${showDeactivated}`; }
  if (searchQuery) { url += `&query=${searchQuery}`; }
  return url;
}

const Users = (props) => {
  const { history, location } = props;
  const { loggedInUser, systemRoles, showToast } = useContext(SessionContext);

  const queryFilters = qs.parse(location.search, { ignoreQueryPrefix: true });
  const [state, dispatch] = useReducer((prevState, action) => {
    switch (action.type) {
      case 'GET_USERS':
        return {
          ...prevState,
          isFetching: true,
        };
      case 'GET_USERS_SUCCESSFUL':
        return {
          ...prevState,
          isFetching: false,
          users: action.users,
          searchOption: {
            ...prevState.searchOption,
            totalCount: action.totalCount,
          },
        };
      case 'GET_USERS_FAILED':
        return {
          ...prevState,
          isFetching: false,
          users: [],
          searchOption: {
            ...prevState.searchOption,
            totalCount: 0,
          },
        };
      case 'SHOW_SET_OWNER_MODAL':
        return {
          ...prevState,
          isSetOwnerModalVisible: true,
          selectedUser: action.selectedUser,
        };
      case 'HIDE_SET_OWNER_MODAL':
        return {
          ...prevState,
          isSetOwnerModalVisible: false,
        };
      case 'SET_SELECTED_MERCHANT_FILTER':
        return {
          ...prevState,
          selectedMerchantFilter: action.selectedMerchantFilter,
        };
      case 'SET_SELECTED_MERCHANT_CHANGE_OWNER':
        return {
          ...prevState,
          selectedMerchantChangeOwner: action.selectedMerchantChangeOwner,
        };
      case 'SET_OWNER':
        return {
          ...prevState,
          isSettingOwner: true,
        };
      case 'SET_OWNER_SUCCESSFUL':
      case 'SET_OWNER_FAILED':
        return {
          ...prevState,
          isSettingOwner: false,
        };
      case 'INITIALIZE_FILTERS':
        return {
          ...prevState,
          merchants: action.merchants,
          merchant: (() => {
            if (!prevState.merchant) { return null; }
            return action.merchants.find(m => m.code === prevState.merchant.code);
          })(),
        };
      default:
        return prevState;
    }
  }, {
    users: [],
    merchants: [],
    selectedUser: {
      firstName: null,
      lastName: null,
      email: null,
      scopes: [],
      id: null,
    },
    selectedMerchantFilter: {
      code: null,
      id: null,
      name: null,
      ownerId: null,
    },
    selectedMerchantChangeOwner: {
      code: null,
      id: null,
      name: null,
      ownerId: null,
    },
    isSettingOwner: false,
    isFetching: false,
    isSetOwnerModalVisible: false,
    searchOption: {
      totalCount: 0,
      page: parseInt(queryFilters.page, 10) || 1,
      scope: queryFilters.scope,
      systemRole: queryFilters.systemrole || null,
      showDeactivated: Boolean(queryFilters.deactivated) || false,
      searchQuery: queryFilters.query || null,
    },
  });

  useEffect(() => {
    let ableToSet = true;

    (async () => {
      try {
        if (state.searchOption.selectedMerchantFilter) {
          const { data } = await getMerchantById(state.searchOption.selectedMerchantFilter.id);
          if (ableToSet) {
            dispatch({ type: 'SET_SELECTED_MERCHANT_FILTER', selectedMerchantFilter: data });
            dispatch({ type: 'INITIALIZE_FILTERS', merchants: [data] });
          }
        } else {
          dispatch({ type: 'SET_SELECTED_MERCHANT_FILTER',
            selectedMerchantFilter: {
              code: null,
              id: null,
              name: null,
              ownerId: null,
            } });
        }
      } catch (error) {
        if (ableToSet) {
          dispatch({ type: 'SET_SELECTED_MERCHANT_FILTER',
            selectedMerchantFilter: {
              code: null,
              id: null,
              name: null,
              ownerId: null,
            } });
        }
      }
    })();

    return () => { ableToSet = false; };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.searchOption.scope]);

  useEffect(() => {
    let ableToSet = true;

    (async () => {
      try {
        if (state.selectedMerchantChangeOwner.code) {
          const { data } = await getMerchantByCode(state.selectedMerchantChangeOwner.code);
          if (ableToSet) {
            dispatch({ type: 'SET_SELECTED_MERCHANT_CHANGE_OWNER', selectedMerchantChangeOwner: data });
          }
        } else {
          dispatch({ type: 'SET_SELECTED_MERCHANT_CHANGE_OWNER',
            selectedMerchantChangeOwner: {
              code: null,
              id: null,
              name: null,
              ownerId: null,
            } });
        }
      } catch (error) {
        if (ableToSet) {
          dispatch({ type: 'SET_SELECTED_MERCHANT_CHANGE_OWNER',
            selectedMerchantChangeOwner: {
              code: null,
              id: null,
              name: null,
              ownerId: null,
            } });
        }
      }
    })();

    return () => { ableToSet = false; };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.selectedMerchantChangeOwner.code]);

  useEffect(() => {
    let ableToSet = true;
    (async () => {
      if (!loggedInUser) { return; }
      try {
        dispatch({ type: 'GET_USERS' });
        const response = await getUsersService({
          ...state.searchOption,
          scope: state.searchOption.scope,
          systemRole: getSystemRoleId(state.searchOption.systemRole),
        });
        if (ableToSet) {
          const { data: { users, totalCount } } = response;
          dispatch({
            type: 'GET_USERS_SUCCESSFUL',
            users,
            totalCount,
          });
        }
      } catch (error) {
        const message = error && error.response ? error.response.data.message
          : 'We are not able to get the users from the server as of the moment';
        dispatch({ type: 'GET_USERS_FAILED', message });
      }
    })();
    return () => { ableToSet = false; };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function getSystemRoleId(systemRole) {
    const found = systemRoles.find(rs => rs.code === systemRole);
    return found ? found.code : null;
  }

  function onChangeSearchOption(searchOption) {
    const url = getFilteredURLPath(location.pathname, searchOption);
    history.push(url, {});
  }

  async function setUserAsOwner(merchantId, userId) {
    dispatch({ type: 'SET_OWNER' });
    try {
      const response = await updateMerchantOwnerService(merchantId, userId);
      const { data: { message } } = response;
      dispatch({ type: 'SET_OWNER_SUCCESSFUL' });
      showToast({ type: 'success', message });
      history.push('/users');
    } catch (error) {
      const message = getAPIErrorMessage(error,
        'We are not able to set this user as the merchant owner. Please try again later');
      dispatch({ type: 'SET_OWNER_FAILED', message });
      showToast({ message, type: 'error' });
      dispatch({ type: 'HIDE_SET_OWNER_MODAL' });
    }
  }

  async function sendInvite(userEmail) {
    try {
      const response = await sendInviteService(userEmail);
      const { data: { message } } = response;
      showToast({ type: 'success', message });
    } catch (error) {
      showToast({
        message: 'Unable to send invite as of the moment',
        type: 'error',
      });
    }
  }

  function onSelectMerchant(merchantCode) {
    if (!merchantCode) {
      dispatch({ type: 'SET_SELECTED_MERCHANT_FILTER',
        selectedMerchantFilter: {
          code: null,
          id: null,
          name: null,
          ownerId: null,
        } });
      onChangeSearchOption({
        ...state.searchOption,
        page: 1,
        scope: undefined,
      });
    } else {
      const foundMerchant = state.merchants.find(ps => ps.code === merchantCode);
      dispatch({ type: 'SET_SELECTED_MERCHANT_FILTER', selectedMerchantFilter: foundMerchant });
      onChangeSearchOption({
        ...state.searchOption,
        page: 1,
        scope: foundMerchant ? foundMerchant.code : undefined,
      });
    }
  }

  let timeout;
  let currentValue;

  async function onSearchMerchant(merchantCode) {
    if (!merchantCode) {
      dispatch({ type: 'SET_MERCHANT',
        selectedMerchantFilter: {
          code: null,
          id: null,
          name: null,
          ownerId: null,
        } });
      return;
    }

    if (timeout) {
      clearTimeout(timeout);
      timeout = null;
    }

    currentValue = merchantCode;
    async function searchMerchant() {
      if (timeout) {
        clearTimeout(timeout);
        timeout = null;
      }
      try {
        if (state.selectedMerchantFilter.id) {
          dispatch({
            type: 'INITIALIZE_FILTERS',
            merchants: [state.selectedMerchantFilter],
          });
          return;
        }

        let searchOptions = {
          size: 20,
          page: 1,
        };

        if (merchantCode) {
          searchOptions = {
            query: merchantCode,
            size: 20,
            page: 1,
          };
        }

        const { data } = await getMerchantsService(searchOptions);
        if (currentValue === merchantCode) {
          dispatch({ type: 'INITIALIZE_FILTERS', merchants: data.merchants });
        }
      } catch (error) {
        dispatch({ type: 'INITIALIZE_FILTERS', merchants: [] });
      }
    }

    // Set timeout to minimize flooding the server with API calls when
    // searching for merchants
    timeout = setTimeout(searchMerchant, 400);
  }

  const paginationSettings = {
    position: 'bottom',
    size: 'small',
    current: state.searchOption.page,
    pageSize: 10,
    total: state.searchOption.totalCount,
    showLessItems: true,
    onChange: (page) => {
      const totalPage = Math.ceil(state.searchOption.totalCount / 10);
      let pg = page || 1;
      if (pg < 1) { pg = 1; }
      if (pg > totalPage) { pg = totalPage - 1; }
      onChangeSearchOption({
        ...state.searchOption,
        page,
      });
    },
    showTotal: (total, range) => `Showing ${range[0]} - ${range[1]} of ${total}`,
  };

  const columns = [
    {
      title: 'User',
      key: 'id',
      render: row => (
        <div className="table-col-item">
          <div>
            {(({ firstName, lastName, id }) => {
              const notEmpty = (firstName && lastName);
              return (
                <Link
                  className={notEmpty ? 'table-item-link user-name' : 'text-secondary'}
                  to={{ pathname: `/users/${id}` }}
                >
                  {notEmpty
                    ? `${firstName} ${lastName}`
                    : 'Invited User'}
                </Link>
              );
            })(row)}
          </div>
        </div>
      ),
    }, {
      title: 'Email',
      key: 'email',
      render: row => (
        <div className="text-secondary">{row.email}</div>
      ),
    }, {
      title: 'Role',
      key: 'systemroles',
      render: row => (
        <div className="scope-role">
          {row.systemRole[2]}
        </div>
      ),
    }, {
      title: 'Status',
      key: 'status',
      render: (row) => {
        if (!row.isAccountConfirmed) {
          return (
            <div className="table-col-item">
              <NoEntryIcon />
              <span className="icon-label">Invited</span>
            </div>
          );
        }
        if (!row.isEnabled) {
          return (
            <div className="table-col-item">
              <TimesCircleIcon className="table-col-item-icon" />
              <span className="icon-label">Disabled</span>
            </div>
          );
        }
        return (
          <div className="table-col-item">
            <CheckCircleIcon className="table-col-item-icon" />
            <span className="icon-label">Active</span>
          </div>
        );
      },
    }, {
      key: 'menu',
      render: (row) => {
        const menu = (
          <Menu>
            {(row.systemRole[0] === 70 && loggedInUser.systemRole[0] === 10) ? (
              <Menu.Item key="set-owner" onClick={() => dispatch({ type: 'SHOW_SET_OWNER_MODAL', selectedUser: row })}>
                Set as owner
              </Menu.Item>
            ) : null
            }
            {(!row.isAccountConfirmed) ? (
              <Menu.Item key="send-email" onClick={() => sendInvite(row.email)}>
                Send email invite
              </Menu.Item>
            ) : null
            }
          </Menu>
        );
        return (
          <div>
            {(row.systemRole[0] === 70 || !row.isAccountConfirmed) ? (
              <Dropdown overlay={menu} trigger={['click']}>
                <Icon type="more" />
              </Dropdown>
            ) : null
            }
          </div>
        );
      },
    },
  ];

  return (
    <div className="qwikwire">
      <section style={{ display: 'flex', justifyContent: 'space-between' }}>
        <div className="transaction-breadcrumb">
          <div className="transaction-breadcrumb-icon">
            <UsersIconBig />
          </div>
          <Breadcrumb>
            <Breadcrumb.Item>
              Users
            </Breadcrumb.Item>
          </Breadcrumb>
        </div>
        <div className="action-container">
          <Button
            className="button button-standard button-standard-outline"
            onClick={() => history.push('/users/new')}
          >
            <Icon type="plus" />
            <span>New User</span>
          </Button>
        </div>
      </section>
      <Row gutter={8} className="filters" style={{ marginBottom: '12px' }}>
        <Col lg={6}>
          <div className="dropdown-container">
            <h4 className="text-secondary">Search by name</h4>
            <div className="search-container">
              <Input.Search
                allowClear
                defaultValue={state.searchOption.searchQuery}
                className="search-bar"
                disabled={state.isFetching}
                placeholder="Search users by name"
                onSearch={(search) => {
                  onChangeSearchOption({
                    ...state.searchOption,
                    page: 1,
                    searchQuery: search,
                  });
                }}
              />
            </div>
          </div>
        </Col>
        <Col lg={4}>
          <div className="dropdown-container">
            <h4 className="text-secondary">Scope</h4>
            <Select
              className="transaction-filter"
              showSearch
              defaultValue={state.searchOption.scope ? state.searchOption.scope : undefined}
              disabled={state.isFetching}
              placeholder="Scope"
              defaultActiveFirstOption={false}
              allowClear
              showArrow={false}
              onChange={onSelectMerchant}
              onSearch={onSearchMerchant}
              notFoundContent={null}
            >
              {state.merchants.map(s => (
                <Select.Option key={s.code} value={s.code} label={s.name}>
                  {s.name}
                </Select.Option>
              ))}
            </Select>
          </div>
        </Col>
        <Col lg={4}>
          <div className="dropdown-container">
            <h4 className="text-secondary">System Role</h4>
            <Select
              allowClear
              suffixIcon={<Icon type="caret-down" />}
              value={state.searchOption.systemRole}
              disabled={state.isFetching}
              className="transaction-filter"
              onChange={(systemRole) => {
                onChangeSearchOption({
                  ...state.searchOption,
                  page: 1,
                  systemRole,
                });
              }}
            >
              {systemRoles.map(s => (
                <Select.Option key={s.key} value={s.code} label={s.label}>
                  {s.label}
                </Select.Option>
              ))}
            </Select>
          </div>
        </Col>
        <Col lg={6}>
          <div className="dropdown-container" style={{ marginTop: 36 }}>
            <div className="switch-container">
              <Switch
                defaultChecked={state.searchOption.showDeactivated}
                disabled={state.isFetching}
                onChange={(value) => {
                  onChangeSearchOption({
                    ...state.searchOption,
                    page: 1,
                    showDeactivated: value,
                  });
                }}
              />
              <span style={{ marginLeft: 4 }}>Show deactivated users</span>
            </div>
          </div>
        </Col>
      </Row>
      <Table
        className="table-standard"
        dataSource={state.users}
        columns={columns}
        rowKey="id"
        loading={state.isFetching}
        pagination={paginationSettings}
      />
      <Modal
        title="Set as Owner"
        className="dialog-modal"
        visible={state.isSetOwnerModalVisible}
        onCancel={() => dispatch({ type: 'HIDE_SET_OWNER_MODAL' })}
        footer={[
          <Button
            key="cancel"
            onClick={() => dispatch({ type: 'HIDE_SET_OWNER_MODAL' })}
          >
            Cancel
          </Button>,
          <Button
            key="submit"
            type="primary"
            htmlType="submit"
            loading={state.isSettingOwner}
            disabled={!state.selectedMerchantChangeOwner.id || (state.selectedMerchantChangeOwner.ownerId === state.selectedUser.id)}
            onClick={() => setUserAsOwner(state.selectedMerchantChangeOwner.id, state.selectedUser.id)}
          >
            Confirm ownership
          </Button>,
        ]}
      >
        <Row>
          <Col lg={3}>
            <Icon type="info-circle" style={{ color: '#399DE5', fontSize: 30, marginTop: 10, marginBottom: 20 }} />
          </Col>
          <Col lg={21}>
            <p>
              Set
              <strong>{` ${state.selectedUser.firstName} ${state.selectedUser.lastName} `}</strong>
              (
              {state.selectedUser.email}
              ) as the account owner. Be careful, this would replace the current owner.
            </p>
          </Col>
        </Row>
        <Row>
          <Select
            className="transaction-filter"
            allowClear
            showSearch
            disabled={state.isSettingOwner}
            placeholder="Select a merchant"
            onSelect={(selectedScope) => {
              dispatch({ type: 'SET_SELECTED_MERCHANT_CHANGE_OWNER', selectedMerchantChangeOwner: { code: selectedScope } });
            }}
            onChange={(selectedScope) => {
              if (!selectedScope) {
                dispatch({ type: 'SET_SELECTED_MERCHANT_CHANGE_OWNER',
                  selectedMerchantChangeOwner: {
                    code: null,
                    id: null,
                    name: null,
                    ownerId: null,
                  } });
              }
            }}
          >
            {state.selectedUser.scopes.map(s => (
              <Select.Option key={s} value={s} label={s}>
                {s}
              </Select.Option>
            ))}
          </Select>
        </Row>
        <Row>
          <Col>
            {state.selectedUser.id === state.selectedMerchantChangeOwner.ownerId ? (
              <div style={{ marginTop: 10, fontStyle: 'italic' }}>
                {`* ${state.selectedUser.firstName} is already the account owner of this merchant.`}
              </div>
            ) : <div /> }
          </Col>
        </Row>
      </Modal>
    </div>
  );
};

Users.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }).isRequired,
  location: PropTypes.shape({
    pathname: PropTypes.string.isRequired,
    search: PropTypes.string.isRequired,
  }).isRequired,
};

export default Users;
