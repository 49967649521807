import React, { Fragment } from 'react';
import PropTypes from 'prop-types';

function PaymentMethodDetails(props) {
  const { payment } = props;
  const { paymentMethodName, paymentMethodProvider, paymentMethodType } = payment;
  const provider = paymentMethodProvider ? paymentMethodProvider.toUpperCase() : '----';
  const methodType = paymentMethodType ? paymentMethodType.toUpperCase() : '----';
  const offlinePaymentAllowedStatus = ['PAID', 'SETTLED', 'REFUNDED'];
  switch (paymentMethodName) {
    case 'cc':
      return (
        <div className="panel-item-group">
          <h3>Credit Card</h3>
          <div className="panel-breakdown-item">
            <div>Name</div>
            <div>{payment.paymentMethodCustomerName}</div>
          </div>
          <div className="panel-breakdown-item">
            <div>Provider</div>
            <div>{`${provider} ${payment.paymentMethodCardLastFour}`}</div>
          </div>
          <div className="panel-breakdown-item">
            <div>Expires at</div>
            <div>{payment.paymentMethodExpiry}</div>
          </div>
          <div className="panel-breakdown-item">
            <div>Origin</div>
            <div>{payment.paymentMethodOrigin || '----'}</div>
          </div>
          <div className="panel-breakdown-item">
            <div>Address</div>
            <div>{payment.paymentMethodCountryName}</div>
          </div>
        </div>
      );
    case 'pp':
      return (
        <div className="panel-item-group">
          <h3>PayPal</h3>
          <div className="panel-breakdown-item">
            <div>Name</div>
            <div>{payment.paymentMethodCustomerName}</div>
          </div>
          <div className="panel-breakdown-item">
            <div>Order ID</div>
            <div>{payment.paymentMethodProcessorId}</div>
          </div>
        </div>
      );
    case 'ewallet':
      return (
        <div className="panel-item-group">
          <h3>E-Wallet</h3>
          <div className="panel-breakdown-item">
            <div>Wallet</div>
            <div>
              {((type) => {
                switch (type) {
                  case 'gcash': return 'GCash';
                  case 'grab_pay': return 'GrabPay';
                  default: return 'Others';
                }
              })(payment.paymentMethodProvider)}
            </div>
          </div>
          <div className="panel-breakdown-item">
            <div>Name</div>
            <div>{payment.paymentMethodCustomerName}</div>
          </div>
        </div>
      );
    case 'ach':
      return (
        <div className="panel-item-group">
          <h3>Bank Transfer (ACH)</h3>
          <div className="panel-breakdown-item">
            <div>Name</div>
            <div>{payment.paymentMethodCustomerName}</div>
          </div>
          <div className="panel-breakdown-item">
            <div>Status</div>
            <div>
              {((status) => {
                switch (status) {
                  case 'SETTLEMENT_PENDING':
                    return 'Pending';
                  case 'SETTLED':
                    return 'Paid';
                  case 'SETTLEMENT_DECLINED':
                    return 'Declined';
                  default:
                    return 'Failed';
                }
              })(payment.paymentMethodStatus)}
            </div>
          </div>
          <div className="panel-breakdown-item">
            <div>Account Type</div>
            <div>{methodType}</div>
          </div>
          <div className="panel-breakdown-item">
            <div>Address</div>
            <div>{payment.paymentMethodBillingAddress}</div>
          </div>
        </div>
      );
    case 'offline':
      return (
        <div className="panel-item-group">
          <h3>Offline Payment</h3>
          <div className="panel-breakdown-item">
            <div>Name</div>
            <div>{payment.paymentMethodCustomerName}</div>
          </div>
          <div className="panel-breakdown-item">
            <div>Method</div>
            <div>{payment.paymentMethodType}</div>
          </div>
          {offlinePaymentAllowedStatus.includes(payment.paymentStatus) && (
            <Fragment>
              <div className="panel-breakdown-item">
                <div>Source</div>
                <div>{payment.paymentMethodIssuer}</div>
              </div>
              <div className="panel-breakdown-item">
                <div>Origin</div>
                <div>{payment.paymentMethodOrigin}</div>
              </div>
            </Fragment>
          )}
        </div>
      );
    case 'directDebit':
      return (
        <div className="panel-item-group">
          <h3>Direct Debit</h3>
          <div className="panel-breakdown-item">
            <div>Bank Name</div>
            <div>
              {((type) => {
                switch (type) {
                  case 'BA_BPI': return 'Bank of the Philippine Islands';
                  case 'BA_UBP': return 'UnionBank of the Philippines';
                  case 'BA_BDO': return 'BDO Unibank, Inc.';
                  default: return 'Others';
                }
              })(payment.paymentMethodProvider)}
            </div>
          </div>
          {
            payment.paymentMethodType && payment.paymentMethodCardLastFour && (
              <div className="panel-breakdown-item">
                <div>Account</div>
                <div>{`${payment.paymentMethodType} ending in ${payment.paymentMethodCardLastFour}`}</div>
              </div>
            )
          }
          <div className="panel-breakdown-item">
            <div>Name</div>
            <div>{payment.paymentMethodCustomerName}</div>
          </div>
          <div className="panel-breakdown-item">
            <div>Payment Origin</div>
            <div>{payment.paymentMethodOrigin}</div>
          </div>
          <div className="panel-breakdown-item">
            <div>Status</div>
            <div>{payment.paymentStatus}</div>
          </div>
        </div>
      );
    case 'partner':
      return (
        <div className="panel-item-group">
          <h3>Partner Merchant</h3>
          <div className="panel-breakdown-item">
            <div>Processor</div>
            <div>
              {payment.paymentMethodProcessor}
            </div>
          </div>
          <div className="panel-breakdown-item">
            <div>Account Name</div>
            <div>{payment.paymentMethodCustomerName}</div>
          </div>
        </div>
      );
    case 'maya':
      return (
        <div className="panel-item-group">
          <h3>Maya</h3>
          <div className="panel-breakdown-item">
            <div>Name</div>
            <div>{payment.paymentMethodCustomerName}</div>
          </div>
          <div className="panel-breakdown-item">
            <div>Account Number</div>
            <div>{payment.paymentMethodCardLastFour}</div>
          </div>
          <div className="panel-breakdown-item">
            <div>Payment ID</div>
            <div>{payment.paymentMethodProcessorId}</div>
          </div>
        </div>
      );
    default:
      return (
        <div className="panel-item-group">
          <h3>Payment</h3>
          <div className="panel-breakdown-item">
            <div>Name</div>
            <div>N/A</div>
          </div>
          <div className="panel-breakdown-item">
            <div>Method</div>
            <div>N/A</div>
          </div>
        </div>
      );
  }
}

PaymentMethodDetails.propTypes = {
  payment: PropTypes.shape({
    paymentStatus: PropTypes.string,
    paymentMethodName: PropTypes.string,
    paymentMethodType: PropTypes.string,
    paymentMethodProvider: PropTypes.string,
    paymentMethodIssuer: PropTypes.string,
    paymentMethodOrigin: PropTypes.string,
    paymentMethodProcessor: PropTypes.string,
    paymentMethodCustomerName: PropTypes.string,
    paymentMethodCardLastFour: PropTypes.string,
    paymentMethodStatus: PropTypes.string,
    paymentMethodBillingAddress: PropTypes.string,
    paymentMethodProcessorId: PropTypes.string,
    paymentMethodExpiry: PropTypes.string,
    paymentMethodCountryName: PropTypes.string,
  }).isRequired,
};

export default PaymentMethodDetails;
