import moment from 'moment';
import React, { Fragment, useContext, useReducer, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Col, Row, Breadcrumb } from 'antd';
import { SingleForm } from '../../components';
import { TransactionIcon, FolderIcon } from '../../components/Icons';
import { getTransactionService } from '../../services/transactions';
import { formatNumber, getStatusColor } from '../../helpers/utils';
import SessionContext from '../../contexts/SessionContext';
import '../../styles/panel.css';

const initialState = {
  isFetchingTransaction: true,
  transaction: null,
};

function reducer(prevState, action) {
  switch (action.type) {
    case 'GET_TRANSACTION':
      return {
        ...prevState,
        isFetchingTransaction: true,
        transaction: null,
      };
    case 'GET_TRANSACTION_SUCCESSFUL':
      return {
        ...prevState,
        isFetchingTransaction: false,
        transaction: action.transaction,
      };
    case 'GET_TRANSACTION_FAILED':
      return {
        ...prevState,
        isFetchingTransaction: false,
        transaction: null,
      };
    default:
      return prevState;
  }
}

const Transaction = (props) => {
  const { match: { params: { merchantCode, transactionId } } } = props;
  const { loggedInUser, merchants } = useContext(SessionContext);
  const merchant = merchants.find(m => m.code === merchantCode);
  const [state, dispatch] = useReducer(reducer, initialState);

  useEffect(() => {
    let ableToSet = true;
    (async () => {
      if (!merchant || !loggedInUser) { return; }

      dispatch({ type: 'GET_TRANSACTION' });
      try {
        const response = await getTransactionService(merchant.id, transactionId);
        const transaction = response.data;
        if (ableToSet) {
          dispatch({ type: 'GET_TRANSACTION_SUCCESSFUL', transaction });
        }
      } catch (error) {
        const message = error && error.response
          ? error.response.data.message
          : 'We are not able to get the transaction data from the server.';
        if (ableToSet) {
          dispatch({ type: 'GET_TRANSACTION_FAILED', message });
        }
      }
    })();

    return () => { ableToSet = false; };
  }, [merchant, transactionId, loggedInUser]);

  if (!merchant) {
    return (
      <SingleForm
        title="Merchant does not exist"
        subtitle="The page you requested could not be found. Please go back to Dashboard or contact us at support@aqwire.io."
        footer={{ show: true, to: '/', text: 'Back to Dashboard' }}
      />
    );
  }

  if (state.isFetchingTransaction) {
    return (
      <div>
        <div className="spinner">Loading...</div>
      </div>
    );
  }

  if (!state.transaction) {
    return (
      <div>
        <div className="spinner">
          <FolderIcon />
          <h1>Transaction Not Found</h1>
          <p>
            You can contact us at&nbsp;
            <a href="mailto:support@aqwire.io">support@aqwire.io</a>
            &nbsp;if you have concerns or issues.
          </p>
        </div>
      </div>
    );
  }

  const { transaction } = state;
  const breadcrumb = (
    <Row className="breadcrumb-row">
      <Col className="breadcrumb-header">
        <Breadcrumb className="breadcrumb-parent">
          <Breadcrumb.Item className="item-container">
            <div className="breadcrumb-item breadcrumb-icon">
              <TransactionIcon />
            </div>
            <div className="breadcrumb-item icon-label">
              Transactions
            </div>
          </Breadcrumb.Item>
        </Breadcrumb>
      </Col>
    </Row>
  );

  const transactionContentPanel = (
    <section className="panel panel-standard" style={{ marginBottom: '32px' }}>
      <div className="panel-header">
        <Row>
          <Col lg={6} className="panel-item">
            <h3>TX ID</h3>
            <div>{transaction.externalTransactionId}</div>
          </Col>
          <Col lg={6} className="panel-item">
            <h3>{transaction.customerName}</h3>
            <div>{transaction.customerEmail}</div>
            {transaction.customerPhone && <div>{` (${transaction.customerPhone})`}</div>}
          </Col>
          <Col lg={6} className="panel-item">
            <h3>Date</h3>
            <div>{moment(transaction.createdAt).format('MMM. DD, YYYY HH:mm')}</div>
          </Col>
          <Col lg={6} className="panel-item panel-item-end">
            <div
              className="panel-badge"
              style={{
                color: '#ffffff',
                backgroundColor: getStatusColor(transaction.transactionStatus),
              }}
            >
              {transaction.transactionStatus}
            </div>
          </Col>
        </Row>
      </div>
      <div className="panel-body">
        <Row>
          <Col lg={12}>
            {transaction.referenceId && (
              <div className="panel-item">
                <h3>QW ID</h3>
                <div>
                  {transaction.referenceId}
                  {/* <Link to={getTransactionLink(transaction)} title="View enrollment">
                  </Link> */}
                </div>
              </div>
            )}
            <div className="panel-item">
              <h3>Payment Type</h3>
              <div>
                {transaction.paymentTypeName}
              </div>
            </div>
            <div className="panel-item">
              <h3>Source</h3>
              <div>
                {((name) => {
                  switch (name) {
                    case 'api': return 'AQWIRE Access';
                    case 'merchant-portal': return 'Portals 2.0';
                    case 'portal3': return 'Portals 3.0';
                    case 'ayalaland-ma': return 'Ayala Land Enrollment';
                    case 'cxd': return 'Customer Dashboard';
                    case 'payment-link': return 'Payment Link';
                    default: return 'N/A';
                  }
                })(transaction.transactionSource)}
              </div>
            </div>
            <div className="panel-item">
              <h3>{transaction.merchantName}</h3>
              {transaction.projectName && <div>{transaction.projectName}</div>}
              {transaction.projectCategory && <div>{transaction.projectCategory}</div>}
              {transaction.customFields && (
                <div className="panel-item-cellar">
                  {Object.keys(transaction.customFields)
                    .map(key => (
                      <div key={key}>
                        {`${transaction.customFields[key].label}: ${transaction.customFields[key].value ? transaction.customFields[key].value : ''}`}
                      </div>
                    ))}
                </div>
              )}
            </div>
            <div className="panel-item">
              <h3>Notes</h3>
              <div>{transaction.clientNotes || 'There are no notes.'}</div>
            </div>
          </Col>
          <Col lg={12}>
            <div className="panel-item-group">
              <h3>Transaction Breakdown</h3>
              <div className="panel-breakdown-item">
                <div>Payment Method</div>
                <div>
                  {((name) => {
                    switch (name) {
                      case 'cc': return 'Credit Card';
                      case 'ach': return 'Bank Transfer (ACH)';
                      case 'pp': return 'PayPal';
                      case 'ewallet': return 'E-Wallet';
                      case 'maya': return 'Maya';
                      default: return 'N/A';
                    }
                  })(transaction.paymentMethodName)}
                </div>
              </div>
              <div className="panel-breakdown-item">
                <div>Amount Due</div>
                <div>{`${transaction.baseAmount[0]} ${formatNumber(transaction.baseAmount[1])}`}</div>
              </div>
            </div>
          </Col>
        </Row>
      </div>
    </section>
  );

  return (
    <div>
      <Fragment>
        {breadcrumb}
        {transactionContentPanel}
      </Fragment>
    </div>
  );
};

Transaction.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      transactionId: PropTypes.string.isRequired,
      merchantCode: PropTypes.string.isRequired,
    }),
  }).isRequired,
};

export default Transaction;
