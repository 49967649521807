import moment from 'moment-timezone';
import PropTypes from 'prop-types';
import React, { useContext, useEffect, useReducer } from 'react';
import { Row, Col, Breadcrumb, Table } from 'antd';
import { TerminalIcon } from '../../components/Icons';
import { getMerchantActivitiesService } from '../../services/activities';
import SessionContext from '../../contexts/SessionContext';

const initialState = {
  page: 1,
  size: 20,
  totalCount: 0,
  activities: [],
  isFetchingActivities: true,
};

function reducer(prevState, action) {
  switch (action.type) {
    case 'GET_ACTIVITIES':
      return {
        ...prevState,
        isFetchingActivities: true,
      };

    case 'GET_ACTIVITIES_SUCCESSFUL':
      return {
        ...prevState,
        activities: action.activities,
        totalCount: action.totalCount,
        isFetchingActivities: false,
      };

    case 'GET_ACTIVITIES_FAILED':
      return {
        ...prevState,
        activities: [],
        totalCount: 0,
        isFetchingActivities: false,
      };

    case 'UPDATE_SEARCH_OPTION':
      return {
        page: action.page,
      };

    default:
      return prevState;
  }
}

const MerchantActivities = (props) => {
  const { showToast, loggedInUser, merchants } = useContext(SessionContext);
  const [state, dispatch] = useReducer(reducer, initialState);
  const { match } = props;
  const merchant = merchants.find(m => m.code === match.params.merchantCode);
  const timeZone = moment.tz.guess(true);

  useEffect(() => {
    let ableToSet = true;
    (async () => {
      dispatch({ type: 'GET_ACTIVITIES' });
      try {
        const { data: { activities, totalCount } } = await getMerchantActivitiesService(merchant.id, state.page, state.size);
        if (ableToSet) dispatch({ type: 'GET_ACTIVITIES_SUCCESSFUL', activities, totalCount });
      } catch (error) {
        const message = error && error.response ? error.response.data.message
          : 'Unable to get activities as of the moment';
        if (ableToSet) dispatch({ type: 'GET_ACTIVITIES_FAILED' });
        showToast({ type: 'error', message });
      }
    })();

    return () => { ableToSet = false; };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.page, state.size]);

  const columns = [
    {
      title: 'Type',
      key: 'activityType',
      render: row => (
        <strong className="text-primary">
          {row.activityType[0]}
          {row.activityType.substring(1).toLowerCase()}
        </strong>
      ),
    }, {
      title: 'User',
      key: 'user',
      render: row => (
        <div>{`${row.loggedByName}`}</div>
      ),
    }, {
      title: 'Description',
      key: 'message',
      render: row => <div className="text-secondary">{row.message}</div>,
    }, {
      title: 'Logged At',
      key: 'loggedAt',
      render: row => <div className="text-secondary">{moment(row.loggedAt).tz(timeZone).format('lll')}</div>,
    },
  ];

  const pagination = {
    current: state.page,
    pageSize: state.size,
    total: state.totalCount,
    size: 'small',
    onChange: (page) => {
      const totalPage = Math.ceil(state.totalCount / state.size);
      let pg = page || 1;
      if (pg < 1) { pg = 1; }
      if (pg > totalPage) { pg = totalPage - 1; }
      dispatch({ type: 'UPDATE_SEARCH_OPTION', page });
    },
    showTotal: (total, range) => `Showing ${range[0]} - ${range[1]} of ${total}`,
  };

  if (!loggedInUser || state.isFetchingActivities) { return <div />; }

  return (
    <div>
      <Row className="breadcrumb-row">
        <Col className="breadcrumb-header">
          <Breadcrumb className="breadcrumb-parent">
            <Breadcrumb.Item className="item-container">
              <div className="breadcrumb-item breadcrumb-icon">
                <TerminalIcon />
              </div>
              <div className="breadcrumb-item icon-label">
                Activity Log
              </div>
            </Breadcrumb.Item>
          </Breadcrumb>
        </Col>
      </Row>
      <Table
        className="table-standard"
        dataSource={state.activities}
        loading={state.isFetchingActivities}
        columns={columns}
        pagination={pagination}
        rowKey="activityId"
      />
    </div>
  );
};

MerchantActivities.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      merchantCode: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
};

export default MerchantActivities;
