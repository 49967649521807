import React, { Fragment, useContext, useReducer } from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import qs from 'query-string';
import {
  Breadcrumb,
  Col,
  Row,
  Tabs,
} from 'antd';

import { FolderIcon, CodeIcon } from '../../components/Icons';
import Message from '../../components/Message';
import AddWebhookEndpointModal from '../../components/AddWebhookEndpointModal';
import { WebhooksTable, ApiKeysTab, ApiLogsTab } from '../../components';
import SessionContext from '../../contexts/SessionContext';
import { getMerchantWebhooksById } from '../../services/merchants';
import { MERCHANT_ROLES } from '../../constants/index';

import './developer-settings.scss';

const getInitialState = queryFilters => ({
  key: queryFilters.key || 'api',
  isAddWebhookModalVisible: false,
  status: null,
  message: null,
  endpoints: null,
});

function reducer(prevState, action) {
  switch (action.type) {
    case 'TOGGLE_ADD_WEBHOOK_MODAL':
      return {
        ...prevState,
        isAddWebhookModalVisible: !prevState.isAddWebhookModalVisible,
      };
    case 'SHOW_MESSAGE':
      return {
        ...prevState,
        status: action.response.status,
        message: action.response.message,
      };
    case 'CLEAR_MESSAGE':
      return {
        ...prevState,
        status: null,
        message: null,
      };
    case 'SET_ENDPOINTS':
      return {
        ...prevState,
        endpoints: action.webhooks,
      };
    case 'SET_ACTIVE_TAB_KEY':
      return {
        ...prevState,
        key: action.key,
      };
    default:
      return prevState;
  }
}

function getFilteredURLPath(path, searchOption) {
  const key = searchOption;
  let url = `${path}`;
  if (key) { url += `?key=${key}`; }
  return url;
}

const DeveloperSettings = () => {
  const { activeMerchant } = useContext(SessionContext);
  const history = useHistory();
  const location = useLocation();

  const queryFilters = qs.parse(location.search, { ignoreQueryPrefix: true });
  const [state, dispatch] = useReducer(reducer, getInitialState(queryFilters));

  const { merchants, loggedInUser } = useContext(SessionContext);
  const merchant = merchants.find(m => m.code === activeMerchant.code);
  const { systemRole, merchantRoles } = loggedInUser;
  const { merchantRole } = merchantRoles.find(m => m.merchantCode === merchant.code);

  if (!(loggedInUser && merchants.length)) { return <div />; }
  if (systemRole[0] >= 80
    || (systemRole[0] === 70 && ![MERCHANT_ROLES.ADMIN, MERCHANT_ROLES.DEVELOPER].includes(merchantRole[0]))
    || !merchant
  ) {
    return (
      <div>
        <div className="spinner">
          <FolderIcon />
          <h1>You are not allowed to access the developer settings page.</h1>
          <p>
            You can contact us at
            <a href="mailto:support@aqwire.io">support@aqwire.io</a>
            if you have concerns or issues.
          </p>
        </div>
      </div>
    );
  }

  function onChangeTabKey(key) {
    const url = getFilteredURLPath(location.pathname, key);
    history.replace(url, {});
    dispatch({ type: 'SET_ACTIVE_TAB_KEY', key });
  }

  async function getWebhookEndpoints() {
    try {
      const { data: { webhooks } } = await getMerchantWebhooksById(activeMerchant.id);
      dispatch({ type: 'SET_ENDPOINTS', webhooks });
    } catch (error) {
      const message = error && error.response
        ? error.response.data.message
        : 'Unable retrieve new endpoints';

      dispatch({
        type: 'SHOW_MESSAGE',
        response: {
          status: 'error',
          message,
        },
      });
    }
  }

  return (
    <div>
      <Fragment>
        <Row className="breadcrumb-row">
          <Col className="breadcrumb-header">
            <Breadcrumb className="breadcrumb-parent">
              <Breadcrumb.Item className="item-container" style={{ alignItems: 'unset' }}>
                <div className="breadcrumb-item breadcrumb-icon">
                  <CodeIcon />
                </div>
                <div className="breadcrumb-item icon-label">
                  <h4 style={{ display: 'flex', flexDirection: 'column', marginTop: '4px', marginBottom: 0 }}>
                    <div className="heading-primary" style={{ lineHeight: 1.24 }}>
                      Developers
                    </div>
                  </h4>
                </div>
              </Breadcrumb.Item>
            </Breadcrumb>
          </Col>
        </Row>
        <Row className="breadcrumb-row">
          <Col>
            {state.status && state.message && (
              <Message
                style={{ marginBottom: '12px' }}
                status={state.status}
                message={state.message}
                onClose={() => dispatch({ type: 'CLEAR_MESSAGE' })}
              />
            )}
          </Col>
        </Row>
        <Tabs
          activeKey={state.key}
          animated={false}
          onChange={key => onChangeTabKey(key)}
        >
          <Tabs.TabPane tab="API Keys" key="api">
            <ApiKeysTab
              merchant={merchant}
              onComplete={(response) => {
                dispatch({ type: 'SHOW_MESSAGE', response });
              }}
            />
          </Tabs.TabPane>
          <Tabs.TabPane tab="Webhooks" key="webhook">
            <div className="webhook-section">
              <div className="webhook-section__header">
                <span>Endpoints</span>
                <AddWebhookEndpointModal
                  history={history}
                  toggleAction={() => dispatch({ type: 'TOGGLE_ADD_WEBHOOK_MODAL' })}
                  onComplete={(response) => {
                    getWebhookEndpoints();
                    dispatch({ type: 'SHOW_MESSAGE', response });
                  }}
                  isVisible={state.isAddWebhookModalVisible}
                />
              </div>
              <WebhooksTable
                history={history}
                merchant={merchant}
                endpoints={state.endpoints}
              />
            </div>
          </Tabs.TabPane>
          <Tabs.TabPane tab="Logs" key="logs">
            <ApiLogsTab merchant={merchant} isActive={state.key === 'logs'} />
          </Tabs.TabPane>
        </Tabs>
      </Fragment>
    </div>
  );
};

export default DeveloperSettings;
