import React, { useContext, useState } from 'react';
import PropTypes from 'prop-types';
import { Card, Form, Input, Button } from 'antd';
import SessionContext from '../../contexts/SessionContext';
import { updateUserDetails } from '../../services/auth';

const formItemLayout = { labelCol: { span: 24 }, wrapperCol: { span: 24 } };
const FormItem = Form.Item;
const AccountSettingsInfo = (props) => {
  const { form } = props;
  const [isSaving, setIsSaving] = useState(false);
  const { showToast } = useContext(SessionContext);

  function onSubmit(e) {
    e.preventDefault();
    form.validateFields(async (err, { email, firstname, lastname }) => {
      if (!err) {
        setIsSaving(true);
        try {
          const { data: { message } } = await updateUserDetails({ email, firstname, lastname });
          showToast({ type: 'success', message });
          setIsSaving(false);
        } catch (error) {
          const message = error && error.response
            ? error.response.data.message
            : 'Unable to save your account information as of the moment';
          showToast({ type: 'error', message });
          setIsSaving(false);
        }
      }
    });
  }

  return (
    <Card className="settings-card" title="My Details">
      <Form onSubmit={onSubmit}>
        <div className="settings-card-body">
          <FormItem {...formItemLayout} label="Email Address">
            {form.getFieldDecorator('email', {
              rules: [{ required: true, message: 'Email is required' }],
              initialValue: props.user.email,
            })(<Input type="email" />)}
          </FormItem>
          <FormItem {...formItemLayout} label="First Name">
            {form.getFieldDecorator('firstname', {
              rules: [{ required: true, message: 'Please enter your first name' }],
              initialValue: props.user.firstName,
            })(<Input />)}
          </FormItem>
          <FormItem {...formItemLayout} label="Last Name">
            {form.getFieldDecorator('lastname', {
              rules: [{ required: true, message: 'Please enter your last name' }],
              initialValue: props.user.lastName,
            })(<Input />)}
          </FormItem>
        </div>
        <Form.Item className="settings-card-action">
          <Button
            disabled={isSaving}
            loading={isSaving}
            className="button button-standard"
            type="primary"
            htmlType="submit"
          >
            Save Details
          </Button>
        </Form.Item>
      </Form>
    </Card>
  );
};

AccountSettingsInfo.propTypes = {
  form: PropTypes.shape({
    getFieldDecorator: PropTypes.func.isRequired,
    validateFields: PropTypes.func.isRequired,
  }).isRequired,
  user: PropTypes.shape({
    id: PropTypes.string.isRequired,
    firstName: PropTypes.string.isRequired,
    lastName: PropTypes.string.isRequired,
    email: PropTypes.string.isRequired,
    role: PropTypes.array.isRequired,
  }).isRequired,
};

export default Form.create()(AccountSettingsInfo);
