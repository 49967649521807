import React, { useContext, useState } from 'react';
import { Breadcrumb, Row, Col, Alert } from 'antd';
import PropTypes from 'prop-types';

import { MerchantsPageIcon, FolderIcon } from '../../components/Icons';
import { ROLES } from '../../constants';
import SessionContext from '../../contexts/SessionContext';
import QueryBasedMerchantsTable from '../../components/QueryBasedMerchantsTable';

function getFilteredURLPath(path, state) {
  const {
    page,
    size,
    query,
    type,
    category,
  } = state;
  let url = `${path}?page=${page || 1}`;
  if (size) { url += `&size=${size}`; }
  if (query) { url += `&query=${query}`; }
  if (type) { url += `&type=${type}`; }
  if (category) { url += `&category=${category}`; }
  return url;
}

function Merchants(props) {
  const { history, location } = props;
  const sessionContext = useContext(SessionContext);
  const { loggedInUser, pushMessage, popMessage } = sessionContext;
  const [msg, setMsg] = useState(popMessage());

  if (!loggedInUser) { return <div />; }

  const { systemRole, merchantRole } = loggedInUser;
  if (systemRole[0] > ROLES.USER || (systemRole[0] === ROLES.USER && merchantRole[0] > 10)) {
    // eslint-disable-next-line react/jsx-one-expression-per-line
    const message = <p>You can contact us at <a href="mailto:support@aqwire.co">support@aqwire.co</a> if you have concerns or issues.</p>;
    return (
      <div>
        <div className="spinner">
          <FolderIcon />
          <h1>You are not allowed to access the merchants page.</h1>
          {message}
        </div>
      </div>
    );
  }

  return (
    <div className="qwikwire">
      <section className="transaction-breadcrumb">
        <div className="transaction-breadcrumb-icon">
          <MerchantsPageIcon />
        </div>
        <Breadcrumb>
          <Breadcrumb.Item>
            Merchants
          </Breadcrumb.Item>
        </Breadcrumb>
      </section>
      {msg && msg.status && msg.message && (
        <div style={{ marginBottom: '8px' }}>
          <Row>
            <Col span={24}>
              <Alert
                message={<p style={{ marginBottom: 0 }}>{msg.message}</p>}
                type={['error', 'success'].includes(msg.status) ? msg.status : 'info'}
                showIcon
                closable
                onClose={() => setMsg(null)}
              />
            </Col>
          </Row>
        </div>
      )}
      <QueryBasedMerchantsTable
        onChangeSearchFunction={(searchOption, data) => {
          if (data && data.status && data.message) {
            pushMessage({ component: 'Merchants', status: data.status, message: data.message });
          }

          const url = getFilteredURLPath(location.pathname, searchOption);
          history.push(url, {});
        }}
        history={history}
      />
    </div>
  );
}

Merchants.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }).isRequired,
  location: PropTypes.shape({
    pathname: PropTypes.string.isRequired,
    search: PropTypes.string.isRequired,
  }).isRequired,
};

export default Merchants;
