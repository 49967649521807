import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import qs from 'query-string';
import { Breadcrumb, Col, Row } from 'antd';
import { UsersIconBig } from '../../components/Icons';
import { EditUserForm } from '../../components';

const EditUser = (props) => {
  const { location: { filter }, match: { params: { userId } } } = props;
  const [email, setEmail] = useState('');

  return (
    <div>
      <Row className="breadcrumb-row">
        <Col className="breadcrumb-header">
          <Breadcrumb className="breadcrumb-parent">
            <Breadcrumb.Item className="item-container">
              <div className="breadcrumb-item breadcrumb-icon">
                <UsersIconBig />
              </div>
              <div className="breadcrumb-item icon-label">
                Users
              </div>
            </Breadcrumb.Item>
          </Breadcrumb>
          <Breadcrumb separator=">">
            <Breadcrumb.Item className="breadcrumb-item">
              <Link to={`/users?${qs.stringify(filter)}`}>
                <span>Users</span>
              </Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item>
              <span>{email}</span>
            </Breadcrumb.Item>
          </Breadcrumb>
        </Col>
      </Row>
      <EditUserForm
        setEmail={setEmail}
        email={email}
        userId={userId}
      />
    </div>
  );
};

EditUser.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      userId: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
  location: PropTypes.shape({
    filter: PropTypes.shape({}),
  }),
};

EditUser.defaultProps = {
  location: null,
};

export default EditUser;
