import moment from 'moment';
import React, { useContext, useEffect, useReducer, useRef } from 'react';
import PropTypes from 'prop-types';
import * as d3 from 'd3';
import SessionContext from '../../contexts/SessionContext';
import { getPaymentReports } from '../../services/reports';
import { formatNumber } from '../../helpers/utils';
import '../../styles/linechart.css';
import './paymentbarchart.css';

const sidebarWidth = 250;
const paddingHorizontal = 64;
const dateFormat = 'YYYY/MM/DD';
const lineColor = '#D9D9D9';
const colorList = [
  '#C4E2F7',
  '#74BAED',
  '#399DE5',
  '#2C8BDE',
  '#2481DA',
  '#176FD3',
  '#72DFE7',
  '#72DFE7',
  '#54D8E1',
];
const initialState = {
  isFetchingData: true,
  chartWidth: null,
  chartHeight: 296,
  isTooltipVisible: false,
  hoveredPointData: {
    projectName: null,
    total: null,
    currency: null,
    x: null,
    y: null,
  },
  payments: null,
  paymentTypes: null,
  mappedColors: null,
};

function reducer(prevState, action) {
  switch (action.type) {
    case 'FETCH_DATA_SUCCESSFUL':
      return {
        ...prevState,
        isFetchingData: false,
        payments: action.payments,
        paymentTypes: action.paymentTypes,
        mappedColors: action.paymentTypes.reduce((acc, curr, idx) => ({ ...acc, [curr]: colorList[idx] }), {}),
      };

    case 'FETCH_DATA_FAILED':
      return {
        ...prevState,
        isFetchingData: false,
        payments: null,
        paymentTypes: null,
      };

    case 'ADJUST_CHART_WIDTH':
      return {
        ...prevState,
        chartWidth: action.chartWidth,
      };

    case 'SHOW_POINT_TOOLTIP':
      return {
        ...prevState,
        isTooltipVisible: true,
        hoveredPointData: action.hoveredPointData,
      };

    case 'HIDE_POINT_TOOLTIP':
      return {
        ...prevState,
        isTooltipVisible: false,
        hoveredPointData: {
          projectName: null,
          total: null,
          currency: null,
          x: null,
          y: null,
        },
      };

    default:
      return prevState;
  }
}

const PaymentBarChart = (props) => {
  const { merchant, title, start, end } = props;
  const { showToast } = useContext(SessionContext);
  const defaultWidth = document.body.scrollWidth - sidebarWidth - paddingHorizontal;
  const [state, dispatch] = useReducer(reducer, initialState);
  const svgRef = useRef(null);

  function showToolTip({ data }) {
    const { pageX: x, pageY: y } = d3.event;
    const { paymentTypes } = state;
    const total = paymentTypes.reduce((sum, pt) => sum + data[pt], 0);
    const hoveredPointData = { ...data, total, x, y };
    dispatch({ type: 'SHOW_POINT_TOOLTIP', hoveredPointData });
  }

  function wrapText(texts, width) {
    function wrap() {
      const text = d3.select(this);
      const words = text.text().split(/\s+/).reverse();
      const y = text.attr('y');
      const dy = parseFloat(text.attr('dy'));
      let tspan = text
        .text(null)
        .append('tspan')
        .attr('x', 0)
        .attr('y', y)
        .attr('dy', `${dy}em`);

      let line = [];
      let word = words.pop();
      let lineNumber = 0;
      while (word) {
        line.push(word);
        tspan.text(line.join(' '));
        if (tspan.node().getComputedTextLength() > width) {
          lineNumber += 1;
          line.pop();
          tspan.text(line.join(' '));
          line = [word];
          tspan = text
            .append('tspan')
            .attr('x', 0)
            .attr('y', y)
            .attr('dy', `${lineNumber + dy}em`)
            .text(word);
        }
        word = words.pop();
      }
    }
    texts.each(wrap);
  }


  /* Data fetching */
  useEffect(() => {
    let ableToSet = true;
    (async () => {
      try {
        const endDate = end ? moment(end, dateFormat) : moment();
        const startDate = start ? moment(start, dateFormat) : moment().subtract(7, 'days');
        const { data } = await getPaymentReports(merchant.id, { startDate, endDate });
        const { payments, paymentTypes } = data;
        if (ableToSet) dispatch({ type: 'FETCH_DATA_SUCCESSFUL', payments, paymentTypes });
      } catch (err) {
        const message = err && err.response
          ? err.response.data.message
          : 'Unable to get the chart data from the server';
        if (ableToSet) dispatch({ type: 'FETCH_DATA_FAILED' });
        showToast({ type: 'error', message });
      }
    })();
    return () => { ableToSet = false; };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [start, end]);

  /* Window resizing */
  useEffect(() => {
    let ableToSet = true;

    function handleResize() {
      dispatch({
        type: 'ADJUST_CHART_WIDTH',
        chartWidth: document.body.scrollWidth - sidebarWidth - paddingHorizontal,
      });
    }

    if (ableToSet) dispatch({ type: 'ADJUST_CHART_WIDTH', chartWidth: defaultWidth - 64 });
    window.addEventListener('resize', handleResize);

    return () => {
      ableToSet = false;
      window.removeEventListener('resize', handleResize);
    };
  }, [defaultWidth]);

  /* Chart */
  useEffect(() => {
    const {
      chartHeight,
      chartWidth,
      payments,
      mappedColors,
      paymentTypes,
    } = state;
    const svg = d3.select(svgRef.current);

    if (paymentTypes) {
      const generateStack = d3.stack()
        .keys(paymentTypes)
        .order(d3.stackOrderAscending);
      const layers = generateStack(payments);

      const xScale = d3.scaleBand()
        .domain(payments.map(d => d.projectName))
        .range([50, chartWidth])
        .padding(chartWidth >= 650 ? 0.5 : 0.2);
      const yScale = d3.scaleLinear()
        .domain([0, d3.max(layers, layer => d3.max(layer, sequence => sequence[1]))])
        .range([chartHeight, 0]);

      const xAxis = g => g
        .attr('transform', `translate(0, ${chartHeight + 20})`)
        .call(d3.axisBottom(xScale).tickSizeOuter(0))
        .call(el => el
          .selectAll('.domain')
          .attr('stroke', lineColor))
        .call(el => el
          .selectAll('.tick')
          .selectAll('line')
          .attr('stroke', lineColor));

      const yAxis = g => g
        .attr('transform', 'translate(50, 20)')
        .call(d3.axisLeft(yScale).ticks(null, 's'))
        .call(el => el
          .selectAll('.domain')
          .attr('stroke', lineColor))
        .call(el => el
          .selectAll('.tick')
          .selectAll('line')
          .attr('stroke', lineColor));

      /* Delete previous doms then rerender */
      d3.selectAll('.bar-chart > *').remove();
      svg.append('g')
        .attr('transform', 'translate(0, 20)')
        .selectAll('g')
        .data(layers)
        .join('g')
        .attr('fill', d => mappedColors[d.key])
        .selectAll('rect')
        .data(d => d)
        .join('rect')
        .on('mouseover', d => showToolTip(d))
        .on('mouseout', () => dispatch({ type: 'HIDE_POINT_TOOLTIP' }))
        .attr('x', d => xScale(d.data.projectName))
        .attr('y', d => yScale(d[1]))
        .attr('height', d => yScale(d[0]) - yScale(d[1]))
        .attr('width', xScale.bandwidth());

      svg.append('g')
        .call(xAxis)
        .selectAll('.tick text')
        .call(wrapText, xScale.bandwidth());

      svg.append('g').call(yAxis);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.paymentTypes, state.chartWidth]);

  if (!state.payments) { return <div />; }
  if (state.status === 'error') { return <div>Transactions chart is not available</div>; }

  const {
    chartWidth: width,
    chartHeight: height,
    mappedColors,
    paymentTypes,
    isTooltipVisible,
    hoveredPointData,
    hoveredPointData: {
      projectName,
      currency,
      total,
      x,
      y,
    },
  } = state;
  return (
    <div className="linechart">
      <h4>
        <div className="heading-primary">{title}</div>
      </h4>
      <svg className="bar-chart" ref={svgRef} width={width} height={height + 88} />
      <div className="legends">
        {Object.keys(mappedColors).map(k => (
          <div key={k} className="legend">
            <div className="legend-color" style={{ backgroundColor: mappedColors[k] }} />
            <div className="legend-text">{k}</div>
          </div>
        ))}
      </div>
      {isTooltipVisible && (
        <div
          className="tooltip"
          style={{ width: '272px', left: `${x > width ? x - 280 : x + 24}px`, top: `${y - 50}px` }}
        >
          <div className="tooltip-header">
            {projectName}
          </div>
          {paymentTypes.map(pt => (
            <div key={pt}>{`${pt}: ${currency} ${formatNumber(hoveredPointData[pt])}`}</div>
          ))}
          <div>{`Total: ${currency} ${formatNumber(total)}`}</div>
        </div>
      )}
    </div>
  );
};

PaymentBarChart.propTypes = {
  merchant: PropTypes.shape({
    id: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
    code: PropTypes.string.isRequired,
  }).isRequired,
  title: PropTypes.string.isRequired,
  start: PropTypes.shape({
    format: PropTypes.func,
  }),
  end: PropTypes.shape({
    format: PropTypes.func,
  }),
};

PaymentBarChart.defaultProps = {
  start: null,
  end: null,
};

export default PaymentBarChart;
