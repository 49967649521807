import React, { useReducer, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Modal, Button, Row, Col, Alert } from 'antd';
import { disableProjectService } from '../../services/projects';
import { getAPIErrorMessage } from '../../helpers/utils';

function DisableProjectModal(props) {
  const { isOpen, onOk, onClose, merchant, project } = props;
  const [state, dispatch] = useReducer((prevState, action) => {
    switch (action.type) {
      case 'DISABLE_PROJECT':
        return {
          ...prevState,
          isDisabling: true,
        };
      case 'DISABLE_PROJECT_SUCCESS':
        return {
          ...prevState,
          isDisabling: false,
          status: 'success',
          message: null,
        };
      case 'DISABLE_PROJECT_FAILED':
        return {
          ...prevState,
          isDisabling: false,
          status: 'error',
          message: action.message,
        };
      default:
        return prevState;
    }
  }, {
    isDisabling: false,
    status: null,
    message: null,
  });

  useEffect(() => {
    if (state.status === 'success') {
      onOk();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.status]);

  async function disableProject() {
    dispatch({ type: 'DISABLE_PROJECT' });

    try {
      await disableProjectService(merchant.id, project.id);
      dispatch({
        type: 'DISABLE_PROJECT_SUCCESS',
      });
    } catch (error) {
      const message = getAPIErrorMessage(error,
        'We are not able to disable this project. Please try again later');
      dispatch({
        type: 'DISABLE_PROJECT_FAILED',
        message,
      });
    }
  }

  return (
    <Modal
      title="Disable Project"
      className="dialog-modal"
      visible={isOpen}
      onCancel={onClose}
      footer={[
        <Button
          key="cancel"
          loading={state.isDisabling}
          disabled={state.isDisabling}
          onClick={onClose}
        >
          Cancel
        </Button>,
        <Button
          key="submit"
          type="danger"
          htmlType="submit"
          loading={state.isDisabling}
          disabled={state.isDisabling}
          onClick={disableProject}
        >
          Disable
        </Button>,
      ]}
    >
      {state.status && state.message && (
        <div style={{ marginBottom: '8px' }}>
          <Row>
            <Col span={24}>
              <Alert
                message={<p style={{ marginBottom: 0 }}>{state.message}</p>}
                type={state.status}
                showIcon
                closable
                onClose={() => dispatch({ type: 'CLEAR_MESSSAGE' })}
              />
            </Col>
          </Row>
        </div>
      )}
      <p>
        This will disable the current project.
        Disabling the project will set the status to disabled.
      </p>
    </Modal>
  );
}

DisableProjectModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onOk: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  merchant: PropTypes.shape({
    id: PropTypes.number.isRequired,
    code: PropTypes.string.isRequired,
  }).isRequired,
  project: PropTypes.shape({
    id: PropTypes.number.isRequired,
    key: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    isActive: PropTypes.bool.isRequired,
    isEnabled: PropTypes.bool.isRequired,
  }).isRequired,
};

export default DisableProjectModal;
