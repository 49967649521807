import React, { useReducer, Fragment } from 'react';
import PropTypes from 'prop-types';
import {
  Form, Input, Button, Select, Modal, Row, Col, Icon, Alert,
} from 'antd';
import { completeOfflinePayment } from '../../services/payments';
import { formatNumber } from '../../helpers/utils';
import '../../styles/modal.scss';

const initialState = {
  isCompleteOfflinePaymentModalVisible: false,
  isReasonNotesRequired: false,
  isSavingPayment: false,
  status: null,
  message: null,
  bill: {
    totalCurrency: 'PHP',
    totalAmount: 0,
    convertedCurrency: 'PHP',
    convertedAmount: 0,
    feeAmount: 0,
    exchangeRate: 0,
  },
};

function reducer(prevState, action) {
  switch (action.type) {
    case 'SHOW_MODAL':
      return {
        ...prevState,
        isCompleteOfflinePaymentModalVisible: true,
      };
    case 'HIDE_MODAL':
      return {
        ...prevState,
        isCompleteOfflinePaymentModalVisible: false,
      };
    case 'COMPLETE_PAYMENT':
      return {
        ...prevState,
        isSavingPayment: true,
      };
    case 'COMPLETE_PAYMENT_SUCCESS':
      return {
        ...prevState,
        isSavingPayment: false,
      };
    case 'COMPLETE_PAYMENT_FAILED':
      return {
        ...prevState,
        isSavingPayment: false,
        status: 'error',
        message: action.message,
      };
    case 'SET_TOTAL_CURRENCY':
      return {
        ...prevState,
        bill: {
          ...prevState.bill,
          totalCurrency: action.totalCurrency,
        },
      };
    case 'SET_TOTAL_AMOUNT':
      return {
        ...prevState,
        bill: {
          ...prevState.bill,
          totalAmount: Number(action.totalAmount),
        },
      };
    case 'SET_CONVERTED_DETAILS':
      return {
        ...prevState,
        bill: {
          ...prevState.bill,
          convertedCurrency: action.convertedCurrency,
          convertedAmount: Number(action.convertedAmount),
        },
      };
    case 'SET_FEE_AMOUNT':
      return {
        ...prevState,
        bill: {
          ...prevState.bill,
          feeAmount: Number(action.feeAmount),
        },
      };
    case 'CLEAR_MESSAGE':
      return {
        ...prevState,
        status: null,
        message: null,
      };
    default:
      return prevState;
  }
}

const CompleteOfflinePaymentModal = (props) => {
  const { form, payment, history } = props;
  const { merchantCode, paymentReferenceId } = payment;
  const [state, dispatch] = useReducer(reducer, initialState);

  const { Option } = Select;
  const { TextArea } = Input;

  function onSubmit(e) {
    e.preventDefault();

    form.validateFields(async (err, values) => {
      if (err) {
        dispatch({
          type: 'COMPLETE_PAYMENT_FAILED',
          message: 'Please double check the errors on the complete payment form',
        });
        return;
      }

      dispatch({ type: 'COMPLETE_PAYMENT' });
      const { billTotalCurrency, source, origin, notes } = values;

      const billTotalAmount = Number(values.billTotalAmount);
      if (Number.isNaN(billTotalAmount)) {
        dispatch({
          type: 'COMPLETE_PAYMENT_FAILED',
          message: 'The total amount is not a number',
        });
        return;
      }

      const billFeeAmount = Number(values.billFeeAmount);
      if (Number.isNaN(billFeeAmount)) {
        dispatch({
          type: 'COMPLETE_PAYMENT_FAILED',
          message: 'Convenience fee is not a number',
        });
        return;
      }

      const billFeeCurrency = billTotalCurrency;
      const convertedCurrency = billTotalCurrency;
      const convertedAmount = billTotalAmount - billFeeAmount;
      const exchangeRateBaseCurrency = payment.billBase[0];
      const exchangeRateConvertedCurrency = billTotalCurrency;
      const exchangeRateAmount = convertedAmount === 0 ? 0 : formatNumber(payment.billBase[1] / convertedAmount);
      try {
        const data = {
          billTotalCurrency,
          billTotalAmount,
          billFeeCurrency,
          billFeeAmount,
          convertedCurrency,
          convertedAmount,
          exchangeRateBaseCurrency,
          exchangeRateConvertedCurrency,
          exchangeRateAmount,
          paymentMethodSource: source,
          paymentMethodOrigin: origin,
          paymentMethodNotes: notes,
        };
        const response = await completeOfflinePayment(merchantCode, paymentReferenceId, data);
        const { message } = response.data;
        dispatch({ type: 'COMPLETE_PAYMENT_SUCCESS', message });

        history.push(`/payments/${payment.invoiceId}`);
      } catch (error) {
        const message = error && error.response
          ? error.response.data.message
          : 'Unable to set payment status to refunded as of the moment';
        dispatch({ type: 'COMPLETE_PAYMENT_FAILED', message });
      }
    });
  }

  if (payment.paymentStatus !== 'PENDING') {
    return <Fragment />;
  }

  if (payment.paymentMethodName !== 'offline') {
    return <Fragment />;
  }

  const { bill } = state;
  const { totalCurrency } = bill;
  const [billBaseCurrency, billBaseAmount] = payment.billBase;
  const totalAmount = Number(bill.totalAmount);
  const feeAmount = Number(bill.feeAmount);
  const convertedCurrency = totalCurrency;
  const convertedAmount = totalAmount - feeAmount;
  const exchangeRate = convertedAmount === 0 ? 0 : formatNumber(billBaseAmount / convertedAmount);

  return (
    <div>
      <Button
        className="button button-standard"
        onClick={() => dispatch({ type: 'SHOW_MODAL' })}
      >
        Complete Payment
      </Button>
      <Modal
        title="Complete Payment"
        className="dialog-modal"
        visible={state.isCompleteOfflinePaymentModalVisible}
        onCancel={() => dispatch({ type: 'HIDE_MODAL' })}
        footer={[
          <Button
            key="cancel"
            onClick={() => dispatch({ type: 'HIDE_MODAL' })}
          >
            Cancel
          </Button>,
          <Button
            key="submit"
            type="primary"
            htmlType="submit"
            onClick={onSubmit}
            loading={state.isSavingPayment}
          >
            Complete Payment
          </Button>,
        ]}
      >
        <Row>
          <Col span={3}>
            <Icon type="info-circle" style={{ color: '#399DE5', fontSize: 30, paddingLeft: 10, paddingTop: 5 }} />
          </Col>
          <Col span={21}>
            <p>Completing this payment will change the status to PAID and it will be ready for settlement. Review and check the details before completing this form.</p>
          </Col>
        </Row>
        {state.status && state.message && (
          <Alert
            style={{ marginBottom: '12px' }}
            type={state.status}
            description={state.message}
            closable
            onClose={() => dispatch({ type: 'CLEAR_MESSAGE' })}
          />
        )}
        <Form onSubmit={onSubmit}>
          <Row style={{ marginBottom: '8px' }}>
            <Col span={8}>
              <div className="text-secondary"><strong>Amount Due</strong></div>
              <div className="text-primary">
                {`${payment.billBase[0]} ${formatNumber(payment.billBase[1])}`}
              </div>
            </Col>
            <Col span={8}>
              <div className="text-secondary"><strong>Converted Due</strong></div>
              <div className="text-primary">{`${totalCurrency} ${formatNumber(totalAmount - feeAmount)}`}</div>
            </Col>
            <Col span={8}>
              {billBaseCurrency !== convertedCurrency
                ? (
                  <div>
                    <div className="text-secondary"><strong>Exchange Rate</strong></div>
                    <div className="text-primary">
                      {`1 ${totalCurrency} - ${billBaseCurrency} ${exchangeRate}`}
                    </div>
                  </div>
                ) : (
                  <div>
                    <div className="text-secondary"><span style={{ color: '#ffffff' }}>Exchange Rate</span></div>
                    <div className="text-primary">
                      <span style={{ color: '#ffffff' }}>
                        {`1 ${totalCurrency} - ${billBaseCurrency} ${exchangeRate}`}
                      </span>
                    </div>
                  </div>
                )}
            </Col>
          </Row>
          <Form.Item label="Currency">
            {form.getFieldDecorator('billTotalCurrency', {
              initialValue: 'PHP',
            })(
              <Select onChange={e => dispatch({ type: 'SET_TOTAL_CURRENCY', totalCurrency: e })}>
                <Option value="PHP">PHP</Option>
                <Option value="USD">USD</Option>
              </Select>,
            )}
          </Form.Item>
          <Form.Item label="Total Amount">
            {form.getFieldDecorator('billTotalAmount', {
              rules: [{ required: true, message: 'Total Amount is required.' }],
              initialValue: 0,
            })(<Input
              type="number"
              onChange={e => dispatch({ type: 'SET_TOTAL_AMOUNT', totalAmount: Number(e.target.value) })}
            />)}
          </Form.Item>
          <Form.Item label="Convenience Fee">
            {form.getFieldDecorator('billFeeAmount', {
              rules: [{ required: true, message: 'Fee is required.' }],
              initialValue: 0,
            })(<Input
              type="number"
              onChange={e => dispatch({ type: 'SET_FEE_AMOUNT', feeAmount: Number(e.target.value) })}
            />)}
          </Form.Item>
          <Form.Item label="Source">
            {form.getFieldDecorator('source', {
              rules: [{ required: true, message: 'Source is required.' }],
            })(<Input
              type="text"
              placeholder="Where did the customer deposit the payment?"
            />)}
          </Form.Item>
          <Form.Item label="Origin">
            {form.getFieldDecorator('origin', {
              rules: [{ required: true, message: 'Origin is required.' }],
            })(<Input
              type="text"
              placeholder="Where did the payment originate?"
            />)}
          </Form.Item>
          <Form.Item label="Notes">
            {form.getFieldDecorator('notes', {
              rules: [{ required: false }],
            })(
              <TextArea autoSize={{ maxRows: 8, minRows: 3 }} allowClear />,
            )}
          </Form.Item>
        </Form>
      </Modal>
    </div>
  );
};

CompleteOfflinePaymentModal.propTypes = {
  form: PropTypes.shape({
    getFieldDecorator: PropTypes.func.isRequired,
    validateFields: PropTypes.func.isRequired,
  }).isRequired,
  payment: PropTypes.shape({
    invoiceId: PropTypes.string.isRequired,
    merchantCode: PropTypes.string.isRequired,
    paymentReferenceId: PropTypes.string,
    billTotal: PropTypes.array.isRequired,
    billBase: PropTypes.array.isRequired,
    paymentStatus: PropTypes.string.isRequired,
    paymentMethodName: PropTypes.string.isRequired,
  }).isRequired,
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }).isRequired,
};

export default Form.create()(CompleteOfflinePaymentModal);
