/* Contains utility functions related to forms */

const REGEX_EMAIL = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
/*
  Params:
    rule - set of rules for validations (e.g. required, max). Extend as needed
    value - the value to be validated
*/
function getValidationErrors(rules, value) {
  const errors = [];
  (rules || []).forEach((rule) => {
    const invalid = (
      (rule.required && !value)
      || (rule.max && typeof value === 'string' && value.length > rule.max)
      || (rule.max && typeof value === 'number' && value > rule.max)
      || (rule.min && typeof value === 'string' && value.length < rule.min)
      || (rule.min && typeof value === 'number' && value < rule.min)
      || (rule.type && rule.type === 'email' && value && !value.match(REGEX_EMAIL))
      || (rule.type && rule.type === 'custom' && rule.fn())
    );

    if (invalid) {
      errors.push(rule.message);
    }
  });

  return errors;
}

export default getValidationErrors;
