import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

const SingleForm = (props) => {
  const {
    children,
    footer,
    subtitle,
    title,
  } = props;

  return (
    <section className="single-form">
      <div className="single-form-card">
        <div className="single-form-card-logo">
          <img src="https://cdn.aqwire.io/assets/aqwire/aqwire-logo-tagline.svg" alt="Aqwire Logo" />
          <h3>Enterprise Dashboard</h3>
        </div>
        {title && (
          <div className="single-form-card-title">
            <h4>{title}</h4>
            <p>{subtitle}</p>
          </div>
        )}
        {children}
      </div>
      {footer.show && (
        <Link to={footer.to} className="single-form-footer-action">
          <div className="single-form-footer-action-text">{footer.text}</div>
        </Link>
      )}
    </section>
  );
};

SingleForm.propTypes = {
  children: PropTypes.element,
  footer: PropTypes.shape({
    show: PropTypes.bool,
    to: PropTypes.string,
    text: PropTypes.string,
  }),
  title: PropTypes.string,
  subtitle: PropTypes.string,
};

SingleForm.defaultProps = {
  children: null,
  footer: {
    show: false,
    to: '/login',
    text: 'Back to login',
  },
  title: null,
  subtitle: null,
};

export default SingleForm;
