import moment from 'moment-timezone';
import React, { useReducer, useEffect, useContext } from 'react';
import { useLocation } from 'react-router-dom';
import qs from 'query-string';
import PropTypes from 'prop-types';
import {
  Button, List, Timeline, Form, Input, Row, Col,
  Menu, Icon, Dropdown, Collapse,
} from 'antd';
import { InfoCircleIcon } from '../Icons';
import {
  getEnrollmentLogsService, submitEnrollmentLogService, removeEnrollmentLogService,
} from '../../services/logs';
import SessionContext from '../../contexts/SessionContext';
import { getAPIErrorMessage } from '../../helpers/utils';

const { TextArea } = Input;
const timeZone = moment.tz.guess(true);

const COMPONENT_STATES = {
  INIT: 0,
  GET_LOGS: 10,
  GET_LOGS_SUCCESSFUL: 11,
  GET_LOGS_FAILED: 12,
  SUBMIT_COMMENT: 20,
  SUBMIT_COMMENT_SUCCESSFUL: 21,
  SUBMIT_COMMENT_FAILED: 22,
  DELETE_COMMENT: 30,
  DELETE_COMMENT_SUCCESSFUL: 31,
  DELETE_COMMENT_FAILED: 32,
};

const getInitialState = queryFilters => ({
  cstate: COMPONENT_STATES.INIT,
  needsRefresh: true,
  comment: null,
  logs: [],
  searchOption: {
    page: parseInt(queryFilters.page, 10) || 1,
    size: parseInt(queryFilters.size, 10) || 10,
    totalCount: 0,
  },
  status: null,
  message: null,
});

function reducer(prevState, action) {
  switch (action.type) {
    case 'GET_LOGS':
      return {
        ...prevState,
        cstate: COMPONENT_STATES.GET_LOGS,
        status: null,
        message: null,
      };
    case 'GET_LOGS_SUCCESSFUL':
      return {
        ...prevState,
        cstate: COMPONENT_STATES.GET_LOGS_SUCCESSFUL,
        logs: action.logs,
        needsRefresh: false,
        searchOption: {
          ...prevState.searchOption,
          totalCount: action.totalCount,
        },
      };
    case 'GET_LOGS_FAILED':
      return {
        ...prevState,
        cstate: COMPONENT_STATES.GET_LOGS_FAILED,
        logs: [],
        status: null,
        message: action.message,
        needsRefresh: false,
        searchOption: {
          ...prevState.searchOption,
          totalCount: 0,
        },
      };
    case 'UPDATE_COMMENT':
      return {
        ...prevState,
        comment: action.comment,
      };
    case 'SUBMIT_COMMENT':
      return {
        ...prevState,
        status: null,
        message: null,
        cstate: COMPONENT_STATES.SUBMIT_COMMENT,
      };
    case 'SUBMIT_COMMENT_SUCCESSFUL':
      return {
        ...prevState,
        cstate: COMPONENT_STATES.SUBMIT_COMMENT_SUCCESSFUL,
        needsRefresh: true,
        comment: null,
      };
    case 'SUBMIT_COMMENT_FAILED':
      return {
        ...prevState,
        cstate: COMPONENT_STATES.SUBMIT_COMMENT_FAILED,
        status: 'error',
        message: action.message,
      };
    case 'DELETE_COMMENT':
      return {
        ...prevState,
        cstate: COMPONENT_STATES.DELETE_COMMENT,
        status: null,
        message: null,
        needsRefresh: true,
      };
    case 'DELETE_COMMENT_SUCCESSFUL':
      return {
        ...prevState,
        cstate: COMPONENT_STATES.DELETE_COMMENT_SUCCESSFUL,
        needsRefresh: true,
      };
    case 'DELETE_COMMENT_FAILED':
      return {
        ...prevState,
        cstate: COMPONENT_STATES.DELETE_COMMENT_FAILED,
        status: 'error',
        message: action.message,
      };
    default:
      return prevState;
  }
}

const EnrollmentLogs = (props) => {
  const { enrollment, user, form, onChangeSearchOption } = props;
  const sessionContext = useContext(SessionContext);
  const location = useLocation();
  const queryFilters = qs.parse(location.search, { ignoreQueryPrefix: true });
  const [state, dispatch] = useReducer(reducer, getInitialState(queryFilters));

  useEffect(() => {
    let ableToSet = true;

    (async () => {
      if (!state.needsRefresh) { return; }

      if (ableToSet) {
        dispatch({ type: 'GET_LOGS' });
      }
      try {
        if (!enrollment.transactionId && ableToSet) {
          dispatch({ type: 'GET_LOGS_SUCCESSFUL', logs: [], totalCount: 0 });
          return;
        }
        const response = await getEnrollmentLogsService(enrollment.transactionId, state.searchOption);
        const { logs, totalCount } = response.data;
        if (ableToSet) {
          dispatch({ type: 'GET_LOGS_SUCCESSFUL', logs, totalCount });
        }
      } catch (error) {
        const message = error && error.response ? error.response.data.message
          : 'Unable to get the invoice logs as of the moment';
        if (ableToSet) {
          dispatch({ type: 'GET_LOGS_FAILED', message });
        }
      }
    })();

    return () => { ableToSet = false; };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.needsRefresh]);

  useEffect(() => {
    if (state.status && state.message) {
      sessionContext.showToast({ message: state.message, type: state.status });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.status, state.message]);

  async function onSubmitComment(e) {
    e.preventDefault();

    dispatch({ type: 'SUBMIT_COMMENT' });
    form.validateFields(async (err, formFields) => {
      if (err) {
        dispatch({
          type: 'SUBMIT_COMMENT_FAILED',
          message: 'You must write something on the comment',
        });
        return;
      }

      try {
        const response = await submitEnrollmentLogService(enrollment.transactionId, formFields);
        const { message } = response.data;
        dispatch({ type: 'SUBMIT_COMMENT_SUCCESSFUL', message });
        form.resetFields();
      } catch (error) {
        const message = getAPIErrorMessage(error,
          'We are not able to log comment as of the moment');
        dispatch({ type: 'SUBMIT_COMMENT_FAILED', message });
      }
    });
  }

  async function removeLogItem(item) {
    dispatch({ type: 'DELETE_COMMENT' });
    try {
      const res = await removeEnrollmentLogService(enrollment.transactionId, item.logId);
      const { message } = res.data;
      dispatch({ type: 'DELETE_COMMENT_SUCCESSFUL', message });
    } catch (error) {
      const message = getAPIErrorMessage(error,
        'We are not able to delete comment as of the moment');
      dispatch({ type: 'DELETE_COMMENT_FAILED', message });
    }
  }

  const customPanelStyle = {
    background: '#F5F5F5',
    borderRadius: 0,
    marginBottom: 8,
    border: 0,
    overflow: 'hidden',
  };
  function renderListItem(item) {
    return (
      <div>
        <Timeline.Item color="#30CCD8">
          <div style={{ marginBottom: '4px' }}>
            <strong className="text-primary" style={{ marginRight: '8px' }}>
              {item.createdByName}
            </strong>
            <span className="text-secondary" style={{ marginRight: '8px' }}>
              {moment(item.createdAt).tz(timeZone).format('MMMM Do YYYY, hh:mm:ss A')}
            </span>
            {user.id === item.createdById && (
              <Dropdown
                overlay={(
                  <Menu>
                    <Menu.Item key="1" onClick={() => removeLogItem(item)}>
                      Delete
                    </Menu.Item>
                  </Menu>
                )}
                trigger={['click']}
              >
                <Icon type="more" />
              </Dropdown>
            )}
          </div>
          <div className="text-primary" style={{ fontSize: '16px' }}>
            <div style={{ whiteSpace: 'pre-wrap', wordBreak: 'break-word' }}>{item.content}</div>
            {item.action && item.status && (
              <Collapse accordion bordered={false} style={{ marginTop: 12 }}>
                <Collapse.Panel header="View Log Data" key="1" style={customPanelStyle}>
                  <pre>
                    {JSON.stringify(item, null, 2)}
                  </pre>
                </Collapse.Panel>
              </Collapse>
            )}
          </div>
        </Timeline.Item>
      </div>
    );
  }

  const paginationSettings = {
    position: 'bottom',
    size: 'small',
    showTotal: (totalNo, range) => (
      range[0] !== range[1]
        ? `Showing ${range[0]} to ${range[1]} of ${totalNo} items`
        : `Showing 1 of ${totalNo} item`
    ),
    onChange: (p) => {
      const totalPage = Math.ceil(state.searchOption.totalCount / state.searchOption.size);
      const tab = 'logs';
      let page = p || 1;
      if (page < 1) { page = 1; }
      if (page > totalPage) { page = totalPage - 1; }

      onChangeSearchOption({
        ...state.searchOption,
        page,
        tab,
      });
    },
    current: state.searchOption.page,
    total: state.searchOption.totalCount,
    pageSize: state.searchOption.size,
  };

  return (
    <section className="panel panel-standard">
      <div className="panel-header">
        <Row>
          <Col lg={16} md={16}>
            <Form onSubmit={onSubmitComment}>
              <Form.Item label="Add a note about this payment">
                {form.getFieldDecorator('content', {
                  rules: [
                    { required: true, message: 'You must type something here' },
                  ],
                })(
                  <TextArea
                    placeholder="Write something here"
                    allowClear
                    disabled={state.cstate === COMPONENT_STATES.SUBMIT_COMMENT}
                    autoSize={{ minRows: 5, maxRows: 7 }}
                  />,
                )}
              </Form.Item>
              <Form.Item style={{ marginBottom: '4px' }}>
                <Button
                  disabled={state.cstate === COMPONENT_STATES.SUBMIT_COMMENT}
                  loading={state.cstate === COMPONENT_STATES.SUBMIT_COMMENT}
                  className="button button-standard"
                  type="primary"
                  htmlType="submit"
                >
                  Post
                </Button>
              </Form.Item>
            </Form>
          </Col>
        </Row>
      </div>
      <div className="panel-body">
        {state.logs.length > 0 ? (
          <Timeline>
            <List
              className="comment-list"
              itemLayout="horizontal"
              size="large"
              loading={state.cstate === COMPONENT_STATES.GET_LOGS}
              dataSource={state.logs}
              renderItem={renderListItem}
              pagination={paginationSettings}
            />
          </Timeline>
        ) : (
          <div style={{ display: 'flex' }}>
            <InfoCircleIcon diameter="24px" />
            <p style={{ marginLeft: '8px' }}>This payment has no logs here.</p>
          </div>
        )}
      </div>
    </section>
  );
};

EnrollmentLogs.propTypes = {
  enrollment: PropTypes.shape({
    transactionId: PropTypes.string.isRequired,
  }).isRequired,
  user: PropTypes.shape({
    id: PropTypes.number.isRequired,
    systemRole: PropTypes.array.isRequired,
  }).isRequired,
  onChangeSearchOption: PropTypes.func.isRequired,
  form: PropTypes.shape({
    getFieldDecorator: PropTypes.func.isRequired,
    validateFields: PropTypes.func.isRequired,
    resetFields: PropTypes.func.isRequired,
  }).isRequired,
};

export default Form.create({ name: 'submitComment' })(EnrollmentLogs);
