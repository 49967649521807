import React, { Fragment, useEffect, useReducer, useContext } from 'react';
import PropTypes from 'prop-types';
import FileIcon, { defaultStyles } from 'react-file-icon';
// import { PDFObject } from 'react-pdfobject';
import { Spin } from 'antd';
// import SessionContext from '../../contexts/SessionContext';
import { getPresignedDownloadUrl } from '../../services/settlements';
import SessionContext from '../../contexts/SessionContext';
// import { removeFileService } from '../../services/uploads';
// import { CloseIcon } from '../Icons';

import './filedisplay.css';

const initialState = {
  isFetching: false,
  presignedUrl: null,
  fileName: null,
  fileType: null,
  message: null,
  status: null,
};

function reducer(prevState, action) {
  switch (action.type) {
    case 'GET_PRESIGNED_URL':
      return {
        ...prevState,
        isFetching: true,
      };
    case 'GET_PRESIGNED_URL_SUCCESS':
      return {
        ...prevState,
        isFetching: false,
        presignedUrl: action.presignedUrl,
        fileName: action.fileName,
        fileType: action.fileType,
        status: 'success',
        message: null,
      };
    case 'GET_PRESIGNED_URL_FAILURE':
      return {
        ...prevState,
        isFetching: false,
        status: 'error',
        message: action.message,
      };
    default:
      return prevState;
  }
}

const FileDisplay = (props) => {
  const { merchant, settlementFileId } = props;
  const [state, dispatch] = useReducer(reducer, initialState);
  const { merchants } = useContext(SessionContext);
  const activeMerchant = merchants.find(m => m.code === merchant);

  useEffect(() => {
    let ableToSet = true;

    (async () => {
      dispatch({ type: 'GET_PRESIGNED_URL' });

      try {
        const response = await getPresignedDownloadUrl(activeMerchant.id, settlementFileId);

        const { fileName, fileType, presignedUrl } = response.data;
        if (ableToSet) {
          dispatch({
            type: 'GET_PRESIGNED_URL_SUCCESS',
            presignedUrl,
            fileName,
            fileType,
          });
        }

        // if (!withPreview) window.open(presignedUrl);
      } catch (error) {
        const message = error && error.response ? error.response.data.message
          : 'Unable to get the settlement file as of the moment';
        if (ableToSet) {
          dispatch({ type: 'GET_PRESIGNED_URL_FAILED', message });
        }
      }
    })();

    return () => { ableToSet = false; };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [merchant, settlementFileId]);

  // async function getDownloadUrl(e) {
  //   if (e) e.preventDefault();
  //   if (!state.fileUrl) {
  //     dispatch({ type: 'GET_FILEURL_REQUEST' });
  //     try {
  //       const { data } = await getPresignedDownloadUrl(merchant, settlementFileId);
  //       const { file_upload: fileUpload, presigned_url: presignedUrl } = data;
  //       dispatch({ type: 'GET_FILEURL_SUCCESS', fileUpload, presignedUrl });
  //       if (!withPreview) window.open(presignedUrl);
  //     } catch (error) {
  //       const message = error && error.response ? error.response.data.message
  //         : 'Unable to get the settlement file as of the moment';
  //       dispatch({ type: 'GET_FILEURL_FAILURE' });
  //       showToast({ type: 'error', message });
  //     }
  //   } else window.open(state.fileUrl);
  // }

  // async function deleteSettlementFile() {
  //   dispatch({ type: 'REMOVE_FILE_REQUEST' });
  //   try {
  //     const { data } = await removeFileService(fileId, merchantCode);
  //     const { response, result } = data;
  //     dispatch({ type: 'REMOVE_FILE_SUCCESS', response, result });
  //     onRemoveCallback(true);
  //   } catch (error) {
  //     const message = error && error.response ? error.response.data.message
  //       : 'Unable to get the settlement file as of the moment';
  //     dispatch({ type: 'REMOVE_FILE_FAILURE' });
  //     showToast({ type: 'error', message });
  //     onRemoveCallback(false);
  //   }
  // }

  // function renderPreview() {
  //   const { fileContentType, fileName, fileUrl } = state;

  //   if (fileContentType.indexOf('pdf') > -1) {
  //     return (
  //       <div className="file-preview">
  //         <PDFObject
  //           url={fileUrl}
  //           pdfOpenParams={{
  //             navpanes: 1,
  //             view: 'FitH',
  //             pagemode: 'thumbs',
  //           }}
  //         />
  //       </div>
  //     );
  //   }

  //   if (fileContentType.indexOf('image') > -1) {
  //     return (
  //       <div className="file-preview">
  //         <img src={fileUrl} alt="File preview" />
  //       </div>
  //     );
  //   }

  //   return <div className="file-preview">{fileName}</div>;
  // }

  if (state.isFetching) {
    return <Spin />;
  }

  const searchExtensionResults = /.[a-z]+$/.exec(state.fileName);
  const fileExtension = searchExtensionResults ? searchExtensionResults[0] : null;
  return (
    <Fragment>
      <div className="file-icon-wrapper">
        <a
          className="file-icon"
          href={state.presignedUrl}
          onClick={() => {}}
          target="_blank"
          rel="noopener noreferrer"
        >
          <Fragment>
            <FileIcon extension={fileExtension} {...defaultStyles[fileExtension]} />
            <span>{state.fileName}</span>
          </Fragment>
        </a>
        {/*
        {removeable && (
          <Tooltip title="Delete file">
            <button type="button" className="delete-button" onClick={deleteSettlementFile}>
              <CloseIcon />
            </button>
          </Tooltip>
        )}
        */}
      </div>
      {/* {withPreview && fileContentType && renderPreview()} */}
    </Fragment>
  );
};

FileDisplay.propTypes = {
  merchant: PropTypes.shape({
    code: PropTypes.string.isRequired,
  }).isRequired,
  settlementFileId: PropTypes.number.isRequired,
};

FileDisplay.defaultProps = {
};

export default FileDisplay;
