import moment from 'moment-timezone';
import React, { Fragment, useEffect, useReducer } from 'react';
import PropTypes from 'prop-types';
import qs from 'query-string';
import { Link, useLocation } from 'react-router-dom';
import { Row, Col, DatePicker, Table, Select } from 'antd';
import { getPaymentsService } from '../../services/payments';
import { getProjectsService } from '../../services/projects';
import { getPaymentTypesService } from '../../services/merchants';
import { formatNumber, getDefaultCreatedDate } from '../../helpers/utils';
import '../../styles/transaction.css';

const dateFormat = 'YYYY-MM-DD';
const { defaultEndDate, defaultStartDate } = getDefaultCreatedDate(dateFormat);

function getInitialState(selectedPayments, queryFilters, settlementReferenceId) {
  return {
    isFetchingPayments: true,
    payments: [],
    selectedPayments,
    projects: [],
    paymentTypes: [],
    searchOption: {
      page: parseInt(queryFilters.page, 10) || 1,
      size: 10,
      totalCount: 0,
      startDate: moment(queryFilters.retrieve === 'all' ? null : (queryFilters.start || defaultStartDate), dateFormat),
      endDate: moment(queryFilters.retrieve === 'all' ? null : (queryFilters.end || defaultEndDate), dateFormat),
      reportingDate: moment(queryFilters.reporting || null, dateFormat),
      paymentType: queryFilters.paymentType || null,
      project: queryFilters.project || null,
      projectName: queryFilters.projectName || null,
      projectCategory: queryFilters.projectCategory || null,
      query: null,
      settlementReferenceId,
      status: ['PAID'],
      retrieve: queryFilters.retrieve || null,
    },
  };
}

function reducer(prevState, action) {
  switch (action.type) {
    case 'GET_PAYMENTS':
      return {
        ...prevState,
        isFetchingPayments: true,
      };
    case 'GET_PAYMENTS_SUCCESSFUL':
      return {
        ...prevState,
        isFetchingPayments: false,
        payments: action.payments,
        searchOption: { ...prevState.searchOption, totalCount: action.totalCount },
      };
    case 'GET_PAYMENTS_FAILED':
      return {
        ...prevState,
        isFetchingPayments: false,
        payments: [],
      };
    case 'SET_PROJECTS_FILTER':
      return {
        ...prevState,
        projects: action.projects,
      };
    case 'SET_PAYMENT_TYPES_FILTER':
      return {
        ...prevState,
        paymentTypes: action.paymentTypes,
      };
    case 'UPDATE_SEARCH_OPTION':
      return {
        ...prevState,
        isFetchingPayments: true,
        searchOption: action.searchOption,
      };
    case 'SET_SELECTED_PAYMENTS':
      return {
        ...prevState,
        selectedPayments: action.selectedPayments,
      };
    default:
      return prevState;
  }
}

const { RangePicker } = DatePicker;
const SettlementPaymentsTable = (props) => {
  const {
    filters, merchant, selectedPayments,
    onChangeSearchOption, onSelection,
  } = props;
  const { settlementReferenceId, showFilterFields } = filters;

  const timeZone = moment.tz.guess(true);

  const location = useLocation();
  const queryFilters = qs.parse(location.search, { ignoreQueryPrefix: true });
  const initialState = getInitialState(selectedPayments, queryFilters, settlementReferenceId);
  const [state, dispatch] = useReducer(reducer, initialState);

  useEffect(() => {
    let ableToSet = true;
    (async () => {
      try {
        const status = state.searchOption.status
          .reduce((pv, cv) => `${pv},${cv}`, '')
          .slice(1);
        const paymentsResponse = await getPaymentsService(merchant.id, {
          ...state.searchOption,
          status,
        });
        const { payments, totalCount } = paymentsResponse.data;

        if (ableToSet) {
          onChangeSearchOption(state.searchOption);
          dispatch({ type: 'GET_PAYMENTS_SUCCESSFUL', payments, totalCount });
        }
      } catch (error) {
        const message = error && error.response ? error.response.data.message
          : 'We are not able to get the payments from the server as of the moment';
        if (ableToSet) {
          dispatch({ type: 'GET_PAYMENTS_FAILED', message });
        }
      }
    })();

    return () => { ableToSet = false; };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    state.searchOption.page,
    state.searchOption.query,
    state.searchOption.status,
    state.searchOption.size,
    state.searchOption.startDate,
    state.searchOption.endDate,
    state.searchOption.project,
    state.searchOption.projectName,
    state.searchOption.projectCategory,
    state.searchOption.paymentType,
    state.searchOption.reportingDate,
  ]);

  useEffect(() => {
    let ableToSet = true;

    (async () => {
      try {
        // Current limit for querying the projects
        const opts = { page: 1, size: 150 };
        const projectsResponse = await getProjectsService(merchant.id, opts);
        const paymentTypeResponse = await getPaymentTypesService(merchant.id);
        const { projects } = projectsResponse.data;
        const { paymentTypes } = paymentTypeResponse.data;
        if (ableToSet) {
          dispatch({ type: 'SET_PROJECTS_FILTER', projects });
          dispatch({ type: 'SET_PAYMENT_TYPES_FILTER', paymentTypes });
        }
      } catch (error) {
        if (ableToSet) {
          dispatch({ type: 'SET_PROJECTS_FILTER', projects: [] });
          dispatch({ type: 'SET_PAYMENT_TYPES_FILTER', paymentTypes: [] });
        }
      }
    })();
    return () => { ableToSet = false; };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    onSelection(state.selectedPayments);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.selectedPayments]);

  function onSelect(payment, isSelected) {
    if (isSelected) {
      const items = state.selectedPayments.concat([payment]);
      dispatch({ type: 'SET_SELECTED_PAYMENTS', selectedPayments: items });
    } else {
      const items = Array.from(state.selectedPayments);
      const index = state.selectedPayments.findIndex(p => p.invoiceId === payment.invoiceId);
      items.splice(index, 1);
      dispatch({ type: 'SET_SELECTED_PAYMENTS', selectedPayments: items });
    }
  }

  function onSelectAll(selected, _, changedRows) {
    if (selected) {
      const items = state.selectedPayments.concat(changedRows);
      dispatch({ type: 'SET_SELECTED_PAYMENTS', selectedPayments: items });
    } else {
      const items = Array
        .from(state.selectedPayments)
        .filter(p => !changedRows.find(c => c.invoiceId === p.invoiceId));
      dispatch({ type: 'SET_SELECTED_PAYMENTS', selectedPayments: items });
    }
  }

  function getUniqueObjectValue(value) {
    const objectValues = [];
    state.projects.map(p => objectValues.push(p[value]));
    return Array.from(new Set(objectValues)).sort();
  }

  const columns = [
    {
      title: 'ID',
      key: 'invoiceId',
      render: row => (
        <Link
          to={`/payments/${row.invoiceId}`}
          target="_blank"
          rel="noopener noreferrer"
        >
          <strong>{row.invoiceId}</strong>
        </Link>
      ),
    }, {
      title: 'Project Name',
      key: 'projectName',
      render: row => (
        <div>
          {(({ projectName }) => {
            if (projectName) { return projectName; }
            return 'Unknown';
          })(row)}
        </div>
      ),
    }, {
      title: 'Project Category',
      key: 'projectCategory',
      render: row => (
        <div>
          {(({ projectCategory }) => {
            if (projectCategory) { return projectCategory; }
            return 'Unknown';
          })(row)}
        </div>
      ),
    }, {
      title: 'Payment type',
      key: 'type',
      render: row => (
        <div className="text-primary">{row.paymentTypeName || '-----'}</div>
      ),
    }, {
      title: 'Customer',
      key: 'customer',
      render: row => (
        <div>
          <div className="text-primary">{row.customerName || '-----'}</div>
        </div>
      ),
    }, {
      title: 'Net amount',
      key: 'amount',
      render: row => (
        <Fragment>
          {/* <div style={{ marginBottom: '8px' }}>
            <span
              style={{
                fontWeight: 700,
                color: '#ffffff',
                backgroundColor: getStatusColor(row.paymentStatus),
                borderRadius: '4px',
                padding: '4px 8px',
              }}
            >
              {row.paymentStatus}
            </span>
          </div> */}
          <div className="text-primary">
            {row.net
              ? `${row.net[0]} ${formatNumber(row.net[1])}`
              : '-----'}
          </div>
        </Fragment>
      ),
    }, {
      title: 'Date',
      key: 'paidAt',
      render: (row) => {
        const dt = row.paidAt || row.invoiceCreatedAt;
        return (
          <div className="table-col-item">
            <div className="text-primary">
              {moment(dt).tz(timeZone).format('lll')}
            </div>
          </div>
        );
      },
    },
  ];

  const pagination = {
    size: 'small',
    current: state.searchOption.page,
    defaultCurrent: 1,
    defaultPageSize: 10,
    pageSize: state.searchOption.size,
    total: state.searchOption.totalCount,
    showSizeChanger: true,
    pageSizeOptions: ['10', '20', '50', '100'],
    onShowSizeChange: (currentPage, size) => {
      dispatch({
        type: 'UPDATE_SEARCH_OPTION',
        searchOption: {
          ...state.searchOption,
          page: 1,
          size: Number(size),
        },
      });
    },
    onChange: (p) => {
      const totalPage = Math.ceil(state.searchOption.totalCount / state.searchOption.size);
      let page = p || 1;
      if (page < 1) { page = 1; }
      if (page > totalPage) { page = totalPage - 1; }

      dispatch({
        type: 'UPDATE_SEARCH_OPTION',
        searchOption: {
          ...state.searchOption,
          page,
        },
      });
    },
    showTotal: (total, range) => `Showing ${range[0]} - ${range[1]} of ${total}`,
  };

  const actions = (
    <Fragment>
      <Row gutter={8} className="filters" style={{ marginBottom: '12px' }}>
        <Col lg={4}>
          <h4 className="text-secondary" style={{ padding: '12px 0 0' }}>Paid date</h4>
          <RangePicker
            className="transaction-filter"
            defaultValue={[
              state.searchOption.startDate.isValid() ? state.searchOption.startDate : null,
              state.searchOption.endDate.isValid() ? state.searchOption.endDate : null,
            ]}
            format={dateFormat}
            style={{ width: '100%' }}
            onChange={([start, end]) => {
              const startDate = start ? start.format(dateFormat) : null;
              const endDate = end ? end.format(dateFormat) : null;
              dispatch({
                type: 'UPDATE_SEARCH_OPTION',
                searchOption: {
                  ...state.searchOption,
                  startDate: moment(startDate, dateFormat),
                  endDate: moment(endDate, dateFormat),
                },
              });
            }}
          />
        </Col>
        <Col lg={5}>
          <h4 className="text-secondary" style={{ padding: '12px 0 0' }}>Report date</h4>
          <DatePicker
            className="transaction-filter"
            defaultValue={state.searchOption.reportingDate.isValid() ? state.searchOption.reportingDate : null}
            format={dateFormat}
            style={{ width: '100%' }}
            placeholder="Reporting date"
            onChange={(date) => {
              const reporting = date ? date.format(dateFormat) : null;
              dispatch({
                type: 'UPDATE_SEARCH_OPTION',
                searchOption: {
                  ...state.searchOption,
                  reportingDate: moment(reporting, dateFormat),
                },
              });
            }}
          />
        </Col>
        <Col lg={5}>
          <h4 className="text-secondary" style={{ padding: '12px 0 0' }}>Project Name</h4>
          <Select
            className="transaction-filter"
            allowClear
            showSearch
            showArrow
            placeholder="Select project name"
            onSelect={(selectedProject) => {
              const project = state.projects.find(ps => ps.name === selectedProject);
              if (project) {
                dispatch({
                  type: 'UPDATE_SEARCH_OPTION',
                  searchOption: { ...state.searchOption, projectName: project.name },
                });
              }
            }}
            onChange={(selectedProject) => {
              if (!selectedProject) {
                dispatch({
                  type: 'UPDATE_SEARCH_OPTION',
                  searchOption: { ...state.searchOption, projectName: null },
                });
              }
            }}
            filterOption={(inputValue, option) => {
              const { props: { children } } = option;
              return children.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1;
            }}
            defaultValue={state.searchOption.projectName || undefined}
          >
            {getUniqueObjectValue('name').map(p => (
              <Select.Option value={p} key={p} label={p || 'Unknown'}>
                {p || 'Unknown'}
              </Select.Option>
            ))}
          </Select>
        </Col>
        <Col lg={5}>
          <h4 className="text-secondary" style={{ padding: '12px 0 0' }}>Project Category</h4>
          <Select
            className="transaction-filter"
            allowClear
            showSearch
            showArrow
            placeholder="Select project category"
            onSelect={(selectedProject) => {
              const project = state.projects.find(ps => ps.category === selectedProject);
              if (project) {
                dispatch({
                  type: 'UPDATE_SEARCH_OPTION',
                  searchOption: { ...state.searchOption, projectCategory: project.category },
                });
              }
            }}
            onChange={(selectedProject) => {
              if (!selectedProject) {
                dispatch({
                  type: 'UPDATE_SEARCH_OPTION',
                  searchOption: { ...state.searchOption, projectCategory: null },
                });
              }
            }}
            filterOption={(inputValue, option) => {
              const { props: { children } } = option;
              return children.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1;
            }}
            defaultValue={state.searchOption.projectCategory || undefined}
          >
            {getUniqueObjectValue('category').map(p => (
              <Select.Option value={p} key={p} label={p || 'Unknown'}>
                {p || 'Unknown'}
              </Select.Option>
            ))}
          </Select>
        </Col>
        <Col lg={5}>
          <h4 className="text-secondary" style={{ padding: '12px 0 0' }}>Payment type</h4>
          <Select
            className="transaction-filter"
            allowClear
            showSearch
            showArrow
            placeholder="Select a payment type"
            onSelect={(selectedPaymentType) => {
              const paymentType = state.paymentTypes.find(ps => ps.id === selectedPaymentType);
              if (paymentType) {
                dispatch({
                  type: 'UPDATE_SEARCH_OPTION',
                  searchOption: { ...state.searchOption, paymentType: paymentType.id },
                });
              }
            }}
            onChange={(selectedPaymentType) => {
              if (!selectedPaymentType) {
                dispatch({
                  type: 'UPDATE_SEARCH_OPTION',
                  searchOption: { ...state.searchOption, paymentType: null },
                });
              }
            }}
            filterOption={(inputValue, option) => {
              const { props: { children } } = option;
              return children.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1;
            }}
            defaultValue={state.searchOption.paymentType || undefined}
          >
            {state.paymentTypes.map(p => (
              <Select.Option value={p.id} key={p.id} label={p.name}>
                {p.name}
              </Select.Option>
            ))}
          </Select>
        </Col>
      </Row>
    </Fragment>
  );

  return (
    <Fragment>
      {showFilterFields && actions}
      <Table
        className="table-standard"
        dataSource={state.payments}
        loading={state.isFetchingPayments}
        columns={columns}
        pagination={pagination}
        rowKey="invoiceId"
        rowSelection={{
          selectedRowKeys: state.selectedPayments.map(i => i.invoiceId),
          onSelect,
          onSelectAll,
        }}
      />
    </Fragment>
  );
};

SettlementPaymentsTable.propTypes = {
  merchant: PropTypes.shape({
    id: PropTypes.number.isRequired,
    code: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
  }).isRequired,
  filters: PropTypes.shape({
    settlementReferenceId: PropTypes.string,
    showFilterFields: PropTypes.bool,
    showStatusMenu: PropTypes.bool,
    showSettlementColumn: PropTypes.bool,
  }),
  selectedPayments: PropTypes.arrayOf(PropTypes.shape({
    invoiceId: PropTypes.string.isRequired,
  })).isRequired,
  onSelection: PropTypes.func.isRequired,
  onChangeSearchOption: PropTypes.func,
};

SettlementPaymentsTable.defaultProps = {
  filters: {
    settlementReferenceId: null,
    settlementStatus: null,
    showFilterFields: true,
    showStatusMenu: true,
    showSettlementColumn: true,
  },
  onChangeSearchOption: () => {},
};

export default SettlementPaymentsTable;
