import React, { useEffect, useReducer } from 'react';
import HttpsRedirect from 'react-https-redirect';
import { BrowserRouter } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import { AppAlert } from '../components';
import SessionContext from '../contexts/SessionContext';
import { getAccountInfoService, loginService } from '../services/auth';
import { getMerchantsService, getAllRolesService, getMerchantRolesService, getAllMerchantCategories } from '../services/merchants';
import { terminateBeacon } from '../services/beacon-widget';
import { getOrGenerateClientId, getAPIErrorMessage } from '../helpers/utils';
import {
  ACCESS_TOKEN,
  USER_MERCHANT,
  CLIENT_ID,
  REFRESH_TOKEN,
  SELECTED_PAYMENTS,
  ACTIVE_SETTLEMENT_REFERENCE_ID,
  APP_STATES,
  ROLES,
} from '../constants';
import AuthenticatedLayout from './AuthenticatedLayout';
import NonAuthenticatedLayout from './NonAuthenticatedLayout';
import './App.css';
import 'react-toastify/dist/ReactToastify.min.css';

function getCachedMerchant() {
  try {
    return JSON.parse(localStorage.getItem(USER_MERCHANT));
  } catch (err) {
    return null;
  }
}

function getAccessToken() {
  try {
    return localStorage.getItem(ACCESS_TOKEN);
  } catch (err) {
    return null;
  }
}

const initialState = {
  isLoading: true,
  isAuthenticated: Boolean(getAccessToken()),
  appState: APP_STATES.INIT,
  merchants: [],
  merchantCategories: [],
  systemRoles: [],
  merchantRoles: [],
  activeMerchant: getCachedMerchant(),
  loggedInUser: null,
  isSearching: false,
  page: 1,
  limit: 10,
  totalCount: 0,
};

function reducer(prevState, action) {
  switch (action.type) {
    case 'LOGIN':
      return {
        ...prevState,
        isAuthenticated: true,
        appState: APP_STATES.LOGGED_IN,
      };
    case 'LOGOUT':
      return {
        isAuthenticated: false,
        merchants: [],
        loggedInUser: null,
        activeMerchant: null,
        appState: APP_STATES.NOT_AUTHENTICATED,
      };
    case 'GET_ACCOUNT_INFORMATION':
      return {
        ...prevState,
        isLoading: true,
        appState: APP_STATES.IS_LOADING,
      };
    case 'GET_ACCOUNT_INFORMATION_SUCCESSFUL':
      return {
        ...prevState,
        merchants: action.merchants,
        merchantCategories: action.merchantCategories,
        systemRoles: action.systemRoles,
        merchantRoles: action.merchantRoles,
        loggedInUser: action.loggedInUser,
        activeMerchant: action.activeMerchant,
        isLoading: false,
        appState: APP_STATES.AUTHENTICATED,
      };
    case 'GET_ACCOUNT_INFORMATION_FAILED':
      return {
        ...prevState,
        isLoading: false,
        appState: APP_STATES.NOT_AUTHENTICATED,
      };
    case 'SET_NO_ACCOUNT_INFORMATION':
      return {
        ...prevState,
        isLoading: false,
        appState: APP_STATES.NOT_AUTHENTICATED,
      };
    case 'SET_ACTIVE_MERCHANT':
      return {
        ...prevState,
        activeMerchant: action.activeMerchant,
      };
    default:
      return prevState;
  }
}

/**
 * This is declared on top of the App function because it is a cache.
 *
 * `messages` is a simple variable effectively caching any messages using push and takes away using pop.
 * I have added context.pushMessage and context.popMessage for managing the messages to child components.
 *
 * What kind of scenarios to use the messages pattern?
 * When the component refreshes and loses the state eventually wiping out the data.
 * Example, before the `Merchants` component execute history.push, push the message into the array,
 *  then it refreshes component and pops the message at the start if any.
 *
 * Why use a variable instead of local/session storage?
 * It only stores string and requires stringify/parse everytime.
 *
 * Why does it not need for state change?
 * It should be resistant to state changes on components.
 */
const _messages = [];

function App() {
  const [state, dispatch] = useReducer(reducer, initialState);
  useEffect(() => {
    async function getAccountData() {
      try {
        const accountResponse = await getAccountInfoService();
        const loggedInUser = accountResponse.data;

        if (loggedInUser.systemRole[0] > ROLES.USER) {
          throw Error('You are not authorized');
        }

        const { systemRoles, merchantRoles } = await (async () => {
          if (loggedInUser.systemRole[0] === ROLES.USER) {
            return { systemRoles: [], merchantRoles: [] };
          }
          const response = await getAllRolesService();
          return response.data;
        })();

        const merchantCategories = await (async () => {
          const response = await getAllMerchantCategories();
          return response.data;
        })();

        if (loggedInUser.systemRole[0] <= ROLES.AUDITOR) {
          dispatch({
            type: 'GET_ACCOUNT_INFORMATION_SUCCESSFUL',
            activeMerchant: null,
            merchants: [],
            merchantCategories,
            systemRoles,
            merchantRoles,
            loggedInUser,
          });
          return;
        }

        // Only ROLES that will use getMerchantsService will be USERS and above
        // Hence, they will only retrieve a certain number of merchants
        const { data: { merchants } } = await getMerchantsService();
        merchants.sort((a, b) => a.name.localeCompare(b.name));

        const currentMerchantCode = window.location.pathname.split('/')[2];
        const activeMerchant = merchants.find(m => m.code === currentMerchantCode) || merchants[0];
        const { merchantRole } = loggedInUser.merchantRoles.find(m => m.merchantCode === activeMerchant.code);
        const isMerchantAdminOfActiveMerchant = loggedInUser.systemRole[0] === 70
          && merchantRole
          && merchantRole[0] === 10;


        // const activeMerchant = state.activeMerchant || merchants[0];
        localStorage.setItem(USER_MERCHANT, JSON.stringify(activeMerchant));

        // Check if user role is greater than AUDITOR (in other words QW Employees)
        // OR if user role is user and is a MERCHANT ADMIN
        if (isMerchantAdminOfActiveMerchant) {
          const { data } = await getMerchantRolesService(activeMerchant.id);

          dispatch({
            type: 'GET_ACCOUNT_INFORMATION_SUCCESSFUL',
            activeMerchant,
            merchants,
            merchantCategories: [],
            systemRoles,
            merchantRoles: data.merchantRoles,
            loggedInUser,
          });
          return;
        }

        dispatch({
          type: 'GET_ACCOUNT_INFORMATION_SUCCESSFUL',
          activeMerchant,
          merchants,
          merchantCategories: [],
          systemRoles: [],
          merchantRoles: [],
          loggedInUser,
        });
      } catch (error) {
        const { response } = error;
        if (response && response.status === 401) {
          logout();
          return;
        }
        dispatch({ type: 'GET_ACCOUNT_INFORMATION_FAILED' });
      }
    }

    if (state.isAuthenticated) {
      getAccountData();
    } else {
      dispatch({ type: 'SET_NO_ACCOUNT_INFORMATION' });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.isAuthenticated]);

  async function login({ email, password }, done) {
    try {
      const clientId = getOrGenerateClientId();
      const { data } = await loginService(email, password, clientId);
      const { authenticationToken, merchant, refreshToken } = data;
      localStorage.setItem(ACCESS_TOKEN, authenticationToken);
      localStorage.setItem(CLIENT_ID, clientId);
      localStorage.setItem(REFRESH_TOKEN, refreshToken);
      localStorage.setItem(USER_MERCHANT, JSON.stringify(merchant));
      dispatch({ type: 'LOGIN' });
      done({ status: 'success', merchant });
    } catch (err) {
      const message = getAPIErrorMessage(err, 'We are not able to log in as of the moment');
      done({ status: 'error', message });
    }
  }

  function setSelectedMerchant(merchant) {
    dispatch({ type: 'SET_ACTIVE_MERCHANT', activeMerchant: merchant });
    localStorage.setItem(USER_MERCHANT, JSON.stringify(merchant));
  }

  function logout() {
    dispatch({ type: 'LOGOUT' });
    localStorage.removeItem(ACCESS_TOKEN);
    localStorage.removeItem(CLIENT_ID);
    localStorage.removeItem(USER_MERCHANT);
    localStorage.removeItem(REFRESH_TOKEN);
    localStorage.removeItem(SELECTED_PAYMENTS);
    localStorage.removeItem(ACTIVE_SETTLEMENT_REFERENCE_ID);
    terminateBeacon();
  }

  function showToast({ message, type, timeOut }) {
    switch (type) {
      case 'success':
        toast.success(<AppAlert message={message} type="success" />, { autoClose: timeOut ?? 5000 });
        break;
      case 'error':
        toast.error(<AppAlert message={message} type="error" />, { autoClose: timeOut ?? 5000 });
        break;
      default:
        toast.info(<AppAlert message={message} type="info" />, { autoClose: timeOut ?? 5000 });
        break;
    }
  }

  function pushMessage(msg) { _messages.push(msg); }

  function popMessage() {
    const message = _messages.pop();
    return message;
  }

  const sessionContextValue = {
    ...state,
    login,
    logout,
    setSelectedMerchant,
    showToast,
    pushMessage,
    popMessage,
  };

  return (
    <HttpsRedirect>
      <BrowserRouter>
        <SessionContext.Provider value={sessionContextValue}>
          <div className="app">
            <ToastContainer
              className="alert-container"
              position={toast.POSITION.TOP_CENTER}
              autoClose={false}
              closeButton={false}
            />
            {((appState) => {
              switch (appState) {
                case APP_STATES.IS_LOADING:
                  return <div />;
                case APP_STATES.AUTHENTICATED:
                  return (
                    <AuthenticatedLayout
                      user={state.loggedInUser}
                      merchants={state.merchants}
                      appState={state.appState}
                    />
                  );
                case APP_STATES.NOT_AUTHENTICATED:
                  return (
                    <NonAuthenticatedLayout
                      user={state.loggedInUser}
                      merchants={state.merchants}
                      appState={state.appState}
                    />
                  );
                default:
                  return <div />;
              }
            })(state.appState)}
          </div>
        </SessionContext.Provider>
      </BrowserRouter>
    </HttpsRedirect>
  );
}

App.propTypes = {
};

export default App;
