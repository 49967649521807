module.exports = {
  ACCESS_TOKEN: 'access_token',
  CLIENT_ID: 'client_id',
  REFRESH_TOKEN: 'refresh_token',
  USER_MERCHANT: 'user_merchant',
  REMEMBER_ME: 'remember_me',
  SELECTED_PAYMENTS: 'selected_payments',
  ACTIVE_SETTLEMENT_REFERENCE_ID: 'active_settlement_ref_id',
  HIDE_WHAT_IS_NEW_MESSAGE: 'hide_what_is_new_message',
  ACTIVE_DASHBOARD_FORMS: {
    LOGIN_FORM: 'login',
    FORGOT_PASSWORD_FORM: 'forgot-password',
  },
  APP_STATES: {
    INIT: 0,
    IS_LOADING: 1,
    LOGGED_IN: 2,
    NOT_AUTHENTICATED: 10,
    AUTHENTICATED: 11,
  },
  ROLES: {
    SYSADMIN: 10,
    ENGINEERING: 20,
    FINANCE: 30,
    SUPPORT: 40,
    MANAGEMENT: 50,
    AUDITOR: 60,
    USER: 70,
    PAYER: 80,
    GUEST: 90,
    RESTRICTED: 100,
  },
  MERCHANT_ROLES: {
    ADMIN: 10,
    ANALYST: 20,
    DEVELOPER: 25,
    VIEWER: 30,
  },
};
