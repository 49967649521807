import moment from 'moment';
import React, { useReducer, Fragment, useContext } from 'react';
import PropTypes from 'prop-types';
import { Link, useLocation } from 'react-router-dom';
import { Row, Col, Breadcrumb, DatePicker, Button, Table, Select } from 'antd';
import qs from 'query-string';

import SessionContext from '../../contexts/SessionContext';
import { getMerchantsService } from '../../services/merchants';
import { getPaymentMethodIssuersReportAdmin } from '../../services/reports';
import { PieChartIcon } from '../../components/Icons';
import { getAPIErrorMessage, formatNumber } from '../../helpers/utils';
import './index.css';


const getInitialState = queryFilters => ({
  isGenerating: false,
  isFetchingMerchants: true,
  issuers: [],
  stats: {},
  status: null,
  message: null,
  merchants: [],
  merchant: null,
  startDate: moment().subtract(8, 'hours'),
  endDate: moment().add(16, 'hours'),
  searchOption: {
    totalCount: 0,
    size: parseInt(queryFilters.size, 10) || 10,
    page: parseInt(queryFilters.page, 10) || 1,
  },
});

/**
 * @param {{ data: { label: String, items: any[] } }} props
 */
function StatsColumn(props) {
  const { data, name } = props;
  if (!data) {
    return (
      <Fragment key={name}>
        <h3>{name}</h3>
        <div>0 / 0</div>
      </Fragment>
    );
  }
  const { label, items } = data;
  return (
    <Fragment key={label}>
      <h3>
        {items
          .map(item => item[0])
          .reduce((pv, cv) => `${pv} / ${cv}`)}
      </h3>
      <div>
        {items
          .map(item => item[1])
          .reduce((pv, cv) => `${pv} / ${cv}`)}
      </div>
    </Fragment>
  );
}


StatsColumn.propTypes = {
  name: PropTypes.string.isRequired,
  data: PropTypes.shape({
    label: PropTypes.string.isRequired,
    items: PropTypes.arrayOf(PropTypes.any).isRequired,
  }).isRequired,
};

/**
 * @param {{
 *   merchants: {id: Number, code: String}[],
 *   match: { params: { merchantCode: String } }
 * }} props
 */
function ReportPaymentIssuers() {
  const dateFormat = 'YYYY/MM/DD';
  const location = useLocation();
  const { showToast } = useContext(SessionContext);
  const queryFilters = qs.parse(location.search, { ignoreQueryPrefix: true });

  const [state, dispatch] = useReducer((prevState, action) => {
    switch (action.type) {
      case 'GET_MERCHANTS':
        return {
          ...prevState,
          isFetchingMerchants: true,
        };
      case 'GET_MERCHANTS_SUCCESSFUL':
        return {
          ...prevState,
          isFetchingMerchants: false,
          merchants: action.merchants,
          searchOption: {
            ...prevState.searchOption,
            totalCount: action.totalCount,
          },
        };
      case 'GET_MERCHANTS_FAILED':
        return {
          ...prevState,
          isFetchingMerchants: false,
          message: action.message,
        };
      case 'UPDATE_MERCHANT':
        return {
          ...prevState,
          merchant: action.merchant,
        };
      case 'UPDATE_DATES':
        return {
          ...prevState,
          startDate: action.startDate,
          endDate: action.endDate,
        };
      case 'GENERATE_REPORT':
        return {
          ...prevState,
          isGenerating: true,
        };
      case 'GENERATE_REPORT_SUCCESSFUL':
        return {
          ...prevState,
          isGenerating: false,
          issuers: action.issuers,
          stats: action.stats,
          status: 'success',
          message: null,
        };
      case 'GENERATE_REPORT_FAILED':
        return {
          ...prevState,
          isGenerating: false,
          issuers: [],
          stats: {},
          status: 'error',
          message: action.message,
        };
      case 'INITIALIZE_FILTERS':
        return {
          ...prevState,
          merchants: action.merchants,
          merchant: (() => {
            if (!prevState.merchant) { return null; }
            return action.merchants.find(m => m.code === prevState.merchant.code);
          })(),
        };
      default:
        return prevState;
    }
  }, getInitialState(queryFilters));

  async function generateReport() {
    try {
      dispatch({ type: 'GENERATE_REPORT' });
      const { merchant } = state;
      const options = {
        startDate: state.startDate,
        endDate: state.endDate,
      };
      const response = await getPaymentMethodIssuersReportAdmin(merchant, options);
      const { issuers, stats } = response.data;
      dispatch({ type: 'GENERATE_REPORT_SUCCESSFUL', issuers, stats });
    } catch (error) {
      const message = getAPIErrorMessage(error,
        'We are not able to generate the report. Please try again later.');
      dispatch({ type: 'GENERATE_REPORT_FAILED', message });
      showToast({ type: 'error', message, timeOut: 3000 });
    }
  }

  function onSelectMerchant(merchantId) {
    if (!merchantId) {
      dispatch({ type: 'UPDATE_MERCHANT', merchant: null });
    } else {
      const foundMerchant = state.merchants.find(ps => ps.id === merchantId);
      dispatch({ type: 'UPDATE_MERCHANT', merchant: foundMerchant });
    }
  }

  let timeout;
  let currentValue;

  async function onSearchMerchant(merchantCode) {
    if (!merchantCode) {
      dispatch({ type: 'UPDATE_MERCHANT', merchant: null });
      return;
    }

    if (timeout) {
      clearTimeout(timeout);
      timeout = null;
    }

    currentValue = merchantCode;
    async function searchMerchant() {
      if (timeout) {
        clearTimeout(timeout);
        timeout = null;
      }
      try {
        if (state.merchant) {
          dispatch({
            type: 'INITIALIZE_FILTERS',
            merchants: [state.merchant],
          });
          return;
        }

        let searchOptions = {
          size: 20,
          page: 1,
        };

        if (merchantCode) {
          searchOptions = {
            query: merchantCode,
            size: 20,
            page: 1,
          };
        }

        const { data } = await getMerchantsService(searchOptions);
        if (currentValue === merchantCode) {
          dispatch({ type: 'INITIALIZE_FILTERS', merchants: data.merchants });
        }
      } catch (error) {
        dispatch({ type: 'INITIALIZE_FILTERS', merchants: [] });
      }
    }

    // Set timeout to minimize flooding the server with API calls when
    // searching for merchants
    timeout = setTimeout(searchMerchant, 400);
  }


  const statHeaders = Object.keys(state.stats).map(key => state.stats[key].items).flat();
  return (
    <div className="qwikwire">
      <Row className="breadcrumb-row">
        <Col className="breadcrumb-header">
          <Breadcrumb className="breadcrumb-parent">
            <Breadcrumb.Item className="item-container">
              <div className="breadcrumb-item breadcrumb-icon">
                <PieChartIcon diameter="28px" />
              </div>
              <div className="breadcrumb-item icon-label">
                Reports
              </div>
            </Breadcrumb.Item>
          </Breadcrumb>
          <Breadcrumb separator=">">
            <Breadcrumb.Item className="breadcrumb-item">
              <Link to="/reports">
                <span>Reports</span>
              </Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item>
              <span>Payment Method Issuers</span>
            </Breadcrumb.Item>
          </Breadcrumb>
        </Col>
      </Row>
      <section style={{ margin: '12px 0 20px' }}>
        <Row gutter={8} className="filters">
          <Col lg={6}>
            <h4 className="text-secondary">Merchant</h4>
            <Select
              className="transaction-filter"
              showSearch
              defaultValue={state.merchant ? state.merchant.id : undefined}
              placeholder="Select a merchant"
              style={{ width: '100%' }}
              defaultActiveFirstOption={false}
              allowClear
              showArrow={false}
              filterOption={(inputValue, option) => {
                const { props: { children } } = option;
                return children.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1;
              }}
              onChange={onSelectMerchant}
              onSearch={onSearchMerchant}
              notFoundContent={null}
            >
              {state.merchants.map(p => (
                <Select.Option value={p.id} key={p.code} label={p.name}>
                  {p.name}
                </Select.Option>
              ))}
            </Select>
          </Col>
          <Col lg={6}>
            <h4 className="text-secondary">Paid At/Created At</h4>
            <DatePicker.RangePicker
              className="transaction-filter"
              defaultValue={[
                state.startDate.isValid() ? state.startDate : null,
                state.endDate.isValid() ? state.endDate : null,
              ]}
              format={dateFormat}
              style={{ width: '100%' }}
              onChange={([start, end]) => {
                const startDate = start ? start.format(dateFormat) : null;
                const endDate = end ? end.format(dateFormat) : null;
                dispatch({
                  type: 'UPDATE_DATES',
                  startDate: moment(startDate, dateFormat),
                  endDate: moment(endDate, dateFormat),
                });
              }}
            />
          </Col>
          <Col lg={6} />
          <Col lg={6} style={{ display: 'flex', justifyContent: 'flex-end', marginTop: '26px' }}>
            <Button
              className="button button-standard button-standard-outline"
              loading={state.isGenerating}
              onClick={generateReport}
            >
              Generate Report
            </Button>
          </Col>
        </Row>
      </section>
      { statHeaders.length > 0 && (
        <div style={{ marginBottom: '12px' }}>
          <section className="panel panel-standard">
            <div className="panel-header">
              <h1 style={{ fontSize: '20px', marginBottom: '8px' }}>Statistics</h1>
              <Row>
                {statHeaders.map(([label, value]) => (
                  <Col key={label} lg={6} className="statistics-panel-item">
                    <h3>{label}</h3>
                    <div>{value}</div>
                  </Col>
                ))}
              </Row>
            </div>
          </section>
        </div>
      )}
      <Table
        bordered
        className="table-standard"
        dataSource={state.issuers}
        loading={state.isGenerating}
        columns={[
          {
            title: 'Method',
            key: 'method',
            align: 'center',
            render: item => (
              <div>
                {((method) => {
                  switch (method) {
                    case 'cc': return 'Credit Card';
                    case 'ach': return 'Bank Transfer (ACH)';
                    case 'pp': return 'PayPal';
                    case 'directDebit': return 'Direct Debit';
                    case 'ewallet': return 'E-Wallet';
                    case 'maya': return 'Maya';
                    default: return 'Unknown';
                  }
                })(item.method)}
              </div>
            ),
          },
          {
            title: 'Origin',
            align: 'center',
            children: [
              {
                title: 'Country',
                key: 'country',
                align: 'center',
                render: item => (
                  <div>{item.origin || 'Unknown'}</div>
                ),
              },
              {
                title: 'ISO',
                key: 'iso',
                align: 'center',
                render: item => (
                  <div>{item.iso || 'Unknown'}</div>
                ),
              },
            ],
          },
          {
            title: 'Provider',
            key: 'provider',
            align: 'center',
            render: item => (
              <div>{item.provider || 'Unknown'}</div>
            ),
          },
          {
            title: 'Amount',
            key: 'amount',
            align: 'center',
            render: item => (
              <div>{`${item.currency} ${formatNumber(item.amount)}`}</div>
            ),
          },
          {
            title: 'Total Count',
            key: 'totalCount',
            align: 'center',
            render: item => (
              <div>{item.count}</div>
            ),
          },
        ]}
        pagination={{
          size: 'small',
          defaultCurrent: 1,
          defaultPageSize: 10,
        }}
        rowKey="key"
      />
    </div>
  );
}

export default ReportPaymentIssuers;
