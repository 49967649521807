import React, { useReducer, useContext } from 'react';
import PropTypes from 'prop-types';
import {
  Form, Input, Button, Select, Modal, Row, Col, Icon,
} from 'antd';
import { createMultipleMerchantMembersService } from '../../services/merchants';
import SessionContext from '../../contexts/SessionContext';
import '../../styles/modal.scss';

const initialState = {
  isAddMerchatMemberModalVisible: false,
  isReasonNotesRequired: false,
  isInviteSending: false,
  status: null,
  message: null,
};

function reducer(prevState, action) {
  switch (action.type) {
    case 'SHOW_MODAL':
      return {
        ...prevState,
        isAddMerchatMemberModalVisible: true,
      };
    case 'HIDE_MODAL':
      return {
        ...prevState,
        isAddMerchatMemberModalVisible: false,
      };
    case 'SEND_INVITE':
      return {
        ...prevState,
        isInviteSending: true,
      };
    case 'SEND_INVITE_SUCCESS':
      return {
        ...prevState,
        isInviteSending: false,
      };
    case 'SEND_INVITE_FAILED':
      return {
        ...prevState,
        isInviteSending: false,
        message: action.message,
        status: 'error',
      };
    default:
      return prevState;
  }
}

const AddMerchantMemberModal = (props) => {
  const { form, history } = props;
  const { activeMerchant, merchantRoles, showToast } = useContext(SessionContext);
  const [state, dispatch] = useReducer(reducer, initialState);

  const { Option } = Select;

  function validateEmail(email) {
    const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  }


  function onSubmit(e) {
    e.preventDefault();
    form.validateFields(async (err, values) => {
      dispatch({ type: 'SEND_INVITE' });

      if (err) {
        dispatch({
          type: 'SEND_INVITE_FAILED',
          message: 'Unable to create users as of the moment',
        });
        return;
      }

      // Get emailAddresses separated by ';' for multiple emails from form
      // and roles as integer which is the role of the merchant user.
      // If emails are valid, there will be a check in the backend whether the
      // number of members for the merchant has exceeded the limit.
      // This limit is found in merchant_member_controller.py which is called
      // by createMultipleMerchantMembersService
      const { emailAddresses, roles } = values;

      const foundMerchantRole = merchantRoles.find(r => r.key === roles);
      const validEmails = [];
      const invalidEmails = [];
      emailAddresses.split(';')
        .filter(ie => ((ie.length > 0) ? ie : null))
        .forEach(email => (validateEmail(email) ? validEmails.push(email) : invalidEmails.push(email)));

      if (invalidEmails.length > 0) {
        showToast({ type: 'error', message: `Invalid emails: ${invalidEmails}` });
        dispatch({ type: 'SET_INVITE_FAILED' });
      }

      try {
        const data = {
          emails: validEmails,
          merchantrole: foundMerchantRole.key,
        };
        const response = await createMultipleMerchantMembersService(activeMerchant.id, data);
        const { message } = response.data;

        dispatch({ type: 'SEND_INVITE_SUCCESS', message });
        history.push(`/merchants/${activeMerchant.code}/settings?key=members`);
        if (validEmails.length > 0) {
          showToast({ type: 'success', message: `Succesfully added new merchant members: ${validEmails}` });
        }
      } catch (error) {
        const message = error && error.response
          ? error.response.data.message
          : 'Unable to add new merchant member as of the moment';
        showToast({ type: 'error', message });
        dispatch({ type: 'SET_INVITE_FAILED', message });
      }
    });
  }

  return (
    <div className="enrollment-actions-center">
      <Button
        className="button button-standard button-standard-outline"
        onClick={() => dispatch({ type: 'SHOW_MODAL' })}
      >
        <Icon type="plus" />
        <span>Add Member</span>
      </Button>
      <Modal
        title="Invite new members"
        className="dialog-modal"
        visible={state.isAddMerchatMemberModalVisible}
        onCancel={() => dispatch({ type: 'HIDE_MODAL' })}
        footer={[
          <Button
            key="cancel"
            onClick={() => dispatch({ type: 'HIDE_MODAL' })}
          >
            Cancel
          </Button>,
          <Button
            key="submit"
            type="primary"
            htmlType="submit"
            loading={state.isInviteSending}
            onClick={onSubmit}
          >
            Invite
          </Button>,
        ]}
      >
        <Row>
          <Col>
            <p>Enter the email addresses of the new members separated by semi-colon and their corresponding role.</p>
          </Col>
        </Row>
        <Form onSubmit={onSubmit}>
          <Form.Item label="Email Addresses">
            {form.getFieldDecorator('emailAddresses', {
              rules: [{ required: true, message: 'Email is required' }],
            })(<Input placeholder="sample@email.com" />)}
          </Form.Item>
          <Form.Item label="Invite as:">
            {form.getFieldDecorator('roles', {
              initialValue: merchantRoles[0].key,
            })(
              <Select
                className="role-select"
                suffixIcon={<Icon type="caret-down" />}
              >
                {merchantRoles.map(r => (
                  <Option key={r.key} value={r.key}>
                    {r.label}
                  </Option>
                ))}
              </Select>,
            )}
          </Form.Item>
        </Form>
      </Modal>
    </div>
  );
};

AddMerchantMemberModal.propTypes = {
  form: PropTypes.shape({
    getFieldDecorator: PropTypes.func.isRequired,
    validateFields: PropTypes.func.isRequired,
  }).isRequired,
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }).isRequired,
};

export default Form.create()(AddMerchantMemberModal);
