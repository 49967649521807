import React, { useEffect, useReducer } from 'react';
import PropTypes from 'prop-types';
import qs from 'query-string';
import { Layout } from 'antd';
import { Switch, Route, Redirect, useHistory, useLocation } from 'react-router-dom';
import Home from '../screens/Home';
import Dashboard from '../screens/Dashboard';
import Payment from '../screens/Payment';
import Payments from '../screens/Payments';
import SettlementReport from '../screens/SettlementReport';
import SettlementReports from '../screens/SettlementReports';
import Transaction from '../screens/Transaction';
import TransactionsSearchResults from '../screens/TransactionsSearchResults';
import Enrollment from '../screens/Enrollment';
import Enrollments from '../screens/Enrollments';
import AccountSettings from '../screens/AccountSettings';
import MerchantActivities from '../screens/MerchantActivities';
import NotFound from '../screens/NotFound';
import Projects from '../screens/Projects';
import Project from '../screens/Project';
import MerchantSettings from '../screens/MerchantSettings';
import DeveloperSettings from '../screens/DeveloperSettings';
import WebhookEndpoint from '../screens/WebhookEndpoint';
import CreateMerchantMember from '../screens/CreateMerchantMember';
import CreateMerchantProject from '../screens/CreateMerchantProject';
import MerchantProjectSettings from '../screens/MerchantProjectSettings';
import Reports from '../screens/Reports';
import ReportPaymentIssuers from '../screens/ReportPaymentIssuers';
import ReportPaymentsByProject from '../screens/ReportPaymentsByProject';
import NotificationSettings from '../screens/NotificationSettings';
import CreatePaymentLink from '../screens/CreatePaymentLink';
import { Navigation, MainNavbar, Banner } from '../components';
import { initializeBeacon } from '../services/beacon-widget';
import withTracker from './withTracker';

function getInitialState(data) {
  return {
    searchQuery: data.query || null,
  };
}

function reducer(prevState, action) {
  switch (action.type) {
    case 'UPDATE_SEARCH_QUERY':
      return {
        ...prevState,
        searchQuery: action.searchQuery,
      };
    default:
      return prevState;
  }
}

/**
 * User Layout component is used enterprise clients tagged with user role
 */
function UserLayout(props) {
  const { user, merchants, appState } = props;
  const { firstName, lastName, email } = user;
  const history = useHistory();
  const location = useLocation();
  const urlQueries = qs.parse(location.search);

  const initialState = getInitialState({ query: urlQueries.query });
  const [state, dispatch] = useReducer(reducer, initialState);

  useEffect(() => {
    let ableToSet = true;
    if (ableToSet) {
      initializeBeacon({
        name: `${lastName}, ${firstName}`,
        email,
      });
    }

    return () => { ableToSet = false; };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const layoutProps = {
    layout: {
      searchQuery: state.searchQuery,
      updateSearchQuery: searchQuery => dispatch({ type: 'UPDATE_SEARCH_QUERY', searchQuery }),
    },
    user,
    merchants,
    appState,
  };
  const routes = [
    { path: '/', exact: true, component: Home },
    { path: '/account', exact: true, component: AccountSettings },
    { path: '/merchants/:merchantCode/search', exact: true, component: TransactionsSearchResults },
    { path: '/merchants/:merchantCode/activities', exact: true, component: MerchantActivities },
    { path: '/merchants/:merchantCode', exact: true, component: Dashboard },
    { path: '/merchants/:merchantCode/payments', exempt: true, exact: true, component: Payments },
    { path: '/merchants/:merchantCode/payments/:externalTransactionId/:invoiceId', exact: true, component: Payment },
    { path: '/merchants/:merchantCode/payments/payment-links', exact: true, component: CreatePaymentLink },
    { path: '/merchants/:merchantCode/enrollments', exempt: true, exact: true, component: Enrollments },
    { path: '/merchants/:merchantCode/enrollments/:externalTransactionId', exact: true, component: Enrollment },
    { path: '/merchants/:merchantCode/transactions/:transactionId', exact: true, component: Transaction },
    { path: '/merchants/:merchantCode/settlements', exempt: true, exact: true, component: SettlementReports },
    { path: '/merchants/:merchantCode/settlements/:settlementReferenceId', exact: true, component: SettlementReport },
    { path: '/merchants/:merchantCode/reports', exact: true, component: Reports },
    { path: '/merchants/:merchantCode/reports/payments', exact: true, component: ReportPaymentsByProject },
    { path: '/merchants/:merchantCode/reports/issuers', exact: true, component: ReportPaymentIssuers },
    { path: '/merchants/:merchantCode/notifications', exact: true, component: NotificationSettings },
    { path: '/merchants/:merchantCode/settings', exact: true, component: MerchantSettings },
    { path: '/merchants/:merchantCode/developers', exempt: true, exact: true, component: DeveloperSettings },
    { path: '/merchants/:merchantCode/developers/webhook/:webhookId', exact: true, component: WebhookEndpoint },
    { path: '/merchants/:merchantCode/members/new', exact: true, component: CreateMerchantMember },
    { path: '/merchants/:merchantCode/projects', exact: true, component: Projects },
    { path: '/merchants/:merchantCode/projects/new', exact: true, component: CreateMerchantProject },
    { path: '/merchants/:merchantCode/projects/:projectId', exact: true, component: Project },
    { path: '/merchants/:merchantCode/projects/:projectId/edit', exact: true, component: MerchantProjectSettings },
    { path: '/login', exact: false, component: Redirect, props: { to: '/' } },
    { path: '/signup', exact: false, component: Redirect, props: { to: '/' } },
    { path: '/forgot-password', exact: false, component: Redirect, props: { to: '/' } },
    { path: '/password-reset', exact: false, component: Redirect, props: { to: '/' } },
    { path: '*', exact: true, component: NotFound },
  ];

  return (
    <Layout className="main">
      <Banner />
      <Layout.Sider theme="light" width="250" className="sidebar">
        <Navigation />
      </Layout.Sider>
      <Layout className="main-layout">
        <MainNavbar
          searchQuery={state.searchQuery}
          onSearch={(searchQuery) => {
            if (location.pathname !== '/search') {
              const merchantCode = location.pathname.split('/')[2];
              history.push(`/merchants/${merchantCode}/search?query=${searchQuery}`);
            }
            dispatch({ type: 'UPDATE_SEARCH_QUERY', searchQuery });
          }}
        />
        <Layout.Content className="main-layout-content">
          <Switch>
            {routes.map((ir) => {
              const Component = ir.component;
              const componentProps = ir.props || {};
              const TrackedComponent = withTracker(trackerProps => (
                <Component {...trackerProps} {...layoutProps} {...componentProps} />
              ));

              if (ir.exempt) {
                return (
                  <Route key={ir.path} exact={ir.exact} path={ir.path}>
                    <Component {...layoutProps} {...componentProps} />
                  </Route>
                );
              }

              return (
                <Route
                  key={ir.path}
                  exact={ir.exact}
                  path={ir.path}
                  component={TrackedComponent}
                />
              );
            })}
          </Switch>
        </Layout.Content>
      </Layout>
    </Layout>
  );
}

UserLayout.propTypes = {
  appState: PropTypes.number.isRequired,
  user: PropTypes.shape({
    id: PropTypes.number,
    email: PropTypes.string,
    firstName: PropTypes.string,
    lastName: PropTypes.string,
  }),
  merchants: PropTypes.arrayOf(PropTypes.any),
};

UserLayout.defaultProps = {
  user: null,
  merchants: [],
};

export default UserLayout;
