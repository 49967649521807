import React, { useContext, useReducer } from 'react';
import PropTypes from 'prop-types';
import { Button, Card, Form, Icon, Input } from 'antd';
import { PasswordMeter } from '..';
import { changePassword } from '../../services/auth';
import SessionContext from '../../contexts/SessionContext';

const initialState = {
  isSaving: false,
  isAcceptable: false,
  isTouched: false,
  currentPassword: null,
  newPassword: null,
};

function reducer(prevState, action) {
  switch (action.type) {
    case 'SET_CURRENT_PASSWORD':
      return {
        ...prevState,
        currentPassword: action.currentPassword,
        isTouched: true,
      };

    case 'SET_NEW_PASSWORD':
      return {
        ...prevState,
        newPassword: action.newPassword,
        passwordScore: action.passwordScore,
        isTouched: true,
      };

    case 'SET_IS_PASSWORD_ACCEPTABLE':
      return {
        ...prevState,
        isAcceptable: action.isAcceptable,
      };

    case 'SAVING_PASSWORD':
      return {
        ...prevState,
        isSaving: true,
      };

    case 'SAVING_PASSWORD_SUCCESSFUL':
      return {
        ...prevState,
        isSaving: false,
        isAcceptable: false,
        currentPassword: null,
        newPassword: null,
      };

    case 'SAVING_PASSWORD_FAILED':
      return {
        ...prevState,
        isSaving: false,
      };

    default:
      return prevState;
  }
}

const formItemLayout = { labelCol: { span: 24 }, wrapperCol: { span: 24 } };
const { Password } = Input;
const AccountSettingsPassword = (props) => {
  const { form } = props;
  const { showToast } = useContext(SessionContext);
  const [state, dispatch] = useReducer(reducer, initialState);

  function submitPassword(e) {
    e.preventDefault();
    form.validateFields(async (err, { currentPassword, newPassword }) => {
      if (!err) {
        dispatch({ type: 'SAVING_PASSWORD' });
        try {
          const { data: { message } } = await changePassword({ currentPassword, newPassword });
          dispatch({ type: 'SAVING_PASSWORD_SUCCESSFUL', message });
          showToast({ type: 'success', message });
          form.resetFields();
        } catch (error) {
          const message = error && error.response
            ? error.response.data.message
            : 'Unable to save your password as of the moment';
          showToast({ type: 'error', message });
          dispatch({ type: 'SAVING_PASSWORD_FAILED' });
        }
      }
    });
  }

  const isFormValid = Boolean(state.newPassword)
    && Boolean(state.currentPassword)
    && state.isAcceptable
    && state.currentPassword !== state.newPassword;

  const formValidationMessage = (() => {
    if (state.isTouched) {
      if (!state.currentPassword) {
        return 'Current password is required';
      }
      if (!state.newPassword) {
        return 'New password is required';
      }
      if (!state.isAcceptable) {
        return 'Your new password must be non-predictable with at least 8 characters';
      }
      if (state.currentPassword === state.newPassword) {
        return 'Your current and new password must not be the same';
      }
    }
    return null;
  })();

  return (
    <Card className="settings-card" title="Change Password">
      <Form onSubmit={submitPassword}>
        <div className="settings-card-body">
          <Form.Item label="Current Password" {...formItemLayout}>
            {form.getFieldDecorator('currentPassword', {
              rules: [{ required: true, message: 'Please input your current password' }],
            })(<Password
              prefix={<Icon type="lock" />}
              onChange={e => dispatch({ type: 'SET_CURRENT_PASSWORD', currentPassword: e.target.value })}
              placeholder="Current password"
            />)}
          </Form.Item>
          <Form.Item
            label="New Password"
            extra={(
              <div>
                <PasswordMeter
                  password={state.newPassword}
                  isPasswordAcceptable={isAcceptable => dispatch({ type: 'SET_IS_PASSWORD_ACCEPTABLE', isAcceptable })}
                />
                <p>{formValidationMessage}</p>
              </div>
            )}
            {...formItemLayout}
          >
            {form.getFieldDecorator('newPassword', {
              rules: [{ required: true, message: 'Please input your new password' }],
            })(<Password
              prefix={<Icon type="lock" />}
              onChange={e => dispatch({ type: 'SET_NEW_PASSWORD', newPassword: e.target.value })}
              placeholder="New password"
            />)}
          </Form.Item>
        </div>
        <Form.Item className="settings-card-action">
          <Button
            className="button button-standard"
            type="primary"
            htmlType="submit"
            loading={state.isSaving}
            disabled={!isFormValid || state.isSaving}
          >
            {state.isSaving ? 'Changing Password' : 'Change Password'}
          </Button>
        </Form.Item>
      </Form>
    </Card>
  );
};

AccountSettingsPassword.propTypes = {
  form: PropTypes.shape({
    getFieldDecorator: PropTypes.func.isRequired,
    validateFields: PropTypes.func.isRequired,
    resetFields: PropTypes.func.isRequired,
  }).isRequired,
  user: PropTypes.shape({
    id: PropTypes.string.isRequired,
    firstName: PropTypes.string.isRequired,
    lastName: PropTypes.string.isRequired,
    email: PropTypes.string.isRequired,
    role: PropTypes.array.isRequired,
  }).isRequired,
};

export default Form.create()(AccountSettingsPassword);
