import React, { useReducer } from 'react';
import PropTypes from 'prop-types';
import { Modal, Button, Form, Input, Typography } from 'antd';
import { cancelEnrollmentInvoice } from '../../services/enrollments';
import { getAPIErrorMessage } from '../../helpers/utils';

const getInitialState = () => ({
  text: null,
  reason: null,
  isCancelling: false,
  message: null,
  status: null,
});

function reducer(prevState, action) {
  switch (action.type) {
    case 'CHANGE_TEXT':
      return {
        ...prevState,
        text: action.value,
      };
    case 'CHANGE_REASON':
      return {
        ...prevState,
        reason: action.value,
      };
    case 'CANCEL_ENROLLMENT_INVOICE':
      return {
        ...prevState,
        isCancelling: true,
        message: null,
        status: null,
      };
    case 'CANCEL_ENROLLMENT_INVOICE_SUCCESS':
      return {
        ...prevState,
        isCancelling: false,
        message: null,
        status: 'success',
      };
    case 'CANCEL_ENROLLMENT_INVOICE_FAILED':
      return {
        ...prevState,
        isCancelling: false,
        message: action.message,
        status: 'error',
      };
    default:
      return prevState;
  }
}

function CancelEnrollmentInvoiceModal(props) {
  const {
    isVisible, merchantId, enrollmentReferenceId, invoice, onDone, onCancel,
  } = props;
  const [state, dispatch] = useReducer(reducer, getInitialState());
  const { TextArea } = Input;

  const word = 'cancel invoice';

  async function cancelInvoice() {
    dispatch({ type: 'CANCEL_ENROLLMENT_INVOICE' });

    if (state.text !== word) {
      dispatch({ type: 'CANCEL_ENROLLMENT_INVOICE_FAILED', message: 'The typed word does not match' });
      return;
    }

    try {
      await cancelEnrollmentInvoice(
        merchantId,
        enrollmentReferenceId,
        invoice.invoiceReferenceId,
        state.reason,
      );
      dispatch({ type: 'CANCEL_ENROLLMENT_INVOICE_SUCCESS' });
      onDone({ message: `${invoice.invoiceReferenceId} has been cancelled.`, status: 'success' });
    } catch (error) {
      const message = getAPIErrorMessage(error, 'We are not able to cancel this enrollment invoice. Please try again later.');
      dispatch({ type: 'CANCEL_ENROLLMENT_INVOICE_FAILED', message });
    }
  }

  // console.log(props);
  return (
    <Modal
      className="dialog-modal"
      visible={isVisible}
      title="Cancel Enrollment Invoice"
      onCancel={onCancel}
      footer={[
        <Button
          key="cancel-confirm-key"
          onClick={onCancel}
        >
          Cancel
        </Button>,
        <Button
          loading={state.isSuspending}
          key="submit"
          type="danger"
          onClick={() => cancelInvoice()}
        >
          Confirm
        </Button>,
      ]}
    >
      <Form
        onSubmit={(ev) => {
          ev.preventDefault();
          cancelInvoice();
        }}
      >
        <p style={{ marginBottom: '4px' }}>
          Are you sure you want to cancel
          <strong>{` ${invoice.invoiceReferenceId} `}</strong>
          ?
        </p>
        <p style={{ marginBottom: '4px' }}>
          Please state your reason:
        </p>
        <Form.Item>
          <TextArea
            allowClear
            autoSize={{ maxRows: 8, minRows: 3 }}
            onChange={e => dispatch({ type: 'CHANGE_REASON', value: e.target.value })}
          />
        </Form.Item>
        <p style={{ marginBottom: '4px' }}>
          Cancellation of an invoice is final.
          Type &quot;
          <i>{word}</i>
          &quot; below to confirm cancellation:
        </p>
        <Form.Item>
          <Input
            type="text"
            placeholder=""
            onChange={e => dispatch({ type: 'CHANGE_TEXT', value: e.target.value })}
          />
          {state.status === 'error' && state.message && (
            <Typography.Text type="danger">{state.message}</Typography.Text>
          )}
        </Form.Item>
      </Form>
    </Modal>
  );
}

CancelEnrollmentInvoiceModal.propTypes = {
  isVisible: PropTypes.bool.isRequired,
  invoice: PropTypes.shape({
    invoiceReferenceId: PropTypes.string,
    invoiceId: PropTypes.number,
    status: PropTypes.string,
  }),
  enrollmentReferenceId: PropTypes.string.isRequired,
  merchantId: PropTypes.number.isRequired,
  onDone: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
};

CancelEnrollmentInvoiceModal.defaultProps = {
  invoice: { invoiceId: 0, invoiceReferenceId: null },
};

export default CancelEnrollmentInvoiceModal;
