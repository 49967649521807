import React, { useReducer } from 'react';
import PropTypes from 'prop-types';
import { Modal, Button, Form, Input, Typography } from 'antd';

import { createPaymentType, updatePaymentType } from '../../services/merchants';
import { getAPIErrorMessage } from '../../helpers/utils';
import './manage-payment-type-modal.scss';

const initialState = {
  code: null,
  name: null,
  isUpdating: false,
  message: null,
  status: null,
};

function reducer(prevState, action) {
  switch (action.type) {
    case 'CHANGE_CODE':
      return {
        ...prevState,
        code: action.value,
      };
    case 'CHANGE_NAME':
      return {
        ...prevState,
        name: action.value,
      };
    case 'POST_PAYMENT_TYPE_CHANGES':
      return {
        ...prevState,
        isUpdating: true,
        message: null,
        status: null,
      };
    case 'POST_PAYMENT_TYPE_CHANGES_SUCCESS':
      return {
        ...prevState,
        isUpdating: false,
      };
    case 'POST_PAYMENT_TYPE_CHANGES_FAILED':
      return {
        ...prevState,
        isUpdating: false,
        status: 'error',
        message: action.message,
      };
    default:
      return prevState;
  }
}

function ManagePaymentTypeModal(props) {
  const {
    isVisible,
    isEditing,
    onDone,
    onCancel,
    merchant,
    paymentType,
  } = props;
  const [state, dispatch] = useReducer(reducer, initialState);

  async function managePaymentType() {
    dispatch({ type: 'POST_PAYMENT_TYPE_CHANGES' });

    try {
      const { name } = state;

      if (isEditing) {
        await updatePaymentType(merchant.id, paymentType.id, name);
      } else {
        const code = `${merchant.code}_${state.code}`.toUpperCase();
        await createPaymentType(merchant.id, { code, name });
      }

      dispatch({ type: 'POST_PAYMENT_TYPE_CHANGES_SUCCESS' });
      onDone({ message: 'Payment type changes applied', status: 'success' });
    } catch (error) {
      const message = getAPIErrorMessage(error, 'Unable to apply payment type changes. Please try again later.');
      dispatch({ type: 'POST_PAYMENT_TYPE_CHANGES_FAILED', message });
    }
  }

  return (
    <Modal
      className="dialog-modal"
      destroyOnClose
      visible={isVisible}
      title={`${isEditing ? 'Edit' : 'New'} Payment Type`}
      onCancel={onCancel}
      footer={[
        <Button
          key="cancel-confirm-key"
          onClick={onCancel}
        >
          Cancel
        </Button>,
        <Button
          loading={state.isUpdating}
          key="submit"
          type="primary"
          disabled={!isEditing && (!state.code || !state.name)}
          onClick={() => managePaymentType()}
        >
          {isEditing ? 'Update' : 'Save'}
        </Button>,
      ]}
    >
      <Form
        onSubmit={(ev) => {
          ev.preventDefault();
          managePaymentType();
        }}
      >
        <Form.Item label="Payment Type Code">
          <Input
            type="text"
            className="uppercase-input"
            defaultValue={isEditing ? paymentType.code : ''}
            onChange={e => dispatch({ type: 'CHANGE_CODE', value: e.target.value })}
            disabled={isEditing}
            required
          />
        </Form.Item>
        <Form.Item label="Payment Type Name">
          <Input
            type="text"
            defaultValue={isEditing ? paymentType.name : ''}
            onChange={e => dispatch({ type: 'CHANGE_NAME', value: e.target.value })}
            required
          />
          {state.status === 'error' && state.message && (
            <Typography.Text type="danger">{state.message}</Typography.Text>
          )}
        </Form.Item>
      </Form>
    </Modal>
  );
}

ManagePaymentTypeModal.propTypes = {
  isVisible: PropTypes.bool.isRequired,
  isEditing: PropTypes.bool,
  paymentType: PropTypes.shape({
    id: PropTypes.number,
    code: PropTypes.string,
    name: PropTypes.string,
  }),
  merchant: PropTypes.shape({
    id: PropTypes.number.isRequired,
    code: PropTypes.string.isRequired,
  }).isRequired,
  onDone: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
};

ManagePaymentTypeModal.defaultProps = {
  isEditing: false,
  paymentType: {
    id: 0,
    code: '',
    name: '',
  },
};

export default ManagePaymentTypeModal;
