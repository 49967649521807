import React from 'react';
import PropTypes from 'prop-types';

import { formatNumber } from '../../helpers/utils';
import { InfoCircleIcon } from '../Icons';
import '../../styles/modal.scss';
import './breakdown-item.scss';


const BreakdownItem = (props) => {
  const { label, bill, highlight, hideOnEmpty, tooltipDescription } = props;

  if (!Array.isArray(bill) || bill.length === 0) {
    return <div />;
  }

  const [currency, amount] = bill;
  if (hideOnEmpty && !amount) {
    return <div />;
  }

  // NOTE: CSS quote escapes have no clean solution so far
  const sanitizedDescription = tooltipDescription
    ? tooltipDescription.replace(/'/g, '\\\'')
    : 'No info provided';

  return (
    <div className="panel-breakdown-item">
      <div className="info-item-parent">
        {label}
        {tooltipDescription ? (
          <div className="info-item-hoverable" style={{ '--breakdown-item-info': `'${sanitizedDescription}'` }}>
            <InfoCircleIcon diameter="16px" />
          </div>
        ) : ''}
      </div>
      <div>
        {highlight
          ? <strong>{`${currency} ${formatNumber(amount)}`}</strong>
          : <span>{`${currency} ${formatNumber(amount)}`}</span>}
      </div>
    </div>
  );
};

BreakdownItem.propTypes = {
  bill: PropTypes.arrayOf(PropTypes.any).isRequired,
  label: PropTypes.string.isRequired,
  highlight: PropTypes.bool,
  hideOnEmpty: PropTypes.bool,
  tooltipDescription: PropTypes.string,
};

BreakdownItem.defaultProps = {
  highlight: false,
  hideOnEmpty: false,
  tooltipDescription: '',
};

export default BreakdownItem;
