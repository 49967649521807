import api from '../helpers/apiClient';

export function getMerchantsService(searchOption = {}) {
  const {
    page,
    size,
    name,
    type,
    category,
    query,
  } = searchOption;

  return api.get('/merchants', {
    params: {
      page: page || 1,
      size: size || 200,
      merchant: name,
      type,
      category,
      query,
    },
  });
}

export function getMerchantById(merchantId) {
  return api.get(`/merchants/${merchantId}`);
}

export function getMerchantByCode(merchantCode) {
  return api.get(`/merchants/${merchantCode}`);
}

export function suspendMerchantService(merchantId) {
  return api.post(`/merchants/${merchantId}/suspend`);
}

export function closeMerchantService(merchantId) {
  return api.post(`/merchants/${merchantId}/close`);
}

export function activateMerchantService(merchantId) {
  return api.post(`/merchants/${merchantId}/activate`);
}

export function getMerchantProjectsService(merchantCode) {
  return api.get(`/merchants/${merchantCode}/projects`);
}

export function createMerchantProjectService(values) {
  const { merchantId, ...otherValues } = values;
  return api.post(`/merchants/${merchantId}/projects`, otherValues);
}

export function findMerchantProjectService(merchantCode, projectId) {
  return api.get(`/merchants/${merchantCode}/projects/${projectId}`);
}

export function updateMerchantProjectService(values) {
  const { merchantId, projectId, ...otherValues } = values;
  return api.post(`/merchants/${merchantId}/projects/${projectId}`, otherValues);
}

export function removeMerchantProjectService(merchantCode, projectId) {
  return api.delete(`/merchants/${merchantCode}/projects/${projectId}`);
}

export function getMerchantMembersService(merchantId, searchOption) {
  const {
    page,
    size,
    role,
    searchQuery,
  } = searchOption;

  return api.get(`/merchants/${merchantId}/members`, {
    params: {
      page,
      size,
      role,
      query: searchQuery,
    },
  });
}

export function getAllMerchantCategories() {
  return api.get('/merchants/categories');
}

export function updateMerchantInformation(merchantId, name, category, address, timezone) {
  return api.post(`/merchants/${merchantId}`, { name, category, address, timezone });
}

export function deleteMerchantMemberService(merchantId, id) {
  return api.delete(`/merchants/${merchantId}/members/${id}`);
}

export function updateMerchantMemberRoleService(merchantId, id, role) {
  return api.post(`/merchants/${merchantId}/members/${id}/roles/${role}`);
}

export function getAllRolesService() {
  return api.get('/merchants/roles');
}

export function getMerchantRolesService(merchantId) {
  return api.get(`/merchants/${merchantId}/roles`);
}

export function getMerchantMemberNotificationsSettings(merchantId, id) {
  return api.get(`/merchants/${merchantId}/members/${id}/notifications`);
}

export function updateMerchantMemberNotifications(canReceiveDailyTransactionEmails, canReceivePaymentEmails, canReceiveSettlementEmails, merchantId) {
  return api.post(`/merchants/${merchantId}/notifications`, {
    canReceiveDailyTransactionEmails,
    canReceivePaymentEmails,
    canReceiveSettlementEmails,
  });
}

export function createMerchantMemberService(merchantId, values) {
  return api.post(`/merchants/${merchantId}/member`, values);
}

export function createMultipleMerchantMembersService(merchantId, values) {
  return api.post(`/merchants/${merchantId}/members`, values);
}

export function sendMerchantMemberInviteService(merchantId, email) {
  return api.post(`/merchants/${merchantId}/members/${email}/invite`);
}

export function getMerchantPaymentMethodsService(merchantId) {
  return api.get(`/merchants/${merchantId}/payment-methods`);
}

export function activateMerchantPaymentMethodsService(merchantId, paymentMethodId) {
  return api.post(`/merchants/${merchantId}/payment-methods/${paymentMethodId}/activate`);
}

export function disableMerchantPaymentMethodsService(merchantId, paymentMethodId) {
  return api.post(`/merchants/${merchantId}/payment-methods/${paymentMethodId}/disable`);
}

export function updateMerchantCategoryService(merchantId, merchantCategory) {
  return api.post(`/merchants/${merchantId}/category`, { merchantCategory });
}

export function updateMerchantOwnerService(merchantId, userId) {
  return api.post(`/merchants/${merchantId}/ownership`, { userId });
}

export function updateMerchantFeatureFlags(merchantId, featureFlags) {
  return api.post(`/merchants/${merchantId}/flags`, featureFlags);
}

/**
 * @param {number} merchantId
 * @param {{ purpose: 'all' | 'pay' | 'enroll' }} opts
 */
export function getPaymentTypesService(merchantId, opts) {
  const options = opts || { purpose: 'all' };
  return api.get(`/merchants/${merchantId}/payment-types`, {
    params: {
      purpose: options.purpose,
    },
  });
}

export function getPaymentCurrenciesService(merchantId) {
  return api.get(`/merchants/${merchantId}/payment-currencies`);
}

export function getMerchantWebhooksById(merchantId) {
  return api.get(`/merchants/${merchantId}/webhook`);
}

export function createMerchantWebhookEndpointService(merchantId, values) {
  return api.post(`/merchants/${merchantId}/webhook/endpoint`, values);
}

export function getMerchantWebhookEndpointService(merchantId, webhookId) {
  return api.get(`/merchants/${merchantId}/webhook/endpoint/${webhookId}`);
}

export function updateMerchantWebhookEndpointService(merchantId, webhookId, action) {
  return api.post(`/merchants/${merchantId}/webhook/endpoint/${webhookId}`, { action });
}

export function getMerchantApiKeysById(merchantId) {
  return api.get(`/merchants/${merchantId}/api-key`);
}

export function createSampleWebhookTask(merchantId, webhookId, event) {
  return api.post(`/merchants/${merchantId}/webhook/endpoint/${webhookId}/test-event`, { event });
}

export function retryFailedWebhookTask(merchantId, webhookId, taskId) {
  return api.post(`/merchants/${merchantId}/webhook/endpoint/${webhookId}/retry/${taskId}`);
}

export function createMerchantApiKey(merchantId) {
  return api.post(`/merchants/${merchantId}/api-key`);
}

export function getMerchantFilesPresignedDownloadUrl(merchantId) {
  return api.get(`/merchants/${merchantId}/files/presigned-url`);
}

export function updatePaymentTypeState(merchantId, id, isEnabled) {
  return api.patch(`/merchants/${merchantId}/payment-types/${id}`, {
    isEnabled,
  });
}

export function createPaymentType(merchantId, paymentType) {
  return api.post(`/merchants/${merchantId}/payment-types`, paymentType);
}

export function updatePaymentType(merchantId, id, name) {
  return api.patch(`/merchants/${merchantId}/payment-types/${id}/change-name`, {
    name,
  });
}
