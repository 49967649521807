import React, { Fragment, useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { Button, Col, Form, Input, Row, Spin, Breadcrumb } from 'antd';
import { MerchantsPageIcon } from '../../components/Icons';
import PageMessage from '../../components/PageMessage';
import Message from '../../components/Message';
import getValidationErrors from '../../helpers/forms';
import SessionContext from '../../contexts/SessionContext';
import { findMerchantProjectService, updateMerchantProjectService } from '../../services/merchants';
import { getAPIErrorMessage } from '../../helpers/utils';
import './merchantprojectsettings.css';

const formItemLayout = { labelCol: { span: 24 }, wrapperCol: { span: 24 } };
const MerchantProjectSettings = (props) => {
  const { form: { resetFields }, history, match: { params: { merchantCode, projectId } } } = props;
  const { merchants, loggedInUser, pushMessage, popMessage } = useContext(SessionContext);
  const [msg, setMsg] = useState(popMessage());
  const merchant = merchants.find(m => m.code === merchantCode);
  const [isFetching, setIsFetching] = useState(false);
  const [fetchedProject, setFetchedProject] = useState({});
  const [editedProject, setEditedProject] = useState({});
  const [merchantProject, setProject] = useState({ name: null, category: null, isEnabled: true });
  const {
    name,
    category,
    description,
    isEnabled,
  } = fetchedProject;
  const { merchantRole } = loggedInUser.merchantRoles.find(m => m.merchantCode === merchantCode);
  const { TextArea } = Input;
  useEffect(() => {
    let ableToSet = true;

    (async () => {
      try {
        setIsFetching(true);
        const response = await findMerchantProjectService(merchant.id, projectId);
        const { data } = response;
        if (ableToSet && data.isEnabled) {
          setFetchedProject({ ...data });
          setEditedProject({ ...data });
          setProject({ name: data.name, category: data.category, isEnabled: data.isEnabled });
          setIsFetching(false);
        }
      } catch (error) {
        const message = getAPIErrorMessage(error,
          'We are not able to get the project as of the moment. Please try again later.');
        if (ableToSet) {
          const alertMessage = { component: 'MerchantProjectSettings', status: 'error', message };
          setMsg(alertMessage);
          pushMessage(alertMessage);
          setIsFetching(false);
          setFetchedProject(false);
        }
      }
    })();

    return () => { ableToSet = false; };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const { handleSubmit, handleChange, errors } = useForm({
    rules: {
      name: [
        { required: true, message: 'Please enter the project name.' },
        { max: 150, message: 'Project name has too many characters' },
      ],
      category: [
        { max: 100, message: 'Project category has too many characters' },
      ],
      description: [
        { max: 255, message: 'Project description has too many characters' },
      ],
    },
  });

  function useForm(options) {
    // eslint-disable-next-line no-shadow
    const [errors, setErrors] = useState({});

    // eslint-disable-next-line no-shadow
    const handleSubmit = async (e) => {
      e.preventDefault();
      const keys = Object.keys(errors);
      if (keys.every(k => !errors[k])) {
        updateProject({
          ...{
            name: editedProject.name,
            category: editedProject.category,
            description: editedProject.description,
          },
          projectId,
        });
      }
    };

    // eslint-disable-next-line no-shadow
    const handleChange = key => (
      e,
    ) => {
      const { value } = e.target;
      setProject({ ...merchantProject, [e.target.name]: value });
      setEditedProject({ ...editedProject, [e.target.name]: value });

      const newErrors = errors;
      const rules = options?.rules[key];
      if (rules) {
        newErrors[key] = getValidationErrors(rules, value);
        if (!newErrors[key].length) {
          newErrors[key] = null;
        }
      }

      setErrors(newErrors);
    };

    return {
      handleSubmit,
      handleChange,
      errors,
    };
  }

  if (!(loggedInUser && merchants.length)) { return <div />; }
  if (loggedInUser.systemRole[0] >= 80
    || (loggedInUser.systemRole[0] === 70 && merchantRole[0] > 10)
    || !merchant
  ) {
    return <PageMessage title="You are not allowed to access this project page" />;
  }

  if (!fetchedProject) {
    return <PageMessage title="Project not found" />;
  }

  function cancel() {
    resetFields();
    history.goBack();
  }

  async function updateProject(values) {
    try {
      setIsFetching(true);
      const { data: { message } } = await updateMerchantProjectService({ merchantId: merchant.id, projectId, ...values });
      setIsFetching(false);
      const alertMessage = { component: 'Project', status: 'success', message };
      pushMessage(alertMessage);
      history.goBack();
    } catch (error) {
      const message = error && error.response ? error.response.data.message : 'Unable to remove merchant project as of the moment';
      const alertMessage = { component: 'MerchantProjectSettings', status: 'error', message };
      setMsg(alertMessage);
      pushMessage(alertMessage);
      setIsFetching(false);
    }
  }

  function getFormFieldErrors(errorsList) {
    if (!errorsList || errorsList.length === 0) {
      return {};
    }

    // re-map errors list for it to have keys
    const mappedErrors = errorsList.map((e, idx) => ({ err: e, key: idx }));

    return {
      validateStatus: 'error',
      help: (mappedErrors || []).map(e => <p className="error-msg" key={e.key}>{e.err}</p>),
    };
  }

  const notChanged = JSON.stringify(editedProject) === JSON.stringify(fetchedProject);

  return (
    <div>
      <Fragment>
        {msg && msg.status && msg.message && (
          <div style={{ marginBottom: '8px' }}>
            <Row>
              <Col span={24}>
                <Message
                  message={msg.message}
                  status={['error', 'success'].includes(msg.status) ? msg.status : 'info'}
                  showIcon
                  closable
                  onClose={() => setMsg(null)}
                />
              </Col>
            </Row>
          </div>
        )}
        <Row className="breadcrumb-row">
          <Col className="breadcrumb-header">
            <Breadcrumb className="breadcrumb-parent">
              <Breadcrumb.Item className="item-container">
                <div className="breadcrumb-item breadcrumb-icon">
                  <MerchantsPageIcon />
                </div>
                <div className="breadcrumb-item icon-label">
                  Edit Project
                </div>
              </Breadcrumb.Item>
            </Breadcrumb>
          </Col>
        </Row>
        <Row gutter={32}>
          <Col span={12} lg={12} md={24} sm={24} xs={24}>
            <section className="panel panel-standard">
              <div className="panel-header">
                <div className="panel-title">Project Details</div>
              </div>
              <div className="panel-body">
                {
                  name ? (
                    <Form id="detailsForm" onSubmit={handleSubmit}>
                      <div className="details-body">
                        <Form.Item
                          {...formItemLayout}
                          {...getFormFieldErrors(errors.name)}
                          className="form-item"
                          label="Project Name"
                        >
                          <Input defaultValue={name} name="name" onChange={handleChange('name')} className="form-input" />
                        </Form.Item>
                        <Form.Item
                          {...formItemLayout}
                          {...getFormFieldErrors(errors.category)}
                          className="form-item"
                          label="Project Category"
                        >
                          <Input defaultValue={category} name="category" onChange={handleChange('category')} className="form-input" />
                        </Form.Item>
                        <Form.Item
                          {...formItemLayout}
                          {...getFormFieldErrors(errors.description)}
                          className="form-item"
                          label="Project Description"
                        >
                          <TextArea
                            defaultValue={description}
                            rows={4}
                            name="description"
                            onChange={handleChange('description')}
                            className="form-input"
                          />
                        </Form.Item>
                      </div>
                    </Form>
                  ) : (
                    <div className="spinner">
                      <Spin />
                      <span className="spinner-text">
                        Loading...
                      </span>
                    </div>
                  )
                }
              </div>
              <div className="panel-footer">
                <Form.Item className="action-buttons">
                  <Button
                    className="button button-standard-outline -padded-right"
                    disabled={!isEnabled}
                    loading={isFetching}
                    onClick={() => cancel()}
                  >
                    Cancel
                  </Button>
                  <Button
                    className="button button-standard"
                    form="detailsForm"
                    key="submit"
                    htmlType="submit"
                    type="primary"
                    loading={isFetching}
                    disabled={notChanged}
                  >
                    Save Changes
                  </Button>
                </Form.Item>
              </div>
            </section>
          </Col>
        </Row>
      </Fragment>
    </div>
  );
};

MerchantProjectSettings.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  form: PropTypes.object.isRequired,
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
    goBack: PropTypes.func.isRequired,
  }).isRequired,
  match: PropTypes.shape({
    params: PropTypes.shape({
      merchantCode: PropTypes.string.isRequired,
      projectId: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
};

export default withRouter(Form.create()(MerchantProjectSettings));
