import moment from 'moment-timezone';
import React, { Fragment, useEffect, useReducer } from 'react';
import PropTypes from 'prop-types';
import qs from 'query-string';
import { Link, useLocation } from 'react-router-dom';
import {
  Row, Col, DatePicker, Table, Icon, Select, Dropdown, Menu,
} from 'antd';
import { getPaymentsService } from '../../services/payments';
import { getProjectsService } from '../../services/projects';
import { getStatusColor, formatNumber } from '../../helpers/utils';
import '../../styles/transaction.css';

const dateFormat = 'YYYY/MM/DD';
const getInitialState = queryFilters => ({
  isFetchingPayments: true,
  payments: [],
  projects: [],
  searchOption: {
    page: parseInt(queryFilters.page, 10) || 1,
    size: 10,
    totalCount: 0,
    startDate: moment(queryFilters.start || null, dateFormat),
    endDate: moment(queryFilters.end || null, dateFormat),
    reportingDate: moment(queryFilters.reporting || null, dateFormat),
    project: queryFilters.project || null,
    query: null,
    settlementReferenceId: null,
    status: queryFilters.status ? queryFilters.status.split(',') : ['PAID', 'SETTLED'],
  },
});

function reducer(prevState, action) {
  switch (action.type) {
    case 'GET_PAYMENTS':
      return {
        ...prevState,
        isFetchingPayments: true,
      };
    case 'GET_PAYMENTS_SUCCESSFUL':
      return {
        ...prevState,
        isFetchingPayments: false,
        payments: action.payments,
        searchOption: { ...prevState.searchOption, totalCount: action.totalCount },
      };
    case 'GET_PAYMENTS_FAILED':
      return {
        ...prevState,
        isFetchingPayments: false,
        payments: [],
      };
    case 'SET_PROJECTS_FILTER':
      return {
        ...prevState,
        projects: action.projects,
      };
    case 'UPDATE_SEARCH_OPTION':
      return {
        ...prevState,
        isFetchingPayments: true,
        searchOption: action.searchOption,
      };
    default:
      return prevState;
  }
}

const { RangePicker } = DatePicker;
const PaymentsTable = (props) => {
  const { merchant, onChangeSearchOption } = props;

  const timeZone = moment.tz.guess(true);

  const location = useLocation();
  const queryFilters = qs.parse(location.search, { ignoreQueryPrefix: true });
  const [state, dispatch] = useReducer(reducer, getInitialState(queryFilters));

  useEffect(() => {
    let ableToSet = true;
    (async () => {
      try {
        const status = state.searchOption.status
          .reduce((pv, cv) => `${pv},${cv}`, '')
          .slice(1);
        const paymentsResponse = await getPaymentsService(merchant.id, {
          ...state.searchOption,
          status,
        });
        const { payments, totalCount } = paymentsResponse.data;

        if (ableToSet) {
          onChangeSearchOption(state.searchOption);
          dispatch({ type: 'GET_PAYMENTS_SUCCESSFUL', payments, totalCount });
        }
      } catch (error) {
        const message = error && error.response ? error.response.data.message
          : 'We are not able to get the payments from the server as of the moment';
        if (ableToSet) {
          dispatch({ type: 'GET_PAYMENTS_FAILED', message });
        }
      }
    })();

    return () => { ableToSet = false; };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    state.searchOption.page,
    state.searchOption.query,
    state.searchOption.status,
    state.searchOption.size,
    state.searchOption.startDate,
    state.searchOption.endDate,
    state.searchOption.project,
    state.searchOption.reportingDate,
  ]);

  useEffect(() => {
    let ableToSet = true;

    (async () => {
      try {
        // Current limit for querying the projects
        const opts = { page: 1, size: 150 };
        const projectsResponse = await getProjectsService(merchant.id, opts);
        const { projects } = projectsResponse.data;
        if (ableToSet) {
          dispatch({ type: 'SET_PROJECTS_FILTER', projects });
        }
      } catch (error) {
        if (ableToSet) {
          dispatch({ type: 'SET_PROJECTS_FILTER', projects: [] });
        }
      }
    })();
    return () => { ableToSet = false; };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const columns = [
    {
      title: 'Reference ID',
      key: 'paymentReferenceId',
      render: (row) => {
        if (row.paymentReferenceId) {
          return (
            <div>
              <Link
                className="table-item-link"
                to={`/merchants/${merchant.code}/payments/${row.paymentReferenceId}`}
              >
                <strong>{row.paymentReferenceId}</strong>
              </Link>
            </div>
          );
        }
        return <div>----</div>;
      },
    }, {
      title: 'Customer',
      key: 'customer',
      render: row => (
        <div>
          <div className="text-primary">{row.customerName || '-----'}</div>
          <div className="text-secondary">
            {(({ projectName, projectCategory }) => {
              if (projectName && projectCategory) { return `${projectName}, ${projectCategory}`; }
              if (projectName) { return projectName; }
              return '----';
            })(row)}
          </div>
        </div>
      ),
    }, {
      title: 'Type',
      key: 'type',
      render: row => (
        <Fragment>
          <div className="text-primary">{row.paymentTypeName}</div>
          <div className="text-secondary">{row.invoiceReferenceId ? 'Recurring Payment' : 'One-Time Payment'}</div>
        </Fragment>
      ),
    }, {
      title: 'Amount',
      key: 'amount',
      render: row => (
        <Fragment>
          <div style={{ marginBottom: '8px' }}>
            <span
              style={{
                fontWeight: 700,
                color: '#ffffff',
                backgroundColor: getStatusColor(row.paymentStatus),
                borderRadius: '4px',
                padding: '4px 8px',
              }}
            >
              {row.paymentStatus}
            </span>
          </div>
          <div className="text-primary">
            {row.billBase
              ? `${row.billBase[0]} ${formatNumber(row.billBase[1])}`
              : '-----'}
          </div>
        </Fragment>
      ),
    }, {
      title: 'Date',
      key: 'createdAt',
      render: (row) => {
        const dt = row.paidAt || row.invoiceCreatedAt;
        return (
          <div className="table-col-item">
            <div className="text-primary">
              {moment(dt).tz(timeZone).format('lll')}
            </div>
          </div>
        );
      },
    }, {
      key: 'externalTransactionId',
      render: (row) => {
        const menu = (
          <Menu>
            <Menu.Item key="transaction">
              <Link
                className="table-item-link"
                to={`/merchants/${merchant.code}/transactions/${row.externalTransactionId}`}
              >
                View transaction
              </Link>
            </Menu.Item>
          </Menu>
        );
        return (
          <Dropdown overlay={menu} trigger={['click']}>
            <Icon type="down-circle" />
          </Dropdown>
        );
      },
    },
  ];

  const pagination = {
    size: 'small',
    current: state.searchOption.page,
    defaultCurrent: 1,
    defaultPageSize: 10,
    pageSize: state.searchOption.size,
    total: state.searchOption.totalCount,
    showSizeChanger: true,
    pageSizeOptions: ['10', '20', '50', '100'],
    onShowSizeChange: (currentPage, size) => {
      dispatch({
        type: 'UPDATE_SEARCH_OPTION',
        searchOption: {
          ...state.searchOption,
          page: 1,
          size: Number(size),
        },
      });
    },
    onChange: (p) => {
      const totalPage = Math.ceil(state.searchOption.totalCount / state.searchOption.size);
      let page = p || 1;
      if (page < 1) { page = 1; }
      if (page > totalPage) { page = totalPage - 1; }

      dispatch({
        type: 'UPDATE_SEARCH_OPTION',
        searchOption: {
          ...state.searchOption,
          page,
        },
      });
    },
    showTotal: (total, range) => `Showing ${range[0]} - ${range[1]} of ${total}`,
  };

  const actions = (
    <Fragment>
      <Row gutter={8} className="filters" style={{ marginBottom: '12px' }}>
        <Col lg={6}>
          <h4 className="text-secondary">Project</h4>
          <Select
            className="transaction-filter"
            allowClear
            placeholder="Select a project"
            onSelect={(selectedProject) => {
              const project = state.projects.find(ps => ps.id === selectedProject);
              dispatch({
                type: 'UPDATE_SEARCH_OPTION',
                searchOption: { ...state.searchOption, project: project.id },
              });
            }}
            onChange={(selectedProject) => {
              if (!selectedProject) {
                dispatch({
                  type: 'UPDATE_SEARCH_OPTION',
                  searchOption: { ...state.searchOption, project: null },
                });
              }
            }}
            filterOption={(inputValue, option) => {
              const { props: { children } } = option;
              return children.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1;
            }}
            defaultValue={state.searchOption.project}
          >
            {state.projects.map(p => (
              <Select.Option value={p.id} key={p.id} label={p.name}>
                {p.name}
              </Select.Option>
            ))}
          </Select>
        </Col>
        <Col lg={6}>
          <h4 className="text-secondary">Created At</h4>
          <RangePicker
            className="transaction-filter"
            defaultValue={[
              state.searchOption.startDate.isValid() ? state.searchOption.startDate : null,
              state.searchOption.endDate.isValid() ? state.searchOption.endDate : null,
            ]}
            format={dateFormat}
            style={{ width: '100%' }}
            onChange={([start, end]) => {
              const startDate = start ? start.format(dateFormat) : null;
              const endDate = end ? end.format(dateFormat) : null;
              dispatch({
                type: 'UPDATE_SEARCH_OPTION',
                searchOption: {
                  ...state.searchOption,
                  startDate: moment(startDate, dateFormat),
                  endDate: moment(endDate, dateFormat),
                },
              });
            }}
          />
        </Col>
        <Col lg={6}>
          <h4 className="text-secondary">Report At</h4>
          <DatePicker
            className="transaction-filter"
            defaultValue={state.searchOption.reportingDate.isValid() ? state.searchOption.reportingDate : null}
            format={dateFormat}
            style={{ width: '100%' }}
            placeholder="Reporting date"
            onChange={(date) => {
              const reporting = date ? date.format(dateFormat) : null;
              dispatch({
                type: 'UPDATE_SEARCH_OPTION',
                searchOption: {
                  ...state.searchOption,
                  reportingDate: moment(reporting, dateFormat),
                },
              });
            }}
          />
        </Col>
        <Col lg={6}>
          <h4 className="text-secondary">Status</h4>
          <Select
            className="transaction-filter"
            mode="multiple"
            style={{ width: '100%' }}
            placeholder="Status"
            defaultValue={state.searchOption.status}
            onChange={(status) => {
              dispatch({
                type: 'UPDATE_SEARCH_OPTION',
                searchOption: {
                  ...state.searchOption,
                  status,
                },
              });
            }}
          >
            <Select.Option key="PAID">Paid</Select.Option>
            <Select.Option key="SCHED">Scheduled</Select.Option>
            <Select.Option key="FAIL">Failed</Select.Option>
            <Select.Option key="CANCELLED">Cancelled</Select.Option>
            <Select.Option key="SETTLED">Settled</Select.Option>
            <Select.Option key="REFUNDED">Refunded</Select.Option>
            <Select.Option key="PENDING">Pending</Select.Option>
          </Select>
        </Col>
      </Row>
    </Fragment>
  );

  return (
    <Fragment>
      {actions}
      <Table
        className="table-standard"
        dataSource={state.payments}
        loading={state.isFetchingPayments}
        columns={columns}
        pagination={pagination}
        rowKey="invoiceId"
      />
    </Fragment>
  );
};

PaymentsTable.propTypes = {
  merchant: PropTypes.shape({
    id: PropTypes.number.isRequired,
    code: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
  }).isRequired,
  onChangeSearchOption: PropTypes.func,
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }).isRequired,
  location: PropTypes.shape({
    pathname: PropTypes.string.isRequired,
    search: PropTypes.string.isRequired,
  }).isRequired,
};

PaymentsTable.defaultProps = {
  onChangeSearchOption: () => {},
};

export default PaymentsTable;
