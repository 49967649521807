import React, { Fragment, useReducer, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { Button, Tag } from 'antd';
import FileUpload from '../FileUpload';
import FilePreview from '../FilePreview';

const COMPONENT_STATE = {
  INIT: 0,
  UPLOADING: 1,
  UPLOADED: 2,
};

function SettlementUploadDocumentStep(props) {
  const {
    merchant, payments, settlementFile,
    onUpload, onRemoveFile, onNext, onPrevious,
  } = props;
  const [state, dispatch] = useReducer((prevState, action) => {
    switch (action.type) {
      case 'UPLOADING':
        return {
          ...prevState,
          compstate: COMPONENT_STATE.UPLOADING,
        };
      case 'SET_SETTLEMENT_FILE':
        return {
          ...prevState,
          settlementFile: action.settlementFile,
          compstate: COMPONENT_STATE.UPLOADED,
        };
      case 'CLEAR_FILE':
        return {
          ...prevState,
          settlementFile: null,
          compstate: COMPONENT_STATE.INIT,
        };
      default:
        return prevState;
    }
  }, {
    compstate: settlementFile ? COMPONENT_STATE.UPLOADED : COMPONENT_STATE.INIT,
    settlementFile,
    status: null,
    message: null,
  });

  useEffect(() => {
    if (state.settlementFile) {
      onUpload(state.settlementFile);
    } else {
      onRemoveFile();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.settlementFile]);

  function getTotalAmountFees(feeType) {
    const paymentValues = [];
    const reducer = (prevValue, currValue) => prevValue + currValue;
    payments.map(amount => (
      // eslint-disable-next-line no-nested-ternary
      amount.net[0] === 'USD'
        ? paymentValues.push(amount[feeType][1])
        : amount[feeType][0] === 'PHP' ? paymentValues.push(amount[feeType][1]) : paymentValues.push(amount.qwxRate[2] * amount[feeType][1])
    ));
    return [Math.round(paymentValues.reduce(reducer) * 100) / 100, paymentValues.length];
  }

  function getTotalCurrency() {
    let currency = '';
    // eslint-disable-next-line no-return-assign
    payments.map(amount => (amount.net[0] === 'USD' ? currency = 'USD' : currency = 'PHP'));
    return currency;
  }

  return (
    <Fragment>
      <section className="middle">
        <div className="middle-col heading-primary subheading">
          <div className="settlement-field">
            <div className="field-label">Payments</div>
            <div className="field-content">
              <div className="field-content-tags">
                {payments.map(payment => (
                  <Tag
                    className="text-primary payments-tag"
                    key={payment.invoiceId}
                    color="geekblue"
                    closable={false}
                  >
                    <Link
                      to={`/payments/${payment.invoiceId}`}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {payment.paymentReferenceId}
                    </Link>
                  </Tag>
                ))}
              </div>
            </div>
          </div>
        </div>
        <div className="middle-col heading-primary subheading">
          <div className="settlement-field">
            <div className="field-label">Settlement Summary</div>
            <div className="field-content">
              <div className="bill-amount">
                <p>
                  Total Amount:
                  { `${getTotalCurrency()} ${getTotalAmountFees('billTotal')[0]}` }
                </p>
                <p>
                  Total Waived:
                  { `${getTotalCurrency()} ${getTotalAmountFees('waivedFee')[0]}` }
                </p>
                <p>
                  Total Net Amount:
                  { `${getTotalCurrency()} ${getTotalAmountFees('net')[0]}` }
                </p>
                <p>
                  From
                  { getTotalAmountFees('billTotal')[1] }
                  {' '}
                  Payments
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="middle-col heading-primary subheading">
          <div className="settlement-field">
            <div className="field-label">Settlement File</div>
            <div className="field-content">
              {state.settlementFile ? (
                <FilePreview
                  fileName={state.settlementFile.fileName}
                  url={state.settlementFile.presignedUrl}
                  isRemoveable
                  onRemove={() => dispatch({ type: 'CLEAR_FILE' })}
                />
              ) : (
                <FileUpload
                  merchant={merchant}
                  onUploading={() => {
                    dispatch({ type: 'UPLOADING' });
                  }}
                  onUpload={(file) => {
                    if (file) {
                      dispatch({ type: 'SET_SETTLEMENT_FILE', settlementFile: file });
                    }
                  }}
                />
              )}
            </div>
          </div>
        </div>
      </section>
      <div className="header footer">
        <div className="action-button">
          <Button
            className="button button-standard button-standard-outline"
            onClick={() => onPrevious()}
          >
            Previous
          </Button>
          <Button
            className="button button-standard next-button"
            onClick={() => onNext()}
            loading={state.compstate === COMPONENT_STATE.UPLOADING}
            disabled={state.compstate !== COMPONENT_STATE.UPLOADED}
          >
            Next
          </Button>
        </div>
      </div>
    </Fragment>
  );
}

SettlementUploadDocumentStep.propTypes = {
  merchant: PropTypes.shape({
    id: PropTypes.number.isRequired,
    code: PropTypes.string.isRequired,
  }).isRequired,
  payments: PropTypes.arrayOf(PropTypes.object).isRequired,
  settlementFile: PropTypes.shape({
    settlementFileId: PropTypes.number.isRequired,
  }),
  onUpload: PropTypes.func,
  onRemoveFile: PropTypes.func,
  onPrevious: PropTypes.func,
  onNext: PropTypes.func,
};

SettlementUploadDocumentStep.defaultProps = {
  settlementFile: null,
  onUpload: () => {},
  onRemoveFile: () => {},
  onPrevious: () => {},
  onNext: () => {},
};

export default SettlementUploadDocumentStep;
