import React, { Fragment, useContext } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { Menu } from 'antd';
import {
  DashboardIcon, PaymentIcon, EnrollmentIcon, SettlementReportsIcon, DisputeIcon,
  MerchantsIcon, UsersIcon, TerminalIcon, PieChartIcon,
} from '../Icons';
import SessionContext from '../../contexts/SessionContext';
import { APP_STATES, ROLES } from '../../constants';
import './index.css';

const QwikwireNavigation = () => {
  const sessionContext = useContext(SessionContext);
  const history = useHistory();
  const location = useLocation();
  const { appState } = sessionContext;

  if (appState <= APP_STATES.IS_LOADING) { return <div />; }

  const { systemRole } = sessionContext.loggedInUser;
  const menus = [
    {
      isVisible: systemRole[0] <= ROLES.AUDITOR,
      value: (
        <Menu.Item key="dashboard">
          <div className="custom-icon">
            <DashboardIcon width="17px" height="17px" />
          </div>
          <span>Dashboard</span>
        </Menu.Item>
      ),
    },
    {
      isVisible: systemRole[0] <= ROLES.SUPPORT,
      value: (
        <Menu.Item key="payments">
          <div className="custom-icon">
            <PaymentIcon width="19px" height="19px" />
          </div>
          <span>Payments</span>
        </Menu.Item>
      ),
    },
    {
      isVisible: systemRole[0] <= ROLES.SUPPORT,
      value: (
        <Menu.Item key="enrollments">
          <div className="custom-icon">
            <EnrollmentIcon width="18px" height="17px" />
          </div>
          <span>Enrollments</span>
        </Menu.Item>
      ),
    },
    {
      isVisible: systemRole[0] <= ROLES.SUPPORT,
      value: (
        <Menu.Item key="settlements">
          <div
            className="custom-icon"
            style={{ marginLeft: '20px', marginRight: '21px' }}
          >
            <SettlementReportsIcon />
          </div>
          <span>Settlements</span>
        </Menu.Item>
      ),
    },
    {
      isVisible: systemRole[0] <= ROLES.SUPPORT,
      value: (
        <Menu.Item key="disputes">
          <div className="custom-icon">
            <DisputeIcon width="18px" height="17px" />
          </div>
          <span>Disputes</span>
        </Menu.Item>
      ),
    },
    {
      isVisible: systemRole[0] <= ROLES.AUDITOR,
      value: (
        <Menu.Item key="reports">
          <div
            className="custom-icon"
            style={{ marginLeft: '20px', marginRight: '21px', marginTop: '3px' }}
          >
            <PieChartIcon />
          </div>
          <span>Reports</span>
        </Menu.Item>
      ),
    },
    {
      isVisible: systemRole[0] <= ROLES.AUDITOR,
      value: (
        <Menu.Item key="activities">
          <div className="custom-icon" style={{ width: '12px', marginTop: '4px', marginRight: '24px' }}>
            <TerminalIcon area="20px" />
          </div>
          <span>Activity Log</span>
        </Menu.Item>
      ),
    },
    {
      isVisible: systemRole[0] <= ROLES.AUDITOR,
      value: (
        <Menu.Item key="merchants">
          <div className="custom-icon" style={{ width: '10px', marginTop: '4px', marginRight: '26px' }}>
            <MerchantsIcon />
          </div>
          <span>Merchants</span>
        </Menu.Item>
      ),
    },
    {
      isVisible: systemRole[0] <= ROLES.AUDITOR,
      value: (
        <Menu.Item key="users">
          <div className="custom-icon" style={{ marginTop: '3px' }}>
            <UsersIcon />
          </div>
          <span>Users</span>
        </Menu.Item>
      ),
    },
  ];

  function getMenuBasedOnPath(loc) {
    const { pathname } = loc;
    // eslint-disable-next-line no-unused-vars
    const [category, ...extra] = pathname.split('/').slice(1);
    return category;
  }

  function onNavigationClick(e) {
    history.push(`/${e.key}`);
  }

  return (
    <Fragment>
      <div className="navigation">
        <div>
          <div className="merchant-dropdown-picker" style={{ margin: '4px 8px 4px 8px' }}>
            <div className="dropdown-selector">
              <div className="dropdown-selector-text">
                <div style={{ marginRight: '12px', width: '24px' }}>
                  <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M0.5 7.00403H23.5" stroke="#36D1DC" strokeLinecap="round" strokeLinejoin="round" />
                    <path d="M21.5 2.00403H2.5C1.39543 2.00403 0.5 2.89946 0.5 4.00403V20.004C0.5 21.1086 1.39543 22.004 2.5 22.004H21.5C22.6046 22.004 23.5 21.1086 23.5 20.004V4.00403C23.5 2.89946 22.6046 2.00403 21.5 2.00403Z" stroke="#36D1DC" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" />
                    <path d="M0.5 7.00403H23.5" stroke="#36D1DC" strokeLinecap="round" strokeLinejoin="round" />
                    <path d="M4 4.25403C4.04945 4.25403 4.09778 4.26869 4.13889 4.29616C4.18 4.32363 4.21205 4.36268 4.23097 4.40836C4.24989 4.45404 4.25484 4.50431 4.2452 4.5528C4.23555 4.6013 4.21174 4.64584 4.17678 4.6808C4.14181 4.71577 4.09727 4.73958 4.04877 4.74922C4.00028 4.75887 3.95001 4.75392 3.90433 4.735C3.85865 4.71608 3.8196 4.68403 3.79213 4.64292C3.76466 4.60181 3.75 4.55347 3.75 4.50403C3.75 4.43772 3.77634 4.37414 3.82322 4.32725C3.87011 4.28037 3.9337 4.25403 4 4.25403" stroke="#36D1DC" strokeLinecap="round" strokeLinejoin="round" />
                    <path d="M7 4.25403C7.04945 4.25403 7.09778 4.26869 7.13889 4.29616C7.18 4.32363 7.21205 4.36268 7.23097 4.40836C7.24989 4.45404 7.25484 4.50431 7.2452 4.5528C7.23555 4.6013 7.21174 4.64584 7.17678 4.6808C7.14181 4.71577 7.09727 4.73958 7.04877 4.74922C7.00028 4.75887 6.95001 4.75392 6.90433 4.735C6.85865 4.71608 6.8196 4.68403 6.79213 4.64292C6.76466 4.60181 6.75 4.55347 6.75 4.50403C6.75 4.43772 6.77634 4.37414 6.82322 4.32725C6.87011 4.28037 6.9337 4.25403 7 4.25403Z" stroke="#36D1DC" strokeLinecap="round" strokeLinejoin="round" />
                    <path d="M10 4.25403C10.0494 4.25403 10.0978 4.26869 10.1389 4.29616C10.18 4.32363 10.212 4.36268 10.231 4.40836C10.2499 4.45404 10.2548 4.50431 10.2452 4.5528C10.2356 4.6013 10.2117 4.64584 10.1768 4.6808C10.1418 4.71577 10.0973 4.73958 10.0488 4.74922C10.0003 4.75887 9.95001 4.75392 9.90433 4.735C9.85865 4.71608 9.8196 4.68403 9.79213 4.64292C9.76466 4.60181 9.75 4.55347 9.75 4.50403C9.75 4.43772 9.77634 4.37414 9.82322 4.32725C9.87011 4.28037 9.9337 4.25403 10 4.25403Z" stroke="#36D1DC" strokeLinecap="round" strokeLinejoin="round" />
                    <path d="M0.5 15.0001H5.232C5.31434 15 5.3954 14.9796 5.46797 14.9407C5.54054 14.9018 5.60238 14.8456 5.648 14.7771L6.972 12.8001C7.02243 12.724 7.0927 12.6633 7.1752 12.6243C7.2577 12.5854 7.34928 12.5698 7.44002 12.5791C7.53077 12.5885 7.61722 12.6225 7.69001 12.6775C7.76281 12.7325 7.81917 12.8063 7.853 12.8911L9.147 16.1261C9.18083 16.2106 9.2371 16.2843 9.30975 16.3392C9.3824 16.3941 9.46867 16.4282 9.55924 16.4376C9.64981 16.4471 9.74124 16.4317 9.82368 16.393C9.90612 16.3543 9.97642 16.2938 10.027 16.2181L12.988 11.7771C13.038 11.7023 13.1072 11.6423 13.1884 11.6036C13.2696 11.5648 13.3597 11.5487 13.4493 11.5569C13.5389 11.5651 13.6246 11.5973 13.6974 11.6501C13.7702 11.7029 13.8274 11.7744 13.863 11.8571L16.136 17.1571C16.1714 17.24 16.2285 17.3118 16.3014 17.3649C16.3742 17.418 16.4601 17.4504 16.5499 17.4587C16.6396 17.467 16.73 17.4509 16.8113 17.4121C16.8927 17.3732 16.962 17.3131 17.012 17.2381L18.351 15.2281C18.396 15.1586 18.4576 15.1014 18.5302 15.0616C18.6028 15.0218 18.6842 15.0007 18.767 15.0001H23.5" stroke="#36D1DC" strokeLinecap="round" strokeLinejoin="round" />
                  </svg>
                </div>
                <h3 style={{ marginBottom: 0 }}>Qwikwire Billing</h3>
              </div>
            </div>
          </div>
          <div>
            <Menu
              className="navigation-menu"
              onClick={onNavigationClick}
              selectedKeys={[getMenuBasedOnPath(location)]}
            >
              {menus.filter(m => m.isVisible).map(m => m.value)}
            </Menu>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default QwikwireNavigation;
