import React, { Fragment, useContext, useReducer } from 'react';
import {
  Breadcrumb,
  Col,
  Row,
} from 'antd';

import { FolderIcon, CodeIcon } from '../../components/Icons';
import Message from '../../components/Message';
import { ApiKeysTab } from '../../components';
import SessionContext from '../../contexts/SessionContext';
import { MERCHANT_ROLES } from '../../constants/index';

import './developer-settings.scss';

const initialState = {
  status: null,
  message: null,
};

function reducer(prevState, action) {
  switch (action.type) {
    case 'SHOW_MESSAGE':
      return {
        ...prevState,
        status: action.response.status,
        message: action.response.message,
      };
    case 'CLEAR_MESSAGE':
      return {
        ...prevState,
        status: null,
        message: null,
      };
    default:
      return prevState;
  }
}

const PartnerDeveloperSettings = () => {
  const { activeMerchant } = useContext(SessionContext);

  const [state, dispatch] = useReducer(reducer, initialState);

  const { merchants, loggedInUser } = useContext(SessionContext);
  const merchant = merchants.find(m => m.code === activeMerchant.code);
  const { systemRole, merchantRoles } = loggedInUser;
  const { merchantRole } = merchantRoles.find(m => m.merchantCode === merchant.code);

  if (!(loggedInUser && merchants.length)) { return <div />; }
  if (systemRole[0] >= 80
    || (systemRole[0] === 70 && ![MERCHANT_ROLES.ADMIN, MERCHANT_ROLES.DEVELOPER].includes(merchantRole[0]))
    || !merchant
  ) {
    return (
      <div>
        <div className="spinner">
          <FolderIcon />
          <h1>You are not allowed to access the developer settings page.</h1>
          <p>
            You can contact us at
            <a href="mailto:support@aqwire.io">support@aqwire.io</a>
            if you have concerns or issues.
          </p>
        </div>
      </div>
    );
  }

  return (
    <div>
      <Fragment>
        <Row className="breadcrumb-row">
          <Col className="breadcrumb-header">
            <Breadcrumb className="breadcrumb-parent">
              <Breadcrumb.Item className="item-container" style={{ alignItems: 'unset' }}>
                <div className="breadcrumb-item breadcrumb-icon">
                  <CodeIcon />
                </div>
                <div className="breadcrumb-item icon-label">
                  <h4 style={{ display: 'flex', flexDirection: 'column', marginTop: '4px', marginBottom: 0 }}>
                    <div className="heading-primary" style={{ lineHeight: 1.24 }}>
                      Developers
                    </div>
                  </h4>
                </div>
              </Breadcrumb.Item>
            </Breadcrumb>
          </Col>
        </Row>
        <Row className="breadcrumb-row">
          <Col>
            {state.status && state.message && (
              <Message
                style={{ marginBottom: '12px' }}
                status={state.status}
                message={state.message}
                onClose={() => dispatch({ type: 'CLEAR_MESSAGE' })}
              />
            )}
          </Col>
        </Row>
        <ApiKeysTab
          merchant={merchant}
          onComplete={(response) => {
            dispatch({ type: 'SHOW_MESSAGE', response });
          }}
        />
      </Fragment>
    </div>
  );
};

export default PartnerDeveloperSettings;
