import React, { useContext, useEffect, useReducer, useState } from 'react';
import PropTypes from 'prop-types';
import { withRouter, Link } from 'react-router-dom';
import { Button, Form, Input } from 'antd';
import SessionContext from '../../contexts/SessionContext';
import { verifyResetTokenService, resetPasswordService } from '../../services/auth';
import { SingleForm, PasswordMeter } from '../../components';
import '../../styles/authform.css';


const initialState = {
  isVerifyingToken: false,
  isResettingPasssword: false,
  isTokenValid: false,
  errorMessage: null,
  user: null,
};

function reducer(prevState, action) {
  switch (action.type) {
    case 'VERIFYING_TOKEN':
      return {
        ...prevState,
        isVerifyingToken: true,
      };

    case 'VERIFYING_TOKEN_SUCCESSFUL':
      return {
        ...prevState,
        isVerifyingToken: false,
        isTokenValid: true,
        user: action.user,
      };

    case 'VERIFYING_TOKEN_FAILED':
      return {
        ...prevState,
        isVerifyingToken: false,
        isTokenValid: false,
        errorMessage: action.message,
      };

    case 'RESETTING_PASSWORD':
      return {
        ...prevState,
        isResettingPasssword: true,
      };

    case 'RESETTING_PASSWORD_SUCCESSFUL':
      return {
        ...prevState,
        isResettingPasssword: false,
      };

    case 'RESETTING_PASSWORD_FAILED':
      return {
        ...prevState,
        isResettingPasssword: false,
        errorMessage: action.message,
      };

    case 'CLEAR_ERROR_MESSAGE':
      return {
        ...prevState,
        errorMessage: null,
      };

    default:
      return prevState;
  }
}

const formItemLayout = { wrapperCol: { xs: { span: 24 } } };
const { Password } = Input;
const PasswordReset = (props) => {
  const { match: { params: { resetId, token } }, form, history } = props;
  const { getFieldDecorator } = form;
  const { showToast } = useContext(SessionContext);
  const [password, setPassword] = useState(null);
  const [isPasswordAcceptable, setIsPasswordAcceptable] = useState(false);
  const [state, dispatch] = useReducer(reducer, initialState);
  const passwordMeter = (
    <PasswordMeter
      isPasswordAcceptable={setIsPasswordAcceptable}
      password={password}
    />
  );

  useEffect(() => {
    let ableToSet = true;

    (async () => {
      dispatch({ type: 'VERIFYING_TOKEN' });
      try {
        const { data: { person: user } } = await verifyResetTokenService(resetId, token);
        if (ableToSet) dispatch({ type: 'VERIFYING_TOKEN_SUCCESSFUL', user });
      } catch (err) {
        const message = err && err.response
          ? err.response.data.message
          : 'Unable to validate your password reset request';
        if (ableToSet) dispatch({ type: 'VERIFYING_TOKEN_FAILED', message });
        showToast({ type: 'error', message });
      }
    })();

    return () => { ableToSet = false; };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function onSubmit(e) {
    e.preventDefault();
    if (!isPasswordAcceptable) { return; }

    dispatch({ type: 'RESETTING_PASSWORD' });
    form.validateFields(async (err, values) => {
      if (err) dispatch({ type: 'RESETTING_PASSWORD_FAILED', message: 'You must provide a valid password' });
      else {
        try {
          await resetPasswordService(resetId, token, values.password);
          dispatch({ type: 'RESETTING_PASSWORD_SUCCESSFUL' });
          history.push(`/login?email=${state.user.email}&reset=success`);
        } catch (error) {
          dispatch({
            type: 'RESETTING_PASSWORD_FAILED',
            message: error && error.response
              ? error.response.data.message
              : 'Unable to validate your password reset request',
          });
        }
      }
    });
  }

  if (state.isVerifyingToken) {
    return <SingleForm><p>Verifying...</p></SingleForm>;
  }

  if (!state.isVerifyingToken && !state.isTokenValid) {
    return (
      <SingleForm
        title="Reset Password"
        subtitle="Something is wrong with your reset password link. If you believe this to be a mistake, please contact us and we will sort it out for you."
      >
        <div style={{ marginTop: '30px', marginBottom: '15px' }}>
          <Link to="/login" className="button button-standard button-fullwidth">
            Back to log in
          </Link>
        </div>
      </SingleForm>
    );
  }

  return (
    <SingleForm
      title="Reset Password"
      subtitle="Please enter your new password."
      message={state.errorMessage}
      status={state.errorMessage ? 'error' : null}
      onMessageClose={() => dispatch({ type: 'CLEAR_ERROR_MESSAGE' })}
    >
      <Form onSubmit={onSubmit}>
        <Form.Item {...formItemLayout} extra={passwordMeter}>
          {getFieldDecorator('password', {
            rules: [{ required: true, message: 'Please input your new password' }],
          })(<Password
            placeholder="New Password"
            onChange={e => setPassword(e.target.value)}
            required
          />)}
        </Form.Item>
        <Form.Item>
          <Button
            className="button button-standard button-fullwidth"
            htmlType="submit"
            loading={state.isResettingPasssword}
            disabled={state.isResettingPasssword || !isPasswordAcceptable}
          >
            Change Password
          </Button>
        </Form.Item>
      </Form>
    </SingleForm>
  );
};

PasswordReset.propTypes = {
  form: PropTypes.shape({
    getFieldDecorator: PropTypes.func.isRequired,
    validateFields: PropTypes.func.isRequired,
  }).isRequired,
  match: PropTypes.shape({
    params: PropTypes.shape({
      resetId: PropTypes.string.isRequired,
      token: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }).isRequired,
};

export default withRouter(Form.create()(PasswordReset));
