import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { Link, withRouter } from 'react-router-dom';
import { Input, Layout, Dropdown, Menu, Icon } from 'antd';
import SessionContext from '../../contexts/SessionContext';

const MainNavbar = (props) => {
  const { history, onSearch, searchQuery } = props;
  const { loggedInUser, activeMerchant, logout } = useContext(SessionContext);

  if (!loggedInUser) { return null; }
  const dropdownHeader = loggedInUser && loggedInUser.id
    ? (
      <div className="ant-dropdown-link header-dropdown-text">
        <div className="header-dropdown-primary">My Account</div>
        <Icon type="down" />
      </div>
    )
    : <div />;

  return (
    <Layout.Header className="main-layout-header">
      {activeMerchant.type !== 'partner'
        ? (
          <Input.Search
            placeholder="Search Transactions, Clients or Projects"
            className="header-search"
            defaultValue={searchQuery}
            onSearch={onSearch}
          />
        ) : <div />
      }
      <div className="header-dropdown">
        <Dropdown
          trigger={['click']}
          overlay={(
            <Menu
              className="header-dropdown-menu"
              onClick={(e) => {
                switch (e.key) {
                  case 'logout':
                    history.replace('/login');
                    logout();
                    break;
                  default: break;
                }
              }}
            >
              <Menu.Item key="accountsettings">
                <Link to="/account">
                  <span>Account Settings</span>
                </Link>
              </Menu.Item>
              <Menu.Item key="logout">
                <span>Logout</span>
              </Menu.Item>
            </Menu>
          )}
        >
          {dropdownHeader}
        </Dropdown>
      </div>
    </Layout.Header>
  );
};

MainNavbar.propTypes = {
  history: PropTypes.shape({
    replace: PropTypes.func.isRequired,
  }).isRequired,
  searchQuery: PropTypes.string,
  onSearch: PropTypes.func.isRequired,
};

MainNavbar.defaultProps = {
  searchQuery: null,
};

export default withRouter(MainNavbar);
