import React from 'react';
import { Link } from 'react-router-dom';
import { Breadcrumb, List } from 'antd';
import { PieChartIcon } from '../../components/Icons';


function Reports() {
  const reports = [
    {
      title: 'Summary of all payments by project and payment type',
      description: 'Total number of payments grouped by payment type and project based on dates',
      to: '/reports/payments',
    },
    {
      title: 'Summary of payment issuers',
      description: 'List of payment issuers based on date range',
      to: '/reports/issuers',
    },
  ];

  return (
    <div className="qwikwire">
      <section style={{ display: 'flex', justifyContent: 'space-between' }}>
        <div className="transaction-breadcrumb">
          <div className="transaction-breadcrumb-icon">
            <PieChartIcon diameter="28px" />
          </div>
          <Breadcrumb>
            <Breadcrumb.Item>
              Reports
            </Breadcrumb.Item>
          </Breadcrumb>
        </div>
      </section>
      <section className="panel panel-standard">
        <div className="panel-body">
          <List
            itemLayout="horizontal"
            dataSource={reports}
            renderItem={item => (
              <List.Item>
                <div style={{ paddingTop: '12px' }}>
                  <Link to={item.to} alt={item.title}>
                    <h3>{item.title}</h3>
                  </Link>
                  <div className="panel-item">{item.description}</div>
                </div>
              </List.Item>
            )}
          />
        </div>
      </section>
    </div>
  );
}

export default Reports;
