/**
 * From ReactGA Community Wiki Page https://github.com/react-ga/react-ga/wiki/React-Router-v4-withTracker
 */

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import ReactGA from 'react-ga';
import { googleAnalyticsTrackingId } from '../config';

if (googleAnalyticsTrackingId) {
  ReactGA.initialize(googleAnalyticsTrackingId);
}

function withTracker(WrappedComponent, options = {}) {
  const trackPage = (page) => {
    if (googleAnalyticsTrackingId) {
      ReactGA.set({
        page,
        ...options,
      });
      ReactGA.pageview(page);
    }
  };

  const HOC = class extends Component {
    componentDidMount() {
      const page = this.props.location.pathname;
      trackPage(page);
    }

    componentDidUpdate(prevProps) {
      const currentPage = prevProps.location.pathname + prevProps.location.search;
      const nextPage = this.props.location.pathname + this.props.location.search;

      if (currentPage !== nextPage) {
        trackPage(nextPage);
      }
    }

    render() {
      return <WrappedComponent {...this.props} />;
    }
  };

  HOC.propTypes = {
    location: PropTypes.shape({
      pathname: PropTypes.string.isRequired,
      search: PropTypes.string,
    }).isRequired,
  };

  return HOC;
}

export default withTracker;
