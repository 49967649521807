import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { Dropdown, Avatar, Menu, Input } from 'antd';
import './activemerchantselector.css';

const MenuItem = Menu.Item;
const ActiveMerchantSelector = (props) => {
  const { activeMerchant, history, merchants, setSelectedMerchant } = props;
  const selectedMerchant = activeMerchant;
  const [merchantHolder, setMerchantHolder] = useState(merchants);
  const [searchQuery, setSearchQuery] = useState('');
  const { Search } = Input;

  if (merchants.length === 0) {
    return (<div className="merchant-dropdown-picker" />);
  }

  const logo = selectedMerchant.logo
    ? (<Avatar src={selectedMerchant.logo} />)
    : (<Avatar shape="circle" style={{ backgroundColor: '#36D1DC' }}>{selectedMerchant.name[0]}</Avatar>);

  if (merchants.length === 1 && selectedMerchant) {
    return (
      <div className="merchant-dropdown-picker">
        <div className="dropdown-selector">
          <div className="dropdown-selector-text">
            <div>{logo}</div>
            <strong className="dropdown-selector-name">
              {selectedMerchant.name}
            </strong>
          </div>
        </div>
      </div>
    );
  }

  function onClick({ key }) {
    if (selectedMerchant && selectedMerchant.code === key) {
      return;
    }
    const merchant = merchants.find(m => m.code === key);
    setSelectedMerchant(merchant);
    history.push(`/merchants/${merchant.code}`);
  }

  function searchMerchants(query) {
    setSearchQuery(query);
    setMerchantHolder(merchants.filter(s => s.name.toLowerCase().includes(query.toLowerCase())));
  }

  const menu = (
    <Menu onClick={onClick}>
      <div className="merchant-search-box">
        <Search type="text" placeholder="Search" value={searchQuery} onChange={({ target: { value } }) => searchMerchants(value)} />
      </div>
      {merchantHolder.map(merchant => (
        <MenuItem key={merchant.code} onClick={() => searchMerchants('')} className="merchant-search-item">
          <Avatar src={merchant.logo} alt="" />
          {selectedMerchant && selectedMerchant.code === merchant.code ? (
            <strong>{merchant.name}</strong>
          ) : (
            <Link to={`/merchants/${merchant.code}`}>{merchant.name}</Link>
          )
          }
        </MenuItem>
      ))}
    </Menu>
  );

  return (
    <div className="merchant-dropdown-picker">
      <Dropdown overlay={menu} trigger={['click']} placement="bottomRight" overlayClassName="merchant-dropdown-picker">
        {selectedMerchant ? (
          <div className="dropdown-selector">
            <div className="dropdown-selector-text">
              <div>{logo}</div>
              <strong className="dropdown-selector-name">
                {selectedMerchant.name}
              </strong>
            </div>
            <div style={{ marginLeft: '3px', marginRight: '5px' }}>
              <div style={{ width: '10px' }}>
                <svg viewBox="64 64 896 896">
                  <path fill="#8B90A0" d="M890.5 755.3L537.9 269.2c-12.8-17.6-39-17.6-51.7 0L133.5 755.3A8 8 0 0 0 140 768h75c5.1 0 9.9-2.5 12.9-6.6L512 369.8l284.1 391.6c3 4.1 7.8 6.6 12.9 6.6h75c6.5 0 10.3-7.4 6.5-12.7z" />
                </svg>
                <svg viewBox="64 64 896 896">
                  <path fill="#8B90A0" d="M884 256h-75c-5.1 0-9.9 2.5-12.9 6.6L512 654.2 227.9 262.6c-3-4.1-7.8-6.6-12.9-6.6h-75c-6.5 0-10.3 7.4-6.5 12.7l352.6 486.1c12.8 17.6 39 17.6 51.7 0l352.6-486.1c3.9-5.3.1-12.7-6.4-12.7z" />
                </svg>
              </div>
            </div>
          </div>
        ) : (
          <div style={{ textAlign: 'center' }}>
            <strong>Choose your Group</strong>
          </div>
        )}
      </Dropdown>
    </div>
  );
};

ActiveMerchantSelector.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  merchants: PropTypes.array.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  activeMerchant: PropTypes.object,
  // eslint-disable-next-line react/forbid-prop-types
  history: PropTypes.object.isRequired,
  setSelectedMerchant: PropTypes.func.isRequired,
};

ActiveMerchantSelector.defaultProps = {
  activeMerchant: null,
};

export default ActiveMerchantSelector;
