import crypto from 'crypto';
import { beaconSecretKey } from '../config/index';

const scriptNode = document.createElement('script');

export function initializeBeacon({ name, email }) {
  if (!beaconSecretKey) {
    return;
  }

  scriptNode.type = 'text/javascript';
  scriptNode.innerHTML = '!function(e,t,n){function a(){var e=t.getElementsByTagName("script")'
    + '[0],n=t.createElement("script");n.type="text/javascript",n.async=!0,n.src="https://beacon-v2.helpscout.net",'
    + 'e.parentNode.insertBefore(n,e)}if(e.Beacon=n=function(t,n,a){e.Beacon.readyQueue.push({method:t,options:n,data:a})}'
    + ',n.readyQueue=[],"complete"===t.readyState)return a();e.attachEvent?e.attachEvent("onload",a):e.addEventListener("load",a,!1)}'
    + '(window,document,window.Beacon||function(){});';

  document.head.appendChild(scriptNode);
  window.Beacon('init', 'fcca0912-63d3-4d0d-ad24-a09b83139bd3');
  window.Beacon('identify', {
    name,
    email,
    signature: crypto.createHmac('sha256', beaconSecretKey).update(email).digest('hex'),
  });
}

export function terminateBeacon() {
  if (!beaconSecretKey || typeof window.Beacon !== 'function') {
    return;
  }

  window.Beacon('destroy');
  scriptNode.remove();
}
