import React, { useContext, useState } from 'react';
import PropTypes from 'prop-types';
import { Card, Form, Switch } from 'antd';
import SessionContext from '../../contexts/SessionContext';
import { updateUserNotifications } from '../../services/auth';

const formItemLayout = { labelCol: { span: 24 }, wrapperCol: { span: 24 } };
const FormItem = Form.Item;
const AccountSettingsNotification = (props) => {
  const [setIsSaving] = useState(false);
  const { showToast } = useContext(SessionContext);
  let { canReceiveDailyTransactionEmails, canReceiveSettlementEmails } = props.user;

  async function onChangeNotifDaily(value) {
    setIsSaving(true);
    try {
      canReceiveDailyTransactionEmails = value;
      const { data: { message } } = await updateUserNotifications(canReceiveDailyTransactionEmails, canReceiveSettlementEmails);
      showToast({ type: 'success', message });
      setIsSaving(false);
    } catch (error) {
      const message = error && error.response
        ? error.response.data.message
        : 'Unable to save your account information as of the moment';
      showToast({ type: 'error', message });
      setIsSaving(false);
    }
  }

  async function onChangeNotifTransaction(value) {
    setIsSaving(true);
    try {
      canReceiveSettlementEmails = value;
      const { data: { message } } = await updateUserNotifications(canReceiveDailyTransactionEmails, canReceiveSettlementEmails);
      showToast({ type: 'success', message });
      setIsSaving(false);
    } catch (error) {
      const message = error && error.response
        ? error.response.data.message
        : 'Unable to save your account information as of the moment';
      showToast({ type: 'error', message });
      setIsSaving(false);
    }
  }

  return (
    <Card className="settings-card">
      <Form>
        <div className="settings-card-body">
          <FormItem {...formItemLayout}>
            Receive notifications on daily transaction reports
            <Switch
              style={{ float: 'right' }}
              defaultChecked={props.user.canReceiveDailyTransactionEmails}
              onChange={value => onChangeNotifDaily(value)}
            />
          </FormItem>
          <FormItem {...formItemLayout}>
            Receive notifications on submitted settlement reports
            <Switch
              style={{ float: 'right' }}
              defaultChecked={props.user.canReceiveSettlementEmails}
              onChange={value => onChangeNotifTransaction(value)}
            />
          </FormItem>
        </div>
      </Form>
    </Card>
  );
};

AccountSettingsNotification.propTypes = {
  form: PropTypes.shape({
    getFieldDecorator: PropTypes.func.isRequired,
    validateFields: PropTypes.func.isRequired,
  }).isRequired,
  user: PropTypes.shape({
    canReceiveDailyTransactionEmails: PropTypes.bool.isRequired,
    canReceiveSettlementEmails: PropTypes.bool.isRequired,
  }).isRequired,
};

export default Form.create()(AccountSettingsNotification);
