import api from '../helpers/apiClient';

export function getUsersService(searchOption) {
  const {
    page,
    scope,
    systemRole,
    merchantRole,
    showDeactivated,
    searchQuery,
  } = searchOption;

  return api.get('/users', {
    params: {
      page,
      merchant: scope,
      systemRole,
      merchantRole,
      deactivated: showDeactivated,
      query: searchQuery,
    },
  });
}

export function getUserService(userId) {
  return api.get(`/users/${userId}`);
}

export function createUserService(values) {
  return api.post('/users', values);
}

export function updateUserService(values) {
  const { userId, ...otherValues } = values;
  return api.post(`/users/${userId}`, otherValues);
}

export function sendInviteService(email) {
  return api.post(`/users/${email}/invite`);
}

export function deactivateUserService(email) {
  return api.delete(`/users/${email}`);
}

export function setMerchantsService(merchants, userid) {
  return api.post(`/users/${userid}/merchants`, { merchants });
}
