import moment from 'moment';
import React, {
  Fragment, useReducer, useEffect, useContext,
} from 'react';
import PropTypes from 'prop-types';
import qs from 'query-string';
import { Link } from 'react-router-dom';
import {
  Col, Row, Breadcrumb, Tabs, Alert,
  Button, Modal, Form, Input, Menu,
  Icon, Dropdown,
} from 'antd';
import { SingleForm } from '../../components';
import PaymentLogs from '../../components/PaymentLogs';
import PaymentSettlementDetails from '../../components/PaymentSettlementDetails';
import PaymentMethodDetails from '../../components/PaymentMethodDetails';
import BreakdownItem from '../../components/BreakdownItem';
import Message from '../../components/Message';
import SessionContext from '../../contexts/SessionContext';
import { PaymentIcon, FolderIcon, CopyIcon } from '../../components/Icons';
import { getPaymentService } from '../../services/payments';
import { getDisputeByInvoiceId } from '../../services/disputes';
import { cancelPaymentLinkService, sendPaymentLinkEmailService } from '../../services/paymentLinkService';
import { formatNumber, getAPIErrorMessage, getStatusColor, parseCustomValue } from '../../helpers/utils';
import '../../styles/panel.css';
import './payment.css';

const getInitialState = queryFilters => ({
  isFetchingEnrollment: true,
  payment: null,
  defaultActiveTab: queryFilters.tab || 'info',
  isPaymentDisputed: false,
  dispute: null,
  isEmailModalVisible: false,
  isCancelLinkVisible: false,
  isSendingPaymentLink: false,
  status: null,
  message: null,
});

function reducer(prevState, action) {
  switch (action.type) {
    case 'GET_PAYMENT':
      return {
        ...prevState,
        isFetchingPayment: true,
        payment: null,
      };
    case 'GET_PAYMENT_SUCCESSFUL':
      return {
        ...prevState,
        isFetchingPayment: false,
        payment: action.payment,
      };
    case 'GET_DISPUTE_SUCCESSFUL':
      return {
        ...prevState,
        isFetchingPayment: false,
        isPaymentDisputed: true,
        dispute: action.dispute,
      };
    case 'GET_PAYMENT_FAILED':
      return {
        ...prevState,
        isFetchingPayment: false,
        payment: null,
      };
    case 'TOGGLE_EMAIL_MODAL':
      return {
        ...prevState,
        isEmailModalVisible: !prevState.isEmailModalVisible,
      };
    case 'TOGGLE_CANCEL_LINK_MODAL':
      return {
        ...prevState,
        isCancelLinkVisible: !prevState.isCancelLinkVisible,
      };
    case 'SEND_PAYMENT_LINK':
      return {
        ...prevState,
        isSendingPaymentLink: true,
        status: null,
        message: null,
      };
    case 'SEND_PAYMENT_LINK_SUCCESS':
      return {
        ...prevState,
        isEmailModalVisible: false,
        status: 'success',
        message: action.message,
      };
    case 'SEND_PAYMENT_LINK_FAILED':
      return {
        ...prevState,
        isEmailModalVisible: false,
        status: 'error',
        message: action.message,
      };
    case 'CLEAR_MESSAGE':
      return {
        ...prevState,
        status: null,
        message: null,
      };
    default:
      return prevState;
  }
}

function getFilteredURLPath(path, searchOption) {
  const { page, size, tab } = searchOption;
  let url = `${path}?page=${page || 1}`;
  if (size) { url += `&size=${size}`; }
  if (tab) { url += `&tab=${tab}`; }
  return url;
}

const Payment = (props) => {
  const {
    location, match, merchants,
    user, history, form,
  } = props;

  const { showToast } = useContext(SessionContext);
  const { getFieldDecorator } = form;
  const { externalTransactionId, invoiceId } = match.params;
  const queryFilters = qs.parse(location.search, { ignoreQueryPrefix: true });
  const [state, dispatch] = useReducer(reducer, getInitialState(queryFilters));
  const merchant = merchants.find(m => m.code === match.params.merchantCode);

  function sendEmail(e) {
    e.preventDefault();

    form.validateFields(async (err, { email }) => {
      if (err) { return; }
      try {
        dispatch({ type: 'SEND_PAYMENT_LINK' });
        const response = await sendPaymentLinkEmailService(merchant.id, invoiceId, email);
        const { status } = response;
        if (status !== 200) {
          dispatch({
            type: 'SEND_PAYMENT_LINK_FAILED',
            message: 'We are not able to send the payment link to the email address. Please try again later.',
          });
          window.scrollTo(0, 0);
          return;
        }

        dispatch({
          type: 'SEND_PAYMENT_LINK_SUCCESS',
          message: 'Payment link email has been sent.',
        });
        window.scrollTo(0, 0);
      } catch (error) {
        const message = getAPIErrorMessage(error,
          'We are not able to send the payment link to the email address. Please try again later.');
        dispatch({
          type: 'SEND_PAYMENT_LINK_FAILED',
          message,
        });
        window.scrollTo(0, 0);
      }
    });
  }

  async function cancelPaymentLink() {
    const { payment } = state;
    try {
      const { data: cancelLinkResult } = await cancelPaymentLinkService(
        merchant.id,
        payment.invoiceId,
      );
      dispatch({ type: 'TOGGLE_CANCEL_LINK_MODAL' });
      showToast({ type: 'success', message: cancelLinkResult.message });
      setTimeout(() => window.location.reload(), 500);
    } catch (error) {
      const message = getAPIErrorMessage(error,
        'Unable to cancel the payment link transaction');
      dispatch({ type: 'TOGGLE_CANCEL_LINK_MODAL' });
      showToast({ type: 'error', message });
    }
  }

  useEffect(() => {
    let ableToSet = true;

    (async () => {
      if (!merchant) { return; }

      if (ableToSet) {
        dispatch({ type: 'GET_PAYMENT' });
      }
      try {
        const { data: payment } = await getPaymentService(merchant.id, externalTransactionId, invoiceId);
        if (ableToSet) {
          dispatch({ type: 'GET_PAYMENT_SUCCESSFUL', payment });
        }

        if (payment.paymentStatus === 'DISPUTED') {
          const { data: dispute } = await getDisputeByInvoiceId(payment.invoiceId);

          if (ableToSet) {
            dispatch({ type: 'GET_DISPUTE_SUCCESSFUL', dispute });
          }
        }
      } catch (error) {
        const message = getAPIErrorMessage(error,
          'We are not able to get the payment data from the server.');
        if (ableToSet) {
          dispatch({ type: 'GET_PAYMENT_FAILED', message });
        }
      }
    })();

    return () => { ableToSet = false; };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (!merchant) {
    return (
      <SingleForm
        title="Merchant does not exist"
        subtitle="The page you requested could not be found. Please go back to Dashboard or contact us at support@aqwire.io."
        footer={{ show: true, to: '/', text: 'Back to Dashboard' }}
      />
    );
  }

  if (state.isFetchingPayment) {
    return (
      <div>
        <div className="spinner">
          <span className="spinner-text">Loading...</span>
        </div>
      </div>
    );
  }

  if (!state.payment) {
    return (
      <div>
        <div className="spinner">
          <FolderIcon />
          <h1>Payment Not Found</h1>
          <p>
            You can contact us at&nbsp;
            <a href="mailto:support@aqwire.io">support@aqwire.io</a>
            &nbsp;if you have concerns or issues.
          </p>
        </div>
      </div>
    );
  }

  const { payment } = state;
  const showDisputeWarnings = state.isPaymentDisputed && state.dispute && state.dispute.status === 'PENDING';
  const actionMenu = (
    <Menu>
      <Menu.Item
        key="copy-link"
        onClick={() => {
          history.push(`/merchants/${merchant.code}/payments/payment-links?externalTransactionId=${externalTransactionId}&invoiceId=${invoiceId}`);
        }}
        disabled={state.isExporting}
      >
        <Icon type="link" />
        Copy as payment link
      </Menu.Item>
      {payment.paymentStatus === 'PENDING' && (
        <Menu.Item
          key="cancel-link"
          onClick={() => dispatch({ type: 'TOGGLE_CANCEL_LINK_MODAL' })}
        >
          <Icon type="close-circle" />
          Cancel payment link
        </Menu.Item>
      )}
    </Menu>
  );

  return (
    <div>
      <Fragment>
        <Row className="breadcrumb-row">
          <Col className="breadcrumb-header">
            <Breadcrumb className="breadcrumb-parent">
              <Breadcrumb.Item className="item-container">
                <div className="breadcrumb-item breadcrumb-icon">
                  <PaymentIcon height="28" width="30" />
                </div>
                <div className="breadcrumb-item icon-label">
                  Payments
                </div>
              </Breadcrumb.Item>
            </Breadcrumb>
            {merchant.canManagePaymentLinks && (
              <div className="action-container">
                <Dropdown overlay={actionMenu} trigger={['click']}>
                  <Button
                    type="primary"
                    className="button button-standard button-standard-outline button-small"
                  >
                    Actions
                    <Icon type="down" />
                  </Button>
                </Dropdown>
              </div>
            )}
          </Col>
        </Row>
        {showDisputeWarnings && (
          <div>
            <Row>
              <Col span={24}>
                <Alert
                  message={(
                    <Fragment>
                      <p>
                        {`This payment has been marked as disputed with reference number: ${state.dispute.referenceId}`}
                      </p>
                      <p style={{ marginBottom: '0' }}>
                        You may contact our support team at
                        <Link href="mailto:support@aqwire.io" target="_blank"> support@aqwire.io </Link>
                        regarding the status of this payment.
                      </p>
                    </Fragment>
                  )}
                  type="warning"
                  showIcon
                />
              </Col>
            </Row>
          </div>
        )}
        {state.status && state.message && (
          <div style={{ marginBottom: '8px' }}>
            <Message
              status={state.status}
              message={state.message}
              onClose={() => dispatch({ type: 'CLEAR_MESSAGE' })}
            />
          </div>
        )}
        <Tabs defaultActiveKey={state.defaultActiveTab}>
          <Tabs.TabPane tab="Information" key="info">
            <div style={{ margin: '12px 4px 32px' }}>
              <section className="panel panel-standard">
                <div className="panel-header">
                  <Row type="flex" align="top" justify="space-between">
                    <Col className="panel-item">
                      <h3>QW ID</h3>
                      <div>{payment.paymentReferenceId ?? '-/-'}</div>
                    </Col>
                    <Col className="panel-item">
                      <h3>{payment.customerName}</h3>
                      <div>{payment.customerEmail}</div>
                      {payment.customerPhone && <div>{` (${payment.customerPhone})`}</div>}
                    </Col>
                    <Col className="panel-item">
                      <h3>{payment.transactionSource !== 'payment-link' ? 'Date' : 'Created At'}</h3>
                      <div>
                        {((p) => {
                          const datetime = p.paidAt || p.invoiceCreatedAt;
                          return moment(datetime).format('MMM. DD, YYYY HH:mm');
                        })(payment)}
                      </div>
                    </Col>
                    <Col className="panel-item panel-item-end">
                      <span
                        className="panel-badge"
                        style={{
                          fontWeight: 700,
                          color: '#ffffff',
                          backgroundColor: getStatusColor(payment.paymentStatus),
                        }}
                      >
                        {payment.paymentStatus}
                      </span>
                    </Col>
                  </Row>
                </div>
                <div className="panel-body">
                  <Row>
                    <Col lg={12}>
                      <div className="panel-item">
                        <h3>Transaction ID</h3>
                        <div>
                          <Link
                            to={`/merchants/${payment.merchantCode}/transactions/${payment.externalTransactionId}`}
                            title="View transaction"
                          >
                            {payment.externalTransactionId}
                          </Link>
                        </div>
                      </div>
                      {payment.transactionReferenceId && payment.invoiceReferenceId && (
                        <div className="panel-item">
                          <h3>Enrollment QW ID</h3>
                          <div>
                            <Link to={`/merchants/${payment.merchantCode}/enrollments/${payment.transactionReferenceId}`} title="View enrollment">
                              {payment.transactionReferenceId}
                            </Link>
                          </div>
                        </div>
                      )}
                      <div className="panel-item">
                        <h3>{payment.invoiceReferenceId ? 'Recurring Payment' : 'One-Time Payment'}</h3>
                        <div>
                          {payment.paymentTypeName}
                          {payment.paymentMode ? ` (${payment.paymentMode})` : ''}
                        </div>
                      </div>
                      <div className="panel-item">
                        <h3>Source</h3>
                        <div>
                          {((name) => {
                            switch (name) {
                              case 'api': return 'AQWIRE Access';
                              case 'merchant-portal': return 'Portals 2.0';
                              case 'portal3': return 'Portals 3.0';
                              case 'ayalaland-ma': return 'Ayala Land Enrollment';
                              case 'cxd': return 'Customer Dashboard';
                              case 'payment-link': return 'Payment Link';
                              case 'dashboard-partner': return 'Partner Reference API';
                              default: return 'N/A';
                            }
                          })(payment.transactionSource)}
                        </div>
                      </div>
                      <div className="panel-item">
                        <h3>{payment.merchantName}</h3>
                        <div style={{ marginBottom: '8px' }}>
                          {payment.projectId && merchant.canManageProjects ? (
                            <Link to={`/merchants/${payment.merchantCode}/projects/${payment.projectId}`}>
                              <div>{payment.projectName}</div>
                            </Link>
                          ) : <div>{payment.projectName}</div>}
                          {payment.projectCategory && <div>{payment.projectCategory}</div>}
                        </div>
                        {payment.transactionSource !== 'payment-link' && payment.customFields && (
                          <div className="panel-item-cellar">
                            {Object.keys(payment.customFields)
                              .map(key => (
                                <div key={key}>
                                  {`${payment.customFields[key].label}: ${payment.customFields[key].value ? parseCustomValue(payment.customFields[key].value) : ''}`}
                                </div>
                              ))}
                          </div>
                        )}
                      </div>
                      {payment.transactionSource === 'payment-link' && payment.paymentStatus === 'PENDING' && (
                        <>
                          <div className="panel-item">
                            <h3>Payment Link</h3>
                            <div className="payment-link">
                              <a href={payment ? payment.paymentLink : ''} target="_blank" rel="noopener noreferrer">
                                <div className="truncate">{payment.paymentLink}</div>
                              </a>
                              <Button
                                className="copy-button"
                                type="link"
                                onClick={() => {
                                  navigator.clipboard.writeText(payment.paymentLink);
                                }}
                              >
                                <CopyIcon />
                              </Button>
                            </div>
                          </div>
                          <div className="panel-item">
                            <h3>Expires At</h3>
                            <div>
                              {((p) => {
                                const datetime = p.expiresAt;
                                return moment(datetime).format('MMM. DD, YYYY HH:mm');
                              })(payment)}
                            </div>
                          </div>
                        </>
                      )}
                      <div className="panel-item">
                        <h3>Notes</h3>
                        <div>{payment.clientNotes || 'There are no notes.'}</div>
                      </div>
                    </Col>
                    <Col lg={12}>
                      <PaymentMethodDetails payment={payment} />
                      <div className="panel-item-group">
                        <h3>Breakdown</h3>
                        <BreakdownItem label="Amount Due" bill={payment.billBase} />
                        {payment.billConverted && payment.billConverted.length > 0 && payment.billConverted[1] > 0 && (
                          <Fragment>
                            {payment.qwxRate && payment.qwxRate.length > 0 && (
                              <div className="panel-breakdown-item">
                                <div>Exchange rate</div>
                                <div>
                                  <span>{`1 ${payment.qwxRate[0]} - ${payment.qwxRate[1]} ${formatNumber(payment.qwxRate[2])}`}</span>
                                </div>
                              </div>
                            )}
                            <BreakdownItem label="Converted Amount" bill={payment.billConverted} />
                          </Fragment>
                        )}
                        {payment.refundReason && (
                          <BreakdownItem label="Refunded Amount" bill={payment.refundTotal} />
                        )}
                        <BreakdownItem label="Convenience Fee" bill={payment.billFee} />
                        <BreakdownItem label="Total Amount" bill={payment.billTotal} />
                        <BreakdownItem label="Waived Fee" hideOnEmpty bill={payment.waivedFee} />
                        <BreakdownItem
                          label="Net Amount"
                          tooltipDescription="Net amount to be deposited on your bank account"
                          bill={payment.net}
                          highlight
                        />
                      </div>
                    </Col>
                  </Row>
                  {payment.transactionSource === 'payment-link' && payment.paymentStatus === 'PENDING' && (
                    <div
                      style={{ textAlign: 'right' }}
                    >
                      <Button
                        className="button button-standard button-standard-primary"
                        onClick={() => dispatch({ type: 'TOGGLE_EMAIL_MODAL' })}
                      >
                        Send Payment Link
                      </Button>
                    </div>
                  )}
                </div>
              </section>
            </div>
          </Tabs.TabPane>
          {['PAID', 'SETTLED'].includes(state.payment.paymentStatus) && (
            <Tabs.TabPane tab="Settlement" key="settlement">
              <div style={{ margin: '12px 4px 32px' }}>
                <PaymentSettlementDetails
                  merchant={merchant}
                  payment={payment}
                  user={user}
                />
              </div>
            </Tabs.TabPane>
          )}
          <Tabs.TabPane tab="Logs" key="logs">
            <div style={{ margin: '12px 4px 32px' }}>
              <PaymentLogs
                merchant={merchant}
                payment={payment}
                user={user}
                onChangeSearchOption={(searchOption) => {
                  const url = getFilteredURLPath(location.pathname, searchOption);
                  history.push(url, {});
                }}
              />
            </div>
          </Tabs.TabPane>
        </Tabs>
      </Fragment>

      <Modal
        className="dialog-modal"
        centered
        title="Cancel payment link"
        focusTriggerAfterClose={false}
        visible={state.isCancelLinkVisible}
        onCancel={() => dispatch({ type: 'TOGGLE_CANCEL_LINK_MODAL' })}
        footer={[
          <Button
            key="back"
            onClick={() => dispatch({ type: 'TOGGLE_CANCEL_LINK_MODAL' })}
          >
            No
          </Button>,
          <Button
            key="submit"
            type="danger"
            onClick={cancelPaymentLink}
            loading={state.emailStatus === 'sending'}
          >
            Confirm Cancel
          </Button>,
        ]}
      >
        <div className="cancel-icon-text">
          <Icon type="info-circle" className="cancel-icon" />
          <p className="subtitle-text">Are you sure you want to cancel this payment link?</p>
        </div>
      </Modal>
      <Modal
        className="dialog-modal"
        centered
        title="Send payment link"
        focusTriggerAfterClose={false}
        visible={state.isEmailModalVisible}
        onCancel={() => dispatch({ type: 'TOGGLE_EMAIL_MODAL' })}
        footer={[
          <Button
            key="back"
            onClick={() => dispatch({ type: 'TOGGLE_EMAIL_MODAL' })}
            loading={state.isSendingPaymentLink}
            disabled={state.isSendingPaymentLink}
          >
            Cancel
          </Button>,
          <Button
            form="emailForm"
            key="submit"
            type="primary"
            htmlType="submit"
            loading={state.isSendingPaymentLink}
            disabled={state.isSendingPaymentLink}
          >
            Send Email
          </Button>,
        ]}
      >
        <div className="inner-body">
          <Form id="emailForm" onSubmit={sendEmail}>
            <Form.Item label="Email address">
              {getFieldDecorator('email', {
                rules: [{ required: true, type: 'email', message: 'Please enter a valid email address' }],
                initialValue: state.payment.customerEmail,
              })(<Input />)}
            </Form.Item>
          </Form>
        </div>
      </Modal>
    </div>
  );
};

Payment.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      externalTransactionId: PropTypes.string.isRequired,
      invoiceId: PropTypes.string.isRequired,
      merchantCode: PropTypes.string.isRequired,
    }),
  }).isRequired,
  location: PropTypes.shape({
    filter: PropTypes.shape({}),
    search: PropTypes.string.isRequired,
    pathname: PropTypes.string.isRequired,
  }),
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }).isRequired,
  merchants: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number.isRequired,
    code: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
  })).isRequired,
  user: PropTypes.shape({
    id: PropTypes.number.isRequired,
  }).isRequired,
  form: PropTypes.shape({
    getFieldDecorator: PropTypes.func.isRequired,
    validateFields: PropTypes.func.isRequired,
  }).isRequired,
};

Payment.defaultProps = {
  location: null,
};

export default Form.create()(Payment);
