import React from 'react';
import { Modal } from 'antd';
import PropTypes from 'prop-types';
import { jsonRenderer, getWebhookTaskStatus } from '../../helpers/utils';
import '../../styles/logmodal.scss';

function ViewWebhookTaskLogModal(props) {
  const { isVisible, taskLog, isFetchingLog, onCancel } = props;
  let content = null;

  if (isFetchingLog || taskLog.taskResponseCode === null) {
    content = (
      <div className="request-response-content">
        <div>
          <h3>Processing...</h3>
        </div>
      </div>
    );
  } else {
    const requestNode = jsonRenderer(taskLog.taskRequest, 'request');
    const responseNode = jsonRenderer(taskLog.taskResponse, 'response');
    const maxLen = Math.max(requestNode.length, responseNode.length);

    // eslint-disable-next-line no-plusplus
    for (let i = requestNode.length === maxLen ? responseNode.length : requestNode.length; i < maxLen; i++) {
      (requestNode.length === maxLen ? responseNode : requestNode).push(
        <span>
          <span className="is-standard">{'\n'}</span>
        </span>,
      );
    }

    content = (
      <div className="request-response-content">
        <div>
          <h3>Request</h3>
          <pre>
            {requestNode}
          </pre>
        </div>
        <div>
          <h3>Response</h3>
          <pre>
            {responseNode}
          </pre>
        </div>
      </div>
    );
  }

  return (
    <Modal
      className="dialog-modal"
      visible={isVisible}
      title="Webhook Log"
      forceRender={false}
      onCancel={onCancel}
      footer={false}
      width="min-content"
    >
      {getWebhookTaskStatus(taskLog, true)}
      {taskLog.source.slice(-6) === 'sample' ? <span className="webhook-task-status -sample">SAMPLE EVENT</span> : null}
      {content}
    </Modal>
  );
}

ViewWebhookTaskLogModal.propTypes = {
  isVisible: PropTypes.bool.isRequired,
  onCancel: PropTypes.func.isRequired,
  isFetchingLog: PropTypes.bool.isRequired,
  taskLog: PropTypes.shape({
    taskId: PropTypes.number.isRequired,
    source: PropTypes.string.isRequired,
    webhookEvent: PropTypes.string.isRequired,
    retries: PropTypes.number,
    retryTimestamp: PropTypes.string,
    isComplete: PropTypes.bool.isRequired,
    taskRequest: PropTypes.any.isRequired,
    taskResponse: PropTypes.any.isRequired,
    taskResponseCode: PropTypes.number,
    createdAt: PropTypes.string.isRequired,
  }).isRequired,
};

export default ViewWebhookTaskLogModal;
