import moment from 'moment';
import api from '../helpers/apiClient';

export function getPaymentLogsService(transactionId, invoiceId, searchOption) {
  const { page, size } = searchOption;
  return api.get(`/logs/${transactionId}/invoices/${invoiceId}`, { params: { page, size } });
}

export function submitPaymentLogService(transactionId, invoiceId, { content }) {
  return api.post(`/logs/${transactionId}/invoices/${invoiceId}`, { content });
}

export function removePaymentLogService(transactionId, invoiceId, logId) {
  return api.delete(`/logs/${transactionId}/invoices/${invoiceId}/logs/${logId}`);
}

export function getEnrollmentLogsService(transactionId, searchOption) {
  const { page, size } = searchOption;
  return api.get(`/logs/${transactionId}`, { params: { page, size } });
}

export function submitEnrollmentLogService(transactionId, { content }) {
  return api.post(`/logs/${transactionId}`, { content });
}

export function removeEnrollmentLogService(transactionId, logId) {
  return api.delete(`/logs/${transactionId}/logs/${logId}`);
}

export function getWebhookLogs(merchantId, endpointId) {
  return api.get(`/logs/${merchantId}/webhooks/${endpointId}?size=20&endday=${moment().subtract(7, 'days').format('YYYY-MM-DD')}`);
}

export function findWebhookTaskLog(merchantId, endpointId, taskId) {
  return api.get(`/logs/${merchantId}/webhooks/${endpointId}/${taskId}`);
}

export function getApiLogs(merchantId) {
  return api.get(`/logs/${merchantId}/api?size=20&endday=${moment().subtract(7, 'days').format('YYYY-MM-DD')}`);
}

export function findApiLog(merchantId, apiLogId) {
  return api.get(`/logs/${merchantId}/api/${apiLogId}`);
}
