import React, { useReducer, useContext } from 'react';
import PropTypes from 'prop-types';
import {
  Form, Input, Button, Select, Modal, Row, Col, Icon,
} from 'antd';

import Message from '../Message';
import { createMerchantWebhookEndpointService } from '../../services/merchants';
import SessionContext from '../../contexts/SessionContext';
import '../../styles/modal.scss';

const initialState = {
  isCreatingEndpoint: false,
  status: null,
  message: null,
};

function reducer(prevState, action) {
  switch (action.type) {
    case 'ADD_WEBHOOK':
      return {
        ...prevState,
        isCreatingEndpoint: true,
      };
    case 'ADD_WEBHOOK_COMPLETE':
      return {
        ...prevState,
        isCreatingEndpoint: false,
      };
    case 'ADD_WEBHOOK_ERROR':
      return {
        ...prevState,
        isCreatingEndpoint: false,
        status: action.status,
        message: action.message,
      };
    case 'CLEAR_MESSAGE':
      return {
        ...prevState,
        status: null,
        message: null,
      };
    default:
      return prevState;
  }
}

const AddWebhookEndpointModal = (props) => {
  const { form, toggleAction, onComplete, isVisible } = props;
  const { activeMerchant } = useContext(SessionContext);
  const [state, dispatch] = useReducer(reducer, initialState);
  const { Option } = Select;
  const { TextArea } = Input;
  const subscribedEvents = [
    'payment.paid',
    'payment.settled',
    'enrollment.success',
  ];

  function onSubmit(e) {
    e.preventDefault();
    form.validateFields(async (err, values) => {
      dispatch({ type: 'ADD_WEBHOOK' });
      if (err) {
        dispatch({
          type: 'ADD_WEBHOOK_ERROR',
          status: 'error',
          message: 'Please fill in the required fields with proper values',
        });
        return;
      }
      try {
        const response = await createMerchantWebhookEndpointService(activeMerchant.id, values);
        const { message } = response.data;

        onComplete({ status: 'success', message });
      } catch (error) {
        const message = error && error.response
          ? error.response.data.message
          : 'Unable to add webhook endpoint as of the moment';

        dispatch({
          type: 'ADD_WEBHOOK_ERROR',
          status: 'error',
          message,
        });
        return;
      }

      dispatch({ type: 'ADD_WEBHOOK_COMPLETE' });
      form.resetFields();
      toggleAction();
    });
  }

  return (
    <div>
      <Button
        className="button button-standard button-rounded button-small button-standard-outline"
        onClick={() => toggleAction()}
      >
        <Icon type="plus" />
        <span>Add Endpoint</span>
      </Button>
      <Modal
        title="Add Endpoint"
        className="dialog-modal"
        visible={isVisible}
        onCancel={() => toggleAction()}
        footer={[
          <Button
            key="submit"
            type="primary"
            htmlType="submit"
            loading={state.isCreatingEndpoint}
            onClick={onSubmit}
          >
            Add Endpoint
          </Button>,
          <Button
            key="cancel"
            loading={state.isCreatingEndpoint}
            onClick={() => toggleAction()}
          >
            Cancel
          </Button>,
        ]}
      >
        <Row>
          <Col>
            <p>Webhook endpoints receive live events from AQWIRE via HTTP POST request.</p>
          </Col>
        </Row>
        <Row>
          <Col>
            {state.status && state.message && (
              <Message
                status={state.status}
                message={state.message}
                onClose={() => dispatch({ type: 'CLEAR_MESSAGE' })}
              />
            )}
          </Col>
        </Row>
        <Form onSubmit={onSubmit}>
          <Form.Item label="URL">
            {form.getFieldDecorator('url', {
              rules: [
                { required: true, message: 'Webhook URL is required' },
                { type: 'url', message: 'Webhook URL must be in HTTPS' },
                { max: 1000, message: 'Webhook URL cannot exceed 1000 characters' },
              ],
            })(<Input />)}
          </Form.Item>
          <Form.Item label="Subscribed events">
            {form.getFieldDecorator('subscribedEvents', {
              rules: [
                { required: true, message: 'At least 1 event is required' },
              ],
            })(
              <Select
                mode="multiple"
                showArrow
                suffixIcon={<Icon type="caret-down" />}
              >
                {subscribedEvents.map(e => (
                  <Option key={e} value={e}>
                    <code>{e}</code>
                  </Option>
                ))}
              </Select>,
            )}
          </Form.Item>
          <Form.Item label="Description">
            {form.getFieldDecorator('description', {
              rules: [
                { required: false },
                { max: 500, message: 'Description cannot exceed 500 characters' },
              ],
            })(
              <TextArea autoSize={{ maxRows: 8, minRows: 3 }} />,
            )}
          </Form.Item>
        </Form>
      </Modal>
    </div>
  );
};

AddWebhookEndpointModal.propTypes = {
  form: PropTypes.shape({
    getFieldDecorator: PropTypes.func.isRequired,
    validateFields: PropTypes.func.isRequired,
    resetFields: PropTypes.func.isRequired,
  }).isRequired,
  toggleAction: PropTypes.func.isRequired,
  onComplete: PropTypes.func.isRequired,
  isVisible: PropTypes.bool.isRequired,
};

export default Form.create()(AddWebhookEndpointModal);
