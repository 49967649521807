import React, { Fragment, useReducer, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Form, Modal, Select, Button, DatePicker, Row, Col, Alert } from 'antd';
import { getMerchantsService, getMerchantById } from '../../services/merchants';
import '../../styles/filtermodal.scss';

const DATE_FORMAT = 'YYYY-MM-DD';

/**
 * @param {{
 *  onApply: function,
 *  searchOption: {
 *    merchant: object,
 *    settledAt: any
 *  },
 *  defaultOption: {
 *  }
 * }} props
 */
function SettlementsFilterModal(props) {
  const { onApply, searchOption, merchant } = props;
  const [state, dispatch] = useReducer((prevState, action) => {
    switch (action.type) {
      case 'OPEN_MODAL':
        return {
          ...prevState,
          isVisible: true,
        };
      case 'CLOSE_MODAL':
        return {
          ...prevState,
          isVisible: false,
        };
      case 'INITIALIZE_MODAL':
        return {
          ...prevState,
          merchants: action.merchants,
          merchant: (() => {
            if (!prevState.merchant) { return null; }
            return action.merchants.find(m => m.id === Number(prevState.merchant.id));
          })(),
        };
      case 'SET_MERCHANT':
        return {
          ...prevState,
          merchant: action.merchant,
        };
      case 'SET_SETTLED_AT':
        return {
          ...prevState,
          settledAt: action.settledAt,
        };
      case 'APPLY_FILTERS':
        return {
          ...prevState,
          isVisible: false,
        };
      case 'RESET_FILTERS':
        return {
          ...prevState,
          merchant: action.merchant,
          settledAt: null,
        };
      case 'SET_MESSAGE':
        return {
          ...prevState,
          status: action.status,
          message: action.message,
        };
      case 'CLEAR_MESSAGE':
        return {
          ...prevState,
          status: null,
          message: null,
        };
      default:
        return prevState;
    }
  }, {
    isVisible: false,
    status: null,
    message: null,
    merchant: searchOption.merchant,
    settledAt: searchOption.settledAt,
    merchants: [],
  });

  useEffect(() => {
    let ableToSet = true;

    (async () => {
      try {
        if (state.merchant) {
          const { data } = await getMerchantById(state.merchant.id);
          if (ableToSet) {
            dispatch({ type: 'SET_MERCHANT', merchant: data });
            dispatch({ type: 'INITIALIZE_MODAL', merchants: [data] });
          }
        }
      } catch (error) {
        if (ableToSet) {
          dispatch({ type: 'INITIALIZE_MODAL', merchants: [] });
        }
      }
    })();

    return () => { ableToSet = false; };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function submitFilters() {
    const opts = {
      merchant: state.merchant,
      settledAt: state.settledAt,
    };
    onApply(opts);
    dispatch({ type: 'APPLY_FILTERS' });
  }

  function onSelectFilter(inputValue, option) {
    const { props: { children } } = option;
    return children.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1;
  }

  function onSelectMerchant(merchantId) {
    if (!merchantId) {
      dispatch({ type: 'SET_MERCHANT', merchant: null });
    } else {
      const foundMerchant = state.merchants.find(ps => ps.id === merchantId);
      dispatch({ type: 'SET_MERCHANT', merchant: foundMerchant });
    }
  }

  let timeout;
  let currentValue;

  async function onSearchMerchant(merchantCode) {
    if (!merchantCode) {
      dispatch({ type: 'SET_MERCHANT', merchant: null });
      return;
    }

    if (timeout) {
      clearTimeout(timeout);
      timeout = null;
    }

    currentValue = merchantCode;
    async function searchMerchant() {
      if (timeout) {
        clearTimeout(timeout);
        timeout = null;
      }
      try {
        if (merchant) {
          dispatch({
            type: 'INITIALIZE_MODAL',
            merchants: [merchant],
          });
          return;
        }

        let searchOptions = {
          size: 20,
          page: 1,
        };

        if (merchantCode) {
          searchOptions = {
            query: merchantCode,
            size: 20,
            page: 1,
          };
        }

        const { data } = await getMerchantsService(searchOptions);
        if (currentValue === merchantCode) {
          dispatch({ type: 'INITIALIZE_MODAL', merchants: data.merchants });
        }
      } catch (error) {
        dispatch({ type: 'INITIALIZE_MODAL', merchants: [] });
      }
    }

    // Set timeout to minimize flooding the server with API calls when
    // searching for merchants
    timeout = setTimeout(searchMerchant, 400);
  }

  const footerActions = (
    <Fragment>
      <Button
        className="button button-standard button-standard-outline button-small"
        key="back"
        onClick={() => {
          dispatch({ type: 'CLOSE_MODAL' });
        }}
      >
        Cancel
      </Button>
      <Button
        className="button button-standard button-primary button-small"
        key="submit"
        type="primary"
        onClick={submitFilters}
      >
        Apply
      </Button>
    </Fragment>
  );

  return (
    <Fragment>
      <Button
        icon="bars"
        className="button button-standard button-standard-outline button-small"
        onClick={() => dispatch({ type: 'OPEN_MODAL' })}
      >
        Filter
      </Button>
      <Modal
        centered
        className="filter-modal transaction-filter"
        title="Filter Settlements"
        focusTriggerAfterClose={false}
        visible={state.isVisible}
        onOk={submitFilters}
        onCancel={() => {
          dispatch({ type: 'CLOSE_MODAL' });
        }}
        footer={footerActions}
      >
        <Form onSubmit={() => {}}>
          {state && state.status && state.message && (
            <div style={{ marginBottom: '12px' }}>
              <Row>
                <Col span={24}>
                  <Alert
                    message={<p style={{ marginBottom: 0 }}>{state.message}</p>}
                    type={state.status}
                    showIcon
                    closable
                    onClose={() => dispatch({ type: 'CLEAR_MESSAGE' })}
                  />
                </Col>
              </Row>
            </div>
          )}
          {!merchant && (
            <Form.Item>
              <label className="label" htmlFor="merchant">Merchant</label>
              <Select
                id="merchant"
                class="merchant-select"
                showSearch
                defaultValue={state.merchant ? state.merchant.id : undefined}
                placeholder="Merchant"
                style={{ width: '100%' }}
                defaultActiveFirstOption={false}
                allowClear
                showArrow={false}
                filterOption={onSelectFilter}
                onChange={onSelectMerchant}
                onSearch={onSearchMerchant}
                notFoundContent={null}
              >
                {state.merchants.map(m => (
                  <Select.Option key={m.id} value={m.id}>{m.name}</Select.Option>
                ))}
              </Select>
            </Form.Item>
          )}
          <Form.Item>
            <label className="label" htmlFor="settledAt">Settled Date</label>
            <div>
              <DatePicker
                id="settledAt"
                format={DATE_FORMAT}
                value={state.settledAt && state.settledAt.isValid() ? state.settledAt : null}
                onChange={(date) => {
                  dispatch({ type: 'SET_SETTLED_AT', settledAt: date });
                }}
                style={{ width: '100%' }}
              />
            </div>
          </Form.Item>
        </Form>
      </Modal>
    </Fragment>
  );
}

SettlementsFilterModal.propTypes = {
  onApply: PropTypes.func.isRequired,
  searchOption: PropTypes.shape({
    merchant: PropTypes.object,
    settledAt: PropTypes.object,
    query: PropTypes.string,
    page: PropTypes.number.isRequired,
    size: PropTypes.number.isRequired,
  }).isRequired,
  merchant: PropTypes.shape({
    id: PropTypes.number.isRequired,
    code: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
  }),
};

SettlementsFilterModal.defaultProps = {
  merchant: null,
};

export default SettlementsFilterModal;
