import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import './logo.css';

const defaultSvgLogo = (
  <svg className="logo" viewBox="106 92 1484 305" xmlns="http://www.w3.org/2000/svg">
    <path d="M514,93c82.82,0,150.28,67.46,150.28,150.28S596.81,393.6,514,393.6A150.28,150.28,0,1,1,514,93Zm0,50.09A100.19,100.19,0,1,0,614.17,243.32,100.05,100.05,0,0,0,514,143.14Z" />
    <path d="M967.14,93h52.75L885.47,393.33,836.12,286.08,788.48,393.33,649.8,93h52.32l86.35,181.31,20.84-46.95L748.15,93h52.32l85,181.31Z" />
    <path d="M1326.64,393.9l-79.07-126.8H1219V393.9h-49.92V217.62h47.36L1137.43,93h131s99.83,0,99.83,87c0,52.9-36.69,73.81-65.7,81.92l82.48,131.92Zm-51.76-176.71c13.23-1.28,43.52-7.68,43.52-37.12,0-37.12-49.92-37.12-49.92-37.12h-40.1Z" />
    <polygon points="373.96 393.41 240.35 92.79 106.74 393.41 165.2 393.41 185.57 343.93 278.71 343.93 259.99 294.44 206.28 294.44 240.35 210.74 315.51 393.41 373.96 393.41" />
    <polygon points="628.47 393.41 444.71 193.41 505.31 193.41 688.46 393.41 628.47 393.41" />
    <rect height="300.38" width="49.48" x="1053.72" y="93.04" />
    <path d="M1588.78,143.15H1471.87v51.09h99.31v50.1h-99.31v99.22h116.91v50.1h-167V93h167Z" />
  </svg>
);

function getPosition(props) {
  switch (props.position) {
    case 'left': return { marginLeft: '0', textAlign: 'left' };
    case 'right': return { marginRight: '0', textAlign: 'right' };
    default: return { marginRight: 'auto', marginLeft: 'auto' };
  }
}

function getStyles(props) {
  return {
    ...getPosition(props),
    marginTop: props.top,
    marginBottom: props.bottom,
    width: props.widthPercent,
  };
}

function getLogo(src, props) {
  return src
    ? <div style={getStyles(props)}><img className="logo-brand" src={src} alt="logo" /></div>
    : <div style={getStyles(props)}>{defaultSvgLogo}</div>;
}

const Logo = props => (
  <Fragment>
    {props.isLink ? <Link to="/">{getLogo(props.src, props)}</Link> : getLogo(props.src, props)}
  </Fragment>
);

Logo.propTypes = {
  isLink: PropTypes.bool,
  // eslint-disable-next-line react/no-unused-prop-types
  src: PropTypes.string,
  // eslint-disable-next-line react/no-unused-prop-types
  top: PropTypes.string,
  // eslint-disable-next-line react/no-unused-prop-types
  bottom: PropTypes.string,
  // eslint-disable-next-line react/no-unused-prop-types
  widthPercent: PropTypes.string,
};

Logo.defaultProps = {
  isLink: false,
  src: null,
  top: '0px',
  bottom: '0px',
  widthPercent: '100%',
};

export default Logo;
