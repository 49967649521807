import React, { useContext, useEffect, useReducer } from 'react';
import PropTypes from 'prop-types';
import { withRouter, useRouteMatch } from 'react-router-dom';
import intlTelInput from 'intl-tel-input';
import qs from 'query-string';
import {
  Card, Form, Input, Row, Col, Button, Select,
  Icon, Breadcrumb, Spin,
} from 'antd';
import { PaymentIcon } from '../../components/Icons';
import SingleForm from '../../components/SingleForm';
import Message from '../../components/Message';
import SessionContext from '../../contexts/SessionContext';
import { getPaymentTypesService, getPaymentCurrenciesService } from '../../services/merchants';
import { getPaymentLinkForm, createPaymentLinkService } from '../../services/paymentLinkService';
import { getProjectsService } from '../../services/projects';
import { getPaymentService } from '../../services/payments';
import { getAPIErrorMessage } from '../../helpers/utils';
import getValidationErrors from '../../helpers/forms';
import 'intl-tel-input/build/css/intlTelInput.css';
import 'intl-tel-input/build/js/utils';
import './createpaymentlink.css';

const formItemLayout = { labelCol: { span: 24 }, wrapperCol: { span: 24 } };

const EXPIRE_TIME_OPTIONS = [
  { value: 3, name: '3 Hours' },
  { value: 6, name: '6 Hours' },
  { value: 12, name: '12 Hours' },
  { value: 24, name: '1 Day' },
  { value: 48, name: '2 Days' },
  { value: 72, name: '3 Days' },
  { value: 96, name: '4 Days' },
  { value: 120, name: '5 Days' },
  { value: 144, name: '6 Days' },
  { value: 168, name: '7 Days' },
];

const getInitialState = queryFilters => ({
  isLoading: true,
  isSubmitting: false,
  paymentForm: [],
  externalTransactionId: queryFilters.externalTransactionId || null,
  invoiceId: queryFilters.invoiceId || null,
  projects: [],
  paymentTypes: [],
  paymentCurrencies: [],
  maximumAmount: 0,
  defaultCurrency: 'PHP',
  form: {
    customerName: null,
    customerEmail: null,
    customerPhone: null,
    billBase: {
      currency: 'PHP',
      amount: 0,
    },
    projectId: null,
    paymentType: null,
    expireTime: EXPIRE_TIME_OPTIONS[0].value,
    clientNotes: null,
  },
  fieldErrors: {},
  status: null,
  message: null,
});

/**
 * Set the values individually in the form based on the "key" properly without affecting the fields.
 * @param {{ billBase: { currency, amount }, projectId, paymentType }} prevState
 * @param {string} key
 * @param {any} value
 */
function setFormField(prevState, key, value) {
  // if (!key || !value) { return {}; }
  switch (key) {
    case 'amount':
      return {
        billBase: {
          ...prevState.form.billBase,
          amount: value,
        },
      };
    case 'currency':
      return {
        billBase: {
          ...prevState.form.billBase,
          currency: value,
        },
      };
    default:
      return { [key]: value };
  }
}

function reducer(prevState, action) {
  switch (action.type) {
    case 'GET_PAYMENT_FORM_SUCCESS':
      return {
        ...prevState,
        isLoading: false,
        paymentForm: action.paymentForm,
        projects: action.projects,
        paymentTypes: action.paymentTypes,
        paymentCurrencies: action.paymentCurrencies,
        maximumAmount: action.maximumAmount,
        defaultCurrency: action.defaultCurrency,
        form: action.copyPayment ? {
          ...prevState.form,
          customerName: action.copyPayment.customerName,
          customerEmail: action.copyPayment.customerEmail,
          customerPhone: action.copyPayment.customerPhone,
          billBase: {
            ...prevState.form.billBase,
            currency: action.copyPayment.billBase[0],
            amount: action.copyPayment.billBase[1],
          },
          projectId: action.copyPayment.projectId,
          paymentType: action.copyPayment.paymentType,
        } : {
          ...prevState.form,
          billBase: {
            amount: 0,
            currency: action.defaultCurrency,
          },
        },
      };
    case 'GET_PAYMENT_FORM_FAILED':
      return {
        ...prevState,
        isLoading: false,
        paymentForm: [],
        maximumAmount: 0,
        defaultCurrency: 'PHP',
        status: 'error',
        message: action.message,
      };
    case 'CREATE_PAYMENT_LINK':
      return {
        ...prevState,
        isSubmitting: true,
        message: null,
        status: null,
      };
    case 'CREATE_PAYMENT_LINK_FAILED':
      return {
        ...prevState,
        isSubmitting: false,
        fieldErrors: action.fieldErrors,
        message: action.message,
        status: 'error',
      };
    case 'SET_PROJECT':
      return {
        ...prevState,
        form: {
          ...prevState.form,
          projectId: action.projectId,
        },
        fieldErrors: {
          ...prevState.fieldErrors,
          project: undefined,
        },
      };
    case 'SET_PAYMENT_TYPE':
      return {
        ...prevState,
        form: {
          ...prevState.form,
          paymentType: action.paymentType,
        },
        fieldErrors: {
          ...prevState.fieldErrors,
          paymentType: undefined,
        },
      };
    case 'SET_EXPIRE_TIME':
      return {
        ...prevState,
        form: {
          ...prevState.form,
          expireTime: action.expireTime,
        },
      };
    case 'SET_PAYMENT_AMOUNT':
      return {
        ...prevState,
        form: {
          ...prevState.form,
          billBase: {
            ...prevState.form.billBase,
            amount: action.amount,
          },
        },
        fieldErrors: {
          ...prevState.fieldErrors,
          amount: undefined,
        },
      };
    case 'SET_PAYMENT_CURRENCY':
      return {
        ...prevState,
        form: {
          ...prevState.form,
          billBase: {
            amount: 0,
            currency: action.currency,
          },
        },
        maximumAmount: action.maximumAmount,
        fieldErrors: {
          ...prevState.fieldErrors,
          amount: undefined,
        },
      };
    case 'SET_FIELD_ERROR':
      return {
        ...prevState,
        fieldErrors: {
          ...prevState.fieldErrors,
          [action.key]: action.errors,
        },
        form: {
          ...prevState.form,
          ...setFormField(prevState, action.key, action.value),
        },
      };
    case 'CLEAR_MESSAGE':
      return {
        ...prevState,
        message: null,
        status: null,
      };
    default:
      return prevState;
  }
}

let phoneInput = null;

const CreatePaymentLink = (props) => {
  const { Option } = Select;
  const match = useRouteMatch();
  const { history, location, merchants } = props;
  const queryFilters = qs.parse(location.search, { ignoreQueryPrefix: true });
  const sessionContext = useContext(SessionContext);
  const { activeMerchant, loggedInUser } = sessionContext;
  const merchant = merchants.find(m => m.code === match.params.merchantCode);
  const role = loggedInUser.merchantRoles.find(r => r.merchantCode === merchant.code);

  const [state, dispatch] = useReducer(reducer, getInitialState(queryFilters));
  const phoneInputRef = React.createRef();
  const { handleSubmit, handleChange } = useForm({
    rules: {
      customerName: [
        { required: true, message: 'Please enter the given name.' },
      ],
      customerEmail: [
        { required: true, message: 'This field is required.' },
        { type: 'email', message: 'Please provide a valid email.' },
      ],
      mobileNumber: [
        { type: 'custom', fn: isMobileNumberInvalid, message: 'Mobile number is invalid.' },
      ],
      projectId: [
        { type: 'custom', fn: isProjectInvalid, message: 'Project is required.' },
      ],
      paymentType: [
        { type: 'custom', fn: isPaymentTypeInvalid, message: 'Payment Type is required.' },
      ],
    },
  });

  useEffect(() => {
    if (activeMerchant.canManagePaymentLinks && merchant && phoneInputRef.current) {
      phoneInput = intlTelInput(phoneInputRef.current, {
        preferredCountries: ['us', 'ph'],
        initialCountry: 'ph',
        separateDialCode: true,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    let ableToSet = true;

    (async () => {
      try {
        const configFormResponse = await getPaymentLinkForm(merchant.id);
        const projectsResponse = await getProjectsService(activeMerchant.id, {
          page: 1,
          size: 256,
        });
        const paymentTypeResponse = await getPaymentTypesService(activeMerchant.id, { purpose: 'pay' });
        const paymentCurrenciesResponse = await getPaymentCurrenciesService(activeMerchant.id);

        const projects = Array
          .from(projectsResponse.data.projects)
          .filter(p => p.isActive && p.isEnabled);
        const { paymentTypes } = paymentTypeResponse.data;
        const { paymentCurrencies } = paymentCurrenciesResponse.data;
        const paymentForm = configFormResponse.data || [];
        const { currency, amount } = paymentCurrencies.length > 0 ? paymentCurrencies[0] : {
          currency: 'PHP',
          amount: 0,
        };

        paymentTypes.sort((a, b) => {
          if (a.name > b.name) return 1;
          if (b.name > a.name) return -1;
          return 0;
        });

        let copyPayment = null;
        if (state.externalTransactionId && state.invoiceId) {
          const paymentResponse = await getPaymentService(activeMerchant.id, state.externalTransactionId, state.invoiceId);
          copyPayment = paymentResponse.data;
        }

        if (ableToSet) {
          // Set number if and only if the phone is valid, because there are merchants not requiring
          // phone number
          if (copyPayment && copyPayment.customerPhone) {
            phoneInput.setNumber(copyPayment.customerPhone);
          }

          dispatch({
            type: 'GET_PAYMENT_FORM_SUCCESS',
            paymentForm,
            projects,
            paymentTypes,
            paymentCurrencies,
            copyPayment,
            maximumAmount: amount,
            defaultCurrency: currency,
          });
        }
      } catch (error) {
        const message = getAPIErrorMessage(error,
          'We are not able to load the payment link form. Please try again later.');
        if (ableToSet) {
          dispatch({
            type: 'GET_PAYMENT_FORM_FAILED',
            message,
          });
        }
      }
    })();

    return () => { ableToSet = false; };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);


  // This is currently a workaround to fix the issue surrounding the requirement for
  // project and payment type for merchants based on Portals 3
  const projectNameField = state.paymentForm.find(f => f.key === 'projectName');
  const paymentTypeField = state.paymentForm.find(f => f.key === 'paymentType');

  function useForm(options) {
    const _validate = (value, key) => {
      const newErrors = state.fieldErrors;
      const rules = options?.rules[key];
      if (rules) {
        newErrors[key] = getValidationErrors(rules, value);
        if (!newErrors[key].length) {
          newErrors[key] = null;
        }
      }
      dispatch({
        type: 'SET_FIELD_ERROR',
        key,
        errors: newErrors[key],
        value,
      });
    };

    // eslint-disable-next-line no-shadow
    const handleSubmit = async (e) => {
      e.preventDefault();
      const { iso2, dialCode } = phoneInput.getSelectedCountryData();
      const { form } = state;
      isAmountInvalid(state.form.billBase?.amount);
      const errors = state.fieldErrors;
      const formKeys = Object.keys(form);
      formKeys.forEach(k => _validate(form[k], k));
      const keys = Object.keys(errors);
      if (keys.every(k => !errors[k])) {
        const {
          customerName,
          customerEmail,
          projectId,
          paymentType,
          billBase,
          expireTime,
          clientNotes,
        } = state.form;
        const amount = Number(billBase.amount);
        const selectedPaymentType = paymentType ? paymentType.split('_').slice(1).join('_') : null;
        const data = {
          customerName,
          customerEmail,
          expireTime,
          amount,
          currency: billBase.currency,
          mobileNumberCountryCode: iso2,
          mobileNumberDialCode: dialCode,
          mobileNumber: phoneInput.getNumber(),
          project: projectId,
          paymentType: selectedPaymentType,
          clientNotes: clientNotes || null,
          externalTransactionId: state.externalTransactionId,
        };
        createPaymentLink(data);
      }
    };

    // eslint-disable-next-line no-shadow
    const handleChange = key => (
      e,
    ) => {
      const { value } = e.target;
      _validate(value, key);
    };

    return {
      handleSubmit,
      handleChange,
    };
  }

  function onSelectExpireTime(expireTime) {
    dispatch({ type: 'SET_EXPIRE_TIME', expireTime });
  }

  function onSelectProject(projectId) {
    dispatch({ type: 'SET_PROJECT', projectId });
  }

  function onSelectPaymentType(paymentType) {
    dispatch({ type: 'SET_PAYMENT_TYPE', paymentType });
  }

  function onSelectPaymentCurrency(currency) {
    const item = state.paymentCurrencies.find(pc => pc.currency === currency);
    if (!item) {
      dispatch({
        type: 'SET_FIELD_ERROR',
        key: 'amount',
        errors: ['Currency is not accepted'],
        value: currency,
      });
      return;
    }

    dispatch({
      type: 'SET_PAYMENT_CURRENCY',
      currency,
      maximumAmount: item.amount,
    });
  }

  function onSetAmount(e) {
    const { value } = e.target;
    const amount = Number(e.target.value);
    if (isAmountInvalid(amount)) return;
    dispatch({ type: 'SET_PAYMENT_AMOUNT', amount: value });
  }

  function isMobileNumberInvalid() {
    return !phoneInput.isValidNumber();
  }

  function isProjectInvalid() {
    return projectNameField && !state.form.projectId;
  }

  function isPaymentTypeInvalid() {
    return paymentTypeField && !state.form.paymentType;
  }

  function isAmountInvalid(amount) {
    if (Number.isNaN(amount)) {
      dispatch({
        type: 'SET_FIELD_ERROR',
        key: 'amount',
        errors: ['Amount is not a number'],
        value: amount,
      });
      return true;
    }

    if (amount <= 0) {
      dispatch({
        type: 'SET_FIELD_ERROR',
        key: 'amount',
        errors: ['Amount must be greater than 0'],
        value: amount,
      });
      return true;
    }

    if (amount > state.maximumAmount) {
      dispatch({
        type: 'SET_FIELD_ERROR',
        key: 'amount',
        errors: ['Amount has exceeded the maximum amount'],
        value: amount,
      });
      return true;
    }

    return false;
  }

  async function createPaymentLink(paymentLinkForm) {
    (async () => {
      try {
        dispatch({ type: 'CREATE_PAYMENT_LINK' });
        const response = await createPaymentLinkService(activeMerchant.id, paymentLinkForm);
        const {
          externalTransactionId,
          invoiceId,
        } = response.data;
        history.push(`/merchants/${activeMerchant.code}/payments/${externalTransactionId}/${invoiceId}`);
      } catch (error) {
        if (error && error.response && error.response.data && error.response.data.errors) {
          dispatch({
            type: 'CREATE_PAYMENT_LINK_FAILED',
            message: 'There are form errors in the submitted payment link.',
            fieldErrors: error.response.data.errors,
          });
          return;
        }

        const message = getAPIErrorMessage(error,
          'This payment link was not created. Please try again later');
        dispatch({ type: 'CREATE_PAYMENT_LINK_FAILED', message, fieldErrors: {} });
      }
    })();
  }

  function getFormFieldErrors(errorsList) {
    if (!errorsList || errorsList.length === 0) {
      return {};
    }

    // re-map errors list for it to have keys
    const mappedErrors = errorsList.map((e, idx) => ({ err: e, key: idx }));

    return {
      validateStatus: 'error',
      help: (mappedErrors || []).map(e => <p className="error-msg" key={e.key}>{e.err}</p>),
    };
  }

  if (!merchant) {
    return (
      <SingleForm
        title="Merchant does not exist"
        subtitle="The page you requested could not be found. Please go back to Dashboard or contact us at support@aqwire.io."
        footer={{ show: true, to: '/', text: 'Back to Dashboard' }}
      />
    );
  }

  if (!activeMerchant.canManagePaymentLinks) {
    return (
      <SingleForm
        title="Payment link creation is disabled for this merchant"
        subtitle="Please go back to Dashboard or contact us at support@aqwire.io."
        footer={{ show: true, to: '/', text: 'Back to Dashboard' }}
      />
    );
  }

  if (!role || role.merchantRole[0] >= 30) {
    return (
      <SingleForm
        title="You are not allowed to create payment links"
        subtitle="Please go back to Dashboard or contact us at support@aqwire.io."
        footer={{ show: true, to: '/', text: 'Back to Dashboard' }}
      />
    );
  }

  return (
    <div className="payment-link-card">
      <Row className="breadcrumb-row">
        <Col className="breadcrumb-header">
          <Breadcrumb className="breadcrumb-parent">
            <Breadcrumb.Item className="item-container">
              <div className="breadcrumb-item breadcrumb-icon">
                <PaymentIcon />
              </div>
              <div className="breadcrumb-item icon-label">
                New Payment Link
              </div>
            </Breadcrumb.Item>
          </Breadcrumb>
        </Col>
      </Row>
      {state.status && state.message && (
        <div style={{ marginBottom: '8px' }}>
          <Message
            status={state.status}
            message={state.message}
            onClose={() => dispatch({ type: 'CLEAR_MESSAGE' })}
          />
        </div>
      )}
      <Form id="detailsForm" onSubmit={handleSubmit}>
        <Spin tip="loading..." spinning={state.isLoading || state.isSubmitting}>
          <Row gutter={[32, 32]}>
            <Col span={12} flex="auto">
              <Card className="card-standard" title="Customer">
                <div className="details-body">
                  <Form.Item
                    {...formItemLayout}
                    {...getFormFieldErrors(state.fieldErrors.customerName)}
                    className="name-input"
                    label="Name"
                  >
                    <Input
                      onChange={handleChange('customerName')}
                      defaultValue={state.form.customerName}
                      type="text"
                      required
                      disabled={state.isSubmitting}
                    />
                  </Form.Item>
                  <Form.Item
                    {...formItemLayout}
                    {...getFormFieldErrors(state.fieldErrors.customerEmail)}
                    className="email-input"
                    label="Email Address"
                  >
                    <Input
                      onChange={handleChange('customerEmail')}
                      defaultValue={state.form.customerEmail}
                      type="email"
                      required
                      disabled={state.isSubmitting}
                    />
                  </Form.Item>
                  <Form.Item
                    {...formItemLayout}
                    {...getFormFieldErrors(state.fieldErrors.mobileNumber)}
                    className="intl-tel-input"
                    label="Mobile Number"
                  >
                    <input
                      onChange={handleChange('mobileNumber')}
                      ref={phoneInputRef}
                      required
                      disabled={state.isSubmitting}
                    />
                  </Form.Item>
                </div>
              </Card>
            </Col>
            <Col span={12} flex="auto">
              <Card className="card-standard" title="Information">
                <div className="details-body">
                  <Form.Item
                    {...formItemLayout}
                    {...getFormFieldErrors(state.fieldErrors.amount)}
                    className="amount-input"
                    label="Amount Due"
                  >
                    <Input
                      type="text"
                      required
                      placeholder="0"
                      value={state.form.billBase.amount}
                      disabled={state.isSubmitting}
                      onChange={onSetAmount}
                      addonBefore={(
                        <Select
                          onChange={onSelectPaymentCurrency}
                          style={{ width: 70 }}
                          value={state.form.billBase.currency}
                          disabled={state.isSubmitting}
                        >
                          {state.paymentCurrencies.map(pc => (
                            <Option key={pc.currency} value={pc.currency} label={pc.currency}>
                              {pc.currency}
                            </Option>
                          ))}
                        </Select>
                      )}
                    />
                  </Form.Item>
                  {projectNameField && (
                    <Form.Item
                      {...getFormFieldErrors(state.fieldErrors.projectId)}
                      className="select-field"
                      label="Project Name"
                      colon={false}
                    >
                      <Select
                        onChange={onSelectProject}
                        value={state.form.projectId}
                        suffixIcon={<Icon type="caret-down" />}
                        disabled={state.isSubmitting}
                      >
                        {state.projects.map(proj => (
                          <Option key={proj.id} value={proj.id} label={proj.name}>
                            {proj.name}
                          </Option>
                        ))}
                      </Select>
                    </Form.Item>
                  )}
                  {paymentTypeField && (
                    <Form.Item
                      {...getFormFieldErrors(state.fieldErrors.paymentType)}
                      className="select-field"
                      label="Payment Type"
                      colon={false}
                    >
                      <Select
                        onChange={onSelectPaymentType}
                        value={state.form.paymentType}
                        suffixIcon={<Icon type="caret-down" />}
                        disabled={state.isSubmitting}
                      >
                        {state.paymentTypes.map(pt => (
                          <Option key={pt.id} value={pt.code} label={pt.name}>
                            {pt.name}
                          </Option>
                        ))}
                      </Select>
                    </Form.Item>
                  )}
                  <Form.Item
                    {...getFormFieldErrors(state.fieldErrors.expireTime)}
                    className="select-field"
                    label="Expiration of payment link"
                    colon={false}
                  >
                    <Select
                      defaultValue={state.form.expireTime}
                      onChange={onSelectExpireTime}
                      suffixIcon={<Icon type="caret-down" />}
                      disabled={state.isSubmitting}
                    >
                      {EXPIRE_TIME_OPTIONS.map(et => (
                        <Option key={et.name} value={et.value} label={et.name}>
                          {et.name}
                        </Option>
                      ))}
                    </Select>
                  </Form.Item>
                  <Form.Item
                    {...formItemLayout}
                    className="name-input"
                    label="Notes"
                  >
                    <Input.TextArea
                      onChange={handleChange('clientNotes')}
                      rows={4}
                      disabled={state.isSubmitting}
                    />
                  </Form.Item>
                </div>
              </Card>
            </Col>
          </Row>
        </Spin>
      </Form>
      <Row>
        <Col offset={12} lg={12} md={12} style={{ display: 'flex', justifyContent: 'flex-end' }}>
          <Button
            className="button button-standard button-standard-outline"
            style={{ marginRight: '4px' }}
            key="back"
            onClick={() => history.push(`/merchants/${activeMerchant.code}/payments`)}
            disabled={state.isSubmitting}
            loading={state.isSubmitting}
          >
            Cancel
          </Button>
          <Button
            className="button button-standard button-primary"
            form="detailsForm"
            key="submit"
            htmlType="submit"
            type="primary"
            disabled={state.isSubmitting}
            loading={state.isSubmitting}
          >
            Create Payment
          </Button>
        </Col>
      </Row>
    </div>
  );

  // const foundProjectName = projects.find(r => r.key === value);
  // return value;
};

CreatePaymentLink.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  form: PropTypes.object.isRequired,
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }).isRequired,
  location: PropTypes.shape({
    pathname: PropTypes.string.isRequired,
    search: PropTypes.string.isRequired,
  }).isRequired,
  merchants: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number.isRequired,
    code: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
  })).isRequired,
};

export default withRouter(Form.create()(CreatePaymentLink));
