import React, { Fragment, useReducer, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Button } from 'antd';
import SettlementPaymentsTable from '../SettlementPaymentsTable';

function SettlementSelectPaymentsStep(props) {
  const { merchant, selectedPayments, onChange, onNext } = props;
  const [state, dispatch] = useReducer((prevState, action) => {
    switch (action.type) {
      case 'UPDATE_PAYMENTS':
        return {
          ...prevState,
          payments: action.payments,
        };
      default:
        return prevState;
    }
  }, {
    payments: selectedPayments,
  });

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => onChange(state.payments), [state.payments]);

  return (
    <Fragment>
      <div className="table-container">
        <SettlementPaymentsTable
          merchant={merchant}
          selectedPayments={selectedPayments}
          onSelection={(payments) => {
            dispatch({ type: 'UPDATE_PAYMENTS', payments });
          }}
          filters={{
            showStatusMenu: false,
            showFilterFields: true,
            showSettlementColumn: false,
          }}
        />
      </div>
      <div className="header footer">
        <div className="action-button">
          <Button
            className="button button-standard next-button"
            onClick={() => {
              if (state.payments.length >= 1) { onNext(); }
            }}
            disabled={!state.payments}
          >
            Next
          </Button>
        </div>
      </div>
    </Fragment>
  );
}

SettlementSelectPaymentsStep.propTypes = {
  merchant: PropTypes.shape({
    id: PropTypes.number.isRequired,
    code: PropTypes.string.isRequired,
  }).isRequired,
  selectedPayments: PropTypes.arrayOf(PropTypes.object).isRequired,
  onChange: PropTypes.func,
  onNext: PropTypes.func,
};

SettlementSelectPaymentsStep.defaultProps = {
  onChange: () => {},
  onNext: () => {},
};

export default SettlementSelectPaymentsStep;
