import moment from 'moment';
import React, { Fragment, useContext, useReducer, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { Table, Row, Col, Breadcrumb, Button, Icon } from 'antd';
import { formatNumber, getStatusColor } from '../../helpers/utils';
import { SearchIcon } from '../../components/Icons';
import SessionContext from '../../contexts/SessionContext';
import {
  authenticateMerchantExportTokenService,
  exportTransactionsService,
  searchTransactionsService,
} from '../../services/transactions';

const getInitialState = data => ({
  isExporting: false,
  isSearching: false,
  searchResults: [],
  totalCount: 0,
  status: null,
  message: null,
  searchOption: {
    query: data.query,
    page: 1,
    size: 10,
  },
});

function reducer(prevState, action) {
  switch (action.type) {
    case 'EXPORTING_TRANSACTIONS':
      return {
        ...prevState,
        isExporting: true,
      };
    case 'EXPORTING_TRANSACTIONS_SUCCESSFUL':
      return {
        ...prevState,
        isExporting: false,
      };
    case 'EXPORTING_TRANSACTIONS_FAILED':
      return {
        ...prevState,
        isExporting: false,
      };
    case 'SET_SEARCH_OPTION':
      return {
        ...prevState,
        searchOption: action.searchOption,
      };
    case 'SEARCH':
      return {
        ...prevState,
        isSearching: true,
      };
    case 'SEARCH_SUCCESSFUL':
      return {
        ...prevState,
        isSearching: false,
        searchResults: action.searchResults,
        totalCount: action.totalCount,
      };
    case 'SEARCH_FAILED':
      return {
        ...prevState,
        isSearching: false,
        searchResults: [],
        totalCount: 0,
        status: 'error',
        message: action.message,
      };
    case 'UPDATE_SEARCH_OPTION':
      return {
        ...prevState,
        searchOption: {
          ...prevState.searchOption,
          query: action.searchOption.query,
          page: action.searchOption.page,
          size: action.searchOption.size,
        },
      };
    default:
      return prevState;
  }
}

const TransactionsSearchResult = (props) => {
  const { layout } = props;
  const { searchQuery } = layout;
  const timeZone = moment.tz.guess(true);

  const { isLoading, showToast, merchants, activeMerchant } = useContext(SessionContext);
  const [state, dispatch] = useReducer(reducer, getInitialState({ query: searchQuery }));

  const merchant = merchants.find(m => m.code === activeMerchant.code);
  const { searchOption } = state;

  useEffect(() => {
    let ableToSet = true;

    (async () => {
      try {
        if (isLoading) { return; }
        if (!searchOption.query) { return; }

        if (ableToSet) {
          dispatch({ type: 'SEARCH' });
        }
        const opts = {
          ...searchOption,
        };
        const response = await searchTransactionsService(merchant.id, opts);
        const { transactions, totalCount } = response.data;
        if (ableToSet) {
          dispatch({ type: 'SEARCH_SUCCESSFUL', searchResults: transactions, totalCount });
        }
      } catch (error) {
        const message = error.response && error.response.message
          ? error.response.message
          : 'We are not able to search for the transactions';
        if (ableToSet) {
          dispatch({ type: 'SEARCH_FAILED', message });
        }
      }
    })();

    return () => { ableToSet = false; };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    searchOption.query,
    searchOption.page,
    searchOption.size,
  ]);

  async function exportTransactions() {
    dispatch({ type: 'EXPORTING_TRANSACTIONS' });
    try {
      const { data: { exportToken } } = await authenticateMerchantExportTokenService(merchant.id);
      dispatch({ type: 'EXPORTING_TRANSACTIONS_SUCCESSFUL' });

      exportTransactionsService(searchOption.query, exportToken);
    } catch (err) {
      const message = err && err.response
        ? err.response.data.message
        : 'Unable to export transactions data as of the moment';
      dispatch({ type: 'EXPORTING_TRANSACTIONS_FAILED' });
      showToast({ type: 'error', message });
    }
  }

  const columns = [{
    title: 'Status',
    key: 'status',
    render: row => (
      <div style={{ marginBottom: '8px' }}>
        <span
          style={{
            fontWeight: 700,
            color: '#ffffff',
            backgroundColor: getStatusColor(row.transactionStatus),
            borderRadius: '4px',
            padding: '4px 8px',
          }}
        >
          {row.transactionStatus}
        </span>
      </div>
    ),
  }, {
    title: 'Customer',
    render: row => (
      <Fragment>
        <div className="text-primary" style={{ marginBottom: '4px' }}>{row.customerName || '-----'}</div>
        <div className="text-secondary" style={{ marginBottom: '4px' }}>{row.merchantName}</div>
      </Fragment>
    ),
  }, {
    title: 'Payment Type',
    key: 'paymentType',
    render: row => (
      <Fragment>
        <div className="text-primary" style={{ marginBottom: '4px' }}>
          {row.transactionType[0] + row.transactionType.toLowerCase().slice(1)}
        </div>
        <div className="text-secondary" style={{ marginBottom: '4px' }}>
          {row.projectName || '-----'}
        </div>
      </Fragment>
    ),
  }, {
    title: 'Amount',
    key: 'amount',
    render: row => (
      <Fragment>
        <div className="text-primary" style={{ marginBottom: '4px' }}>
          {row.paymentTypeName}
        </div>
        <div className="text-secondary">
          {`${row.baseAmount[0]} ${formatNumber(row.baseAmount[1])}`}
        </div>
      </Fragment>
    ),
  }, {
    title: 'Transaction Date',
    key: 'createdAt',
    render: (row) => {
      const createdAt = moment(row.createdAt).tz(timeZone);
      return (
        <div className="text-primary">{createdAt.format('MM/D/YYYY HH:mm')}</div>
      );
    },
  }, {
    key: 'options',
    render: row => (
      <Fragment>
        <div className="table-col-item text-primary" style={{ marginBottom: '4px' }}>
          <Link to={`/merchants/${row.merchantCode}/transactions/${row.externalTransactionId}`} className="table-item-link">
            <strong>View transaction</strong>
          </Link>
        </div>
        {row.transactionType === 'ENROLLMENT' && row.referenceId && (
          <div className="table-col-item text-primary" style={{ marginBottom: '4px' }}>
            <Link to={`/merchants/${row.merchantCode}/enrollments/${row.externalTransactionId}`} className="table-item-link">
              <strong>View enrollment</strong>
            </Link>
          </div>
        )}
        {/* {row.transactionType === 'PAYMENT' && row.referenceId && (
          <div className="table-col-item text-primary" style={{ marginBottom: '4px' }}>
            <Link to={`/merchants/${row.merchantCode}/payments/${row.externalTransactionId}/${row.invoiceId}`} className="table-item-link">
              <strong>View one-time payment</strong>
            </Link>
          </div>
        )} */}
        {/* {row.transactionType === 'ENROLLMENT' && row.paymentReferenceId && (
          <div className="table-col-item text-primary" style={{ marginBottom: '4px' }}>
            <Link to={`/merchants/${row.merchantCode}/payments/${row.externalTransactionId}/${row.invoiceId}`} className="table-item-link">
              <strong>View recurring payment</strong>
            </Link>
          </div>
        )} */}
      </Fragment>
    ),
  }];

  const pagination = {
    position: 'bottom',
    size: 'small',
    current: searchOption.page,
    pageSize: searchOption.size,
    total: state.totalCount,
    onChange: (targetPage) => {
      const totalPage = Math.ceil(state.totalCount / searchOption.size);
      let page = targetPage || 1;
      if (page < 1) { page = 1; }
      if (page > totalPage) { page = totalPage - 1; }
      dispatch({
        type: 'UPDATE_SEARCH_OPTION',
        searchOption: { ...searchOption, page },
      });
    },
    showTotal: (total, range) => `${range[0]} to ${range[1]} of ${total}`,
  };

  return (
    <div>
      <Row className="breadcrumb-row">
        <Col className="breadcrumb-header">
          <Breadcrumb className="breadcrumb-parent">
            <Breadcrumb.Item className="item-container">
              <div className="breadcrumb-item breadcrumb-icon">
                <SearchIcon />
              </div>
              <div className="breadcrumb-item icon-label">
                {searchOption.query ? `Search results for ${searchOption.query}` : 'Search transactions'}
              </div>
            </Breadcrumb.Item>
          </Breadcrumb>
          <div className="action-container">
            <Button
              className="button button-standard button-standard-outline"
              loading={state.isExporting}
              onClick={exportTransactions}
            >
              <Icon type="download" />
              Download
            </Button>
          </div>
        </Col>
      </Row>
      <Table
        className="table-standard"
        dataSource={state.searchResults}
        columns={columns}
        loading={state.isSearching}
        rowKey="transactionId"
        pagination={pagination}
      />
    </div>
  );
};

TransactionsSearchResult.propTypes = {
  layout: PropTypes.shape({
    searchQuery: PropTypes.string,
  }).isRequired,
};

TransactionsSearchResult.defaultProps = {
};

export default TransactionsSearchResult;
