import React, { useReducer } from 'react';
import PropTypes from 'prop-types';
import { Modal, Button } from 'antd';
import { activateMerchantService } from '../../services/merchants';
import { getAPIErrorMessage } from '../../helpers/utils';

const getInitialState = () => ({
  isActivating: false,
  message: null,
  status: null,
});

function reducer(prevState, action) {
  switch (action.type) {
    case 'ACTIVATE_MERCHANT':
      return {
        ...prevState,
        isSuspending: true,
        message: null,
        status: null,
      };
    case 'ACTIVATE_MERCHANT_SUCCESS':
      return {
        ...prevState,
        isSuspending: false,
        message: null,
        status: 'success',
      };
    case 'ACTIVATE_MERCHANT_FAILED':
      return {
        ...prevState,
        isSuspending: false,
        message: action.message,
        status: 'error',
      };
    default:
      return prevState;
  }
}

function ActivateMerchantAccountModal(props) {
  const { isVisible, merchant, onDone, onCancel } = props;
  const [state, dispatch] = useReducer(reducer, getInitialState());

  async function activateMerchant() {
    dispatch({ type: 'ACTIVATE_MERCHANT' });

    try {
      await activateMerchantService(merchant.id);
      dispatch({ type: 'ACTIVATE_MERCHANT_SUCCESS' });
      onDone({ message: `${merchant.name} account has been activated.`, status: 'success' });
    } catch (error) {
      const message = getAPIErrorMessage(error, 'We are not able to activate this merchant. Please try again later.');
      dispatch({ type: 'ACTIVATE_MERCHANT_FAILED', message });
    }
  }

  return (
    <Modal
      className="dialog-modal"
      visible={isVisible}
      title="Activate account"
      onCancel={onCancel}
      footer={[
        <Button
          key="cancel-confirm-key"
          onClick={onCancel}
        >
          Cancel
        </Button>,
        <Button
          loading={state.isActivating}
          key="submit"
          type="primary"
          onClick={() => activateMerchant()}
        >
          Activate
        </Button>,
      ]}
    >
      <p style={{ marginBottom: '4px' }}>
        Are you sure you want to activate
        <strong>{` ${merchant.name} `}</strong>
        to accept payments?
      </p>
    </Modal>
  );
}

ActivateMerchantAccountModal.propTypes = {
  isVisible: PropTypes.bool.isRequired,
  merchant: PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string,
  }),
  onDone: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
};

ActivateMerchantAccountModal.defaultProps = {
  merchant: { id: 0, name: null },
};

export default ActivateMerchantAccountModal;
