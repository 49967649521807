import api from '../helpers/apiClient';
import { exportUrl } from '../config/index';

export function searchEnrollments(searchOption) {
  const {
    page, size, status, project, merchant, paymentType,
  } = searchOption;
  return api.get('/enrollments', {
    params: {
      page,
      size,
      status,
      mid: merchant,
      project,
      paymentType,
      query: searchOption.query ? searchOption.query.trim() : null,
      startday: searchOption.startDate && searchOption.startDate.isValid()
        ? searchOption.startDate.format('YYYY-MM-DD') : undefined,
      endday: searchOption.endDate && searchOption.endDate.isValid()
        ? searchOption.endDate.format('YYYY-MM-DD') : undefined,
    },
  });
}

export function getEnrollmentByTransactionId(transactionId) {
  return api.get(`/enrollments/${transactionId}`);
}

export function getEnrollmentsService(merchantId, searchOption) {
  const { page, size } = searchOption;
  return api.get(`/merchants/${merchantId}/enrollments`, {
    params: {
      page,
      size,
      project: searchOption.project,
      paymentType: searchOption.paymentType,
      status: searchOption.status,
      query: searchOption.query ? searchOption.query.trim() : null,
      startday: searchOption.startDate.isValid() ? searchOption.startDate.format('YYYY-MM-DD') : undefined,
      endday: searchOption.endDate.isValid() ? searchOption.endDate.format('YYYY-MM-DD') : undefined,
    },
  });
}

export function findEnrollmentService(merchantId, externalTransactionid) {
  return api.get(`/merchants/${merchantId}/enrollments/${externalTransactionid}`);
}

export function getEnrollmentInvoicesService(merchantId, referenceId) {
  // Default the size by 12 months x 10 years = 120 items
  return api.get(`/merchants/${merchantId}/enrollments/${referenceId}/invoices`, {
    params: { page: 1, size: 120 },
  });
}

export function approveEnrollment(merchantId, referenceId) {
  return api.post(`/merchants/${merchantId}/enrollments/${referenceId}/approve`);
}

export function declineEnrollment(merchantId, referenceId, comment) {
  return api.post(`/merchants/${merchantId}/enrollments/${referenceId}/decline`, {
    comment,
  });
}

export function cancelEnrollment(merchantId, referenceId, comment) {
  return api.post(`/merchants/${merchantId}/enrollments/${referenceId}/cancel`, {
    comment,
  });
}

export function archiveEnrollment(merchantId, transactionId) {
  return api.post(`/merchants/${merchantId}/enrollments/${transactionId}/archive`);
}

export function unarchiveEnrollment(merchantId, transactionId) {
  return api.post(`/merchants/${merchantId}/enrollments/${transactionId}/unarchive`);
}

export function cancelEnrollmentInvoice(merchantId, enrollmentReferenceId, invoiceReferenceId, comment) {
  return api.post(`/merchants/${merchantId}/enrollments/${enrollmentReferenceId}/invoice/${invoiceReferenceId}/cancel`, {
    comment,
  });
}

export function chargeEnrollmentInvoice(merchantId, enrollmentReferenceId, invoiceReferenceId, comment) {
  return api.post(`/merchants/${merchantId}/enrollments/${enrollmentReferenceId}/invoice/${invoiceReferenceId}/charge`, {
    comment,
  });
}

export function exportMerchantEnrollmentsService(merchant, searchOption, token) {
  const startday = searchOption.startDate.isValid() ? searchOption.startDate.format('YYYY-MM-DD') : undefined;
  const endday = searchOption.endDate.isValid() ? searchOption.endDate.format('YYYY-MM-DD') : undefined;
  let url = `${exportUrl}/merchants/${merchant.code}/enrollments/export?token=${token}`;
  if (startday && endday) { url += `&startday=${startday}&endday=${endday}`; }
  if (searchOption.project) { url += `&project=${searchOption.project}`; }
  if (searchOption.status) { url += `&status=${searchOption.status}`; }
  if (searchOption.query) { url += `&query=${searchOption.query}`; }
  window.open(url, '_blank');
}

export function exportEnrollmentsService(searchOption, token) {
  const startday = searchOption.startDate.isValid() ? searchOption.startDate.format('YYYY-MM-DD') : undefined;
  const endday = searchOption.endDate.isValid() ? searchOption.endDate.format('YYYY-MM-DD') : undefined;
  let url = `${exportUrl}/enrollments/export?token=${token}`;
  if (startday && endday) { url += `&startday=${startday}&endday=${endday}`; }
  if (searchOption.project) { url += `&project=${searchOption.project}`; }
  if (searchOption.paymentType) { url += `&paymentType=${searchOption.paymentType}`; }
  if (searchOption.status) { url += `&status=${searchOption.status}`; }
  if (searchOption.query) { url += `&query=${searchOption.query}`; }
  window.open(url, '_blank');
}

export function aylResendNotification(merchantId, enrollmentTransactionId) {
  return api.post(`/ayl/${merchantId}/resend-schedule-notif/${enrollmentTransactionId}`);
}
