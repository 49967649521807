import React, { Fragment, useContext, useState } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import {
  Card, Button, Form, Input, Select, Icon, Row, Col,
} from 'antd';
import { createUserService } from '../../services/users';
import { getMerchantsService } from '../../services/merchants';
import SessionContext from '../../contexts/SessionContext';
import './newuserform.css';
import MainNavbar from '../MainNavbar';


const formItemLayout = { labelCol: { span: 24 }, wrapperCol: { span: 24 } };
const { Option } = Select;
const NewUserForm = (props) => {
  const { showToast, merchantRoles, systemRoles } = useContext(SessionContext);
  const [scopes, setScopes] = useState([]);
  const [systemRole, setSystemRole] = useState({
    key: 10,
    code: 'sysadmin',
    label: 'System Admin',
    description: 'Administrators have complete and unrestricted access to the system.',
  });
  const [merchantRole, setMerchantRole] = useState({
    key: null,
    label: null,
    description: null,
  });
  const [merchants, setMerchants] = useState([]);
  const [isFetching, setIsFetching] = useState(false);
  const { form, form: { getFieldDecorator }, history } = props;

  async function createUser(values) {
    try {
      setIsFetching(true);
      const response = await createUserService(values);
      const { data: { message, person: { id } } } = response;
      setIsFetching(false);
      showToast({ type: 'success', message });
      history.push(`/users/${id}`);
    } catch (error) {
      const message = error.response && error.response.data
        ? error.response.data.message
        : 'Unable to create users as of the moment';
      showToast({ type: 'error', message });
      setIsFetching(false);
    }
  }

  function handleSubmit(e) {
    e.preventDefault();
    form.validateFields((err, values) => {
      if (!err) {
        const selectedSystemRole = systemRole && systemRole.key ? systemRole.key : 10;
        let selectedMerchantRole = 10;

        if (systemRole && systemRole.key === 70) {
          if (merchantRole && merchantRole.key) {
            selectedMerchantRole = merchantRole.key;
          }
        } else {
          selectedMerchantRole = null;
        }

        createUser({
          ...values,
          systemrole: selectedSystemRole,
          merchantrole: selectedMerchantRole,
          merchants: systemRole.key === 70 && selectedMerchantRole >= 10 ? [...scopes] : null,
        });
      }
    });
  }

  function onSelectSystemRole(value) {
    if (value !== 'user') {
      setScopes([]);
      setMerchantRole({
        key: null,
        label: null,
        description: null,
      });
    } else {
      setMerchantRole({
        key: 10,
        label: 'Admin',
        description: 'Merchant administrator. Can manage all merchant information and transactions',
      });
    }

    const foundSystemRole = systemRoles.find(r => r.code === value);

    setSystemRole({
      key: foundSystemRole.key,
      code: foundSystemRole.code,
      label: foundSystemRole.label,
      description: foundSystemRole.description,
    });
  }

  let timeout;
  let currentValue;

  async function onSearchMerchant(merchantCode) {
    if (timeout) {
      clearTimeout(timeout);
      timeout = null;
    }

    currentValue = merchantCode;
    async function searchMerchant() {
      try {
        let searchOptions = {
          size: 20,
          page: 1,
        };

        if (currentValue) {
          searchOptions = {
            query: currentValue,
            size: 20,
            page: 1,
          };
        }

        const { data } = await getMerchantsService(searchOptions);
        if (currentValue === merchantCode) {
          setMerchants(data.merchants);
        }
      } catch (error) {
        setMerchants([]);
      }
    }

    // Set timeout to minimize flooding the server with API calls when
    // searching for merchants
    timeout = setTimeout(searchMerchant, 400);
  }

  function onSelectMerchantRole(value) {
    const foundMerchantRole = merchantRoles.find(r => r.key === value);

    setMerchantRole({
      key: value,
      label: foundMerchantRole.label,
      description: foundMerchantRole.description,
    });
  }

  const onSelectMerchants = value => setScopes([...value]);
  return (
    <Fragment>
      <Row gutter={32}>
        <Col span={12}>
          <Card className="card-standard" title="User Details">
            <Form id="detailsForm" onSubmit={handleSubmit}>
              <div className="details-body">
                <Form.Item {...formItemLayout} className="email-input" label="Email Address">
                  {getFieldDecorator('email', {
                    rules: [
                      { required: true, message: 'This field is required.' },
                      { type: 'email', message: 'Please provide a valid email.' },
                    ],
                  })(<Input type="email" />)}
                </Form.Item>
                <Form.Item {...formItemLayout} className="name-input" label="First Name">
                  {getFieldDecorator('firstname', {
                    rules: [{ required: true, message: 'Please enter the first name.' }],
                  })(<Input />)}
                </Form.Item>
                <Form.Item {...formItemLayout} className="name-input" label="Last Name">
                  {getFieldDecorator('lastname', {
                    rules: [{ required: true, message: 'Please enter the last name.' }],
                  })(<Input />)}
                </Form.Item>
              </div>
            </Form>
          </Card>
        </Col>
        <Col span={12}>
          <Card className="card-standard" title="Permission">
            <Form>
              <div className="details-body">
                <div className="dropdown-label">
                  Set the system role for this user.
                </div>
                <div className="dropdown-role">
                  <Select
                    className="role-select"
                    defaultValue="sysadmin"
                    onChange={onSelectSystemRole}
                    suffixIcon={<Icon type="caret-down" />}
                  >
                    {systemRoles.map(r => (
                      <Option key={r.key} value={r.code} label={r.label}>
                        {r.label}
                      </Option>
                    ))}
                  </Select>
                  {
                    (systemRole && systemRole.description) && (
                      <div className="role-description">
                        {`${systemRole.description}`}
                      </div>
                    )
                  }
                </div>
                {
                  systemRole.key === 70 && (
                    <Fragment>
                      <div className="dropdown-label">
                        Set the merchant role for this USER.
                      </div>
                      <div className="dropdown-role">
                        <Select
                          className="role-select"
                          defaultValue={merchantRoles[0].key}
                          onChange={onSelectMerchantRole}
                          suffixIcon={<Icon type="caret-down" />}
                        >
                          {merchantRoles.map(r => (
                            <Option key={r.key} value={r.key}>
                              {r.label}
                            </Option>
                          ))}
                        </Select>
                        {
                          merchantRole && merchantRole.key
                            ? (
                              <div className="role-description">
                                {`${merchantRole.description}`}
                              </div>
                            )
                            : (
                              <div className="role-description">
                                {`${merchantRoles[0].description}`}
                              </div>
                            )
                        }
                      </div>
                    </Fragment>
                  )
                }
                {
                  (systemRole && systemRole.key === 70) && (
                    <Fragment>
                      <div className="dropdown-label merchant-label">
                        Select the merchant/s this user has access to.
                      </div>
                      <div className="dropdown-role">
                        <Select
                          style={{ width: '100%' }}
                          className="merchant-select"
                          mode="multiple"
                          onChange={onSelectMerchants}
                          onSearch={onSearchMerchant}
                          showSearch
                          defaultValue={[]}
                          placeholder="Please select a merchant"
                          suffixIcon={<Icon type="caret-down" />}
                          filterOption={false}
                          notFoundContent={null}
                        >
                          {merchants.map(m => (
                            <Option key={m.code} value={m.code} label={MainNavbar.name}>
                              {m.code}
                            </Option>
                          ))}
                        </Select>
                      </div>
                    </Fragment>
                  )
                }
              </div>
            </Form>
          </Card>
        </Col>
      </Row>
      <div className="button-container">
        <Button
          className="button button-standard"
          form="detailsForm"
          key="submit"
          htmlType="submit"
          type="primary"
          loading={isFetching}
        >
          Add User
        </Button>
      </div>
    </Fragment>
  );
};

NewUserForm.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  form: PropTypes.object.isRequired,
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }).isRequired,
};

export default withRouter(Form.create()(NewUserForm));
