import React from 'react';
import { Row, Col, Breadcrumb } from 'antd';
import { DashboardIcon } from '../../components/Icons';
import LatestExchangeRate from '../../components/LatestExchangeRate';

function QwikwireDashboard() {
  return (
    <div>
      <Row className="breadcrumb-row">
        <Col span={8} className="breadcrumb-header">
          <Breadcrumb className="breadcrumb-parent">
            <Breadcrumb.Item className="item-container">
              <div className="breadcrumb-item breadcrumb-icon">
                <DashboardIcon />
              </div>
              <div className="breadcrumb-item icon-label">
                Dashboard
              </div>
            </Breadcrumb.Item>
          </Breadcrumb>
        </Col>
      </Row>
      <LatestExchangeRate />
    </div>
  );
}

export default QwikwireDashboard;
