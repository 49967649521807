import React, { useEffect, useReducer, useContext, useState } from 'react';
import PropTypes from 'prop-types';
import { Breadcrumb, Col, Row, Menu, Dropdown, Button, Icon, Alert } from 'antd';
import { Link } from 'react-router-dom';
import FileIcon from 'react-file-icon';
import { getMerchantByCode, getMerchantFilesPresignedDownloadUrl } from '../../services/merchants';
import { MerchantsPageIcon, FolderIcon } from '../../components/Icons';
import { getAPIErrorMessage, getMerchantStatusColor } from '../../helpers/utils';
import '../../styles/transactiondetails.css';
import { portalsPrefix } from '../../config';
import SuspendMerchantAccountModal from '../../components/SuspendMerchantAccountModal';
import ActivateMerchantAccountModal from '../../components/ActivateMerchantAccountModal';
import CloseMerchantAccountModal from '../../components/CloseMerchantAccountModal';
import ChangeMerchantCategoryModal from '../../components/ChangeMerchantCategoryModal';
import ToggleMerchantFeatureFlagsModal from '../../components/ToggleMerchantFeatureFlagsModal';
import PaymentTypeTable from '../../components/PaymentTypeTable';
import SessionContext from '../../contexts/SessionContext';

const initialState = {
  isProcessing: false,
  isFetchingMerchant: true,
  isFetchingPresignedUrls: false,
  presignedUrl: null,
  merchant: null,
  presignedUrls: [],
  isSuspendMerchantModalVisible: false,
  isClosingMerchantModalVisible: false,
  isActivateMerchantModalVisible: false,
  isChangeMerchantCategoryModalVisible: false,
  isMerchantFeatureToggleModalVisible: false,
  status: null,
  message: null,
};

function reducer(prevState, action) {
  switch (action.type) {
    case 'GET_MERCHANT_SUCCESSFUL':
      return {
        ...prevState,
        isFetchingMerchant: false,
        merchant: action.merchant,
      };
    case 'GET_MERCHANT_FAILED':
      return {
        ...prevState,
        isFetchingMerchant: false,
        merchant: null,
      };
    case 'GET_PRESIGNED_URLS':
      return {
        ...prevState,
        isFetchingPresignedUrls: false,
        presignedUrls: [],
      };
    case 'GET_PRESIGNED_URLS_SUCCESSFUL':
      return {
        ...prevState,
        isFetchingPresignedUrls: false,
        presignedUrls: action.presignedUrls,
      };
    case 'GET_PRESIGNED_URLS_FAILED':
      return {
        ...prevState,
        isFetchingPresignedUrls: false,
        presignedUrl: [],
        status: 'error',
        message: action.message,
      };
    case 'OPEN_MODAL':
      return {
        ...prevState,
        [action.modalType]: true,
      };
    case 'CLEAR_MESSAGE':
      return {
        ...prevState,
        status: null,
        message: null,
      };
    case 'SUSPEND_ACCOUNT_DONE':
      return {
        ...prevState,
        isSuspendMerchantModalVisible: false,
        message: action.message,
        status: action.status,
      };
    case 'ACTIVATE_ACCOUNT_DONE':
      return {
        ...prevState,
        isActivateMerchantModalVisible: false,
        message: action.message,
        status: action.status,
      };
    case 'CLOSE_ACCOUNT_DONE':
      return {
        ...prevState,
        isClosingMerchantModalVisible: false,
        message: action.message,
        status: action.status,
      };
    case 'CHANGE_CATEGORY_DONE':
      return {
        ...prevState,
        isChangeMerchantCategoryModalVisible: false,
        message: action.message,
        status: action.status,
      };
    case 'TOGGLE_FEATURE_FLAGS_DONE':
      return {
        ...prevState,
        isMerchantFeatureToggleModalVisible: false,
        message: action.message,
        status: action.status,
      };
    case 'HIDE_MODAL':
      return {
        ...prevState,
        isSuspendMerchantModalVisible: false,
        isClosingMerchantModalVisible: false,
        isActivateMerchantModalVisible: false,
        isChangeMerchantCategoryModalVisible: false,
        isMerchantFeatureToggleModalVisible: false,
      };
    default:
      return prevState;
  }
}

const QwikwireMerchant = (props) => {
  const { match: { params: { merchantCode } }, history } = props;
  const [state, dispatch] = useReducer(reducer, initialState);
  const { pushMessage, popMessage } = useContext(SessionContext);
  const [msg, setMsg] = useState(popMessage());

  useEffect(() => {
    let ableToSet = true;

    (async () => {
      try {
        const response = await getMerchantByCode(merchantCode);
        if (ableToSet) {
          dispatch({
            type: 'GET_MERCHANT_SUCCESSFUL',
            merchant: response.data,
          });
        }
      } catch (err) {
        const message = getAPIErrorMessage(err, 'Merchant does not exist');
        if (ableToSet) {
          dispatch({
            type: 'GET_MERCHANT_FAILED',
            message,
          });
        }
      }
    })();

    return () => { ableToSet = false; };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    let ableToSet = true;

    (async () => {
      try {
        if (!state.merchant) { return; }

        if (ableToSet) {
          dispatch({ type: 'GET_PRESIGNED_URLS' });
        }

        const response = await getMerchantFilesPresignedDownloadUrl(state.merchant.id);
        const presignedUrls = response.data;
        if (ableToSet) {
          dispatch({ type: 'GET_PRESIGNED_URLS_SUCCESSFUL', presignedUrls });
        }
      } catch (error) {
        const message = getAPIErrorMessage(error,
          'We are not able to get the attached files information. Please try again later');
        if (ableToSet) {
          dispatch({ type: 'GET_PRESIGNED_URLS_FAILED', message });
        }
      }
    })();

    return () => { ableToSet = false; };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.merchant]);

  if (state.isFetchingMerchant) {
    return (
      <div className="qwikwire">
        <section className="transaction-breadcrumb">
          <div className="transaction-breadcrumb-icon">
            <MerchantsPageIcon />
          </div>
          <Breadcrumb>
            <Breadcrumb.Item>
              <Link to="/merchants">All Merchants</Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item>
              {`#${merchantCode}`}
            </Breadcrumb.Item>
          </Breadcrumb>
        </section>
        <section style={{ padding: '8px' }}>
          <p>Loading...</p>
        </section>
      </div>
    );
  }

  if (!state.merchant) {
    return (
      <div>
        <div className="spinner">
          <FolderIcon />
          <h1>Merchant Not Found</h1>
          <p>
            You can contact us at&nbsp;
            <a href="mailto:support@aqwire.io">
              support@aqwire.io
            </a>
            &nbsp;if you have concerns or issues.
          </p>
        </div>
      </div>
    );
  }

  const {
    merchant,
    isSuspendMerchantModalVisible,
    isActivateMerchantModalVisible,
    isClosingMerchantModalVisible,
    isChangeMerchantCategoryModalVisible,
    isMerchantFeatureToggleModalVisible,
  } = state;
  const menu = (
    <Menu>
      {merchant.isPortals3Configured && (
      <Menu.Item
        key="view-portals-page"
      >
        <a href={`${portalsPrefix}/${merchant.code}`} rel="noopener noreferrer" target="_blank">
          View Portals Page
        </a>
      </Menu.Item>
      )}
      <Menu.Item
        key="toggle-features"
        onClick={() => dispatch({ type: 'OPEN_MODAL', data: merchant, modalType: 'isMerchantFeatureToggleModalVisible' })}
      >
        Toggle features
      </Menu.Item>
      <Menu.Item
        key="change-category"
        onClick={() => dispatch({ type: 'OPEN_MODAL', data: merchant, modalType: 'isChangeMerchantCategoryModalVisible' })}
      >
        Change category
      </Menu.Item>
      {merchant.status[1] !== 'suspended' && (
        <Menu.Item
          key="suspend-account"
          onClick={() => dispatch({ type: 'OPEN_MODAL', data: merchant, modalType: 'isSuspendMerchantModalVisible' })}
        >
          Suspend account
        </Menu.Item>
      )}
      {merchant.status[1] !== 'active' && (
        <Menu.Item
          key="activate-account"
          onClick={() => dispatch({ type: 'OPEN_MODAL', data: merchant, modalType: 'isActivateMerchantModalVisible' })}
        >
          Activate account
        </Menu.Item>
      )}
      <Menu.Item
        key="close-account"
        onClick={() => dispatch({ type: 'OPEN_MODAL', data: merchant, modalType: 'isClosingMerchantModalVisible' })}
      >
        Close account
      </Menu.Item>
    </Menu>
  );
  function renderFileType(fileType) {
    switch (fileType) {
      case 'bir2303':
        return 'BIR 2303';
      case 'dti':
        return 'DTI Certificate of Registration';
      case 'secPartnership':
        return 'SEC + Articles of Partnership';
      case 'secCorporation':
        return 'Articles of Incorporation with By Laws';
      case 'gis':
        return 'General Information Sheet';
      case 'amendedArticles':
        return 'Amended Articles of Incorporation';
      case 'brgy':
        return 'Barangay Clearance';
      case 'mayorsPermit':
        return 'Mayor\'s Permit';
      default:
        return 'N/A';
    }
  }

  return (
    <div className="qwikwire">
      <section className="transaction-breadcrumb">
        <div className="transaction-breadcrumb-icon">
          <MerchantsPageIcon />
        </div>
        <Breadcrumb>
          <Breadcrumb.Item>
            <Link to="/merchants">All Merchants</Link>
          </Breadcrumb.Item>
          <Breadcrumb.Item>
            {`#${merchant.id}`}
          </Breadcrumb.Item>
        </Breadcrumb>
      </section>
      { msg && msg.status && msg.message && (
        <div style={{ marginBottom: '8px' }}>
          <Row>
            <Col span={24}>
              <Alert
                message={<p style={{ marginBottom: 0 }}>{msg.message}</p>}
                type={['error', 'success'].includes(msg.status) ? msg.status : 'info'}
                showIcon
                closable
                onClose={() => setMsg(null)}
              />
            </Col>
          </Row>
        </div>
      )}
      <section className="panel panel-standard" style={{ marginBottom: '40px' }}>
        <div className="panel-header" style={{ paddingBottom: '0px' }}>
          <Row>
            <Col lg={8} className="panel-item">
              <h3>ID</h3>
              <div>{merchant.code}</div>
            </Col>
            <Col lg={8} className="panel-item">
              <h3>Name</h3>
              <div>{merchant.name}</div>
            </Col>
            <Col lg={2} className="panel-item">
              <h3>Status</h3>
              <div style={{ marginBottom: '8px' }}>
                <span
                  style={{
                    fontWeight: 700,
                    color: '#ffffff',
                    backgroundColor: getMerchantStatusColor(merchant.status[0]),
                    borderRadius: '4px',
                    padding: '4px 8px',
                  }}
                >
                  {merchant.status[2]}
                </span>
              </div>
            </Col>
            <Col lg={6} className="panel-item">
              <div className="table-col-item" style={{ float: 'right' }}>
                <Dropdown overlay={menu} placement="bottomRight">
                  <Button
                    type="primary"
                    className="button button-standard button-standard-outline button-small"
                  >
                    Options
                    <Icon type="down" />
                  </Button>
                </Dropdown>
              </div>
            </Col>
          </Row>
        </div>
        <div className="panel-body">
          <div className="panel-item">
            <h3 style={{ marginBottom: '20px' }}>Business Information</h3>
            <Row>
              <Col lg={5} className="panel-item">
                <div className="text-primary">Legal Name</div>
              </Col>
              <Col lg={15} className="panel-item">
                <div className="text-secondary">{merchant.legalName}</div>
              </Col>
            </Row>
            <Row>
              <Col lg={5} className="panel-item">
                <div className="text-primary">Type</div>
              </Col>
              <Col lg={15} className="panel-item">
                <div className="text-secondary">{merchant.businessType}</div>
              </Col>
            </Row>
            <Row>
              <Col lg={5} className="panel-item">
                <div className="text-primary">Registration Number</div>
              </Col>
              <Col lg={15} className="panel-item">
                <div className="text-secondary">{merchant.businessNumber}</div>
              </Col>
            </Row>
            <Row>
              <Col lg={5} className="panel-item">
                <div className="text-primary">TIN</div>
              </Col>
              <Col lg={15} className="panel-item">
                <div className="text-secondary">{merchant.taxNumber}</div>
              </Col>
            </Row>
            <Row>
              <Col lg={5} className="panel-item">
                <div className="text-primary">Address</div>
              </Col>
              <Col lg={15} className="panel-item">
                <div className="text-secondary">
                  {merchant.type === 'wiremo' && (
                    <div>{`${merchant.address.address_one}, ${merchant.address.address_city}, ${merchant.address.address_postal_code}, ${merchant.address.address_state}, ${merchant.address.address_country_iso2_code}`}</div>
                  )}
                  {merchant.type === 'aqwire' && (
                    <div>{`${merchant.address.address_one} ${merchant.address.address_two}, ${merchant.address.address_three}`}</div>
                  )}
                </div>
              </Col>
            </Row>
            <Row>
              <Col lg={5} className="panel-item">
                <div className="text-primary">Is Verified</div>
              </Col>
              <Col lg={15} className="panel-item">
                <div className="text-secondary">{merchant.isVerified ? 'Yes' : 'No'}</div>
              </Col>
            </Row>
            <Row>
              <Col lg={5} className="panel-item">
                <div className="text-primary">Timezone</div>
              </Col>
              <Col lg={15} className="panel-item">
                <div className="text-secondary">{merchant.timezone}</div>
              </Col>
            </Row>
            <h3 style={{ marginTop: '28px', marginBottom: '20px' }}>Owner Information</h3>
            <Row>
              <Col lg={5} className="panel-item">
                <div className="text-primary">Owner</div>
              </Col>
              <Col lg={15} className="panel-item">
                <div className="text-secondary">{merchant.ownerName}</div>
              </Col>
            </Row>
            <Row>
              <Col lg={5} className="panel-item">
                <div className="text-primary">Mobile Number</div>
              </Col>
              <Col lg={15} className="panel-item">
                <div className="text-secondary">{merchant.ownerMobile ? '+'.concat(merchant.ownerMobile) : ''}</div>
              </Col>
            </Row>
            <Row>
              <Col lg={5} className="panel-item">
                <div className="text-primary">Email</div>
              </Col>
              <Col lg={15} className="panel-item">
                <div className="text-secondary">{merchant.ownerEmail}</div>
              </Col>
            </Row>
            <h3 style={{ marginTop: '28px', marginBottom: '20px' }}>Business Documents</h3>
            <div className="panel-item">
              {state.presignedUrls ? (
                state.presignedUrls.map((url) => {
                  const searchExtensionResults = /.[a-z]+$/.exec(url.fileName);
                  const fileExtension = searchExtensionResults ? searchExtensionResults[0] : null;
                  return (
                    <div className="file-icon-wrapper" style={{ display: 'block' }} key={url.fileCategory}>
                      <a
                        className="file-icon"
                        href={url.presignedUrl}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <FileIcon
                          extension={fileExtension}
                          style={{ width: '24px', height: '24px' }}
                        />
                        <span>{renderFileType(url.fileCategory)}</span>
                      </a>
                    </div>
                  );
                })
              ) : <p>No attached file</p>}
            </div>
          </div>
        </div>
      </section>
      <section className="panel panel-standard">
        <PaymentTypeTable merchant={state.merchant} />
      </section>
      {isSuspendMerchantModalVisible && (
        <SuspendMerchantAccountModal
          isVisible
          merchant={merchant}
          onDone={({ message, status }) => {
            dispatch({ type: 'SUSPEND_ACCOUNT_DONE' });
            pushMessage({ component: 'QwikwireMerchant', status, message });
            history.push(`${merchant.code}`, {});
          }}
          onCancel={() => dispatch({ type: 'HIDE_MODAL' })}
        />
      )}

      {isActivateMerchantModalVisible && (
        <ActivateMerchantAccountModal
          isVisible
          merchant={merchant}
          onDone={({ message, status }) => {
            dispatch({ type: 'ACTIVATE_ACCOUNT_DONE' });
            pushMessage({ component: 'QwikwireMerchant', status, message });
            history.push(`${merchant.code}`, {});
          }}
          onCancel={() => dispatch({ type: 'HIDE_MODAL' })}
        />
      )}

      {isClosingMerchantModalVisible && (
        <CloseMerchantAccountModal
          isVisible
          merchant={merchant}
          onDone={({ message, status }) => {
            dispatch({ type: 'CLOSE_ACCOUNT_DONE' });
            pushMessage({ component: 'QwikwireMerchant', status, message });
            history.push(`${merchant.code}`, {});
          }}
          onCancel={() => dispatch({ type: 'HIDE_MODAL' })}
        />
      )}

      {isChangeMerchantCategoryModalVisible && (
        <ChangeMerchantCategoryModal
          isVisible
          merchant={merchant}
          onDone={({ message, status }) => {
            dispatch({ type: 'CHANGE_CATEGORY_DONE' });
            pushMessage({ component: 'QwikwireMerchant', status, message });
            history.push(`${merchant.code}`, {});
          }}
          onCancel={() => dispatch({ type: 'HIDE_MODAL' })}
        />
      )}

      {isMerchantFeatureToggleModalVisible && (
        <ToggleMerchantFeatureFlagsModal
          isVisible
          merchant={merchant}
          onDone={({ message, status }) => {
            dispatch({ type: 'TOGGLE_FEATURE_FLAGS_DONE' });
            pushMessage({ component: 'QwikwireMerchant', status, message });
            history.push(`${merchant.code}`, {});
          }}
          onCancel={() => dispatch({ type: 'HIDE_MODAL' })}
        />
      )}
    </div>
  );
};

QwikwireMerchant.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      merchantCode: PropTypes.string.isRequired,
    }),
  }),
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }).isRequired,
  location: PropTypes.shape({
    pathname: PropTypes.string.isRequired,
    search: PropTypes.string.isRequired,
  }),
};

QwikwireMerchant.defaultProps = {
  match: {
    params: {
      merchantCode: null,
    },
  },
  location: {
    pathname: null,
    search: null,
  },
};

export default QwikwireMerchant;
