import React from 'react';
import PropTypes from 'prop-types';
import { CheckCircleIcon, TimesCircleIcon, InfoCircleIcon, CloseIcon } from '../Icons';
import './appalert.css';

function AppAlert(props) {
  const { message, type } = props;
  return (
    <div className="global-alert">
      <div>
        <div className="icon-container">
          {(() => {
            switch (type) {
              case 'success':
                return <CheckCircleIcon fill="#ffffff" diameter="22px" />;
              case 'error':
                return <TimesCircleIcon fill="#ffffff" diameter="22px" />;
              case 'info':
                return <InfoCircleIcon fill="#ffffff" diameter="22px" />;
              default:
                return null;
            }
          })()}
        </div>
      </div>
      <div>
        {message}
      </div>
      <div>
        <CloseIcon stroke="#ffffff" />
      </div>
    </div>
  );
}

AppAlert.propTypes = {
  message: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
};

export default AppAlert;
