import React, { useReducer, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Modal, Button, Row, Col, Alert } from 'antd';
import { deleteProjectService } from '../../services/projects';
import { getAPIErrorMessage } from '../../helpers/utils';

function DeleteProjectModal(props) {
  const { isOpen, onOk, onClose, merchant, project } = props;
  const [state, dispatch] = useReducer((prevState, action) => {
    switch (action.type) {
      case 'DELETE_PROJECT':
        return {
          ...prevState,
          isDeleting: true,
        };
      case 'DELETE_PROJECT_SUCCESS':
        return {
          ...prevState,
          isDeleting: false,
          status: 'success',
          message: null,
        };
      case 'DELETE_PROJECT_FAILED':
        return {
          ...prevState,
          isDeleting: false,
          status: 'error',
          message: action.message,
        };
      default:
        return prevState;
    }
  }, {
    isDeleting: false,
    status: null,
    message: null,
  });

  useEffect(() => {
    if (state.status === 'success') {
      onOk();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.status]);

  async function deleteProject() {
    dispatch({ type: 'DELETE_PROJECT' });

    try {
      await deleteProjectService(merchant.id, project.id);
      dispatch({
        type: 'DELETE_PROJECT_SUCCESS',
      });
    } catch (error) {
      const message = getAPIErrorMessage(error,
        'We are not able to delete this project. Please try again later');
      dispatch({
        type: 'DELETE_PROJECT_FAILED',
        message,
      });
    }
  }

  return (
    <Modal
      title="Delete Project"
      className="dialog-modal"
      visible={isOpen}
      onCancel={onClose}
      footer={[
        <Button
          key="cancel"
          loading={state.isDeleting}
          disabled={state.isDeleting}
          onClick={onClose}
        >
          Cancel
        </Button>,
        <Button
          key="submit"
          type="danger"
          htmlType="submit"
          loading={state.isDeleting}
          disabled={state.isDeleting}
          onClick={deleteProject}
        >
          Delete
        </Button>,
      ]}
    >
      {state.status && state.message && (
        <div style={{ marginBottom: '8px' }}>
          <Row>
            <Col span={24}>
              <Alert
                message={<p style={{ marginBottom: 0 }}>{state.message}</p>}
                type={state.status}
                showIcon
                closable
                onClose={() => dispatch({ type: 'CLEAR_MESSSAGE' })}
              />
            </Col>
          </Row>
        </div>
      )}
      <p>
        This will delete the current project.
        Deleting the project will result to the project being permanently deleted.
      </p>
    </Modal>
  );
}

DeleteProjectModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onOk: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  merchant: PropTypes.shape({
    id: PropTypes.number.isRequired,
    code: PropTypes.string.isRequired,
  }).isRequired,
  project: PropTypes.shape({
    id: PropTypes.number.isRequired,
    key: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    isActive: PropTypes.bool.isRequired,
    isEnabled: PropTypes.bool.isRequired,
  }).isRequired,
};

export default DeleteProjectModal;
