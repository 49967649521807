import React, { useReducer, Fragment, useContext } from 'react';
import PropTypes from 'prop-types';
import { Form, Button, Modal, Input } from 'antd';
import SessionContext from '../../contexts/SessionContext';
import { ROLES } from '../../constants';
import { sendPaymentReceipt } from '../../services/payments';
import '../../styles/modal.scss';

const initialState = {
  isModalVisible: false,
  isProcessingRequest: false,
};

function reducer(prevState, action) {
  switch (action.type) {
    case 'CHANGE_RECEIPT_MODAL_VISIBILITY':
      return {
        ...prevState,
        isModalVisible: !prevState.isModalVisible,
      };

    case 'PROCESS_RECEIPT_REQUEST':
      return {
        ...prevState,
        isProcessingRequest: true,
      };

    case 'COMPLETE_RECEIPT_REQUEST':
      return {
        ...prevState,
        isProcessingRequest: false,
      };

    case 'RECEIPT_REQUEST_FAILED':
      return {
        ...prevState,
        isProcessingRequest: false,
        state: 'error',
        message: action.message,
      };

    default:
      return prevState;
  }
}

const SendReceiptModal = (props) => {
  const { form, payment } = props;
  const [state, dispatch] = useReducer(reducer, initialState);
  const { getFieldDecorator } = form;
  const { loggedInUser, showToast } = useContext(SessionContext);

  function sendEmail(e) {
    e.preventDefault();

    dispatch({ type: 'PROCESS_RECEIPT_REQUEST' });

    form.validateFields(async (err, { email }) => {
      if (err) {
        dispatch({ type: 'COMPLETE_RECEIPT_REQUEST' });
        showToast({ type: 'error', message: 'Invalid email provided' });
        return;
      }

      try {
        const { data: { message } } = await sendPaymentReceipt(payment.merchantCode, payment.paymentReferenceId, email);
        showToast({ type: 'success', message });
        dispatch({ type: 'CHANGE_RECEIPT_MODAL_VISIBILITY' });
      } catch (error) {
        const message = error && error.response
          ? error.response.data.message
          : 'Unable to send receipt as of the moment';

        showToast({ type: 'error', message });
      }

      dispatch({ type: 'COMPLETE_RECEIPT_REQUEST' });
    });
  }

  if (loggedInUser.systemRole[0] > ROLES.SUPPORT || !['PAID', 'SETTLED'].includes(payment.paymentStatus)) {
    return <Fragment />;
  }

  return (
    <div>
      <Button
        className="button button-standard"
        onClick={() => dispatch({ type: 'CHANGE_RECEIPT_MODAL_VISIBILITY' })}
      >
        Send Receipt
      </Button>
      <Modal
        className="dialog-modal"
        title="Send Receipt"
        visible={state.isModalVisible}
        onCancel={() => dispatch({ type: 'CHANGE_RECEIPT_MODAL_VISIBILITY' })}
        footer={[
          <Button
            key="cancel"
            onClick={() => dispatch({ type: 'CHANGE_RECEIPT_MODAL_VISIBILITY' })}
          >
            Cancel
          </Button>,
          <Button
            key="send"
            type="primary"
            htmlType="submit"
            loading={state.isProcessingRequest}
            onClick={sendEmail}
          >
            Send
          </Button>,
        ]}
      >
        <Form onSubmit={sendEmail}>
          <Form.Item label="Deliver to">
            {getFieldDecorator('email', {
              rules: [{ required: true, type: 'email', message: 'Please enter a valid email address' }],
              initialValue: payment.customerEmail,
            })(<Input />)}
          </Form.Item>
        </Form>
      </Modal>
    </div>
  );
};

SendReceiptModal.propTypes = {
  form: PropTypes.shape({
    getFieldDecorator: PropTypes.func.isRequired,
    validateFields: PropTypes.func.isRequired,
  }).isRequired,
  payment: PropTypes.shape({
    invoiceId: PropTypes.string.isRequired,
    merchantCode: PropTypes.string.isRequired,
    paymentReferenceId: PropTypes.string,
    billTotal: PropTypes.arrayOf(PropTypes.any).isRequired,
    billBase: PropTypes.arrayOf(PropTypes.any).isRequired,
    paymentStatus: PropTypes.string.isRequired,
    customerEmail: PropTypes.string.isRequired,
  }).isRequired,
};

export default Form.create()(SendReceiptModal);
