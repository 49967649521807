import * as moment from 'moment';
import React, { useEffect, useReducer } from 'react';
import { getLatestExchangeRate } from '../../services/exchangeRates';

function LatestExchangeRate() {
  const [state, dispatch] = useReducer((prevState, action) => {
    switch (action.type) {
      case 'GET_RATE_SUCCESSFUL':
        return {
          ...prevState,
          isFetching: false,
          exchangeRate: action.exchangeRate,
        };
      case 'GET_RATE_FAILED':
        return {
          ...prevState,
          isFetching: false,
          exchangeRate: null,
          status: 'error',
          message: action.message,
        };
      default:
        return prevState;
    }
  }, {
    exchangeRate: null,
    isFetching: true,
  });

  useEffect(() => {
    let canDispatch = true;

    (async () => {
      try {
        const response = await getLatestExchangeRate();
        if (canDispatch) {
          dispatch({ type: 'GET_RATE_SUCCESSFUL', exchangeRate: response.data });
        }
      } catch (error) {
        const message = error && error.response && error.response.data ? error.response.data.message
          : 'We are not able to get the latest exchange rate from the server';
        if (canDispatch) {
          dispatch({ type: 'GET_RATE_FAILED', message });
        }
      }
    })();

    return () => { canDispatch = false; };
  }, []);

  return (
    <div
      className="card"
      style={{
        backgroundColor: '#ffffff',
        margin: '16px 0',
        padding: '28px',
        display: 'flex',
        justifyContent: 'space-between',
      }}
    >
      <div>
        {state.exchangeRate && (
          <React.Fragment>
            <div>
              {`Market exchange rate as of ${moment(state.exchangeRate.timestamp).format('MMM. DD, hh:mm:ss A')}`}
            </div>
            {state.exchangeRate.rates.PHP && (
              <h3 style={{ marginTop: '8px', marginBottom: 0 }}>
                {`USD 1 - PHP ${(1 / state.exchangeRate.rates.USD).toFixed(4)}`}
              </h3>
            )}
          </React.Fragment>
        )}
      </div>
    </div>
  );
}

export default LatestExchangeRate;
