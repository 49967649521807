import moment from 'moment';
import React from 'react';
import PropTypes from 'prop-types';
import { Link, useHistory } from 'react-router-dom';
import { Row, Col, Button } from 'antd';

const PaymentSettlementDetails = (props) => {
  const { payment, merchant, user } = props;
  const history = useHistory();

  if (!['PAID', 'SETTLED'].includes(payment.paymentStatus)) {
    return <div />;
  }

  if (!payment.settlementReferenceId) {
    return (
      <section className="panel panel-standard">
        <div className="panel-body">
          <Row>
            <Col md={12} lg={8}>
              <p>This payment has not yet been settled.</p>
              {user.systemRole[0] <= 50 && (
                <div className="settlement-details-button">
                  <Button
                    className="button button-standard-outline"
                    onClick={() => history.push(`/settlements/${merchant.code}/new`)}
                  >
                    Upload Settlement Report
                  </Button>
                </div>
              )}
            </Col>
          </Row>
        </div>
      </section>
    );
  }

  if (!payment.settledDate) {
    return (
      <section className="panel panel-standard">
        <div className="panel-body">
          <p>
            This payment has not yet been settled.
          </p>
        </div>
      </section>
    );
  }

  return (
    <section className="panel panel-standard">
      <div className="panel-body">
        <Row>
          <Col lg={6}>
            <div className="panel-item">
              <h3>Settlement ID</h3>
              <div>
                {user.systemRole[0] <= 50 ? (
                  <Link to={`/settlements/${payment.settlementId}`}>
                    {payment.settlementReferenceId}
                  </Link>
                ) : (
                  <Link to={`/merchants/${merchant.code}/settlements/${payment.settlementReferenceId}`}>
                    {payment.settlementReferenceId}
                  </Link>
                )}
              </div>
            </div>
          </Col>
          <Col lg={6}>
            <div className="panel-item">
              <h3>Date</h3>
              <div>
                {payment.settledDate ? moment(payment.settledDate, 'YYYY-MM-DD').format('LL') : '----'}
              </div>
            </div>
          </Col>
        </Row>
      </div>
    </section>
  );
};

PaymentSettlementDetails.propTypes = {
  payment: PropTypes.shape({
    settlementId: PropTypes.number,
    settlementReferenceId: PropTypes.string,
    settledDate: PropTypes.string,
    paymentStatus: PropTypes.string,
  }).isRequired,
  merchant: PropTypes.shape({
    code: PropTypes.string.isRequired,
  }).isRequired,
  user: PropTypes.shape({
    id: PropTypes.number.isRequired,
    systemRole: PropTypes.arrayOf(PropTypes.any).isRequired,
  }).isRequired,
};

PaymentSettlementDetails.defaultProps = {
};

export default PaymentSettlementDetails;
