import api from '../helpers/apiClient';

/* eslint-disable-next-line */
export function getActivitiesService(page, size) {
  return api.get('/activities', {
    params: {
      page,
      size,
    },
  });
}

/* eslint-disable-next-line */
export function getMerchantActivitiesService(merchantId, page, size) {
  return api.get(`/merchants/${merchantId}/activities`, {
    params: {
      page,
      size,
    },
  });
}
