import moment from 'moment';
import React, { useEffect, useReducer } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { Table, Menu, Dropdown, Icon } from 'antd';
import CancelEnrollmentInvoiceModal from '../CancelEnrollmentInvoiceModal';
import ChargeEnrollmentInvoiceModal from '../ChargeEnrollmentInvoiceModal';
import { getEnrollmentInvoicesService } from '../../services/enrollments';
import { formatNumber } from '../../helpers/utils';
import '../../styles/transaction.css';

const initialState = {
  isFetching: false,
  invoices: [],
  totalCount: 0,
  invoice: {},
  isCancelEnrollmentInvoiceModalVisible: false,
};

function reducer(prevState, action) {
  switch (action.type) {
    case 'GET_INVOICES':
      return {
        ...prevState,
        isFetching: true,
      };

    case 'GET_INVOICES_SUCCESS':
      return {
        ...prevState,
        isFetching: false,
        invoices: action.invoices,
        totalCount: action.totalCount,
      };

    case 'GET_INVOICES_FAILED':
      return {
        ...prevState,
        isFetching: false,
      };

    case 'CANCEL_INVOICE_DONE':
      return {
        ...prevState,
        isCancelEnrollmentInvoiceModalVisible: false,
        invoice: {},
      };

    case 'OPEN_MODAL':
      return {
        ...prevState,
        [action.modalType]: true,
        invoice: { ...action.data },
      };

    case 'HIDE_MODAL':
      return {
        ...prevState,
        [action.modalType]: false,
      };

    default:
      return prevState;
  }
}

const EnrollmentInvoices = (props) => {
  const {
    enrollmentReferenceId, externalTransactionId, merchantId, merchantCode, layout, onChangeSearchFunction,
  } = props;
  const [state, dispatch] = useReducer(reducer, initialState);

  const columns = [{
    title: 'Due At',
    key: 'dueAt',
    render: row => (
      <div className="text-primary">
        <strong>{moment(row.dueAt).format('LL')}</strong>
      </div>
    ),
  }, {
    title: 'Invoice Reference ID',
    key: 'invoiceReferenceId',
    render: row => (
      <div className="text-secondary">
        {row.invoiceReferenceId}
      </div>
    ),
  }, {
    title: 'Status',
    key: 'status',
    render: row => (
      <div className="text-secondary">
        {row.status}
      </div>
    ),
  }, {
    title: 'Amount Due',
    key: 'baseAmount',
    render: row => (
      <div className="text-secondary">
        {`${row.billBase[0]} ${formatNumber(row.billBase[1])}`}
      </div>
    ),
  }, {
    title: 'Charged Amount',
    key: 'amount',
    render: row => (
      <div className="text-secondary">
        {`${row.billTotal[0]} ${formatNumber(row.billTotal[1])}`}
      </div>
    ),
  }, {
    title: 'Retries',
    key: 'retries',
    render: row => (
      <div className="text-secondary">
        {`${row.retryAttemptCount}`}
      </div>
    ),
  }, {
    title: 'Payment Reference ID',
    key: 'paymentReferenceId',
    render: row => (
      row.paymentReferenceId
        ? (
          <Link
            to={layout === 'Qwikwire'
              ? `/payments/${row.invoiceId}`
              : `/merchants/${merchantCode}/payments/${externalTransactionId}/${row.invoiceId}`}
          >
            {row.paymentReferenceId}
          </Link>
        )
        : <span>----</span>
    ),
  }, {
    title: 'Paid At',
    key: 'paidAt',
    render: row => (
      <div>
        {((val) => {
          const datetime = moment(val);
          if (!datetime.isValid()) { return '--- --, ----'; }
          return datetime.format('LL');
        })(row.paidAt)}
      </div>
    ),
  }, {
    key: 'actions',
    render: (row) => {
      if (layout !== 'Qwikwire' || row.status !== 'SCHED') {
        return null;
      }
      const menu = (
        <Menu>
          <Menu.Item
            key="charge-enrollment-invoice"
            onClick={() => dispatch({ type: 'OPEN_MODAL', data: row, modalType: 'isChargeEnrollmentInvoiceModalVisible' })}
          >
            Charge Invoice
          </Menu.Item>
          <Menu.Item
            key="cancel-enrollment-invoice"
            onClick={() => dispatch({ type: 'OPEN_MODAL', data: row, modalType: 'isCancelEnrollmentInvoiceModalVisible' })}
          >
            Cancel Invoice
          </Menu.Item>
        </Menu>
      );
      return (
        <Dropdown overlay={menu} trigger={['click']}>
          <Icon type="ellipsis" />
        </Dropdown>
      );
    },
  }];

  useEffect(() => {
    let ableToSet = true;
    (async () => {
      if (ableToSet) {
        dispatch({ type: 'GET_INVOICES' });
      }

      if (enrollmentReferenceId) {
        try {
          const response = await getEnrollmentInvoicesService(merchantId, enrollmentReferenceId);
          const { data: { invoices, totalCount } } = response;
          if (ableToSet) {
            dispatch({ type: 'GET_INVOICES_SUCCESS', invoices, totalCount });
          }
        } catch (err) {
          const message = err && err.response
            ? err.response.data.message
            : 'We are not able to get the invoices from the server as of the moment';
          if (ableToSet) {
            dispatch({ type: 'GET_INVOICES_FAILED', message });
          }
        }
      } else if (ableToSet) {
        const invoices = [];
        const totalCount = 0;
        dispatch({ type: 'GET_INVOICES_SUCCESS', invoices, totalCount });
      }
    })();

    return () => { ableToSet = false; };
  }, [merchantId, merchantCode, enrollmentReferenceId]);

  return (
    <div>
      {state.isCancelEnrollmentInvoiceModalVisible && (
        <CancelEnrollmentInvoiceModal
          isVisible
          merchantId={merchantId}
          enrollmentReferenceId={enrollmentReferenceId}
          invoice={state.invoice}
          onDone={({ message, status }) => {
            dispatch({ type: 'CANCEL_INVOICE_DONE' });
            onChangeSearchFunction({ ...state.searchOption }, { message, status });
          }}
          onCancel={() => dispatch({ type: 'HIDE_MODAL', modalType: 'isCancelEnrollmentInvoiceModalVisible' })}
        />
      )}
      {state.isChargeEnrollmentInvoiceModalVisible && (
        <ChargeEnrollmentInvoiceModal
          isVisible
          merchantId={merchantId}
          enrollmentReferenceId={enrollmentReferenceId}
          invoice={state.invoice}
          onDone={({ message, status }) => {
            dispatch({ type: 'CHARGE_INVOICE_DONE' });
            onChangeSearchFunction({ ...state.searchOption }, { message, status });
          }}
          onCancel={() => dispatch({ type: 'HIDE_MODAL', modalType: 'isChargeEnrollmentInvoiceModalVisible' })}
        />
      )}
      <Table
        pagination={{
          size: 'small',
          pageSize: 10,
          total: state.totalCount,
          showTotal: (total, range) => `Showing ${range[0]} - ${range[1]} of ${total}`,
        }}
        loading={state.isFetching}
        style={{ marginTop: '16px' }}
        className="table-standard"
        dataSource={state.invoices}
        columns={columns}
        rowKey="invoiceReferenceId"
      />
    </div>
  );
};

EnrollmentInvoices.propTypes = {
  onChangeSearchFunction: PropTypes.func,
  enrollmentReferenceId: PropTypes.string,
  externalTransactionId: PropTypes.string.isRequired,
  merchantId: PropTypes.number.isRequired,
  merchantCode: PropTypes.string.isRequired,
  layout: PropTypes.string.isRequired,
};

EnrollmentInvoices.defaultProps = {
  enrollmentReferenceId: null,
  onChangeSearchFunction: () => {},
};

export default EnrollmentInvoices;
