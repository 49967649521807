import axios from 'axios';
import config from '../config';
import { ACCESS_TOKEN, CLIENT_ID, REFRESH_TOKEN } from '../constants';

function getAuthorizationHeaders() {
  const accessToken = localStorage.getItem(ACCESS_TOKEN);
  const clientId = localStorage.getItem(CLIENT_ID);
  const refreshToken = localStorage.getItem(REFRESH_TOKEN);
  if (accessToken) {
    return {
      Authorization: `Bearer ${accessToken}`,
      'X-Client-Id': clientId,
      'X-Refresh-Token': refreshToken,
    };
  }

  return {};
}

function createClient() {
  return axios.create({
    baseURL: config.apiBaseUrl,
    headers: {
      ...getAuthorizationHeaders(),
    },
  });
}

function createApiClient() {
  function resolveAccessToken(res) {
    const accessToken = res.headers['x-access-token'];
    if (accessToken) {
      localStorage.setItem(ACCESS_TOKEN, accessToken);
    }
    return res;
  }

  return {
    get(...args) {
      return createClient().get(...args).then(resolveAccessToken);
    },
    post(...args) {
      return createClient().post(...args).then(resolveAccessToken);
    },
    put(...args) {
      return createClient().put(...args).then(resolveAccessToken);
    },
    delete(...args) {
      return createClient().delete(...args).then(resolveAccessToken);
    },
    patch(...args) {
      return createClient().patch(...args).then(resolveAccessToken);
    },
  };
}

export default createApiClient();
