import React, { Fragment, useContext, useState, useEffect, useReducer } from 'react';
import PropTypes from 'prop-types';
import qs from 'query-string';
import {
  Avatar,
  Breadcrumb,
  Col,
  Row,
  Tabs,
  Input,
  Button,
  Form,
  Select,
} from 'antd';
import {
  MerchantMembersTable,
  MerchantPaymentMethods,
  MerchantPaymentTypesTable,
  TimezoneSelector,
  AddMerchantMemberModal,
} from '../../components';
import { FolderIcon } from '../../components/Icons';
import SessionContext from '../../contexts/SessionContext';
import { updateMerchantInformation, getAllMerchantCategories } from '../../services/merchants';
import { portalsPrefix } from '../../config';

import './merchantsettings.css';

const getInitialState = queryFilters => ({
  key: queryFilters.key || 'info',
});


function reducer(prevState, action) {
  switch (action.type) {
    default:
      return prevState;
  }
}

function getFilteredURLPath(path, searchOption) {
  const { key } = searchOption;
  let url = `${path}`;
  if (key) { url += `?key=${key}`; }
  return url;
}

const { TextArea } = Input;
const { Option } = Select;

const MerchantSettings = (props) => {
  const { history, location, match, form } = props;
  const queryFilters = qs.parse(location.search, { ignoreQueryPrefix: true });
  const [state] = useReducer(reducer, getInitialState(queryFilters));

  const { merchants, loggedInUser, showToast } = useContext(SessionContext);
  const merchant = merchants.find(m => m.code === match.params.merchantCode);
  const [merchantState, setMerchant] = useState(merchant);
  const [categoryState, setCategory] = useState([]);
  const [isLoading, setLoadingState] = useState(false);
  const { getFieldDecorator } = form;
  const isConfigured = merchant && merchant.isPortals3Configured
    ? (
      <a
        className="portals-configured"
        href={`${portalsPrefix}/${merchant.code}`}
        target="_blank"
        rel="noopener noreferrer"
      >
        configured
      </a>
    )
    : 'not configured';

  const { systemRole, merchantRoles } = loggedInUser;
  const { merchantRole } = merchantRoles.find(m => m.merchantCode === merchant.code);

  useEffect(() => {
    let ableToSet = true;

    (async () => {
      try {
        const { data } = await getAllMerchantCategories();

        if (ableToSet) {
          setCategory(data.map(category => <Option key={category.id}>{category.description}</Option>));
        }
      } catch (error) {
        let message = error && error.response && error.response.data.message;
        message = message || 'Unknown error occured';

        if (ableToSet) {
          showToast({ type: 'error', message });
        }
      }
    })();

    return () => { ableToSet = false; };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (!(loggedInUser && merchants.length)) { return <div />; }
  if (systemRole[0] >= 80
    || (systemRole[0] === 70 && merchantRole[0] > 10)
    || !merchant
  ) {
    return (
      <div>
        <div className="spinner">
          <FolderIcon />
          <h1>You are not allowed to access the merchant settings page.</h1>
          <p>
            You can contact us at
            <a href="mailto:support@aqwire.io">support@aqwire.io</a>
            if you have concerns or issues.
          </p>
        </div>
      </div>
    );
  }

  function onChangeTabKey(key) {
    const url = getFilteredURLPath(location.pathname, key);
    history.push(url, {});
  }

  function getPortals3StatusColor(status) {
    return status ? '#399DE5' : '#FFCC00';
  }

  function updateMerchantInfo(e) {
    e.preventDefault();

    form.validateFields((err, {
      name, category, addressOne, addressTwo, addressThree, timezone,
    }) => {
      setLoadingState(true);

      if (err) {
        showToast({ type: 'error', message: 'Invalid input' });
        setLoadingState(false);
        return;
      }

      // NOTE: This workaround had to be done as the Form
      // could not take merchant.categoryId as initial value
      // and still display the description
      const parsedCategory = category === merchant.categoryDescription ? Number(merchant.categoryId) : category;

      merchant.timezone = timezone;
      merchant.address = {
        addressOne,
        addressTwo,
        addressThree,
      };

      setLoadingState(false);

      updateMerchantInformation(merchant.id, name, parsedCategory, merchant.address, timezone)
        .then(({ data }) => {
          showToast({ type: 'success', message: data.message });

          setMerchant({
            ...merchant,
            name,
            categoryDescription: category,
          });
        })
        .catch(({ response: { data, status } }) => {
          const message = status >= 400 ? data.message : 'Unknown error occured';
          showToast({ type: 'error', message });
        })
        .finally(() => setLoadingState(false));
    });
  }

  return (
    <div>
      <Fragment>
        <Row className="breadcrumb-row">
          <Col className="breadcrumb-header">
            <Breadcrumb className="breadcrumb-parent">
              <Breadcrumb.Item className="item-container">
                <div className="breadcrumb-item breadcrumb-icon">
                  <Avatar
                    style={{
                      width: '48px', height: '48px', border: '1px solid #E1E7ED', marginRight: '16px',
                    }}
                    src={merchantState.logo}
                  />
                </div>
                <div className="breadcrumb-item icon-label">
                  <h4 style={{ display: 'flex', flexDirection: 'column', marginTop: '4px', marginBottom: 0 }}>
                    <div className="heading-primary" style={{ lineHeight: 1.24 }}>
                      {`${merchantState.name} (${merchantState.code})`}
                    </div>
                    <div className="text-secondary">
                      {`${merchantState.categoryDescription} (${merchantState.invoicingMode})`}
                    </div>
                  </h4>
                </div>
              </Breadcrumb.Item>
            </Breadcrumb>
          </Col>
        </Row>
        <Tabs
          defaultActiveKey={state.key}
          onChange={key => onChangeTabKey({ key })}
        >
          <Tabs.TabPane tab="Information" key="info">
            <div style={{ margin: '12px 4px 32px', maxWidth: '50vw' }}>
              {/* <section className="panel panel-standard panel-warning" style={{ marginBottom: '8px' }}>
                <div className="panel-body">
                  <p style={{ marginBottom: 0 }}>
                    Any updates to your merchant account information would not appear on your Portals payment page for now.
                    We are working on this feature for the upcoming releases. Stay tuned for our updates.
                  </p>
                </div>
              </section> */}
              <section className="panel panel-standard">
                <Form onSubmit={e => updateMerchantInfo(e)}>
                  <div className="panel-body">
                    <h3>Settings</h3>
                    <div style={{ marginTop: '16px' }} className="merchant-info-fields">
                      <Form.Item label="Name">
                        {getFieldDecorator('name', {
                          rules: [
                            { required: true, message: 'Merchant name is required' },
                            { max: 100, message: '100 characters is the limit for merchant name' },
                          ],
                          initialValue: merchant.name,
                        })(
                          <Input placeholder="Merchant Name" />,
                        )}
                      </Form.Item>
                      <Form.Item label="Category">
                        {getFieldDecorator('category', {
                          rules: [{ required: true, message: 'Category is required' }],
                          initialValue: merchant.categoryDescription,
                        })(
                          <Select disabled>
                            {categoryState}
                          </Select>,
                        )}
                      </Form.Item>
                      <Form.Item label="Address One">
                        {getFieldDecorator('addressOne', {
                          rules: [{ max: 250, message: '250 characters is the limit for address' }],
                          initialValue: merchant.address.address_one,
                        })(
                          <TextArea placeholder="Address One" />,
                        )}
                      </Form.Item>
                      <Form.Item label="Address Two">
                        {getFieldDecorator('addressTwo', {
                          rules: [{ max: 250, message: '250 characters is the limit for address' }],
                          initialValue: merchant.address.address_two,
                        })(
                          <TextArea placeholder="Address Two" />,
                        )}
                      </Form.Item>
                      <Form.Item label="Address Three">
                        {getFieldDecorator('addressThree', {
                          rules: [{ max: 250, message: '250 characters is the limit for address' }],
                          initialValue: merchant.address.address_three,
                        })(
                          <TextArea placeholder="Address Three" />,
                        )}
                      </Form.Item>
                      <Form.Item label="Timezone">
                        {getFieldDecorator('timezone', {
                          rules: [{ required: true, message: 'Timezone is required' }],
                          initialValue: merchant.timezone,
                        })(
                          <TimezoneSelector />,
                        )}
                      </Form.Item>
                      <Row gutter={16} style={{ marginTop: '16px' }}>
                        <Col span={6}>
                          <span>Portals3</span>
                        </Col>
                        <Col span={6}>
                          <span
                            style={{
                              fontWeight: 700,
                              color: '#ffffff',
                              backgroundColor: getPortals3StatusColor(merchant.isPortals3Configured),
                              borderRadius: '4px',
                              padding: '4px 8px',
                            }}
                          >
                            {isConfigured}
                          </span>
                        </Col>
                      </Row>
                    </div>
                  </div>
                  <div className="submit-field">
                    <Button
                      htmlType="submit"
                      className="button button-standard"
                      loading={isLoading}
                      disabled={merchant.type === 'partner'}
                    >
                      Save
                    </Button>
                  </div>
                </Form>
              </section>
            </div>
          </Tabs.TabPane>
          {/* <Tabs.TabPane tab="Projects" key="projects">
            <div className="action-container create-project">
              <Button
                className="button button-standard button-standard-outline"
                onClick={() => history.push(`/merchants/${merchant.code}/projects/new`)}
              >
                <Icon type="plus" />
                <span>New Project</span>
              </Button>
            </div>
            <div style={{ margin: '12px 4px 32px' }}>
              <MerchantProjectsTable
                merchant={merchantState}
                location={location}
                history={history}
              />
            </div>
          </Tabs.TabPane> */}
          {merchant.type !== 'partner'
            && (
              <Tabs.TabPane tab="Payment Types" key="types">
                <div style={{ margin: '12px 4px 32px ' }}>
                  <MerchantPaymentTypesTable merchant={merchantState} />
                </div>
              </Tabs.TabPane>
            )
          }
          {merchant.canManagePaymentMethods && merchant.type !== 'partner'
            && (
              <Tabs.TabPane tab="Payment Methods" key="paymentMethods">
                <div style={{ margin: '12px 4px 32px ' }}>
                  <MerchantPaymentMethods merchant={merchantState} />
                </div>
              </Tabs.TabPane>
            )
          }
          {merchant.type !== 'partner'
            && (
              <Tabs.TabPane tab="Members" key="members">
                <div className="action-container create-project">
                  <AddMerchantMemberModal history={history} />
                </div>
                <div style={{ margin: '12px 4px 32px' }}>
                  <MerchantMembersTable
                    merchant={merchantState}
                    location={location}
                    history={history}
                  />
                </div>
              </Tabs.TabPane>
            )
          }
        </Tabs>
      </Fragment>
    </div>
  );
};

MerchantSettings.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      merchantCode: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }).isRequired,
  location: PropTypes.shape({
    pathname: PropTypes.string.isRequired,
    search: PropTypes.string.isRequired,
  }).isRequired,
  form: PropTypes.shape({
    getFieldDecorator: PropTypes.func.isRequired,
    validateFields: PropTypes.func.isRequired,
  }).isRequired,
};

export default Form.create()(MerchantSettings);
