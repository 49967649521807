import React, { useReducer, useContext } from 'react';
import PropTypes from 'prop-types';
import { Modal, Button, Select, Typography, Form } from 'antd';
import { updateMerchantCategoryService } from '../../services/merchants';
import { getAPIErrorMessage } from '../../helpers/utils';
import SessionContext from '../../contexts/SessionContext';

const getInitialState = () => ({
  category: 1, // todo
  isChanging: false,
  message: null,
  status: null,
});

function reducer(prevState, action) {
  switch (action.type) {
    case 'SET_ACTIVE_CATEGORY':
      return {
        ...prevState,
        category: action.category,
      };
    case 'UPDATE_MERCHANT_CATEGORY':
      return {
        ...prevState,
        isChanging: true,
        message: null,
        status: null,
      };
    case 'UPDATE_MERCHANT_CATEGORY_SUCCESS':
      return {
        ...prevState,
        isChanging: false,
        message: null,
        status: 'success',
      };
    case 'UPDATE_MERCHANT_CATEGORY_FAILED':
      return {
        ...prevState,
        isChanging: false,
        message: action.message,
        status: 'error',
      };
    default:
      return prevState;
  }
}

function ChangeMerchantCategoryModal(props) {
  const { isVisible, merchant, onDone, onCancel } = props;
  const [state, dispatch] = useReducer(reducer, getInitialState(merchant));
  const { merchantCategories } = useContext(SessionContext);

  async function updateCategory() {
    dispatch({ type: 'UPDATE_MERCHANT_CATEGORY' });

    if (!state.category || !state.category.id) {
      dispatch({
        type: 'UPDATE_MERCHANT_CATEGORY_FAILED',
        message: 'Category is required',
      });
      return;
    }

    try {
      await updateMerchantCategoryService(merchant.id, state.category.id);
      dispatch({ type: 'UPDATE_MERCHANT_CATEGORY_SUCCESS' });
      onDone({ message: `${merchant.name} account category has been updated.`, status: 'success' });
    } catch (error) {
      const message = getAPIErrorMessage(error, 'We are not able to update the category. Please try again later.');
      dispatch({ type: 'UPDATE_MERCHANT_CATEGORY_FAILED', message });
    }
  }

  return (
    <Modal
      className="dialog-modal"
      visible={isVisible}
      title="Change category"
      onCancel={onCancel}
      footer={[
        <Button
          key="cancel-confirm-key"
          onClick={onCancel}
        >
          Cancel
        </Button>,
        <Button
          loading={state.isChanging}
          key="submit"
          type="primary"
          onClick={() => updateCategory()}
        >
          Update
        </Button>,
      ]}
    >
      <p>
        You are updating the category for
        <strong>{` ${merchant.name}`}</strong>
        . This will enable or disable features, and it will impact their operation.
      </p>
      <Form
        onSubmit={(ev) => {
          ev.preventDefault();
          updateCategory();
        }}
      >
        <Form.Item>
          <Select
            className="transaction-filter"
            allowClear
            showSearch
            disabled={state.isChanging}
            defaultValue={merchant.categoryId}
            placeholder="Select industry"
            filterOption={(inputValue, option) => {
              const { props: { children } } = option;
              return children.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1;
            }}
            onSelect={(id) => {
              const category = merchantCategories.find(s => s.id === Number(id));
              dispatch({ type: 'SET_ACTIVE_CATEGORY', category });
            }}
            onChange={(selectedScope) => {
              if (!selectedScope) {
                dispatch({ type: 'SET_ACTIVE_CATEGORY', category: null });
              }
            }}
          >
            {merchantCategories.map(c => (
              <Select.Option key={c.id} value={c.id} label={c.description}>
                {c.description}
              </Select.Option>
            ))}
          </Select>
          {state.status === 'error' && state.message && (
            <Typography.Text type="danger">{state.message}</Typography.Text>
          )}
        </Form.Item>
      </Form>
    </Modal>
  );
}

ChangeMerchantCategoryModal.propTypes = {
  isVisible: PropTypes.bool.isRequired,
  merchant: PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string,
    categoryId: PropTypes.number,
  }),
  onDone: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
};

ChangeMerchantCategoryModal.defaultProps = {
  merchant: { id: 0, name: null, categoryId: 1 },
};

export default ChangeMerchantCategoryModal;
