import React, { useContext, useState, useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import { Tabs } from 'antd';

import { AccountIcon } from '../../components/Icons';
import { AccountSettingsInfo, AccountSettingsPassword } from '../../components';
import { getAccountInfoService } from '../../services/auth';
import SessionContext from '../../contexts/SessionContext';
import './accountsettings.css';

const AccountSettings = () => {
  const { showToast, loggedInUser } = useContext(SessionContext);
  const [user, setUser] = useState(null);
  const { TabPane } = Tabs;

  useEffect(() => {
    let ableToSet = true;

    (async () => {
      try {
        const { data: person } = await getAccountInfoService();
        if (ableToSet) setUser(person);
      } catch (err) {
        const message = err && err.response
          ? err.response.data.message
          : 'Unable to retrieve your account information as of the moment';
        showToast({ type: 'error', message });
      }
    })();

    return () => { ableToSet = false; };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (!loggedInUser) { return <div />; }

  if (!user) { return <div />; }

  return (
    <div>
      <div className="accounts-header">
        <AccountIcon />
        <span className="accounts-header-title">Account Settings</span>
      </div>
      <div className="accounts-content">

        <Tabs defaultActiveKey="1">
          <TabPane tab="Details" key="1">
            <div className="accounts-content-item">
              <AccountSettingsInfo user={user} />
            </div>
          </TabPane>
          <TabPane tab="Security" key="2">
            <div className="accounts-content-item">
              <AccountSettingsPassword user={user} />
            </div>
          </TabPane>
        </Tabs>
      </div>
    </div>
  );
};

export default withRouter(AccountSettings);
