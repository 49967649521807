import React from 'react';
import './banner.css';
import { environment } from '../../config/index';

const Banner = () => {
  if (environment !== 'production') {
    return (
      <div className="banner-container">
        <p className="banner" />
        <p className="banner banner-text">SANDBOX MODE</p>
      </div>
    );
  }
  return (<div />);
};


export default Banner;
