import React, { Fragment, useContext } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { Menu } from 'antd';
import {
  DashboardIcon, PaymentIcon, EnrollmentIcon, SettlementReportsIcon, CodeIcon,
  MerchantsIcon, UsersIcon, TerminalIcon, PieChartIcon, NotificationsIcon, TagIcon,
} from '../Icons';
import { ActiveMerchantSelector } from '..';
import SessionContext from '../../contexts/SessionContext';
import { APP_STATES, ROLES, MERCHANT_ROLES } from '../../constants';
import './navigation.css';


const Navigation = () => {
  const sessionContext = useContext(SessionContext);
  const history = useHistory();
  const location = useLocation();
  const { activeMerchant, merchants, setSelectedMerchant, appState } = sessionContext;

  if (appState <= APP_STATES.IS_LOADING) { return <div />; }

  const { systemRole, merchantRoles } = sessionContext.loggedInUser;
  const { merchantRole } = merchantRoles.find(m => m.merchantCode === activeMerchant.code);
  const menus = [
    {
      isVisible: activeMerchant
        && activeMerchant.type !== 'partner'
        && (systemRole[0] <= ROLES.USER || merchantRole[0] <= MERCHANT_ROLES.ANALYST),
      value: (
        <Menu.Item key="dashboard">
          <div className="custom-icon">
            <DashboardIcon width="17px" height="17px" />
          </div>
          <span>Dashboard</span>
        </Menu.Item>
      ),
    },
    {
      isVisible: activeMerchant
        && activeMerchant.type !== 'partner'
        && (systemRole[0] <= ROLES.USER || merchantRole[0] <= MERCHANT_ROLES.ANALYST),
      value: (
        <Menu.Item key="payments">
          <div className="custom-icon">
            <PaymentIcon width="19px" height="19px" />
          </div>
          <span>Payments</span>
        </Menu.Item>
      ),
    },
    {
      isVisible: activeMerchant
        && activeMerchant.type !== 'partner'
        && (systemRole[0] <= ROLES.USER || merchantRole[0] <= MERCHANT_ROLES.ANALYST),
      value: (
        <Menu.Item key="enrollments">
          <div className="custom-icon">
            <EnrollmentIcon width="18px" height="17px" />
          </div>
          <span>Enrollments</span>
        </Menu.Item>
      ),
    },
    {
      isVisible: activeMerchant
        && activeMerchant.type !== 'partner'
        && (systemRole[0] <= ROLES.USER || merchantRole[0] <= MERCHANT_ROLES.ADMIN)
        && activeMerchant.canManageProjects,
      value: (
        <Menu.Item key="projects">
          <div
            className="custom-icon"
            style={{ marginLeft: '18px', marginRight: '23px', marginTop: '4px' }}
          >
            <TagIcon width="16px" height="20px" />
          </div>
          <span>Projects</span>
        </Menu.Item>
      ),
    },
    {
      isVisible: activeMerchant
        && activeMerchant.type !== 'partner'
        && systemRole[0] <= ROLES.USER
        && merchantRole[0] <= MERCHANT_ROLES.ANALYST,
      value: (
        <Menu.Item key="settlements">
          <div
            className="custom-icon"
            style={{ marginLeft: '20px', marginRight: '21px' }}
          >
            <SettlementReportsIcon />
          </div>
          <span>Settlement Reports</span>
        </Menu.Item>
      ),
    },
    {
      isVisible: activeMerchant
        && activeMerchant.type !== 'partner'
        && systemRole[0] <= ROLES.USER
        && merchantRole[0] === MERCHANT_ROLES.ADMIN
        && activeMerchant.canAccessReports,
      value: (
        <Menu.Item key="reports">
          <div
            className="custom-icon"
            style={{ marginLeft: '20px', marginRight: '21px', marginTop: '3px' }}
          >
            <PieChartIcon />
          </div>
          <span>Reports</span>
        </Menu.Item>
      ),
    },
    {
      isVisible: activeMerchant
        && systemRole[0] <= ROLES.USER
        && [MERCHANT_ROLES.ADMIN, MERCHANT_ROLES.DEVELOPER].includes(merchantRole[0])
        && activeMerchant.canManageApi,
      value: (
        <Menu.Item key="developers">
          <div
            className="custom-icon"
            style={{ marginLeft: '20px', marginRight: '21px', marginTop: '3px' }}
          >
            <CodeIcon area="20px" />
          </div>
          <span>Developers</span>
        </Menu.Item>
      ),
    },
    {
      isVisibleFooter: systemRole[0] <= ROLES.USER
        && merchantRole[0] === MERCHANT_ROLES.ADMIN
        && activeMerchant.type !== 'partner',
      value: (
        <Menu.Item key="activities">
          <div className="custom-icon" style={{ width: '12px', marginTop: '4px', marginRight: '24px' }}>
            <TerminalIcon area="20px" />
          </div>
          <span>Activity Log</span>
        </Menu.Item>
      ),
    },
    {
      isVisibleFooter: systemRole[0] === ROLES.USER
        && merchantRole[0] <= MERCHANT_ROLES.ANALYST
        && activeMerchant.canManageNotificationSettings
        && activeMerchant.type !== 'partner',
      value: (
        <Menu.Item key="notifications">
          <div className="custom-icon" style={{ width: '10px', marginTop: '4px', marginRight: '26px' }}>
            <NotificationsIcon />
          </div>
          <span>Notifications</span>
        </Menu.Item>
      ),
    },
    {
      isVisibleFooter: systemRole[0] <= ROLES.USER && merchantRole[0] === MERCHANT_ROLES.ADMIN,
      value: (
        <Menu.Item key="settings">
          <div className="custom-icon" style={{ width: '10px', marginTop: '4px', marginRight: '26px' }}>
            <MerchantsIcon />
          </div>
          <span>Settings</span>
        </Menu.Item>
      ),
    },
    {
      isVisibleFooter: systemRole[0] <= ROLES.AUDITOR,
      value: (
        <Menu.Item key="users">
          <div className="custom-icon" style={{ marginTop: '3px' }}>
            <UsersIcon />
          </div>
          <span>Users</span>
        </Menu.Item>
      ),
    },
  ];

  function getMenuBasedOnPath(loc) {
    const { pathname } = loc;
    // eslint-disable-next-line no-unused-vars
    const [category, merchantCode, subcategory, ...extra] = pathname.split('/').slice(1);

    switch (category) {
      case 'merchants':
        return ((name) => {
          switch (name) {
            case 'transactions': return 'dashboard';
            case 'payments': return 'payments';
            case 'enrollments': return 'enrollments';
            case 'settlements': return 'settlements';
            case 'reports': return 'reports';
            case 'developers': return 'developers';
            case 'settings': return 'settings';
            case 'members': return 'settings';
            case 'projects': return 'projects';
            case 'notifications': return 'notifications';
            case 'activities': return 'activities';
            default: return merchantCode ? 'dashboard' : 'merchants';
          }
        })(subcategory);
      case 'users':
        return 'users';
      case 'activities':
        return 'activities';
      default:
        return activeMerchant.type !== 'partner' ? 'none' : 'developers';
    }
  }

  function onNavigationClick(e) {
    const route = ((key, merchant) => {
      switch (key) {
        case 'dashboard':
          return `/merchants/${merchant.code}`;
        case 'payments':
          return `/merchants/${merchant.code}/payments`;
        case 'enrollments':
          return `/merchants/${merchant.code}/enrollments`;
        case 'settlements':
          return `/merchants/${merchant.code}/settlements`;
        case 'projects':
          return `/merchants/${merchant.code}/projects`;
        case 'reports':
          return `/merchants/${merchant.code}/reports`;
        case 'developers':
          return activeMerchant.type !== 'partner' ? `/merchants/${merchant.code}/developers` : '/';
        case 'notifications':
          return `/merchants/${merchant.code}/notifications`;
        case 'settings':
          return `/merchants/${merchant.code}/settings`;
        case 'activities':
          return `/merchants/${merchant.code}/activities`;
        case 'newUser':
          return '/users/new';
        default:
          return `/${key}`;
      }
    })(e.key, activeMerchant);
    history.push(route);
  }

  return (
    <Fragment>
      <div className="navigation">
        <div>
          <ActiveMerchantSelector
            merchants={merchants}
            activeMerchant={activeMerchant}
            setSelectedMerchant={setSelectedMerchant}
            history={history}
          />
          <div>
            <Menu
              className="navigation-menu"
              onClick={onNavigationClick}
              selectedKeys={[getMenuBasedOnPath(location)]}
            >
              {menus.filter(m => m.isVisible).map(m => m.value)}
            </Menu>
          </div>
        </div>
        <div style={{ borderTop: '1px solid #d9d9d9', paddingBottom: '4px' }}>
          <Menu
            className="navigation-footer"
            onClick={onNavigationClick}
            selectedKeys={[getMenuBasedOnPath(location)]}
          >
            {menus.filter(m => m.isVisibleFooter).map(m => m.value)}
          </Menu>
        </div>
      </div>
    </Fragment>
  );
};

export default Navigation;
