import moment from 'moment';
import React, { useReducer, useEffect } from 'react';
import PropTypes from 'prop-types';
import qs from 'query-string';
import { useLocation, useHistory } from 'react-router-dom';
import {
  Row, Col, Breadcrumb, Table, Select, Button, Input, Icon, Modal,
} from 'antd';
import Message from '../../components/Message';
import SettlementsFilterModal from '../../components/SettlementsFilterModal';
import { SettlementReportsIconBig } from '../../components/Icons';
import { getMerchantsService, getMerchantById } from '../../services/merchants';
import { searchSettlements } from '../../services/settlements';
import { APP_STATES } from '../../constants';
import '../../styles/transaction.css';
import '../../styles/modal.css';
import './index.css';

const dateFormat = 'YYYY-MM-DD';
const displayDateFormat = 'MMM. DD, YYYY';
function getInitialState(queryFilters) {
  return {
    isFetchingSettlements: true,
    settlements: [],
    merchants: [],
    merchant: null,
    status: null,
    message: null,
    searchOption: {
      page: parseInt(queryFilters.page, 10) || 1,
      size: parseInt(queryFilters.size, 10) || 20,
      totalCount: 0,
      settledAt: moment(queryFilters.settled || null, dateFormat),
      merchant: ((qf) => {
        if (typeof qf.merchant === 'object') {
          return qf.merchant;
        }
        const id = Number(qf.merchant);
        if (!Number.isNaN(id)) {
          return { id };
        }
        return null;
      })(queryFilters),
      query: queryFilters.query || null,
    },
  };
}

function reducer(prevState, action) {
  switch (action.type) {
    case 'GET_SETTLEMENTS':
      return {
        ...prevState,
        isFetchingSettlements: true,
        status: null,
        message: null,
      };
    case 'GET_SETTLEMENTS_SUCCESSFUL':
      return {
        ...prevState,
        isFetchingSettlements: false,
        settlements: action.settlements,
        searchOption: {
          ...prevState.searchOption,
          totalCount: action.totalCount,
        },
      };
    case 'GET_SETTLEMENTS_FAILED':
      return {
        ...prevState,
        isFetchingSettlements: false,
        settlements: [],
        searchOption: {
          ...prevState.searchOption,
          totalCount: 0,
        },
        status: 'error',
        message: action.message,
      };
    case 'GET_MERCHANTS_SUCCESSFUL':
      return {
        ...prevState,
        merchants: action.merchants,
      };
    case 'GET_MERCHANTS_FAILED':
      return {
        ...prevState,
        merchants: [],
        status: 'error',
        message: action.message,
      };
    case 'UPDATE_SEARCH_OPTION':
      return {
        ...prevState,
        searchOption: action.searchOption,
      };
    case 'CLEAR_MESSAGE':
      return {
        ...prevState,
        message: null,
        status: null,
      };
    case 'SET_MERCHANT':
      return {
        ...prevState,
        merchant: action.merchant,
      };
    default:
      return prevState;
  }
}

function getFilteredURLPath(path, searchOption) {
  const { page, size, settledAt, query, merchant } = searchOption;
  let url = `${path}?page=${page || 1}`;
  if (size) { url += `&size=${size}`; }
  if (settledAt && settledAt.isValid()) { url += `&settled=${settledAt.format('YYYY-MM-DD')}`; }
  if (merchant) { url += `&merchant=${merchant.id}`; }
  if (query) { url += `&query=${query}`; }
  return url;
}

function NewSettlementSelector(props) {
  const { history } = props;
  const [state, dispatch] = useReducer((prevState, action) => {
    switch (action.type) {
      case 'OPEN':
        return {
          ...prevState,
          isOpen: true,
        };
      case 'CLOSE':
        return {
          ...prevState,
          isOpen: false,
        };
      case 'INITIALIZE_FILTERS':
        return {
          ...prevState,
          merchants: action.merchants,
          merchant: (() => {
            if (!prevState.merchant) { return null; }
            return action.merchants.find(m => m.code === prevState.merchant.code);
          })(),
        };
      case 'SET':
        return {
          ...prevState,
          merchant: action.merchant,
        };
      default:
        return prevState;
    }
  }, {
    isOpen: false,
    merchant: null,
    merchants: [],
  });

  function onSelectMerchant(merchantId) {
    if (!merchantId) {
      dispatch({ type: 'SET', merchant: null });
    } else {
      dispatch({
        type: 'SET',
        merchant: state.merchants.find(ps => ps.id === merchantId),
      });
    }
  }

  let timeout;
  let currentValue;

  async function onSearchMerchant(merchantCode) {
    if (!merchantCode) {
      dispatch({ type: 'SET', merchant: null });
      return;
    }

    if (timeout) {
      clearTimeout(timeout);
      timeout = null;
    }

    currentValue = merchantCode;
    async function searchMerchant() {
      if (timeout) {
        clearTimeout(timeout);
        timeout = null;
      }
      try {
        if (state.merchant) {
          dispatch({
            type: 'INITIALIZE_FILTERS',
            merchants: [state.merchant],
          });
          return;
        }

        let searchOptions = {
          size: 20,
          page: 1,
        };

        if (merchantCode) {
          searchOptions = {
            query: merchantCode,
            size: 20,
            page: 1,
          };
        }

        const { data } = await getMerchantsService(searchOptions);
        if (currentValue === merchantCode) {
          dispatch({ type: 'INITIALIZE_FILTERS', merchants: data.merchants });
        }
      } catch (error) {
        dispatch({ type: 'INITIALIZE_FILTERS', merchants: [] });
      }
    }

    // Set timeout to minimize flooding the server with API calls when
    // searching for merchants
    timeout = setTimeout(searchMerchant, 400);
  }
  return (
    <div>
      <Button
        className="button button-standard button-standard-outline button-small"
        onClick={() => dispatch({ type: 'OPEN' })}
      >
        <Icon type="plus" />
        Create
      </Button>
      <Modal
        className="dialog-modal"
        title="Create Settlement"
        visible={state.isOpen}
        footer={
          [
            <Button
              key="create"
              onClick={() => {
                if (state.merchant) {
                  history.push(`/settlements/${state.merchant.code}/new`);
                }
              }}
              type="primary"
            >
              Create
            </Button>,
            <Button key="cancel" onClick={() => dispatch({ type: 'CLOSE' })}>
              Cancel
            </Button>,
          ]
        }
        onCancel={() => dispatch({ type: 'CLOSE' })}
      >
        <div>
          <h4>Which merchant to settle the payments?</h4>
          <Select
            className="transaction-filter"
            showSearch
            defaultValue={state.merchant ? state.merchant.id : undefined}
            placeholder="Merchant"
            style={{ width: '100%' }}
            defaultActiveFirstOption={false}
            allowClear
            showArrow={false}
            filterOption={(inputValue, option) => {
              const { props: { children } } = option;
              return children.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1;
            }}
            onChange={onSelectMerchant}
            onSearch={onSearchMerchant}
            notFoundContent={null}
          >
            {state.merchants.map(p => (
              <Select.Option value={p.id} key={p.code} label={p.name}>
                {p.name}
              </Select.Option>
            ))}
          </Select>
        </div>
      </Modal>
    </div>
  );
}

NewSettlementSelector.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }).isRequired,
};

function QwikwireSettlements(props) {
  const { appState } = props;
  const location = useLocation();
  const history = useHistory();

  const queryFilters = qs.parse(location.search, { ignoreQueryPrefix: true });
  const [state, dispatch] = useReducer(reducer, getInitialState(queryFilters));
  const { searchOption } = state;

  useEffect(() => {
    let ableToSet = true;

    (async () => {
      try {
        if (state.searchOption.merchant) {
          const { data } = await getMerchantById(state.searchOption.merchant.id);
          if (ableToSet) {
            dispatch({ type: 'SET_MERCHANT', merchant: data });
          }
        } else {
          dispatch({ type: 'SET_MERCHANT', merchant: null });
        }
      } catch (error) {
        if (ableToSet) {
          dispatch({ type: 'SET_MERCHANT', merchant: null });
        }
      }
    })();

    return () => { ableToSet = false; };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.searchOption.merchant]);

  useEffect(() => {
    let ableToSet = true;
    (async () => {
      try {
        dispatch({ type: 'GET_SETTLEMENTS' });

        const settlementsResponse = await searchSettlements({
          ...state.searchOption,
        });
        const { settlements, totalCount } = settlementsResponse.data;

        if (ableToSet) {
          dispatch({
            type: 'GET_SETTLEMENTS_SUCCESSFUL',
            settlements,
            totalCount,
          });
        }
      } catch (error) {
        const message = error && error.response ? error.response.data.message
          : 'We are not able to get the settlements from the server as of the moment';
        if (ableToSet) {
          dispatch({ type: 'GET_SETTLEMENTS_FAILED', message });
        }
      }
    })();

    return () => { ableToSet = false; };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    searchOption.page,
    searchOption.size,
    searchOption.merchant,
    searchOption.settledAt,
    searchOption.query,
  ]);

  function onChangeSearchOption(opts) {
    const url = getFilteredURLPath(location.pathname, opts);
    history.push(url, {});
    dispatch({
      type: 'UPDATE_SEARCH_OPTION',
      searchOption: opts,
    });
  }

  function onApplyFilters(opts) {
    // I just need to convert the IDs back to string because
    // the prop types for PaymentsFilterModal are expecting it as string.
    onChangeSearchOption({
      ...state.searchOption,
      page: 1,
      merchant: opts.merchant,
      settledAt: opts.settledAt,
    });
  }

  function resetFiltersToDefault() {
    onChangeSearchOption({
      ...state.searchOption,
      query: null,
      merchant: null,
      settledAt: null,
    });
  }

  function onSearchByQuery(query) {
    if (!query) {
      resetFiltersToDefault();
      return;
    }

    onChangeSearchOption({
      ...state.searchOption,
      query,
      page: 1,
      merchant: null,
      settledAt: moment(null, dateFormat),
    });
  }

  if (appState < APP_STATES.AUTHENTICATED) { return <div />; }

  const columns = [
    {
      title: 'Reference ID',
      key: 'referenceId',
      render: row => (
        <div className="table-col-item">
          <strong>{row.settlementReferenceId}</strong>
        </div>
      ),
    },
    {
      title: 'Merchant',
      key: 'merchant',
      render: row => (
        <div className="table-col-item">{row.merchantName}</div>
      ),
    },
    {
      title: 'Total Amount',
      key: 'payments',
      render: row => (
        <div className="text-primary">
          {row.totalSettlementAmount
            ? `${row.totalSettlementAmount[0]} ${row.totalSettlementAmount[1].toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ',')}`
            : '-----'}
          {row.totalPaymentCount === 1 ? ' (1 payment)' : ` (${row.totalPaymentCount} payments)`}
        </div>
      ),
    },
    {
      title: 'Settled Date',
      key: 'settledDate',
      render: row => (
        <div className="text-primary">
          {row.settledDate ? moment(row.settledDate, 'YYYY-MM-DD').format('L') : '----'}
        </div>
      ),
    },
    {
      title: 'Created Date',
      key: 'createdAt',
      render: row => (
        <div className="text-primary">
          {row.createdAt ? moment(row.createdAt).format('LL') : '----'}
        </div>
      ),
    },
  ];

  const pagination = {
    size: 'small',
    current: state.searchOption.page,
    defaultCurrent: 1,
    defaultPageSize: 10,
    pageSize: state.searchOption.size,
    total: state.searchOption.totalCount,
    showSizeChanger: true,
    pageSizeOptions: ['10', '20', '50', '100'],
    onShowSizeChange: (currentPage, size) => {
      onChangeSearchOption({
        ...state.searchOption,
        page: 1,
        size,
      });
    },
    onChange: (p) => {
      const totalPage = Math.ceil(state.searchOption.totalCount / state.searchOption.size);
      let page = p || 1;
      if (page < 1) { page = 1; }
      if (page > totalPage) { page = totalPage - 1; }

      onChangeSearchOption({
        ...state.searchOption,
        page,
      });
    },
    showTotal: (total, range) => `Showing ${range[0]} - ${range[1]} of ${total}`,
  };

  return (
    <div className="qwikwire">
      <section style={{ display: 'flex', justifyContent: 'space-between' }}>
        <div className="transaction-breadcrumb">
          <div className="transaction-breadcrumb-icon">
            <SettlementReportsIconBig />
          </div>
          <Breadcrumb>
            <Breadcrumb.Item>
              All Settlements
            </Breadcrumb.Item>
          </Breadcrumb>
        </div>
        <NewSettlementSelector history={history} />
      </section>
      <Row
        type="flex"
        justify="space-between"
        align="middle"
        className="filters"
        style={{ marginBottom: '12px' }}
      >
        <Col lg={12}>
          {(() => {
            const { query, settledAt } = searchOption;
            if (query) {
              return (
                <h4 className="text-secondary">
                  {`Showing all settlements for "${query}"`}
                </h4>
              );
            }
            if (settledAt && settledAt.isValid() && state.merchant) {
              return (
                <h4 className="text-secondary">
                  {`Showing settlements for ${state.merchant.name} on ${settledAt.format(displayDateFormat)}`}
                </h4>
              );
            }
            if (settledAt && settledAt.isValid()) {
              return (
                <h4 className="text-secondary">
                  {`Showing settlements on ${settledAt.format(displayDateFormat)}`}
                </h4>
              );
            }
            if (state.merchant) {
              return (
                <h4 className="text-secondary">
                  {`Showing settlements for ${state.merchant.name}`}
                </h4>
              );
            }
            return (
              <h4 className="text-secondary">
                Showing all settlements
              </h4>
            );
          })()}
        </Col>
        <Col lg={12}>
          <Row type="flex" justify="end">
            <Col style={{ marginRight: '16px' }}>
              {!searchOption.query && (
                // Hide the payments filter modal if the search query is available.
                // It will rerender and reset to default upon clear.
                <SettlementsFilterModal
                  onApply={onApplyFilters}
                  searchOption={searchOption}
                />
              )}
            </Col>
            <Col lg={12}>
              <Input.Search
                allowClear
                placeholder="Search by name or ID"
                style={{ width: '100%' }}
                defaultValue={state.searchOption.query}
                onChange={(e) => {
                  if (!e.target.value) { resetFiltersToDefault(); }
                }}
                onSearch={onSearchByQuery}
              />
            </Col>
          </Row>
        </Col>
      </Row>
      {state && state.status && state.message && (
        <div style={{ marginBottom: '12px' }}>
          <Row>
            <Col span={24}>
              <Message
                status={state.status}
                message={state.message}
                onClose={() => dispatch({ type: 'CLEAR_MESSAGE' })}
              />
            </Col>
          </Row>
        </div>
      )}
      <Table
        className="table-standard"
        dataSource={state.settlements}
        loading={state.isFetchingSettlements}
        columns={columns}
        pagination={pagination}
        rowKey="settlementId"
        rowClassName="row-clickable"
        onRow={(row) => {
          const onClick = () => history.push(`/settlements/${row.settlementId}`);
          return { onClick };
        }}
      />
    </div>
  );
}

QwikwireSettlements.propTypes = {
  appState: PropTypes.number.isRequired,
};

export default QwikwireSettlements;
