import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import FileIcon, { defaultStyles } from 'react-file-icon';
import { CloseIcon } from '../Icons';

import './filedisplay.css';

const FilePreview = (props) => {
  const { fileName, url, isRemoveable, onClick, onRemove } = props;

  const searchExtensionResults = /.[a-z]+$/.exec(fileName);
  const fileExtension = searchExtensionResults ? searchExtensionResults[0] : null;
  return (
    <Fragment>
      <div className="file-icon-wrapper">
        <a
          className="file-icon"
          href={url}
          onClick={onClick}
          target="_blank"
          rel="noopener noreferrer"
        >
          <Fragment>
            <FileIcon extension={fileExtension} {...defaultStyles[fileExtension]} />
            <span>{fileName}</span>
          </Fragment>
        </a>
        {isRemoveable && (
          <button type="button" className="delete-button" onClick={onRemove}>
            <CloseIcon />
          </button>
        )}
      </div>
    </Fragment>
  );
};

FilePreview.propTypes = {
  fileName: PropTypes.string.isRequired,
  url: PropTypes.string.isRequired,
  isRemoveable: PropTypes.bool,
  onClick: PropTypes.func,
  onRemove: PropTypes.func,
};

FilePreview.defaultProps = {
  isRemoveable: false,
  onClick: () => {},
  onRemove: () => {},
};

export default FilePreview;
