import moment from 'moment';
import React, { useReducer, useEffect } from 'react';
import PropTypes from 'prop-types';
import qs from 'query-string';
import { Link } from 'react-router-dom';
import {
  Row, Col, Breadcrumb, Table, Select, Input,
  Dropdown, Button, Icon, Menu,
} from 'antd';
import { DisputeIcon } from '../../components/Icons';
import { getMerchantsService, getMerchantById } from '../../services/merchants';
import { getProjectsService } from '../../services/projects';
import { searchDisputes, exportQWDisputesService } from '../../services/disputes';
import { authenticateExportTokenService } from '../../services/transactions';
import { getStatusColor } from '../../helpers/utils';
import { APP_STATES } from '../../constants';
import '../../styles/transaction.css';
import './QwikwireDisputes.scss';

const dateFormat = 'YYYY-MM-DD';
const getInitialState = queryFilters => ({
  isExporting: false,
  isFetchingDisputes: true,
  disputes: [],
  merchants: [],
  merchant: null,
  projects: [],
  searchOption: {
    page: parseInt(queryFilters.page, 10) || 1,
    size: parseInt(queryFilters.size, 10) || 20,
    totalCount: 0,
    startDate: moment(queryFilters.start || null, dateFormat),
    endDate: moment(queryFilters.end || null, dateFormat),
    merchantId: Number(queryFilters.merchant) || null,
    projectId: Number(queryFilters.project) || null,
    query: queryFilters.query || null,
    settlementReferenceId: null,
    status: queryFilters.status ? queryFilters.status.split(',') : ['PENDING'],
  },
});

function reducer(prevState, action) {
  switch (action.type) {
    case 'SET_MERCHANTS':
      return {
        ...prevState,
        merchants: action.merchants,
      };
    case 'SET_MERCHANT':
      return {
        ...prevState,
        merchant: action.merchant,
        searchOption: {
          ...prevState.searchOption,
          merchantId: action.merchant ? action.merchant.id : null,
          totalCount: 0,
        },
      };
    case 'INITIALIZE_FILTERS':
      return {
        ...prevState,
        merchants: action.merchants,
        merchant: (() => {
          if (!prevState.merchant) { return null; }
          return action.merchants.find(m => m.code === prevState.merchant.code);
        })(),
      };
    case 'GET_DISPUTES_SUCCESSFUL':
      return {
        ...prevState,
        isFetchingDisputes: false,
        disputes: action.disputes,
        searchOption: {
          ...prevState.searchOption,
          totalCount: action.totalCount,
        },
      };
    case 'GET_DISPUTES_FAILED':
      return {
        ...prevState,
        isFetchingDisputes: false,
        disputes: [],
        searchOption: {
          ...prevState.searchOption,
          totalCount: 0,
        },
      };
    case 'GET_PROJECTS_SUCCESSFUL':
      return {
        ...prevState,
        projects: action.projects,
      };
    case 'GET_PROJECTS_FAILED':
      return {
        ...prevState,
        projects: [],
      };
    case 'EXPORTING_DISPUTES':
      return {
        ...prevState,
        isExporting: true,
      };
    case 'EXPORTING_DISPUTES_SUCCESSFUL':
      return {
        ...prevState,
        isExporting: false,
      };
    case 'EXPORTING_DISPUTES_FAILED':
      return {
        ...prevState,
        isExporting: false,
      };
    default:
      return prevState;
  }
}

function getFilteredURLPath(path, searchOption) {
  const {
    page, size, status, startDate, endDate, query,
    projectId, merchantId,
  } = searchOption;
  let url = `${path}?page=${page || 1}`;
  if (size) { url += `&size=${size}`; }
  if (startDate && startDate.isValid()) { url += `&start=${startDate.format('YYYY-MM-DD')}`; }
  if (endDate && endDate.isValid()) { url += `&end=${endDate.format('YYYY-MM-DD')}`; }
  if (status) { url += `&status=${status}`; }
  if (projectId) { url += `&project=${projectId}`; }
  if (merchantId) { url += `&merchant=${merchantId}`; }
  if (query) { url += `&query=${query}`; }
  return url;
}

function QwikwireDisputes(props) {
  const { history, location, appState } = props;

  const timeZone = moment.tz.guess(true);
  const queryFilters = qs.parse(location.search, { ignoreQueryPrefix: true });
  const [state, dispatch] = useReducer(reducer, getInitialState(queryFilters));

  useEffect(() => {
    let ableToSet = true;

    (async () => {
      try {
        if (state.searchOption.merchantId) {
          const { data } = await getMerchantById(state.searchOption.merchantId);
          if (ableToSet) {
            dispatch({ type: 'SET_MERCHANT', merchant: data });
            dispatch({ type: 'INITIALIZE_FILTERS', merchants: [data] });
          }
        }
      } catch (error) {
        if (ableToSet) {
          dispatch({ type: 'INITIALIZE_FILTERS', merchants: [] });
        }
      }
    })();

    return () => { ableToSet = false; };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    let ableToSet = true;
    (async () => {
      try {
        const status = state.searchOption.status
          .reduce((pv, cv) => `${pv},${cv}`, '')
          .slice(1);
        const disputesResponse = await searchDisputes({
          ...state.searchOption,
          status,
        });
        const { disputes, totalCount } = disputesResponse.data;

        if (ableToSet) {
          dispatch({
            type: 'GET_DISPUTES_SUCCESSFUL',
            disputes,
            totalCount,
          });
        }
      } catch (error) {
        const message = error && error.response ? error.response.data.message
          : 'We are not able to get the disputes from the server as of the moment';
        if (ableToSet) {
          dispatch({ type: 'GET_DISPUTES_FAILED', message });
        }
      }
    })();

    return () => { ableToSet = false; };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.merchant ? state.merchant.id : null]);

  useEffect(() => {
    let ableToSet = true;
    (async () => {
      try {
        if (!state.searchOption.merchantId) {
          return;
        }

        const merchant = state.merchants.find(m => m.id === state.searchOption.merchantId);
        if (!merchant) {
          return;
        }

        // Current limit for querying the projects
        const opts = { page: 1, size: 150 };
        const projectsRepsonse = await getProjectsService(merchant.id, opts);
        const { projects } = projectsRepsonse.data;

        if (ableToSet) {
          dispatch({
            type: 'GET_PROJECTS_SUCCESSFUL',
            projects,
          });
        }
      } catch (error) {
        const message = error && error.response ? error.response.data.message
          : 'We are not able to get the projects from the server as of the moment';
        if (ableToSet) {
          dispatch({ type: 'GET_PROJECTS_FAILED', message });
        }
      }
    })();

    return () => { ableToSet = false; };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.searchOption.merchantId, state.merchants.length]);

  function onChangeSearchOption(searchOption) {
    const url = getFilteredURLPath(location.pathname, searchOption);
    history.push(url, {});
  }

  if (appState < APP_STATES.AUTHENTICATED) { return <div />; }

  const selectedMerchant = state.merchants.find(m => m.id === state.searchOption.merchantId);
  const selectedProject = state.projects.find(p => p.id === state.searchOption.projectId);
  const columns = [
    {
      title: 'Dispute ID',
      key: 'id',
      render: row => (
        <Link to={`/disputes/${row.id}`}>
          <strong>{row.id}</strong>
        </Link>
      ),
    },
    {
      title: 'Payment ID',
      key: 'paymentId',
      render: row => (
        <Link to={`/payments/${row.disputedPayment.invoiceId}`}>
          <strong>{row.disputedPayment.invoiceId}</strong>
        </Link>
      ),
    },
    {
      title: 'Merchant',
      key: 'merchant',
      render: row => (
        <div>
          <div><strong>{row.disputedPayment.merchantName}</strong></div>
        </div>
      ),
    },
    {
      title: 'Customer',
      key: 'customer',
      render: row => (
        <div>
          <div className="text-secondary">{row.disputedPayment.customerEmail}</div>
        </div>
      ),
    },
    {
      title: 'Status',
      key: 'status',
      render: row => (
        <div>
          <div
            style={{
              fontWeight: 700,
              color: '#ffffff',
              backgroundColor: getStatusColor(row.status),
              borderRadius: '4px',
              padding: '4px 8px',
              width: 'min-content',
            }}
          >
            {row.status}
          </div>
        </div>
      ),
    },
    {
      title: 'Created At',
      key: 'createdAt',
      render: (row) => {
        const dt = row.createdAt;
        return (
          <div className="table-col-item">
            <div className="text-primary">
              {moment(dt).tz(timeZone).format('lll')}
            </div>
          </div>
        );
      },
    },
  ];

  const pagination = {
    size: 'small',
    current: state.searchOption.page,
    defaultCurrent: 1,
    defaultPageSize: 10,
    pageSize: state.searchOption.size,
    total: state.searchOption.totalCount,
    showSizeChanger: true,
    pageSizeOptions: ['10', '20', '50', '100'],
    onShowSizeChange: (currentPage, size) => {
      onChangeSearchOption({
        ...state.searchOption,
        page: 1,
        size,
      });
    },
    onChange: (p) => {
      const totalPage = Math.ceil(state.searchOption.totalCount / state.searchOption.size);
      let page = p || 1;
      if (page < 1) { page = 1; }
      if (page > totalPage) { page = totalPage - 1; }

      onChangeSearchOption({
        ...state.searchOption,
        page,
      });
    },
    showTotal: (total, range) => `Showing ${range[0]} - ${range[1]} of ${total}`,
  };

  async function exportDisputes() {
    dispatch({ type: 'EXPORTING_DISPUTES' });
    try {
      const { data: { exportToken } } = await authenticateExportTokenService();
      exportQWDisputesService(state.searchOption, exportToken);
      dispatch({ type: 'EXPORTING_DISPUTES_SUCCESSFUL' });
    } catch (err) {
      const message = err && err.response
        ? err.response.data.message
        : 'Unable to export disputes data as of the moment';
      dispatch({ type: 'EXPORTING_DISPUTES_FAILED', message });
    }
  }

  function onSelectMerchant(merchantId) {
    if (!merchantId) {
      dispatch({ type: 'SET_MERCHANT', merchant: null });
      onChangeSearchOption({
        ...state.searchOption,
        merchantId,
        page: 1,
      });
    } else {
      const foundMerchant = state.merchants.find(ps => ps.id === merchantId);
      dispatch({ type: 'SET_MERCHANT', merchant: foundMerchant });
      onChangeSearchOption({
        ...state.searchOption,
        merchantId: foundMerchant.id,
        page: 1,
      });
    }
  }

  let timeout;
  let currentValue;

  async function onSearchMerchant(merchantCode) {
    if (!merchantCode) {
      dispatch({ type: 'SET_MERCHANT', merchant: null });
      return;
    }

    if (timeout) {
      clearTimeout(timeout);
      timeout = null;
    }

    currentValue = merchantCode;
    async function searchMerchant() {
      if (timeout) {
        clearTimeout(timeout);
        timeout = null;
      }
      try {
        if (state.merchant) {
          dispatch({
            type: 'INITIALIZE_FILTERS',
            merchants: [state.merchant],
          });
          return;
        }

        let searchOptions = {
          size: 20,
          page: 1,
        };

        if (merchantCode) {
          searchOptions = {
            query: merchantCode,
            size: 20,
            page: 1,
          };
        }

        const { data } = await getMerchantsService(searchOptions);
        if (currentValue === merchantCode) {
          dispatch({ type: 'INITIALIZE_FILTERS', merchants: data.merchants });
        }
      } catch (error) {
        dispatch({ type: 'INITIALIZE_FILTERS', merchants: [] });
      }
    }

    // Set timeout to minimize flooding the server with API calls when
    // searching for merchants
    timeout = setTimeout(searchMerchant, 400);
  }

  const menu = (
    <Menu>
      <Menu.Item key="download" onClick={() => exportDisputes()}>
        <Icon type="download" />
        Export to CSV
      </Menu.Item>
    </Menu>
  );

  if (appState < APP_STATES.AUTHENTICATED) { return <div />; }
  return (
    <div className="qwikwire">
      <section style={{ display: 'flex', justifyContent: 'space-between' }}>
        <div className="transaction-breadcrumb">
          <div className="transaction-breadcrumb-icon">
            <DisputeIcon />
          </div>
          <Breadcrumb>
            <Breadcrumb.Item>
              Disputes
            </Breadcrumb.Item>
          </Breadcrumb>
        </div>
        <div className="action-container">
          <Dropdown overlay={menu} trigger={['click']}>
            <Button
              type="primary"
              className="button button-standard button-standard-outline button-small"
            >
              Actions
              <Icon type="down" />
            </Button>
          </Dropdown>
        </div>
        {/* <div className="action-container">
          <Button
            className="button button-standard button-standard-outline"
            onClick={exportDisputes}
            loading={state.isExporting}
          >
            <Icon type="download" />
            Download
          </Button>
        </div> */}
      </section>
      <Row gutter={8} className="filters" style={{ marginBottom: '12px' }}>
        <Col lg={6}>
          <h4 className="text-secondary">Merchant</h4>
          <Select
            className="transaction-filter"
            style={{ width: '100%' }}
            allowClear
            showArrow={false}
            showSearch
            placeholder="Select a merchant"
            disabled={state.isFetchingDisputes}
            defaultActiveFirstOption={false}
            onSearch={onSearchMerchant}
            onChange={onSelectMerchant}
            filterOption={(inputValue, option) => {
              const { props: { children } } = option;
              return children.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1;
            }}
            value={selectedMerchant ? selectedMerchant.id : undefined}
            notFoundContent={null}
          >
            {state.merchants.map(m => (
              <Select.Option value={m.id} key={m.id} label={m.name}>
                {m.name}
              </Select.Option>
            ))}
          </Select>
        </Col>
        <Col lg={6}>
          <h4 className="text-secondary">Project</h4>
          <Select
            className="transaction-filter"
            style={{ width: '100%' }}
            allowClear
            showArrow={false}
            showSearch
            placeholder="Select a project"
            disabled={state.isFetchingDisputes}
            onSelect={(pid) => {
              const project = state.projects.find(ps => ps.id === Number(pid));
              if (project) {
                onChangeSearchOption({
                  ...state.searchOption,
                  projectId: project.id,
                  page: 1,
                });
              }
            }}
            onChange={(sp) => {
              if (!sp) {
                onChangeSearchOption({
                  ...state.searchOption,
                  projectId: null,
                  page: 1,
                });
              }
            }}
            filterOption={(inputValue, option) => {
              const { props: { children } } = option;
              return children.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1;
            }}
            value={selectedProject ? selectedProject.id : undefined}
          >
            {state.projects.map(p => (
              <Select.Option value={p.id} key={p.code} label={p.name}>
                {p.name}
              </Select.Option>
            ))}
          </Select>
        </Col>
        <Col lg={6}>
          <h4 className="text-secondary">Status</h4>
          <Select
            className="transaction-filter"
            mode="multiple"
            style={{ width: '100%' }}
            placeholder="Status"
            disabled={state.isFetchingDisputes}
            defaultValue={state.searchOption.status}
            onChange={(status) => {
              onChangeSearchOption({
                ...state.searchOption,
                status,
                page: 1,
              });
            }}
          >
            <Select.Option key="PENDING">Pending</Select.Option>
            <Select.Option key="WON">Won</Select.Option>
            <Select.Option key="LOST">Lost</Select.Option>
          </Select>
        </Col>
        <Col lg={6}>
          <h4 className="text-secondary">Search by Reference ID or Name</h4>
          <Input.Search
            allowClear
            className="transaction-filter"
            placeholder="QW-P-0000000 or Customer Name"
            style={{ width: '100%' }}
            defaultValue={state.searchOption.query}
            onSearch={(query) => {
              onChangeSearchOption({
                ...state.searchOption,
                query,
                page: 1,
              });
            }}
          />
        </Col>
      </Row>
      <Table
        className="table-standard"
        dataSource={state.disputes}
        loading={state.isFetchingDisputes}
        columns={columns}
        pagination={pagination}
        rowKey="disputeId"
      />
    </div>
  );
}

QwikwireDisputes.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }).isRequired,
  location: PropTypes.shape({
    pathname: PropTypes.string.isRequired,
    search: PropTypes.string.isRequired,
  }).isRequired,
  appState: PropTypes.number.isRequired,
};

export default QwikwireDisputes;
