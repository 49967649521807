import React from 'react';
import PropTypes from 'prop-types';
import { useLocation, useRouteMatch, useHistory } from 'react-router-dom';
import { SingleForm } from '../../components';
import QueryBasedPaymentsTable from '../../components/QueryBasedPaymentsTable';
import { APP_STATES } from '../../constants';
import '../../styles/transaction.css';

const dateFormat = 'YYYY-MM-DD';

function getFilteredURLPath(path, searchOption) {
  const {
    page, size, status, startDate, endDate, query,
    project, paymentMethod, paymentType, transactionType,
  } = searchOption;
  let url = `${path}?page=${page || 1}`;
  if (size) { url += `&size=${size}`; }

  if (query) {
    url += `&query=${query}`;
  } else {
    if (startDate && startDate.isValid()) { url += `&start=${startDate.format(dateFormat)}`; }
    if (endDate && endDate.isValid()) { url += `&end=${endDate.format(dateFormat)}`; }
    if (status) { url += `&status=${status}`; }
    if (paymentMethod) { url += `&method=${paymentMethod}`; }
    if (transactionType) { url += `&transactionType=${transactionType}`; }
    if (project) { url += `&project=${project}`; }
    if (paymentType) { url += `&paymentType=${paymentType}`; }
  }
  return url;
}

const Payments = (props) => {
  const { appState, merchants } = props;
  const match = useRouteMatch();
  const location = useLocation();
  const history = useHistory();
  const { merchantCode, settlementReferenceId } = match.params;
  const merchant = merchants.find(m => m.code === merchantCode);

  if (!merchant) {
    return (
      <SingleForm
        title="Merchant does not exist"
        subtitle="The page you requested could not be found. Please go back to Dashboard or contact us at support@aqwire.io."
        footer={{ show: true, to: '/', text: 'Back to Dashboard' }}
      />
    );
  }

  if (appState < APP_STATES.AUTHENTICATED) { return <div />; }

  return (
    <div>
      <QueryBasedPaymentsTable
        merchant={merchant}
        location={location}
        history={history}
        filters={{
          settlementReferenceId,
          showFilterFields: true,
          showSettlementColumn: true,
          showStatusMenu: true,
        }}
        onChangeSearchOption={(searchOption) => {
          const url = getFilteredURLPath(location.pathname, searchOption);
          history.push(url, {});
        }}
      />
    </div>
  );
};

Payments.propTypes = {
  appState: PropTypes.number.isRequired,
  merchants: PropTypes.arrayOf(PropTypes.any).isRequired,
};

export default Payments;
