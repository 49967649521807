import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { Row, Col, Breadcrumb, List } from 'antd';
import SingleForm from '../../components/SingleForm';
import { PieChartIcon, FolderIcon } from '../../components/Icons';
import SessionContext from '../../contexts/SessionContext';

/**
 * @param {{
 *   match: { params: { merchantCode: String } }
 * }} props
 */
function Reports(props) {
  const { match } = props;
  const { merchantCode } = match.params;
  const sessionContext = useContext(SessionContext);
  const { merchants, loggedInUser } = sessionContext;
  const merchant = merchants.find(m => m.code === merchantCode);

  const { systemRole, merchantRoles } = loggedInUser;
  const { merchantRole } = merchantRoles.find(m => m.merchantCode === merchantCode);

  if (!merchant) {
    return (
      <SingleForm
        title="Merchant does not exist"
        subtitle="The page you requested could not be found. Please go back to Dashboard or contact us at support@aqwire.io."
        footer={{ show: false }}
      />
    );
  }
  if (systemRole[0] >= 80
    || (systemRole[0] === 70 && merchantRole[0] > 10)
    || !merchant
    || !merchant.canAccessReports
  ) {
    return (
      <div>
        <div className="spinner">
          <FolderIcon />
          <h1>You are not allowed to access the merchant settings page.</h1>
          <p>
            You can contact us at
            <a href="mailto:support@aqwire.io">support@aqwire.io</a>
            if you have concerns or issues.
          </p>
        </div>
      </div>
    );
  }

  const reports = [
    {
      title: 'Summary of all payments by project and payment type',
      description: 'Total number of payments grouped by payment type and project based on dates',
      to: `/merchants/${merchant.code}/reports/payments`,
    },
    {
      title: 'Summary of payment issuers',
      description: 'List of payment issuers based on date range',
      to: `/merchants/${merchant.code}/reports/issuers`,
    },
  ];

  return (
    <div>
      <Row className="breadcrumb-row">
        <Col className="breadcrumb-header">
          <Breadcrumb className="breadcrumb-parent">
            <Breadcrumb.Item className="item-container">
              <div className="breadcrumb-item breadcrumb-icon">
                <PieChartIcon diameter="28px" />
              </div>
              <div className="breadcrumb-item icon-label">
                Reports
              </div>
            </Breadcrumb.Item>
          </Breadcrumb>
        </Col>
      </Row>
      <section className="panel panel-standard">
        <div className="panel-body">
          <List
            itemLayout="horizontal"
            dataSource={reports}
            renderItem={item => (
              <List.Item>
                <div style={{ paddingTop: '12px' }}>
                  <Link to={item.to} alt={item.title}>
                    <h3>{item.title}</h3>
                  </Link>
                  <div className="panel-item">{item.description}</div>
                </div>
              </List.Item>
            )}
          />
        </div>
      </section>
    </div>
  );
}

Reports.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      merchantCode: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
};

export default Reports;
