import React, { Fragment, useContext, useReducer, useState } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import {
  Button, Form, Input, Spin, Row, Col, Breadcrumb,
} from 'antd';
import { TagIcon } from '../../components/Icons';
import PageMessage from '../../components/PageMessage';
import SessionContext from '../../contexts/SessionContext';
import { createMerchantProjectService } from '../../services/merchants';
import getValidationErrors from '../../helpers/forms';
import './createmerchantproject.scss';
import Message from '../../components/Message';


const getInitialState = () => ({
  fieldErrors: {},
  project: {
    name: null,
    category: null,
    description: null,
  },
});

function reducer(prevState, action) {
  switch (action.type) {
    case 'SET_PROJECT':
      return {
        ...prevState,
        project: { ...action.project },
      };
    case 'SET_FIELD_ERRORS':
      return {
        ...prevState,
        fieldErrors: {
          ...prevState.fieldErrors,
          [action.key]: action.errors,
        },
      };
    default:
      return prevState;
  }
}

const CreateMerchantProject = (props) => {
  const { form: { resetFields }, history, match: { params: { merchantCode } } } = props;
  const formItemLayout = { labelCol: { span: 24 }, wrapperCol: { span: 24 } };
  const { merchants, loggedInUser, pushMessage, popMessage } = useContext(SessionContext);
  const [msg, setMsg] = useState(popMessage());
  const merchant = merchants.find(m => m.code === merchantCode);
  const [isFetching, setIsFetching] = useState(false);
  const [state, dispatch] = useReducer(reducer, getInitialState());

  const { systemRole, merchantRoles } = loggedInUser;
  const { merchantRole } = merchantRoles.find(m => m.merchantCode === merchantCode);
  const { TextArea } = Input;

  const { handleSubmit, handleChange } = useForm({
    rules: {
      name: [
        { required: true, message: 'Please enter the project name.' },
        { max: 150, message: 'Project name has too many characters' },
      ],
      category: [
        { max: 100, message: 'Project category has too many characters' },
      ],
      description: [
        { max: 255, message: 'Project description has too many characters' },
      ],
    },
  });

  function useForm(options) {
    const _validate = (value, key) => {
      const newErrors = state.fieldErrors;
      const rules = options?.rules[key];
      if (rules) {
        newErrors[key] = getValidationErrors(rules, value);
        if (!newErrors[key].length) {
          newErrors[key] = null;
        }
      }
      dispatch({
        type: 'SET_FIELD_ERRORS',
        key,
        errors: newErrors[key],
      });
    };

    // eslint-disable-next-line no-shadow
    const handleSubmit = async (e) => {
      e.preventDefault();
      const { project } = state;
      const errors = state.fieldErrors;
      const projectKeys = Object.keys(project);
      projectKeys.forEach(k => _validate(project[k], k));
      const keys = Object.keys(errors);
      if (keys.every(k => !errors[k])) {
        createMerchantProject({
          merchantId: merchant.id,
          ...project,
        });
      }
    };

    // eslint-disable-next-line no-shadow
    const handleChange = key => (
      e,
    ) => {
      const { value } = e.target;
      const project = { ...state.project };
      project[key] = value;
      dispatch({
        type: 'SET_PROJECT',
        project,
      });
      _validate(value, key);
    };

    return {
      handleSubmit,
      handleChange,
    };
  }

  if (!(loggedInUser && merchants.length)) { return <div />; }
  if (systemRole[0] > 70 || merchantRole[0] !== 10) {
    return (
      <PageMessage
        title="Forbidden"
        description="You are not allowed to access this page"
      />
    );
  }
  if (!merchant) {
    return (
      <PageMessage
        title="Page Not Found"
        description="This merchant does not exist"
      />
    );
  }
  if (!merchants.length) {
    return (
      <div className="spinner">
        <Spin />
        <span className="spinner-text">Loading...</span>
      </div>
    );
  }

  async function createMerchantProject(values) {
    try {
      setIsFetching(true);
      const response = await createMerchantProjectService(values);
      const { data: { message, projectId } } = response;
      setIsFetching(false);
      const alertMessage = { component: 'Project', status: 'success', message };
      pushMessage(alertMessage);
      history.push(`/merchants/${merchantCode}/projects/${projectId}`);
    } catch (error) {
      const message = error.response && error.response.data
        ? error.response.data.message
        : 'Unable to create merchant project as of the moment';
      const alertMessage = { component: 'CreateMerchantProject', status: 'error', message };
      setMsg(alertMessage);
      pushMessage(alertMessage);
      setIsFetching(false);
    }
  }

  function cancel() {
    resetFields();
    history.goBack();
  }

  function getFormFieldErrors(errorsList) {
    if (!errorsList || errorsList.length === 0) {
      return {};
    }
    // re-map errors list for it to have keys
    const mappedErrors = errorsList.map((e, idx) => ({ err: e, key: idx }));
    return {
      validateStatus: 'error',
      help: (mappedErrors || []).map(e => <p className="error-msg" key={e.key}>{e.err}</p>),
    };
  }

  return (
    <div>
      <Fragment>
        {msg && msg.status && msg.message && (
          <div style={{ marginBottom: '8px' }}>
            <Row>
              <Col span={24}>
                <Message
                  message={msg.message}
                  status={['error', 'success'].includes(msg.status) ? msg.status : 'info'}
                  showIcon
                  closable
                  onClose={() => setMsg(null)}
                />
              </Col>
            </Row>
          </div>
        )}
        <Row>
          <Col className="breadcrumb-header">
            <Breadcrumb className="breadcrumb-parent">
              <Breadcrumb.Item className="item-container">
                <div className="breadcrumb-item breadcrumb-icon">
                  <TagIcon />
                </div>
                <div className="breadcrumb-item icon-label">
                  Add Project
                </div>
              </Breadcrumb.Item>
            </Breadcrumb>
          </Col>
        </Row>
        <Row gutter={32}>
          <Col span={12} lg={12} md={24} sm={24} xs={24}>
            <Form
              id="detailsForm"
              onSubmit={handleSubmit}
              onReset={() => cancel()}
              className="new-merchant-project-form"
            >
              <section className="panel panel-standard">
                <div className="panel-header">
                  <div className="panel-title">Information</div>
                </div>
                <div className="panel-body">
                  <div className="details-body">
                    <Form.Item
                      {...formItemLayout}
                      {...getFormFieldErrors(state.fieldErrors.name)}
                      className="form-item"
                      label="Name"
                    >
                      <Input name="name" onChange={handleChange('name')} className="form-input" />
                    </Form.Item>
                    <Form.Item
                      {...formItemLayout}
                      {...getFormFieldErrors(state.fieldErrors.category)}
                      className="form-item"
                      label="Category"
                    >
                      <Input name="category" onChange={handleChange('category')} className="form-input" />
                    </Form.Item>
                    <Form.Item
                      {...formItemLayout}
                      {...getFormFieldErrors(state.fieldErrors.description)}
                      className="form-item"
                      label="Description"
                    >
                      <TextArea rows={4} name="description" onChange={handleChange('description')} className="form-input" />
                    </Form.Item>
                  </div>
                </div>
                <div className="panel-footer">
                  <Form.Item className="action-buttons">
                    <Button
                      className="button button-standard-outline -padded-right"
                      key="reset"
                      htmlType="reset"
                      type="danger"
                      loading={isFetching}
                      disabled={isFetching}
                    >
                      Cancel
                    </Button>
                    <Button
                      className="button button-standard"
                      key="submit"
                      htmlType="submit"
                      type="primary"
                      loading={isFetching}
                      disabled={isFetching}
                    >
                      Add Project
                    </Button>
                  </Form.Item>
                </div>
              </section>
            </Form>
          </Col>
        </Row>
      </Fragment>
    </div>
  );
};

CreateMerchantProject.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  form: PropTypes.object.isRequired,
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
    goBack: PropTypes.func.isRequired,
  }).isRequired,
  match: PropTypes.shape({
    params: PropTypes.shape({
      merchantCode: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
};

export default withRouter(Form.create()(CreateMerchantProject));
