import React, { Fragment, useContext, useState } from 'react';
import PropTypes from 'prop-types';
import { withRouter, Link } from 'react-router-dom';
import {
  Card, Button, Form, Input, Select, Spin, Icon, Row, Col, Breadcrumb,
} from 'antd';
import { createMerchantMemberService } from '../../services/merchants';
import SessionContext from '../../contexts/SessionContext';
import './createmerchantmember.css';


const formItemLayout = { labelCol: { span: 24 }, wrapperCol: { span: 24 } };
const { Option } = Select;
const CreateMerchantMember = (props) => {
  const { showToast, merchants, activeMerchant, merchantRoles } = useContext(SessionContext);
  const merchant = merchants.find(m => m.code === activeMerchant.code);
  const [merchantRole, setMerchantRole] = useState({
    key: 10,
    label: 'Admin',
    description: 'Merchant administrator. Can manage all merchant information and transactions',
  });
  const [isFetching, setIsFetching] = useState(false);
  const { form, form: { getFieldDecorator }, history } = props;

  if (!(merchantRoles.length && merchants.length)) {
    return (
      <div className="spinner">
        <Spin />
        <span className="spinner-text">Loading...</span>
      </div>
    );
  }

  async function createUser(values) {
    try {
      setIsFetching(true);
      const response = await createMerchantMemberService(activeMerchant.id, values);
      const { data: { message } } = response;
      setIsFetching(false);
      showToast({ type: 'success', message });
      history.push(`/merchants/${activeMerchant.code}/settings?key=members`);
    } catch (error) {
      const message = error.response && error.response.data
        ? error.response.data.message
        : 'Unable to create users as of the moment';
      showToast({ type: 'error', message });
      setIsFetching(false);
    }
  }

  function handleSubmit(e) {
    e.preventDefault();
    form.validateFields((err, values) => {
      if (!err) {
        const selectedMerchantRole = merchantRole.key;

        createUser({
          ...values,
          merchantrole: selectedMerchantRole,
        });
      }
    });
  }

  function onSelectMerchantRole(value) {
    const foundMerchantRole = merchantRoles.find(r => r.key === value);

    setMerchantRole({
      key: value,
      label: foundMerchantRole.label,
      description: foundMerchantRole.description,
    });
  }

  return (
    <div>
      <Fragment>
        <Row>
          <Col className="breadcrumb-header">
            <Breadcrumb separator=">">
              <Breadcrumb.Item>
                <Link to={`/merchants/${activeMerchant.code}/settings?key=members`}>
                  <span>{merchant ? merchant.name : '----'}</span>
                </Link>
              </Breadcrumb.Item>
              <Breadcrumb.Item>
                <span>Add Member</span>
              </Breadcrumb.Item>
            </Breadcrumb>
          </Col>
        </Row>
        <Row gutter={32}>
          <Col span={12}>
            <Card className="card-standard" title="User Details">
              <Form id="detailsForm" onSubmit={handleSubmit}>
                <div className="details-body">
                  {/* <Form.Item {...formItemLayout} className="name-input" label="Given Name">
                    {getFieldDecorator('firstname', {
                      rules: [{ required: true, message: 'Please enter the given name.' }],
                    })(<Input />)}
                  </Form.Item>
                  <Form.Item {...formItemLayout} className="name-input" label="Family Name">
                    {getFieldDecorator('lastname', {
                      rules: [{ required: true, message: 'Please enter the family name.' }],
                    })(<Input />)}
                  </Form.Item> */}
                  <Form.Item {...formItemLayout} className="email-input" label="Email Address">
                    {getFieldDecorator('email', {
                      rules: [
                        { required: true, message: 'This field is required.' },
                        { type: 'email', message: 'Please provide a valid email.' },
                      ],
                    })(<Input type="email" />)}
                  </Form.Item>
                  <div className="dropdown-label">
                    Set the role for this user
                  </div>
                  <div className="dropdown-role">
                    <Select
                      className="role-select"
                      defaultValue={merchantRoles[0].key}
                      onChange={onSelectMerchantRole}
                      suffixIcon={<Icon type="caret-down" />}
                    >
                      {merchantRoles.map(r => (
                        <Option key={r.key} value={r.key}>
                          {r.label}
                        </Option>
                      ))}
                    </Select>
                    {
                      merchantRole && merchantRole.key
                        ? (
                          <div className="role-description">
                            {`${merchantRole.description}`}
                          </div>
                        )
                        : (
                          <div className="role-description">
                            {`${merchantRoles[0].description}`}
                          </div>
                        )
                    }
                  </div>
                  <div className="button-container">
                    <Button
                      className="button button-standard button-standard-outline"
                      form="detailsForm"
                      loading={isFetching}
                      onClick={() => history.push(`/merchants/${activeMerchant.code}/settings?key=members`)}
                    >
                      Back
                    </Button>
                    <Button
                      className="button button-standard"
                      form="detailsForm"
                      key="submit"
                      htmlType="submit"
                      type="primary"
                      loading={isFetching}
                    >
                      Add User
                    </Button>
                  </div>
                </div>
              </Form>
            </Card>
          </Col>
          {/* <Col span={12}>
            <Card className="card-standard" title="Permission">
            </Card>
          </Col> */}
        </Row>
      </Fragment>
    </div>
  );
};

CreateMerchantMember.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  form: PropTypes.object.isRequired,
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }).isRequired,
};

export default withRouter(Form.create()(CreateMerchantMember));
