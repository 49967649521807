import React, { useReducer, Fragment } from 'react';
import PropTypes from 'prop-types';
import {
  Form, Input, Button, Select, Modal, Row, Col, Icon, Alert,
} from 'antd';
import { refundPayment } from '../../services/payments';
import '../../styles/modal.scss';

const initialState = {
  isRefundModalVisible: false,
  isReasonNotesRequired: false,
  isRefundSaving: false,
  status: null,
  message: null,
};

function reducer(prevState, action) {
  switch (action.type) {
    case 'SHOW_MODAL':
      return {
        ...prevState,
        isRefundModalVisible: true,
      };
    case 'HIDE_MODAL':
      return {
        ...prevState,
        isRefundModalVisible: false,
      };
    case 'REASON_REQUIRED':
      return {
        ...prevState,
        isReasonNotesRequired: true,
      };
    case 'REASON_NOT_REQUIRED':
      return {
        ...prevState,
        isReasonNotesRequired: false,
      };
    case 'SET_REFUND':
      return {
        ...prevState,
        isRefundSaving: true,
      };
    case 'SET_REFUND_SUCCESS':
      return {
        ...prevState,
        isRefundSaving: false,
      };
    case 'SET_REFUND_FAILED':
      return {
        ...prevState,
        isRefundSaving: false,
        message: action.message,
        status: 'error',
      };
    case 'CLEAR_MESSAGE':
      return {
        ...prevState,
        message: null,
        status: null,
      };
    default:
      return prevState;
  }
}

const RefundModal = (props) => {
  const { form, payment, history } = props;
  const { merchantCode, paymentReferenceId } = payment;
  const [state, dispatch] = useReducer(reducer, initialState);

  const { Option } = Select;
  const { TextArea } = Input;

  const refundablePaymentsStatus = ['SETTLED', 'PAID'];

  function onSubmit(e) {
    e.preventDefault();
    form.validateFields(async (err, values) => {
      dispatch({ type: 'SET_REFUND' });

      if (err) {
        dispatch({
          type: 'SET_REFUND_FAILED',
          message: 'Please double check the errors on the refund form',
        });
        return;
      }

      const { refundReason, refundNotes } = values;

      const refundTotalAmount = Number(values.refundTotalAmount);
      if (Number.isNaN(refundTotalAmount)) {
        dispatch({
          type: 'SET_REFUND_FAILED',
          message: 'The refund amount is not a number',
        });
        return;
      }

      if (refundTotalAmount < 1) {
        dispatch({
          type: 'SET_REFUND_FAILED',
          message: 'Refund amount must be greater than 1',
        });
        return;
      }

      if (refundTotalAmount > payment.billTotal[1]) {
        dispatch({
          type: 'SET_REFUND_FAILED',
          message: 'Refund amount must be less than the total amount due',
        });
        return;
      }

      try {
        const data = {
          refundTotalCurrency: payment.billTotal[0],
          refundTotalAmount,
          refundReason,
          refundNotes,
        };
        const response = await refundPayment(merchantCode, paymentReferenceId, data);
        const { message } = response.data;

        dispatch({ type: 'SET_REFUND_SUCCESS', message });
        history.push(`/payments/${payment.invoiceId}`);
      } catch (error) {
        const message = error && error.response
          ? error.response.data.message
          : 'Unable to set payment status to refunded as of the moment';

        dispatch({ type: 'SET_REFUND_FAILED', message });
      }
    });
  }

  if (!refundablePaymentsStatus.includes(payment.paymentStatus)) {
    return <Fragment />;
  }

  return (
    <div className="enrollment-actions-center">
      <Button
        className="button button-standard button-standard-outline"
        onClick={() => dispatch({ type: 'SHOW_MODAL' })}
      >
        Refund
      </Button>
      <Modal
        title="Refund Payment"
        className="dialog-modal"
        visible={state.isRefundModalVisible}
        onCancel={() => dispatch({ type: 'HIDE_MODAL' })}
        footer={[
          <Button
            key="cancel"
            onClick={() => dispatch({ type: 'HIDE_MODAL' })}
          >
            Cancel
          </Button>,
          <Button
            key="submit"
            type="primary"
            htmlType="submit"
            loading={state.isRefundSaving}
            onClick={onSubmit}
          >
            Refund
          </Button>,
        ]}
      >
        <Row>
          <Col span={3}>
            <Icon type="info-circle" style={{ color: '#399DE5', fontSize: 30, paddingLeft: 10 }} />
          </Col>
          <Col span={21}>
            <p>This feature is only for recording purposes right now. It does not automatically process the refund from the payment processor.</p>
          </Col>
        </Row>
        {state.status && state.message && (
          <Alert
            style={{ marginBottom: '12px' }}
            type={state.status}
            description={state.message}
            closable
            onClose={() => dispatch({ type: 'CLEAR_MESSAGE' })}
          />
        )}
        <Form onSubmit={onSubmit}>
          <Form.Item label="Refund amount">
            {form.getFieldDecorator('refundTotalAmount', {
              rules: [{ required: true, message: 'Refund amount must be a number' }],
              initialValue: payment.billTotal[1],
            })(<Input
              type="number"
              prefix={payment.billTotal[0]}
            />)}
          </Form.Item>
          <Form.Item label="Reason">
            {form.getFieldDecorator('refundReason', {
              initialValue: 'Fraudulent',
            })(
              <Select
                onSelect={(selectedReason) => {
                  if (selectedReason === 'Others') {
                    dispatch({ type: 'REASON_REQUIRED' });
                  } else {
                    dispatch({ type: 'REASON_NOT_REQUIRED' });
                  }
                }}
              >
                <Option value="Fraudulent">Fraudulent</Option>
                <Option value="Duplicate">Duplicate</Option>
                <Option value="Requested By Customer">Requested By Customer</Option>
                <Option value="Others">Others</Option>
              </Select>,
            )}
          </Form.Item>
          <Form.Item>
            {form.getFieldDecorator('refundNotes', {
              rules: [{ required: state.isReasonNotesRequired, message: 'Please specify the reason.' }],
            })(
              <TextArea autoSize={{ maxRows: 8, minRows: 3 }} allowClear />,
            )}
          </Form.Item>
        </Form>
      </Modal>
    </div>
  );
};

RefundModal.propTypes = {
  form: PropTypes.shape({
    getFieldDecorator: PropTypes.func.isRequired,
    validateFields: PropTypes.func.isRequired,
  }).isRequired,
  payment: PropTypes.shape({
    invoiceId: PropTypes.string.isRequired,
    merchantCode: PropTypes.string.isRequired,
    paymentReferenceId: PropTypes.string,
    billTotal: PropTypes.arrayOf(PropTypes.any).isRequired,
    billConverted: PropTypes.arrayOf(PropTypes.any).isRequired,
    paymentStatus: PropTypes.string.isRequired,
  }).isRequired,
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }).isRequired,
};

export default Form.create()(RefundModal);
