import api from '../helpers/apiClient';

export function loginService(email, password, clientId) {
  return api.post('/login', { email, password, clientId });
}

export function checkAuthService() {
  return api.get('/check-auth');
}

export function deleteTokenService(data) {
  return api.delete('/auth/token', { data });
}

export function decodeEmailService(encodedEmail) {
  return api.get('/verify-signup-url', { params: { data: encodedEmail } });
}

export function signupService(encodedEmail, userid, password, firstname, lastname) {
  return api.post(`/signup/${userid}`, { encodedEmail, password, firstname, lastname });
}

export function getAccountInfoService() {
  return api.get('/account/info');
}

export function changePassword({ currentPassword, newPassword }) {
  return api.post('/account/password', { currentPassword, newPassword });
}

export function requestResetTokenService(email) {
  return api.post('/reset-token', { email });
}

export function verifyResetTokenService(resetId, token) {
  return api.post(`/password-reset/${resetId}/verify`, { token });
}

export function resetPasswordService(resetId, token, newPassword) {
  return api.post(`/password-reset/${resetId}/reset`, { token, new_password: newPassword });
}

export function updateUserDetails({ firstname, lastname, email }) {
  return api.post('/account/info', { firstname, lastname, email });
}

export function updateUserNotifications(canReceiveDailyTransactionEmails, canReceiveSettlementEmails) {
  return api.post('/account/notif', { can_receive_daily_transaction_emails: canReceiveDailyTransactionEmails, can_receive_settlement_emails: canReceiveSettlementEmails });
}
