import api from '../helpers/apiClient';

/**
 * @param {number} merchantId
 */
export function getPaymentLinkForm(merchantId) {
  return api.get(`/merchants/${merchantId}/payment-links`);
}

/**
 * @param {number} merchantId
 * @param {{}} paymentLinkData
 */
export function createPaymentLinkService(merchantId, paymentLinkData) {
  return api.post(`/merchants/${merchantId}/payment-links`, paymentLinkData);
}

/**
 * @param {number} merchantId
 * @param {number} invoiceId
 */
export function cancelPaymentLinkService(merchantId, invoiceId) {
  return api.post(`/merchants/${merchantId}/payment-links/${invoiceId}/cancel`);
}

/**
 * @param {number} merchantId
 * @param {number} invoiceId
 * @param {string} email
 */
export function sendPaymentLinkEmailService(merchantId, invoiceId, email) {
  return api.post(`/merchants/${merchantId}/payment-links/${invoiceId}/send-email`, {
    email,
  });
}
