import api from '../helpers/apiClient';

export function searchSettlements(searchOption) {
  const { page, size, merchant, settledAt, query } = searchOption;
  return api.get('/settlements', {
    params: {
      page,
      size,
      reportdate: settledAt && settledAt.isValid() ? settledAt.format('YYYY-MM-DD') : undefined,
      mid: merchant ? merchant.id : undefined,
      query,
    },
  });
}

export function findSettlementById(settlementId) {
  return api.get(`/settlements/${settlementId}`);
}

/**
 * @param {{ code: String }} merchant
 * @param {{
 *   invoiceIds: Number[],
 *   settlementFile: { settlementFileId: Number }
 *   settledDate: any,
 *   settlementNotes: String,
 * }} data
 */
export function createSettlementService(merchantId, data) {
  return api.post(`/merchants/${merchantId}/settlements`, data);
}


export function updateSettlementPaymentsService(merchantCode, settlementReferenceId, invoiceIds) {
  return api.post(`/merchants/${merchantCode}/settlements/${settlementReferenceId}/payments`, {
    invoiceIds,
  });
}


export function getSettlementsService(merchantId, searchOption) {
  // The settled date is passed into the reported date on the search options
  //  the reason is because they have similar attributes
  //  and to avoid creating a lot of properties in the search option model
  const { page, size, settledAt, query } = searchOption;
  return api.get(`/merchants/${merchantId}/settlements`, {
    params: {
      page,
      size,
      reportdate: settledAt && settledAt.isValid() ? settledAt.format('YYYY-MM-DD') : undefined,
      query,
    },
  });
}


export function getSettlementService(merchantId, settlementRefId) {
  return api.get(`/merchants/${merchantId}/settlements/${settlementRefId}`);
}


export function updateSettlementService(merchantCode, settlementRefId, dateSettled) {
  return api.post(`/merchants/${merchantCode}/settlements/${settlementRefId}`, { date_settled: dateSettled });
}


export function deleteSettlementService(merchantId, settlementRefId) {
  return api.delete(`/merchants/${merchantId}/settlements/${settlementRefId}`);
}


export function getPresignedUploadUrl(merchantId, file) {
  return api.post(`/merchants/${merchantId}/settlements/files/upload/presigned-url`, {
    filename: file.name,
    contentType: file.type,
    contentLength: file.size,
  });
}

export function getPresignedDownloadUrl(merchantId, settlementFileId) {
  return api.get(`/merchants/${merchantId}/settlements/files/${settlementFileId}/presigned-url`);
}


/**
 * @param {{ code: String }} merchant
 * @param {{ uniqueIdentifer: String }} data
 */
export function addSettlementFile(merchantId, data) {
  return api.post(`/merchants/${merchantId}/settlements/files`, data);
}
