import moment from 'moment';
import React, { useContext, useReducer, useEffect } from 'react';
import PropTypes from 'prop-types';
import * as d3 from 'd3';
import { getRevenueSummaryReports } from '../../services/reports';
import SessionContext from '../../contexts/SessionContext';
import { formatNumber } from '../../helpers/utils';
import '../../styles/linechart.css';

// NOTE: These are constant values provided on the stylesheets
const sidebarWidth = 250;
const paddingHorizontal = 64;
const dateFormat = 'YYYY/MM/DD';
const initialState = {
  isFetchingData: true,
  chartWidth: null,
  chartHeight: 360,
  isTooltipVisible: false,
  hoveredPointData: null,
  revenues: null,
  status: null,
};

function reducer(prevState, action) {
  switch (action.type) {
    case 'GET_DATA_SUCCESSFUL':
      return {
        ...prevState,
        isFetchingData: false,
        status: 'success',
        revenues: action.revenues,
      };

    case 'GET_DATA_FAILED':
      return {
        ...prevState,
        isFetchingData: false,
        status: 'error',
        revenues: null,
      };

    case 'ADJUST_CHART_WIDTH':
      return {
        ...prevState,
        chartWidth: action.chartWidth,
      };

    case 'SHOW_POINT_TOOLTIP':
      return {
        ...prevState,
        isTooltipVisible: true,
        hoveredPointData: action.hoveredPointData,
      };

    case 'HIDE_POINT_TOOLTIP':
      return {
        ...prevState,
        isTooltipVisible: false,
        hoveredPointData: null,
      };

    default:
      return prevState;
  }
}

const DashboardLineChart = (props) => {
  const { merchant, title, start, end } = props;
  const { showToast } = useContext(SessionContext);
  const defaultWidth = document.body.scrollWidth - sidebarWidth - paddingHorizontal;
  const [state, dispatch] = useReducer(reducer, initialState);

  useEffect(() => {
    let ableToSet = true;

    function handleResize() {
      dispatch({
        type: 'ADJUST_CHART_WIDTH',
        chartWidth: document.body.scrollWidth - sidebarWidth - paddingHorizontal,
      });
    }

    if (ableToSet) dispatch({ type: 'ADJUST_CHART_WIDTH', chartWidth: defaultWidth - 64 });
    window.addEventListener('resize', handleResize);

    return () => {
      ableToSet = false;
      window.removeEventListener('resize', handleResize);
    };
  }, [defaultWidth]);

  useEffect(() => {
    let ableToSet = true;
    (async () => {
      try {
        const endDate = end ? moment(end, dateFormat) : moment();
        const startDate = start ? moment(start, dateFormat) : moment().subtract(7, 'days');
        const { data: { revenues } } = await getRevenueSummaryReports(merchant.id, { startDate, endDate });
        if (ableToSet) dispatch({ type: 'GET_DATA_SUCCESSFUL', revenues });
      } catch (err) {
        if (ableToSet) dispatch({ type: 'GET_DATA_FAILED' });
        showToast({ type: 'error', message: 'Unable to get the chart data from the server' });
      }
    })();

    return () => { ableToSet = false; };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [start, end]);

  if (!state.revenues) { return <div />; }
  if (state.status === 'error') {
    return <div>Transactions chart is not available</div>;
  }

  const { isTooltipVisible, hoveredPointData, revenues } = state;

  const transactions = revenues.map(r => ({
    transactionDate: moment(r.transactionDate, 'YYYY-MM-DD'),
    baseAmount: r.totalBaseAmount,
    feeAmount: r.totalTransactionFeeAmount,
    chargedAmount: r.totalChargedAmount,
    totalTransactionCount: r.totalTransactionCount,
  })).sort((a, b) => a.transactionDate.diff(b.transactionDate));

  const height = state.chartHeight - 64;
  const width = state.chartWidth - 64;

  const n = transactions.length;
  const xScale = d3.scaleTime()
    .domain([transactions[0].transactionDate.toDate(), transactions[n - 1].transactionDate.toDate()])
    .range([50, width - 50]);
  const yScale = d3.scaleLinear()
    .domain([0, d3.max(transactions, t => t.baseAmount)])
    .range([height - 30, 30]);
  const line = d3.line()
    .x(d => xScale(d.transactionDate))
    .y(d => yScale(d.baseAmount));

  const xTicks = xScale.ticks(n).map((d) => {
    const mdt = moment(d).format('MM/D');
    return (
      <g fill="#D9D9D9" key={mdt} transform={`translate(${xScale(d)},0)`}>
        <text fill="#8C8C8C" x={0} y={height - 8} textAnchor="start">{mdt}</text>
      </g>
    );
  });

  const yTicks = yScale.ticks(8).map(d => (
    <g fill="#D9D9D9" key={d} transform={`translate(0,${yScale(d)})`}>
      <text fill="#8C8C8C" x={50 - 8} y={0} textAnchor="end">{String(d).replace(/000$/, 'k')}</text>
      {d > 0 && <line className="line-tick" x1={50} x2={width} />}
    </g>
  ));

  const total = revenues.reduce((acc, curr) => acc + curr.totalBaseAmount, 0);
  const currency = revenues[0].totalBaseCurrency;

  return (
    <div className="linechart">
      <h4 style={{ display: 'flex', justifyContent: 'space-between' }}>
        <div className="heading-primary">{title}</div>
        <div className="heading-primary">{`${currency} ${formatNumber(total)}`}</div>
      </h4>
      <svg width={width - 5} height={height}>
        <line className="axis" x1={width} x2={50} y1={height - 30} y2={height - 30} />
        <line className="axis" x1={50} x2={50} y1={20} y2={height - 30} />
        <g className="axis-labels">{yTicks}</g>
        {revenues.length <= 20 && <g className="axis-labels">{xTicks}</g>}
        <path className="line" d={line(transactions)} />
        {transactions.map(t => (
          <g
            key={t.transactionDate.toDate()}
            className="tx-point"
            transform={`translate(${xScale(t.transactionDate)},${yScale(t.baseAmount)})`}
          >
            <circle
              r={5}
              onMouseEnter={() => dispatch({ type: 'SHOW_POINT_TOOLTIP', hoveredPointData: t })}
              onMouseLeave={() => dispatch({ type: 'HIDE_POINT_TOOLTIP' })}
            />
          </g>
        ))}
      </svg>
      {(isTooltipVisible && hoveredPointData) && (() => {
        const {
          baseAmount,
          transactionDate,
          chargedAmount,
          totalTransactionCount,
        } = hoveredPointData;
        const xt = xScale(transactionDate);
        const yt = yScale(baseAmount) - height;
        return (
          <div
            className="tooltip"
            style={{
              width: '240px',
              transform: `translate(${xt > width - 240 ? (xt - 240) : xt}px,${yt}px)`,
            }}
          >
            <div className="tooltip-header">
              <div>{`${transactionDate.format('MMM D, YYYY')}`}</div>
              <small>{totalTransactionCount === 1 ? '1 payment' : `${totalTransactionCount} payments`}</small>
            </div>
            <div>{`Base: ${currency} ${formatNumber(baseAmount)}`}</div>
            <div>{`Charged: ${currency} ${formatNumber(chargedAmount)}`}</div>
          </div>
        );
      })()}
    </div>
  );
};

DashboardLineChart.propTypes = {
  merchant: PropTypes.shape({
    id: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
    code: PropTypes.string.isRequired,
  }).isRequired,
  title: PropTypes.string.isRequired,
  start: PropTypes.shape({
    format: PropTypes.func,
  }),
  end: PropTypes.shape({
    format: PropTypes.func,
  }),
};

DashboardLineChart.defaultProps = {
  start: null,
  end: null,
};

export default DashboardLineChart;
