import React, { useCallback, useReducer, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useDropzone } from 'react-dropzone';
import { s3UploadFileService } from '../../services/uploads';
import { getPresignedUploadUrl, getPresignedDownloadUrl, addSettlementFile } from '../../services/settlements';
import './fileupload.css';


const FileUpload = (props) => {
  const { merchant, onUpload, onUploading } = props;
  const [state, dispatch] = useReducer((prevState, action) => {
    switch (action.type) {
      case 'UPLOADING':
        return {
          ...prevState,
          isUploading: true,
        };
      case 'UPLOAD_SUCCESSFUL':
        return {
          ...prevState,
          settlementFile: action.settlementFile,
          isUploading: false,
        };
      case 'UPLOAD_FAILED':
        return {
          ...prevState,
          isUploading: false,
        };
      default:
        return prevState;
    }
  }, {
    hasFiles: false,
    isUploading: false,
    settlementFile: null,
  });

  useEffect(() => {
    if (state.settlementFile) {
      onUpload(state.settlementFile);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.settlementFile]);

  useEffect(() => {
    if (state.isUploading) {
      onUploading();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.isUploading]);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop: useCallback((file) => {
      if (file.length > 0) uploadFile(file[0]);
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []),
  });

  async function uploadFile(file) {
    dispatch({ type: 'UPLOADING' });
    try {
      const presignedUploadUrlResponse = await getPresignedUploadUrl(merchant.id, file);
      const {
        presignedUrl, uniqueIdentifier, url, bucket, domain, directory,
      } = presignedUploadUrlResponse.data;

      await s3UploadFileService(presignedUrl, file);

      const fileData = {
        uniqueIdentifier,
        fileUrl: url,
        fileName: file.name,
        fileType: file.type,
        fileDirectory: directory,
        bucketName: bucket,
        domainName: domain,
      };
      const saveFileResponse = await addSettlementFile(merchant.id, fileData);
      const { settlementFileId } = saveFileResponse.data;

      const getSettlementFileResponse = await getPresignedDownloadUrl(merchant.id, settlementFileId);

      dispatch({
        type: 'UPLOAD_SUCCESSFUL',
        settlementFile: getSettlementFileResponse.data,
      });
    } catch (err) {
      dispatch({ type: 'UPLOAD_FAILED' });
    }
  }

  function getStyles() {
    let string = 'dropzone ';
    if (state.isUploading) string += 'is-uploading ';
    if (isDragActive) string += 'drag-entered ';
    if (state.hasFiles) string += 'has-files ';
    return string;
  }

  return (
    <div {...getRootProps({ className: getStyles() })}>
      <input {...getInputProps()} />
      {
        isDragActive
          ? <p>Drop the file here</p>
          : <p>Drag a file here or click to select files</p>
      }
    </div>
  );
};

FileUpload.propTypes = {
  merchant: PropTypes.shape({
    id: PropTypes.number.isRequired,
    code: PropTypes.string.isRequired,
  }).isRequired,
  onUploading: PropTypes.func,
  onUpload: PropTypes.func,
};

FileUpload.defaultProps = {
  onUploading: () => {},
  onUpload: () => {},
};

export default FileUpload;
