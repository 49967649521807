import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import zxcvbn from 'zxcvbn';
import { Progress } from 'antd';
import { zxcvbnMinScore } from '../../config/index';

import './passwordmeter.css';

const PasswordMeter = (props) => {
  const { password, isPasswordAcceptable } = props;
  const [meter, setMeter] = useState({ feedback: null, percent: 0, status: null });

  useEffect(() => {
    if (!password) {
      setMeter({ feedback: null, percent: 0, status: null });
      isPasswordAcceptable(false);
      return;
    }
    const zxResult = zxcvbn(password);
    const status = zxResult.score < zxcvbnMinScore ? 'exception' : 'success';
    setMeter({
      feedback: zxResult.feedback,
      percent: 20 * (zxResult.score + 1),
      status,
    });
    isPasswordAcceptable(status === 'success');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [password]);

  return (
    <div className="password-meter-feedback">
      <Progress percent={meter.percent} status={meter.status} strokeLinecap="square" />
    </div>
  );
};

PasswordMeter.propTypes = {
  password: PropTypes.string,
  isPasswordAcceptable: PropTypes.func,
};

PasswordMeter.defaultProps = {
  password: null,
  isPasswordAcceptable: () => {},
};

export default PasswordMeter;
