import React, { useReducer } from 'react';
import PropTypes from 'prop-types';
import { Modal, Button, Form, Input, Typography } from 'antd';
import { suspendMerchantService } from '../../services/merchants';
import { getAPIErrorMessage } from '../../helpers/utils';

const getInitialState = () => ({
  text: null,
  isSuspending: false,
  message: null,
  status: null,
});

function reducer(prevState, action) {
  switch (action.type) {
    case 'CHANGE_TEXT':
      return {
        ...prevState,
        text: action.value,
      };
    case 'SUSPEND_MERCHANT':
      return {
        ...prevState,
        isSuspending: true,
        message: null,
        status: null,
      };
    case 'SUSPEND_MERCHANT_SUCCESS':
      return {
        ...prevState,
        isSuspending: false,
        message: null,
        status: 'success',
      };
    case 'SUSPEND_MERCHANT_FAILED':
      return {
        ...prevState,
        isSuspending: false,
        message: action.message,
        status: 'error',
      };
    default:
      return prevState;
  }
}

function SuspendMerchantAccountModal(props) {
  const { isVisible, merchant, onDone, onCancel } = props;
  const [state, dispatch] = useReducer(reducer, getInitialState());

  const word = 'suspend account';

  async function suspendMerchant() {
    dispatch({ type: 'SUSPEND_MERCHANT' });

    if (state.text !== word) {
      dispatch({ type: 'SUSPEND_MERCHANT_FAILED', message: 'The typed word does not match' });
      return;
    }

    try {
      await suspendMerchantService(merchant.id);
      dispatch({ type: 'SUSPEND_MERCHANT_SUCCESS' });
      onDone({ message: `${merchant.name} account has been suspended.`, status: 'success' });
    } catch (error) {
      const message = getAPIErrorMessage(error, 'We are not able to suspend this merchant. Please try again later.');
      dispatch({ type: 'SUSPEND_MERCHANT_FAILED', message });
    }
  }

  return (
    <Modal
      className="dialog-modal"
      visible={isVisible}
      title="Suspend account"
      onCancel={onCancel}
      footer={[
        <Button
          key="cancel-confirm-key"
          onClick={onCancel}
        >
          Cancel
        </Button>,
        <Button
          loading={state.isSuspending}
          key="submit"
          type="danger"
          onClick={() => suspendMerchant()}
        >
          Suspend
        </Button>,
      ]}
    >
      <p style={{ marginBottom: '4px' }}>
        Are you sure you want to suspend
        <strong>{` ${merchant.name} `}</strong>
        from accepting payments?
      </p>
      <p style={{ marginBottom: '4px' }}>
        Type &quot;
        {word}
        &quot; below to confirm suspension:
      </p>
      <Form
        onSubmit={(ev) => {
          ev.preventDefault();
          suspendMerchant();
        }}
      >
        <Form.Item>
          <Input
            type="text"
            placeholder=""
            onChange={e => dispatch({ type: 'CHANGE_TEXT', value: e.target.value })}
          />
          {state.status === 'error' && state.message && (
            <Typography.Text type="danger">{state.message}</Typography.Text>
          )}
        </Form.Item>
      </Form>
    </Modal>
  );
}

SuspendMerchantAccountModal.propTypes = {
  isVisible: PropTypes.bool.isRequired,
  merchant: PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string,
  }),
  onDone: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
};

SuspendMerchantAccountModal.defaultProps = {
  merchant: { id: 0, name: null },
};

export default SuspendMerchantAccountModal;
