import moment from 'moment';
import React, { Fragment, useContext, useReducer, useEffect } from 'react';
import PropTypes from 'prop-types';
import qs from 'query-string';
import { Link } from 'react-router-dom';
import { Col, Row, Breadcrumb, Tabs, Button } from 'antd';
import { SingleForm } from '../../components';
import EnrollmentLogs from '../../components/EnrollmentLogs';
import EnrollmentActions from '../../components/EnrollmentActions';
import EnrollmentInvoices from '../../components/EnrollmentInvoices';
import PaymentMethodDetails from '../../components/PaymentMethodDetails';
import { EnrollmentIcon, FolderIcon, CopyIcon } from '../../components/Icons';
import { findEnrollmentService } from '../../services/enrollments';
import { formatNumber, getStatusColor, parseCustomValue } from '../../helpers/utils';
import SessionContext from '../../contexts/SessionContext';
import '../../styles/panel.css';


const getInitialState = queryFilters => ({
  isFetchingEnrollment: true,
  enrollment: null,
  defaultActiveTab: queryFilters.tab || 'info',
});

function reducer(prevState, action) {
  switch (action.type) {
    case 'GET_ENROLLMENT':
      return {
        ...prevState,
        isFetchingEnrollment: true,
        enrollment: null,
      };

    case 'GET_ENROLLMENT_SUCCESSFUL':
      return {
        ...prevState,
        isFetchingEnrollment: false,
        enrollment: action.enrollment,
      };

    case 'GET_ENROLLMENT_FAILED':
      return {
        ...prevState,
        isFetchingEnrollment: false,
        enrollment: null,
      };

    default:
      return prevState;
  }
}

function getFilteredURLPath(path, searchOption) {
  const { page, size, tab } = searchOption;
  let url = `${path}?page=${page || 1}`;
  if (size) { url += `&size=${size}`; }
  if (tab) { url += `&tab=${tab}`; }
  return url;
}

const Enrollment = (props) => {
  const { match, location, user, history } = props;
  const { externalTransactionId } = match.params;
  const { showToast, merchants } = useContext(SessionContext);
  const merchant = merchants.find(m => m.code === match.params.merchantCode);
  const queryFilters = qs.parse(location.search, { ignoreQueryPrefix: true });
  const [state, dispatch] = useReducer(reducer, getInitialState(queryFilters));

  useEffect(() => {
    let ableToSet = true;
    (async () => {
      if (!merchant) { return; }

      if (ableToSet) { dispatch({ type: 'GET_ENROLLMENT' }); }
      try {
        const { data: enrollment } = await findEnrollmentService(merchant.id, externalTransactionId);
        if (ableToSet) dispatch({ type: 'GET_ENROLLMENT_SUCCESSFUL', enrollment });
      } catch (error) {
        if (ableToSet) {
          const message = error && error.response
            ? error.response.data.message
            : 'Unable to get the enrollment data as of the moment';
          dispatch({ type: 'GET_ENROLLMENT_FAILED' });
          showToast({ type: 'error', message });
        }
      }
    })();
    return () => { ableToSet = false; };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (!merchant) {
    return (
      <SingleForm
        title="Merchant does not exist"
        subtitle="The page you requested could not be found. Please go back to Dashboard or contact us at support@aqwire.io."
        footer={{ show: true, to: '/', text: 'Back to Dashboard' }}
      />
    );
  }

  if (state.isFetchingEnrollment) {
    return (
      <div>
        <div className="spinner">
          <span className="spinner-text">Loading...</span>
        </div>
      </div>
    );
  }

  if (!state.enrollment) {
    // eslint-disable-next-line react/jsx-one-expression-per-line
    const message = <p>You can contact us at <a href="mailto:support@aqwire.io">support@aqwire.io</a> if you have concerns or issues.</p>;
    return (
      <div>
        <div className="spinner">
          <FolderIcon />
          <h1>Enrollment Not Found</h1>
          {message}
        </div>
      </div>
    );
  }

  const { enrollment } = state;
  return (
    <div>
      <Fragment>
        <Row className="breadcrumb-row">
          <Col className="breadcrumb-header">
            <Breadcrumb className="breadcrumb-parent">
              <Breadcrumb.Item className="item-container">
                <div className="breadcrumb-item breadcrumb-icon">
                  <EnrollmentIcon />
                </div>
                <div className="breadcrumb-item icon-label">
                  Enrollments
                </div>
              </Breadcrumb.Item>
            </Breadcrumb>
            <Breadcrumb separator=">">
              <Breadcrumb.Item className="breadcrumb-item">
                <Link to={`/merchants/${merchant.code}/enrollments?${qs.stringify(location.filter)}`}>
                  <span>Enrollments</span>
                </Link>
              </Breadcrumb.Item>
              <Breadcrumb.Item>
                <span>{enrollment ? enrollment.enrollmentReferenceId : '----'}</span>
              </Breadcrumb.Item>
            </Breadcrumb>
          </Col>
        </Row>
        <Tabs defaultActiveKey={state.defaultActiveTab}>
          <Tabs.TabPane tab="Information" key="info">
            <div style={{ margin: '12px 4px 32px' }}>
              <section className="panel panel-standard">
                <div className="panel-header">
                  <Row>
                    <Col lg={6} className="panel-item">
                      <h3>QW ID</h3>
                      <div>{enrollment.enrollmentReferenceId}</div>
                    </Col>
                    <Col lg={6} className="panel-item">
                      <h3>{enrollment.customerName}</h3>
                      <div>{enrollment.customerEmail}</div>
                      {enrollment.customerPhone && <div>{` (${enrollment.customerPhone})`}</div>}
                    </Col>
                    <Col lg={6} className="panel-item">
                      <h3>Enrollment Date</h3>
                      <div>{moment(enrollment.createdAt).format('MMM. DD, YYYY HH:mm')}</div>
                    </Col>
                    <Col lg={6} className="panel-item panel-item-end">
                      <div
                        className="panel-badge"
                        style={{
                          color: '#ffffff',
                          backgroundColor: getStatusColor(enrollment.transactionStatus),
                        }}
                      >
                        {enrollment.transactionStatus}
                      </div>
                    </Col>
                  </Row>
                </div>
                <div className="panel-body">
                  <Row>
                    <Col lg={12}>
                      <div className="panel-item">
                        <h3>Transaction ID</h3>
                        <Link
                          to={`/merchants/${enrollment.merchantCode}/transactions/${enrollment.externalTransactionId}`}
                          title="View transaction"
                        >
                          {enrollment.externalTransactionId}
                        </Link>
                      </div>
                      <div className="panel-item">
                        <h3>{`${enrollment.paymentMethodProcessor === 'BT' ? 'International Enrollment (USD)' : 'Enrollment'} Payment`}</h3>
                        <div>{`${enrollment.paymentTypeName || 'N/A'}`}</div>
                      </div>
                      {enrollment.transactionStatus === 'INC' && enrollment.enrolleeLink && (
                        <div className="panel-item">
                          <h3>Enrollment link</h3>
                          <div className="payment-link">
                            <a href={enrollment ? enrollment.enrolleeLink : ''} target="_blank" rel="noopener noreferrer">
                              <div className="truncate">{enrollment.enrolleeLink}</div>
                            </a>
                            <Button
                              className="copy-button"
                              type="link"
                              onClick={() => navigator.clipboard.writeText(enrollment.enrolleeLink)}
                            >
                              <CopyIcon />
                            </Button>
                          </div>
                        </div>
                      )}
                      <div className="panel-item">
                        <h3>{enrollment.merchantName}</h3>
                        <div style={{ marginBottom: '8px' }}>
                          <div>{enrollment.projectName}</div>
                          {enrollment.projectCategory && <div>{enrollment.projectCategory}</div>}
                        </div>
                        {enrollment.customFields && (
                          <div className="panel-item-cellar">
                            {Object.keys(enrollment.customFields)
                              .map(key => (
                                <div key={key}>
                                  {`${enrollment.customFields[key].label}: ${enrollment.customFields[key].value ? parseCustomValue(enrollment.customFields[key].value) : ''}`}
                                </div>
                              ))}
                          </div>
                        )}
                      </div>
                      <div className="panel-item">
                        <h3>Notes</h3>
                        <div>{enrollment.clientNotes || 'There are no notes'}</div>
                      </div>
                    </Col>
                    <Col lg={12}>
                      <div style={{ marginBottom: '12px' }}>
                        <h3>Enrollment Details</h3>
                        <Row>
                          <Col lg={12} className="panel-item">
                            <div>Total Contract Amount</div>
                            <div>{`${enrollment.baseAmount[0]} ${formatNumber(enrollment.baseAmount[1])}`}</div>
                          </Col>
                          <Col lg={12} className="panel-item">
                            <div>Number of Months</div>
                            <div>{`${enrollment.enrollmentMonths} months`}</div>
                          </Col>
                        </Row>
                        <Row>
                          <Col lg={12} className="panel-item">
                            <div>Start Date</div>
                            <div>
                              {((val) => {
                                const datetime = moment(val);
                                if (!datetime.isValid()) { return '--- --, ----'; }
                                return datetime.format('LL');
                              })(enrollment.enrollmentStartDate)}
                            </div>
                          </Col>
                          <Col lg={12} className="panel-item">
                            <div>End Date</div>
                            <div>
                              {((val) => {
                                const datetime = moment(val);
                                if (!datetime.isValid()) { return '--- --, ----'; }
                                return datetime.format('LL');
                              })(enrollment.enrollmentEndDate)}
                            </div>
                          </Col>
                        </Row>
                      </div>
                      {(enrollment.enrollmentComment
                        && ['DECLINED', 'CANCELLED'].indexOf(enrollment.transactionStatus) >= 0)
                        && (
                          <div style={{ marginTop: '20px' }}>
                            <h3 className="card-box-header card-box-header-flex">
                              <span>
                                {((status) => {
                                  switch (status) {
                                    case 'DECLINED': return 'Declined Reason';
                                    case 'CANCELLED': return 'Reason for Cancellation';
                                    default: return 'Comment';
                                  }
                                })(enrollment.transactionStatus)}
                              </span>
                              <span className="text-secondary">
                                {moment(enrollment.updatedAt).format('LLL')}
                              </span>
                            </h3>
                            <div style={{
                              border: '1px solid #e0e0e0',
                              background: '#F9FCFF',
                              padding: '15px',
                              marginTop: '8px',
                            }}
                            >
                              <div className="text-pre-line">
                                {enrollment.enrollmentComment}
                              </div>
                            </div>
                          </div>
                        )}
                    </Col>
                  </Row>
                </div>
                <EnrollmentActions
                  status={enrollment.transactionStatus}
                  enrollmentReferenceId={enrollment.enrollmentReferenceId}
                  enrollmentTransactionId={enrollment.externalTransactionId}
                  merchantCode={enrollment.merchantCode}
                  scheduleFlag={enrollment?.customFields?.scheduleFlag?.value}
                />
              </section>
            </div>
          </Tabs.TabPane>
          <Tabs.TabPane tab="Payment Method" key="method">
            <div style={{ margin: '12px 4px 32px' }}>
              <section className="panel panel-standard">
                <div className="panel-body">
                  <Row>
                    <Col lg={10}>
                      <PaymentMethodDetails payment={enrollment} />
                    </Col>
                  </Row>
                </div>
              </section>
            </div>
          </Tabs.TabPane>
          <Tabs.TabPane tab="Invoices" key="invoices">
            <div style={{ margin: '12px 4px 32px' }}>
              <EnrollmentInvoices
                merchantId={merchant.id}
                merchantCode={merchant.code}
                enrollmentReferenceId={enrollment.enrollmentReferenceId}
                externalTransactionId={enrollment.externalTransactionId}
                layout="Merchant"
              />
            </div>
          </Tabs.TabPane>
          <Tabs.TabPane tab="Logs" key="logs">
            <div style={{ margin: '12px 4px 32px' }}>
              <EnrollmentLogs
                enrollment={enrollment}
                user={user}
                onChangeSearchOption={(searchOption) => {
                  const url = getFilteredURLPath(location.pathname, searchOption);
                  history.push(url, {});
                }}
              />
            </div>
          </Tabs.TabPane>
        </Tabs>
      </Fragment>
    </div>
  );
};

Enrollment.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }).isRequired,
  match: PropTypes.shape({
    params: PropTypes.shape({
      externalTransactionId: PropTypes.string.isRequired,
      merchantCode: PropTypes.string.isRequired,
    }),
  }).isRequired,
  location: PropTypes.shape({
    filter: PropTypes.shape({}),
    search: PropTypes.string.isRequired,
    pathname: PropTypes.string.isRequired,
  }),
  user: PropTypes.shape({
    id: PropTypes.number.isRequired,
    systemRole: PropTypes.arrayOf(PropTypes.any).isRequired,
  }).isRequired,
};

Enrollment.defaultProps = {
  location: null,
};

export default Enrollment;
