import moment from 'moment-timezone';
import React, { Fragment, useContext, useEffect, useReducer } from 'react';
import PropTypes from 'prop-types';
import qs from 'query-string';
import {
  Row, Col, Table, Input, Alert,
  Breadcrumb, Dropdown, Menu, Icon, Button,
} from 'antd';
import { RepeatIcon, CircleIcon, PaymentIcon } from '../Icons';
import PaymentsFilterModal from '../PaymentsFilterModal';
import SessionContext from '../../contexts/SessionContext';
import { getPaymentsService, exportMerchantPaymentsService } from '../../services/payments';
import { authenticateMerchantExportTokenService } from '../../services/transactions';
import { getStatusColor, formatNumber, getDefaultCreatedDate } from '../../helpers/utils';
import '../../styles/transaction.scss';
import './querybasedpaymentstable.scss';

const dateFormat = 'YYYY-MM-DD';
const displayDateFormat = 'MMM DD, YYYY';
const { defaultEndDate, defaultStartDate } = getDefaultCreatedDate(dateFormat);

const defaultFilter = {
  page: 1,
  status: ['PAID', 'SETTLED'],
  startDate: defaultStartDate,
  endDate: defaultEndDate,
  paymentMethod: 'all',
  transactionType: 'all',
  query: null,
  project: null,
  paymentType: null,
};

function getInitialState(queryFilters) {
  const defaultOpts = {
    page: parseInt(queryFilters.page, 10) || 1,
    size: parseInt(queryFilters.size, 10) || 20,
    totalCount: 0,
  };

  // if there is "query" on the filters on load, ignore all others
  const searchOption = queryFilters.query ? {
    ...defaultOpts,
    query: queryFilters.query,
    status: [],
    startDate: moment(null, dateFormat),
    endDate: moment(null, dateFormat),
    paymentMethod: 'all',
    transactionType: 'all',
    merchant: null,
    paymentType: null,
    project: null,
  } : {
    ...defaultOpts,
    query: null,
    status: queryFilters.status ? queryFilters.status.split(',') : defaultFilter.status,
    startDate: moment(queryFilters.start || defaultStartDate, dateFormat),
    endDate: moment(queryFilters.end || defaultEndDate, dateFormat),
    paymentMethod: queryFilters.method || defaultFilter.paymentMethod,
    transactionType: queryFilters.transactionType || defaultFilter.transactionType,
    merchant: queryFilters.merchant,
    paymentType: queryFilters.paymentType,
    project: queryFilters.project,
  };

  return {
    isFetchingPayments: true,
    isExporting: false,
    payments: [],
    status: null,
    message: null,
    searchOption,
  };
}

function reducer(prevState, action) {
  switch (action.type) {
    case 'GET_PAYMENTS':
      return {
        ...prevState,
        isFetchingPayments: true,
      };
    case 'GET_PAYMENTS_SUCCESSFUL':
      return {
        ...prevState,
        isFetchingPayments: false,
        payments: action.payments,
        searchOption: { ...prevState.searchOption, totalCount: action.totalCount },
      };
    case 'GET_PAYMENTS_FAILED':
      return {
        ...prevState,
        isFetchingPayments: false,
        payments: [],
        searchOption: {
          ...prevState.searchOption,
          totalCount: 0,
        },
        message: action.message,
        status: 'error',
      };
    case 'CLEAR_MESSAGE':
      return {
        ...prevState,
        status: null,
        message: null,
      };
    case 'SET_MESSAGE':
      return {
        ...prevState,
        status: action.status,
        message: action.message,
      };
    case 'UPDATE_SEARCH_OPTION':
      return {
        ...prevState,
        searchOption: action.searchOption,
      };
    case 'EXPORTING_PAYMENTS':
      return {
        ...prevState,
        isExporting: true,
      };
    case 'EXPORTING_PAYMENTS_SUCCESSFUL':
      return {
        ...prevState,
        isExporting: false,
      };
    case 'EXPORTING_PAYMENTS_FAILED':
      return {
        ...prevState,
        isExporting: false,
      };
    default:
      return prevState;
  }
}

const QueryBasedPaymentsTable = (props) => {
  const { merchant, onChangeSearchOption, location, history } = props;
  const timeZone = moment.tz.guess(true);
  const queryFilters = qs.parse(location.search, { ignoreQueryPrefix: true });
  const [state, dispatch] = useReducer(reducer, getInitialState(queryFilters, merchant));
  const { searchOption } = state;
  const { loggedInUser } = useContext(SessionContext);
  const role = loggedInUser.merchantRoles.find(r => r.merchantCode === merchant.code);

  useEffect(() => {
    let ableToSet = true;
    (async () => {
      try {
        dispatch({ type: 'GET_PAYMENTS' });
        const status = state.searchOption.status
          .reduce((pv, cv) => `${pv},${cv}`, '')
          .slice(1);
        const paymentsResponse = await getPaymentsService(merchant.id, {
          ...state.searchOption,
          status,
        });
        const { payments, totalCount } = paymentsResponse.data;
        if (ableToSet) {
          dispatch({ type: 'GET_PAYMENTS_SUCCESSFUL', payments, totalCount });
        }
      } catch (error) {
        const message = error && error.response ? error.response.data.message
          : 'We are not able to get the payments from the server as of the moment';
        if (ableToSet) {
          dispatch({ type: 'GET_PAYMENTS_FAILED', message });
        }
      }
    })();

    return () => { ableToSet = false; };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    searchOption.page,
    searchOption.size,
    searchOption.status,
    searchOption.paymentMethod,
    searchOption.transactionType,
    searchOption.startDate,
    searchOption.endDate,
    searchOption.project,
    searchOption.paymentType,
    searchOption.query,
  ]);

  function onApplyFilters(opts) {
    // I just need to convert the IDs back to string because
    // the prop types for PaymentsFilterModal are expecting it as string.
    const updatedOpts = {
      ...state.searchOption,
      page: 1,
      startDate: opts.startDate,
      endDate: opts.endDate,
      status: opts.status,
      paymentMethod: opts.paymentMethod,
      transactionType: opts.transactionType,
      project: opts.projectId ? opts.projectId.toString() : null,
      paymentType: opts.paymentTypeId ? opts.paymentTypeId.toString() : null,
    };
    onChangeSearchOption(updatedOpts);
    dispatch({ type: 'UPDATE_SEARCH_OPTION', searchOption: updatedOpts });
  }

  function resetFiltersToDefault() {
    const updatedOpts = {
      ...state.searchOption,
      ...defaultFilter,
    };
    onChangeSearchOption(updatedOpts);
    dispatch({ type: 'UPDATE_SEARCH_OPTION', searchOption: updatedOpts });
  }

  function onSearchByQuery(query) {
    if (!query) {
      resetFiltersToDefault();
      return;
    }

    const updatedOpts = {
      ...state.searchOption,
      query,
      page: 1,
      startDate: moment(null, dateFormat),
      endDate: moment(null, dateFormat),
      status: [],
      project: null,
      paymentType: null,
      paymentMethod: 'all',
      transactionType: 'all',
    };
    onChangeSearchOption(updatedOpts);
    dispatch({ type: 'UPDATE_SEARCH_OPTION', searchOption: updatedOpts });
  }

  async function exportPayments() {
    try {
      if (state.searchOption.totalCount === 0) {
        dispatch({
          type: 'SET_MESSAGE',
          status: 'error',
          message: 'You cannot export zero payments into a CSV file',
        });
        return;
      }

      dispatch({ type: 'EXPORTING_PAYMENTS' });
      const { data: { exportToken } } = await authenticateMerchantExportTokenService(merchant.id);
      exportMerchantPaymentsService(merchant, searchOption, exportToken);
      dispatch({ type: 'EXPORTING_PAYMENTS_SUCCESSFUL' });
    } catch (err) {
      const message = err && err.response
        ? err.response.data.message
        : 'Unable to export payments data as of the moment';
      dispatch({ type: 'EXPORTING_PAYMENTS_FAILED', message });
    }
  }

  const columns = [
    {
      title: 'Status',
      key: 'paymentStatus',
      render: row => (
        <div>
          <span
            style={{
              fontWeight: 700,
              color: '#ffffff',
              backgroundColor: getStatusColor(row.paymentStatus),
              borderRadius: '4px',
              padding: '4px 8px',
            }}
          >
            {row.paymentStatus}
          </span>
        </div>
      ),
    }, {
      title: 'Customer',
      key: 'customer',
      render: row => (
        <div>
          <div className="text-primary">{row.customerName || '-----'}</div>
        </div>
      ),
    }, {
      title: 'Payment type',
      key: 'paymentType',
      render: row => (
        <div className="text-secondary">
          {row.paymentTypeName}
        </div>
      ),
    }, {
      title: 'Project',
      key: 'project',
      render: row => (
        <div className="text-secondary">
          {(({ projectName, projectCategory }) => {
            if (projectName && projectCategory) { return `${projectCategory} - ${projectName}`; }
            if (projectName) { return projectName; }
            return '----';
          })(row)}
        </div>
      ),
    }, {
      title: 'Amount',
      key: 'amount',
      render: row => (
        <div style={{ display: 'flex' }}>
          <span style={{ width: 14.6, marginTop: 2, marginRight: 8 }} alt={row.transactionType}>
            {row.transactionType === 'PAYMENT'
              ? <CircleIcon diameter="14px" fill="#8ED140" />
              : <RepeatIcon diameter="16px" fill="#FFD426" />}
          </span>
          <div className="text-primary">
            {row.billBase
              ? `${row.billBase[0]} ${formatNumber(row.billBase[1])}`
              : '-----'}
          </div>
        </div>
      ),
    }, {
      title: 'Date',
      key: 'createdAt',
      render: (row) => {
        const dt = row.paidAt || row.invoiceCreatedAt;
        return (
          <div className="text-primary">
            {moment(dt).tz(timeZone).format('lll')}
          </div>
        );
      },
    },
  ];

  const pagination = {
    size: 'small',
    current: state.searchOption.page,
    defaultCurrent: 1,
    defaultPageSize: 10,
    pageSize: state.searchOption.size,
    total: state.searchOption.totalCount,
    showSizeChanger: true,
    pageSizeOptions: ['10', '20', '50', '100'],
    onShowSizeChange: (currentPage, size) => {
      const updatedOpts = {
        ...state.searchOption,
        page: 1,
        size: Number(size),
      };
      onChangeSearchOption(updatedOpts);
      dispatch({ type: 'UPDATE_SEARCH_OPTION', searchOption: updatedOpts });
    },
    onChange: (p) => {
      const totalPage = Math.ceil(state.searchOption.totalCount / state.searchOption.size);
      let page = p || 1;
      if (page < 1) { page = 1; }
      if (page > totalPage) { page = totalPage - 1; }

      const updatedOpts = {
        ...state.searchOption,
        page,
      };
      onChangeSearchOption(updatedOpts);
      dispatch({ type: 'UPDATE_SEARCH_OPTION', searchOption: updatedOpts });
    },
    showTotal: (total, range) => `Showing ${range[0]} - ${range[1]} of ${total}`,
  };

  const actionMenu = (
    <Menu>
      {merchant.canManagePaymentLinks && role.merchantRole[0] <= 20 && (
        <Menu.Item
          key="1"
          onClick={() => history.push(`/merchants/${merchant.code}/payments/payment-links`)}
        >
          <Icon type="link" />
          Create payment link
        </Menu.Item>
      )}
      <Menu.Item key="2" onClick={exportPayments} disabled={state.isExporting}>
        <Icon type="download" />
        Export as CSV
      </Menu.Item>
    </Menu>
  );

  return (
    <Fragment>
      <Row className="breadcrumb-row">
        <Col className="breadcrumb-header">
          <Breadcrumb className="breadcrumb-parent">
            <Breadcrumb.Item className="item-container">
              <div className="breadcrumb-item breadcrumb-icon">
                <PaymentIcon />
              </div>
              <div className="breadcrumb-item icon-label">
                Payments
              </div>
            </Breadcrumb.Item>
          </Breadcrumb>
          <div className="action-container">
            <Dropdown overlay={actionMenu} trigger={['click']}>
              <Button
                type="primary"
                className="button button-standard button-standard-outline button-small"
              >
                Actions
                <Icon type="down" />
              </Button>
            </Dropdown>
          </div>
        </Col>
      </Row>
      <Row
        type="flex"
        justify="space-between"
        align="middle"
        className="filters"
        style={{ marginBottom: '12px' }}
      >
        <Col lg={12}>
          {(() => {
            if (state.searchOption.query) {
              return (
                <h4 className="text-secondary">
                  Showing all payments for
                  {` "${state.searchOption.query}"`}
                </h4>
              );
            }
            return (
              <h4 className="text-secondary">
                Showing payments on
                {` ${state.searchOption.startDate.format(displayDateFormat)} - ${state.searchOption.endDate.format(displayDateFormat)}`}
              </h4>
            );
          })()}
        </Col>
        <Col lg={12}>
          <Row type="flex" justify="end">
            <Col style={{ marginRight: '16px' }}>
              {!state.searchOption.query && (
                <PaymentsFilterModal
                  onApply={onApplyFilters}
                  merchant={merchant}
                  defaultOption={defaultFilter}
                  searchOption={state.searchOption}
                />
              )}
            </Col>
            <Col lg={12}>
              <Input.Search
                allowClear
                placeholder="Search by name or ID"
                style={{ width: '100%' }}
                defaultValue={state.searchOption.query}
                onChange={(e) => {
                  if (!e.target.value) { resetFiltersToDefault(); }
                }}
                onSearch={onSearchByQuery}
              />
            </Col>
          </Row>
        </Col>
      </Row>
      {state && state.status && state.message && (
        <div style={{ marginBottom: '12px' }}>
          <Row>
            <Col span={24}>
              <Alert
                message={<p style={{ marginBottom: 0 }}>{state.message}</p>}
                type={state.status}
                showIcon
                closable
                onClose={() => dispatch({ type: 'CLEAR_MESSAGE' })}
              />
            </Col>
          </Row>
        </div>
      )}
      <Table
        className="table-standard payments-table"
        dataSource={state.payments}
        loading={state.isFetchingPayments}
        columns={columns}
        pagination={pagination}
        rowKey="invoiceId"
        onRow={record => ({
          onClick: () => {
            history.push(`/merchants/${merchant.code}/payments/${record.externalTransactionId}/${record.invoiceId}`);
          },
        })}
      />
    </Fragment>
  );
};

QueryBasedPaymentsTable.propTypes = {
  merchant: PropTypes.shape({
    id: PropTypes.number.isRequired,
    code: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    canManagePaymentLinks: PropTypes.bool.isRequired,
  }).isRequired,
  onChangeSearchOption: PropTypes.func,
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }).isRequired,
  location: PropTypes.shape({
    pathname: PropTypes.string.isRequired,
    search: PropTypes.string.isRequired,
  }).isRequired,
};

QueryBasedPaymentsTable.defaultProps = {
  onChangeSearchOption: () => { },
};

export default QueryBasedPaymentsTable;
