import React, { Fragment, useContext, useReducer } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { Button, Form, Input } from 'antd';
import SessionContext from '../../contexts/SessionContext';
import { requestResetTokenService } from '../../services/auth';
import { SingleForm } from '../../components';
import { getAPIErrorMessage } from '../../helpers/utils';
import '../../styles/authform.css';


const initialState = {
  isSendingVerification: false,
  isVerificationSent: false,
};

function reducer(prevState, action) {
  switch (action.type) {
    case 'SENDING_VERIFICATION':
      return {
        ...prevState,
        isSendingVerification: true,
      };

    case 'SENDING_VERIFICATION_SUCCESSFUL':
      return {
        ...prevState,
        isVerificationSent: true,
        isSendingVerification: false,
      };

    case 'SENDING_VERIFICATION_FAILED':
      return {
        ...prevState,
        isVerificationSent: false,
        isSendingVerification: false,
      };

    default:
      return prevState;
  }
}

const formItemLayout = { wrapperCol: { xs: { span: 24 } } };
const ForgotPassword = (props) => {
  const { form: { getFieldDecorator, validateFields } } = props;
  const { showToast } = useContext(SessionContext);
  const [state, dispatch] = useReducer(reducer, initialState);

  function onSubmit(e) {
    e.preventDefault();
    dispatch({ type: 'SENDING_VERIFICATION' });
    validateFields(async (err, values) => {
      if (err) {
        dispatch({ type: 'SENDING_VERIFICATION_FAILED' });
        showToast({ type: 'error', message: 'You must provide a valid email' });
        return;
      }

      try {
        await requestResetTokenService(values.email);
        dispatch({ type: 'SENDING_VERIFICATION_SUCCESSFUL' });
      } catch (error) {
        const message = getAPIErrorMessage(error, 'We are not able to submit your request. Please try again later');
        dispatch({ type: 'SENDING_VERIFICATION_FAILED' });
        showToast({ type: 'error', message });
      }
    });
  }

  if (state.isVerificationSent) {
    return (
      <SingleForm
        title="Account Recovery"
        subtitle="Thank you. You should be getting a password reset link in your inbox within a few minutes."
        footer={{ show: true, to: '/login', text: 'Back to Log In' }}
      />
    );
  }

  return (
    <SingleForm
      title="Forgot Password"
      subtitle="To reset your password, enter the email address you are using."
      footer={{ show: true, to: '/login', text: 'Back to Log In' }}
    >
      <Fragment>
        <Form onSubmit={onSubmit}>
          <Form.Item {...formItemLayout}>
            {getFieldDecorator('email', {
              rules: [{ required: true, message: 'Please input your email!' }],
            })(<Input type="email" placeholder="you@example.com" required />)}
          </Form.Item>
          <Form.Item>
            <Button
              className="button button-standard button-fullwidth"
              htmlType="submit"
              disabled={state.isSendingVerification}
              loading={state.isSendingVerification}
            >
              Get Reset Link
            </Button>
          </Form.Item>
        </Form>
      </Fragment>
    </SingleForm>
  );
};

ForgotPassword.propTypes = {
  form: PropTypes.shape({
    getFieldDecorator: PropTypes.func.isRequired,
    validateFields: PropTypes.func.isRequired,
  }).isRequired,
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }).isRequired,
};

export default withRouter(Form.create()(ForgotPassword));
