import React, { Fragment, useReducer, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useLocation, useHistory } from 'react-router-dom';
import qs from 'query-string';
import { Table, Row, Col, Input } from 'antd';
import * as moment from 'moment';

import { getProjectsService } from '../../services/projects';
import { getAPIErrorMessage } from '../../helpers/utils';
import Message from '../Message';

const defaultFilter = {
  page: 1,
  query: null,
};

function getInitialState(queryFilters) {
  const defaultOpts = {
    page: parseInt(queryFilters.page, 10) || 1,
    size: parseInt(queryFilters.size, 10) || 20,
    totalCount: 0,
  };

  // if there is "query" on the filters on load, ignore all others
  const searchOption = queryFilters.query ? {
    ...defaultOpts,
    query: queryFilters.query,
  } : {
    ...defaultOpts,
    query: null,
  };

  return {
    isFetchingProjects: true,
    projects: [],
    searchOption,
  };
}

function reducer(prevState, action) {
  switch (action.type) {
    case 'GET_PROJECTS':
      return {
        ...prevState,
        isFetchingProjects: true,
      };
    case 'GET_PROJECTS_SUCCESSFUL':
      return {
        ...prevState,
        isFetchingProjects: false,
        projects: action.projects,
        searchOption: { ...prevState.searchOption, totalCount: action.totalCount },
      };
    case 'GET_PROJECTS_FAILED':
      return {
        ...prevState,
        isFetchingProjects: false,
        projects: [],
        searchOption: {
          ...prevState.searchOption,
          totalCount: 0,
        },
        message: action.message,
        status: 'error',
      };
    case 'UPDATE_SEARCH_OPTION':
      return {
        ...prevState,
        searchOption: action.searchOption,
      };
    case 'CLEAR_MESSAGE':
      return {
        ...prevState,
        message: null,
        status: null,
      };
    default:
      return prevState;
  }
}

const QueryBasedProjectsTable = (props) => {
  const { merchant, onChangeSearchOption, passInitialState } = props;
  const location = useLocation();
  const history = useHistory();
  const queryFilters = qs.parse(location.search, { ignoreQueryPrefix: true });
  const [state, dispatch] = useReducer(reducer, getInitialState(queryFilters));
  const { searchOption } = state;

  useEffect(() => {
    let ableToSet = true;

    (async () => {
      try {
        dispatch({ type: 'GET_PROJECTS' });
        const response = await getProjectsService(merchant.id, {
          ...state.searchOption,
        });
        const { projects, totalCount } = response.data;

        if (ableToSet) {
          dispatch({ type: 'GET_PROJECTS_SUCCESSFUL', projects, totalCount });
          passInitialState(state.searchOption, totalCount);
        }
      } catch (error) {
        const message = getAPIErrorMessage(error, 'We are not able to get the projects. Please try again later.');
        if (ableToSet) {
          dispatch({ type: 'GET_PROJECTS_FAILED', message });
        }
      }
    })();

    return () => { ableToSet = false; };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    searchOption.page,
    searchOption.size,
    searchOption.query,
  ]);

  function resetFiltersToDefault() {
    const updatedOpts = {
      ...state.searchOption,
      ...defaultFilter,
    };
    onChangeSearchOption(updatedOpts);
    dispatch({ type: 'UPDATE_SEARCH_OPTION', searchOption: updatedOpts });
  }

  function onSearchByQuery(query) {
    if (!query) {
      resetFiltersToDefault();
      return;
    }

    const updatedOpts = {
      ...state.searchOption,
      query,
      page: 1,
    };
    onChangeSearchOption(updatedOpts);
    dispatch({ type: 'UPDATE_SEARCH_OPTION', searchOption: updatedOpts });
  }

  const columns = [
    {
      title: 'Name',
      key: 'name',
      render: row => row.name,
    },
    {
      title: 'Category',
      key: 'category',
      render: row => row.category,
    },
    {
      title: 'Status',
      key: 'status',
      render: row => (row.isEnabled ? 'Active' : 'Disabled'),
    },
    {
      title: 'Modified At',
      key: 'modifiedAt',
      render: row => moment(row.modifiedAt).format('LL'),
    },
  ];

  const pagination = {
    size: 'small',
    current: state.searchOption.page,
    defaultCurrent: 1,
    defaultPageSize: 10,
    pageSize: state.searchOption.size,
    total: state.searchOption.totalCount,
    showSizeChanger: true,
    onShowSizeChange: (currentPage, size) => {
      const updatedOpts = {
        ...state.searchOption,
        page: 1,
        size: Number(size),
      };
      onChangeSearchOption(updatedOpts);
      dispatch({ type: 'UPDATE_SEARCH_OPTION', searchOption: updatedOpts });
    },
    onChange: (p) => {
      const totalPage = Math.ceil(state.searchOption.totalCount / state.searchOption.size);
      let page = p || 1;
      if (page < 1) { page = 1; }
      if (page > totalPage) { page = totalPage - 1; }

      const updatedOpts = {
        ...state.searchOption,
        page,
      };
      onChangeSearchOption(updatedOpts);
      dispatch({ type: 'UPDATE_SEARCH_OPTION', searchOption: updatedOpts });
    },
    showTotal: (total, range) => `Showing ${range[0]} - ${range[1]} of ${total}`,
  };

  return (
    <Fragment>
      <Row
        type="flex"
        justify="space-between"
        align="middle"
        className="filters"
        style={{ marginBottom: '12px' }}
      >
        <Col lg={12}>
          {(() => {
            if (state.searchOption.query) {
              return (
                <h4 className="text-secondary">
                  Showing all projects for
                  {` "${state.searchOption.query}"`}
                </h4>
              );
            }
            return (
              <h4 className="text-secondary">
                Showing all projects
              </h4>
            );
          })()}
        </Col>
        <Col lg={12}>
          <Row type="flex" justify="end">
            <Col lg={12}>
              <Input.Search
                allowClear
                placeholder="Search by project name"
                style={{ width: '100%' }}
                defaultValue={state.searchOption.query}
                onChange={(e) => {
                  if (!e.target.value) { resetFiltersToDefault(); }
                }}
                onSearch={onSearchByQuery}
              />
            </Col>
          </Row>
        </Col>
      </Row>
      {state && state.status && state.message && (
        <Message
          style={{ marginBottom: '12px' }}
          status={state.status}
          message={state.message}
          onClose={() => dispatch({ type: 'CLEAR_MESSAGE' })}
        />
      )}
      <Table
        className="table-standard"
        rowClassName="row-clickable"
        dataSource={state.projects}
        loading={state.isFetchingProjects}
        columns={columns}
        pagination={pagination}
        rowKey="id"
        onRow={record => ({
          onClick: () => {
            history.push(`/merchants/${merchant.code}/projects/${record.id}`);
          },
        })}
      />
    </Fragment>
  );
};

QueryBasedProjectsTable.propTypes = {
  merchant: PropTypes.shape({
    id: PropTypes.number.isRequired,
    code: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
  }).isRequired,
  onChangeSearchOption: PropTypes.func,
  passInitialState: PropTypes.func,
};

QueryBasedProjectsTable.defaultProps = {
  onChangeSearchOption: () => {},
  passInitialState: () => {},
};

export default QueryBasedProjectsTable;
