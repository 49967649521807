import api from '../helpers/apiClient';
import { exportUrl } from '../config/index';

export function searchPayments(searchOption) {
  const {
    page,
    size,
    project,
    paymentMethod,
    transactionType,
    status,
    merchant,
    paymentType,
  } = searchOption;
  return api.get('/payments', {
    params: {
      page,
      size,
      project,
      paymentMethod,
      transactionType,
      paymentType,
      mid: merchant,
      query: searchOption.query ? searchOption.query.trim() : null,
      startday: searchOption.startDate && searchOption.startDate.isValid()
        ? searchOption.startDate.format('YYYY-MM-DD') : undefined,
      endday: searchOption.endDate && searchOption.endDate.isValid()
        ? searchOption.endDate.format('YYYY-MM-DD') : undefined,
      reportdate: searchOption.reportingDate && searchOption.reportingDate.isValid()
        ? searchOption.reportingDate.format('YYYY-MM-DD') : undefined,
      duedate: searchOption.dueDate && searchOption.dueDate.isValid()
        ? searchOption.dueDate.format('YYYY-MM-DD') : undefined,
      status,
    },
  });
}

export function getPaymentByInvoiceId(invoiceId) {
  return api.get(`/payments/${invoiceId}`);
}

export function getPaymentsService(merchantId, searchOption) {
  const {
    page,
    size,
    project,
    projectName,
    projectCategory,
    paymentMethod,
    transactionType,
    paymentType,
    settlementReferenceId,
    settlementStatus,
    status,
  } = searchOption;
  return api.get(`/merchants/${merchantId}/payments`, {
    params: {
      page,
      size,
      project,
      projectName,
      projectCategory,
      paymentMethod,
      transactionType,
      paymentType,
      query: searchOption.query ? searchOption.query.trim() : null,
      startday: searchOption.startDate && searchOption.startDate.isValid()
        ? searchOption.startDate.format('YYYY-MM-DD') : undefined,
      endday: searchOption.endDate && searchOption.endDate.isValid()
        ? searchOption.endDate.format('YYYY-MM-DD') : undefined,
      reportdate: searchOption.reportingDate && searchOption.reportingDate.isValid()
        ? searchOption.reportingDate.format('YYYY-MM-DD') : undefined,
      settlementrefid: settlementReferenceId,
      settlementstatus: settlementStatus,
      status,
    },
  });
}

export function getPaymentService(merchantId, externalTransactionId, invoiceId) {
  return api.get(`/merchants/${merchantId}/payments/${externalTransactionId}/${invoiceId}`);
}

export function exportMerchantPaymentsService(merchant, searchOption, token) {
  const { startDate, endDate, reportingDate } = searchOption;
  const startday = startDate && startDate.isValid() ? startDate.format('YYYY-MM-DD') : undefined;
  const endday = endDate && endDate.isValid() ? endDate.format('YYYY-MM-DD') : undefined;
  const reportdate = reportingDate && reportingDate.isValid() ? reportingDate.format('YYYY-MM-DD') : undefined;
  let url = `${exportUrl}/merchants/${merchant.code}/payments/export?token=${token}`;
  if (startday && endday) { url += `&startday=${startday}&endday=${endday}`; }
  if (reportdate) { url += `&reportdate=${reportdate}`; }
  if (searchOption.project) { url += `&project=${searchOption.project}`; }
  if (searchOption.query) { url += `&query=${searchOption.query}`; }
  if (searchOption.paymentType) { url += `&paymentType=${searchOption.paymentType}`; }
  if (searchOption.status) { url += `&status=${searchOption.status}`; }
  if (searchOption.settlementReferenceId) { url += `&settlementrefid=${searchOption.settlementReferenceId}`; }
  window.open(url, '_blank');
}

export function exportPaymentsService(searchOption, token) {
  const { startDate, endDate, reportingDate, dueDate } = searchOption;
  const startday = startDate && startDate.isValid() ? startDate.format('YYYY-MM-DD') : undefined;
  const endday = endDate && endDate.isValid() ? endDate.format('YYYY-MM-DD') : undefined;
  const reportdate = reportingDate && reportingDate.isValid() ? reportingDate.format('YYYY-MM-DD') : undefined;
  const dueday = dueDate && dueDate.isValid() ? dueDate.format('YYYY-MM-DD') : undefined;
  let url = `${exportUrl}/payments/export?token=${token}`;
  if (startday && endday) { url += `&startday=${startday}&endday=${endday}`; }
  if (searchOption.project) { url += `&project=${searchOption.project}`; }
  if (searchOption.query) { url += `&query=${searchOption.query}`; }
  if (searchOption.status) { url += `&status=${searchOption.status}`; }
  if (searchOption.merchant) { url += `&mid=${searchOption.merchant}`; }
  if (searchOption.paymentType) { url += `&paymentType=${searchOption.paymentType}`; }
  if (reportdate) { url += `&reportdate=${reportdate}`; }
  if (dueday) { url += `&duedate=${dueday}`; }
  window.open(url, '_blank');
}

export function refundPayment(merchantCode, referenceId, data) {
  return api.post(`/merchants/${merchantCode}/payments/${referenceId}/refund`, data);
}

export function completeOfflinePayment(merchantCode, referenceId, data) {
  return api.post(`/merchants/${merchantCode}/payments/${referenceId}/offline`, data);
}

export function sendPaymentReceipt(merchantCode, referenceId, email) {
  return api.post(`/merchants/${merchantCode}/payments/${referenceId}/receipt/send`, { email });
}

export function createPaymentDispute(merchantCode, referenceId, reason) {
  return api.post(`/merchants/${merchantCode}/payments/${referenceId}/dispute`, { reason });
}
