import React, { useEffect, useReducer } from 'react';
import { Modal, Table, Radio, Select, Icon } from 'antd';
import PropTypes from 'prop-types';
import moment from 'moment';
import { jsonRenderer } from '../../helpers/utils';
import '../../styles/logmodal.scss';

import { getApiLogs, findApiLog } from '../../services/logs';

const initialState = {
  isFetchingApiLogs: false,
  isFetchingApiLog: false,
  isApiLogModalVisible: false,
  refreshInterval: 0,
  modalActiveKey: 'body',
  apiLogs: [],
  apiLog: null,
};

function reducer(prevState, action) {
  switch (action.type) {
    case 'FETCH_API_LOGS':
      return {
        ...prevState,
        isFetchingApiLogs: true,
      };

    case 'SET_API_LOGS':
      return {
        ...prevState,
        apiLogs: action.logs,
        isFetchingApiLogs: false,
      };

    case 'FETCH_API_LOG':
      return {
        ...prevState,
        isFetchingApiLog: true,
      };

    case 'SET_API_LOG':
      return {
        ...prevState,
        apiLog: action.apiLog,
        isFetchingApiLog: false,
      };

    case 'TOGGLE_API_LOG_MODAL':
      return {
        ...prevState,
        isApiLogModalVisible: !prevState.isApiLogModalVisible,
        modalActiveKey: !prevState.isApiLogModalVisible ? 'body' : prevState.modalActiveKey,
      };

    case 'SET_MODAL_ACTIVE_KEY':
      return {
        ...prevState,
        modalActiveKey: action.activeKey,
      };

    case 'SET_REFRESH_INTERVAL':
      return {
        ...prevState,
        refreshInterval: action.refreshInterval,
      };

    default:
      return prevState;
  }
}

const ApiLogsTab = (props) => {
  const { merchant, isActive } = props;
  const [state, dispatch] = useReducer(reducer, initialState);
  const timezone = moment.tz.guess(true);
  const { Option } = Select;
  let interval;

  function fetchApiLog(row) {
    dispatch({ type: 'FETCH_API_LOG' });
    dispatch({ type: 'TOGGLE_API_LOG_MODAL' });

    findApiLog(merchant.id, row.logId)
      .then(({ data }) => {
        dispatch({ type: 'SET_API_LOG', apiLog: data });
      })
      .catch(() => {
        dispatch({ type: 'TOGGLE_API_LOG_MODAL' });
      });
  }

  function getContent(part) {
    let request = state.apiLog.requestBody;
    let response = state.apiLog.responseBody;

    if (part === 'headers') {
      request = state.apiLog.requestHeaders;
      response = state.apiLog.responseHeaders;
    }

    const requestNode = jsonRenderer(request, 'request');
    const responseNode = jsonRenderer(response, 'response');
    const maxLen = Math.max(requestNode.length, responseNode.length);

    // eslint-disable-next-line no-plusplus
    for (let i = requestNode.length === maxLen ? responseNode.length : requestNode.length; i < maxLen; i++) {
      (requestNode.length === maxLen ? responseNode : requestNode).push(
        <span>
          <span className="is-standard">{'\n'}</span>
        </span>,
      );
    }

    return state.isFetchingApiLog
      ? (
        <div>
          Loading
        </div>
      )
      : (
        <div>
          {state.apiLog.responseStatusCode === 200
            || state.apiLog.responseStatusCode === 201
            ? (
              <span className="webhook-task-status -success">
                {state.apiLog.responseStatusCode}
                &nbsp;
                SUCCESS
              </span>
            ) : (
              <span className="webhook-task-status -failed">
                {state.apiLog.responseStatusCode}
                &nbsp;
                FAILED
              </span>
            )
          }
          <div className="request-response-content">
            <div>
              <h3>Request</h3>
              <pre>
                {requestNode}
              </pre>
            </div>
            <div>
              <h3>Response</h3>
              <pre>
                {responseNode}
              </pre>
            </div>
          </div>

        </div>

      );
  }

  useEffect(() => {
    if (isActive) {
      if (state.refreshInterval > 0) {
        interval = setInterval(retrieveLogs, state.refreshInterval);
      } else {
        retrieveLogs();
      }
    }
    return () => {
      interval && clearInterval(interval);
    };
  }, [state.refreshInterval, isActive]);

  async function retrieveLogs() {
    dispatch({ type: 'FETCH_API_LOGS' });

    try {
      const { data: { logs } } = await getApiLogs(merchant.id);
      dispatch({ type: 'SET_API_LOGS', logs });
    } catch (error) {
      dispatch({ type: 'SET_API_LOGS', logs: [] });
    }
  }

  const columns = [
    {
      title: 'Status',
      key: 'status',
      width: 120,
      render: row => (
        <div>
          {row.responseStatusCode === 200 || row.responseStatusCode === 201
            ? (
              <span className="webhook-task-status -success">
                SUCCESS
              </span>
            )
            : (
              <span className="webhook-task-status -failed">
                FAILED
              </span>
            )}
        </div>
      ),
    },
    {
      title: 'Transaction Id',
      width: 180,
      render: row => (
        <div>
          {row.externalTransactionId
            ? row.externalTransactionId
            : '----'}
        </div>
      ),
    },
    {
      title: 'Endpoint',
      ellipsis: true,
      dataIndex: 'requestEndpoint',
    },
    {
      title: 'Method',
      width: 90,
      dataIndex: 'requestMethod',
    },
    {
      title: 'Created At',
      width: 180,
      render: row => (
        <div>
          {moment(row.createdAt).tz(timezone).format('lll')}
        </div>
      ),
    },
  ];

  const radioGroup = (
    <Radio.Group
      onChange={(e) => {
        dispatch({
          type: 'SET_MODAL_ACTIVE_KEY',
          activeKey: e.target.value,
        });
      }}
      style={{
        marginBottom: 12,
        marginLeft: 0,
      }}
      value={state.modalActiveKey}
    >
      <Radio.Button value="body">Body</Radio.Button>
      <Radio.Button value="headers">Headers</Radio.Button>
    </Radio.Group>
  );

  const modal = () => {
    const headers = state.apiLog !== null && getContent('headers');
    const body = state.apiLog !== null && getContent('body');

    return (
      <Modal
        className="dialog-modal"
        visible={state.isApiLogModalVisible}
        title="API Log"
        forceRender={false}
        onCancel={() => {
          dispatch({ type: 'TOGGLE_API_LOG_MODAL' });
        }}
        footer={false}
        width="min-content"
      >
        {radioGroup}
        {state.modalActiveKey === 'headers' ? headers : body}
      </Modal>
    );
  };

  return (
    <>
      <div style={{ textAlign: 'right', marginBottom: '10px' }}>
        {state.isFetchingApiLogs && <Icon type="loading" style={{ marginRight: '5px' }} />}
        <span>Refresh interval: </span>
        <Select
          defaultValue="0"
          style={{ minWidth: '110px' }}
          onChange={(value) => {
            dispatch({ type: 'SET_REFRESH_INTERVAL', refreshInterval: value * 1000 });
          }}
        >
          <Option key="0" value="0">Off</Option>
          {[5, 10, 15, 20, 30, 60, 80, 120, 240, 300].map(i => (
            <Option key={i} value={i}>
              {`${i} seconds`}
            </Option>
          ))}
        </Select>
      </div>
      <Table
        className="table-standard"
        dataSource={state.apiLogs}
        columns={columns}
        rowKey="logId"
        pagination={false}
        onRow={row => ({
          onClick: () => fetchApiLog(row),
        })}
      />
      {modal()}
    </>
  );
};

ApiLogsTab.propTypes = {
  merchant: PropTypes.shape({
    id: PropTypes.number.isRequired,
    code: PropTypes.string.isRequired,
  }).isRequired,
  isActive: PropTypes.bool.isRequired,
};

export default ApiLogsTab;
