import React, { useReducer } from 'react';
import PropTypes from 'prop-types';
import { Modal, Button, Form, Switch, Typography } from 'antd';
import { updateMerchantFeatureFlags } from '../../services/merchants';
import { getAPIErrorMessage } from '../../helpers/utils';

const getInitialState = merchant => ({
  canManageProjects: merchant.canManageProjects,
  canManagePaymentMethods: merchant.canManagePaymentMethods,
  canManageApi: merchant.canManageApi,
  canAccessReports: merchant.canAccessReports,
  canCopySalesAgents: merchant.canCopySalesAgents,
  canManagePaymentLinks: merchant.canManagePaymentLinks,
  canManageNotificationSettings: merchant.canManageNotificationSettings,
  canCheckCardholdernameOnPayments: merchant.canCheckCardholdernameOnPayments,
  canCheckCardholdernameOnEnrollments: merchant.canCheckCardholdernameOnEnrollments,
  isUpdating: false,
  message: null,
  status: null,
});

function reducer(prevState, action) {
  switch (action.type) {
    case 'SET_CAN_MANAGE_PROJECTS':
      return {
        ...prevState,
        canManageProjects: action.canManageProjects,
      };
    case 'SET_CAN_MANAGE_PAYMENT_METHODS':
      return {
        ...prevState,
        canManagePaymentMethods: action.canManagePaymentMethods,
      };
    case 'SET_CAN_MANAGE_APIS':
      return {
        ...prevState,
        canManageApi: action.canManageApi,
      };
    case 'SET_CAN_ACCESS_REPORTS':
      return {
        ...prevState,
        canAccessReports: action.canAccessReports,
      };
    case 'SET_CAN_COPY_SALES_AGENTS':
      return {
        ...prevState,
        canCopySalesAgents: action.canCopySalesAgents,
      };
    case 'SET_CAN_MANAGE_PAYMENT_LINKS':
      return {
        ...prevState,
        canManagePaymentLinks: action.canManagePaymentLinks,
      };
    case 'SET_CAN_MANAGE_NOTIFICATION_SETTINGS':
      return {
        ...prevState,
        canManageNotificationSettings: action.canManageNotificationSettings,
      };
    case 'SET_CAN_CHECK_CARDHOLDERNAME_ON_PAYMENTS':
      return {
        ...prevState,
        canCheckCardholdernameOnPayments: action.canCheckCardholdernameOnPayments,
      };
    case 'SET_CAN_CHECK_CARDHOLDERNAME_ON_ENROLLMENTS':
      return {
        ...prevState,
        canCheckCardholdernameOnEnrollments: action.canCheckCardholdernameOnEnrollments,
      };
    case 'UPDATE_FEATURE_FLAGS':
      return {
        ...prevState,
        isUpdating: true,
        message: null,
        status: null,
      };
    case 'UPDATE_FEATURE_FLAGS_SUCCESS':
      return {
        ...prevState,
        isUpdating: false,
        message: null,
        status: 'success',
      };
    case 'UPDATE_FEATURE_FLAGS_FAILED':
      return {
        ...prevState,
        isUpdating: false,
        message: action.message,
        status: 'error',
      };
    default:
      return prevState;
  }
}

function ToggleMerchantFeatureFlagsModal(props) {
  const { isVisible, merchant, onDone, onCancel } = props;
  const [state, dispatch] = useReducer(reducer, getInitialState(merchant));

  async function updateFeatureFlags() {
    dispatch({ type: 'UPDATE_FEATURE_FLAGS' });

    const {
      canManageProjects,
      canManagePaymentMethods,
      canManageApi,
      canAccessReports,
      canCopySalesAgents,
      canManagePaymentLinks,
      canManageNotificationSettings,
      canCheckCardholdernameOnPayments,
      canCheckCardholdernameOnEnrollments,
    } = state;

    if (typeof canManageProjects !== 'boolean') {
      dispatch({
        type: 'UPDATE_FEATURE_FLAGS_FAILED',
        message: 'Allow management of projects is invalid',
      });
      return;
    }
    if (typeof canManagePaymentMethods !== 'boolean') {
      dispatch({
        type: 'UPDATE_FEATURE_FLAGS_FAILED',
        message: 'Allow management of payment methods is invalid',
      });
      return;
    }
    if (typeof canManageApi !== 'boolean') {
      dispatch({
        type: 'UPDATE_FEATURE_FLAGS_FAILED',
        message: 'Allow management of APIs is invalid',
      });
      return;
    }
    if (typeof canAccessReports !== 'boolean') {
      dispatch({
        type: 'UPDATE_FEATURE_FLAGS_FAILED',
        message: 'Allow report access is invalid',
      });
      return;
    }
    if (typeof canCopySalesAgents !== 'boolean') {
      dispatch({
        type: 'UPDATE_FEATURE_FLAGS_FAILED',
        message: 'Allow sales agents to receive payment notifications from customers is invalid',
      });
      return;
    }
    if (typeof canManagePaymentLinks !== 'boolean') {
      dispatch({
        type: 'UPDATE_FEATURE_FLAGS_FAILED',
        message: 'Allow management of payment links is invalid',
      });
      return;
    }
    if (typeof canManageNotificationSettings !== 'boolean') {
      dispatch({
        type: 'UPDATE_FEATURE_FLAGS_FAILED',
        message: 'Allow to manage notification settings is invalid',
      });
      return;
    }
    if (typeof canCheckCardholdernameOnPayments !== 'boolean') {
      dispatch({
        type: 'UPDATE_FEATURE_FLAGS_FAILED',
        message: 'Allow to check cardholdername on payments is invalid',
      });
      return;
    }
    if (typeof canCheckCardholdernameOnEnrollments !== 'boolean') {
      dispatch({
        type: 'UPDATE_FEATURE_FLAGS_FAILED',
        message: 'Allow to check cardholdername on enrollments is invalid',
      });
      return;
    }
    try {
      await updateMerchantFeatureFlags(merchant.id, {
        canManageProjects,
        canManagePaymentMethods,
        canManageApi,
        canAccessReports,
        canCopySalesAgents,
        canManagePaymentLinks,
        canManageNotificationSettings,
        canCheckCardholdernameOnPayments,
        canCheckCardholdernameOnEnrollments,
      });
      dispatch({ type: 'UPDATE_FEATURE_FLAGS_SUCCESS' });
      onDone({
        message: `${merchant.name} account feature flags has been updated.`,
        status: 'success',
      });
    } catch (error) {
      const message = getAPIErrorMessage(error,
        'We are not able to update the merchant feature flags. Please try again later.');
      dispatch({ type: 'UPDATE_FEATURE_FLAGS_FAILED', message });
    }
  }

  return (
    <Modal
      className="dialog-modal"
      visible={isVisible}
      title="Toggle features"
      onCancel={onCancel}
      footer={[
        <Button
          loading={state.isUpdating}
          key="cancel-confirm-key"
          onClick={onCancel}
        >
          Cancel
        </Button>,
        <Button
          loading={state.isUpdating}
          key="submit"
          type="primary"
          onClick={() => updateFeatureFlags()}
        >
          Save
        </Button>,
      ]}
    >
      <Form
        onSubmit={(ev) => {
          ev.preventDefault();
          updateFeatureFlags();
        }}
      >
        <h4>
          {`Feature flags for ${merchant.name}`}
        </h4>
        <div className="flags">
          <div className="flag-item">
            <span>Allow management of projects</span>
            <Form.Item>
              <Switch
                defaultChecked={merchant.canManageProjects}
                onChange={(value) => {
                  dispatch({ type: 'SET_CAN_MANAGE_PROJECTS', canManageProjects: value });
                }}
              />
            </Form.Item>
          </div>
          <div className="flag-item">
            <span>Allow management of payment methods</span>
            <Form.Item>
              <Switch
                defaultChecked={merchant.canManagePaymentMethods}
                onChange={(value) => {
                  dispatch({ type: 'SET_CAN_MANAGE_PAYMENT_METHODS', canManagePaymentMethods: value });
                }}
              />
            </Form.Item>
          </div>
          <div className="flag-item">
            <span>Allow management of APIs</span>
            <Form.Item>
              <Switch
                defaultChecked={merchant.canManageApi}
                onChange={(value) => {
                  dispatch({ type: 'SET_CAN_MANAGE_APIS', canManageApi: value });
                }}
              />
            </Form.Item>
          </div>
          <div className="flag-item">
            <span>Allow access to reports</span>
            <Form.Item>
              <Switch
                defaultChecked={merchant.canAccessReports}
                onChange={(value) => {
                  dispatch({ type: 'SET_CAN_ACCESS_REPORTS', canAccessReports: value });
                }}
              />
            </Form.Item>
          </div>
          <div className="flag-item">
            <span>Allow sales agents to receive payment notifications from customers</span>
            <Form.Item>
              <Switch
                defaultChecked={merchant.canCopySalesAgents}
                onChange={(value) => {
                  dispatch({ type: 'SET_CAN_COPY_SALES_AGENTS', canCopySalesAgents: value });
                }}
              />
            </Form.Item>
          </div>
          <div className="flag-item">
            <span>Allow payment links</span>
            <Form.Item>
              <Switch
                defaultChecked={merchant.canManagePaymentLinks}
                onChange={(value) => {
                  dispatch({ type: 'SET_CAN_MANAGE_PAYMENT_LINKS', canManagePaymentLinks: value });
                }}
              />
            </Form.Item>
          </div>
          <div className="flag-item">
            <span>Allow management of notifications</span>
            <Form.Item>
              <Switch
                defaultChecked={merchant.canManageNotificationSettings}
                onChange={(value) => {
                  dispatch({ type: 'SET_CAN_MANAGE_NOTIFICATION_SETTINGS', canManageNotificationSettings: value });
                }}
              />
            </Form.Item>
          </div>
          <div className="flag-item">
            <span>Allow cardholder name check on payments</span>
            <Form.Item>
              <Switch
                defaultChecked={merchant.canCheckCardholdernameOnPayments}
                onChange={(value) => {
                  dispatch({ type: 'SET_CAN_CHECK_CARDHOLDERNAME_ON_PAYMENTS', canCheckCardholdernameOnPayments: value });
                }}
              />
            </Form.Item>
          </div>
          <div className="flag-item">
            <span>Allow cardholder name check on enrollments</span>
            <Form.Item>
              <Switch
                defaultChecked={merchant.canCheckCardholdernameOnEnrollments}
                onChange={(value) => {
                  dispatch({ type: 'SET_CAN_CHECK_CARDHOLDERNAME_ON_ENROLLMENTS', canCheckCardholdernameOnEnrollments: value });
                }}
              />
            </Form.Item>
          </div>
        </div>
        {state.status === 'error' && state.message && (
          <Typography.Text type="danger">{state.message}</Typography.Text>
        )}
      </Form>
    </Modal>
  );
}

ToggleMerchantFeatureFlagsModal.propTypes = {
  isVisible: PropTypes.bool.isRequired,
  merchant: PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string,
    canManageProjects: PropTypes.bool,
    canManagePaymentMethods: PropTypes.bool,
    canManageApi: PropTypes.bool,
    canAccessReports: PropTypes.bool,
    canCopySalesAgents: PropTypes.bool,
    canManagePaymentLinks: PropTypes.bool,
    canManageNotificationSettings: PropTypes.bool,
    canCheckCardholdernameOnPayments: PropTypes.bool,
    canCheckCardholdernameOnEnrollments: PropTypes.bool,
  }),
  onDone: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
};

ToggleMerchantFeatureFlagsModal.defaultProps = {
  merchant: {
    id: 0,
    name: null,
    canManageProjects: false,
    canManagePaymentMethods: false,
    canManageApi: false,
    canAccessReports: false,
    canCopySalesAgents: false,
    canManagePaymentLinks: false,
    canCheckCardholdernameOnPayments: false,
    canCheckCardholdernameOnEnrollments: false,
  },
};

export default ToggleMerchantFeatureFlagsModal;
