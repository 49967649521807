import moment from 'moment';
import React, { useReducer, useEffect, Fragment } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { Col, Row, Breadcrumb } from 'antd';
import PaymentLogs from '../../components/PaymentLogs';
import PaymentMethodDetails from '../../components/PaymentMethodDetails';
import UpdateDisputeModal from '../../components/UpdateDisputeModal';
import BreakdownItem from '../../components/BreakdownItem';
import { DisputeIcon, FolderIcon } from '../../components/Icons';
import { getDisputeById } from '../../services/disputes';
import { formatNumber, getAPIErrorMessage, getStatusColor, parseCustomValue } from '../../helpers/utils';

const initialState = {
  isFetchingDispute: false,
  dispute: null,
};

function reducer(prevState, action) {
  switch (action.type) {
    case 'GET_DISPUTE':
      return {
        ...prevState,
        isFetchingDispute: true,
        dispute: null,
      };

    case 'GET_DISPUTE_SUCCESSFUL':
      return {
        ...prevState,
        isFetchingDispute: false,
        dispute: action.dispute,
      };

    case 'GET_DISPUTE_FAILED':
      return {
        ...prevState,
        isFetchingDispute: false,
        dispute: null,
      };

    default:
      return prevState;
  }
}

function getFilteredURLPath(path, searchOption) {
  const { page, size } = searchOption;
  let url = `${path}?page=${page || 1}`;
  if (size) { url += `&size=${size}`; }
  return url;
}

const QwikwireDispute = (props) => {
  const { match, user, location, history } = props;
  const { disputeId } = match.params;
  const [state, dispatch] = useReducer(reducer, initialState);

  useEffect(() => {
    let ableToSet = true;
    (async () => {
      if (ableToSet) {
        dispatch({ type: 'GET_DISPUTE' });
      }

      try {
        const { data: dispute } = await getDisputeById(disputeId);
        if (ableToSet) {
          dispatch({ type: 'GET_DISPUTE_SUCCESSFUL', dispute });
        }
      } catch (error) {
        const message = getAPIErrorMessage(error,
          'We are not able to get the dispute data from the server.');
        if (ableToSet) {
          dispatch({ type: 'GET_DISPUTE_FAILED', message });
        }
      }
    })();

    return () => { ableToSet = false; };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (state.isFetchingDispute) {
    return (
      <div>
        <div className="spinner">
          <span className="spinner-text">Loading...</span>
        </div>
      </div>
    );
  }

  if (!state.dispute) {
    return (
      <div>
        <div className="spinner">
          <FolderIcon />
          <h1>Dispute Not Found</h1>
          <p>
            You can contact us at&nbsp;
            <a href="mailto:support@aqwire.io">support@aqwire.io</a>
            &nbsp;if you have concerns or issues.
          </p>
        </div>
      </div>
    );
  }

  const { dispute } = state;
  return (
    <div className="qwikwire">
      <section className="transaction-breadcrumb">
        <div className="transaction-breadcrumb-icon">
          <DisputeIcon />
        </div>
        <Breadcrumb>
          <Breadcrumb.Item>
            <Link to="/disputes">Disputes</Link>
          </Breadcrumb.Item>
          <Breadcrumb.Item>
            {`#${dispute.id}`}
          </Breadcrumb.Item>
        </Breadcrumb>
      </section>
      <div style={{ margin: '12px 4px 32px' }}>
        <section className="panel panel-standard">
          <div className="panel-header">
            <Row>
              <Col lg={6} className="panel-item">
                <h3>Dispute Reference ID</h3>
                <div>{dispute.referenceId}</div>
              </Col>
              <Col lg={6} className="panel-item">
                <h3>{dispute.disputedPayment.customerName}</h3>
                <div>{dispute.disputedPayment.customerEmail}</div>
                {dispute.disputedPayment.customerPhone && <div>{` (${dispute.disputedPayment.customerPhone})`}</div>}
              </Col>
              <Col lg={6} className="panel-item">
                <h3>Date</h3>
                <div>
                  {((d) => {
                    const datetime = d.createdAt;
                    return moment(datetime).format('MMM. DD, YYYY HH:mm');
                  })(dispute)}
                </div>
              </Col>
              <Col lg={6} className="panel-item panel-item-end">
                <span
                  className="panel-badge"
                  style={{
                    fontWeight: 700,
                    color: '#ffffff',
                    backgroundColor: getStatusColor(dispute.status),
                  }}
                >
                  {dispute.status}
                </span>
              </Col>
            </Row>
          </div>
          <div className="panel-body">
            <Row>
              <Col lg={12}>
                <div className="panel-item">
                  <h3>Payment Reference ID</h3>
                  <div>
                    <Link to={`/payments/${dispute.disputedPayment.invoiceId}`} title="View payment">
                      {dispute.disputedPayment.paymentReferenceId}
                    </Link>
                  </div>
                </div>
                {dispute.disputedPayment.transactionReferenceId && dispute.disputedPayment.invoiceReferenceId && (
                  <div className="panel-item">
                    <h3>Enrollment QW ID</h3>
                    <div>
                      <Link to={`/enrollments/${dispute.disputedPayment.transactionId}`} title="View enrollment">
                        {dispute.disputedPayment.transactionReferenceId}
                      </Link>
                    </div>
                  </div>
                )}
                <div className="panel-item">
                  <h3>{dispute.disputedPayment.invoiceReferenceId ? 'Recurring Payment' : 'One-Time Payment'}</h3>
                  <div>
                    {dispute.disputedPayment.paymentTypeName}
                    {dispute.disputedPayment.paymentMode ? ` (${dispute.disputedPayment.paymentMode})` : ''}
                  </div>
                </div>
                <div className="panel-item">
                  <h3>Source</h3>
                  <div>
                    {((name) => {
                      switch (name) {
                        case 'api': return 'AQWIRE Access';
                        case 'merchant-portal': return 'Portals 2.0';
                        case 'portal3': return 'Portals 3.0';
                        case 'ayalaland-ma': return 'Ayala Land Enrollment';
                        case 'cxd': return 'Customer Dashboard';
                        default: return 'N/A';
                      }
                    })(dispute.disputedPayment.transactionSource)}
                  </div>
                </div>
                <div className="panel-item">
                  <h3>{dispute.disputedPayment.merchantName}</h3>
                  <div style={{ marginBottom: '8px' }}>
                    <div>{dispute.disputedPayment.projectName}</div>
                    {dispute.disputedPayment.projectCategory && <div>{dispute.disputedPayment.projectCategory}</div>}
                  </div>
                  {dispute.disputedPayment.customFields && (
                    <div className="panel-item-cellar">
                      {Object.keys(dispute.disputedPayment.customFields)
                        .map(key => (
                          <div key={key}>
                            {`${dispute.disputedPayment.customFields[key].label}: ${dispute.disputedPayment.customFields[key].value ? parseCustomValue(dispute.disputedPayment.customFields[key].value) : ''}`}
                          </div>
                        ))}
                    </div>
                  )}
                </div>
                <div className="panel-item">
                  <h3>Reason of Dispute</h3>
                  <div>{dispute.reason || 'No dispute notes.'}</div>
                </div>
              </Col>
              <Col lg={12}>
                <PaymentMethodDetails payment={dispute.disputedPayment} />
                <div className="panel-item-group">
                  <h3>Breakdown</h3>
                  <BreakdownItem label="Amount Due (PHP)" bill={dispute.disputedPayment.billBase} />
                  {dispute.disputedPayment.billConverted && dispute.disputedPayment.billConverted.length > 0 && dispute.disputedPayment.billConverted[1] > 0 && (
                    <Fragment>
                      {dispute.disputedPayment.qwxRate && dispute.disputedPayment.qwxRate.length > 0 && dispute.disputedPayment.qwxRate[0] !== dispute.disputedPayment.qwxRate[1] && (
                        <div className="panel-breakdown-item">
                          <div>Exchange rate</div>
                          <div>
                            <span>{`1 ${dispute.disputedPayment.qwxRate[0]} - ${dispute.disputedPayment.qwxRate[1]} ${formatNumber(dispute.disputedPayment.qwxRate[2])}`}</span>
                          </div>
                        </div>
                      )}
                      <BreakdownItem label="Converted Amount" bill={dispute.disputedPayment.billConverted} />
                    </Fragment>
                  )}
                  {dispute.disputedPayment.refundTotal && dispute.disputedPayment.paymentStatus === 'REFUNDED' && (
                    <BreakdownItem label="Refunded Amount" bill={dispute.disputedPayment.refundTotal} />
                  )}
                  <BreakdownItem label="Convenience Fee" bill={dispute.disputedPayment.billFee} />
                  <BreakdownItem label="Total Amount" bill={dispute.disputedPayment.billTotal} />
                  <BreakdownItem label="Waived Fee" hideOnEmpty bill={dispute.disputedPayment.waivedFee} />
                  <BreakdownItem
                    label="Net Amount"
                    tooltipDescription={`Net amount to be deposited on ${dispute.disputedPayment.merchantName}'s bank account`}
                    bill={dispute.disputedPayment.net}
                    highlight
                  />
                </div>
              </Col>
            </Row>
          </div>
          {dispute.status === 'PENDING' && (
            <div className="panel-footer -space-before">
              <UpdateDisputeModal dispute={dispute} history={props.history} />
            </div>
          )}
        </section>
      </div>
      <div style={{ margin: '12px 4px 32px' }}>
        <PaymentLogs
          merchant={{
            id: dispute.disputedPayment.merchantId,
            code: dispute.disputedPayment.merchantCode,
            name: dispute.disputedPayment.merchantCode,
          }}
          payment={dispute.disputedPayment}
          user={user}
          onChangeSearchOption={(searchOption) => {
            const url = getFilteredURLPath(location.pathname, searchOption);
            history.push(url, {});
          }}
          disableComment
        />
      </div>
    </div>
  );
};

QwikwireDispute.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      disputeId: PropTypes.string.isRequired,
    }),
  }).isRequired,
  location: PropTypes.shape({
    filter: PropTypes.shape({}),
    pathname: PropTypes.string.isRequired,
  }),
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }).isRequired,
  user: PropTypes.shape({
    id: PropTypes.number.isRequired,
    systemRole: PropTypes.array.isRequired,
  }).isRequired,
};

QwikwireDispute.defaultProps = {
  location: null,
};

export default QwikwireDispute;
