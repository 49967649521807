import moment from 'moment';
import React, { Fragment, useReducer, useEffect, useContext, useState, useCallback } from 'react';
import { debounce } from 'lodash';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import {
  Row, Col, Breadcrumb, Select, Input, Checkbox,
  Button, Form, Modal, Menu, Dropdown, Icon,
} from 'antd';
import { TagIcon, CloseIcon, FolderIcon } from '../../components/Icons';
import { SingleForm } from '../../components';
import DeleteProjectModal from '../../components/DeleteProjectModal';
import DisableProjectModal from '../../components/DisableProjectModal';
import PublishProjectModal from '../../components/PublishProjectModal';
import SessionContext from '../../contexts/SessionContext';
import { findProjectService, updateProjectMetadata } from '../../services/projects';
import { getAPIErrorMessage } from '../../helpers/utils';
import './project.scss';
import Message from '../../components/Message';

const initialState = {
  isFetchingProject: false,
  isPublishModalOpen: false,
  isDisableModalOpen: false,
  isDeleteModalOpen: false,
  isUpdatingProject: false,
  metadata: [],
  metadataSnapshot: [],
  project: null,
  status: null,
  message: null,
};

// for compiler check / autocompletion of different action types
const PROJECT_ACTIONS = {
  FIND_PROJECT: 'FIND_PROJECT',
  FIND_PROJECT_SUCCESS: 'FIND_PROJECT_SUCCESS',
  FIND_PROJECT_FAILED: 'FIND_PROJECT_FAILED',
  UPDATE_PROJECT_METADATA: 'UPDATE_PROJECT_METADATA',
  UPDATE_PROJECT_METADATA_SUCCESS: 'UPDATE_PROJECT_METADATA_SUCCESS',
  UPDATE_PROJECT_METADATA_FAILED: 'UPDATE_PROJECT_METADATA_FAILED',
  RESET_METADATA: 'RESET_METADATA',
  ADD_METADATA_FIELD: 'ADD_METADATA_FIELD',
  METADATA_TEXT_CHANGED: 'METADATA_TEXT_CHANGED',
  METADATA_VALUE_CHANGED: 'METADATA_VALUE_CHANGED',
  METADATA_FIELD_TYPE_CHANGED: 'METADATA_FIELD_TYPE_CHANGED',
  METADATA_BOOLEAN_CHANGED: 'METADATA_BOOLEAN_CHANGED',
  REMOVE_METADATA_FIELD: 'REMOVE_METADATA_FIELD',
  DISABLE_PROJECT_DONE: 'DISABLE_PROJECT_DONE',
  PUBLISH_PROJECT_DONE: 'PUBLISH_PROJECT_DONE',
  OPEN_PUBLISH_MODAL: 'OPEN_PUBLISH_MODAL',
  OPEN_DISABLE_MODAL: 'OPEN_DISABLE_MODAL',
  OPEN_DELETE_MODAL: 'OPEN_DELETE_MODAL',
  HIDE_MODAL: 'HIDE_MODAL',
  CLEAR_MESSAGE: 'CLEAR_MESSAGE',
};

const { Option } = Select;
const { Item } = Form;

function reducer(prevState, action) {
  switch (action.type) {
    case PROJECT_ACTIONS.FIND_PROJECT:
      return {
        ...prevState,
        isFetchingProject: true,
        message: null,
        status: null,
      };
    case PROJECT_ACTIONS.FIND_PROJECT_SUCCESS:
      return {
        ...prevState,
        isFetchingProject: false,
        project: action.project,
        metadata: action.metadata,
        metadataSnapshot: action.snapshot,
        message: null,
        status: null,
      };
    case PROJECT_ACTIONS.FIND_PROJECT_FAILED:
      return {
        ...prevState,
        isFetchingProject: false,
        project: null,
        message: action.message,
        status: 'error',
      };
    case PROJECT_ACTIONS.UPDATE_PROJECT_METADATA:
      return {
        ...prevState,
        isUpdatingProject: true,
      };
    case PROJECT_ACTIONS.UPDATE_PROJECT_METADATA_SUCCESS:
      return {
        ...prevState,
        isUpdatingProject: false,
        metadataSnapshot: action.snapshot,
        alertMessage: { component: 'Project', status: 'success', message: action.message },
      };
    case PROJECT_ACTIONS.UPDATE_PROJECT_METADATA_FAILED:
      return {
        ...prevState,
        isUpdatingProject: false,
        alertMessage: { component: 'Project', status: 'error', message: action.message },
      };
    case PROJECT_ACTIONS.RESET_METADATA:
      return {
        ...prevState,
        metadata: JSON.parse(JSON.stringify(prevState.metadataSnapshot)),
        isUpdatingProject: false,
      };
    case PROJECT_ACTIONS.ADD_METADATA_FIELD:
      return {
        ...prevState,
        metadata: [...prevState.metadata, {
          // make sure name will never have duplicates
          name: `newField_${Date.now()}`,
          text: 'New Field',
          value: 'New Value',
          typeHolder: 'string',
          boolHolder: true,
        }],
      };
    case PROJECT_ACTIONS.METADATA_TEXT_CHANGED:
      return {
        ...prevState,
        metadata: action.metadata,
      };
    case PROJECT_ACTIONS.METADATA_VALUE_CHANGED:
      return {
        ...prevState,
        metadata: action.metadata,
      };
    case PROJECT_ACTIONS.METADATA_FIELD_TYPE_CHANGED:
      return {
        ...prevState,
        metadata: action.metadata,
      };
    case PROJECT_ACTIONS.METADATA_BOOLEAN_CHANGED: {
      const tmp = prevState.metadata;
      tmp[action.index].boolHolder = !tmp[action.index].boolHolder;

      return {
        ...prevState,
        metadata: tmp,
      };
    }
    case PROJECT_ACTIONS.REMOVE_METADATA_FIELD:
      return {
        ...prevState,
        metadata: prevState.metadata.filter((data, index) => index !== action.index),
      };
    case PROJECT_ACTIONS.DISABLE_PROJECT_DONE:
      return {
        ...prevState,
        status: 'success',
        message: 'Project has been disabled',
        isDisableModalOpen: false,
        project: {
          ...prevState.project,
          isActive: false,
        },
        alertMessage: { component: 'Project', status: 'success', message: 'Project has been disabled' },
      };
    case PROJECT_ACTIONS.PUBLISH_PROJECT_DONE:
      return {
        ...prevState,
        status: 'success',
        message: 'Project has been published',
        isPublishModalOpen: false,
        project: {
          ...prevState.project,
          isActive: true,
        },
        alertMessage: { component: 'Project', status: 'success', message: 'Project has been published' },
      };
    case PROJECT_ACTIONS.OPEN_PUBLISH_MODAL:
      return {
        ...prevState,
        isPublishModalOpen: true,
      };
    case PROJECT_ACTIONS.OPEN_DISABLE_MODAL:
      return {
        ...prevState,
        isDisableModalOpen: true,
      };
    case PROJECT_ACTIONS.OPEN_DELETE_MODAL:
      return {
        ...prevState,
        isDeleteModalOpen: true,
      };
    case PROJECT_ACTIONS.HIDE_MODAL:
      return {
        ...prevState,
        isPublishModalOpen: false,
        isDisableModalOpen: false,
        isDeleteModalOpen: false,
      };
    case PROJECT_ACTIONS.CLEAR_MESSAGE:
      return {
        ...prevState,
        status: null,
        message: null,
      };
    default:
      return prevState;
  }
}

const Project = (props) => {
  const { match, merchants, form, history } = props;
  const {
    getFieldDecorator,
    validateFields,
    getFieldValue,
    resetFields,
    setFieldsValue,
  } = form;
  const { merchantCode, projectId } = match.params;

  const { loggedInUser, pushMessage, popMessage } = useContext(SessionContext);
  const [msg, setMsg] = useState(popMessage());
  const [state, dispatch] = useReducer(reducer, initialState);
  const { merchantRole } = loggedInUser.merchantRoles.find(m => m.merchantCode === merchantCode);
  const merchant = merchants.find(m => m.code === merchantCode);

  useEffect(() => {
    let ableToSet = true;

    // check if there is an alert message stored and display it
    if (state.alertMessage) {
      setMsg(state.alertMessage);
      pushMessage(state.alertMessage);
    }

    (async () => {
      try {
        if (!merchant && ableToSet) {
          dispatch({ type: PROJECT_ACTIONS.FIND_PROJECT_FAILED, message: 'Project does not exist' });
          return;
        }

        if (ableToSet) { dispatch({ type: PROJECT_ACTIONS.FIND_PROJECT }); }

        const { data } = await findProjectService(merchant.id, projectId);
        if (ableToSet) {
          const metadata = (data.projectFields?.fields || [])
            .filter(f => ['string', 'number', 'boolean'].includes(typeof f.value))
            .map(field => ({
              ...field,
              typeHolder: typeof field.value,
              boolHolder: Boolean(field.value),
            }));

          dispatch({
            type: PROJECT_ACTIONS.FIND_PROJECT_SUCCESS,
            project: data,
            snapshot: JSON.parse(JSON.stringify(metadata)),
            metadata,
          });
        }
      } catch (error) {
        const message = getAPIErrorMessage(error,
          'We are not able to get this project. Please try again later.');
        if (ableToSet) {
          dispatch({
            type: PROJECT_ACTIONS.FIND_PROJECT_FAILED,
            message,
          });
        }
      }
    })();

    return () => { ableToSet = false; };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.alertMessage]);

  const DEBOUNCE_DELAY = 500;

  // uses debouncing so that updating metadata item text runs only
  // after 500 milliseconds has lapsed from last typed character
  const debounceMetadataTextChange = useCallback(debounce((metadataState, value, index) => {
    const tmp = metadataState.map((item, idx) => {
      if (idx === index) {
        return { ...item, text: value };
      }
      return item;
    });
    dispatch({ type: PROJECT_ACTIONS.METADATA_TEXT_CHANGED, metadata: tmp });
  }, DEBOUNCE_DELAY), [state.metadata.text]);

  // uses debouncing so that updating metadata item value runs only
  // after 500 milliseconds has lapsed from last typed character
  const debounceMetadataValueChange = useCallback(debounce((metadataState, value, index) => {
    const tmp = metadataState.map((item, idx) => {
      if (idx === index) {
        return { ...item, value };
      }
      return item;
    });
    dispatch({ type: PROJECT_ACTIONS.METADATA_VALUE_CHANGED, metadata: tmp });
  }, DEBOUNCE_DELAY), [state.metadata.value, state.metadata.typeHolder]);

  function updateMetadata() {
    validateFields((err, values) => {
      if (err) {
        dispatch({ type: PROJECT_ACTIONS.UPDATE_PROJECT_METADATA_FAILED });
        return;
      }

      const metadataPayload = [];
      for (let i = 0; i < state.metadata.length; i += 1) {
        // Converts text to camel case
        const fieldName = values[`text__${i}`]
          .replace(/(?:^\w|[A-Z]|\b\w)/g, (word, index) => (index === 0 ? word.toLowerCase() : word.toUpperCase()))
          .replace(/\s+/g, '');

        const sanitizedValue = ((v) => {
          switch (state.metadata[i].typeHolder) {
            case 'boolean': return typeof v === 'undefined' ? values[`checkbox__${i}`] : v;
            case 'string': return String(v);
            case 'number': return Number(v);
            default: return null;
          }
        })(values[`value__${i}`]);

        const metadataItem = {
          name: fieldName,
          text: values[`text__${i}`],
          value: sanitizedValue,
        };

        metadataPayload.push(metadataItem);
      }

      updateProjectMetadata(merchant.id, projectId, metadataPayload)
        .then(({ data: { message } }) => {
          const snapshot = metadataPayload.map(field => ({
            ...field,
            typeHolder: typeof field.value,
            boolHolder: Boolean(field.value),
          }));
          dispatch({ type: PROJECT_ACTIONS.UPDATE_PROJECT_METADATA_SUCCESS, snapshot, message });
        })
        .catch((error) => {
          const message = getAPIErrorMessage(error, 'Could not update merchant project metadata');
          dispatch({ type: PROJECT_ACTIONS.UPDATE_PROJECT_METADATA_FAILED, message });
        });
    });
  }

  function resetData() {
    dispatch({ type: PROJECT_ACTIONS.RESET_METADATA });
    resetFields();
  }

  async function checkSimilarField(_, value) {
    return new Promise((resolve, reject) => {
      let counter = 0;

      if (!value.replace(/\s+/g, '')) {
        reject(new Error('Metadata field name is required'));
        return;
      }

      state.metadata.forEach((__, index) => {
        const transformedText = getFieldValue(`text__${index}`).replace(/\s+/g, '').toLowerCase();
        const transformedValue = value.replace(/\s+/g, '').toLowerCase();

        if (transformedText === transformedValue) {
          counter += 1;
        }
      });

      if (counter > 1) {
        reject(new Error('Cannot have similar metadata descriptions'));
      } else {
        resolve();
      }
    });
  }

  async function convertibleToNumber({ field }, value) {
    return new Promise((resolve, reject) => {
      const index = field.split('__').pop();

      if (state.metadata[Number(index)].typeHolder === 'number' && Number.isNaN(Number(value))) {
        reject(new Error('The provided value is not a valid number'));
      } else {
        resolve();
      }
    });
  }

  if (!merchant) {
    return (
      <SingleForm
        title="Merchant does not exist"
        subtitle="The page you requested could not be found. Please go back to Dashboard or contact us at support@aqwire.io."
        footer={{ show: true, to: '/', text: 'Back to Dashboard' }}
      />
    );
  }

  if (!state.project && !state.isFetchingProject) {
    return (
      <div>
        <div className="spinner">
          <FolderIcon />
          <h1>Project Not Found</h1>
          <p>
            You can contact us at&nbsp;
            <a href="mailto:support@aqwire.io">support@aqwire.io</a>
            &nbsp;if you have concerns or issues.
          </p>
        </div>
      </div>
    );
  }

  const project = state.project || {};
  const menu = (
    <Menu>
      <Menu.Item key="edit">
        <Link to={`/merchants/${merchantCode}/projects/${projectId}/edit`}>
          Edit
        </Link>
      </Menu.Item>
      {project.isActive ? (
        <Menu.Item key="disable" onClick={() => dispatch({ type: PROJECT_ACTIONS.OPEN_DISABLE_MODAL })}>
          <div>
            Disable
          </div>
        </Menu.Item>
      ) : (
        <Menu.Item key="publish" onClick={() => dispatch({ type: PROJECT_ACTIONS.OPEN_PUBLISH_MODAL })}>
          <div>
            Publish
          </div>
        </Menu.Item>
      )}
      <Menu.Item key="delete" onClick={() => dispatch({ type: PROJECT_ACTIONS.OPEN_DELETE_MODAL })}>
        <div>
          Delete
        </div>
      </Menu.Item>
    </Menu>
  );

  return (
    <div>
      <Row className="breadcrumb-row">
        <Col className="breadcrumb-header">
          <Breadcrumb className="breadcrumb-parent">
            <Breadcrumb.Item className="item-container">
              <div className="breadcrumb-item breadcrumb-icon">
                <TagIcon />
              </div>
              <div className="breadcrumb-item icon-label">
                Projects
              </div>
            </Breadcrumb.Item>
          </Breadcrumb>
        </Col>
      </Row>
      {msg && msg.status && msg.message && (
        <div style={{ marginBottom: '8px' }}>
          <Row>
            <Col span={24}>
              <Message
                message={msg.message}
                status={['error', 'success'].includes(msg.status) ? msg.status : 'info'}
                showIcon
                closable
                onClose={() => setMsg(null)}
              />
            </Col>
          </Row>
        </div>
      )}
      <div style={{ margin: '12px 4px 32px' }}>
        <section className="panel panel-standard">
          <div className="panel-header">
            <Row>
              <Col lg={12} className="panel-item">
                <h3>Name</h3>
                <div className="text-secondary">{project.name}</div>
              </Col>
              <Col lg={12} className="panel-item" style={{ display: 'flex', justifyContent: 'flex-end' }}>
                {merchantRole[0] === 10 && (
                  <Dropdown overlay={menu} trigger={['click']}>
                    <Button
                      type="primary"
                      className="button button-standard button-standard-outline"
                      style={{ minHeight: '16px', padding: '6px 20px', minWidth: 'auto' }}
                    >
                      Actions
                      <Icon type="down" />
                    </Button>
                  </Dropdown>
                )}
              </Col>
            </Row>
          </div>
          <div className="panel-body">
            <Row>
              <Col lg={12}>
                <Row>
                  <Col lg={6}>
                    <p className="text-primary"><strong>Status</strong></p>
                  </Col>
                  <Col>
                    <p>
                      <span
                        style={{
                          fontWeight: 700,
                          color: '#ffffff',
                          backgroundColor: project.isActive ? '#399DE5' : '#EB3800',
                          borderRadius: '4px',
                          padding: '4px 8px',
                        }}
                      >
                        {project.isActive ? 'Active' : 'Disabled'}
                      </span>
                    </p>
                  </Col>
                </Row>
                <Row>
                  <Col lg={6}>
                    <p className="text-primary"><strong>Updated at</strong></p>
                  </Col>
                  <Col>
                    <p className="text-secondary">{moment(project.modifiedAt).format('MMM. DD, YYYY hh:mm A')}</p>
                  </Col>
                </Row>
                <Row>
                  <Col lg={6}>
                    <p className="text-primary"><strong>Category</strong></p>
                  </Col>
                  <Col>
                    <p className="text-secondary">{project.category}</p>
                  </Col>
                </Row>
                <Row>
                  <Col lg={6}>
                    <p className="text-primary"><strong>Description</strong></p>
                  </Col>
                  <Col>
                    <p className="text-secondary">{project.description}</p>
                  </Col>
                </Row>
              </Col>
            </Row>
          </div>
        </section>
      </div>
      <div style={{ margin: '12px 4px 32px' }}>
        <section className="panel panel-standard">
          <div className="panel-header">
            <Row>
              <Col className="panel-item" style={{ paddingBottom: 0 }}>
                <h3>Metadata</h3>
              </Col>
            </Row>
          </div>
          <Form onReset={() => resetData()}>
            <div className="panel-body">
              <div className="panel-items">
                <Row>
                  <Col lg={9} className="panel-item -padded">
                    <h3>Name</h3>
                  </Col>
                  <Col lg={6} className="panel-item -padded">
                    <h3>Type</h3>
                  </Col>
                  <Col lg={9} className="panel-item -padded">
                    <h3>Value</h3>
                  </Col>
                </Row>
                {state.metadata && state.metadata.map((field, index) => (
                  <Row key={field.name}>
                    <Col lg={9} className="panel-item -padded">
                      <Item>
                        {
                          getFieldDecorator(
                            `text__${index}`,
                            {
                              rules: [{ validator: checkSimilarField }],
                              initialValue: field.text,
                            },
                          )(<Input
                            onChange={(event) => {
                              debounceMetadataTextChange(state.metadata, event.target.value, index);
                            }}
                          />)}
                      </Item>
                    </Col>
                    <Col lg={6} className="panel-item -padded">
                      <Item>
                        <Select
                          style={{ width: '100%' }}
                          value={field.typeHolder}
                          onChange={(value) => {
                            const tmp = state.metadata;

                            // fieldName refers to input component for metadata value
                            const fieldName = `value__${index}`;

                            tmp[index].typeHolder = value;
                            tmp[index].value = '';

                            // when changing from boolean to another type
                            // no need to set when boolean type is the last value before changing
                            // since it was set to '' before it became a boolean
                            if (field.typeHolder === 'boolean') {
                              setFieldsValue({ [fieldName]: '' });

                              // set to true so that when user decides to go back to boolean type,
                              // checkbox will always be checked at the start even if last state
                              // was not checked. e.g:
                              // 1. boolean selected but user removed checked state
                              // 2. user selects different type (e.g. number)
                              // 3. user goes back to boolean. checkbox should be checked
                              tmp[index].boolHolder = true;
                            }

                            dispatch({ type: PROJECT_ACTIONS.METADATA_FIELD_TYPE_CHANGED, metadata: tmp });
                          }}
                        >
                          <Option value="string">Text</Option>
                          <Option value="number">Number</Option>
                          <Option value="boolean">Boolean</Option>
                        </Select>
                      </Item>
                    </Col>
                    <Col lg={8} className="panel-item -padded">
                      <Item>
                        {
                          field.typeHolder === 'boolean'
                            ? getFieldDecorator(`checkbox__${index}`,
                              { valuePropName: 'checked', initialValue: field.boolHolder })(
                                <Checkbox onChange={() => dispatch({ type: PROJECT_ACTIONS.METADATA_BOOLEAN_CHANGED, index })} />,
                            )
                            : getFieldDecorator(`value__${index}`, {
                              initialValue: field.value,
                              rules: [{ validator: convertibleToNumber }],
                            })(<Input
                              onChange={(event) => {
                                debounceMetadataValueChange(state.metadata, event.target.value, index);
                              }}
                            />)}
                      </Item>
                    </Col>
                    <Col lg={1} className="panel-item -padded">
                      {merchantRole[0] === 10 && (
                        <button
                          type="button"
                          className="remove-icon"
                          onClick={() => {
                            // const tmp = state.metadata.filter((data, idx) => idx !== index);
                            dispatch({ type: PROJECT_ACTIONS.REMOVE_METADATA_FIELD, index });
                          }}
                        >
                          <CloseIcon />
                        </button>
                      )}
                    </Col>
                  </Row>
                ))}
              </div>
              {merchantRole[0] === 10 && (
                <Fragment>
                  <div className="add-button">
                    <Button
                      className="-link-button"
                      onClick={() => dispatch({ type: PROJECT_ACTIONS.ADD_METADATA_FIELD })}
                    >
                      Add New Item
                    </Button>
                  </div>
                  <div className="submit-button">
                    <Button
                      className="button button-standard"
                      disabled={state.isUpdatingProject}
                      loading={state.isUpdatingProject}
                      onClick={() => dispatch({ type: PROJECT_ACTIONS.UPDATE_PROJECT_METADATA })}
                    >
                      Save
                    </Button>
                    <Modal
                      className="dialog-modal"
                      title="Save Metadata"
                      visible={state.isUpdatingProject}
                      onCancel={() => dispatch({ type: PROJECT_ACTIONS.RESET_METADATA })}
                      footer={[
                        <Button
                          key="cancel"
                          htmlType="reset"
                          onClick={() => dispatch({ type: PROJECT_ACTIONS.RESET_METADATA })}
                        >
                          No
                        </Button>,
                        <Button
                          key="send"
                          type="primary"
                          htmlType="submit"
                          onClick={() => updateMetadata()}
                          loading={state.isProcessingRequest}
                        >
                          Yes
                        </Button>,
                      ]}
                    >
                      <Row>
                        <Col>
                          <p> Are you sure you want to save the metadata?</p>
                        </Col>
                      </Row>
                    </Modal>
                  </div>
                </Fragment>
              )}
            </div>
          </Form>
        </section>
      </div>
      {state.project && (
        <Fragment>
          <DeleteProjectModal
            merchant={merchant}
            project={project}
            isOpen={state.isDeleteModalOpen}
            onOk={() => {
              const alertMessage = { component: 'Projects', status: 'success', message: 'Project has been deleted' };
              pushMessage(alertMessage);
              history.push(`/merchants/${merchant.code}/projects`);
            }}
            onClose={() => { dispatch({ type: PROJECT_ACTIONS.HIDE_MODAL }); }}
          />
          <DisableProjectModal
            merchant={merchant}
            project={project}
            isOpen={state.isDisableModalOpen}
            onOk={() => { dispatch({ type: PROJECT_ACTIONS.DISABLE_PROJECT_DONE }); }}
            onClose={() => { dispatch({ type: PROJECT_ACTIONS.HIDE_MODAL }); }}
          />
          <PublishProjectModal
            merchant={merchant}
            project={project}
            isOpen={state.isPublishModalOpen}
            onOk={() => { dispatch({ type: PROJECT_ACTIONS.PUBLISH_PROJECT_DONE }); }}
            onClose={() => { dispatch({ type: PROJECT_ACTIONS.HIDE_MODAL }); }}
          />
        </Fragment>
      )}
    </div>
  );
};

Project.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      merchantCode: PropTypes.string.isRequired,
      projectId: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }).isRequired,
  form: PropTypes.shape({
    getFieldDecorator: PropTypes.func.isRequired,
    validateFields: PropTypes.func.isRequired,
    getFieldValue: PropTypes.func.isRequired,
    setFieldsValue: PropTypes.func.isRequired,
    resetFields: PropTypes.func.isRequired,
  }).isRequired,
  location: PropTypes.shape({
    pathname: PropTypes.string.isRequired,
  }).isRequired,
  merchants: PropTypes.arrayOf(PropTypes.any).isRequired,
};

export default Form.create()(Project);
