import React, { Fragment, useEffect, useReducer, useContext } from 'react';
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Dropdown, Icon, Menu, Table, Button } from 'antd';

import { CheckCircleIcon, TimesCircleIcon } from '../Icons';
import ManagePaymentTypeModal from '../ManagePaymentTypeModal';
import { getPaymentTypesService, updatePaymentTypeState } from '../../services/merchants';
import SessionContext from '../../contexts/SessionContext';

import '../../styles/transaction.css';

const initialState = {
  isFetchingPaymentTypes: true,
  isUpdatingPaymentTypeState: false,
  paymentTypes: [],
  selectedPaymentType: {},
  modalIsVisible: false,
  isEditing: true,
};

function reducer(prevState, action) {
  switch (action.type) {
    case 'GET_PAYMENT_TYPES':
      return {
        ...prevState,
        isFetchingPaymentTypes: true,
      };
    case 'GET_PAYMENT_TYPES_SUCCESSFUL':
      return {
        ...prevState,
        isFetchingPaymentTypes: false,
        paymentTypes: action.paymentTypes,
      };
    case 'GET_PAYMENT_TYPES_FAILED':
      return {
        ...prevState,
        isFetchingPaymentTypes: false,
        paymentTypes: [],
      };
    case 'UPDATE_PAYMENT_TYPE_STATE':
      return {
        ...prevState,
        isUpdatingPaymentTypeState: true,
      };
    case 'PAYMENT_TYPE_STATE_SUCCESSFUL':
      return {
        ...prevState,
        isUpdatingPaymentTypeState: false,
      };
    case 'PAYMENT_TYPE_STATE_FAIL':
      return {
        ...prevState,
        isUpdatingPaymentTypeState: false,
      };
    case 'SHOW_MODAL':
      return {
        ...prevState,
        modalIsVisible: true,
        selectedPaymentType: action.selectedPaymentType,
        isEditing: Object.keys(action.selectedPaymentType).length !== 0,
      };
    case 'HIDE_MODAL':
      return {
        ...prevState,
        modalIsVisible: false,
        selectedPaymentType: {},
      };
    default:
      return prevState;
  }
}

const PaymentTypeTable = (props) => {
  const { merchant } = props;
  const history = useHistory();
  const [state, dispatch] = useReducer(reducer, initialState);
  const { pushMessage } = useContext(SessionContext);

  useEffect(() => {
    let ableToSet = true;
    (async () => {
      try {
        const { data: { paymentTypes } } = await getPaymentTypesService(merchant.id);

        if (ableToSet) {
          dispatch({
            type: 'GET_PAYMENT_TYPES_SUCCESSFUL',
            paymentTypes: paymentTypes.sort((a, b) => {
              if (a.code > b.code) { return 1; }
              if (a.code < b.code) { return -1; }
              return 0;
            }).map(({ id, code, name, isEnabled }) => ({
              id,
              name,
              isEnabled,
              code: code.replace(`${merchant.code.toUpperCase()}_`, ''),
            })),
          });
        }
      } catch (error) {
        const message = error && error.response ? error.response.data.message
          : 'We are not able to get the payment types from the server as of the moment';
        if (ableToSet) {
          dispatch({ type: 'GET_PAYMENT_TYPES_FAILED', message });
        }
      }
    })();

    return () => { ableToSet = false; };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  async function changePaymentTypeState(paymentType) {
    const { id, isEnabled } = paymentType;
    dispatch({ type: 'UPDATE_PAYMENT_TYPE_STATE' });

    try {
      await updatePaymentTypeState(merchant.id, id, isEnabled);
      dispatch({ type: 'PAYMENT_TYPE_STATE_SUCCESSFUL' });
      pushMessage({ component: 'QwikwireMerchant', status: 'success', message: `Payment type ${isEnabled ? 'enabled' : 'disabled'}` });
      history.push(`/merchants/${merchant.code}`);
    } catch (error) {
      const message = error && error.response ? error.response.data.message
        : 'Unable to change the payment type status';
      dispatch({ type: 'PAYMENT_TYPE_STATE_FAIL' });
      pushMessage({ component: 'QwikwireMerchant', status: 'error', message });
    }
  }

  const columns = [
    {
      title: 'Code',
      key: 'code',
      render: row => (
        <div>
          <code>{row.code}</code>
        </div>
      ),
    }, {
      title: 'Name',
      key: 'name',
      render: row => <div>{row.name}</div>,
    }, {
      title: 'Enabled',
      key: 'enabled',
      render: row => (
        <div className="table-col-item">
          {row.isEnabled
            ? <CheckCircleIcon className="table-col-item-icon" />
            : <TimesCircleIcon className="table-col-item-icon" />
          }
        </div>
      ),
    }, {
      key: 'action',
      render: (row) => {
        const menu = (
          <Menu>
            <Menu.Item
              disabled={state.isUpdatingPaymentTypeState}
              onClick={() => changePaymentTypeState({
                ...row,
                isEnabled: !row.isEnabled,
              })}
            >
              {`${row.isEnabled ? 'Disable' : 'Enable'} Payment Type`}
            </Menu.Item>
            <Menu.Item
              onClick={() => {
                // Remove the merchant code prefix on payment type code
                const shortenedCode = row.code.replace(`${merchant.code.toUpperCase()}_`, '');

                dispatch({
                  type: 'SHOW_MODAL',
                  selectedPaymentType: {
                    id: row.id,
                    name: row.name,
                    code: shortenedCode,
                  },
                });
              }}
            >
              Edit Payment Type
            </Menu.Item>
          </Menu>
        );

        return (
          <div>
            <Dropdown overlay={menu} trigger={['click']}>
              <Icon type="more" />
            </Dropdown>
          </div>
        );
      },
    },
  ];

  return (
    <Fragment>
      <div
        className="panel-header"
        style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}
      >
        <h3 style={{ margin: 'auto 0' }}>
          Payment Types
        </h3>
        <Button
          type="primary"
          className="button button-standard button-standard-outline button-small"
          icon="add"
          onClick={() => dispatch({ type: 'SHOW_MODAL', selectedPaymentType: {} })}
        >
          <Icon type="plus" />
          <span>New</span>
        </Button>
      </div>
      <Table
        className="table-standard"
        dataSource={state.paymentTypes}
        loading={state.isFetchingPaymentTypes}
        columns={columns}
        pagination={false}
        rowKey="code"
      />
      <ManagePaymentTypeModal
        merchant={merchant}
        paymentType={state.selectedPaymentType}
        isVisible={state.modalIsVisible}
        isEditing={state.isEditing}
        onCancel={() => dispatch({ type: 'HIDE_MODAL' })}
        onDone={({ message, status }) => {
          pushMessage({ component: 'QwikwireMerchant', status, message });
          history.push(`/merchants/${merchant.code}`);
        }}
      />
    </Fragment>
  );
};

PaymentTypeTable.propTypes = {
  merchant: PropTypes.shape({
    id: PropTypes.number.isRequired,
    code: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
  }).isRequired,
};

export default PaymentTypeTable;
