import React, { useReducer, useContext } from 'react';
import PropTypes from 'prop-types';
import { Link, withRouter } from 'react-router-dom';
import qs from 'query-string';
import {
  Button, Form, Input,
} from 'antd';
import SessionContext from '../../contexts/SessionContext';
import { SingleForm } from '../../components';
import '../../styles/authform.css';

const formItemLayout = { wrapperCol: { xs: { span: 24 } } };
const initialState = {
  isLoggingIn: false,
  errorMessage: null,
};

function reducer(prevState, action) {
  switch (action.type) {
    case 'LOGIN':
      return {
        ...prevState,
        isLoggingIn: true,
      };
    case 'LOGIN_FAILED':
      return {
        ...prevState,
        isLoggingIn: false,
        errorMessage: action.message,
      };
    case 'CLEAR_MESSAGE':
      return {
        ...prevState,
        errorMessage: null,
      };
    default:
      return prevState;
  }
}

const LogIn = (props) => {
  const { history, location, form } = props;
  const { getFieldDecorator, validateFields } = form;
  const queries = qs.parse(location.search, { ignoreQueryPrefix: true });
  const [state, dispatch] = useReducer(reducer, initialState);
  const { showToast, login } = useContext(SessionContext);

  function onLogIn(event) {
    event.preventDefault();
    dispatch({ type: 'LOGIN' });
    validateFields((err, values) => {
      if (err) {
        dispatch({ type: 'LOGIN_FAILED', message: 'The email address or password are not valid' });
        return;
      }

      const { email, password } = values;
      login({ email, password }, (result) => {
        const { status, message } = result;
        switch (status) {
          case 'success':
            history.push('/');
            break;
          default:
            dispatch({ type: 'LOGIN_FAILED', message });
            showToast({ type: 'error', message });
            break;
        }
      });
    });
  }

  return (
    <SingleForm>
      <Form onSubmit={onLogIn}>
        <Form.Item {...formItemLayout}>
          {getFieldDecorator('email', {
            rules: [{ required: true, message: 'Please input your email' }],
            initialValue: queries && queries.email ? queries.email : '',
          })(<Input type="email" placeholder="Email Address" required />)}
        </Form.Item>
        <Form.Item {...formItemLayout}>
          {getFieldDecorator('password', {
            rules: [{ required: true, message: 'Please input your password' }],
          })(<Input.Password placeholder="Password" required />)}
        </Form.Item>
        <Form.Item style={{ marginBottom: '36px' }}>
          <Link className="single-form-card-link" to="/forgot-password">
            Forgot Password
          </Link>
        </Form.Item>
        <Form.Item>
          <Button
            className="button button-standard button-fullwidth"
            htmlType="submit"
            disabled={state.isLoggingIn}
            loading={state.isLoggingIn}
          >
            Login
          </Button>
        </Form.Item>
      </Form>
    </SingleForm>
  );
};

LogIn.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }).isRequired,
  form: PropTypes.shape({
    getFieldDecorator: PropTypes.func.isRequired,
    validateFields: PropTypes.func.isRequired,
  }).isRequired,
  location: PropTypes.shape({
    search: PropTypes.string.isRequired,
  }).isRequired,
};

LogIn.defaultProps = {};

export default withRouter(Form.create()(LogIn));
