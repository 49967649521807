import moment from 'moment';
import React, { useReducer, useEffect, Fragment } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { Col, Row, Breadcrumb, Alert } from 'antd';
import PaymentLogs from '../../components/PaymentLogs';
import PaymentSettlementDetails from '../../components/PaymentSettlementDetails';
import PaymentMethodDetails from '../../components/PaymentMethodDetails';
import RefundModal from '../../components/RefundModal';
import SendReceiptModal from '../../components/SendReceiptModal';
import CompleteOfflinePaymentModal from '../../components/CompleteOfflinePaymentModal';
import BreakdownItem from '../../components/BreakdownItem';
import DisputeModal from '../../components/DisputeModal';
import { PaymentIcon, FolderIcon } from '../../components/Icons';
import { getPaymentByInvoiceId } from '../../services/payments';
import { getDisputeByInvoiceId } from '../../services/disputes';
import { formatNumber, getAPIErrorMessage, getStatusColor, parseCustomValue } from '../../helpers/utils';

const initialState = {
  isFetchingPayment: false,
  payment: null,
  isPaymentDisputed: false,
  dispute: null,
};

function reducer(prevState, action) {
  switch (action.type) {
    case 'GET_PAYMENT':
      return {
        ...prevState,
        isFetchingPayment: true,
        payment: null,
      };

    case 'GET_PAYMENT_SUCCESSFUL':
      return {
        ...prevState,
        isFetchingPayment: false,
        payment: action.payment,
      };

    case 'GET_DISPUTE_SUCCESSFUL':
      return {
        ...prevState,
        isFetchingPayment: false,
        isPaymentDisputed: true,
        dispute: action.dispute,
      };

    case 'GET_PAYMENT_FAILED':
      return {
        ...prevState,
        isFetchingPayment: false,
        payment: null,
      };

    default:
      return prevState;
  }
}

function getFilteredURLPath(path, searchOption) {
  const { page, size } = searchOption;
  let url = `${path}?page=${page || 1}`;
  if (size) { url += `&size=${size}`; }
  return url;
}

const QwikwirePayment = (props) => {
  const { match, user, location, history } = props;
  const { invoiceId } = match.params;
  const [state, dispatch] = useReducer(reducer, initialState);

  useEffect(() => {
    let ableToSet = true;
    (async () => {
      if (ableToSet) {
        dispatch({ type: 'GET_PAYMENT' });
      }

      try {
        const { data: payment } = await getPaymentByInvoiceId(invoiceId);
        if (ableToSet) {
          dispatch({ type: 'GET_PAYMENT_SUCCESSFUL', payment });
        }

        if (payment.paymentStatus === 'DISPUTED') {
          const { data: dispute } = await getDisputeByInvoiceId(invoiceId);

          if (ableToSet) {
            dispatch({ type: 'GET_DISPUTE_SUCCESSFUL', dispute });
          }
        }
      } catch (error) {
        const message = getAPIErrorMessage(error,
          'We are not able to get the payment data from the server.');
        if (ableToSet) {
          dispatch({ type: 'GET_PAYMENT_FAILED', message });
        }
      }
    })();

    return () => { ableToSet = false; };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (state.isFetchingPayment) {
    return (
      <div>
        <div className="spinner">
          <span className="spinner-text">Loading...</span>
        </div>
      </div>
    );
  }

  if (!state.payment) {
    return (
      <div>
        <div className="spinner">
          <FolderIcon />
          <h1>Payment Not Found</h1>
          <p>
            You can contact us at&nbsp;
            <a href="mailto:support@aqwire.io">support@aqwire.io</a>
            &nbsp;if you have concerns or issues.
          </p>
        </div>
      </div>
    );
  }

  const { payment } = state;
  const showDisputeWarnings = state.isPaymentDisputed && state.dispute && state.dispute.status === 'PENDING';
  return (
    <div className="qwikwire">
      <section className="transaction-breadcrumb">
        <div className="transaction-breadcrumb-icon">
          <PaymentIcon height="28" width="30" />
        </div>
        <Breadcrumb>
          <Breadcrumb.Item>
            <Link to="/payments">All Payments</Link>
          </Breadcrumb.Item>
          <Breadcrumb.Item>
            {`#${payment.invoiceId}`}
          </Breadcrumb.Item>
        </Breadcrumb>
      </section>
      {showDisputeWarnings && (
        <div>
          <Row>
            <Col span={24}>
              <Alert
                message={(
                  <div>
                    This payment has been marked as disputed with reference number:
                    <Link to={`/disputes/${state.dispute.id}`} target="_blank">
                      {` ${state.dispute.referenceId}`}
                    </Link>
                  </div>
                )}
                type="warning"
                showIcon
              />
            </Col>
          </Row>
        </div>
      )}
      <div style={{ margin: '12px 4px 32px' }}>
        <section className="panel panel-standard">
          <div className="panel-header">
            <Row>
              <Col lg={6} className="panel-item">
                <h3>Reference ID</h3>
                <div>{payment.paymentReferenceId}</div>
              </Col>
              <Col lg={6} className="panel-item">
                <h3>{payment.customerName}</h3>
                <div>{payment.customerEmail}</div>
                {payment.customerPhone && <div>{` (${payment.customerPhone})`}</div>}
              </Col>
              <Col lg={6} className="panel-item">
                <h3>Date</h3>
                <div>
                  {((p) => {
                    const datetime = p.paidAt || p.invoiceCreatedAt;
                    return moment(datetime).format('MMM. DD, YYYY HH:mm');
                  })(payment)}
                </div>
              </Col>
              <Col lg={6} className="panel-item panel-item-end">
                <span
                  className="panel-badge"
                  style={{
                    fontWeight: 700,
                    color: '#ffffff',
                    backgroundColor: getStatusColor(payment.paymentStatus),
                  }}
                >
                  {payment.paymentStatus}
                </span>
              </Col>
            </Row>
          </div>
          <div className="panel-body">
            <Row>
              <Col lg={12}>
                <div className="panel-item">
                  <h3>Transaction ID</h3>
                  <div>
                    {payment.externalTransactionId}
                  </div>
                </div>
                {payment.transactionReferenceId && payment.invoiceReferenceId && (
                  <div className="panel-item">
                    <h3>Enrollment QW ID</h3>
                    <div>
                      <Link to={`/enrollments/${payment.transactionId}`} title="View enrollment">
                        {payment.transactionReferenceId}
                      </Link>
                    </div>
                  </div>
                )}
                <div className="panel-item">
                  <h3>{payment.invoiceReferenceId ? 'Recurring Payment' : 'One-Time Payment'}</h3>
                  <div>
                    {payment.paymentTypeName}
                    {payment.paymentMode ? ` (${payment.paymentMode})` : ''}
                  </div>
                </div>
                <div className="panel-item">
                  <h3>Source</h3>
                  <div>
                    {((name) => {
                      switch (name) {
                        case 'api': return 'AQWIRE Access';
                        case 'merchant-portal': return 'Portals 2.0';
                        case 'portal3': return 'Portals 3.0';
                        case 'ayalaland-ma': return 'Ayala Land Enrollment';
                        case 'cxd': return 'Customer Dashboard';
                        case 'payment-link': return 'Payment Link';
                        case 'dashboard-partner': return 'Partner Reference API';
                        default: return 'N/A';
                      }
                    })(payment.transactionSource)}
                  </div>
                </div>
                <div className="panel-item">
                  <h3>{payment.merchantName}</h3>
                  <div style={{ marginBottom: '8px' }}>
                    <div>{payment.projectName}</div>
                    {payment.projectCategory && <div>{payment.projectCategory}</div>}
                  </div>
                  {payment.customFields && (
                    <div className="panel-item-cellar">
                      {Object.keys(payment.customFields)
                        .map(key => (
                          <div key={key}>
                            {`${payment.customFields[key].label}: ${payment.customFields[key].value ? parseCustomValue(payment.customFields[key].value) : ''}`}
                          </div>
                        ))}
                    </div>
                  )}
                </div>
                <div className="panel-item">
                  <h3>Notes</h3>
                  <div style={{ paddingRight: '8ch' }}>{payment.clientNotes || 'There are no notes.'}</div>
                </div>
              </Col>
              <Col lg={12}>
                <PaymentMethodDetails payment={payment} />
                <div className="panel-item-group">
                  <h3>Breakdown</h3>
                  <BreakdownItem label="Amount Due" bill={payment.billBase} />
                  {payment.billConverted && payment.billConverted.length > 0 && payment.billConverted[1] > 0 && (
                    <Fragment>
                      {payment.qwxRate && payment.qwxRate.length > 0 && payment.qwxRate[0] !== payment.qwxRate[1] && (
                        <div className="panel-breakdown-item">
                          <div>Exchange rate</div>
                          <div>
                            <span>{`1 ${payment.qwxRate[0]} - ${payment.qwxRate[1]} ${formatNumber(payment.qwxRate[2])}`}</span>
                          </div>
                        </div>
                      )}
                      <BreakdownItem label="Converted Amount" bill={payment.billConverted} />
                    </Fragment>
                  )}
                  {payment.refundTotal && payment.paymentStatus === 'REFUNDED' && (
                    <BreakdownItem label="Refunded Amount" bill={payment.refundTotal} />
                  )}
                  <BreakdownItem label="Convenience Fee" bill={payment.billFee} />
                  <BreakdownItem label="Total Amount" bill={payment.billTotal} />
                  <BreakdownItem label="Waived Fee" hideOnEmpty bill={payment.waivedFee} />
                  <BreakdownItem
                    label="Net Amount"
                    tooltipDescription={`Net amount to be deposited on ${payment.merchantName}'s bank account`}
                    bill={payment.net}
                    highlight
                  />
                </div>
              </Col>
            </Row>
          </div>
          {['PAID', 'PENDING', 'SETTLED'].includes(payment.paymentStatus) && (
            <div className="panel-footer -space-between">
              <SendReceiptModal payment={payment} />
              <DisputeModal payment={payment} history={props.history} />
              <RefundModal payment={payment} history={props.history} />
              <CompleteOfflinePaymentModal payment={payment} history={props.history} />
            </div>
          )}
        </section>
      </div>
      <div style={{ margin: '12px 4px 32px' }}>
        <PaymentSettlementDetails
          merchant={{
            id: payment.merchantId,
            code: payment.merchantCode,
            name: payment.merchantCode,
          }}
          payment={payment}
          user={user}
        />
      </div>
      <div style={{ margin: '12px 4px 32px' }}>
        <PaymentLogs
          merchant={{
            id: payment.merchantId,
            code: payment.merchantCode,
            name: payment.merchantCode,
          }}
          payment={payment}
          user={user}
          onChangeSearchOption={(searchOption) => {
            const url = getFilteredURLPath(location.pathname, searchOption);
            history.push(url, {});
          }}
        />
      </div>
    </div>
  );
};

QwikwirePayment.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      invoiceId: PropTypes.string.isRequired,
    }),
  }).isRequired,
  location: PropTypes.shape({
    filter: PropTypes.shape({}),
    pathname: PropTypes.string.isRequired,
  }),
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }).isRequired,
  user: PropTypes.shape({
    id: PropTypes.number.isRequired,
  }).isRequired,
};

QwikwirePayment.defaultProps = {
  location: null,
};

export default QwikwirePayment;
