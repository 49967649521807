import React, { useContext, useEffect, useReducer } from 'react';
import PropTypes from 'prop-types';
import { Breadcrumb, Col, Row, Form, Switch } from 'antd';
import { FolderIcon, BellIcon } from '../../components/Icons';
import SingleForm from '../../components/SingleForm';
import SessionContext from '../../contexts/SessionContext';
import {
  getMerchantMemberNotificationsSettings,
  updateMerchantMemberNotifications,
} from '../../services/merchants';
import { ROLES, MERCHANT_ROLES } from '../../constants/index';
import './notificationsettings.css';

const formItemLayout = { labelCol: { span: 24 }, wrapperCol: { span: 24 } };
const FormItem = Form.Item;

const NotificationSettings = (props) => {
  const { match } = props;
  const { merchants, loggedInUser, showToast } = useContext(SessionContext);
  const merchant = merchants.find(m => m.code === match.params.merchantCode);
  const [state, dispatch] = useReducer((prevState, action) => {
    switch (action.type) {
      case 'GET_SETTINGS':
        return {
          ...prevState,
          isFetching: true,
        };
      case 'GET_SETTINGS_SUCCESSFUL':
        return {
          ...prevState,
          isFetching: false,
          canReceiveDailyTransactionEmails: action.canReceiveDailyTransactionEmails,
          canReceivePaymentEmails: action.canReceivePaymentEmails,
          canReceiveSettlementEmails: action.canReceiveSettlementEmails,
        };
      case 'GET_SETTINGS_FAILED':
        return {
          ...prevState,
          isFetching: false,
          canReceiveDailyTransactionEmails: false,
          canReceiveSettlementEmails: false,
        };
      case 'SET_SETTLEMENT_SETTING':
        return {
          ...prevState,
          canReceiveSettlementEmails: action.value,
        };
      case 'SET_PAYMENT_SETTING':
        return {
          ...prevState,
          canReceivePaymentEmails: action.value,
        };
      case 'SET_DAILY_NOTIF_SETTING':
        return {
          ...prevState,
          canReceiveDailyTransactionEmails: action.value,
        };
      case 'UPDATE_SETTINGS':
        return {
          ...prevState,
          isSaving: true,
        };
      case 'UPDATE_SETTINGS_SUCCESSFUL':
        return {
          ...prevState,
          isSaving: false,
        };
      case 'UPDATE_SETTINGS_FAILED':
        return {
          ...prevState,
          isSaving: false,
        };
      default:
        return prevState;
    }
  }, {
    isFetching: false,
    isSaving: false,
    canReceiveDailyTransactionEmails: false,
    canReceivePaymentEmails: false,
    canReceiveSettlementEmails: false,
  });


  const { systemRole, merchantRoles } = loggedInUser;
  const { merchantRole } = merchantRoles.find(m => m.merchantCode === merchant.code);

  useEffect(() => {
    let ableToSet = true;
    dispatch({ type: 'GET_SETTINGS' });

    (async () => {
      try {
        const {
          data: {
            canReceiveDailyTransactionEmails,
            canReceivePaymentEmails,
            canReceiveSettlementEmails,
          },
        } = await getMerchantMemberNotificationsSettings(merchant.id, loggedInUser.id);

        if (ableToSet) {
          dispatch({ type: 'GET_SETTINGS_SUCCESSFUL', canReceiveDailyTransactionEmails, canReceivePaymentEmails, canReceiveSettlementEmails });
        }
      } catch (error) {
        let message = error && error.response && error.response.data.message;
        message = message || 'Unknown error occured';
        dispatch({ type: 'GET_SETTINGS_FAILED', message });

        if (ableToSet) {
          showToast({ type: 'error', message });
        }
      }
    })();

    return () => { ableToSet = false; };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  async function saveNotificationSettings(canReceiveDailyTransactionEmails, canReceivePaymentEmails, canReceiveSettlementEmails) {
    dispatch({ type: 'UPDATE_SETTINGS' });
    try {
      await updateMerchantMemberNotifications(
        canReceiveDailyTransactionEmails,
        canReceivePaymentEmails,
        canReceiveSettlementEmails,
        merchant.id,
      );
      dispatch({ type: 'UPDATE_SETTINGS_SUCCESSFUL' });
    } catch (error) {
      const message = error && error.response
        ? error.response.data.message
        : 'Unable to save your notification settings as of the moment';
      dispatch({ type: 'UPDATE_SETTINGS_FAILED', message });
      showToast({ type: 'error', message });
    }
  }

  if (!(loggedInUser && merchants.length)) { return <div />; }
  if (systemRole[0] >= 80
    || (systemRole[0] === ROLES.USER && merchantRole[0] > MERCHANT_ROLES.ANALYST)
    || !merchant
  ) {
    return (
      <div>
        <div className="spinner">
          <FolderIcon />
          <h1>You are not allowed to access the notifications settings page.</h1>
          <p>
            You can contact us at
            <a href="mailto:support@aqwire.io">support@aqwire.io</a>
            if you have concerns or issues.
          </p>
        </div>
      </div>
    );
  }

  if (!merchant.canManageNotificationSettings) {
    return (
      <SingleForm
        title="Notification management is disabled for this merchant"
        subtitle="Please go back to Dashboard or contact us at support@aqwire.io."
        footer={{ show: true, to: '/', text: 'Back to Dashboard' }}
      />
    );
  }

  return (
    <div>
      <Row className="breadcrumb-row">
        <Col className="breadcrumb-header">
          <Breadcrumb className="breadcrumb-parent">
            <Breadcrumb.Item className="item-container">
              <div className="breadcrumb-item breadcrumb-icon">
                <BellIcon />
              </div>
              <div className="breadcrumb-item icon-label">
                Notifications
              </div>
            </Breadcrumb.Item>
          </Breadcrumb>
        </Col>
      </Row>
      {!state.isFetching && (
        <div>
          <section className="panel panel-standard">
            <div className="panel-header">
              <div style={{ display: 'flex', justifyContent: 'flex-start' }}>
                <h4 style={{ display: 'flex', flexDirection: 'column', marginTop: '4px', marginBottom: 0 }}>
                  <div className="heading-primary" style={{ lineHeight: 1.24 }}>
                    Email notifications
                  </div>
                </h4>
              </div>
            </div>
            <div className="panel-body">
              <Form>
                <FormItem {...formItemLayout}>
                  Receive transaction summary report daily on your inbox
                  <Switch
                    style={{ float: 'right' }}
                    defaultChecked={state.canReceiveDailyTransactionEmails}
                    onChange={
                      (value) => {
                        dispatch({ type: 'SET_DAILY_NOTIF_SETTING', value });
                        saveNotificationSettings(value, state.canReceivePaymentEmails, state.canReceiveSettlementEmails);
                      }
                    }
                  />
                </FormItem>
                <FormItem {...formItemLayout}>
                  Receive email notifications on new payment from Payment Portals
                  <Switch
                    style={{ float: 'right' }}
                    defaultChecked={state.canReceivePaymentEmails}
                    onChange={
                      (value) => {
                        dispatch({ type: 'SET_PAYMENT_SETTING', value });
                        saveNotificationSettings(state.canReceiveDailyTransactionEmails, value, state.canReceiveSettlementEmails);
                      }
                    }
                  />
                </FormItem>
                <FormItem {...formItemLayout}>
                  Receive email notifications on new settlement reports
                  <Switch
                    style={{ float: 'right' }}
                    defaultChecked={state.canReceiveSettlementEmails}
                    onChange={
                      (value) => {
                        dispatch({ type: 'SET_SETTLEMENT_SETTING', value });
                        saveNotificationSettings(state.canReceiveDailyTransactionEmails, state.canReceivePaymentEmails, value);
                      }
                    }
                  />
                </FormItem>
              </Form>
            </div>
          </section>
        </div>
      )}
    </div>
  );
};

NotificationSettings.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      merchantCode: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }).isRequired,
  location: PropTypes.shape({
    pathname: PropTypes.string.isRequired,
    search: PropTypes.string.isRequired,
  }).isRequired,
  form: PropTypes.shape({
    getFieldDecorator: PropTypes.func.isRequired,
    validateFields: PropTypes.func.isRequired,
  }).isRequired,
};

export default Form.create()(NotificationSettings);
