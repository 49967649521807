import moment from 'moment';
import React, { useEffect, useReducer } from 'react';
import PropTypes from 'prop-types';
import { Breadcrumb, Col, DatePicker, Row } from 'antd';
import { DashboardLineChart, PaymentBarChart } from '../../components';
import { DashboardIcon, CloseIcon } from '../../components/Icons';
import SingleForm from '../../components/SingleForm';
import { APP_STATES, HIDE_WHAT_IS_NEW_MESSAGE } from '../../constants';
import '../../styles/transaction.css';

const dateFormat = 'YYYY/MM/DD';
const { RangePicker } = DatePicker;

const hideWhatIsMessage = localStorage.getItem(HIDE_WHAT_IS_NEW_MESSAGE);
const initialState = {
  startDate: moment().subtract(14, 'days'),
  endDate: moment(),
  hideWhatIsNew: hideWhatIsMessage === 'true',
};

function reducer(prevState, action) {
  switch (action.type) {
    case 'SET_DATES':
      return {
        ...prevState,
        startDate: action.startDate,
        endDate: action.endDate,
      };
    case 'CLOSE_WHAT_IS_NEW':
      return {
        ...prevState,
        hideWhatIsNew: true,
      };
    default:
      return prevState;
  }
}

const Dashboard = (props) => {
  const { appState, merchants } = props;
  const { merchantCode } = props.match.params;
  const [state, dispatch] = useReducer(reducer, initialState);

  useEffect(() => {
    localStorage.setItem(HIDE_WHAT_IS_NEW_MESSAGE, true);
  }, [state.showWhatIsNew]);

  if (appState < APP_STATES.AUTHENTICATED) {
    return <div />;
  }

  const merchant = merchants.find(m => m.code === merchantCode);
  if (!merchant) {
    return (
      <SingleForm
        title="Merchant does not exist"
        subtitle="The page you requested could not be found. Please go back to Dashboard or contact us at support@aqwire.io."
        footer={{ show: true, to: '/', text: 'Back to Dashboard' }}
      />
    );
  }

  return (
    <div>
      <Row className="breadcrumb-row">
        <Col span={8} className="breadcrumb-header">
          <Breadcrumb className="breadcrumb-parent">
            <Breadcrumb.Item className="item-container">
              <div style={{ paddingTop: '6px' }} className="breadcrumb-item breadcrumb-icon">
                <DashboardIcon />
              </div>
              <div style={{ paddingTop: '6px' }} className="breadcrumb-item icon-label">
                Dashboard
              </div>
            </Breadcrumb.Item>
          </Breadcrumb>
        </Col>
        <Col span={8} offset={8} className="breadcrumb-header filters">
          <RangePicker
            className="transaction-filter"
            format={dateFormat}
            style={{ width: '100%' }}
            defaultValue={[state.startDate, state.endDate]}
            disabledDate={current => current < moment().subtract(90, 'days') || current > moment()}
            onChange={(dates) => {
              if (dates.length) {
                dispatch({
                  type: 'SET_DATES',
                  startDate: dates[0],
                  endDate: dates[1],
                });
              } else {
                dispatch({ type: 'SET_DATES' });
              }
            }}
          />
        </Col>
      </Row>
      {!state.hideWhatIsNew && (
        <section className="panel panel-standard">
          <div className="panel-body">
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
              <h2>What&#39;s new in AQWIRE?</h2>
              <button
                type="button"
                style={{ cursor: 'pointer', padding: 0, backgroundColor: 'transparent', border: 0 }}
                onClick={() => dispatch({ type: 'CLOSE_WHAT_IS_NEW' })}
              >
                <CloseIcon />
              </button>
            </div>
            <p>We have built new features to help you manage your payments on AQWIRE.</p>
            <ul>
              <li>Auditing logs on payments and enrollments are now displayed.</li>
              <li>Searching by multiple status on payments.</li>
              <li>Receive email notifications about important events on your merchant account.</li>
              <li>Manage your merchant account, members and projects.</li>
            </ul>
          </div>
        </section>
      )}
      <div style={{ margin: '16px 0' }}>
        <DashboardLineChart
          merchant={merchant}
          title="Payments"
          start={state.startDate}
          end={state.endDate}
        />
      </div>
      <div style={{ margin: '16px 0' }}>
        <PaymentBarChart
          merchant={merchant}
          title="Payment Types"
          start={state.startDate}
          end={state.endDate}
        />
      </div>
    </div>
  );
};

Dashboard.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      merchantCode: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
  appState: PropTypes.number.isRequired,
  merchants: PropTypes.arrayOf(PropTypes.object).isRequired,
};

export default Dashboard;
