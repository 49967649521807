import React from 'react';
import PropTypes from 'prop-types';
import { Row, Col, Button } from 'antd';

/**
 * @param {{
 *   paymentMethods: {
 *     mpmId: number,
 *     code: string,
 *     currency: string,
 *     isEnabled: boolean,
 *     isAutoDebitEnabled: boolean,
 *     channels: { code: string, name: string }[]
 *     updatedAt: string
 *   }[],
 *   code: string,
 *   logo: string,
 *   title: string,
 *   description: string,
 *   notes: string,
 *   showActions: boolean,
 *   onActivate: function,
 *   onDisable: function,
 * }} props
 */
function PaymentMethodCard(props) {
  const {
    code, paymentMethods, methodType,
    logo, title, description, notes,
    showActions, onActivate, onDisable,
  } = props;

  const enabledMethods = paymentMethods.filter(p => p.isEnabled);
  const currencies = paymentMethods
    .map(c => c.currency)
    .reduce((pv, cv) => `${pv}, ${cv}`, '')
    .slice(1)
    .trim();
  const autoDebitSupportedCurrencies = paymentMethods
    .filter(c => c.isAutoDebitEnabled)
    .map(c => c.currency)
    .reduce((pv, cv) => `${pv}, ${cv}`, '')
    .slice(1)
    .trim();
  const channels = paymentMethods
    .map(m => m.channels
      .map(c => c.name)
      .reduce((pv, cv) => `${pv}, ${cv}`, '')
      .slice(1)
      .trim())
    .filter(m => Boolean(m))
    .reduce((pv, cv) => `${pv}, ${cv}`, '')
    .slice(1)
    .trim();
  const paymentMethodId = paymentMethods.length > 0 ? paymentMethods[0].mpmId : 0;

  const actionButton = enabledMethods.length > 0 ? {
    tagColor: '#399DE5',
    name: 'Active',
    action: (
      <Button
        className="button button-standard button-standard-outline button-small"
        onClick={() => onDisable(paymentMethodId, code)}
      >
        Disable
      </Button>
    ),
  } : {
    tagColor: '#FFCC00',
    name: 'Disabled',
    action: (
      <Button
        className="button button-standard button-small"
        onClick={() => onActivate(paymentMethodId, code)}
      >
        Activate
      </Button>
    ),
  };

  return (
    <div className="payment-method-card">
      <section className="panel panel-standard">
        <div className="panel-header">
          <div>
            <img src={logo} alt="logo" />
            <div className="payment-method-title">{title}</div>
            <div className="text-secondary">{description}</div>
          </div>
        </div>
        <div className="panel-body">
          <Row>
            <Col lg={20}>
              <Row>
                <Col lg={6}>
                  <div className="text-secondary">Status</div>
                </Col>
                <Col lg={18}>
                  <span
                    style={{
                      fontWeight: 700,
                      color: '#ffffff',
                      backgroundColor: actionButton.tagColor,
                      borderRadius: '4px',
                      padding: '4px 8px',
                    }}
                  >
                    {actionButton.name}
                  </span>
                </Col>
              </Row>
              <Row>
                <Col lg={6}>
                  <div className="text-secondary">Type</div>
                </Col>
                <Col lg={18}>
                  <div className="text-primary">{methodType}</div>
                </Col>
              </Row>
              <Row>
                <Col lg={6}>
                  <div className="text-secondary">Currencies</div>
                </Col>
                <Col lg={18}>
                  <div className="text-primary">{currencies}</div>
                </Col>
              </Row>
              <Row>
                <Col lg={6}>
                  <div className="text-secondary">Auto-debit</div>
                </Col>
                <Col lg={18}>
                  <div className="text-primary">{autoDebitSupportedCurrencies || 'No'}</div>
                </Col>
              </Row>
              {channels && (
                <Row>
                  <Col lg={6}>
                    <div className="text-secondary">Channels</div>
                  </Col>
                  <Col lg={18}>
                    <div className="text-primary">{channels}</div>
                  </Col>
                </Row>
              )}
              {notes && (
                <Row>
                  <Col lg={6}>
                    <div className="text-secondary">Notes</div>
                  </Col>
                  <Col lg={18}>
                    <div className="text-primary">{notes}</div>
                  </Col>
                </Row>
              )}
            </Col>
          </Row>
          <Row className="payment-method-actions">
            <Col lg={4}>
              {showActions && actionButton.action}
            </Col>
          </Row>
        </div>
      </section>
    </div>
  );
}

PaymentMethodCard.propTypes = {
  code: PropTypes.string.isRequired,
  paymentMethods: PropTypes.arrayOf(PropTypes.shape({
    mpmId: PropTypes.number.isRequired,
    code: PropTypes.string.isRequired,
    currency: PropTypes.string.isRequired,
    channels: PropTypes.array.isRequired,
    isEnabled: PropTypes.bool.isRequired,
    isAutoDebitEnabled: PropTypes.bool.isRequired,
    updatedAt: PropTypes.string.isRequired,
  })),
  methodType: PropTypes.string.isRequired,
  logo: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  notes: PropTypes.string,
  showActions: PropTypes.bool,
  onActivate: PropTypes.func,
  onDisable: PropTypes.func,
};

PaymentMethodCard.defaultProps = {
  paymentMethods: [],
  notes: null,
  showActions: true,
  onActivate: () => {},
  onDisable: () => {},
};

export default PaymentMethodCard;
