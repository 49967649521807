import React, { useEffect, useReducer, useContext } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { Table, Menu, Dropdown, Icon, Button, Modal, Radio } from 'antd';
import {
  getMerchantMembersService,
  deleteMerchantMemberService,
  updateMerchantMemberRoleService,
  sendMerchantMemberInviteService,
} from '../../services/merchants';
import {
  NoEntryIcon,
  CheckCircleIcon,
  TimesCircleIcon,
} from '../Icons';
import SessionContext from '../../contexts/SessionContext';
import './merchantmemberstable.scss';

const initialState = {
  isSendEmailModalVisible: false,
  isRemoveUserModalVisible: false,
  isChangeRoleModalVisible: false,
  isSendingEmail: false,
  isRemovingUser: false,
  isUpdatingUserRole: false,
  isFetching: false,
  accountData: {
    firstName: null,
    lastName: null,
    email: null,
    id: null,
    merchantRole: null,
  },
  members: [],
  page: 1,
  size: 20,
  totalCount: 0,
};

function reducer(prevState, action) {
  switch (action.type) {
    case 'FETCH_MEMBERS':
      return {
        ...prevState,
        isFetching: true,
      };

    case 'FETCH_MEMBERS_SUCCESSFUL':
      return {
        ...prevState,
        isFetching: false,
        members: action.members,
        totalCount: action.totalCount,
        page: action.page,
      };

    case 'FETCH_MEMBERS_FAILED':
      return {
        ...prevState,
        isFetching: false,
      };

    case 'SEND_MEMBER_INVITE':
      return {
        ...prevState,
        isSendingEmail: true,
      };

    case 'SEND_MEMBER_INVITE_SUCCESS':
      return {
        ...prevState,
        isSendingEmail: false,
      };

    case 'SEND_MEMBER_INVITE_FAILED':
      return {
        ...prevState,
        isSendingEmail: false,
      };

    case 'REMOVE_MEMBER':
      return {
        ...prevState,
        isRemovingUser: true,
      };

    case 'REMOVE_MEMBER_SUCCESS':
      return {
        ...prevState,
        isRemovingUser: false,
      };

    case 'REMOVE_MEMBER_FAILED':
      return {
        ...prevState,
        isRemovingUser: false,
      };

    case 'CHANGE_MEMBER_ROLE':
      return {
        ...prevState,
        isUpdatingUserRole: true,
      };

    case 'CHANGE_MEMBER_ROLE_SUCCESS':
      return {
        ...prevState,
        isUpdatingUserRole: false,
      };

    case 'CHANGE_MEMBER_ROLE_FAILED':
      return {
        ...prevState,
        isUpdatingUserRole: false,
      };

    case 'SELECT_ROLE':
      return {
        ...prevState,
        accountData: {
          ...prevState.accountData,
          merchantRole: action.data,
        },
      };

    case 'SHOW_MODAL':
      return {
        ...prevState,
        [action.modalType]: true,
        accountData: {
          ...prevState.accountData,
          firstName: action.data.firstName,
          lastName: action.data.lastName,
          email: action.data.email,
          id: action.data.id,
          merchantRole: action.data.merchantRole[0],
        },
      };

    case 'HIDE_MODAL':
      return {
        ...prevState,
        isSendEmailModalVisible: false,
        isRemoveUserModalVisible: false,
        isChangeRoleModalVisible: false,
      };

    default:
      return prevState;
  }
}

const MerchantMembersTable = (props) => {
  const { merchant, history } = props;
  const [state, dispatch] = useReducer(reducer, initialState);
  const { showToast, loggedInUser } = useContext(SessionContext);
  const {
    isSendEmailModalVisible,
    isRemoveUserModalVisible,
    isChangeRoleModalVisible,
    accountData,
  } = state;

  function getMembers(pg, sz) {
    let ableToSet = true;
    const page = pg || 1;
    const size = sz || 20;
    (async () => {
      dispatch({ type: 'FETCH_MEMBERS' });
      try {
        const { data } = await getMerchantMembersService(merchant.id, {
          page,
          size,
        });
        const { members, totalCount } = data;
        if (ableToSet) dispatch({ type: 'FETCH_MEMBERS_SUCCESSFUL', members, totalCount, page });
      } catch (error) {
        let message = error && error.response && error.response.data.message;
        message = message || 'Unable to get members as of the moment';
        showToast({ type: 'error', message });
        if (ableToSet) dispatch({ type: 'FETCH_MEMBERS_FAILED' });
      }
    })();

    return () => { ableToSet = false; };
  }

  async function sendInvite(memberEmail) {
    dispatch({ type: 'SEND_MEMBER_INVITE' });
    try {
      const response = await sendMerchantMemberInviteService(merchant.id, memberEmail);
      const { data: { message } } = response;
      showToast({ type: 'success', message });
      dispatch({ type: 'SEND_MEMBER_INVITE_SUCCESS' });
      dispatch({ type: 'HIDE_MODAL' });
    } catch (error) {
      showToast({
        message: 'Unable to send invite as of the moment',
        type: 'error',
      });
      dispatch({ type: 'SEND_MEMBER_INVITE_FAILED' });
      dispatch({ type: 'HIDE_MODAL' });
    }
  }

  async function deleteUser(memberId) {
    dispatch({ type: 'REMOVE_MEMBER' });
    try {
      const response = await deleteMerchantMemberService(merchant.id, memberId);
      const { data: { message } } = response;
      showToast({ type: 'success', message });
      dispatch({ type: 'REMOVE_MEMBER_SUCCESS' });
      history.push(`/merchants/${merchant.code}/settings?key=members`);
    } catch (error) {
      showToast({
        message: 'Unable to remove member as of the moment',
        type: 'error',
      });
      dispatch({ type: 'REMOVE_MEMBER_FAILED' });
      dispatch({ type: 'HIDE_MODAL' });
    }
  }

  async function changeMemberRole(memberId) {
    dispatch({ type: 'CHANGE_MEMBER_ROLE' });
    try {
      const response = await updateMerchantMemberRoleService(merchant.id, memberId, state.accountData.merchantRole);
      const { data: { message } } = response;
      showToast({ type: 'success', message });
      dispatch({ type: 'CHANGE_MEMBER_ROLE_SUCCESS' });
      history.push(`/merchants/${merchant.code}/settings?key=members`);
    } catch (error) {
      showToast({
        message: 'Unable to update the member\'s role as of the moment',
        type: 'error',
      });
      dispatch({ type: 'CHANGE_MEMBER_ROLE_FAILED' });
      dispatch({ type: 'HIDE_MODAL' });
    }
  }

  useEffect(() => {
    getMembers();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const paginationSettings = {
    current: state.page,
    pageSize: state.size,
    total: state.totalCount,
    size: 'small',
    onChange: (page) => {
      const totalPage = Math.ceil(state.totalCount / state.size);
      let pg = page || 1;
      if (pg < 1) { pg = 1; }
      if (pg > totalPage) { pg = totalPage - 1; }
      getMembers(pg, state.size);
    },
    showTotal: (total, range) => `Showing ${range[0]} - ${range[1]} of ${total}`,
  };

  const columns = [
    {
      title: 'Email Address',
      key: 'email',
      render: row => (
        <div className="text-primary">{row.email}</div>
      ),
    }, {
      title: 'Name',
      key: 'id',
      render: row => (
        <div className="table-col-item">
          <div>
            { loggedInUser.systemRole[0] >= 60 && loggedInUser.merchantRole[0] >= 10 ? (
              <div
                className="table-item user-name"
              >
                {`${row.firstName} ${row.lastName}`}
              </div>
            )
              : (
                <Link
                  className="table-item-link user-name"
                  to={{
                    pathname: `/users/${row.id}`,
                  }}
                >
                  {`${row.firstName} ${row.lastName}`}
                </Link>
              )}
          </div>
        </div>
      ),
    }, {
      title: 'Role',
      key: 'roles',
      render: row => (
        <div className="scope-role">
          {row.merchantRole[1]}
        </div>
      ),
    }, {
      title: 'Status',
      key: 'status',
      render: (row) => {
        if (!row.isAccountConfirmed) {
          return (
            <div className="table-col-item">
              <NoEntryIcon />
              <span className="icon-label">Invited</span>
            </div>
          );
        }
        if (!row.isEnabled) {
          return (
            <div className="table-col-item">
              <TimesCircleIcon className="table-col-item-icon" />
              <span className="icon-label">Disabled</span>
            </div>
          );
        }
        return (
          <div className="table-col-item">
            <CheckCircleIcon className="table-col-item-icon" />
            <span className="icon-label">Active</span>
          </div>
        );
      },
    }, {
      key: 'options',
      render: (row) => {
        if (row.id !== loggedInUser.id) {
          const menu = (
            <Menu>
              {!row.isAccountConfirmed && row.id !== loggedInUser.id && (
                <Menu.Item
                  key="send-email"
                  onClick={() => dispatch({ type: 'SHOW_MODAL', data: row, modalType: 'isSendEmailModalVisible' })}
                >
                  Resend Invite
                </Menu.Item>
              )}
              <Menu.Item
                key="change-role"
                onClick={() => dispatch({ type: 'SHOW_MODAL', data: row, modalType: 'isChangeRoleModalVisible' })}
              >
                Change Role
              </Menu.Item>
              <Menu.Item
                key="remove-member"
                onClick={() => dispatch({ type: 'SHOW_MODAL', data: row, modalType: 'isRemoveUserModalVisible' })}
              >
                Remove Member
              </Menu.Item>
            </Menu>
          );
          return (
            <div className="table-col-item">
              <Dropdown overlay={menu} placement="bottomRight">
                <Icon type="more" style={{ cursor: 'pointer' }} />
              </Dropdown>
            </div>
          );
        }
        return null;
      },
    },
  ];

  return (
    <div>
      <Table
        className="table-standard"
        dataSource={state.members}
        columns={columns}
        rowKey="id"
        loading={state.isFetching}
        pagination={paginationSettings}
      />
      <Modal
        className="dialog-modal"
        visible={isSendEmailModalVisible}
        title="Send Email Invite"
        onClick={() => sendInvite(accountData.email)}
        onCancel={() => dispatch({ type: 'HIDE_MODAL' })}
        footer={[
          <Button
            key="cancel-confirm-key"
            onClick={() => dispatch({ type: 'HIDE_MODAL' })}
          >
            Cancel
          </Button>,
          <Button
            loading={state.isSendingEmail}
            key="submit"
            type="primary"
            onClick={() => sendInvite(accountData.email)}
          >
            Send Invite
          </Button>,
        ]}
      >
        <p>
          Do you wish to send
          <strong>{` ${accountData.firstName} ${accountData.lastName} `}</strong>
          an email invite?
          (
          <strong>{`${accountData.email}`}</strong>
          )
        </p>
      </Modal>
      <Modal
        className="dialog-modal"
        visible={isChangeRoleModalVisible}
        title="Change Role"
        onClick={() => changeMemberRole(accountData.id)}
        onCancel={() => dispatch({ type: 'HIDE_MODAL' })}
        footer={[
          <Button
            key="cancel-confirm-key"
            onClick={() => dispatch({ type: 'HIDE_MODAL' })}
          >
            Cancel
          </Button>,
          <Button
            loading={state.isUpdatingUserRole}
            key="submit"
            type="primary"
            onClick={
              () => {
                const member = state.members.find(e => e.email === accountData.email);
                if (member.merchantRole[0] !== accountData.merchantRole) {
                  changeMemberRole(accountData.id);
                }
              }
            }
          >
            Change Role
          </Button>,
        ]}
      >
        <p>
          {`Select the new role for ${accountData.firstName} ${accountData.lastName} (${accountData.email}) in ${merchant.name}`}
        </p>
        <Radio.Group
          disabled={state.isUpdatingUserRole}
          value={accountData.merchantRole}
          onChange={e => dispatch({ type: 'SELECT_ROLE', data: e.target.value })}
        >
          <Radio value={10}>
            <strong>Admin </strong>
            - Account admin. Can manage the merchant account, projects, activity logs, and transactions.
          </Radio>
          <Radio value={20}>
            <strong>Analyst </strong>
            - Can view merchant information and transactions, and manage payment links and notifications.
          </Radio>
          <Radio value={25}>
            <strong>Developer </strong>
            - Can view merchant transactions and access the developer settings.
          </Radio>
          <Radio value={30}>
            <strong>Viewer </strong>
            - Read-only access to payments and enrollments.
          </Radio>
        </Radio.Group>
      </Modal>
      <Modal
        className="dialog-modal"
        visible={isRemoveUserModalVisible}
        title="Remove User"
        onClick={() => deleteUser(accountData.id)}
        onCancel={() => dispatch({ type: 'HIDE_MODAL' })}
        footer={[
          <Button
            key="cancel-confirm-key"
            onClick={() => dispatch({ type: 'HIDE_MODAL' })}
          >
            Cancel
          </Button>,
          <Button
            loading={state.isRemovingUser}
            key="submit"
            type="primary"
            onClick={() => deleteUser(accountData.id)}
          >
            Remove
          </Button>,
        ]}
      >
        <p>
          Do you wish to remove
          <strong>{` ${accountData.firstName} ${accountData.lastName}`}</strong>
          ?
          (
          <strong>{`${accountData.email}`}</strong>
          )
        </p>
      </Modal>
    </div>
  );
};

MerchantMembersTable.propTypes = {
  merchant: PropTypes.shape({
    id: PropTypes.number.isRequired,
    code: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
  }).isRequired,
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }).isRequired,
  location: PropTypes.shape({
    pathname: PropTypes.string.isRequired,
    search: PropTypes.string.isRequired,
  }).isRequired,
};

export default MerchantMembersTable;
