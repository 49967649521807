import React, { useReducer, Fragment } from 'react';
import PropTypes from 'prop-types';
import {
  Form, Input, Button, Modal, Row, Col, Alert, Select, Icon,
} from 'antd';
import { updateDisputeById } from '../../services/disputes';
import '../../styles/modal.scss';

const initialState = {
  isDisputeModalVisible: false,
  isSuccessModalVisible: false,
  isReasonNotesRequired: false,
  isDisputeUpdating: false,
  disputeStatus: null,
  status: null,
  message: null,
};

function reducer(prevState, action) {
  switch (action.type) {
    case 'SHOW_DISPUTE_MODAL':
      return {
        ...prevState,
        isDisputeModalVisible: true,
      };

    case 'HIDE_DISPUTE_MODAL':
      return {
        ...prevState,
        isDisputeModalVisible: false,
      };
    case 'SHOW_SUCCESS_MODAL':
      return {
        ...prevState,
        isDisputeModalVisible: false,
        isSuccessModalVisible: true,
      };
    case 'HIDE_SUCCESS_MODAL':
      return {
        ...prevState,
        isSuccessModalVisible: false,
      };
    case 'HIDE_MODAL':
      return {
        ...prevState,
        isDisputeModalVisible: false,
        isSuccessModalVisible: false,
      };
    case 'UPDATE_DISPUTE':
      return {
        ...prevState,
        isDisputeUpdating: true,
      };
    case 'UPDATE_DISPUTE_SUCCESS':
      return {
        ...prevState,
        isDisputeUpdating: false,
        message: action.message,
        disputeStatus: action.disputeStatus,
      };
    case 'UPDATE_DISPUTE_FAILED':
      return {
        ...prevState,
        isDisputeUpdating: false,
        message: action.message,
        status: 'error',
      };
    case 'CLEAR_MESSAGE':
      return {
        ...prevState,
        message: null,
        status: null,
      };
    default:
      return prevState;
  }
}

const UpdateDisputeModal = (props) => {
  const { form, dispute, history } = props;
  const disputeId = dispute.id;
  const { getFieldDecorator } = form;
  const [state, dispatch] = useReducer(reducer, initialState);

  const disputedDoneStatus = ['WON', 'LOST'];

  const { TextArea } = Input;
  const { Option } = Select;

  function onSubmit(e) {
    e.preventDefault();
    form.validateFields(async (err, values) => {
      dispatch({ type: 'UPDATE_DISPUTE' });

      if (err) {
        dispatch({
          type: 'UPDATE_DISPUTE_FAILED',
          message: 'Please double check the errors on the dispute form',
        });
        return;
      }

      const {
        disputeStatus,
        disputeNotes,
        // email,
      } = values;

      try {
        const data = {
          disputeStatus,
          disputeNotes,
          // email,
        };
        const response = await updateDisputeById(disputeId, data);
        const { message } = response.data;

        dispatch({ type: 'UPDATE_DISPUTE_SUCCESS', message, disputeStatus });
        dispatch({ type: 'SHOW_SUCCESS_MODAL' });
      } catch (error) {
        const message = error && error.response
          ? error.response.data.message
          : 'Unable to update status of dispute as of the moment';

        dispatch({ type: 'UPDATE_DISPUTE_FAILED', message });
      }
    });
  }

  if (disputedDoneStatus.includes(dispute.status)) {
    return <Fragment />;
  }

  return (
    <div className="enrollment-actions-center">
      <Button
        className="button button-standard button-standard-outline"
        onClick={() => dispatch({ type: 'SHOW_DISPUTE_MODAL' })}
      >
        Update Status
      </Button>
      <Modal
        title="Update Status"
        className="dialog-modal"
        visible={state.isDisputeModalVisible}
        onCancel={() => dispatch({ type: 'HIDE_MODAL' })}
        footer={[
          <Button
            key="submit"
            type="primary"
            htmlType="submit"
            loading={state.isDisputeUpdating}
            onClick={onSubmit}
          >
            UPDATE
          </Button>,
        ]}
      >
        {state.status && state.message && (
          <Alert
            style={{ marginBottom: '12px' }}
            type={state.status}
            description={state.message}
            closable
            onClose={() => dispatch({ type: 'CLEAR_MESSAGE' })}
          />
        )}
        <Row>
          <Col span={3}>
            <Icon type="info-circle" style={{ color: '#399DE5', fontSize: 30, paddingLeft: 10 }} />
          </Col>
          <Col span={21}>
            <p>
              <strong>WON </strong>
              - will revert the payment status to PAID/SETTLED and mark the dispute as WON
            </p>
            <p>
              <strong>LOST </strong>
              - will update the payment status to
              <strong> REFUNDED </strong>
              and mark the dispute as LOST
            </p>
          </Col>
        </Row>
        <Form onSubmit={onSubmit}>
          <Form.Item label="Status">
            {getFieldDecorator('disputeStatus', {
              rules: [{ message: 'Please select a status for dispute' }],
              initialValue: 'WON',
            })(
              <Select
                showSearch
                style={{ width: '100%' }}
                placeholder="Select a person"
                optionFilterProp="children"
                filterOption={(inputValue, option) => {
                  const { props: { children } } = option;
                  return children.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1;
                }}
              >
                <Option value="WON">Won</Option>
                <Option value="LOST">Lost</Option>
              </Select>,
            )}
          </Form.Item>
          {/* <Form.Item label="Email">
            {getFieldDecorator('email', {
              rules: [{ required: true, type: 'email', message: 'Please enter a valid email address' }],
              initialValue: customerEmail,
            })(<Input />)}
          </Form.Item> */}
          <Form.Item label="Notes">
            {form.getFieldDecorator('disputeNotes', {
              rules: [{ required: state.isReasonNotesRequired, message: 'Please enter notes for updating the dispute' }],
            })(
              <TextArea autoSize={{ maxRows: 8, minRows: 3 }} allowClear />,
            )}
          </Form.Item>
        </Form>
        <Row>
          <Col span={24}>
            <Alert
              message="Please take note that any status change to won or lost is final, and will be reflected."
              type="warning"
              showIcon
            />
          </Col>
        </Row>
      </Modal>
      <Modal
        title="Dispute Status Updated"
        className="dialog-modal"
        visible={state.isSuccessModalVisible}
        onCancel={() => {
          dispatch({ type: 'HIDE_MODAL' });
          history.push(`/disputes/${disputeId}`);
        }}
        footer={[
          <Button
            onClick={() => {
              dispatch({ type: 'HIDE_MODAL' });
              history.push(`/disputes/${disputeId}`);
            }}
            key="submit"
            type="primary"
            htmlType="submit"
            loading={state.isDisputeUpdating}
          >
            Close
          </Button>,
        ]}
      >
        {state.status && state.message && (
          <Alert
            style={{ marginBottom: '12px' }}
            type={state.status}
            description={state.message}
            closable
            onClose={() => dispatch({ type: 'CLEAR_MESSAGE' })}
          />
        )}
        <Row>
          <Col span={3}>
            <Icon type="check-circle" style={{ color: '#96D647', fontSize: 30, paddingLeft: 10 }} />
          </Col>
          <Col span={21}>
            <p>
              Dispute status successfully updated to
              <strong>{` ${state.disputeStatus}.`}</strong>
            </p>
          </Col>
        </Row>
      </Modal>
    </div>
  );
};

UpdateDisputeModal.propTypes = {
  form: PropTypes.shape({
    getFieldDecorator: PropTypes.func.isRequired,
    validateFields: PropTypes.func.isRequired,
  }).isRequired,
  dispute: PropTypes.shape({
    id: PropTypes.number.isRequired,
    status: PropTypes.string.isRequired,
    disputedPayment: PropTypes.shape({
      invoiceId: PropTypes.string.isRequired,
      merchantCode: PropTypes.string.isRequired,
      paymentReferenceId: PropTypes.string.isRequired,
      billTotal: PropTypes.array.isRequired,
      paymentStatus: PropTypes.string.isRequired,
      customerEmail: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }).isRequired,
};

export default Form.create()(UpdateDisputeModal);
