import api from '../helpers/apiClient';
import { exportUrl } from '../config/index';

export function searchDisputes(searchOption) {
  const { page, size, projectId, status, merchantId } = searchOption;
  return api.get('/disputes', {
    params: {
      page,
      size,
      project: projectId,
      mid: merchantId,
      query: searchOption.query ? searchOption.query.trim() : null,
      startday: searchOption.startDate && searchOption.startDate.isValid()
        ? searchOption.startDate.format('YYYY-MM-DD') : undefined,
      endday: searchOption.endDate && searchOption.endDate.isValid()
        ? searchOption.endDate.format('YYYY-MM-DD') : undefined,
      reportdate: searchOption.reportingDate && searchOption.reportingDate.isValid()
        ? searchOption.reportingDate.format('YYYY-MM-DD') : undefined,
      status,
    },
  });
}

export function getDisputeById(disputeId) {
  return api.get(`/disputes/${disputeId}`);
}

export function getDisputeByInvoiceId(invoiceId) {
  return api.get(`/disputes/invoices/${invoiceId}`);
}

export function exportQWDisputesService(searchOption, token) {
  const { startDate, endDate, reportingDate } = searchOption;
  const startday = startDate && startDate.isValid() ? startDate.format('YYYY-MM-DD') : undefined;
  const endday = endDate && endDate.isValid() ? endDate.format('YYYY-MM-DD') : undefined;
  const reportdate = reportingDate && reportingDate.isValid() ? reportingDate.format('YYYY-MM-DD') : undefined;
  let url = `${exportUrl}/disputes/export?token=${token}`;
  if (startday && endday) { url += `&startday=${startday}&endday=${endday}`; }
  if (reportdate) { url += `&reportdate=${reportdate}`; }
  if (searchOption.projectId) { url += `&project=${searchOption.projectId}`; }
  if (searchOption.query) { url += `&query=${searchOption.query}`; }
  if (searchOption.settlementReferenceId) { url += `&settlementrefid=${searchOption.settlementReferenceId}`; }
  if (searchOption.settlementStatus) { url += `&settlementstatus=${searchOption.settlementStatus}`; }
  if (searchOption.status) { url += `&status=${searchOption.status}`; }
  if (searchOption.merchantId) { url += `&mid=${searchOption.merchantId}`; }
  window.open(url, '_blank');
}

export function updateDisputeById(disputeId, data) {
  return api.post(`/disputes/${disputeId}`, data);
}
