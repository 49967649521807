import React from 'react';
import { SingleForm } from '../../components';

const NotFound = () => (
  <SingleForm
    title="Page not found"
    subtitle="The page you requested could not be found. Please go back to Dashboard or contact us at support@aqwire.io."
    footer={{ show: true, to: '/', text: 'Back to Dashboard' }}
  />
);

export default NotFound;
