import React from 'react';
import {
  Breadcrumb, Col, Row,
} from 'antd';
import { NewUserForm } from '../../components';
import { UsersIconBig } from '../../components/Icons';

const CreateUser = () => (
  <div>
    <Row>
      <Col className="breadcrumb-header">
        <Breadcrumb className="breadcrumb-parent">
          <Breadcrumb.Item className="item-container">
            <div className="breadcrumb-item breadcrumb-icon">
              <UsersIconBig />
            </div>
            <div className="breadcrumb-item icon-label">
              Users
            </div>
          </Breadcrumb.Item>
        </Breadcrumb>
      </Col>
    </Row>
    <NewUserForm />
  </div>
);

export default CreateUser;
