import moment from 'moment';
import React, { Fragment, useEffect, useReducer, useContext } from 'react';
import PropTypes from 'prop-types';
// import { PDFObject } from 'react-pdfobject';
import { Breadcrumb, Button, Col, Row, Modal, Table } from 'antd';
import { Link } from 'react-router-dom';
import FileIcon from 'react-file-icon';
import { findSettlementById, deleteSettlementService, getPresignedDownloadUrl } from '../../services/settlements';
import { getPaymentsService } from '../../services/payments';
import { SettlementReportsIconBig, FolderIcon } from '../../components/Icons';
import { SELECTED_PAYMENTS } from '../../constants';
import SessionContext from '../../contexts/SessionContext';
import { getAPIErrorMessage, getStatusColor, formatNumber, aggregateListToText } from '../../helpers/utils';
import BreakdownItem from '../../components/BreakdownItem';
import '../../styles/transactiondetails.css';

const MAX_PAYMENTS_TO_DISPLAY = 100;

const initialState = {
  isExporting: false,
  isProcessing: false,
  isFetchingPayments: false,
  payments: [],
  totalPayments: 0,
  isFetchingSettlementReport: true,
  settlementReport: null,
  isFetchingPresignedUrl: false,
  presignedUrl: null,
  isDeleteModalVisible: false,
};

function reducer(prevState, action) {
  switch (action.type) {
    case 'EXPORTING_PAYMENTS':
      return {
        ...prevState,
        isExporting: true,
      };
    case 'EXPORTING_PAYMENTS_SUCCESSFUL':
      return {
        ...prevState,
        isExporting: false,
      };
    case 'EXPORTING_PAYMENTS_FAILED':
      return {
        ...prevState,
        isExporting: false,
      };
    case 'GET_PAYMENTS':
      return {
        ...prevState,
        isFetchingPayments: true,
      };
    case 'GET_PAYMENTS_SUCCESSFUL':
      return {
        ...prevState,
        isFetchingPayments: false,
        payments: action.payments,
        totalPayments: action.totalPayments,
      };
    case 'GET_PAYMENTS_FAILED':
      return {
        ...prevState,
        isFetchingPayments: false,
        payments: [],
        totalPayments: 0,
        status: 'error',
        message: action.message,
      };
    case 'GET_SETTLEMENT_SUCCESSFUL':
      return {
        ...prevState,
        isFetchingSettlementReport: false,
        settlementReport: action.settlementReport,
      };
    case 'GET_SETTLEMENT_FAILED':
      return {
        ...prevState,
        isFetchingSettlementReport: false,
        settlementReport: null,
      };
    case 'GET_PRESIGNED_URL':
      return {
        ...prevState,
        isFetchingPresignedUrl: false,
        presignedUrl: null,
      };
    case 'GET_PRESIGNED_URL_SUCCESSFUL':
      return {
        ...prevState,
        isFetchingPresignedUrl: false,
        presignedUrl: action.presignedUrl,
      };
    case 'GET_PRESIGNED_URL_FAILED':
      return {
        ...prevState,
        isFetchingPresignedUrl: false,
        presignedUrl: null,
        status: 'error',
        message: action.message,
      };
    case 'DELETE_SETTLEMENT':
      localStorage.removeItem(SELECTED_PAYMENTS);
      return {
        ...prevState,
        isProcessing: true,
      };
    case 'DELETE_SETTLEMENT_FAILED':
      return {
        ...prevState,
        isProcessing: false,
      };
    case 'TOGGLE_MODAL':
      return {
        ...prevState,
        isDeleteModalVisible: !prevState.isDeleteModalVisible,
      };
    default:
      return prevState;
  }
}

const QwikwireSettlement = (props) => {
  const timeZone = moment.tz.guess(true);
  const { history, match: { params: { settlementId } } } = props;
  const [state, dispatch] = useReducer(reducer, initialState);
  const { showToast, loggedInUser } = useContext(SessionContext);

  useEffect(() => {
    let ableToSet = true;
    (async () => {
      try {
        const res = await findSettlementById(settlementId);
        if (ableToSet) {
          dispatch({
            type: 'GET_SETTLEMENT_SUCCESSFUL',
            settlementReport: res.data,
            settlementReferenceId: res.data.settlementReferenceId,
          });
        }
      } catch (error) {
        const message = getAPIErrorMessage(error,
          'We are not able to get the settlement data. Please try again later');
        if (ableToSet) {
          dispatch({ type: 'GET_SETTLEMENT_FAILED', message });
        }
      }
    })();

    return () => { ableToSet = false; };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    let ableToSet = true;

    (async () => {
      try {
        if (!state.settlementReport) { return; }

        if (ableToSet) {
          dispatch({ type: 'GET_PAYMENTS' });
        }

        const response = await getPaymentsService(state.settlementReport.merchantId, {
          page: 1,
          size: MAX_PAYMENTS_TO_DISPLAY,
          settlementStatus: 'SETTLED',
          settlementReferenceId: state.settlementReport.settlementReferenceId,
        });
        const { payments, totalCount } = response.data;
        if (ableToSet) {
          dispatch({
            type: 'GET_PAYMENTS_SUCCESSFUL',
            payments,
            totalPayments: totalCount,
          });
        }
      } catch (error) {
        const message = getAPIErrorMessage(error,
          'We are not able to get the settlement data. Please try again later');
        if (ableToSet) {
          dispatch({ type: 'GET_PAYMENTS_FAILED', message });
        }
      }
    })();

    return () => { ableToSet = false; };
  }, [state.settlementReport]);


  useEffect(() => {
    let ableToSet = true;

    (async () => {
      try {
        if (!state.settlementReport) { return; }

        if (ableToSet) {
          dispatch({ type: 'GET_PRESIGNED_URL' });
        }

        const id = state.settlementReport.fileId;
        const merchant = {
          id: state.settlementReport.merchantId,
          code: state.settlementReport.merchantCode,
        };
        const response = await getPresignedDownloadUrl(merchant.id, id);
        const { presignedUrl } = response.data;
        if (ableToSet) {
          dispatch({ type: 'GET_PRESIGNED_URL_SUCCESSFUL', presignedUrl });
        }
      } catch (error) {
        const message = getAPIErrorMessage(error,
          'We are not able to get the attached file information. Please try again later');
        if (ableToSet) {
          dispatch({ type: 'GET_PRESIGNED_URL_FAILED', message });
        }
      }
    })();

    return () => { ableToSet = false; };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.settlementReport]);

  async function deleteSettlementReport(settlementReport) {
    dispatch({ type: 'DELETE_SETTLEMENT' });
    try {
      await deleteSettlementService(settlementReport.merchantId, settlementReport.settlementReferenceId);
      history.push('/settlements');
      showToast({ type: 'success', message: `Successfully deleted ${settlementReport.settlementReferenceId}` });
    } catch (err) {
      const message = err && err.response
        ? err.response.data.message
        : 'Unable to delete settlement as of the moment';
      dispatch({ type: 'DELETE_SETTLEMENT_FAILED' });
      showToast({ type: 'error', message });
    }
  }

  if (state.isFetchingSettlementReport) {
    return (
      <div className="qwikwire">
        <section className="transaction-breadcrumb">
          <div className="transaction-breadcrumb-icon">
            <SettlementReportsIconBig />
          </div>
          <Breadcrumb>
            <Breadcrumb.Item>
              <Link to="/settlements">All Settlements</Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item>
              {`#${settlementId}`}
            </Breadcrumb.Item>
          </Breadcrumb>
        </section>
        <section style={{ padding: '8px' }}>
          <p>Loading...</p>
        </section>
      </div>
    );
  }

  if (!state.settlementReport) {
    return (
      <div>
        <div className="spinner">
          <FolderIcon />
          <h1>Settlement Report Not Found</h1>
          <p>
            You can contact us at&nbsp;
            <a href="mailto:support@aqwire.io">
              support@aqwire.io
            </a>
            &nbsp;if you have concerns or issues.
          </p>
        </div>
      </div>
    );
  }

  const columns = [
    {
      title: 'Status',
      key: 'status',
      render: row => (
        <div style={{ marginBottom: '8px' }}>
          <span
            style={{
              fontWeight: 700,
              color: '#ffffff',
              backgroundColor: getStatusColor(row.paymentStatus),
              borderRadius: '4px',
              padding: '4px 8px',
            }}
          >
            {row.paymentStatus}
          </span>
        </div>
      ),
    }, {
      title: 'Project',
      key: 'project',
      width: 280,
      render: row => (
        <div>
          <div className="text-primary">
            {(({ projectName, projectCategory }) => {
              if (projectName && projectCategory) { return `${projectCategory} - ${projectName}`; }
              if (projectName) { return projectName; }
              return '----';
            })(row)}
          </div>
        </div>
      ),
    }, {
      title: 'Type',
      key: 'type',
      render: row => (
        <Fragment>
          <div className="text-primary">{row.invoiceReferenceId ? 'Recurring Payment' : 'One-Time Payment'}</div>
        </Fragment>
      ),
    }, {
      title: 'Net Amount',
      key: 'amount',
      render: row => (
        <div className="text-primary">
          {row.net
            ? `${row.net[0]} ${formatNumber(row.net[1])}`
            : '-----'}
        </div>
      ),
    }, {
      title: 'Date',
      key: 'createdAt',
      render: (row) => {
        const dt = row.paidAt || row.invoiceCreatedAt;
        return (
          <div className="table-col-item">
            <div className="text-primary">
              {moment(dt).tz(timeZone).format('lll')}
            </div>
          </div>
        );
      },
    },
  ];

  const { settlementReport, payments } = state;
  const searchExtensionResults = /.[a-z]+$/.exec(settlementReport.fileName);
  const fileExtension = searchExtensionResults ? searchExtensionResults[0] : null;
  const displayProjects = aggregateListToText(payments.map((pmt) => {
    if (!pmt.projectCategory) {
      return pmt.projectName;
    }
    return `${pmt.projectCategory} - ${pmt.projectName}`;
  }));

  return (
    <div className="qwikwire">
      <section className="transaction-breadcrumb">
        <div className="transaction-breadcrumb-icon">
          <SettlementReportsIconBig />
        </div>
        <Breadcrumb>
          <Breadcrumb.Item>
            <Link to="/settlements">All Settlements</Link>
          </Breadcrumb.Item>
          <Breadcrumb.Item>
            {`#${settlementId}`}
          </Breadcrumb.Item>
        </Breadcrumb>
      </section>
      <section className="panel panel-standard">
        <div className="panel-header">
          <Row>
            <Col lg={6} className="panel-item">
              <h3>Reference ID</h3>
              <div>{settlementReport.settlementReferenceId}</div>
            </Col>
            <Col lg={6} className="panel-item">
              <h3>Merchant</h3>
              <div>{settlementReport.merchantName}</div>
            </Col>
            <Col lg={6} className="panel-item">
              <h3>Settled Date</h3>
              <div>{settlementReport.settledDate ? moment(settlementReport.settledDate, 'YYYY-MM-DD').format('LL') : '----'}</div>
            </Col>
            <Col lg={6} className="panel-item">
              <h3>Created Date</h3>
              <div>{settlementReport.createdAt ? moment(settlementReport.createdAt).format('LL') : '----'}</div>
            </Col>
          </Row>
        </div>
        <div className="panel-body">
          <Row>
            <Col lg={12}>
              <div className="panel-item-group">
                <h3>Breakdown</h3>
                <BreakdownItem label="Base Amount" bill={settlementReport.totalBaseAmount} />
                <BreakdownItem label="Waived Fees" bill={settlementReport.totalWaivedFeeAmount} />
                <BreakdownItem highlight label="Settled Amount" bill={settlementReport.totalSettlementAmount} />
              </div>
              <div className="panel-item-group" style={{ marginBottom: 0 }}>
                {displayProjects && (
                  <div className="panel-item">
                    <h3>Projects</h3>
                    <p>{displayProjects}</p>
                  </div>
                )}
                <div className="panel-item">
                  <h3>Notes</h3>
                  <p className="comments">
                    {settlementReport.settlementNotes || 'No notes provided'}
                  </p>
                </div>
              </div>
            </Col>
          </Row>
        </div>
        {loggedInUser.systemRole[0] <= 50 && (
          <div className="panel-footer">
            <div className="enrollment-actions-end">
              <Button
                className="button button-danger button-danger-outline"
                onClick={() => dispatch({ type: 'TOGGLE_MODAL' })}
              >
                <span>Delete</span>
              </Button>
            </div>
            <Modal
              className="dialog-modal"
              visible={state.isDeleteModalVisible}
              onCancel={() => dispatch({ type: 'TOGGLE_MODAL' })}
              footer={[
                <Button
                  key="cancel"
                  className="button button-standard button-standard-outline"
                  onClick={() => dispatch({ type: 'TOGGLE_MODAL' })}
                >
                  Cancel
                </Button>,
                <Button
                  className="button button-danger"
                  key="submit"
                  type="primary"
                  onClick={() => deleteSettlementReport(state.settlementReport)}
                >
                  Delete Settlement
                </Button>,
              ]}
            >
              <h3>{`Delete ${state.settlementReport.settlementReferenceId}`}</h3>
              <p>Are you sure you want to delete this settlement?</p>
            </Modal>
          </div>
        )}
      </section>

      <Table
        style={{ marginTop: '20px' }}
        className="table-standard settlements-table"
        dataSource={payments}
        columns={columns}
        rowKey="invoiceId"
        loading={state.isFetchingPayments}
        pagination={false}
        rowClassName="row-clickable"
        onRow={(row) => {
          const onClick = () => history.push(`/payments/${row.invoiceId}`);
          return { onClick };
        }}
      />

      <section className="panel panel-standard" style={{ marginBottom: '40px' }}>
        <div className="panel-header">
          <h3>Attachments</h3>
        </div>
        <div className="panel-body">
          <div className="panel-item">
            {settlementReport.fileId && state.presignedUrl ? (
              <div className="file-icon-wrapper" style={{ display: 'block' }}>
                <a
                  className="file-icon"
                  href={state.presignedUrl}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <FileIcon
                    extension={fileExtension}
                    style={{ width: '24px', height: '24px' }}
                  />
                  <span>{settlementReport.fileName}</span>
                </a>
              </div>
            ) : <p>No attached file</p>}
          </div>
        </div>
      </section>
    </div>
  );
};

QwikwireSettlement.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      settlementId: PropTypes.string.isRequired,
    }),
  }),
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }).isRequired,
  location: PropTypes.shape({
    pathname: PropTypes.string.isRequired,
    search: PropTypes.string.isRequired,
  }),
};

QwikwireSettlement.defaultProps = {
  match: {
    params: {
      settlementId: null,
    },
  },
  location: {
    pathname: null,
    search: null,
  },
};

export default QwikwireSettlement;
