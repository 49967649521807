import axios from 'axios';
import api from '../helpers/apiClient';


export function getPresignedUploadUrlService(directory, file) {
  return api.get('/files/presigned-url/upload', {
    params: {
      directory,
      filename: file.name,
      content_type: file.type,
    },
  });
}

export function s3UploadFileService(presignedUrl, file) {
  return axios.put(presignedUrl, file, { headers: { 'Content-Type': file.type } });
}

export function recordFileUploadService(merchant, fileMetadata) {
  return api.post(`/merchants/${merchant}/settlements/file`, fileMetadata);
}

export function getPresignedDownloadUrlService(settlementFileId) {
  return api.get(`/files/presigned-url/download/${settlementFileId}`);
}

export function removeFileService(settlementFileId) {
  return api.delete(`/files/${settlementFileId}`);
}
