import React, { useEffect, useReducer } from 'react';
import PropTypes from 'prop-types';
import qs from 'query-string';
import { Layout } from 'antd';
import { Switch, Route, Redirect, useHistory, useLocation } from 'react-router-dom';
import QwikwireMainNavbar from '../components/QwikwireMainNavbar';
import CreateUser from '../screens/CreateUser';
import Home from '../screens/Home';
import EditUser from '../screens/EditUser';
import Merchants from '../screens/Merchants';
import TransactionsSearchResults from '../screens/TransactionsSearchResults';
import Users from '../screens/Users';
import AccountSettings from '../screens/AccountSettings';
import Activities from '../screens/Activities';
import NotFound from '../screens/NotFound';
import QwikwireNavigation from '../components/QwikwireNavigation';
import QwikwireDashboard from '../screens/QwikwireDashboard';
import QwikwirePayments from '../screens/QwikwirePayments';
import QwikwirePayment from '../screens/QwikwirePayment';
import QwikwireEnrollments from '../screens/QwikwireEnrollments';
import QwikwireEnrollment from '../screens/QwikwireEnrollment';
import QwikwireSettlements from '../screens/QwikwireSettlements';
import QwikwireSettlement from '../screens/QwikwireSettlement';
import QwikwireDisputes from '../screens/QwikwireDisputes';
import QwikwireDispute from '../screens/QwikwireDispute';
import QwikwireReports from '../screens/QwikwireReports';
import QwikwireReportPaymentIssuers from '../screens/QwikwireReportPaymentIssuers';
import QwikwireReportPaymentsByProject from '../screens/QwikwireReportPaymentsByProject';
import CreateSettlementReport from '../screens/CreateSettlementReport';
import QwikwireMerchant from '../screens/QwikwireMerchant';
import { Banner } from '../components';
import withTracker from './withTracker';
import '../styles/qwikwire.css';

function getInitialState(data) {
  return {
    searchQuery: data.query || null,
  };
}

function reducer(prevState, action) {
  switch (action.type) {
    case 'UPDATE_SEARCH_QUERY':
      return {
        ...prevState,
        searchQuery: action.searchQuery,
      };
    default:
      return prevState;
  }
}

/**
 * Qwikwire Layout component is used for syadmin, engineering, finance, support
 * and internal staff in Qwikwire. They have different layout compared to enterprise users.
 */
function QwikwireLayout(props) {
  const { user, merchants, appState } = props;
  const history = useHistory();
  const location = useLocation();
  const urlQueries = qs.parse(location.search);

  const initialState = getInitialState({ query: urlQueries.query });
  const [state, dispatch] = useReducer(reducer, initialState);

  useEffect(() => {
  }, []);

  const layoutProps = {
    layout: {
      searchQuery: state.searchQuery,
      updateSearchQuery: searchQuery => dispatch({ type: 'UPDATE_SEARCH_QUERY', searchQuery }),
    },
    user,
    merchants,
    appState,
  };

  // Added "exempt" to remove history, location and match from the component props
  // to prevent remount when history.push is executed. This is helpful for
  // search tables patterns.
  const routes = [
    { path: '/', exact: true, component: Home },
    { path: '/search', exact: true, component: TransactionsSearchResults },
    { path: '/dashboard', exact: true, component: QwikwireDashboard },
    { path: '/payments', exact: true, exempt: true, component: QwikwirePayments },
    { path: '/payments/:invoiceId', exact: true, component: QwikwirePayment },
    { path: '/enrollments', exact: true, exempt: true, component: QwikwireEnrollments },
    { path: '/enrollments/:transactionId', exact: true, component: QwikwireEnrollment },
    { path: '/settlements', exact: true, exempt: true, component: QwikwireSettlements },
    { path: '/settlements/:merchantCode/new', exact: true, component: CreateSettlementReport },
    { path: '/settlements/:settlementId', exact: true, component: QwikwireSettlement },
    { path: '/disputes', exact: true, component: QwikwireDisputes },
    { path: '/disputes/:disputeId', exact: true, component: QwikwireDispute },
    { path: '/reports', exact: true, component: QwikwireReports },
    { path: '/reports/payments', exact: true, component: QwikwireReportPaymentsByProject },
    { path: '/reports/issuers', exact: true, component: QwikwireReportPaymentIssuers },
    { path: '/users', exact: true, component: Users },
    { path: '/users/new', exact: true, component: CreateUser },
    { path: '/users/:userId', exact: true, component: EditUser },
    { path: '/account', exact: true, component: AccountSettings },
    { path: '/activities', exact: true, component: Activities },
    { path: '/merchants', exact: true, component: Merchants },
    { path: '/merchants/:merchantCode', exact: true, component: QwikwireMerchant },
    { path: '/login', exact: false, component: Redirect, props: { to: '/' } },
    { path: '/signup', exact: false, component: Redirect, props: { to: '/' } },
    { path: '/forgot-password', exact: false, component: Redirect, props: { to: '/' } },
    { path: '/password-reset', exact: false, component: Redirect, props: { to: '/' } },
    { path: '*', exact: true, component: NotFound },
  ];

  return (
    <Layout className="main">
      <Banner />
      <Layout.Sider theme="light" width="250" className="sidebar">
        <QwikwireNavigation />
      </Layout.Sider>
      <Layout className="main-layout">
        <QwikwireMainNavbar
          searchQuery={state.searchQuery}
          onSearch={(searchQuery) => {
            if (location.pathname !== '/search') {
              history.push(`/search?query=${searchQuery}`);
            }
            dispatch({ type: 'UPDATE_SEARCH_QUERY', searchQuery });
          }}
        />
        <Layout.Content className="main-layout-content">
          <Switch>
            {routes.map((ir) => {
              const Component = ir.component;
              const componentProps = ir.props || {};
              const TrackedComponent = withTracker(trackerProps => (
                <Component {...trackerProps} {...layoutProps} {...componentProps} />
              ));

              if (ir.exempt) {
                return (
                  <Route key={ir.path} exact={ir.exact} path={ir.path}>
                    <Component {...layoutProps} {...componentProps} />
                  </Route>
                );
              }

              return (
                <Route
                  key={ir.path}
                  exact={ir.exact}
                  path={ir.path}
                  component={TrackedComponent}
                />
              );
            })}
          </Switch>
        </Layout.Content>
      </Layout>
    </Layout>
  );
}

QwikwireLayout.propTypes = {
  appState: PropTypes.number.isRequired,
  user: PropTypes.shape({
    id: PropTypes.number,
  }),
  merchants: PropTypes.arrayOf(PropTypes.any),
};

QwikwireLayout.defaultProps = {
  user: null,
  merchants: [],
};

export default QwikwireLayout;
