import api from '../helpers/apiClient';
import { exportUrl } from '../config/index';

/**
 * @param {number} merchantId
 * @param {{ page: number, size: number, query: string }} opts
 * @returns {Promise<any>}
 */
export function getProjectsService(merchantId, opts) {
  return api.get(`/merchants/${merchantId}/projects`, {
    params: {
      page: opts.page,
      size: opts.size,
      query: opts.query,
    },
  });
}

/**
 * @param {number} merchantId
 * @param {number} projectId
 * @returns {Promise<any>}
 */
export function findProjectService(merchantId, projectId) {
  return api.get(`/merchants/${merchantId}/projects/${projectId}`);
}

export function publishProjectService(merchantId, projectId) {
  return api.post(`/merchants/${merchantId}/projects/${projectId}/publish`);
}

export function disableProjectService(merchantId, projectId) {
  return api.post(`/merchants/${merchantId}/projects/${projectId}/disable`);
}

export function deleteProjectService(merchantId, projectId) {
  return api.delete(`/merchants/${merchantId}/projects/${projectId}`);
}

export function exportMerchantProjectsService(merchant, searchOption, token) {
  let url = `${exportUrl}/merchants/${merchant.code}/projects/export?token=${token}`;
  if (searchOption.query) { url += `&query=${searchOption.query}`; }
  window.open(url, '_blank');
}

/**
 * @param {string} merchantCode
 * @param {number} projectId
 * @param {any[]} payload
 * @returns {Promise<any>}
 */
export function updateProjectMetadata(merchantId, projectId, payload) {
  return api.post(`/merchants/${merchantId}/projects/${projectId}/metadata`, payload);
}
