import React, { Fragment, useReducer, useEffect } from 'react';
import PropTypes from 'prop-types';
import * as moment from 'moment';
import { Form, Modal, Select, Button, DatePicker, Checkbox } from 'antd';
import Message from '../Message';
import { getMerchantsService, getPaymentTypesService, getMerchantById } from '../../services/merchants';
import { getProjectsService } from '../../services/projects';
import '../../styles/filtermodal.scss';

const DATE_FORMAT = 'YYYY-MM-DD';
const MAX_DAYS_ON_DATE_RANGE = 365;

const ENROLLMENT_FILTER_MODAL_ACTIONS = {
  OPEN_MODAL: 'OPEN_MODAL',
  CLOSE_MODAL: 'CLOSE_MODAL',
  INITIALIZE_MODAL: 'INITIALIZE_MODAL',
  INITIALIZE_MERCHANT_FIELDS: 'INITIALIZE_MERCHANT_FIELDS',
  SET_STATUS: 'SET_STATUS',
  SET_MERCHANT: 'SET_MERCHANT',
  SET_MERCHANT_OPTIONS: 'SET_MERCHANT_OPTIONS',
  SET_CREATED_AT_RANGE: 'SET_CREATED_AT_RANGE',
  APPLY_FILTERS: 'APPLY_FILTERS',
  RESET_FILTERS: 'RESET_FILTERS',
  SET_MESSAGE: 'SET_MESSAGE',
  CLEAR_MESSAGE: 'CLEAR_MESSAGE',
};

/**
 * @param {{
 *  onApply: function,
 *  searchOption: {
 *    status: string[],
 *    merchant: string,
 *    project: string,
 *    paymentType: string,
 *    startDate: any
 *    endDate: any
 *  },
 *  defaultOption: {
 *    status: string[],
 *    startDate: any,
 *    endDate: any,
 *  }
 * }} props
 */
function EnrollmentsFilterModal(props) {
  const { onApply, searchOption, defaultOption, merchant } = props;
  const [state, dispatch] = useReducer((prevState, action) => {
    switch (action.type) {
      case ENROLLMENT_FILTER_MODAL_ACTIONS.OPEN_MODAL:
        return {
          ...prevState,
          isVisible: true,
        };
      case ENROLLMENT_FILTER_MODAL_ACTIONS.CLOSE_MODAL:
        return {
          ...prevState,
          isVisible: false,
        };
      case ENROLLMENT_FILTER_MODAL_ACTIONS.INITIALIZE_MODAL:
        return {
          ...prevState,
          merchants: action.merchants,
          merchant: (() => {
            if (!prevState.merchant) { return null; }
            return action.merchants.find(m => m.code === prevState.merchant.code);
          })(),
        };
      case ENROLLMENT_FILTER_MODAL_ACTIONS.INITIALIZE_MERCHANT_FIELDS:
        return {
          ...prevState,
          merchant: action.merchant,
          projects: action.projects,
          paymentTypes: action.paymentTypes,
          project: action.project,
          paymentType: action.paymentType,
        };
      case ENROLLMENT_FILTER_MODAL_ACTIONS.SET_STATUS:
        return {
          ...prevState,
          checkedStatus: action.checkedStatus,
          status: null,
          message: null,
        };
      case ENROLLMENT_FILTER_MODAL_ACTIONS.SET_MERCHANT:
        return {
          ...prevState,
          merchant: action.merchant,
          project: null,
          paymentType: null,
          status: null,
          message: null,
        };
      case ENROLLMENT_FILTER_MODAL_ACTIONS.SET_MERCHANT_OPTIONS:
        return {
          ...prevState,
          project: action.project,
          paymentType: action.paymentType,
          status: null,
          message: null,
        };
      case ENROLLMENT_FILTER_MODAL_ACTIONS.SET_CREATED_AT_RANGE:
        return {
          ...prevState,
          startDate: action.startDate,
          endDate: action.endDate,
          status: null,
          message: null,
        };
      case ENROLLMENT_FILTER_MODAL_ACTIONS.APPLY_FILTERS:
        return {
          ...prevState,
          isVisible: false,
          status: null,
          message: null,
        };
      case ENROLLMENT_FILTER_MODAL_ACTIONS.RESET_FILTERS:
        return {
          ...prevState,
          startDate: action.startDate,
          endDate: action.endDate,
          checkedStatus: action.status,
          merchant: action.merchant,
          project: null,
          paymentType: null,
          status: null,
          message: null,
        };
      case ENROLLMENT_FILTER_MODAL_ACTIONS.SET_MESSAGE:
        return {
          ...prevState,
          status: action.status,
          message: action.message,
        };
      case ENROLLMENT_FILTER_MODAL_ACTIONS.CLEAR_MESSAGE:
        return {
          ...prevState,
          status: null,
          message: null,
        };
      default:
        return prevState;
    }
  }, {
    isVisible: false,
    status: null,
    message: null,
    checkedStatus: searchOption.status,
    // NOTE: if props.merchant is passed, disable merchant, due at and report at filters
    merchant: searchOption.merchant ? { id: Number(searchOption.merchant) } : merchant,
    project: { id: Number(searchOption.project) },
    paymentType: { id: Number(searchOption.paymentType) },
    startDate: searchOption.startDate,
    endDate: searchOption.endDate,
    merchants: [],
    projects: [],
    paymentTypes: [],
  });

  useEffect(() => {
    let ableToSet = true;

    (async () => {
      try {
        if (state.merchant) {
          const { data } = await getMerchantById(state.merchant.id);
          if (ableToSet) {
            dispatch({ type: ENROLLMENT_FILTER_MODAL_ACTIONS.SET_MERCHANT, merchant: data });
            dispatch({ type: ENROLLMENT_FILTER_MODAL_ACTIONS.INITIALIZE_MODAL, merchants: [data] });
          }
        }
      } catch (error) {
        if (ableToSet) {
          dispatch({ type: ENROLLMENT_FILTER_MODAL_ACTIONS.INITIALIZE_MODAL, merchants: [] });
        }
      }
    })();

    return () => { ableToSet = false; };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    let ableToSet = true;

    (async () => {
      if (!state.merchant || !state.merchant.id) {
        return;
      }

      try {
        const { id } = state.merchant;
        const { data: { projects } } = await getProjectsService(id, { page: 1, size: 200 });
        const { data: { paymentTypes } } = await getPaymentTypesService(id);
        if (ableToSet) {
          dispatch({
            type: ENROLLMENT_FILTER_MODAL_ACTIONS.INITIALIZE_MERCHANT_FIELDS,
            merchant: state.merchant,
            projects,
            paymentTypes,
            project: projects.find(ps => ps.id === (state.project && state.project.id ? state.project.id : null)),
            paymentType: paymentTypes.find(pt => pt.id === (state.paymentType && state.paymentType.id ? state.paymentType.id : null)),
          });
        }
      } catch (error) {
        if (ableToSet) {
          dispatch({
            type: ENROLLMENT_FILTER_MODAL_ACTIONS.INITIALIZE_MERCHANT_FIELDS,
            merchant: state.merchant,
            projects: [],
            paymentTypes: [],
            project: { id: 0 },
            paymentType: { id: 0 },
          });
        }
      }
    })();

    return () => { ableToSet = false; };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.merchant]);

  function submitFilters() {
    const opts = {
      startDate: state.startDate,
      endDate: state.endDate,
      status: state.checkedStatus,
      merchantId: state.merchant ? state.merchant.id : null,
      projectId: state.project ? state.project.id : null,
      paymentTypeId: state.paymentType ? state.paymentType.id : null,
    };
    onApply(opts);
    dispatch({ type: ENROLLMENT_FILTER_MODAL_ACTIONS.APPLY_FILTERS });
  }

  function onSelectFilter(inputValue, option) {
    const { props: { children } } = option;
    return children.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1;
  }

  async function onSelectMerchant(merchantId) {
    if (!merchantId) {
      dispatch({ type: ENROLLMENT_FILTER_MODAL_ACTIONS.SET_MERCHANT, merchant: null });
    } else {
      const foundMerchant = state.merchants.find(ps => ps.id === merchantId);
      const { data: { projects } } = await getProjectsService(merchantId, { page: 1, size: 200 });
      const { data: { paymentTypes } } = await getPaymentTypesService(merchantId);

      dispatch({
        type: ENROLLMENT_FILTER_MODAL_ACTIONS.INITIALIZE_MERCHANT_FIELDS,
        merchant: foundMerchant,
        projects,
        paymentTypes,
        project: projects.length > 0 ? projects[0] : { id: 0 },
        paymentType: paymentTypes.length > 0 ? paymentTypes[0] : { id: 0 },
      });
    }
  }

  let timeout;
  let currentValue;

  async function onSearchMerchant(merchantCode) {
    if (!merchantCode) {
      dispatch({ type: ENROLLMENT_FILTER_MODAL_ACTIONS.SET_MERCHANT, merchant: null });
      return;
    }

    if (timeout) {
      clearTimeout(timeout);
      timeout = null;
    }

    currentValue = merchantCode;
    async function searchMerchant() {
      if (timeout) {
        clearTimeout(timeout);
        timeout = null;
      }
      try {
        if (merchant) {
          dispatch({
            type: ENROLLMENT_FILTER_MODAL_ACTIONS.INITIALIZE_MODAL,
            merchants: [merchant],
          });
          return;
        }

        let searchOptions = {
          size: 20,
          page: 1,
        };

        if (merchantCode) {
          searchOptions = {
            query: merchantCode,
            size: 20,
            page: 1,
          };
        }

        const { data } = await getMerchantsService(searchOptions);
        if (currentValue === merchantCode) {
          dispatch({ type: ENROLLMENT_FILTER_MODAL_ACTIONS.INITIALIZE_MODAL, merchants: data.merchants });
        }
      } catch (error) {
        dispatch({ type: ENROLLMENT_FILTER_MODAL_ACTIONS.INITIALIZE_MODAL, merchants: [] });
      }
    }

    // Set timeout to minimize flooding the server with API calls when
    // searching for merchants
    timeout = setTimeout(searchMerchant, 400);
  }

  function onSelectProject(projectId) {
    if (!projectId) {
      dispatch({
        type: ENROLLMENT_FILTER_MODAL_ACTIONS.SET_MERCHANT_OPTIONS,
        project: null,
        paymentType: state.paymentType,
      });
    } else {
      const project = state.projects.find(ps => ps.id === projectId);
      dispatch({
        type: ENROLLMENT_FILTER_MODAL_ACTIONS.SET_MERCHANT_OPTIONS,
        project,
        paymentType: state.paymentType,
      });
    }
  }

  function onSelectPaymentType(paymentTypeId) {
    if (!paymentTypeId) {
      dispatch({
        type: ENROLLMENT_FILTER_MODAL_ACTIONS.SET_MERCHANT_OPTIONS,
        project: state.project,
        paymentType: null,
      });
    } else {
      const paymentType = state.paymentTypes.find(ps => ps.id === paymentTypeId);
      dispatch({
        type: ENROLLMENT_FILTER_MODAL_ACTIONS.SET_MERCHANT_OPTIONS,
        project: state.project,
        paymentType,
      });
    }
  }

  function onSelectedCreatedAtRange(createdDates) {
    const [start, end] = createdDates;
    const startDate = start.isValid() ? start.format(DATE_FORMAT) : null;
    const endDate = end.isValid() ? end.format(DATE_FORMAT) : null;
    const diffInDays = moment(endDate).diff(moment(startDate), 'days');
    if (diffInDays > MAX_DAYS_ON_DATE_RANGE) {
      dispatch({
        type: ENROLLMENT_FILTER_MODAL_ACTIONS.SET_MESSAGE,
        message: 'You cannot select date range exceeding more than 365 days',
        status: 'error',
      });
      return;
    }
    dispatch({
      type: ENROLLMENT_FILTER_MODAL_ACTIONS.SET_CREATED_AT_RANGE,
      startDate: startDate ? moment(startDate, DATE_FORMAT) : null,
      endDate: endDate ? moment(endDate, DATE_FORMAT) : null,
    });
  }

  const footerActions = (
    <Fragment>
      <Button
        className="button button-standard button-standard-outline button-small"
        key="back"
        onClick={() => {
          dispatch({ type: ENROLLMENT_FILTER_MODAL_ACTIONS.CLOSE_MODAL });
        }}
      >
        Cancel
      </Button>
      <Button
        className="button button-standard button-primary button-small"
        key="submit"
        type="primary"
        onClick={submitFilters}
      >
        Apply
      </Button>
    </Fragment>
  );

  return (
    <Fragment>
      <Button
        icon="bars"
        className="button button-standard button-standard-outline button-small"
        onClick={() => dispatch({ type: ENROLLMENT_FILTER_MODAL_ACTIONS.OPEN_MODAL })}
      >
        Filter
      </Button>
      <Modal
        centered
        className="filter-modal transaction-filter"
        title="Filter Enrollments"
        focusTriggerAfterClose={false}
        visible={state.isVisible}
        onOk={submitFilters}
        onCancel={() => {
          dispatch({ type: ENROLLMENT_FILTER_MODAL_ACTIONS.CLOSE_MODAL });
        }}
        footer={footerActions}
      >
        <Form onSubmit={() => {}}>
          {state && state.status && state.message && (
            <Message
              message={state.message}
              status={state.status}
              onClose={() => dispatch({ type: ENROLLMENT_FILTER_MODAL_ACTIONS.CLEAR_MESSAGE })}
            />
          )}
          <Form.Item>
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
              <label className="label" htmlFor="status">Status</label>
              <div
                role="presentation"
                className="clear-link"
                onClick={() => {
                  dispatch({
                    type: ENROLLMENT_FILTER_MODAL_ACTIONS.RESET_FILTERS,
                    startDate: defaultOption.startDate,
                    endDate: defaultOption.endDate,
                    status: defaultOption.status,
                    merchant,
                  });
                }}
              >
                Reset
              </div>
            </div>
            <Checkbox.Group
              name="status"
              options={[
                { label: 'Ongoing', value: 'ONGOING' },
                { label: 'Done', value: 'DONE' },
                { label: 'Incomplete', value: 'INC' },
                { label: 'Pending', value: 'PENDING' },
                { label: 'Cancelled', value: 'CANCELLED' },
                { label: 'Declined', value: 'DECLINED' },
                { label: 'For Review', value: 'FOR REVIEW' },
              ]}
              value={state.checkedStatus}
              onChange={(checkedStatus) => {
                dispatch({ type: ENROLLMENT_FILTER_MODAL_ACTIONS.SET_STATUS, checkedStatus });
              }}
            />
          </Form.Item>
          <Form.Item>
            <label className="label" htmlFor="createdAt">Created At</label>
            <div>
              <DatePicker.RangePicker
                value={[state.startDate, state.endDate]}
                format={DATE_FORMAT}
                allowClear={false}
                style={{ width: '100%' }}
                onChange={onSelectedCreatedAtRange}
                disabledDate={current => moment().isBefore(current)}
              />
            </div>
          </Form.Item>
          {!merchant && (
            <Form.Item>
              <label className="label" htmlFor="merchant">Merchant</label>
              <Select
                id="merchant"
                class="merchant-select"
                showSearch
                defaultValue={state.merchant ? state.merchant.id : undefined}
                placeholder="Merchant"
                style={{ width: '100%' }}
                defaultActiveFirstOption={false}
                allowClear
                showArrow={false}
                filterOption={onSelectFilter}
                onChange={onSelectMerchant}
                onSearch={onSearchMerchant}
                notFoundContent={null}
              >
                {state.merchants.map(m => (
                  <Select.Option key={m.id} value={m.id}>{m.name}</Select.Option>
                ))}
              </Select>
            </Form.Item>
          )}
          {state.merchant && (
            <Fragment>
              <Form.Item>
                <label className="label" htmlFor="project">Project</label>
                <Select
                  id="project"
                  style={{ width: '100%' }}
                  allowClear
                  showArrow={false}
                  showSearch
                  placeholder="Project"
                  value={state.project ? state.project.id : undefined}
                  onSelect={onSelectProject}
                  onChange={onSelectProject}
                  filterOption={onSelectFilter}
                >
                  {state.projects.map(m => (
                    <Select.Option key={m.id} value={m.id}>
                      {m.category ? `${m.category} - ${m.name}` : m.name}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
              <Form.Item>
                <label className="label" htmlFor="paymentType">Payment Type</label>
                <Select
                  id="paymentType"
                  style={{ width: '100%' }}
                  allowClear
                  showArrow={false}
                  showSearch
                  placeholder="Payment type"
                  value={state.paymentType ? state.paymentType.id : undefined}
                  onSelect={onSelectPaymentType}
                  onChange={onSelectPaymentType}
                  filterOption={onSelectFilter}
                >
                  {state.paymentTypes.map(m => (
                    <Select.Option key={m.id} value={m.id}>{m.name}</Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </Fragment>
          )}
        </Form>
      </Modal>
    </Fragment>
  );
}

EnrollmentsFilterModal.propTypes = {
  onApply: PropTypes.func.isRequired,
  searchOption: PropTypes.shape({
    status: PropTypes.arrayOf(PropTypes.string).isRequired,
    startDate: PropTypes.object.isRequired,
    endDate: PropTypes.object.isRequired,
    merchant: PropTypes.string,
    project: PropTypes.string,
    paymentType: PropTypes.string,
    query: PropTypes.string,
    page: PropTypes.number.isRequired,
    size: PropTypes.number.isRequired,
  }).isRequired,
  defaultOption: PropTypes.shape({
    status: PropTypes.arrayOf(PropTypes.string),
    startDate: PropTypes.object,
    endDate: PropTypes.object,
  }).isRequired,
  merchant: PropTypes.shape({
    id: PropTypes.number.isRequired,
    code: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
  }),
};

EnrollmentsFilterModal.defaultProps = {
  merchant: null,
};

export default EnrollmentsFilterModal;
