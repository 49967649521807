import React, { useContext, useEffect, useReducer, useState } from 'react';
import PropTypes from 'prop-types';
import { withRouter, Link } from 'react-router-dom';
import { Button, Form, Input } from 'antd';
import SessionContext from '../../contexts/SessionContext';
import { PasswordMeter, SingleForm } from '../../components';
import { signupService, decodeEmailService } from '../../services/auth';
import '../../styles/authform.css';


const initialState = {
  isVerifyingToken: false,
  isSigningUp: false,
  isTokenValid: false,
  errorMessage: null,
  user: null,
};

function reducer(prevState, action) {
  switch (action.type) {
    case 'VERIFYING_TOKEN':
      return {
        ...prevState,
        isVerifyingToken: true,
      };

    case 'VERIFYING_TOKEN_SUCCESSFUL':
      return {
        ...prevState,
        isVerifyingToken: false,
        isTokenValid: true,
        user: action.user,
      };

    case 'VERIFYING_TOKEN_FAILED':
      return {
        ...prevState,
        isVerifyingToken: false,
        isTokenValid: false,
        errorMessage: action.message,
      };

    case 'SIGNUP':
      return {
        ...prevState,
        isSigningUp: true,
      };

    case 'SIGNUP_SUCCESSFUL':
      return {
        ...prevState,
        isSigningUp: false,
      };

    case 'SIGNUP_FAILED':
      return {
        ...prevState,
        isSigningUp: false,
        errorMessage: action.message,
      };

    case 'CLEAR_ERROR_MESSAGE':
      return {
        ...prevState,
        errorMessage: null,
      };

    default:
      return prevState;
  }
}

const formItemLayout = { labelCol: { span: 24 }, wrapperCol: { span: 24 } };
const { Password } = Input;
const SignUp = (props) => {
  const { match: { params: { encodedEmail } }, form, history } = props;
  const { getFieldDecorator } = form;
  const { showToast } = useContext(SessionContext);
  const [state, dispatch] = useReducer(reducer, initialState);
  const [password, setPassword] = useState(null);
  const [isPasswordAcceptable, setIsPasswordAcceptable] = useState(false);

  const passwordMeter = (
    <PasswordMeter
      isPasswordAcceptable={setIsPasswordAcceptable}
      password={password}
    />
  );

  useEffect(() => {
    let ableToSet = true;

    (async () => {
      dispatch({ type: 'VERIFYING_TOKEN' });
      try {
        const { data: { person } } = await decodeEmailService(encodedEmail);
        if (ableToSet) dispatch({ type: 'VERIFYING_TOKEN_SUCCESSFUL', user: person });
      } catch (err) {
        const { message } = err && err.response
          ? err.response.data
          : { message: 'Unable to validate the sign up request' };

        if (ableToSet) dispatch({ type: 'VERIFYING_TOKEN_FAILED', message });
      }
    })();

    return () => { ableToSet = false; };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function onSubmit(e) {
    e.preventDefault();
    if (!isPasswordAcceptable) { return; }

    dispatch({ type: 'SIGNUP' });
    form.validateFields(async (err, values) => {
      if (err) {
        const message = 'Please check that your inputs are correct';
        dispatch({ type: 'SIGNUP_FAILED', message });
        showToast({ type: 'error', message });
      } else {
        try {
          const { password: newPassword, firstname, lastname } = values;
          await signupService(encodedEmail, state.user.id, newPassword, firstname, lastname);
          dispatch({ type: 'SIGNUP_SUCCESSFUL' });
          showToast({ type: 'success', message: 'Sign up successful' });
          history.push(`/login?email=${encodeURIComponent(state.user.email)}&signup=success`);
        } catch (error) {
          const message = error && error.response
            ? error.response.data.message
            : 'Unable to complete the sign up request';
          dispatch({ type: 'SIGNUP_FAILED', message });
          showToast({ type: 'error', message });
        }
      }
    });
  }

  if (state.isVerifyingToken) {
    return <SingleForm><p>Loading...</p></SingleForm>;
  }

  if (!state.isVerifyingToken && !state.isTokenValid) {
    return (
      <SingleForm
        title="Sign Up"
        subtitle={state.errorMessage}
      >
        <div>
          If you believe this to be a mistake, please contact us and we will sort it out for you.
        </div>
        <div style={{ marginTop: '30px', marginBottom: '15px' }}>
          <Link to="/login" className="button button-standard button-fullwidth">
            Back to log in
          </Link>
        </div>
      </SingleForm>
    );
  }

  return (
    <SingleForm
      title="Create your account"
      subtitle="To create your account, please enter your password"
      message={state.errorMessage}
      status={state.errorMessage ? 'error' : null}
      onMessageClose={() => dispatch({ type: 'CLEAR_ERROR_MESSAGE' })}
    >
      <Form onSubmit={onSubmit}>
        <Form.Item {...formItemLayout} className="name-input" label="Given Name">
          {getFieldDecorator('firstname', {
            rules: [
              { required: true, message: 'Please enter your given name.' },
              { max: 50, message: 'Given name must be a maximum of 50 characters.' },
            ],
            initialValue: state.user.firstName,
          })(<Input />)}
        </Form.Item>
        <Form.Item {...formItemLayout} className="name-input" label="Family Name">
          {getFieldDecorator('lastname', {
            rules: [
              { required: true, message: 'Please enter your family name.' },
              { max: 50, message: 'Last name must be a maximum of 50 characters.' },
            ],
            initialValue: state.user.lastName,
          })(<Input />)}
        </Form.Item>
        <Form.Item {...formItemLayout} extra={passwordMeter} label="Password">
          {getFieldDecorator('password', {
            rules: [{ required: true, message: 'Please enter your password' }],
          })(<Password
            placeholder="New Password"
            onChange={e => setPassword(e.target.value)}
          />)}
        </Form.Item>
        <Form.Item>
          <Button
            className="button button-standard button-fullwidth"
            htmlType="submit"
            loading={state.isSigningUp}
            disabled={state.isSigningUp || !isPasswordAcceptable}
          >
            Create Account
          </Button>
        </Form.Item>
      </Form>
    </SingleForm>
  );
};

SignUp.propTypes = {
  form: PropTypes.shape({
    getFieldDecorator: PropTypes.func.isRequired,
    validateFields: PropTypes.func.isRequired,
  }).isRequired,
  match: PropTypes.shape({
    params: PropTypes.shape({
      encodedEmail: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }).isRequired,
};

SignUp.defaultProps = {
};

export default withRouter(Form.create()(SignUp));
