import moment from 'moment';
import React, { Fragment, useReducer, useEffect, useContext } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { Breadcrumb, Card, Col, Row, Steps } from 'antd';
import { SingleForm } from '../../components';
import SettlementSelectPaymentsStep from '../../components/SettlementSelectPaymentsStep';
import SettlementUploadDocumentStep from '../../components/SettlementUploadDocumentStep';
import SettlementDateStep from '../../components/SettlementDateStep';
import SessionContext from '../../contexts/SessionContext';
import {
  PastStepIcon, CurrentStepIcon, FutureStepIcon, SettlementReportsIconBig,
} from '../../components/Icons';
import { getMerchantByCode } from '../../services/merchants';
import { createSettlementService } from '../../services/settlements';
import { getAPIErrorMessage } from '../../helpers/utils';
import { APP_STATES } from '../../constants';
import './createupdatesettlementreport.css';

const initialState = {
  payments: [],
  merchant: null,
  isLoading: true,
  settlementFile: null,
  settledDate: moment(),
  settlementNotes: null,
  currentStep: 1,
  settlementId: null,
  referenceId: null,
  status: null,
  message: null,
  isSaving: false,
};

function reducer(prevState, action) {
  switch (action.type) {
    case 'SET_SETTLEMENT_CREATED':
      return {
        ...prevState,
        currentStep: 1,
      };
    case 'CHANGE_STEP':
      return {
        ...prevState,
        currentStep: action.step,
      };
    case 'UPDATE_PAYMENTS':
      return {
        ...prevState,
        payments: action.payments,
      };
    case 'UPDATE_SETTLEMENT_FILE':
      return {
        ...prevState,
        settlementFile: action.settlementFile,
      };
    case 'UPDATE_SETTLEMENT_FORM':
      return {
        ...prevState,
        settledDate: action.settledDate,
        settlementNotes: action.settlementNotes,
      };
    case 'CREATE_SETTLEMENT':
      return {
        ...prevState,
        isSaving: true,
        status: null,
        message: null,
      };
    case 'CREATE_SETTLEMENT_SUCCESS':
      return {
        ...prevState,
        isSaving: false,
        settlementId: action.settlementId,
        referenceId: action.referenceId,
        status: 'success',
        message: action.message,
      };
    case 'CREATE_SETTLEMENT_FAILED':
      return {
        ...prevState,
        isSaving: false,
        status: 'error',
        message: action.message,
      };
    case 'GET_MERCHANT_SUCCESSFUL':
      return {
        ...prevState,
        status: null,
        message: null,
        isLoading: false,
        merchant: action.merchant,
      };
    case 'GET_MERCHANT_FAILED':
      return {
        ...prevState,
        status: 'error',
        message: action.message,
        isLoading: false,
        merchant: null,
      };
    default:
      return prevState;
  }
}

const CreateSettlementReport = (props) => {
  const { appState, match, history } = props;
  const { merchantCode } = match.params;

  const sessionContext = useContext(SessionContext);
  const [state, dispatch] = useReducer(reducer, initialState);

  const { merchant, isLoading } = state;

  useEffect(() => {
    let ableToSet = true;

    (async () => {
      try {
        const response = await getMerchantByCode(merchantCode);
        if (ableToSet) {
          dispatch({
            type: 'GET_MERCHANT_SUCCESSFUL',
            merchant: response.data,
          });
        }
      } catch (err) {
        const message = getAPIErrorMessage(err, 'Merchant does not exist');
        if (ableToSet) {
          dispatch({
            type: 'GET_MERCHANT_FAILED',
            message,
          });
        }
      }
    })();

    return () => { ableToSet = false; };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!merchant || !state.settlementId) { return; }

    switch (state.status) {
      case 'success':
        history.push(`/settlements/${state.settlementId}`);
        break;
      case 'error':
        sessionContext.showToast({ type: 'error', message: state.message });
        break;
      default:
        break;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.status, state.message]);

  if (isLoading) {
    return <div />;
  }

  if (!isLoading && !merchant) {
    return (
      <SingleForm
        title="Merchant does not exist"
        subtitle="The page you requested could not be found. Please go back to Dashboard or contact us at support@aqwire.io."
        footer={{ show: true, to: '/', text: 'Back to Dashboard' }}
      />
    );
  }

  if (appState < APP_STATES.AUTHENTICATED) { return <div />; }

  async function createSettlement() {
    try {
      if (state.isSaving) { return; }

      dispatch({ type: 'CREATE_SETTLEMENT' });
      const { payments, settlementFile, settledDate, settlementNotes } = state;
      const response = await createSettlementService(merchant.id, {
        invoiceIds: payments.map(p => Number(p.invoiceId)),
        settlementFileId: settlementFile.settlementFileId,
        settledDate: settledDate.format('YYYY-MM-DD'),
        settlementNotes,
      });
      const { settlementId, referenceId, message } = response.data;
      dispatch({
        type: 'CREATE_SETTLEMENT_SUCCESS',
        message,
        settlementId,
        referenceId,
      });
    } catch (error) {
      const message = getAPIErrorMessage(error, 'Settlement has not been created');
      dispatch({ type: 'CREATE_SETTLEMENT_FAILED', message });
    }
  }

  const stepsViews = [
    {
      title: 'Select Payments',
      content: (
        <SettlementSelectPaymentsStep
          merchant={merchant}
          selectedPayments={state.payments}
          onNext={() => dispatch({ type: 'CHANGE_STEP', step: 2 })}
          onChange={payments => dispatch({ type: 'UPDATE_PAYMENTS', payments })}
        />
      ),
      icon: state.currentStep === 1 ? <CurrentStepIcon /> : <PastStepIcon />,
    },
    {
      title: 'Upload Report',
      content: (
        <Fragment>
          <div className="middle-col heading-primary subheading">
            <SettlementUploadDocumentStep
              merchant={merchant}
              payments={state.payments}
              settlementFile={state.settlementFile}
              onRemoveFile={() => {
                dispatch({ type: 'UPDATE_SETTLEMENT_FILE', settlementFile: null });
              }}
              onUpload={(settlementFile) => {
                dispatch({ type: 'UPDATE_SETTLEMENT_FILE', settlementFile });
              }}
              onNext={() => {
                dispatch({ type: 'CHANGE_STEP', step: 3 });
              }}
              onPrevious={() => {
                dispatch({ type: 'CHANGE_STEP', step: 1 });
              }}
            />
          </div>
        </Fragment>
      ),
      icon: ((step) => {
        switch (step) {
          case 1: return <FutureStepIcon />;
          case 2: return <CurrentStepIcon />;
          default: return <PastStepIcon />;
        }
      })(state.currentStep),
    },
    {
      title: 'Finalize and Save',
      content: (
        <SettlementDateStep
          settledDate={state.settledDate}
          settlementNotes={state.settlementNotes}
          merchant={merchant}
          payments={state.payments}
          file={state.settlementFile}
          isSaving={state.isSaving}
          onFormChange={({ settledDate, settlementNotes }) => {
            dispatch({ type: 'UPDATE_SETTLEMENT_FORM', settledDate, settlementNotes });
          }}
          onNext={() => {
            createSettlement();
          }}
          onPrevious={() => {
            dispatch({ type: 'CHANGE_STEP', step: 2 });
          }}
        />
      ),
      icon: state.currentStep === 3 ? <CurrentStepIcon /> : <FutureStepIcon />,
    },
  ];

  const view = stepsViews[state.currentStep - 1];
  return (
    <div className="qwikwire">
      <section style={{ display: 'flex', justifyContent: 'space-between' }}>
        <div className="transaction-breadcrumb">
          <div className="transaction-breadcrumb-icon">
            <SettlementReportsIconBig />
          </div>
          <Breadcrumb>
            <Breadcrumb.Item>
              <Link to="/settlements">All Settlements</Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item>
              <Link to={`/settlements?merchant=${merchant.id}`}>{merchant.name}</Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item>
              Create Settlement
            </Breadcrumb.Item>
          </Breadcrumb>
        </div>
      </section>
      <Card className="card-standard card-container" style={{ minHeight: 'auto' }}>
        <Row className="header">
          <Col span={10}>
            <div className="heading-primary">
              Upload Settlement Report
            </div>
            <div className="text-secondary">
              Settlement report requires tagging the settled payments,
              bank settlement file and the settled date.
            </div>
          </Col>
          <Col span={12} offset={2}>
            <Steps
              className="header-steps"
              current={state.currentStep}
              labelPlacement="vertical"
              size="small"
            >
              {stepsViews.map(s => <Steps.Step icon={s.icon} key={s.title} title={s.title} />)}
            </Steps>
          </Col>
        </Row>
        {view.content}
      </Card>
    </div>
  );
};

CreateSettlementReport.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      merchantCode: PropTypes.string.isRequired,
    }),
  }),
  appState: PropTypes.number.isRequired,
  history: PropTypes.shape({
    push: PropTypes.func,
    go: PropTypes.func,
  }),
  location: PropTypes.shape({
    search: PropTypes.string,
  }),
};

CreateSettlementReport.defaultProps = {
  match: {
    params: {
      settlementReferenceId: null,
    },
  },
  location: null,
  history: null,
};

export default CreateSettlementReport;
