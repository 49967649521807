import React from 'react';
import PropTypes from 'prop-types';
import { Link, Redirect } from 'react-router-dom';
import { Row, Col, Layout, Button } from 'antd';
import SingleForm from '../../components/SingleForm';
import { APP_STATES, ROLES } from '../../constants';

const Home = (props) => {
  const { user, merchants, appState } = props;

  if (appState < APP_STATES.NOT_AUTHENTICATED) {
    return <div />;
  }

  if (user) {
    if (user.systemRole[0] <= ROLES.AUDITOR) {
      return <Redirect to="/dashboard" />;
    }

    const firstMerchant = merchants[0];
    return <Redirect to={`/merchants/${firstMerchant.code}`} />;
  }

  return (
    <Layout>
      <SingleForm
        footer={{ show: false }}
      >
        <section style={{ marginTop: '16px', marginBottom: '16px' }}>
          <Row>
            <Col>
              <p className="text-primary">
                Enterprise dashboard is a platform to monitor AQWIRE transactions and accessed.
              </p>
              <p className="text-primary">
                You can contact us at support@aqwire.io if you have questions, concerns or issues.
              </p>
              <div style={{ marginTop: '20px' }}>
                <Link to="/login">
                  <Button
                    className="button button-standard button-fullwidth"
                    htmlType="submit"
                  >
                    Log In
                  </Button>
                </Link>
              </div>
            </Col>
          </Row>
        </section>
      </SingleForm>
    </Layout>
  );
};

Home.propTypes = {
  user: PropTypes.shape({
    id: PropTypes.number.isRequired,
    systemRole: PropTypes.array.isRequired,
  }),
  merchants: PropTypes.arrayOf(PropTypes.object),
  appState: PropTypes.number.isRequired,
};

Home.defaultProps = {
  user: null,
  merchants: [],
};

export default Home;
