import React from 'react';
import PropTypes from 'prop-types';
import {
  ExclamationMark, TimesCircleIcon, CloseIcon, InfoCircleIcon, CheckCircleIcon, CircleIcon,
} from '../Icons';
import './index.scss';

/**
 * Message is a replacement for antd's <Alert>. It currently supports basic
 * display of success, warning, info and error messages.
 * @param {{ status: string, message: string, onClose: function }} props
 */
function Message(props) {
  const { status, message, onClose } = props;

  const diameter = '22px';
  const [containerClass, targetIcon] = ((sts) => {
    switch (sts) {
      case 'info':
        return ['message-info', <InfoCircleIcon diameter={diameter} />];
      case 'warning':
        return ['message-warning', <ExclamationMark diameter={diameter} fill="#FFCC00" />];
      case 'error':
        return ['message-danger', <TimesCircleIcon diameter={diameter} />];
      case 'success':
        return ['message-success', <CheckCircleIcon diameter={diameter} />];
      default:
        return ['', <CircleIcon diameter={diameter} />];
    }
  })(status);

  return (
    <section className={`message ${containerClass}`}>
      <div className="message-wrapper">
        <span className="message-icon">
          {targetIcon}
        </span>
        <p>{message}</p>
      </div>
      <div
        className="message-close-icon"
        role="button"
        tabIndex="0"
        onKeyPress={() => onClose()}
        onClick={() => onClose()}
      >
        <CloseIcon diameter="20px" stroke="#8C8C8C" />
      </div>
    </section>
  );
}

Message.propTypes = {
  status: PropTypes.oneOf(['info', 'error', 'success', 'warning']),
  message: PropTypes.string,
  onClose: PropTypes.func,
};

Message.defaultProps = {
  status: 'info',
  message: null,
  onClose: () => {},
};

export default Message;
