import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import PartnerLayout from './PartnerLayout';
import QwikwireLayout from './QwikwireLayout';
import UserLayout from './UserLayout';
import SessionContext from '../contexts/SessionContext';

function AuthenticatedLayout(props) {
  const { activeMerchant } = useContext(SessionContext);
  const { user, merchants, appState } = props;
  const roleId = user.systemRole[0];
  if (roleId <= 60) {
    return <QwikwireLayout user={user} merchants={merchants} appState={appState} />;
  }
  if (activeMerchant.type === 'partner') {
    return <PartnerLayout user={user} appState={appState} />;
  }

  return <UserLayout user={user} merchants={merchants} appState={appState} />;
}

AuthenticatedLayout.propTypes = {
  appState: PropTypes.number.isRequired,
  user: PropTypes.shape({
    id: PropTypes.number,
    systemRole: PropTypes.arrayOf(PropTypes.any).isRequired,
  }),
  merchants: PropTypes.arrayOf(PropTypes.any),
};

AuthenticatedLayout.defaultProps = {
  user: null,
  merchants: [],
};

export default AuthenticatedLayout;
