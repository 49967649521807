import moment from 'moment';
import React, { useReducer, useContext } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { Row, Col, Breadcrumb, Button, DatePicker, Table, Select } from 'antd';
import qs from 'query-string';
import { PieChartIcon } from '../../components/Icons';

import SessionContext from '../../contexts/SessionContext';
import { getMerchantsService } from '../../services/merchants';
import { getSummaryPaymentByProjectPaymentTypeReport } from '../../services/reports';
import { getAPIErrorMessage, formatNumber } from '../../helpers/utils';
import './index.css';

const getInitialState = queryFilters => ({
  isGenerating: false,
  isFetchingMerchants: true,
  reportData: [],
  status: null,
  message: null,
  merchants: [],
  merchant: null,
  startDate: moment().subtract(8, 'hours'),
  endDate: moment().add(16, 'hours'),
  searchOption: {
    totalCount: 0,
    size: parseInt(queryFilters.size, 10) || 10,
    page: parseInt(queryFilters.page, 10) || 1,
  },
});

function ReportPaymentsByProject() {
  const dateFormat = 'YYYY-MM-DD';
  const location = useLocation();
  const { showToast } = useContext(SessionContext);
  const queryFilters = qs.parse(location.search, { ignoreQueryPrefix: true });

  const [state, dispatch] = useReducer((prevState, action) => {
    switch (action.type) {
      case 'GET_MERCHANTS':
        return {
          ...prevState,
          isFetchingMerchants: true,
        };
      case 'GET_MERCHANTS_SUCCESSFUL':
        return {
          ...prevState,
          isFetchingMerchants: false,
          merchants: action.merchants,
          searchOption: {
            ...prevState.searchOption,
            totalCount: action.totalCount,
          },
        };
      case 'GET_MERCHANTS_FAILED':
        return {
          ...prevState,
          isFetchingMerchants: false,
          message: action.message,
        };
      case 'UPDATE_MERCHANT':
        return {
          ...prevState,
          merchant: action.merchant,
        };
      case 'UPDATE_DATES':
        return {
          ...prevState,
          startDate: action.startDate,
          endDate: action.endDate,
        };
      case 'GENERATE_REPORT':
        return {
          ...prevState,
          isGenerating: true,
        };
      case 'GENERATE_REPORT_SUCCESSFUL':
        return {
          ...prevState,
          isGenerating: false,
          reportData: action.reportData,
          status: 'success',
          message: null,
        };
      case 'GENERATE_REPORT_FAILED':
        return {
          ...prevState,
          isGenerating: false,
          reportData: [],
          status: 'error',
          message: action.message,
        };
      case 'INITIALIZE_FILTERS':
        return {
          ...prevState,
          merchants: action.merchants,
          merchant: (() => {
            if (!prevState.merchant) { return null; }
            return action.merchants.find(m => m.code === prevState.merchant.code);
          })(),
        };
      default:
        return prevState;
    }
  }, getInitialState(queryFilters));

  async function generateReport() {
    try {
      dispatch({ type: 'GENERATE_REPORT' });
      const options = {
        startDate: state.startDate,
        endDate: state.endDate,
      };
      const { merchant } = state;
      const response = await getSummaryPaymentByProjectPaymentTypeReport(merchant, options);
      const reportData = response.data;
      dispatch({ type: 'GENERATE_REPORT_SUCCESSFUL', reportData });
    } catch (error) {
      const message = getAPIErrorMessage(error,
        'We are not able to generate the report. Please try again later.');
      dispatch({ type: 'GENERATE_REPORT_FAILED', message });
      showToast({ type: 'error', message, timeOut: 3000 });
    }
  }

  function onSelectMerchant(merchantId) {
    if (!merchantId) {
      dispatch({ type: 'UPDATE_MERCHANT', merchant: null });
    } else {
      const foundMerchant = state.merchants.find(ps => ps.id === merchantId);
      dispatch({ type: 'UPDATE_MERCHANT', merchant: foundMerchant });
    }
  }

  let timeout;
  let currentValue;

  async function onSearchMerchant(merchantCode) {
    if (!merchantCode) {
      dispatch({ type: 'UPDATE_MERCHANT', merchant: null });
      return;
    }

    if (timeout) {
      clearTimeout(timeout);
      timeout = null;
    }

    currentValue = merchantCode;
    async function searchMerchant() {
      if (timeout) {
        clearTimeout(timeout);
        timeout = null;
      }
      try {
        if (state.merchant) {
          dispatch({
            type: 'INITIALIZE_FILTERS',
            merchants: [state.merchant],
          });
          return;
        }

        let searchOptions = {
          size: 20,
          page: 1,
        };

        if (merchantCode) {
          searchOptions = {
            query: merchantCode,
            size: 20,
            page: 1,
          };
        }

        const { data } = await getMerchantsService(searchOptions);
        if (currentValue === merchantCode) {
          dispatch({ type: 'INITIALIZE_FILTERS', merchants: data.merchants });
        }
      } catch (error) {
        dispatch({ type: 'INITIALIZE_FILTERS', merchants: [] });
      }
    }

    // Set timeout to minimize flooding the server with API calls when
    // searching for merchants
    timeout = setTimeout(searchMerchant, 400);
  }

  return (
    <div className="qwikwire">
      <Row className="breadcrumb-row">
        <Col className="breadcrumb-header">
          <Breadcrumb className="breadcrumb-parent">
            <Breadcrumb.Item className="item-container">
              <div className="breadcrumb-item breadcrumb-icon">
                <PieChartIcon diameter="28px" />
              </div>
              <div className="breadcrumb-item icon-label">
                Reports
              </div>
            </Breadcrumb.Item>
          </Breadcrumb>
          <Breadcrumb separator=">">
            <Breadcrumb.Item className="breadcrumb-item">
              <Link to="/reports">
                <span>Reports</span>
              </Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item>
              <span>Payments by Project and Payment Type</span>
            </Breadcrumb.Item>
          </Breadcrumb>
        </Col>
      </Row>
      <section style={{ margin: '12px 0 20px' }}>
        <Row gutter={8} className="filters">
          <Col lg={6}>
            <h4 className="text-secondary">Merchant</h4>
            <Select
              className="transaction-filter"
              showSearch
              defaultValue={state.merchant ? state.merchant.id : undefined}
              placeholder="Select a merchant"
              style={{ width: '100%' }}
              defaultActiveFirstOption={false}
              allowClear
              showArrow={false}
              filterOption={(inputValue, option) => {
                const { props: { children } } = option;
                return children.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1;
              }}
              onChange={onSelectMerchant}
              onSearch={onSearchMerchant}
              notFoundContent={null}
            >
              {state.merchants.map(p => (
                <Select.Option value={p.id} key={p.code} label={p.name}>
                  {p.name}
                </Select.Option>
              ))}
            </Select>
          </Col>
          <Col lg={6}>
            <h4 className="text-secondary">Paid At/Created At</h4>
            <DatePicker
              className="transaction-filter"
              placeholder="Filter Date"
              defaultValue={state.startDate.isValid() ? state.startDate : null}
              format={dateFormat}
              style={{ width: '100%' }}
              onChange={(start) => {
                const startDate = start ? start.format(dateFormat) : null;
                const endDate = start ? start.format(dateFormat) : moment().add(16, 'hours');
                dispatch({
                  type: 'UPDATE_DATES',
                  startDate: moment(startDate, dateFormat).subtract(8, 'hours'),
                  endDate: moment(endDate, dateFormat).add(16, 'hours'),
                });
              }}
            />
          </Col>
          <Col lg={6} />
          <Col lg={6} style={{ display: 'flex', justifyContent: 'flex-end', marginTop: '26px' }}>
            <Button
              className="button button-standard button-standard-outline"
              loading={state.isGenerating}
              onClick={generateReport}
            >
              Generate Report
            </Button>
          </Col>
        </Row>
      </section>
      <Table
        bordered
        className="table-standard"
        dataSource={state.reportData}
        loading={state.isGenerating}
        columns={[
          {
            title: 'Project',
            key: 'project',
            align: 'center',
            render: item => (
              <div>{item.project || 'Unknown'}</div>
            ),
          },
          {
            title: 'Project Category',
            key: 'category',
            align: 'center',
            render: item => (
              <div>{item.category || 'Unknown'}</div>
            ),
          },
          {
            title: 'Reservation Fee',
            align: 'center',
            children: [
              {
                title: 'Count',
                key: 'rfCount',
                align: 'center',
                render: item => (
                  <div>{item.rfCount}</div>
                ),
              },
              {
                title: 'Amount',
                key: 'rfSum',
                align: 'center',
                render: item => (
                  <div>{`${item.currency} ${formatNumber(item.rfSum)}`}</div>
                ),
              },
            ],
          },
          {
            title: 'Monthly Amortization',
            align: 'center',
            children: [
              {
                title: 'Count',
                key: 'maCount',
                align: 'center',
                render: item => (
                  <div>{item.maCount}</div>
                ),
              },
              {
                title: 'Amount',
                key: 'maSum',
                align: 'center',
                render: item => (
                  <div>{`${item.currency} ${formatNumber(item.maSum)}`}</div>
                ),
              },
            ],
          },
          {
            title: 'Down Payment',
            align: 'center',
            children: [
              {
                title: 'Count',
                key: 'dpCount',
                align: 'center',
                render: item => (
                  <div>{item.dpCount}</div>
                ),
              },
              {
                title: 'Amount',
                key: 'dpSum',
                align: 'center',
                render: item => (
                  <div>{`${item.currency} ${formatNumber(item.dpSum)}`}</div>
                ),
              },
            ],
          },
        ]}
        pagination={{
          size: 'small',
          defaultCurrent: 1,
          defaultPageSize: 10,
        }}
        rowKey="key"
      />
    </div>
  );
}

export default ReportPaymentsByProject;
