import React, { Fragment, useReducer, useEffect } from 'react';
import { Table, Icon, Select } from 'antd';
import PropTypes from 'prop-types';
import moment from 'moment';

import { getWebhookLogs } from '../../services/logs';
import { retryFailedWebhookTask } from '../../services/merchants';
import { getWebhookTaskStatus } from '../../helpers/utils';
import './webhook-task-log-table.scss';

const initialState = {
  isFetchingWebhookLogs: false,
  isFetchingSingleLog: false,
  refreshInterval: 0,
  isModalVisible: false,
  webhookLogs: [],
};

function reducer(prevState, action) {
  switch (action.type) {
    case 'FETCH_WEBHOOK_LOGS':
      return {
        ...prevState,
        isFetchingWebhookLogs: true,
      };

    case 'SET_WEBHOOK_LOGS':
      return {
        ...prevState,
        webhookLogs: action.logs,
        isFetchingWebhookLogs: false,
      };

    case 'SET_REFRESH_INTERVAL':
      return {
        ...prevState,
        refreshInterval: action.refreshInterval,
      };

    default:
      return prevState;
  }
}

const WebhookTaskLogTable = (props) => {
  const { merchant, endpointId, onRowClick, onRetryComplete } = props;
  const [state, dispatch] = useReducer(reducer, initialState);
  const timezone = moment.tz.guess(true);
  const { Option } = Select;
  let interval;

  useEffect(() => {
    if (state.refreshInterval > 0) {
      interval = setInterval(retrieveLogs, state.refreshInterval);
    } else {
      retrieveLogs();
    }
    return () => {
      interval && clearInterval(interval);
    };
  }, [state.refreshInterval]);

  async function retrieveLogs() {
    dispatch({ type: 'FETCH_WEBHOOK_LOGS' });

    try {
      const { data: { logs } } = await getWebhookLogs(merchant.id, endpointId);
      dispatch({ type: 'SET_WEBHOOK_LOGS', logs });
    } catch (error) {
      dispatch({ type: 'SET_WEBHOOK_LOGS', logs: [] });
    }
  }

  async function retryFailedTask(taskId) {
    try {
      const { data: { message } } = await retryFailedWebhookTask(merchant.id, endpointId, taskId);
      onRetryComplete({ status: 'success', message });
    } catch (error) {
      const errMessage = error && error.response
        ? error.response.data.message
        : 'Unable to process the request';

      onRetryComplete({ status: 'error', message: errMessage });
    }
  }

  const columns = [
    {
      title: 'Status',
      key: 'status',
      render: row => (
        <div>
          {getWebhookTaskStatus(row)}
        </div>
      ),
    },
    {
      title: 'Event',
      render: row => (
        <Fragment>
          <code>
            <button
              type="submit"
              className="webhook-event"
              onClick={() => onRowClick(row)}
            >
              {row.webhookEvent}
            </button>
          </code>
        </Fragment>
      ),
    },
    {
      title: 'Created At',
      render: row => (
        <div>
          {moment(row.createdAt).tz(timezone).format('lll')}
        </div>
      ),
    },
    {
      render: row => (
        !row.isComplete && row.taskResponseCode !== null && (
          <div className="retry-webhook-event">
            <button type="submit" onClick={() => retryFailedTask(row.taskId)}>
              <Icon type="redo" />
            </button>
          </div>
        )
      ),
    },
  ];

  return (
    <div>
      <div style={{ textAlign: 'right', marginBottom: '10px' }}>
        {state.isFetchingWebhookLogs && <Icon type="loading" style={{ marginRight: '5px' }} />}
        <span>Refresh interval: </span>
        <Select
          defaultValue="0"
          style={{ minWidth: '110px' }}
          onChange={(value) => {
            dispatch({ type: 'SET_REFRESH_INTERVAL', refreshInterval: value * 1000 });
          }}
        >
          <Option key="0" value="0">Off</Option>
          {[5, 10, 15, 20, 30, 60, 80, 120, 240, 300].map(i => (
            <Option key={i} value={i}>
              {`${i} seconds`}
            </Option>
          ))}
        </Select>
      </div>

      <Table
        className="table-standard"
        dataSource={state.webhookLogs}
        columns={columns}
        rowKey="taskId"
        pagination={false}
      />
    </div>
  );
};

WebhookTaskLogTable.propTypes = {
  merchant: PropTypes.shape({
    id: PropTypes.number.isRequired,
    code: PropTypes.string.isRequired,
  }).isRequired,
  endpointId: PropTypes.string.isRequired,
  onRowClick: PropTypes.func.isRequired,
  onRetryComplete: PropTypes.func.isRequired,
};

export default WebhookTaskLogTable;
