import React, { useContext, useReducer, useState } from 'react';
import PropTypes from 'prop-types';
import { Row, Col, Breadcrumb, Button, Icon, Dropdown, Menu } from 'antd';
import { TagIcon } from '../../components/Icons';
import QueryBasedProjectsTable from '../../components/QueryBasedProjectsTable';
import SingleForm from '../../components/SingleForm';
import Message from '../../components/Message';
import SessionContext from '../../contexts/SessionContext';

import { exportMerchantProjectsService } from '../../services/projects';
import { authenticateMerchantExportTokenService } from '../../services/transactions';

const initialState = {
  message: null,
  status: null,
  searchOption: {
    page: 0,
    size: 0,
    totalCount: 0,
    query: null,
  },
  isExporting: false,
};

function reducer(prevState, action) {
  switch (action.type) {
    case 'SET_MESSAGE':
      return {
        ...prevState,
        status: action.status,
        message: action.message,
      };
    case 'EXPORTING_PROJECTS':
      return {
        ...prevState,
        isExporting: true,
      };
    case 'EXPORTING_PROJECTS_SUCCESSFUL':
      return {
        ...prevState,
        isExporting: false,
      };
    case 'EXPORTING_PROJECTS_FAILED':
      return {
        ...prevState,
        isExporting: false,
        status: action.status,
        message: action.message,
      };
    case 'UPDATE_SEARCH_OPTION':
      return {
        ...prevState,
        searchOption: action.searchOption,
      };
    case 'CLEAR_MESSAGE':
      return {
        ...prevState,
        message: null,
        status: null,
      };
    default:
      return prevState;
  }
}

function getFilteredURLPath(path, state) {
  const { query, page, size } = state;
  let url = `${path}?page=${page || 1}`;
  if (size) { url += `&size=${size}`; }
  if (query) { url += `&query=${query}`; }
  return url;
}

const Projects = (props) => {
  const { match, merchants, history, location } = props;
  const { merchantCode } = match.params;
  const { loggedInUser, activeMerchant, popMessage } = useContext(SessionContext);
  const [msg] = useState(popMessage());
  if (msg) {
    initialState.message = msg.message;
    initialState.status = msg.status;
  }
  const merchant = merchants.find(m => m.code === merchantCode);
  const [state, dispatch] = useReducer(reducer, initialState);

  const { merchantRoles } = loggedInUser;
  const { merchantRole } = merchantRoles.find(m => m.merchantCode === merchantCode);

  async function exportProjects() {
    try {
      if (state.searchOption.totalCount === 0) {
        dispatch({
          type: 'SET_MESSAGE',
          status: 'error',
          message: 'You cannot export zero projects into a CSV file',
        });
        return;
      }
      dispatch({ type: 'EXPORTING_PROJECTS' });
      const { data: { exportToken } } = await authenticateMerchantExportTokenService(merchant.id);
      exportMerchantProjectsService(merchant, state.searchOption, exportToken);
      dispatch({ type: 'EXPORTING_PROJECTS_SUCCESSFUL' });
    } catch (err) {
      const message = err && err.response
        ? err.response.data.message
        : 'Unable to export projects data as of the moment';
      dispatch({ type: 'EXPORTING_PROJECTS_FAILED', message, status: 'error' });
    }
  }

  if (!merchant) {
    return (
      <SingleForm
        title="Merchant does not exist"
        subtitle="The page you requested could not be found. Please go back to Dashboard or contact us at support@aqwire.io."
        footer={{ show: true, to: '/', text: 'Back to Dashboard' }}
      />
    );
  }

  if (!activeMerchant.canManageProjects) {
    return (
      <SingleForm
        title="Project management is disabled for this merchant"
        subtitle="Please go back to Dashboard or contact us at support@aqwire.io."
        footer={{ show: true, to: '/', text: 'Back to Dashboard' }}
      />
    );
  }

  const actionMenu = (
    <Menu>
      {merchantRole[0] === 10 && (
        <Menu.Item
          key="1"
          onClick={() => history.push(`/merchants/${merchantCode}/projects/new`, {})}
        >
          <Icon type="plus" />
          Add Project
        </Menu.Item>
      )}
      <Menu.Item key="2" onClick={() => exportProjects()} disabled={state.isExporting}>
        <Icon type="download" />
        Export as CSV
      </Menu.Item>
    </Menu>
  );

  return (
    <div>
      <Row className="breadcrumb-row ">
        <Col className="breadcrumb-header">
          <Breadcrumb className="breadcrumb-parent">
            <Breadcrumb.Item className="item-container">
              <div className="breadcrumb-item breadcrumb-icon">
                <TagIcon />
              </div>
              <div className="breadcrumb-item icon-label">
                Projects
              </div>
            </Breadcrumb.Item>
          </Breadcrumb>
          <div className="action-container">
            <Dropdown overlay={actionMenu} trigger={['click']}>
              <Button
                type="primary"
                className="button button-standard button-standard-outline button-small"
              >
                Actions
                <Icon type="down" />
              </Button>
            </Dropdown>
          </div>
        </Col>
      </Row>
      {state && state.status && state.message && (
        <Message
          style={{ marginBottom: '12px' }}
          status={state.status}
          message={state.message}
          onClose={() => dispatch({ type: 'CLEAR_MESSAGE' })}
        />
      )}
      <QueryBasedProjectsTable
        merchant={merchant}
        onChangeSearchOption={(searchOption) => {
          const url = getFilteredURLPath(location.pathname, searchOption);
          dispatch({ type: 'UPDATE_SEARCH_OPTION', searchOption });
          history.push(url, {});
        }}
        passInitialState={(searchOption, totalCount) => {
          const updatedOpts = {
            page: searchOption.page,
            size: searchOption.size,
            totalCount,
            query: searchOption.query,
          };
          dispatch({ type: 'UPDATE_SEARCH_OPTION', searchOption: updatedOpts });
        }}
      />
    </div>
  );
};

Projects.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      merchantCode: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }).isRequired,
  location: PropTypes.shape({
    pathname: PropTypes.string.isRequired,
    search: PropTypes.string.isRequired,
  }).isRequired,
  merchants: PropTypes.arrayOf(PropTypes.any).isRequired,
};

export default Projects;
