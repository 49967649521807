import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Layout } from 'antd';
import { Switch, Route, Redirect } from 'react-router-dom';
import MerchantSettings from '../screens/MerchantSettings';
import AccountSettings from '../screens/AccountSettings';
import PartnerDeveloperSettings from '../screens/PartnerDeveloperSettings';
import NotFound from '../screens/NotFound';
import { Navigation, MainNavbar, Banner } from '../components';
import { initializeBeacon } from '../services/beacon-widget';
import withTracker from './withTracker';

/**
 * Partner Layout component is used on enterprise clients under 'partner' merchant types
 */
function PartnerLayout(props) {
  const { user, appState } = props;
  const { firstName, lastName, email } = user;

  useEffect(() => {
    let ableToSet = true;
    if (ableToSet) {
      initializeBeacon({
        name: `${lastName}, ${firstName}`,
        email,
      });
    }

    return () => { ableToSet = false; };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const layoutProps = {
    user,
    appState,
  };
  const routes = [
    { path: '/', exact: true, component: PartnerDeveloperSettings },
    { path: '/account', exact: true, component: AccountSettings },
    { path: '/merchants/:merchantCode/settings', exact: true, component: MerchantSettings },
    { path: '/merchants/:merchantCode', exact: false, component: Redirect, props: { to: '/' } },
    { path: '/login', exact: false, component: Redirect, props: { to: '/' } },
    { path: '/signup', exact: false, component: Redirect, props: { to: '/' } },
    { path: '/forgot-password', exact: false, component: Redirect, props: { to: '/' } },
    { path: '/password-reset', exact: false, component: Redirect, props: { to: '/' } },
    { path: '*', exact: true, component: NotFound },
  ];

  return (
    <Layout className="main">
      <Banner />
      <Layout.Sider theme="light" width="250" className="sidebar">
        <Navigation />
      </Layout.Sider>
      <Layout className="main-layout">
        <MainNavbar
          onSearch={() => { }}
        />
        <Layout.Content className="main-layout-content">
          <Switch>
            {routes.map((ir) => {
              const Component = ir.component;
              const componentProps = ir.props || {};
              const TrackedComponent = withTracker(trackerProps => (
                <Component {...trackerProps} {...layoutProps} {...componentProps} />
              ));

              if (ir.exempt) {
                return (
                  <Route key={ir.path} exact={ir.exact} path={ir.path}>
                    <Component {...layoutProps} {...componentProps} />
                  </Route>
                );
              }

              return (
                <Route
                  key={ir.path}
                  exact={ir.exact}
                  path={ir.path}
                  component={TrackedComponent}
                />
              );
            })}
          </Switch>
        </Layout.Content>
      </Layout>
    </Layout>
  );
}

PartnerLayout.propTypes = {
  appState: PropTypes.number.isRequired,
  user: PropTypes.shape({
    id: PropTypes.number,
    email: PropTypes.string,
    firstName: PropTypes.string,
    lastName: PropTypes.string,
  }),
};

PartnerLayout.defaultProps = {
  user: null,
};

export default PartnerLayout;
