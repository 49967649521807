import React from 'react';
import { APP_STATES } from '../constants/index';

/**
 * @description
 * Maintains the state for the Enterprise Dashboard
 * Provider is used in the App
 * Consumer is used on the navigation, main navbar and active merchant selector components
 */
const SessionContext = React.createContext({
  isLoading: true,
  isAuthenticated: false,
  loggedInUser: null,
  activeMerchant: null,
  merchants: [],
  merchantCategories: [],
  roles: [],
  appState: APP_STATES.INIT,
  login: () => {},
  logout: () => {},
  setSelectedMerchant: () => {},
  showToast: () => {},
  pushMessage: () => {},
  popMessage: () => {},
});

export default SessionContext;
