import React, { useReducer, Fragment, useContext } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { Form, Button, Modal, Input, Row, Col, Icon, Alert } from 'antd';
import SessionContext from '../../contexts/SessionContext';
import { ROLES } from '../../constants';
import { createPaymentDispute } from '../../services/payments';
import '../../styles/modal.scss';

const initialState = {
  isModalVisible: false,
  isProcessingRequest: false,
  isSuccessModalVisible: false,
  successModalMessage: null,
  disputeId: null,
};

function reducer(prevState, action) {
  switch (action.type) {
    case 'CHANGE_DISPUTE_MODAL_VISIBILITY':
      return {
        ...prevState,
        isModalVisible: !prevState.isModalVisible,
      };

    case 'CHANGE_SUCCESS_MODAL_VISIBILITY':
      return {
        ...prevState,
        isSuccessModalVisible: !prevState.isSuccessModalVisible,
        successModalMessage: action.message,
        disputeId: action.disputeId,
      };

    case 'PROCESS_DISPUTE_REQUEST':
      return {
        ...prevState,
        isProcessingRequest: true,
      };

    case 'COMPLETE_DISPUTE_REQUEST':
      return {
        ...prevState,
        isProcessingRequest: false,
      };

    case 'DISPUTE_REQUEST_FAILED':
      return {
        ...prevState,
        isProcessingRequest: false,
        state: 'error',
        message: action.message,
      };

    default:
      return prevState;
  }
}

const DisputeModal = (props) => {
  const { form, payment } = props;
  const [state, dispatch] = useReducer(reducer, initialState);
  const { getFieldDecorator } = form;
  const { loggedInUser, showToast } = useContext(SessionContext);

  function disputePayment(e) {
    e.preventDefault();

    dispatch({ type: 'PROCESS_DISPUTE_REQUEST' });

    form.validateFields(async (err, { reason }) => {
      if (err) {
        dispatch({ type: 'COMPLETE_DISPUTE_REQUEST' });
        return;
      }

      try {
        const { data: { message, disputeId } } = await createPaymentDispute(payment.merchantCode, payment.paymentReferenceId, reason);
        dispatch({ type: 'CHANGE_DISPUTE_MODAL_VISIBILITY' });
        dispatch({ type: 'CHANGE_SUCCESS_MODAL_VISIBILITY', message, disputeId });
      } catch (error) {
        const message = error && error.response
          ? error.response.data.message
          : 'Unable to set payment status to disputed as of the moment';

        showToast({ type: 'error', message });
      }

      dispatch({ type: 'COMPLETE_DISPUTE_REQUEST' });
    });
  }

  if (loggedInUser.systemRole[0] > ROLES.SUPPORT || !['PAID', 'SETTLED'].includes(payment.paymentStatus)) {
    return <Fragment />;
  }

  return (
    <div>
      <Button
        className="button button-standard"
        onClick={() => dispatch({ type: 'CHANGE_DISPUTE_MODAL_VISIBILITY' })}
        style={{ marginRight: '8px' }}
      >
        Dispute
      </Button>
      <Modal
        title="Do you want to dispute this payment?"
        className="dialog-modal"
        visible={state.isModalVisible}
        onCancel={() => dispatch({ type: 'CHANGE_DISPUTE_MODAL_VISIBILITY' })}
        footer={[
          <Button
            key="cancel"
            onClick={() => dispatch({ type: 'CHANGE_DISPUTE_MODAL_VISIBILITY' })}
          >
            No
          </Button>,
          <Button
            key="send"
            type="primary"
            htmlType="submit"
            loading={state.isProcessingRequest}
            onClick={disputePayment}
          >
            Yes
          </Button>,
        ]}
      >
        {/* <Row>
          <Col span={24} style={{ textAlign: 'center' }}>
            <Icon type="info-circle" style={{ color: '#399DE5', fontSize: 48, paddingTop: '48px', paddingBottom: '48px' }} />
          </Col>
        </Row> */}
        <Row>
          <Col span={3}>
            <Icon type="info-circle" style={{ color: '#399DE5', fontSize: 30, paddingLeft: 10 }} />
          </Col>
          <Col span={21}>
            {/* <h3>Do you want to dispute this payment?</h3> */}
            <p>
              {`Disputing this payment will change the status of this payment from
              ${payment.paymentStatus}
              to DISPUTED and will create a dispute entry. Disputed entries will then be verified and will be updated to either WON or LOST.`}
            </p>
          </Col>
        </Row>
        {state.status && state.message && (
          <Alert
            style={{ marginBottom: '12px' }}
            type={state.status}
            description={state.message}
            closable
            onClose={() => dispatch({ type: 'CLEAR_MESSAGE' })}
          />
        )}
        <Form onSubmit={disputePayment}>
          <Form.Item label="Reason for Dispute">
            {getFieldDecorator('reason', {
              rules: [{ required: true, message: 'Please enter a dispute reason' }],
            })(<Input.TextArea />)}
          </Form.Item>
        </Form>
      </Modal>
      <Modal
        title="Payment Disputed"
        className="dialog-modal"
        visible={state.isSuccessModalVisible}
        onCancel={() => dispatch({ type: 'CHANGE_SUCCESS_MODAL_VISIBILITY' })}
        footer={null}
      >
        {/* <Row>
          <Col span={24} style={{ textAlign: 'center' }}>
            <Icon type="check-circle" style={{ color: '#96D647', fontSize: 48, paddingTop: '48px', paddingBottom: '48px' }} />
          </Col>
        </Row> */}
        <Row>
          <Col span={3}>
            <Icon type="check-circle" style={{ color: '#96D647', fontSize: 30, paddingLeft: 10 }} />
          </Col>
          <Col span={21}>
            <p>{state.successModalMessage}</p>
          </Col>
        </Row>
        <Row>
          <Col span={6}>
            <Button type="primary" style={{ textAlign: 'center' }}>
              <Link to={`/disputes/${state.disputeId}`}>
                View Dispute
              </Link>
            </Button>
          </Col>
        </Row>
      </Modal>
    </div>
  );
};

DisputeModal.propTypes = {
  form: PropTypes.shape({
    getFieldDecorator: PropTypes.func.isRequired,
    validateFields: PropTypes.func.isRequired,
  }).isRequired,
  payment: PropTypes.shape({
    invoiceId: PropTypes.string.isRequired,
    merchantCode: PropTypes.string.isRequired,
    paymentReferenceId: PropTypes.string,
    paymentStatus: PropTypes.string.isRequired,
    customerEmail: PropTypes.string.isRequired,
  }).isRequired,
};

export default Form.create()(DisputeModal);
