import { Moment } from 'moment'; // eslint-disable-line no-unused-vars
import api from '../helpers/apiClient';

export function getTotalPaymentsByType(merchant) {
  return api.get('/reports/total-payments-by-type', {
    params: {
      merchant: merchant.code,
    },
  });
}

export function getRevenueSummaryReports(merchantId, { startDate, endDate }) {
  return api.get(`/reports/${merchantId}/revenue`, {
    params: {
      startday: startDate.isValid() ? startDate.format('YYYY-MM-DD') : null,
      endday: endDate.isValid() ? endDate.format('YYYY-MM-DD') : null,
    },
  });
}

export function getPaymentReports(merchantId, searchOption) {
  const { startDate, endDate } = searchOption;
  return api.get(`/reports/${merchantId}/payments`, {
    params: {
      startday: startDate.isValid() ? startDate.format('YYYY-MM-DD') : undefined,
      endday: endDate.isValid() ? endDate.format('YYYY-MM-DD') : undefined,
    },
  });
}

/**
 * @param {{ id: Number, code: String }} merchant
 * @param {{ startDate: Moment, endDate: Moment }} options
 */
export function getPaymentByProjectPaymentTypeReport(merchantId, options) {
  const { startDate, endDate } = options;
  return api.get(`/reports/${merchantId}/payments-by-project-payment-type`, {
    params: {
      startday: startDate.isValid() ? startDate.format('YYYY-MM-DD') : undefined,
      endday: endDate.isValid() ? endDate.format('YYYY-MM-DD') : undefined,
    },
  });
}

/**
 * @param {{ id: Number, code: String }} merchant
 * @param {{ startDate: Moment, endDate: Moment }} options
 */
export function getSummaryPaymentByProjectPaymentTypeReport(merchant, options) {
  const { startDate, endDate } = options;
  return api.get('/reports/summary-payments-by-project-payment-type', {
    params: {
      merchant: merchant ? merchant.code : undefined,
      startday: startDate.isValid() ? startDate.format('YYYY-MM-DD') : undefined,
      endday: endDate.isValid() ? endDate.format('YYYY-MM-DD') : undefined,
    },
  });
}

/**
 * @param {{ id: Number, code: String }} merchant
 * @param {{ startDate: Moment, endDate: Moment }} options
 */
export function getPaymentMethodIssuersReport(merchantId, options) {
  const { startDate, endDate } = options;
  return api.get(`/reports/${merchantId}/payment-method-issuers`, {
    params: {
      startday: startDate.isValid() ? startDate.format('YYYY-MM-DD') : undefined,
      endday: endDate.isValid() ? endDate.format('YYYY-MM-DD') : undefined,
    },
  });
}

export function getPaymentMethodIssuersReportAdmin(merchant, options) {
  const { startDate, endDate } = options;
  return api.get('/reports/payment-method-issuers-admin', {
    params: {
      merchant: merchant ? merchant.code : undefined,
      startday: startDate.isValid() ? startDate.format('YYYY-MM-DD') : undefined,
      endday: endDate.isValid() ? endDate.format('YYYY-MM-DD') : undefined,
    },
  });
}
