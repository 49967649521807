import moment from 'moment';
import React, { useContext, useReducer, useEffect } from 'react';
import qs from 'query-string';
import { useRouteMatch, useLocation, useHistory } from 'react-router-dom';
import { Breadcrumb, Col, Row, Icon, Table, Input } from 'antd';
import SessionContext from '../../contexts/SessionContext';
import SettlementsFilterModal from '../../components/SettlementsFilterModal';
import SingleForm from '../../components/SingleForm';
import Message from '../../components/Message';
import { SettlementReportsIconBig } from '../../components/Icons';
import { getSettlementsService } from '../../services/settlements';
import { getAPIErrorMessage } from '../../helpers/utils';
import '../../styles/transaction.css';

const dateFormat = 'YYYY-MM-DD';
const displayDateFormat = 'MMM. DD, YYYY';

function getFilteredURLPath(path, searchOption) {
  const { page, size, settledAt, query } = searchOption;
  let url = `${path}?page=${page || 1}`;
  if (size) { url += `&size=${size}`; }
  if (settledAt && settledAt.isValid()) { url += `&settled=${settledAt.format('YYYY-MM-DD')}`; }
  if (query) { url += `&query=${query}`; }
  return url;
}

function getIntiialState(queryFilters) {
  return {
    isFetchingSettlements: true,
    settlements: [],
    totalCount: 0,
    status: null,
    message: null,
    searchOption: {
      page: parseInt(queryFilters.page, 10) || 1,
      size: parseInt(queryFilters.size, 10) || 20,
      totalCount: 0,
      settledAt: moment(queryFilters.settled || null, dateFormat),
      query: queryFilters.query || null,
    },
  };
}

function SettlementReports() {
  const match = useRouteMatch();
  const location = useLocation();
  const history = useHistory();
  const sessionContext = useContext(SessionContext);
  const merchant = sessionContext.merchants.find(m => m.code === match.params.merchantCode);

  const queryFilters = qs.parse(location.search, { ignoreQueryPrefix: true });
  const [state, dispatch] = useReducer((prevState, action) => {
    switch (action.type) {
      case 'UPDATE_SEARCH_OPTION':
        return {
          ...prevState,
          searchOption: action.searchOption,
          status: null,
          message: null,
        };
      case 'GET_SETTLEMENTS':
        return {
          ...prevState,
          isFetchingSettlements: true,
        };
      case 'GET_SETTLEMENTS_SUCCESSFUL':
        return {
          ...prevState,
          isFetchingSettlements: false,
          settlements: action.settlements,
          totalCount: action.totalCount,
          status: 'success',
        };
      case 'GET_SETTLEMENTS_FAILED':
        return {
          ...prevState,
          isFetchingSettlements: false,
          settlements: [],
          status: 'error',
          message: action.message,
        };
      default:
        return prevState;
    }
  }, getIntiialState(queryFilters));

  useEffect(() => {
    let ableToSet = true;

    (async () => {
      try {
        if (!merchant || !merchant.id) {
          return;
        }

        if (ableToSet) {
          dispatch({ type: 'GET_SETTLEMENTS' });
        }

        const { data } = await getSettlementsService(merchant.id, state.searchOption);
        const { settlements, totalCount } = data;
        if (ableToSet) {
          dispatch({ type: 'GET_SETTLEMENTS_SUCCESSFUL', settlements, totalCount });
        }
      } catch (err) {
        const message = getAPIErrorMessage(err,
          'We are not able to get the settlement reports. Please try again later');
        dispatch({ type: 'GET_SETTLEMENTS_FAILED', message });
      }
    })();
    return () => { ableToSet = false; };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    merchant,
    state.searchOption.settledAt,
    state.searchOption.query,
    state.searchOption.page,
    state.searchOption.size,
  ]);

  function onChangeSearchOption(opts) {
    const url = getFilteredURLPath(location.pathname, opts);
    history.push(url, {});
    dispatch({
      type: 'UPDATE_SEARCH_OPTION',
      searchOption: opts,
    });
  }

  function onApplyFilters(opts) {
    // I just need to convert the IDs back to string because
    // the prop types for PaymentsFilterModal are expecting it as string.
    onChangeSearchOption({
      ...state.searchOption,
      page: 1,
      merchant: opts.merchant,
      settledAt: opts.settledAt,
    });
  }

  function resetFiltersToDefault() {
    onChangeSearchOption({
      ...state.searchOption,
      query: null,
      merchant: null,
      settledAt: null,
    });
  }

  function onSearchByQuery(query) {
    if (!query) {
      resetFiltersToDefault();
      return;
    }

    onChangeSearchOption({
      ...state.searchOption,
      query,
      page: 1,
      merchant: null,
      settledAt: moment(null, dateFormat),
    });
  }

  const columns = [
    {
      title: 'QW ID',
      key: 'referenceId',
      render: record => (
        <div className="table-col-item">
          <strong>
            {record.settlementReferenceId}
          </strong>
        </div>
      ),
    }, {
      title: 'File',
      key: 'settlementReport',
      render: row => (
        <div className="text-primary">
          <Icon type="file" style={{ marginRight: '8px' }} />
          <span>{row.fileName}</span>
        </div>
      ),
    }, {
      title: 'Total Amount',
      key: 'payments',
      render: record => (
        <div className="text-primary">
          {record.totalSettlementAmount
            ? `${record.totalSettlementAmount[0]} ${record.totalSettlementAmount[1].toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ',')}`
            : '-----'}
          {record.totalPaymentCount === 1 ? ' (1 payment)' : ` (${record.totalPaymentCount} payments)`}
        </div>
      ),
    }, {
      title: 'Settled Date',
      key: 'settledDate',
      render: record => (
        <div className="text-primary">
          {record.settledDate ? moment(record.settledDate, 'YYYY-MM-DD').format('LL') : '----'}
        </div>
      ),
    }, {
      title: 'Created Date',
      key: 'createdAt',
      render: record => (
        <div className="text-primary">
          {record.createdAt ? moment(record.createdAt).format('LL') : '----'}
        </div>
      ),
    },
  ];

  const pagination = {
    current: state.searchOption.page,
    pageSize: state.searchOption.size,
    total: state.totalCount,
    size: 'small',
    onChange: (p) => {
      const totalPage = Math.ceil(state.totalCount / state.searchOption.size);
      let page = p || 1;
      if (page < 1) { page = 1; }
      if (page > totalPage) { page = totalPage - 1; }

      dispatch({
        type: 'UPDATE_SEARCH_OPTION',
        searchOption: {
          ...state.searchOption,
          page,
        },
      });
    },
    showTotal: (total, range) => `Showing ${range[0]} - ${range[1]} of ${total}`,
  };

  const { searchOption } = state;

  if (!merchant) {
    return (
      <SingleForm
        title="Merchant does not exist"
        subtitle="The page you requested could not be found. Please go back to Dashboard or contact us at support@aqwire.io."
        footer={{ show: true, to: '/', text: 'Back to Dashboard' }}
      />
    );
  }

  return (
    <div>
      <Row className="breadcrumb-row">
        <Col className="breadcrumb-header">
          <Breadcrumb className="breadcrumb-parent">
            <Breadcrumb.Item className="item-container">
              <div className="breadcrumb-item breadcrumb-icon">
                <SettlementReportsIconBig />
              </div>
              <div className="breadcrumb-item icon-label">Settlement Reports</div>
            </Breadcrumb.Item>
          </Breadcrumb>
        </Col>
      </Row>
      <Row
        type="flex"
        justify="space-between"
        align="middle"
        className="filters"
        style={{ marginBottom: '12px' }}
      >
        <Col lg={12}>
          {(() => {
            const { query, settledAt } = searchOption;
            if (query) {
              return (
                <h4 className="text-secondary">
                  {`Showing settlements for "${query}"`}
                </h4>
              );
            }
            if (settledAt && settledAt.isValid()) {
              return (
                <h4 className="text-secondary">
                  {`Showing settlements on ${settledAt.format(displayDateFormat)}`}
                </h4>
              );
            }
            return (
              <h4 className="text-secondary">
                Showing all settlements
              </h4>
            );
          })()}
        </Col>
        <Col lg={12}>
          <Row type="flex" justify="end">
            <Col style={{ marginRight: '16px' }}>
              {!searchOption.query && (
                // Hide the payments filter modal if the search query is available.
                // It will rerender and reset to default upon clear.
                <SettlementsFilterModal
                  merchant={merchant}
                  onApply={onApplyFilters}
                  searchOption={searchOption}
                />
              )}
            </Col>
            <Col lg={12}>
              <Input.Search
                allowClear
                placeholder="Search by ID"
                style={{ width: '100%' }}
                defaultValue={state.searchOption.query}
                onChange={(e) => {
                  if (!e.target.value) { resetFiltersToDefault(); }
                }}
                onSearch={onSearchByQuery}
              />
            </Col>
          </Row>
        </Col>
      </Row>
      {state && state.status && state.message && (
        <div style={{ marginBottom: '12px' }}>
          <Row>
            <Col span={24}>
              <Message
                status={state.status}
                message={state.message}
                onClose={() => dispatch({ type: 'CLEAR_MESSAGE' })}
              />
            </Col>
          </Row>
        </div>
      )}
      <Table
        style={{ marginTop: '20px', marginBottom: '32px' }}
        className="table-standard settlements-table"
        dataSource={state.settlements}
        columns={columns}
        rowKey="settlementId"
        loading={state.isFetchingSettlements}
        pagination={pagination}
        rowClassName="row-clickable"
        onRow={(row) => {
          const onClick = () => history.push(`/merchants/${merchant.code}/settlements/${row.settlementReferenceId}`);
          return { onClick };
        }}
      />
    </div>
  );
}

SettlementReports.propTypes = {
};

export default SettlementReports;
