import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { Link, withRouter } from 'react-router-dom';
import { Layout, Dropdown, Menu, Icon } from 'antd';
import SessionContext from '../../contexts/SessionContext';

const QwikwireMainNavbar = (props) => {
  const { history } = props;
  const { loggedInUser, logout } = useContext(SessionContext);

  if (!loggedInUser) { return null; }
  const dropdownHeader = loggedInUser && loggedInUser.id
    ? (
      <div className="ant-dropdown-link header-dropdown-text">
        <div className="header-dropdown-primary">My Account</div>
        <Icon type="down" />
      </div>
    )
    : <div />;

  return (
    <Layout.Header className="main-layout-header">
      <div />
      <div className="header-dropdown">
        <Dropdown
          trigger={['click']}
          overlay={(
            <Menu
              className="header-dropdown-menu"
              onClick={(e) => {
                switch (e.key) {
                  case 'logout':
                    history.replace('/login');
                    logout();
                    break;
                  default: break;
                }
              }}
            >
              <Menu.Item key="accountsettings">
                <Link to="/account">
                  <span>Account Settings</span>
                </Link>
              </Menu.Item>
              <Menu.Item key="logout">
                <span>Logout</span>
              </Menu.Item>
            </Menu>
          )}
        >
          {dropdownHeader}
        </Dropdown>
      </div>
    </Layout.Header>
  );
};

QwikwireMainNavbar.propTypes = {
  history: PropTypes.shape({
    replace: PropTypes.func.isRequired,
  }).isRequired,
};

QwikwireMainNavbar.defaultProps = {
};

export default withRouter(QwikwireMainNavbar);
