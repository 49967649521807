import moment from 'moment';
import React, { useReducer, useEffect, useState, useContext } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { Col, Row, Breadcrumb, Alert, Button } from 'antd';
import EnrollmentLogs from '../../components/EnrollmentLogs';
import EnrollmentActions from '../../components/EnrollmentActions';
import EnrollmentInvoices from '../../components/EnrollmentInvoices';
import PaymentMethodDetails from '../../components/PaymentMethodDetails';
import SessionContext from '../../contexts/SessionContext';
import { EnrollmentIcon, FolderIcon, CopyIcon } from '../../components/Icons';
import { getEnrollmentByTransactionId } from '../../services/enrollments';
import { formatNumber, getStatusColor, getAPIErrorMessage, parseCustomValue } from '../../helpers/utils';
import '../../styles/panel.css';


const initialState = {
  isFetchingEnrollment: true,
  enrollment: null,
};

function reducer(prevState, action) {
  switch (action.type) {
    case 'GET_ENROLLMENT':
      return {
        ...prevState,
        isFetchingEnrollment: true,
        enrollment: null,
      };

    case 'GET_ENROLLMENT_SUCCESSFUL':
      return {
        ...prevState,
        isFetchingEnrollment: false,
        enrollment: action.enrollment,
      };

    case 'GET_ENROLLMENT_FAILED':
      return {
        ...prevState,
        isFetchingEnrollment: false,
        enrollment: null,
      };

    default:
      return prevState;
  }
}

function getFilteredURLPath(path, searchOption) {
  const { page, size } = searchOption;
  let url = `${path}?page=${page || 1}`;
  if (size) { url += `&size=${size}`; }
  return url;
}

const QwikwireEnrollment = (props) => {
  const { match, user, location, history } = props;
  const { transactionId } = match.params;
  const sessionContext = useContext(SessionContext);
  const { pushMessage, popMessage } = sessionContext;
  const [state, dispatch] = useReducer(reducer, initialState);
  const [msg, setMsg] = useState(popMessage());

  useEffect(() => {
    let ableToSet = true;
    (async () => {
      if (ableToSet) {
        dispatch({ type: 'GET_ENROLLMENT' });
      }

      try {
        const { data: enrollment } = await getEnrollmentByTransactionId(transactionId);
        if (ableToSet) {
          dispatch({ type: 'GET_ENROLLMENT_SUCCESSFUL', enrollment });
        }
      } catch (error) {
        const message = getAPIErrorMessage(error,
          'We are not able to get the payment data from the server.');
        if (ableToSet) {
          dispatch({ type: 'GET_ENROLLMENT_FAILED', message });
        }
      }
    })();
    return () => { ableToSet = false; };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (state.isFetchingEnrollment) {
    return (
      <div>
        <div className="spinner">
          <span className="spinner-text">Loading...</span>
        </div>
      </div>
    );
  }

  if (!state.enrollment) {
    // eslint-disable-next-line react/jsx-one-expression-per-line
    const message = <p>You can contact us at <a href="mailto:support@aqwire.io">support@aqwire.io</a> if you have concerns or issues.</p>;
    return (
      <div>
        <div className="spinner">
          <FolderIcon />
          <h1>Enrollment Not Found</h1>
          {message}
        </div>
      </div>
    );
  }

  const { enrollment } = state;
  return (
    <div className="qwikwire">
      <section className="transaction-breadcrumb">
        <div className="transaction-breadcrumb-icon">
          <EnrollmentIcon />
        </div>
        <Breadcrumb>
          <Breadcrumb.Item>
            <Link to="/enrollments">All Enrollments</Link>
          </Breadcrumb.Item>
          <Breadcrumb.Item>
            {`#${enrollment.transactionId}`}
          </Breadcrumb.Item>
        </Breadcrumb>
      </section>
      {msg && msg.status && msg.message && (
        <div style={{ marginBottom: '8px' }}>
          <Row>
            <Col span={24}>
              <Alert
                message={<p style={{ marginBottom: 0 }}>{msg.message}</p>}
                type={['error', 'success'].includes(msg.status) ? msg.status : 'info'}
                showIcon
                closable
                onClose={() => setMsg(null)}
              />
            </Col>
          </Row>
        </div>
      )}
      <div style={{ margin: '12px 4px 32px' }}>
        <section className="panel panel-standard">
          <div className="panel-header">
            <Row>
              <Col lg={6} className="panel-item">
                <h3>QW ID</h3>
                <div>{enrollment.enrollmentReferenceId}</div>
              </Col>
              <Col lg={6} className="panel-item">
                <h3>{enrollment.customerName}</h3>
                <div>{enrollment.customerEmail}</div>
                {enrollment.customerPhone && <div>{` (${enrollment.customerPhone})`}</div>}
              </Col>
              <Col lg={6} className="panel-item">
                <h3>Enrollment Date</h3>
                <div>{moment(enrollment.createdAt).format('MMM. DD, YYYY HH:mm')}</div>
              </Col>
              <Col lg={6} className="panel-item panel-item-end">
                <div
                  className="panel-badge"
                  style={{
                    color: '#ffffff',
                    backgroundColor: getStatusColor(
                      enrollment.isArchived ? 'ARCHIVED' : enrollment.transactionStatus,
                    ),
                  }}
                >
                  {enrollment.isArchived ? 'ARCHIVED' : enrollment.transactionStatus}
                </div>
              </Col>
            </Row>
          </div>
          <div className="panel-body">
            <Row>
              <Col lg={12}>
                <div className="panel-item">
                  <h3>Transaction ID</h3>
                  <div>
                    {enrollment.externalTransactionId}
                  </div>
                </div>
                <div className="panel-item">
                  <h3>{`${enrollment.paymentMethodProcessor === 'BT' ? 'International Enrollment (USD)' : 'Enrollment'} Payment`}</h3>
                  <div>{`${enrollment.paymentTypeName || 'N/A'}`}</div>
                </div>
                {enrollment.transactionStatus === 'INC' && enrollment.enrolleeLink && (
                  <div className="panel-item">
                    <h3>Enrollment link</h3>
                    <div className="payment-link">
                      <a href={enrollment ? enrollment.enrolleeLink : ''} target="_blank" rel="noopener noreferrer">
                        <div className="truncate">{enrollment.enrolleeLink}</div>
                      </a>
                      <Button
                        className="copy-button"
                        type="link"
                        onClick={() => navigator.clipboard.writeText(enrollment.enrolleeLink)}
                      >
                        <CopyIcon />
                      </Button>
                    </div>
                  </div>
                )}
                <div className="panel-item">
                  <h3>{enrollment.merchantName}</h3>
                  <div style={{ marginBottom: '8px' }}>
                    <div>{enrollment.projectName}</div>
                    {enrollment.projectCategory && <div>{enrollment.projectCategory}</div>}
                  </div>
                  {enrollment.customFields && (
                    <div className="panel-item-cellar">
                      {Object.keys(enrollment.customFields)
                        .map(key => (
                          <div key={key}>
                            {`${enrollment.customFields[key].label}: ${enrollment.customFields[key].value ? parseCustomValue(enrollment.customFields[key].value) : ''}`}
                          </div>
                        ))}
                    </div>
                  )}
                </div>
                <div className="panel-item">
                  <h3>Notes</h3>
                  <div>{enrollment.clientNotes || 'There are no notes'}</div>
                </div>
              </Col>
              <Col lg={12}>
                <div style={{ marginBottom: '12px' }}>
                  <h3>Enrollment Details</h3>
                  <Row>
                    <Col lg={12} className="panel-item">
                      <div>Total Contract Amount</div>
                      <div>{`${enrollment.baseAmount[0]} ${formatNumber(enrollment.baseAmount[1])}`}</div>
                    </Col>
                    <Col lg={12} className="panel-item">
                      <div>Number of Months</div>
                      <div>{`${enrollment.enrollmentMonths} months`}</div>
                    </Col>
                  </Row>
                  <Row>
                    <Col lg={12} className="panel-item">
                      <div>Start Date</div>
                      <div>
                        {((val) => {
                          const datetime = moment(val);
                          if (!datetime.isValid()) { return '--- --, ----'; }
                          return datetime.format('LL');
                        })(enrollment.enrollmentStartDate)}
                      </div>
                    </Col>
                    <Col lg={12} className="panel-item">
                      <div>End Date</div>
                      <div>
                        {((val) => {
                          const datetime = moment(val);
                          if (!datetime.isValid()) { return '--- --, ----'; }
                          return datetime.format('LL');
                        })(enrollment.enrollmentEndDate)}
                      </div>
                    </Col>
                  </Row>
                </div>
                {(enrollment.enrollmentComment
                  && ['DECLINED', 'CANCELLED'].indexOf(enrollment.transactionStatus) >= 0)
                  && (
                    <div style={{ marginTop: '20px' }}>
                      <h3 className="card-box-header card-box-header-flex">
                        <span>
                          {((status) => {
                            switch (status) {
                              case 'DECLINED': return 'Declined Reason';
                              case 'CANCELLED': return 'Reason for Cancellation';
                              default: return 'Comment';
                            }
                          })(enrollment.transactionStatus)}
                        </span>
                        <span className="text-secondary">
                          {moment(enrollment.updatedAt).format('LLL')}
                        </span>
                      </h3>
                      <div style={{
                        border: '1px solid #e0e0e0',
                        background: '#F9FCFF',
                        padding: '15px',
                        marginTop: '8px',
                      }}
                      >
                        <div className="text-pre-line">
                          {enrollment.enrollmentComment}
                        </div>
                      </div>
                    </div>
                  )}
              </Col>
            </Row>
          </div>
          <EnrollmentActions
            status={enrollment.transactionStatus}
            enrollmentReferenceId={enrollment.enrollmentReferenceId}
            enrollmentTransactionId={enrollment.externalTransactionId}
            transactionId={enrollment.transactionId}
            merchantCode={enrollment.merchantCode}
            isArchived={enrollment.isArchived}
            scheduleFlag={enrollment?.customFields?.scheduleFlag?.value}
          />
        </section>
      </div>
      <div style={{ margin: '12px 4px 32px' }}>
        <section className="panel panel-standard">
          <div className="panel-header">
            <h3>Payment Methods</h3>
          </div>
          <div className="panel-body">
            <Row>
              <Col lg={10}>
                <PaymentMethodDetails payment={enrollment} />
              </Col>
            </Row>
          </div>
        </section>
      </div>
      <div style={{ margin: '12px 4px 32px' }}>
        <EnrollmentInvoices
          merchantId={enrollment.merchantId}
          merchantCode={enrollment.merchantCode}
          enrollmentReferenceId={enrollment.enrollmentReferenceId}
          externalTransactionId={enrollment.externalTransactionId}
          layout="Qwikwire"
          onChangeSearchFunction={(searchOption, data) => {
            if (data && data.status && data.message) {
              pushMessage({ component: 'QwikwireEnrollment', status: data.status, message: data.message });
            }

            const url = getFilteredURLPath(location.pathname, searchOption);
            history.push(url, {});
          }}
        />
      </div>
      <div style={{ margin: '12px 4px 32px' }}>
        <EnrollmentLogs
          enrollment={enrollment}
          user={user}
          onChangeSearchOption={(searchOption) => {
            const url = getFilteredURLPath(location.pathname, searchOption);
            history.push(url, {});
          }}
        />
      </div>
    </div>
  );
};

QwikwireEnrollment.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }).isRequired,
  match: PropTypes.shape({
    params: PropTypes.shape({
      transactionId: PropTypes.string.isRequired,
    }),
  }).isRequired,
  location: PropTypes.shape({
    filter: PropTypes.shape({}),
    pathname: PropTypes.string.isRequired,
  }),
  user: PropTypes.shape({
    id: PropTypes.number.isRequired,
    systemRole: PropTypes.arrayOf(PropTypes.any).isRequired,
  }).isRequired,
};

QwikwireEnrollment.defaultProps = {
  location: null,
};

export default QwikwireEnrollment;
