import React, { useContext, useEffect, useReducer } from 'react';
import PropTypes from 'prop-types';
import { Row, Col, Button, Modal, Icon } from 'antd';
import PaymentMethodCard from '../PaymentMethodCard';
import CardIcon from '../../assets/credit-card-icon.svg';
import ACHIcon from '../../assets/ach-icon.svg';
import PayPalIcon from '../../assets/paypal-icon.svg';
import EWalletIcon from '../../assets/ewallet-icon.svg';
import DirectDebitIcon from '../../assets/direct-debit-icon.svg';
import MayaIcon from '../../assets/maya-icon.svg';
import SessionContext from '../../contexts/SessionContext';
import {
  getMerchantPaymentMethodsService, activateMerchantPaymentMethodsService,
  disableMerchantPaymentMethodsService,
} from '../../services/merchants';
import { getAPIErrorMessage } from '../../helpers/utils';
import '../../styles/transaction.css';
import './merchantpaymentmethods.scss';

const initialState = {
  isFetchingPaymentMethods: false,
  isSettingPaymentMethod: false,
  isActivatePaymentModalVisible: false,
  isDisablePaymentModalVisible: false,
  selectedPaymentMethodId: null,
  paymentMethods: [],
};

function reducer(prevState, action) {
  switch (action.type) {
    case 'GET_PAYMENT_METHODS':
      return {
        ...prevState,
        isFetchingPaymentMethods: true,
      };
    case 'GET_PAYMENT_METHODS_SUCCESS':
      return {
        ...prevState,
        isFetchingPaymentMethods: false,
        paymentMethods: action.paymentMethods,
      };
    case 'GET_PAYMENT_METHODS_FAILED':
      return {
        ...prevState,
        isFetchingPaymentMethods: false,
        message: action.message,
        status: 'error',
      };
    case 'SET_PAYMENT_METHOD_STATUS':
      return {
        ...prevState,
        isSettingPaymentMethod: true,
      };
    case 'SET_PAYMENT_METHOD_STATUS_SUCCESS':
      return {
        ...prevState,
        isSettingPaymentMethod: false,
      };
    case 'SET_PAYMENT_METHOD_STATUS_FAILED':
      return {
        ...prevState,
        isSettingPaymentMethod: false,
        message: action.message,
        status: 'error',
      };
    case 'SHOW_ACTIVATE_MODAL':
      return {
        ...prevState,
        isActivatePaymentModalVisible: true,
        selectedPaymentMethodId: action.selectedPaymentMethodId,
      };
    case 'SHOW_DISABLE_MODAL':
      return {
        ...prevState,
        isDisablePaymentModalVisible: true,
        selectedPaymentMethodId: action.selectedPaymentMethodId,
      };
    case 'HIDE_MODAL':
      return {
        ...prevState,
        isActivatePaymentModalVisible: false,
        isDisablePaymentModalVisible: false,
      };
    default:
      return prevState;
  }
}

const MerchantPaymentMethods = (props) => {
  const { merchant } = props;
  const { loggedInUser, showToast } = useContext(SessionContext);
  const [state, dispatch] = useReducer(reducer, initialState);

  useEffect(() => {
    let ableToSet = true;

    (async () => {
      try {
        if (ableToSet) {
          dispatch({ type: 'GET_PAYMENT_METHODS' });
        }
        const { data } = await getMerchantPaymentMethodsService(merchant.id);
        if (ableToSet) {
          // const arrangePaymentMethods = arr => _.chain(arr).groupBy('code').map((
          //   value, key,
          // ) => ({ code: key, paymentMethodDetails: value })).value();
          dispatch({
            type: 'GET_PAYMENT_METHODS_SUCCESS',
            paymentMethods: data,
          });
        }
      } catch (error) {
        const message = getAPIErrorMessage(error,
          'We are not able to get the payment methods. Please try again later.');

        if (ableToSet) {
          showToast({ type: 'error', message });
          dispatch({
            type: 'GET_PAYMENT_METHODS_FAILED',
          });
        }
      }
    })();

    return () => { ableToSet = true; };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  async function activatePaymentMethod(paymentMethodId) {
    dispatch({ type: 'SET_PAYMENT_METHOD_STATUS' });
    try {
      await activateMerchantPaymentMethodsService(merchant.id, paymentMethodId);
      dispatch({ type: 'SET_PAYMENT_METHOD_STATUS_SUCCESS' });
      setTimeout(() => window.location.reload(), 500);
    } catch (error) {
      const message = getAPIErrorMessage(error,
        'We are not able to activate this payment method. Please try again later');
      dispatch({ type: 'SET_PAYMENT_METHOD_STATUS_FAILED', message });
      showToast({ message, type: 'error' });
    }
  }

  async function disablePaymentMethod(paymentMethodId) {
    dispatch({ type: 'SET_PAYMENT_METHOD_STATUS' });
    try {
      await disableMerchantPaymentMethodsService(merchant.id, paymentMethodId);
      dispatch({ type: 'SET_PAYMENT_METHOD_STATUS_SUCCESS' });
      setTimeout(() => window.location.reload(), 500);
    } catch (error) {
      const message = getAPIErrorMessage(error,
        'We are not able to disable this payment method. Please try again later');
      dispatch({ type: 'SET_PAYMENT_METHOD_STATUS_FAILED', message });
      showToast({ message, type: 'error' });
    }
  }

  function openActivateModal(id) {
    dispatch({ type: 'SHOW_ACTIVATE_MODAL', selectedPaymentMethodId: id });
  }

  function openDisableModal(id) {
    dispatch({ type: 'SHOW_DISABLE_MODAL', selectedPaymentMethodId: id });
  }

  const { selectedPaymentMethodId } = state;

  if (!loggedInUser) { return <div />; }

  return (
    <section>
      <PaymentMethodCard
        code="card"
        paymentMethods={state.paymentMethods.filter(p => p.code === 'card')}
        methodType="Card"
        title="Credit/Debit Card"
        description="Accepts payments from credit card providers Visa, Mastercard, American Express, Discover, Diners Club and JCB."
        logo={CardIcon}
        showActions={false}
      />
      <PaymentMethodCard
        code="ach"
        paymentMethods={state.paymentMethods.filter(p => p.code === 'ach')}
        methodType="Debit"
        title="ACH Debit"
        description="Direct bank transfer for US Customers. Takes up to 2-3 days for confirmation."
        logo={ACHIcon}
        onActivate={openActivateModal}
        onDisable={openDisableModal}
      />
      <PaymentMethodCard
        code="paypal"
        paymentMethods={state.paymentMethods.filter(p => p.code === 'paypal')}
        methodType="Debit"
        title="PayPal"
        description="Accepts payments from PayPal payers."
        logo={PayPalIcon}
        onActivate={openActivateModal}
        onDisable={openDisableModal}
      />
      <PaymentMethodCard
        code="ewallet"
        paymentMethods={state.paymentMethods.filter(p => p.code === 'ewallet')}
        methodType="Wallet"
        title="E-Wallet"
        description="Accept payments from e-wallet users."
        logo={EWalletIcon}
        onActivate={openActivateModal}
        onDisable={openDisableModal}
      />
      <PaymentMethodCard
        code="directDebit"
        paymentMethods={state.paymentMethods.filter(p => p.code === 'directDebit')}
        methodType="Debit"
        title="Online Banking"
        description="Direct bank transfer for Philippine Customers."
        logo={DirectDebitIcon}
        onActivate={openActivateModal}
        onDisable={openDisableModal}
      />
      <PaymentMethodCard
        code="maya"
        paymentMethods={state.paymentMethods.filter(p => p.code === 'maya')}
        methodType="Wallet"
        title="Maya Philippines"
        description="Accept payments from Maya Philippines users."
        logo={MayaIcon}
        onActivate={openActivateModal}
        onDisable={openDisableModal}
      />
      <Modal
        title="Activate Payment Method"
        className="dialog-modal payment-method-modal"
        visible={state.isActivatePaymentModalVisible}
        onCancel={() => dispatch({ type: 'HIDE_MODAL' })}
        footer={[
          <Button
            key="cancel"
            onClick={() => dispatch({ type: 'HIDE_MODAL' })}
          >
            Cancel
          </Button>,
          <Button
            key="submit"
            type="primary"
            htmlType="submit"
            loading={state.isSettingPaymentMethod}
            onClick={() => activatePaymentMethod(selectedPaymentMethodId)}
          >
            Activate
          </Button>,
        ]}
      >
        <Row>
          <Col lg={3}>
            <Icon type="info-circle" style={{ color: '#399DE5', fontSize: 30, marginTop: 10, marginBottom: 20 }} />
          </Col>

          <Col lg={21}>
            <p>
              Are you sure you want to activate this payment method on your Payment Portals?
            </p>
          </Col>
        </Row>
      </Modal>
      <Modal
        title="Disable Payment Method"
        className="dialog-modal payment-method-modal"
        visible={state.isDisablePaymentModalVisible}
        onCancel={() => dispatch({ type: 'HIDE_MODAL' })}
        footer={[
          <Button
            key="cancel"
            onClick={() => dispatch({ type: 'HIDE_MODAL' })}
          >
            Cancel
          </Button>,
          <Button
            key="submit"
            type="primary"
            htmlType="submit"
            loading={state.isSettingPaymentMethod}
            onClick={() => disablePaymentMethod(selectedPaymentMethodId)}
          >
            Disable
          </Button>,
        ]}
      >
        <Row>
          <Col lg={3}>
            <Icon type="info-circle" style={{ color: '#399DE5', fontSize: 30, marginTop: 10, marginBottom: 20 }} />
          </Col>

          <Col lg={21}>
            <p>
              Are you sure you want to disable this payment method on your Payment Portals?
            </p>
          </Col>
        </Row>
      </Modal>
    </section>
  );
};

MerchantPaymentMethods.propTypes = {
  merchant: PropTypes.shape({
    id: PropTypes.number.isRequired,
  }).isRequired,
};

export default MerchantPaymentMethods;
