import React, { useContext, useState, useReducer, useEffect } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { Button, Modal, Form } from 'antd';
import {
  approveEnrollment,
  declineEnrollment,
  cancelEnrollment,
  archiveEnrollment,
  unarchiveEnrollment,
  aylResendNotification,
} from '../../services/enrollments';
import { getMerchantByCode } from '../../services/merchants';
import SessionContext from '../../contexts/SessionContext';
import '../../styles/transaction.css';
import './enrollmentActions.css';

const initialState = {
  isApproveModalVisible: false,
  isDeclineModalVisible: false,
  isCancellationModalVisible: false,
  isArchiveModalVisible: false,
  isResendButtonVisible: false,
  isResendButtonLoading: false,
  isResendModalVisible: false,
  isFetchingMerchant: true,
  merchant: null,
  isProcessing: false,
};

function reducer(prevState, action) {
  switch (action.type) {
    case 'SHOW_MODAL':
      return {
        ...prevState,
        [action.payload]: true,
      };
    case 'HIDE_MODAL':
      return {
        ...prevState,
        isApproveModalVisible: false,
        isDeclineModalVisible: false,
        isCancellationModalVisible: false,
        isArchiveModalVisible: false,
        isResendModalVisible: false,
      };
    case 'GET_MERCHANT':
      return {
        ...prevState,
        isFetchingMerchant: true,
        merchant: null,
      };
    case 'GET_MERCHANT_SUCCESSFUL':
      return {
        ...prevState,
        isFetchingMerchant: false,
        merchant: action.merchant,
      };
    case 'GET_MERCHANT_FAILED':
      return {
        ...prevState,
        isFetchingMerchant: false,
        merchant: null,
      };
    case 'START_PROCESSING':
      return {
        ...prevState,
        isProcessing: true,
      };
    case 'SHOW_RESEND_BUTTON':
      return {
        ...prevState,
        isResendButtonVisible: true,
      };
    case 'TOGGLE_RESEND_BUTTON_LOAD':
      return {
        ...prevState,
        isResendButtonLoading: !prevState.isResendButtonLoading,
      };
    default:
      return prevState;
  }
}

function useInputForm(placeholder) {
  const [value, setValue] = useState('');
  const onChange = e => setValue(e.target.value);
  return {
    value,
    className: 'ant-input',
    placeholder,
    rows: '2',
    onChange,
  };
}

const EnrollmentActions = (props) => {
  const {
    status,
    merchantCode,
    enrollmentReferenceId,
    enrollmentTransactionId,
    transactionId,
    scheduleFlag,
    isArchived,
  } = props;
  const ayalaMerchants = ['ayalalandpremier', 'amaia', 'alveo', 'avida', 'bellavita'];
  const [state, dispatch] = useReducer(reducer, initialState);
  const { showToast } = useContext(SessionContext);
  const commentForm = useInputForm('');
  const cancelReasonForm = useInputForm('');
  const {
    isApproveModalVisible,
    isDeclineModalVisible,
    isCancellationModalVisible,
    isArchiveModalVisible,
    isResendModalVisible,
    isResendButtonVisible,
    isResendButtonLoading,
  } = state;

  useEffect(() => {
    let ableToSet = true;
    (async () => {
      if (ableToSet) { dispatch({ type: 'GET_MERCHANTS' }); }
      try {
        const response = await getMerchantByCode(merchantCode);
        if (ableToSet) {
          dispatch({
            type: 'GET_MERCHANT_SUCCESSFUL',
            merchant: response.data,
          });
        }
      } catch (error) {
        if (ableToSet) {
          const message = error && error.response
            ? error.response.data.message
            : 'Unable to fetch the merchant information as of the moment';
          dispatch({ type: 'GET_MERCHANT_FAILED' });
          showToast({ type: 'error', message });
        }
      }
      if (ayalaMerchants.includes(merchantCode) && scheduleFlag) {
        if (ableToSet) dispatch({ type: 'SHOW_RESEND_BUTTON' });
      }
    })();
    return () => { ableToSet = false; };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (state.isFetchingMerchant && !state.merchant) {
    return (
      <div>
        <div className="spinner">
          <span className="spinner-text">Loading...</span>
        </div>
      </div>
    );
  }

  async function onApproveOk() {
    try {
      await approveEnrollment(state.merchant.id, enrollmentReferenceId);
      setTimeout(() => window.location.reload(), 500);
    } catch (err) {
      showToast({
        message: err.message,
        type: 'error',
      });
    }
  }

  async function onDeclineOk() {
    try {
      await declineEnrollment(state.merchant.id, enrollmentReferenceId, commentForm.value);
      setTimeout(() => window.location.reload(), 500);
    } catch (err) {
      showToast({
        message: err.message,
        type: 'error',
      });
    }
  }

  async function onCancellationOk() {
    try {
      await cancelEnrollment(state.merchant.id, enrollmentReferenceId, cancelReasonForm.value);
      setTimeout(() => window.location.reload(), 500);
    } catch (err) {
      showToast({
        message: err.message,
        type: 'error',
      });
    }
  }

  async function onArchiveOk(archived) {
    dispatch({ type: 'START_PROCESSING' });
    try {
      if (!archived) await archiveEnrollment(state.merchant.id, transactionId);
      else await unarchiveEnrollment(state.merchant.id, transactionId);
      setTimeout(() => window.location.reload(), 500);
    } catch (err) {
      showToast({
        message: err.message,
        type: 'error',
      });
    }
  }

  async function onResendOk() {
    dispatch({ type: 'TOGGLE_RESEND_BUTTON_LOAD' });
    dispatch({ type: 'HIDE_MODAL' });
    try {
      const response = await aylResendNotification(state.merchant.id, enrollmentTransactionId);
      showToast({
        type: 'success',
        message: response.data.message,
      });
    } catch (err) {
      showToast({
        type: 'error',
        message: err.message,
      });
    }
    dispatch({ type: 'TOGGLE_RESEND_BUTTON_LOAD' });
  }

  let resendButton = (<div />);
  if (isResendButtonVisible) {
    resendButton = (
      <div>
        <Button
          className="button button-standard"
          loading={isResendButtonLoading}
          onClick={() => dispatch({ type: 'SHOW_MODAL', payload: 'isResendModalVisible' })}
        >
          Resend Notification
        </Button>
        <Modal
          title="Resend Notification"
          className="dialog-modal"
          visible={isResendModalVisible}
          onOk={onResendOk}
          onCancel={() => dispatch({ type: 'HIDE_MODAL' })}
          footer={[
            <Button
              key="cancel-confirm-key"
              onClick={() => dispatch({ type: 'HIDE_MODAL' })}
            >
              Cancel
            </Button>,
            <Button
              key="submit"
              type="primary"
              onClick={onResendOk}
            >
              Resend Notification
            </Button>,
          ]}
        >
          <p>Are you sure you want to resend an email notification?</p>
        </Modal>
      </div>
    );
  }

  const cancelEnrollmentAction = () => (
    <span>
      <div className="enrollment-actions-end">
        <Button
          className="button button-danger button-danger-outline"
          onClick={() => dispatch({ type: 'SHOW_MODAL', payload: 'isCancellationModalVisible' })}
        >
          Cancel Enrollment
        </Button>
      </div>
      <Modal
        title="Cancel Enrollment"
        className="dialog-modal"
        visible={isCancellationModalVisible}
        onOk={onCancellationOk}
        onCancel={() => dispatch({ type: 'HIDE_MODAL' })}
        footer={[
          <Button
            key="cancel-confirm-key"
            onClick={() => dispatch({ type: 'HIDE_MODAL' })}
          >
            Cancel
          </Button>,
          <Button
            key="submit"
            type="danger"
            onClick={onCancellationOk}
            loading={state.isProcessing}
          >
            Confirm Cancellation
          </Button>,
        ]}
      >
        <p>Once the enrollment is cancelled, the client cannot use this data anymore. They need to re-enroll again. </p>
        <Form layout="vertical">
          <Form.Item label="Reason for cancellation">
            <textarea
              {...cancelReasonForm}
            />
          </Form.Item>
        </Form>
      </Modal>
    </span>
  );

  const archiveEnrollmentAction = archived => (
    <span>
      <div className="enrollment-actions-end">
        <Button
          className="button button-danger button-danger-outline"
          onClick={() => dispatch({ type: 'SHOW_MODAL', payload: 'isArchiveModalVisible' })}
        >
          {`${!archived ? 'Archive' : 'Unarchive'} Enrollment`}
        </Button>
      </div>
      <Modal
        title={`${!archived ? 'Archive' : 'Unarchive'} Enrollment`}
        className="dialog-modal"
        visible={isArchiveModalVisible}
        onOk={() => onArchiveOk(isArchived)}
        onCancel={() => dispatch({ type: 'HIDE_MODAL' })}
        footer={[
          <Button
            key="cancel-confirm-key"
            onClick={() => dispatch({ type: 'HIDE_MODAL' })}
          >
            Cancel
          </Button>,
          <Button
            key="submit"
            type="danger"
            onClick={() => onArchiveOk(isArchived)}
            loading={state.isProcessing}
          >
            Yes
          </Button>,
        ]}
      >
        <p>
          {`Are you sure you want to ${!archived ? '' : 'un'}archive this enrollment?`}
        </p>
      </Modal>
    </span>
  );

  switch (status) {
    case 'FOR REVIEW':
      return (
        <div className="panel-footer">
          <div className="enrollment-actions-center">
            <Button
              className="button button-danger button-danger-outline"
              onClick={() => dispatch({ type: 'SHOW_MODAL', payload: 'isDeclineModalVisible' })}
            >
              Decline Enrollment
            </Button>
            <span style={{ width: 16 }} />
            <Button
              className="button button-standard"
              onClick={() => dispatch({ type: 'SHOW_MODAL', payload: 'isApproveModalVisible' })}
            >
              Approve Enrollment
            </Button>
          </div>
          <Modal
            title="Approve Enrollment"
            className="dialog-modal"
            visible={isApproveModalVisible}
            onCancel={() => dispatch({ type: 'HIDE_MODAL' })}
            footer={[
              <Button
                key="cancel-key"
                onClick={() => dispatch({ type: 'HIDE_MODAL' })}
              >
                Cancel
              </Button>,
              <Button
                key="submit"
                type="primary"
                onClick={onApproveOk}
              >
                Approve Enrollment
              </Button>,
            ]}
          >
            <p>Are you sure you want to approve this enrollment?</p>
          </Modal>
          <Modal
            title="Decline Enrollment"
            className="dialog-modal"
            visible={isDeclineModalVisible}
            onCancel={() => dispatch({ type: 'HIDE_MODAL' })}
            footer={[
              <Button
                key="cancel-decline-key"
                onClick={() => dispatch({ type: 'HIDE_MODAL' })}
              >
                Cancel
              </Button>,
              <Button
                key="submit"
                type="danger"
                onClick={onDeclineOk}
              >
                Decline Enrollment
              </Button>,
            ]}
          >
            <p>Once the enrollment is declined, the client cannot use this data anymore. They need to re-enroll again. </p>
            <Form layout="vertical">
              <Form.Item label="Reason for declining">
                <textarea
                  {...commentForm}
                />
              </Form.Item>
            </Form>
          </Modal>
        </div>
      );
    case 'DONE':
    case 'CANCELLED':
    case 'DECLINED':
      return (
        <div className="panel-footer -flex-end">
          {archiveEnrollmentAction(isArchived)}
        </div>
      );
    case 'ONGOING':
      return (
        <div className="panel-footer -flex-end">
          {cancelEnrollmentAction()}
        </div>
      );
    case 'PENDING':
      return (
        <div className="panel-footer -flex-end">
          {!isArchived ? resendButton : null}
          {archiveEnrollmentAction(isArchived)}
          {!isArchived ? cancelEnrollmentAction() : null}
        </div>
      );
    case 'INC':
      return (
        <div className="panel-footer">
          {resendButton}
        </div>
      );
    default:
      return null;
  }
};

EnrollmentActions.propTypes = {
  merchantCode: PropTypes.string.isRequired,
  enrollmentReferenceId: PropTypes.string,
  enrollmentTransactionId: PropTypes.string.isRequired,
  transactionId: PropTypes.number.isRequired,
  status: PropTypes.string.isRequired,
  isArchived: PropTypes.bool.isRequired,
  scheduleFlag: PropTypes.bool,
};

EnrollmentActions.defaultProps = {
  scheduleFlag: false,
  enrollmentReferenceId: null,
};


export default withRouter(EnrollmentActions);
