import { Button, Modal, Form, Input, Icon } from 'antd';
import * as moment from 'moment';
import PropTypes from 'prop-types';
import React, { useEffect, useReducer } from 'react';
import { getMerchantApiKeysById, createMerchantApiKey } from '../../services/merchants';
import { CopyIcon } from '../Icons';
import './index.scss';


const initialState = {
  isFetchingApiKeys: false,
  apiKeys: [],
  isSecretVisible: false,
  apiKeyResetStatus: null,
  isResetApiModalVisible: false,
  generateKeyStatus: null,
  copyIdPromptVisible: false,
  copyKeyPromptVisible: false,
};

function reducer(prevState, action) {
  switch (action.type) {
    case 'FETCH_API_KEYS':
      return {
        ...prevState,
        isFetchingApiKeys: true,
      };

    case 'SET_API_KEYS':
      return {
        ...prevState,
        apiKeys: action.apiKeys,
        isFetchingApiKeys: false,
      };

    case 'TOGGLE_SECRET_VISIBILITY':
      return {
        ...prevState,
        isSecretVisible: !prevState.isSecretVisible,
      };

    case 'TOGGLE_RESET_API_MODAL':
      return {
        ...prevState,
        isResetApiModalVisible: !prevState.isResetApiModalVisible,
      };

    case 'SET_FORM_STATUS':
      return {
        ...prevState,
        apiKeyResetStatus: action.status,
      };

    case 'SET_GENERATE_KEY_STATUS':
      return {
        ...prevState,
        generateKeyStatus: action.status,
      };

    case 'SHOW_ID_COPY_PROMPT':
      return {
        ...prevState,
        copyIdPromptVisible: true,
      };

    case 'HIDE_ID_COPY_PROMPT':
      return {
        ...prevState,
        copyIdPromptVisible: false,
      };

    case 'SHOW_KEY_COPY_PROMPT':
      return {
        ...prevState,
        copyKeyPromptVisible: true,
      };

    case 'HIDE_KEY_COPY_PROMPT':
      return {
        ...prevState,
        copyKeyPromptVisible: false,
      };

    default:
      return prevState;
  }
}

const ApiKeysTab = (props) => {
  const { merchant, form, onComplete } = props;
  const { getFieldDecorator } = form;
  const [state, dispatch] = useReducer(reducer, initialState);
  const timezone = moment.tz.guess(true);

  async function loadApiKeys(ableToSet) {
    if (ableToSet) {
      dispatch({ type: 'FETCH_API_KEYS' });
    }

    try {
      const { data: { api_keys: apiKeys } } = await getMerchantApiKeysById(merchant.id);

      if (ableToSet) {
        dispatch({ type: 'SET_API_KEYS', apiKeys });
      }
    } catch (error) {
      if (ableToSet) {
        dispatch({ type: 'SET_API_KEYS', apiKeys: [] });
      }
    }
  }

  function resetApiKey(e) {
    e.preventDefault();
    form.validateFields(['merchantId'], { first: true }, async (err, values) => {
      if (!err) {
        dispatch({ type: 'SET_FORM_STATUS', status: 'loading' });
        try {
          const { data: { api_key_id: apiKeyId, message } } = await createMerchantApiKey(merchant.id);
          if (!apiKeyId) {
            dispatch({ type: 'SET_FORM_STATUS', status: null });
            form.setFields({
              merchantId: {
                value: values.merchantId,
                errors: [new Error(message)],
              },
            });
            return;
          }
          await loadApiKeys(true);
          dispatch({ type: 'SET_FORM_STATUS', status: 'success' });
          onComplete({ status: 'success', message: 'Api key has been successfully reset.' });
          dispatch({ type: 'TOGGLE_RESET_API_MODAL' });
        } catch (error) {
          dispatch({ type: 'SET_FORM_STATUS', status: null });
          form.setFields({
            merchantId: {
              value: values.merchantId,
              errors: [new Error('Failed to reset api key, we could not process your request at this time.')],
            },
          });
        }
      }
    });
  }

  async function merchantValidator(_, value) {
    const isPartner = merchant.type === 'partner';
    if (!value) {
      throw new Error(`${isPartner ? 'Partner' : 'Merchant'} id is required.`);
    }

    if (value !== merchant.code) {
      throw new Error(`The provided ${isPartner ? 'partner' : 'merchant'} id is invalid.`);
    }
  }

  const resetApiConfirmModal = (
    <Modal
      className="dialog-modal"
      centered
      title="Reset Key"
      visible={state.isResetApiModalVisible}
      onCancel={() => dispatch({ type: 'TOGGLE_RESET_API_MODAL' })}
      footer={state.apiKeyResetStatus !== 'success' && [
        <Button
          key="back"
          onClick={() => dispatch({ type: 'TOGGLE_RESET_API_MODAL' })}
        >
          Cancel
        </Button>,
        <Button
          form="confirmationForm"
          key="submit"
          type="primary"
          htmlType="submit"
          loading={state.apiKeyResetStatus === 'loading'}
        >
          Reset Key
        </Button>,
      ]}
    >
      <div className="inner-body">
        <div style={{ color: '#EB3800', marginBottom: '8px' }}>
          <Icon type="warning" style={{ marginRight: '8px' }} />
          Warning! This will revoke your current primary key.
        </div>
        <Form id="confirmationForm" onSubmit={resetApiKey}>
          <Form.Item label={`Type "${merchant.code}" to continue`}>
            {getFieldDecorator('merchantId', {
              rules: [{ validator: merchantValidator }],
              validateTrigger: 'onBlur',
            })(<Input disabled={state.apiKeyResetStatus === 'loading'} />)}
          </Form.Item>
        </Form>
      </div>
    </Modal>
  );

  useEffect(() => {
    let ableToSet = true;

    loadApiKeys(ableToSet);

    return () => { ableToSet = false; };
  }, [merchant.id]);

  return (
    <>
      {resetApiConfirmModal}
      <section className="panel panel-standard">
        <div className="panel-header">
          <h3>Primary keys</h3>
          <p style={{ whiteSpace: 'pre' }}>
            These keys are used to authenticate in
            {merchant.type === 'partner' ? ' Partner Reference API' : ' Access API'}
            .
            {'\n'}
            Read our documentation
            <a href="https://developers.aqwire.io/docs"> here</a>
          </p>
        </div>
        <div className="panel-body">
          {state.apiKeys.length > 0 ? state.apiKeys.map(apiKey => (
            <div key={apiKey.clientId}>
              <div className="panel-item">
                <h3>{merchant.type === 'partner' ? 'Partner Code' : 'Merchant Code'}</h3>
                {merchant.code}
              </div>
              <div className="panel-item">
                <h3>Client ID</h3>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <span style={{ paddingRight: '8px' }}>
                    <code
                      className="api-id-secret-content"
                      style={{ '--copy-prompt-display': state.copyIdPromptVisible ? 1 : 0 }}
                    >
                      {apiKey.clientId}
                    </code>
                  </span>
                  <Button
                    className="api-copy-button"
                    title="Copy to clipboard"
                    type="link"
                    onClick={() => {
                      navigator.clipboard.writeText(apiKey.clientId);
                      dispatch({ type: 'SHOW_ID_COPY_PROMPT' });
                      setTimeout(() => dispatch({ type: 'HIDE_ID_COPY_PROMPT' }), 3000);
                    }}
                  >
                    <CopyIcon />
                  </Button>
                </div>
              </div>
              <div className="panel-item">
                <h3>Security Key</h3>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <span style={{ paddingRight: '8px' }}>
                    {state.isSecretVisible
                      ? (
                        <span>
                          <span style={{ paddingRight: '8px' }}>
                            <code
                              className="api-key-secret-content"
                              style={{ '--copy-prompt-display': state.copyKeyPromptVisible ? 1 : 0 }}
                            >
                              {apiKey.secretKey}
                            </code>
                          </span>
                          <Button
                            className="api-copy-button"
                            title="Copy to clipboard"
                            type="link"
                            onClick={() => {
                              navigator.clipboard.writeText(apiKey.secretKey);
                              dispatch({ type: 'SHOW_KEY_COPY_PROMPT' });
                              setTimeout(() => dispatch({ type: 'HIDE_KEY_COPY_PROMPT' }), 3000);
                            }}
                          >
                            <CopyIcon />
                          </Button>
                        </span>
                      )
                      : '******************************************************'}
                  </span>
                  <Button
                    type="primary"
                    size="small"
                    style={{
                      verticalAlign: 'bottom',
                    }}
                    onClick={() => {
                      dispatch({ type: 'TOGGLE_SECRET_VISIBILITY' });
                    }}
                  >
                    {state.isSecretVisible
                      ? 'Hide'
                      : 'Reveal'
                    }
                  </Button>
                </div>
              </div>
              <div className="panel-item">
                <h3>Date Created</h3>
                {moment(apiKey.createdAt).tz(timezone).format('lll')}
              </div>
              {state.apiKeyResetStatus !== 'success' && (
                <div className="panel-item">
                  <Button
                    className="button button-danger button-danger-outline button-small"
                    onClick={() => {
                      dispatch({ type: 'TOGGLE_RESET_API_MODAL' });
                    }}
                  >
                    Reset key
                  </Button>
                </div>
              )}
            </div>
          )) : (
            <div>
              <div className="panel-item" style={{ whiteSpace: 'pre' }}>
                It looks like you do not have an active primary key at the moment.
                {'\n'}
                Generate your primary key to start using api features.
              </div>
              <div className="panel-item">
                <Button
                  className="button button-standard button-standard-outline button-small"
                  loading={state.generateKeyStatus === 'loading'}
                  onClick={async () => {
                    try {
                      dispatch({ type: 'SET_GENERATE_KEY_STATUS', status: 'loading' });
                      const { data: { api_key_id: apiKeyId, message } } = await createMerchantApiKey(merchant.id);
                      if (apiKeyId) {
                        await loadApiKeys(true);
                        dispatch({ type: 'SET_GENERATE_KEY_STATUS', status: 'success' });
                        onComplete({ status: 'success', message: message && 'Generate keys successful.' });
                      }
                    } catch (error) {
                      dispatch({ type: 'SET_GENERATE_KEY_STATUS', status: null });
                      const message = error && error.response && error.response.data.message;
                      onComplete({ status: 'error', message: message || 'Unable to generate key at this time.' });
                    }
                  }}
                >
                  Generate key
                </Button>
              </div>
            </div>
          )}
        </div>
      </section>
    </>
  );
};

ApiKeysTab.propTypes = {
  merchant: PropTypes.shape({
    id: PropTypes.number.isRequired,
    code: PropTypes.string.isRequired,
    type: PropTypes.string.isRequired,
  }).isRequired,
  form: PropTypes.shape({
    getFieldDecorator: PropTypes.func.isRequired,
    validateFields: PropTypes.func.isRequired,
    setFields: PropTypes.func.isRequired,
  }).isRequired,
  onComplete: PropTypes.func.isRequired,
};

export default Form.create()(ApiKeysTab);
