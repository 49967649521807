import api from '../helpers/apiClient';
import { apiBaseUrl } from '../config/index';

export function searchTransactionsService(merchantId, searchOption) {
  const { page, size } = searchOption;
  return api.get(`/merchants/${merchantId}/transactions/search`, {
    params: {
      page,
      size,
      query: searchOption.query ? searchOption.query.trim() : null,
    },
  });
}

export function getTransactionsService(merchantCode, searchOption) {
  const { page, size } = searchOption;
  return api.get(`/merchants/${merchantCode}/transactions`, {
    params: {
      page,
      size,
      project: searchOption.project,
      showinc: searchOption.showIncomplete ? 'true' : undefined,
      query: searchOption.query ? searchOption.query.trim() : null,
      startday: searchOption.startDate.isValid() ? searchOption.startDate.format('YYYY-MM-DD') : undefined,
      endday: searchOption.endDate.isValid() ? searchOption.endDate.format('YYYY-MM-DD') : undefined,
    },
  });
}

export function getTransactionService(merchantId, transactionId) {
  return api.get(`/merchants/${merchantId}/transactions/${transactionId}`);
}

export function authenticateExportTokenService() {
  return api.post('/auth/export');
}

export function authenticateMerchantExportTokenService(merchantId) {
  return api.post(`/merchants/${merchantId}/auth/export`);
}

export function exportTransactionsService(searchQuery, token) {
  let url = `${apiBaseUrl}/transactions/export?token=${token}`;
  url += `&query=${searchQuery}`;
  window.open(url, '_blank');
}
