import React, { Fragment, useContext, useReducer, useEffect } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { Link, useHistory } from 'react-router-dom';
import { Col, Row, Button, Dropdown, Icon, Modal, Select, Menu } from 'antd';
import { SingleForm, WebhookTaskLogTable, ViewWebhookTaskLogModal } from '../../components';
import Message from '../../components/Message';
import {
  getMerchantWebhookEndpointService,
  updateMerchantWebhookEndpointService,
  createSampleWebhookTask,
} from '../../services/merchants';
import { findWebhookTaskLog } from '../../services/logs';
import SessionContext from '../../contexts/SessionContext';
import { FolderIcon, CodeIcon, CopyIcon } from '../../components/Icons';
import './index.scss';

const disableContent = {
  title: 'Disable webhook endpoint',
  content: 'Are you sure you want to disable this endpoint?',
  okButton: 'Disable',
  action: 'disable',
  type: 'danger',
};

const enableContent = {
  title: 'Enable webhook endpoint',
  content: 'Are you sure you want to enable this endpoint?',
  okButton: 'Enable',
  action: 'enable',
  type: 'primary',
};

const deleteContent = {
  title: 'Delete webhook endpoint',
  content: 'Are you sure you want to delete this endpoint?',
  okButton: 'Delete',
  action: 'delete',
  type: 'danger',
};

const resetContent = {
  title: 'Reset webhook signing secret',
  content: 'Are you sure you want to reset the signing secret?',
  okButton: 'Reset',
  action: 'reset',
  type: 'danger',
};

const initialState = {
  isFetchingWebhook: true,
  webhook: null,
  subscribedEvents: [],
  message: null,
  status: null,
  sampleTaskErrorMessage: null,
  isSecretVisible: false,
  isTestEventModalVisible: false,
  isTaskModalVisible: false,
  isActionModalVisible: false,
  selectedEvent: null,
  isSubmittingSample: false,
  isFetchingSingleLog: false,
  copyPromptVisible: false,
  actionModalContent: {
    title: null,
    content: null,
    okButton: null,
    action: null,
  },
  singleWebhookTaskLog: {
    taskId: 0,
    source: '',
    webhookEvent: '',
    retries: null,
    retryTimestamp: null,
    isComplete: false,
    taskRequest: {},
    taskResponse: {},
    createdAt: '',
  },
};

function reducer(prevState, action) {
  switch (action.type) {
    case 'GET_WEBHOOK':
      return {
        ...prevState,
        isFetchingWebhook: true,
        webhook: null,
        subscribedEvents: [],
      };
    case 'SET_WEBHOOK':
      return {
        ...prevState,
        isFetchingWebhook: false,
        webhook: action.webhook,
        subscribedEvents: action.subscribedEvents,
        message: action.message,
        status: action.status,
      };
    case 'SET_MESSAGE':
      return {
        ...prevState,
        message: action.message,
        status: action.status,
      };
    case 'CLEAR_MESSAGE':
      return {
        ...prevState,
        message: null,
        status: null,
      };
    case 'TOGGLE_SECRET_VISIBILITY':
      return {
        ...prevState,
        isSecretVisible: !prevState.isSecretVisible,
      };
    case 'TOGGLE_TASK_LOG_MODAL_VISIBILITY':
      return {
        ...prevState,
        isTaskModalVisible: !prevState.isTaskModalVisible,
      };
    case 'FETCH_SINGLE_WEBHOOK_TASK_LOG':
      return {
        ...prevState,
        isFetchingSingleLog: true,
      };

    case 'SET_SINGLE_WEBHOOK_TASK_LOG':
      return {
        ...prevState,
        singleWebhookTaskLog: action.taskLog,
        isFetchingSingleLog: false,
      };
    case 'TOGGLE_TEST_EVENT_MODAL_VISIBILITY':
      return {
        ...prevState,
        isTestEventModalVisible: !prevState.isTestEventModalVisible,
        selectedEvent: null,
        sampleTaskErrorMessage: null,
      };
    case 'SET_WEBHOOK_EVENT':
      return {
        ...prevState,
        selectedEvent: action.event,
        sampleTaskErrorMessage: null,
      };
    case 'SET_ACTION_MODAL':
      return {
        ...prevState,
        actionModalContent: {
          title: action.content.title,
          content: action.content.content,
          okButton: action.content.okButton,
          action: action.content.action,
          type: action.content.type,
        },
        isActionModalVisible: true,
      };
    case 'HIDE_ACTION_MODAL':
      return {
        ...prevState,
        isActionModalVisible: false,
      };
    case 'START_SUBMIT_SAMPLE_EVENT':
      return {
        ...prevState,
        isSubmittingSample: true,
      };
    case 'COMPLETE_SUBMIT_SAMPLE_EVENT':
      return {
        ...prevState,
        isSubmittingSample: false,
        isTestEventModalVisible: false,
        selectedEvent: null,
        isTaskModalVisible: true,
        singleWebhookTaskLog: action.task,
      };
    case 'FAIL_SUBMIT_SAMPLE_EVENT':
      return {
        ...prevState,
        isSubmittingSample: false,
        sampleTaskErrorMessage: action.message,
      };
    case 'CLEAR_SUBMIT_SAMPLE_EVENT_MESSAGE':
      return {
        ...prevState,
        sampleTaskErrorMessage: null,
      };
    case 'SHOW_COPY_PROMPT':
      return {
        ...prevState,
        copyPromptVisible: true,
      };
    case 'HIDE_COPY_PROMPT':
      return {
        ...prevState,
        copyPromptVisible: false,
      };
    default:
      return prevState;
  }
}

const WebhookEndpoint = (props) => {
  const { match: { params: { merchantCode, webhookId } } } = props;
  const { loggedInUser, merchants } = useContext(SessionContext);
  const history = useHistory();
  const merchant = merchants.find(m => m.code === merchantCode);
  const [state, dispatch] = useReducer(reducer, initialState);
  const timezone = moment.tz.guess(true);

  useEffect(() => {
    let ableToSet = true;
    (async () => {
      if (!merchant || !loggedInUser) { return; }

      dispatch({ type: 'GET_WEBHOOK' });
      try {
        const response = await getMerchantWebhookEndpointService(merchant.id, webhookId);
        const subscribedEvents = response.data.webhook.subscribedEvents.split(',');
        const eventsCount = subscribedEvents.length - 1;
        subscribedEvents[0] = subscribedEvents[0].substring(1);
        subscribedEvents[eventsCount] = subscribedEvents[eventsCount].substring(0, subscribedEvents[eventsCount].length - 1);

        if (ableToSet) {
          dispatch({
            type: 'SET_WEBHOOK',
            webhook: response.data.webhook,
            subscribedEvents,
            message: null,
            status: null,
          });
        }
      } catch (_) {
        dispatch({
          type: 'SET_WEBHOOK',
          webhook: null,
          subscribedEvents: [],
        });
      }
    })();

    return () => { ableToSet = false; };
  }, []);

  async function updateWebhook() {
    // FIXME: Does not display message on success or on error

    try {
      const { action } = state.actionModalContent;
      const { status, data: { message, webhook } } = await updateMerchantWebhookEndpointService(merchant.id, webhookId, action);
      if (status === 200) {
        if (action === 'delete') {
          history.push(`/merchants/${merchant.code}/developers?key=webhook`);
        } else {
          dispatch({
            type: 'SET_WEBHOOK',
            webhook,
            subscribedEvents: state.subscribedEvents,
            message,
            status: 'success',
          });
          dispatch({ type: 'HIDE_ACTION_MODAL' });
        }
      }
    } catch (error) {
      const message = error && error.response
        ? error.response.data.message
        : 'We are not able to update the webhook as of the moment';
      dispatch({ type: 'SET_MESSAGE', message, status: 'error' });
    }
  }

  if (!merchant) {
    return (
      <SingleForm
        title="Merchant does not exist"
        subtitle="The page you requested could not be found. Please go back to Dashboard or contact us at support@aqwire.io."
        footer={{ show: true, to: '/', text: 'Back to Dashboard' }}
      />
    );
  }

  if (state.isFetchingWebhook) {
    return (
      <div>
        <div className="spinner">Loading...</div>
      </div>
    );
  }

  if (!state.webhook) {
    return (
      <div>
        <div className="spinner">
          <FolderIcon />
          <h1>Webhook Not Found</h1>
          <p>
            You can contact us at&nbsp;
            <a href="mailto:support@aqwire.io">support@aqwire.io</a>
            &nbsp;if you have concerns or issues.
          </p>
        </div>
      </div>
    );
  }

  async function submitSampleWebhook() {
    dispatch({ type: 'START_SUBMIT_SAMPLE_EVENT' });

    try {
      const { data } = await createSampleWebhookTask(merchant.id, webhookId, state.selectedEvent);
      dispatch({ type: 'COMPLETE_SUBMIT_SAMPLE_EVENT', task: data });
    } catch (error) {
      if (error.response && error.response.status === 400) {
        const { data } = error.response;
        dispatch({ type: 'COMPLETE_SUBMIT_SAMPLE_EVENT', task: data });
        return;
      }

      const message = error && error.response
        ? error.response.data.message
        : 'Unable to process the request';

      dispatch({ type: 'FAIL_SUBMIT_SAMPLE_EVENT', message });
    }
  }

  function fetchSingleWebhookTaskLog(row) {
    dispatch({ type: 'FETCH_SINGLE_WEBHOOK_TASK_LOG' });
    dispatch({ type: 'TOGGLE_TASK_LOG_MODAL_VISIBILITY' });

    findWebhookTaskLog(merchant.id, webhookId, row.taskId)
      .then(({ data }) => {
        dispatch({ type: 'SET_SINGLE_WEBHOOK_TASK_LOG', taskLog: data });
      })
      .catch(() => {
        dispatch({ type: 'TOGGLE_TASK_LOG_MODAL_VISIBILITY' });
      });
  }

  const breadcrumb = (
    <div className="bc-container">
      <div className="bc-icon">
        <CodeIcon />
      </div>
      <div className="bc-item heavy">
        <Link to={`/merchants/${merchant.code}/developers?key=api`}>
          Developers
        </Link>
      </div>
      <div className="bc-arrow">&#62;</div>
      <div className="bc-item">
        <Link to={`/merchants/${merchant.code}/developers?key=webhook`}>
          Webhooks
        </Link>
      </div>
      <div className="bc-arrow">&#62;</div>
      <div className="bc-item">
        <span>Endpoint</span>
      </div>
    </div>
  );

  const menu = (
    <Menu>
      <Menu.Item key="1" disabled>
        Edit
      </Menu.Item>
      {state.webhook.endpointStatus === 'active'
        ? (
          <Menu.Item onClick={() => dispatch({ type: 'SET_ACTION_MODAL', content: disableContent })} key="2">
            Disable
          </Menu.Item>
        )
        : (
          <Menu.Item onClick={() => dispatch({ type: 'SET_ACTION_MODAL', content: enableContent })} key="2">
            Enable
          </Menu.Item>
        )
      }
      <Menu.Item onClick={() => dispatch({ type: 'SET_ACTION_MODAL', content: deleteContent })} key="3">
        Delete
      </Menu.Item>
      <Menu.Item onClick={() => dispatch({ type: 'SET_ACTION_MODAL', content: resetContent })} key="4">
        Reset Secret
      </Menu.Item>
      <Menu.Item onClick={() => dispatch({ type: 'TOGGLE_TEST_EVENT_MODAL_VISIBILITY' })} key="5">
        Send test event
      </Menu.Item>
    </Menu>
  );

  const actionDropdown = (
    <Col span={4} align="right" className="action-container">
      <Dropdown
        overlay={menu}
        trigger={['click']}
      >
        <Button
          type="primary"
          className="button button-standard button-standard-outline button-small"
        >
          Actions
          <Icon type="down" />
        </Button>
      </Dropdown>
    </Col>
  );

  const signingSecret = (
    <Row className="padding-top-16 padding-bottom-8">
      <h3>Signing Secret</h3>
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <span style={{ paddingRight: '8px' }}>
          {state.isSecretVisible
            ? (
              <span>
                <span style={{ paddingRight: '8px' }}>
                  <code
                    className="secret-content"
                    style={{ '--copy-prompt-display': state.copyPromptVisible ? 1 : 0 }}
                  >
                    {state.webhook.signingSecret}
                  </code>
                </span>
                <Button
                  className="api-copy-button"
                  title="Copy to clipboard"
                  type="link"
                  onClick={() => {
                    navigator.clipboard.writeText(state.webhook.signingSecret);
                    dispatch({ type: 'SHOW_COPY_PROMPT' });
                    setTimeout(() => dispatch({ type: 'HIDE_COPY_PROMPT' }), 3000);
                  }}
                >
                  <CopyIcon />
                </Button>
              </span>
            )
            : '****************************************************'}
        </span>
        <Button
          type="primary"
          size="small"
          style={{
            verticalAlign: 'bottom',
          }}
          onClick={() => {
            dispatch({ type: 'TOGGLE_SECRET_VISIBILITY' });
          }}
        >
          {state.isSecretVisible
            ? 'Hide'
            : 'Reveal'
          }
        </Button>
      </div>
    </Row>
  );

  const webhookContentPanel = (
    <section className="panel panel-standard -margin-bottom">
      {state.status && state.message && (
        <Message
          style={{ marginBottom: '12px' }}
          status={state.status}
          message={state.message}
          onClose={() => dispatch({ type: 'CLEAR_MESSAGE' })}
        />
      )}
      <div className="panel-header">
        <Row>
          <Col span={14}>
            <h3>URL</h3>
            <div>{state.webhook.callbackUrl}</div>
          </Col>
          <Col span={6}>
            <h3>Updated At</h3>
            <div>{moment(state.webhook.updatedAt).tz(timezone).format('lll')}</div>
          </Col>
          {actionDropdown}
        </Row>
      </div>
      <div className="panel-body">
        <Row className="padding-bottom-8">
          <h3>Information</h3>
        </Row>
        <Row>
          <Col span={6}>
            <h4 className="text-primary">Mode</h4>
          </Col>
          <Col span={6}>
            <div className="text-secondary">{state.webhook.endpointMode}</div>
          </Col>
        </Row>
        <Row>
          <Col span={6}>
            <h4 className="text-primary">Status</h4>
          </Col>
          <Col span={6}>
            <div className="text-secondary">{state.webhook.endpointStatus}</div>
          </Col>
        </Row>
        <Row>
          <Col span={6}>
            <h4 className="text-primary">Description</h4>
          </Col>
          <Col span={6}>
            <div className="text-secondary">{state.webhook.endpointDescription}</div>
          </Col>
        </Row>
        <div className="padding-bottom-8">
          <Row className="padding-top-16 padding-bottom-8">
            <h3>Subscribed Events</h3>
          </Row>
          {state.subscribedEvents.map(e => (
            <Row key={e}>
              <code>{e}</code>
            </Row>
          ))}
        </div>
        {signingSecret}
      </div>
    </section>
  );

  return (
    <div>
      <Fragment>
        {breadcrumb}
        {webhookContentPanel}
        <Modal
          className="dialog-modal"
          title={state.actionModalContent.title}
          visible={state.isActionModalVisible}
          onCancel={() => dispatch({ type: 'HIDE_ACTION_MODAL' })}
          onOk={() => updateWebhook()}
          footer={[
            <Button
              key="cancel-confirm-key"
              onClick={() => dispatch({ type: 'HIDE_ACTION_MODAL' })}
            >
              Cancel
            </Button>,
            <Button
              key="submit"
              type={state.actionModalContent.type}
              onClick={() => updateWebhook()}
            >
              {state.actionModalContent.okButton}
            </Button>,
          ]}
        >
          <p>{state.actionModalContent.content}</p>
          <code>{state.webhook.callbackUrl}</code>
        </Modal>
        <Modal
          className="dialog-modal"
          visible={state.isTestEventModalVisible}
          title="Send a test event to a webhook endpoint"
          onCancel={() => dispatch({ type: 'TOGGLE_TEST_EVENT_MODAL_VISIBILITY' })}
          onOk={() => submitSampleWebhook()}
          footer={[
            <Button
              key="cancel-confirm-key"
              onClick={() => dispatch({ type: 'TOGGLE_TEST_EVENT_MODAL_VISIBILITY' })}
            >
              Cancel
            </Button>,
            <Button
              loading={state.isSubmittingSample}
              key="submit"
              type="primary"
              disabled={state.selectedEvent === null}
              onClick={() => submitSampleWebhook()}
            >
              Send test webhook
            </Button>,
          ]}
        >
          {state.sampleTaskErrorMessage && (
            <Message
              status="error"
              message={state.sampleTaskErrorMessage}
              onClose={() => dispatch({ type: 'CLEAR_SUBMIT_SAMPLE_EVENT_MESSAGE' })}
            />
          )}
          <div className="test-event-modal">
            <div>
              <h4>Event Type</h4>
            </div>
            <div>
              <Select
                style={{ width: '12rem' }}
                value={state.selectedEvent}
                onChange={event => dispatch({ type: 'SET_WEBHOOK_EVENT', event })}
              >
                {state.subscribedEvents.map(e => (
                  <Select.Option key={e} value={e}>
                    <code>{e}</code>
                  </Select.Option>
                ))}
              </Select>
            </div>
          </div>
        </Modal>
      </Fragment>
      <Fragment>
        <WebhookTaskLogTable
          merchant={merchant}
          endpointId={webhookId}
          onRowClick={fetchSingleWebhookTaskLog}
          onRetryComplete={response => dispatch({
            type: 'SET_MESSAGE',
            message: response.message,
            status: response.status,
          })}
        />
        <ViewWebhookTaskLogModal
          isVisible={state.isTaskModalVisible}
          isFetchingLog={state.isFetchingSingleLog}
          taskLog={state.singleWebhookTaskLog}
          onCancel={() => dispatch({ type: 'TOGGLE_TASK_LOG_MODAL_VISIBILITY' })}
        />
      </Fragment>
    </div>
  );
};

WebhookEndpoint.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      webhookId: PropTypes.string.isRequired,
      merchantCode: PropTypes.string.isRequired,
    }),
  }).isRequired,
};

export default WebhookEndpoint;
