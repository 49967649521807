import React from 'react';
import PropTypes from 'prop-types';
import { FolderIcon } from '../Icons';

function PageMessage(props) {
  const { title, description } = props;
  return (
    <div>
      <div className="spinner">
        <FolderIcon />
        <h1>{title}</h1>
        <p>{description}</p>
      </div>
    </div>
  );
}

PageMessage.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
};

PageMessage.defaultProps = {
  title: 'Page Not Found',
  description: 'The page you are looking for does not exist',
};

export default PageMessage;
