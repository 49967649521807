import React, { Fragment, useReducer, useEffect } from 'react';
import { Table } from 'antd';
import PropTypes from 'prop-types';
import * as moment from 'moment';

import { getMerchantWebhooksById } from '../../services/merchants';

const initialState = {
  isFetchingWebhooks: false,
  webhooks: [],
};

function reducer(prevState, action) {
  switch (action.type) {
    case 'FETCH_WEBHOOKS':
      return {
        ...prevState,
        isFetchingWebhooks: true,
      };

    case 'SET_WEBHOOKS':
      return {
        ...prevState,
        webhooks: action.webhooks,
        isFetchingWebhooks: false,
      };

    default:
      return prevState;
  }
}

const WebhooksTable = (props) => {
  const { merchant, history, endpoints } = props;
  const [state, dispatch] = useReducer(reducer, initialState);
  const timezone = moment.tz.guess(true);

  useEffect(() => {
    let ableToSet = true;

    (async () => {
      if (ableToSet) {
        dispatch({ type: 'FETCH_WEBHOOKS' });
      }

      if (!endpoints) {
        try {
          const { data: { webhooks } } = await getMerchantWebhooksById(merchant.id);

          if (ableToSet) {
            dispatch({ type: 'SET_WEBHOOKS', webhooks });
          }
        } catch (error) {
          if (ableToSet) {
            dispatch({ type: 'SET_WEBHOOKS', webhooks: [] });
          }
        }
      } else if (ableToSet) {
        dispatch({ type: 'SET_WEBHOOKS', webhooks: endpoints });
      }
    })();

    return () => { ableToSet = false; };
  }, []);

  const columns = [
    {
      title: 'URL',
      key: 'callbackUrl',
      render: row => (
        <div>
          {row.callbackUrl}
        </div>
      ),
    },
    {
      title: 'Status',
      render: row => (
        <Fragment>
          <div className="text-primary">
            {row.endpointStatus}
          </div>
        </Fragment>
      ),
    },
    {
      title: 'Updated At',
      render: row => (
        <div>
          {moment(row.updatedAt).tz(timezone).format('lll')}
        </div>
      ),
    },
  ];

  return (
    <Table
      className="table-standard "
      dataSource={endpoints || state.webhooks}
      columns={columns}
      loading={state.isFetchingWebhooks}
      rowKey="callbackUrl"
      pagination={false}
      onRow={row => ({
        onClick: () => {
          history.push(`/merchants/${merchant.code}/developers/webhook/${row.webhookEndpointId}`);
        },
      })}
    />
  );
};

WebhooksTable.propTypes = {
  merchant: PropTypes.shape({
    id: PropTypes.number.isRequired,
    code: PropTypes.string.isRequired,
  }).isRequired,
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }).isRequired,
  endpoints: PropTypes.arrayOf(PropTypes.any),
};

WebhooksTable.defaultProps = {
  endpoints: null,
};

export default WebhooksTable;
