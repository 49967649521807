import crypto from 'crypto';
import md5 from 'md5';
import moment from 'moment';
import React from 'react';

import {
  ACCESS_TOKEN,
  CLIENT_ID,
  REFRESH_TOKEN,
  USER_MERCHANT,
  REMEMBER_ME,
} from '../constants';

function getAPIErrorMessage(error, defaultMessage) {
  if (!error) { return 'We are not able to reach the server. Please try again later'; }

  if (error && error.response && error.response.data) {
    return error.response.data.message || defaultMessage;
  }

  return defaultMessage;
}

function generateRandomString(length = 24) {
  try {
    const buf = crypto.randomBytes(length);
    return buf.toString('hex');
  } catch (err) {
    return false;
  }
}

function getOrGenerateClientId() {
  try {
    let clientId;
    clientId = localStorage.getItem(CLIENT_ID);
    if (!clientId) {
      const newClientId = generateRandomString(40);
      localStorage.setItem(CLIENT_ID, newClientId);
      clientId = newClientId;
    }
    return clientId;
  } catch (err) {
    return false;
  }
}

function removeAuthTokens() {
  localStorage.removeItem(ACCESS_TOKEN);
  localStorage.removeItem(CLIENT_ID);
  localStorage.removeItem(USER_MERCHANT);
  localStorage.removeItem(REFRESH_TOKEN);
  localStorage.removeItem(REMEMBER_ME);
}

function getReadablePaymentType(type) {
  if (!type) { return 'N/A'; }

  switch (type) {
    case 'RF': return 'Reservation Fee';
    case 'MA': return 'Monthly Amortization';
    case 'IC': return 'Interest Charges';
    case 'TC': return 'Transfer Charges';
    case 'DP': return 'Down Payment';
    case 'SD': return 'Spot Down Payment';
    case 'TRANSFER_FEE': return 'Transfer Fee';
    case 'RF_U': return 'Unit - Reservation Fee';
    case 'RF_P': return 'Parking - Reservation Fee';
    default: return 'Others';
  }
}

function getReadableTransactionType(type) {
  if (!type) { return 'N/A'; }

  switch (type) {
    case 'recurring payment': return 'Recurring Payment';
    case 'payment': return 'One-Time Payment';
    default: return 'N/A';
  }
}

function getStatusColor(status) {
  if (!status) { return '#0D0D0D'; }

  switch (status) {
    case 'INC': return '#FFCC00';
    case 'FAIL': return '#EB3800';
    case 'PENDING': return '#FFCC00';
    case 'SCHED': return '#FFCC00';
    case 'ONGOING': return '#36D1DC';
    case 'FOR REVIEW': return '#FFCC00';
    case 'DECLINED': return '#EB3800';
    case 'CANCELLED': return '#EB3800';
    case 'SUCCESS': return '#399DE5';
    case 'PAID': return '#399DE5';
    case 'DONE': return '#96D647';
    case 'SETTLED': return '#96D647';
    case 'REFUNDED': return '#8B90A0';
    case 'DISPUTED': return '#FFCC00';
    case 'WON': return '#399DE5';
    case 'LOST': return '#EB3800';
    case 'ARCHIVED': return '#8B90A0';
    default: return '#0D0D0D';
  }
}

function getMerchantStatusColor(status) {
  if (!status) { return '#0D0D0D'; }

  switch (status) {
    case 10: return '#399DE5';
    case 20: return '#FFCC00';
    case 30: return '#000000';
    case 40: return '#EB3800';
    case 50: return '#FFCC00';
    default: return '#0D0D0D';
  }
}

function getPaymentModes(merchant) {
  if (!merchant.paymentModes || merchant.paymentModes.length === 0) {
    return 'None';
  }
  return merchant.paymentModes
    .reduce((a, b) => `${a}, ${b}`, '')
    .slice(2);
}

function getPaymentTypes(merchant) {
  if (!merchant.paymentTypes || merchant.paymentTypes.length === 0) {
    return 'None';
  }
  return merchant.paymentTypes
    .reduce((a, b) => `${a}, ${b}`, '')
    .slice(2);
}

function getReadableMerchantGroup(group) {
  if (!group) { return 'N/A'; }
  switch (group) {
    case 'realestate': return 'Real Estate';
    case 'bpo': return 'Professional Services';
    default: return 'N/A';
  }
}

function getGravatarURL(email, size = 24) {
  const formattedEmail = `${email}`.trim().toLowerCase();
  const hash = md5(formattedEmail, { encoding: 'binary' });
  return `//www.gravatar.com/avatar/${hash}?d=identicon&r=g&s=${size}`;
}

function getPermissionsDisplay(permissionKeys, allPermissions) {
  return allPermissions
    .filter(p => permissionKeys.indexOf(p.key) > -1)
    .map(p => p.label)
    .reduce((a, b) => `${a}, ${b}`, '')
    .slice(2);
}

function capitalize(str) {
  const splitStr = str.toLowerCase().split(' ');
  for (let i = 0; i < splitStr.length; i += 1) {
    splitStr[i] = splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);
  }
  return splitStr.join(' ');
}

function formatNumber(num) {
  if (!num) { return 0; }
  if (typeof num !== 'number') { return 0; }

  return num.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ',');
}

function getTransactionLink(row) {
  const {
    referenceId,
    merchantCode,
    transactionType,
    paymentReferenceId,
    externalTransactionId,
  } = row;

  switch (transactionType) {
    case 'PAYMENT':
      return `/merchants/${merchantCode}/payments/${referenceId || paymentReferenceId}`;
    case 'RECURRING PAYMENT':
      return `/merchants/${merchantCode}/payments/${referenceId || paymentReferenceId}`;
    case 'ENROLLMENT':
      return `/merchants/${merchantCode}/enrollments/${externalTransactionId}`;
    default:
      return null;
  }
}

function getDefaultCreatedDate(dateFormat) {
  const date = new Date();
  const defaultEndDate = moment(date, dateFormat);
  const defaultStartDate = moment(date, dateFormat).subtract(7, 'd');

  return { defaultStartDate, defaultEndDate };
}

/**
 * Function to parse values from custom fields to
 * datetime if value is of ISO datetime string format
 * @param {*} value Value from any custom fields
 * @returns Formatted datetime string or value
 */
function parseCustomValue(value) {
  const parsedValue = moment(value, moment.ISO_8601, true);

  if (parsedValue.isValid()) {
    return parsedValue.format('YYYY-MM-DD');
  }

  return value;
}

function aggregateListToText(items) {
  const uniques = [];
  for (let i = 0; i < items.length; i += 1) {
    const category = items[i];
    if (uniques.indexOf(category) === -1) {
      uniques.push(category);
    }
  }
  if (uniques.every(p => !p)) { return null; }

  const reduced = uniques.reduce((pv, cv) => `${cv}, ${pv}`, '');
  return reduced.slice(0, reduced.length - 2);
}

function jsonRenderer(data, source) {
  function typeIdentifier(d) {
    if (['true', 'false', 'null'].includes(d)) {
      return 'is-bool';
    }
    if (d.includes('"')) {
      return 'is-string';
    }
    if (!Number.isNaN(Number(d))) {
      return 'is-number';
    }

    return 'is-standard';
  }

  return JSON.stringify(data, null, 2)
    .split('\n')
    .map((text, index) => {
      if (!text.includes(':')) {
        const hasComma = text[text.length - 1] === ',';
        const display = hasComma ? text.slice(0, -1) : text;
        const datatype = typeIdentifier(display.trim());

        return (
          <span
            // eslint-disable-next-line react/no-array-index-key
            key={`${source}-${index}`}
          >
            <span className={datatype}>
              {display}
              <span className="is-standard">{hasComma ? ',\n' : '\n'}</span>
            </span>
          </span>
        );
      }

      const [key, ...values] = text.split(':');
      return (
        // eslint-disable-next-line react/no-array-index-key
        <span key={`${source}-${index}`}>
          <span className="is-key">{key}</span>
          <span className="is-standard">:</span>
          {((_values) => {
            // NOTE: Indicates a string that contains ':'
            if (_values.length > 1) {
              const display = _values.join(':');
              const hasComma = display[display.length - 1] === ',';
              return (
                <span className="is-string">
                  {hasComma ? display.slice(0, -1) : display}
                  <span className="is-standard">{hasComma ? ',\n' : '\n'}</span>
                </span>
              );
            }

            const [value] = _values;
            const hasComma = value[value.length - 1] === ',';
            const display = hasComma ? value.slice(0, -1) : value;
            const datatype = typeIdentifier(display.trim());

            return (
              <span className={datatype}>
                {display}
                <span className="is-standard">{hasComma ? ',\n' : '\n'}</span>
              </span>
            );
          })(values)}
        </span>
      );
    });
}

function getWebhookTaskStatus(taskLog, verbose = false) {
  if (taskLog.isComplete) {
    return (
      <span className="webhook-task-status -success">
        {verbose ? `${taskLog.taskResponseCode} ` : ''}
        SUCCESS
      </span>
    );
  }

  if (taskLog.taskResponseCode === null) {
    return (
      <span className="webhook-task-status -in-progress">
        IN PROGRESS
      </span>
    );
  }

  return (
    <span className="webhook-task-status -failed">
      {taskLog.taskResponseCode !== null && verbose ? `${taskLog.taskResponseCode} ` : ''}
      FAILED
    </span>
  );
}

export {
  getOrGenerateClientId,
  removeAuthTokens,
  getReadablePaymentType,
  getStatusColor,
  getGravatarURL,
  getPaymentModes,
  getPaymentTypes,
  getReadableMerchantGroup,
  getPermissionsDisplay,
  getReadableTransactionType,
  formatNumber,
  getTransactionLink,
  getAPIErrorMessage,
  capitalize,
  parseCustomValue,
  getDefaultCreatedDate,
  aggregateListToText,
  jsonRenderer,
  getWebhookTaskStatus,
  getMerchantStatusColor,
};
