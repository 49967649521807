import moment from 'moment';
import React, { Fragment, useReducer, useEffect } from 'react';
import PropTypes from 'prop-types';
import FileIcon, { defaultStyles } from 'react-file-icon';
import { Link } from 'react-router-dom';
import { Tag, Button, DatePicker, Input } from 'antd';

function SettlementDateStep(props) {
  const dateFormat = 'YYYY-MM-DD';
  const {
    payments, file, settledDate, settlementNotes, isSaving,
    onFormChange, onNext, onPrevious,
  } = props;

  const [state, dispatch] = useReducer((prevState, action) => {
    switch (action.type) {
      case 'SET_SETTLED_DATE':
        return {
          ...prevState,
          settledDate: action.settledDate,
        };
      case 'SET_SETTLEMENT_NOTES':
        return {
          ...prevState,
          settlementNotes: action.settlementNotes,
        };
      default:
        return prevState;
    }
  }, {
    settledDate,
    settlementNotes,
  });

  useEffect(() => {
    onFormChange({
      settledDate: state.settledDate,
      settlementNotes: state.settlementNotes,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.settledDate, state.settlementNotes]);

  function getTotalAmountFees(feeType) {
    const paymentValues = [];
    const reducer = (prevValue, currValue) => prevValue + currValue;
    payments.map(amount => (
      // eslint-disable-next-line no-nested-ternary
      amount.net[0] === 'USD'
        ? paymentValues.push(amount[feeType][1])
        : amount[feeType][0] === 'PHP' ? paymentValues.push(amount[feeType][1]) : paymentValues.push(amount.qwxRate[2] * amount[feeType][1])
    ));
    return [Math.round(paymentValues.reduce(reducer) * 100) / 100, paymentValues.length];
  }

  function getTotalCurrency() {
    let currency = '';
    // eslint-disable-next-line no-return-assign
    payments.map(amount => (amount.net[0] === 'USD' ? currency = 'USD' : currency = 'PHP'));
    return currency;
  }

  const searchExtensionResults = /.[a-z]+$/.exec(file.fileName);
  const fileExtension = searchExtensionResults ? searchExtensionResults[0] : null;
  return (
    <Fragment>
      <div className="middle">
        <div className="middle-col heading-primary subheading">
          <div className="settlement-field">
            <div className="field-label">Payments</div>
            <div className="field-content">
              <div className="field-content-tags">
                {payments.map(payment => (
                  <Tag
                    className="text-primary payments-tag payments-tag-center"
                    key={payment.invoiceId}
                    color="geekblue"
                    closable={false}
                  >
                    <Link
                      to={`/payments/${payment.invoiceId}`}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {payment.paymentReferenceId}
                    </Link>
                  </Tag>
                ))}
              </div>
            </div>
          </div>
          <div className="settlement-field">
            <div className="field-label">
              Date Settled
            </div>
            <div className="field-content">
              <DatePicker
                className="field-content-datepicker"
                defaultValue={state.settledDate && state.settledDate.isValid() ? state.settledDate : null}
                format={dateFormat}
                placeholder="Select date"
                onChange={(date) => {
                  dispatch({ type: 'SET_SETTLED_DATE', settledDate: date ? moment(date) : null });
                }}
              />
            </div>
          </div>
          <div className="settlement-field">
            <div className="field-label">
              Notes
            </div>
            <div className="field-content">
              <Input.TextArea
                className="field-content-textarea"
                defaultValue={state.settlementNotes}
                onChange={(e) => {
                  dispatch({ type: 'SET_SETTLEMENT_NOTES', settlementNotes: e.target.value });
                }}
              />
            </div>
          </div>
        </div>
        <div className="middle-col heading-primary subheading">
          <div className="settlement-field">
            <div className="field-label">Settlement Summary</div>
            <div className="field-content">
              <div className="bill-amount">
                <p>
                  Total Amount:
                  { `${getTotalCurrency()} ${getTotalAmountFees('billTotal')[0]}` }
                </p>
                <p>
                  Total Waived:
                  { `${getTotalCurrency()} ${getTotalAmountFees('waivedFee')[0]}` }
                </p>
                <p>
                  Total Net Amount:
                  { `${getTotalCurrency()} ${getTotalAmountFees('net')[0]}` }
                </p>
                <p>
                  From
                  { getTotalAmountFees('billTotal')[1] }
                  {' '}
                  Payments
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="middle-col heading-primary subheading">
          <div className="txn-label">Settlement File</div>
          <div>
            <div className="file-icon-wrapper">
              <a
                className="file-icon"
                href={file.presignedUrl}
                onClick={() => {}}
                target="_blank"
                rel="noopener noreferrer"
              >
                <Fragment>
                  <FileIcon extension={fileExtension} {...defaultStyles[fileExtension]} />
                  <span>{file.fileName}</span>
                </Fragment>
              </a>
            </div>
          </div>
        </div>
      </div>
      <div className="header footer">
        <div className="action-button">
          <Button
            className="button button-standard button-standard-outline"
            disabled={isSaving}
            onClick={() => onPrevious()}
          >
            Previous
          </Button>
          <span>
            <Button
              className="button button-standard next-button"
              onClick={() => onNext()}
              loading={isSaving}
              disabled={isSaving}
            >
              Done
            </Button>
          </span>
        </div>
      </div>
    </Fragment>
  );
}

SettlementDateStep.propTypes = {
  settledDate: PropTypes.any.isRequired, // eslint-disable-line react/forbid-prop-types
  settlementNotes: PropTypes.string,
  merchant: PropTypes.shape({
    id: PropTypes.number.isRequired,
    code: PropTypes.string.isRequired,
  }).isRequired,
  file: PropTypes.shape({
    settlementFileId: PropTypes.number.isRequired,
    fileName: PropTypes.string.isRequired,
    presignedUrl: PropTypes.string.isRequired,
  }),
  payments: PropTypes.arrayOf(PropTypes.object).isRequired,
  isSaving: PropTypes.bool.isRequired,
  onFormChange: PropTypes.func,
  onPrevious: PropTypes.func,
  onNext: PropTypes.func,
};

SettlementDateStep.defaultProps = {
  file: null,
  settlementNotes: null,
  onFormChange: () => {},
  onPrevious: () => {},
  onNext: () => {},
};

export default SettlementDateStep;
