import React, { useEffect, useReducer, useContext } from 'react';
import PropTypes from 'prop-types';
import { Select } from 'antd';
import SessionContext from '../../contexts/SessionContext';
import { getTimezonesService } from '../../services/lookup';

const initialState = {
  isFetching: false,
  timezones: [],
};

function reducer(prevState, action) {
  switch (action.type) {
    case 'FETCH_TIMEZONES':
      return {
        ...prevState,
        isFetching: true,
      };

    case 'FETCH_TIMEZONES_SUCCESSFUL':
      return {
        ...prevState,
        isFetching: false,
        timezones: action.timezones,
      };

    case 'FETCH_TIMEZONES_FAILED':
      return {
        ...prevState,
        isFetching: false,
        timezones: [],
      };

    default:
      return prevState;
  }
}

const TimezoneSelector = (props) => {
  const { value, onChange } = props;
  const { Option } = Select;
  const [state, dispatch] = useReducer(reducer, initialState);
  const { showToast } = useContext(SessionContext);

  useEffect(() => {
    let ableToSet = true;
    (async () => {
      try {
        dispatch({ type: 'FETCH_TIMEZONES' });
        const { data } = await getTimezonesService();
        if (ableToSet) dispatch({ type: 'FETCH_TIMEZONES_SUCCESSFUL', timezones: data });
      } catch (error) {
        let message = error && error.response && error.response.data.message;
        message = message || 'Unable to get timezones as of the moment';
        showToast({ type: 'error', message });
        if (ableToSet) dispatch({ type: 'FETCH_TIMEZONES_FAILED' });
      }
    })();

    return () => { ableToSet = false; };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const options = state.timezones.map(({ name, utcOffset }) => <Option key={name}>{`(${utcOffset}) ${name}`}</Option>);

  function handleTimezoneChange(changedValue) {
    triggerChange(changedValue);
  }

  function triggerChange(changedValue) {
    if (onChange) {
      onChange(changedValue);
    }
  }

  return (
    <Select
      style={{ width: '100%' }}
      showSearch
      loading={state.isFetching}
      value={value}
      onChange={e => handleTimezoneChange(e)}
    >
      {options}
    </Select>
  );
};

TimezoneSelector.propTypes = {
  value: PropTypes.string,
  onChange: PropTypes.func,
};

TimezoneSelector.defaultProps = {
  value: '',
  onChange: () => {},
};

export default TimezoneSelector;
